import { useContext } from 'react';
import { FilterContext } from './FilterProvider';

export default function useFilterContext() {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a FilterProvider');
  }
  return context;
}
