import { Tooltip } from '@mui/material';
import { selectParams } from 'common/selectors';
import { createPageRef } from 'features/case/redux/createPageRef';
import React, { useRef, useState, useEffect } from 'react';
import AutosizeInput from 'react-input-autosize';
import onClickOutside from 'react-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';

const CurrentGlobalPage = ({
  currentFileMetaData,
  currentPageNumber,
  setPageNumber,
  fileId,
}: {
  currentFileMetaData: any;
  currentPageNumber: number;
  setPageNumber: (val: any) => void;
  fileId: string;
}) => {
  const dispatch = useDispatch();
  const { case: aCase, file } = useSelector(selectParams);
  const [gPNumberValue, setGPNumberValue] = useState(0);
  const [openTooltip, setOpenTooltip] = useState(false);
  const gPNumberElement = useRef<any>(null);

  const startPageVal = (currentFileMetaData.startPage || 0).toString().split('.');
  const isStartPageFirstDigitAfterDecimalZero =
    startPageVal && startPageVal.length > 1 && startPageVal[1].startsWith('0');
  const currentFileStartPage =
    startPageVal.length > 1
      ? parseInt(startPageVal[1], 10)
      : parseInt(currentFileMetaData.startPage, 10);
  const endGPNumber = parseInt(currentFileMetaData.pageCount, 10) + currentFileStartPage - 1;
  const gpText = `${currentFileMetaData.globalPagePrefix}${
    startPageVal.length > 1
      ? startPageVal[0] + `.${isStartPageFirstDigitAfterDecimalZero ? '0' : ''}`
      : ''
  }${gPNumberValue}`;
  //@ts-ignore
  CurrentGlobalPage.handleClickOutside = () =>
    gPNumberElement.current && gPNumberElement.current.blur();
  const changePageNumber = () => {
    const newNum = Math.min(Math.max(gPNumberValue, currentFileStartPage), endGPNumber);
    const localPageValue = newNum - currentFileStartPage + 1;
    setPageNumber(localPageValue);
    setGPNumberValue(newNum);
  };

  const copyToClipboard = () => {
    const text = gpText.toString();
    const url = `${window.location.origin}/case/${aCase}/files/${fileId ||
      file}?goToPage=${currentPageNumber}`;
    const htmlToCopy = `<a href="${url}">${text}</a>`;
    // Using the Clipboard API to write HTML
    navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([htmlToCopy], { type: 'text/html' }),
          'text/plain': new Blob([text], { type: 'text/plain' }),
        }),
      ])
      .then(() => {
        handleTooltipOpen();
        dispatch(createPageRef({ globalPage: text }));
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const handleTooltipClose = () => setOpenTooltip(false);
  const handleTooltipOpen = () => setOpenTooltip(true);

  useEffect(() => {
    if (openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1500);
    }
  }, [openTooltip]);

  useEffect(() => {
    const currentGPNumber = currentFileStartPage + currentPageNumber - 1;
    setGPNumberValue(currentGPNumber);
  }, [currentPageNumber, currentFileStartPage]);

  return (
    <Tooltip
      placement="top"
      onClose={handleTooltipClose}
      open={openTooltip}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={`${gpText} - Copied to clipboard`}
      arrow
    >
      <div
        className="pageNumber"
        onClick={() => gPNumberElement.current && gPNumberElement.current.focus()}
        onDoubleClick={(e: any) => {
          e.stopPropagation();
          copyToClipboard();
        }}
      >
        <span style={{ marginRight: '-0.1rem' }}>
          {currentFileMetaData.globalPagePrefix}
          {startPageVal.length > 1 &&
            startPageVal[0] + `.${isStartPageFirstDigitAfterDecimalZero ? '0' : ''}`}
        </span>
        <AutosizeInput
          ref={gPNumberElement}
          value={gPNumberValue || ''}
          onChange={(event: any) => {
            const re = /^[0-9\b]+$/;
            if (event.target.value === '' || re.test(event.target.value))
              setGPNumberValue(event.target.value);
          }}
          onBlur={changePageNumber}
          onKeyDown={(e: any) => {
            e.key === 'Enter' && gPNumberElement.current.blur();
            (e.ctrlKey || e.metaKey) && e.keyCode === 67 && copyToClipboard();
          }}
          autoComplete="off"
        />
      </div>
    </Tooltip>
  );
};

export default onClickOutside(CurrentGlobalPage, {
  //@ts-ignore
  handleClickOutside: () => CurrentGlobalPage.handleClickOutside,
});
