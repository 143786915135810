import React, { useEffect, useRef, useState } from 'react';
import { Button, Paper, Typography, Container, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AudioDevice from './AudioDevice/AudioDevice';
import ProgressBar from './ProgressBar/ProgressBar';
import useAudioTest from './useAudioTest/useAudioTest';
import { ActivePane, useAppStateContext } from '../../AppStateProvider/AppStateProvider';
import Microphone from '../../../icons/Microphone';
import Speaker from '../../../icons/SpeakerIcon';

const useStyles = makeStyles({
  paper: {
    display: 'inline-block',
    padding: '23px',
    borderRadius: '8px',
    minHeight: '280px',
    width: '300px',
  },
  audioLevelContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '2.5em',
  },
  topLine: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
});

export function AudioTest() {
  const classes = useStyles();
  const [inputDeviceId, setInputDeviceId] = useState('');
  const [outputDeviceId, setOutputDeviceId] = useState('');
  const [disableRecord, setDisableRecord] = useState(false);
  const [disablePlayBack, setDisablePlayBack] = useState(true);
  const [disableYes, setDisableYes] = useState(true);
  const previousInputDeviceIdRef = useRef('');
  const { state, dispatch } = useAppStateContext();

  const {
    error,
    setError,
    isRecording,
    isAudioInputTestRunning,
    isAudioOutputTestRunning,
    playAudio,
    playbackURI,
    readAudioInput,
    stopAudioTest,
    inputLevel,
    outputLevel,
  } = useAudioTest();

  const volumeLevel = isAudioOutputTestRunning ? outputLevel : inputLevel;

  const disableAll =
    isRecording || isAudioOutputTestRunning || (!!error && error !== 'No audio detected');

  const handleRecordClick = () => {
    readAudioInput({ deviceId: inputDeviceId, enableRecording: true });
  };

  const handlePlayClick = () => {
    playAudio({ deviceId: outputDeviceId, testURI: playbackURI });
  };

  // stop test when not on AudioTest and there's an active test
  useEffect(() => {
    if (
      state.activePane !== ActivePane.AudioTest &&
      (isAudioOutputTestRunning || isAudioInputTestRunning)
    ) {
      stopAudioTest();
    }
  }, [state.activePane, stopAudioTest, isAudioInputTestRunning, isAudioOutputTestRunning]);

  // start audio test when on AudioTest and deviceId changes
  useEffect(() => {
    if (state.activePane === ActivePane.AudioTest) {
      const newInputDeviceSelected = previousInputDeviceIdRef.current !== inputDeviceId;
      previousInputDeviceIdRef.current = inputDeviceId;

      // Restarts the test to continuously capture audio input
      if (!error && (newInputDeviceSelected || (!isRecording && !isAudioInputTestRunning))) {
        readAudioInput({ deviceId: inputDeviceId });
      }
    }
    if (error) {
      stopAudioTest();
    }
  }, [
    error,
    state.activePane,
    inputDeviceId,
    isRecording,
    isAudioInputTestRunning,
    readAudioInput,
    dispatch,
    stopAudioTest,
  ]);

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={5}>
          <Typography variant="h5" gutterBottom>
            Test your audio
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong>
              <i>Record</i>
            </strong>{' '}
            an audio clip and{' '}
            <strong>
              <i>play it back</i>
            </strong>{' '}
            to check that your microphone and speaker are working. If they aren't, make sure your
            volume is turned up, try a different speaker or microphone, or check your bluetooth
            settings.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <strong> Does your audio sound good?</strong>
          </Typography>
          {!disableRecord && (
            <Button
              variant="outlined"
              style={{ marginRight: '1em' }}
              size="small"
              disabled={disableAll}
              onClick={() => {
                handleRecordClick();
                setDisableRecord(true);
                setDisablePlayBack(false);
              }}
            >
              Record
            </Button>
          )}
          {!disablePlayBack && (
            <Button
              variant="outlined"
              size="small"
              disabled={!playbackURI || disableAll}
              onClick={() => {
                setDisablePlayBack(true);
                setDisableYes(false);
                handlePlayClick();
              }}
            >
              Play back
            </Button>
          )}
          {!disableYes && (
            <Button
              variant="contained"
              style={{ marginRight: '1.5em' }}
              color="primary"
              onClick={() => dispatch({ type: 'next-pane' })}
              disabled={(!!error && error !== 'No audio detected') || disableYes}
            >
              Yes
            </Button>
          )}
          {/* <Button
            color="primary"
            onClick={() => dispatch({ type: 'next-pane' })}
            disabled={!!error && error !== 'No audio detected'}
          >
            Skip for now
          </Button> */}
        </Grid>
        ​
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            ​
            <AudioDevice
              disabled={disableAll}
              kind="audiooutput"
              onDeviceChange={setOutputDeviceId}
              setDeviceError={setError}
            />
            <AudioDevice
              disabled={disableAll}
              kind="audioinput"
              onDeviceChange={setInputDeviceId}
              setDeviceError={setError}
              error={error}
            />
            <div className={classes.audioLevelContainer}>
              <div style={{ width: '2em', display: 'flex', justifyContent: 'center' }}>
                {isAudioOutputTestRunning ? <Speaker /> : <Microphone />}
              </div>
              <ProgressBar position={volumeLevel} duration={0.1} style={{ flex: '1' }} />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
