import { useCallback, useEffect, useRef, useState } from 'react';
import { ViewerControl } from '@prizmdoc/viewer-core';
import { getScrollContainer } from '../utils';
import { MARK_TYPE, PRESENT_COLOR } from '../constants';
import { MouseToolType } from '../../types';

export const usePauseDocument = (
  viewerControl: typeof ViewerControl,
  isTheatreMode: boolean,
  initializingCount: number,
  selectedMouseTool: any,
  initializingRef: any,
) => {
  const [isDisconnected, setIsDisconnected] = useState<boolean | undefined>(undefined);
  const [pauseTheatreMode, setPauseTheatreMode] = useState(false);

  const pauseTheatreModeTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const scrollContainer = getScrollContainer(viewerControl);

  const disconnectPauseTheatreModeHandler = () => {
    clearPauseTheatreModeTimeout();
    setIsDisconnected(true);
  };

  const unpauseTheatreMode = useCallback(() => {
    setPauseTheatreMode(prev => (prev ? false : prev));
    isDisconnected && setIsDisconnected(false);
  }, [isDisconnected]);

  const clearPauseTheatreModeTimeout = () => {
    pauseTheatreModeTimeoutRef.current && clearTimeout(pauseTheatreModeTimeoutRef.current);
  };

  const pauseTheatreModeHandler = useCallback(() => {
    if (isTheatreMode && !initializingCount && !isDisconnected && !initializingRef.current) {
      setPauseTheatreMode(prev => (!prev ? true : prev));
      clearPauseTheatreModeTimeout();
      pauseTheatreModeTimeoutRef.current = setTimeout(() => {
        unpauseTheatreMode();
      }, 3000);
    }
  }, [initializingCount, isDisconnected, isTheatreMode, unpauseTheatreMode, initializingRef]);

  const unpauseTheatreModeHandler = () => {
    setPauseTheatreMode(prev => (prev ? false : prev));
    isDisconnected && setIsDisconnected(false);
  };

  // MOUNT
  useEffect(() => {
    return () => {
      pauseTheatreModeTimeoutRef.current && clearTimeout(pauseTheatreModeTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!viewerControl) return;

    const mouseUpDownHandler = () => {
      if (selectedMouseTool !== MouseToolType.PanAndEdit) {
        pauseTheatreModeHandler();
      }
    };

    const markCreatedHandler = ({ mark }: any) => {
      const isScrollMark = mark.type === MARK_TYPE;
      const isPresentMark = mark.fillColor === PRESENT_COLOR;
      // if it's not scroll mark and not presentationmark, we need to pause (user is creating mark)
      if (isTheatreMode && !isScrollMark && !isPresentMark) {
        pauseTheatreModeHandler();
      }
    };

    if (isTheatreMode) {
      // events that bring viewer in pause mode
      viewerControl.on('DocumentRotated', pauseTheatreModeHandler);
      scrollContainer?.addEventListener('scroll', pauseTheatreModeHandler);
      scrollContainer?.addEventListener('mouseup', mouseUpDownHandler);
      scrollContainer?.addEventListener('mousedown', mouseUpDownHandler);
      viewerControl.on('MarkCreated', markCreatedHandler);
      viewerControl.on('PageChanged', pauseTheatreModeHandler);
      viewerControl.on('ScaleChanged', pauseTheatreModeHandler);
    } else {
      // TO-DO
      // when page or scale changes we need to update customToolbox
      // viewerControl.on('PageChanged', invalidate);
      // viewerControl.on('ScaleChanged', invalidate);
    }

    return () => {
      if (isTheatreMode) {
        viewerControl.off('DocumentRotated', pauseTheatreModeHandler);
        scrollContainer?.removeEventListener('scroll', pauseTheatreModeHandler);
        scrollContainer?.removeEventListener('mouseup', mouseUpDownHandler);
        scrollContainer?.removeEventListener('mousedown', mouseUpDownHandler);
        viewerControl.off('MarkCreated', markCreatedHandler);
        viewerControl.off('PageChanged', pauseTheatreModeHandler);
        viewerControl.off('ScaleChanged', pauseTheatreModeHandler);
      } else {
        // viewerControl.off('PageChanged', invalidate);
        // viewerControl.off('ScaleChanged', invalidate);
      }
    };
  }, [viewerControl, isTheatreMode, pauseTheatreModeHandler, scrollContainer, selectedMouseTool]);

  return {
    unpauseTheatreModeHandler,
    disconnectPauseTheatreModeHandler,
    pauseTheatreMode,
  };
};
