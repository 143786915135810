import moment from 'moment';

export const onCustomSortDate = (a: any, b: any) => {
  if (!a) return -1;
  if (!b) return 1;

  const formats = ['DD/MM/YYYY', 'DD/MM/YYYY HH:mm:ss'];

  const dateA = moment(a, formats, true);
  const dateB = moment(b, formats, true);

  if (!dateA.isValid()) return -1;
  if (!dateB.isValid()) return 1;

  const diff = dateA.diff(dateB);

  return diff;
};

export const onCustomSortNum = (a: any, b: any) => {
  if (!a) return -1;
  if (!b) return 1;
  return a - b;
};

export const onCustomSortText = (a: any, b: any) => {
  if (!a) return -1;
  if (!b) return 1;
  const varA = a.toUpperCase();
  const varB = b.toUpperCase();
  return varA.localeCompare(varB, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};

export const onCustomSortCreatedByName = (a: any, b: any) => {
  if (!a) return -1;
  if (!b) return 1;
  const varA = a['name'].toUpperCase();
  const varB = b['name'].toUpperCase();
  return varA.localeCompare(varB, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
};
