import {
  CASE_TEAM_MEMBER_SEND_INVITE_BEGIN,
  CASE_TEAM_MEMBER_SEND_INVITE_SUCCESS,
  CASE_TEAM_MEMBER_CHANGE_TEAM_SUCCESS,
  CASE_TEAM_MEMBER_SEND_INVITE_FAILURE,
  CASE_TEAM_MEMBER_SEND_INVITE_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { addItemImmutableAtFirst, replaceItemImmutable } from 'utils/arrays';

export const sendInvite = createPromiseAction(CASE_TEAM_MEMBER_SEND_INVITE_BEGIN);

export function dismissSendInviteFeedback() {
  return {
    type: CASE_TEAM_MEMBER_SEND_INVITE_DISMISS_FEEDBACK,
  };
}

export function* doSendInvite(action) {
  const { caseId, teamId, email, sendInvite, changeTeam } = action.payload;

  let res = yield call(
    api.post,
    `/cases/${caseId}/teams/${teamId}/invites?noInvite=${sendInvite ? false : true}`,
    email,
  );

  if (res && res.error) {
    yield put({
      type: CASE_TEAM_MEMBER_SEND_INVITE_FAILURE,
      feedback: {
        message: 'feedback.sendInviteFailure',
        params: { userEmail: email.email },
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  const newUser = res.users.find(item => item.mail === email.email.toLowerCase());

  if (changeTeam) {
    yield put({
      type: CASE_TEAM_MEMBER_CHANGE_TEAM_SUCCESS,
      data: { team: res },
    });
  } else {
    yield put({
      type: CASE_TEAM_MEMBER_SEND_INVITE_SUCCESS,
      data: { team: res, newUser, email },
    });
  }
  yield call(resolvePromiseAction, action, res);
}

export function* watchSendInvite() {
  yield takeEvery(sendInvite, withCurrentCaseId(doSendInvite));
}

export function reducer(state, action) {
  const getTeamIndex = teamId => state.teams && state.teams.findIndex(({ id }) => id === teamId);
  switch (action.type) {
    case CASE_TEAM_MEMBER_SEND_INVITE_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        sendInvitePending: true,
        sendInviteFeedback: null,
      };

    case CASE_TEAM_MEMBER_CHANGE_TEAM_SUCCESS:
      return {
        ...state,
        sendInvitePending: false,
        sendInviteFeedback: action.feedback,
        ...(state.teams && {
          teams: replaceItemImmutable(
            state.teams,
            action.data.team,
            getTeamIndex(action.data.team.id),
          ),
        }),
      };

    case CASE_TEAM_MEMBER_SEND_INVITE_SUCCESS:
      // The request is success
      return {
        ...state,
        sendInvitePending: false,
        sendInviteFeedback: action.feedback,
        ...(state.team && {
          team: {
            ...action.data.team,
            users: addItemImmutableAtFirst(state.team.users, action.data.newUser),
          },
        }),
        ...(state.teams && {
          teams: replaceItemImmutable(
            state.teams,
            action.data.team,
            getTeamIndex(action.data.team.id),
          ),
        }),
      };

    case CASE_TEAM_MEMBER_SEND_INVITE_FAILURE:
      // The request is failed
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        sendInvitePending: false,
        sendInviteFeedback: action.feedback,
        ...(concurencyError && {
          team: {
            ...action.data.team,
            users: addItemImmutableAtFirst(
              state.team.users,
              action.feedback.error.body.users.find(item => item.mail === action.data.email),
            ),
          },
        }),
      };

    case CASE_TEAM_MEMBER_SEND_INVITE_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        sendInviteFeedback: null,
      };

    default:
      return state;
  }
}
