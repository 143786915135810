import React from 'react';
import T from 'i18n';
import PropTypes from 'prop-types';

import TVFooter from './TVFooter';
import TVHeader from './TVHeader';
import SyncIcon from '@mui/icons-material/Sync';
import withAuth from 'features/auth/withAuth';

const Error = ({ children, title, text }) => (
  <div style={{ width: '100%', height: '100%' }}>
    <TVHeader />
    <div className="common-error-page">
      <div className="image">
        <SyncIcon style={{ fontSize: '12rem' }} />
      </div>
      <span className="title">{T.translate('generic.errorPageTitle')}</span>
      <span className="title">{title || T.translate('generic.pageError')}</span>
      {text && <div className="text">{text}</div>}
      {children}
      <span className="link-text">
        {T.translate('generic.goBackMsg')}
        <a href="/" className="link-text link">
          {T.translate('generic.homePage')}
        </a>
      </span>
    </div>
    <div className="footer-container">
      <TVFooter />
    </div>
  </div>
);

Error.propTypes = {
  compact: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default withAuth(Error, { forceLogin: false });
