import React from 'react';
import T from 'i18n';
import { bytesToMegaBytes } from 'utils/formating';

export default ({
  totalPageCount,
  totalDocumentSize,
  documentCount,
  filteredRowCount,
  totalDocumentCount,
  selectedRowsLength,
}: any) => (
  <div className="folder-footer">
    <div className="total-page-count">
      {totalPageCount > -1 &&
        T.translate('case.totalPageCount', {
          count: totalPageCount,
        })}
    </div>
    <div className="total-document-size">
      {totalDocumentSize > -1 &&
        T.translate('case.totalDocumentSize', {
          size: bytesToMegaBytes(totalDocumentSize),
        })}
    </div>
    <div className="document-count">
      {documentCount > -1 &&
        //@ts-ignore
        T.translate('case.documentCount', {
          count: `${filteredRowCount !== null ? filteredRowCount + ' / ' : ''}${
            totalDocumentCount > 0 ? totalDocumentCount : documentCount
          }${selectedRowsLength > 0 ? ' (' + selectedRowsLength + ')' : ''}`,
        })}
    </div>
  </div>
);
