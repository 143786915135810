import { useCallback } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_BEGIN,
  CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_SUCCESS,
  CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FAILURE,
  CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import { withCurrentCaseId } from 'common/selectors';
import api from 'common/api';
import { useDispatch } from 'react-redux';

export const fetchFolderSpecificFileDetails = createPromiseAction(
  CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_BEGIN,
);

export function dismissfetchFolderSpecificFileDetailsFeedback() {
  return {
    type: CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FEEDBACK,
  };
}

// worker Saga: will be fired on HOME_FETCH_TSANDCS_BEGIN actions
export function* doFetchFolderSpecificFileDetails(action) {
  const {
    payload: { caseId, fileId, firstTrialbookOnly = true },
  } = action;

  const res = yield call(
    api.get,
    `/cases/${caseId}/files/${fileId}/tabs?firstTrialbookOnly=${firstTrialbookOnly}`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FAILURE,
      feedback: {
        message: 'feedback.fetchFolderSpecificFileDetailsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function useFetchFolderSpecificFileDetails() {
  const dispatch = useDispatch();

  // const {
  //   fetchFolderSpecificFileDetailsPending,
  //   fetchFolderSpecificFileDetailsFeedback,
  // } = useSelector(
  //   state => ({
  //     fetchFolderSpecificFileDetailsPending: state.case.fetchFolderSpecificFileDetailsPending,
  //     fetchFolderSpecificFileDetailsFeedback: state.case.fetchFolderSpecificFileDetailsFeedback,
  //   }),
  //   shallowEqual,
  // );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchFolderSpecificFileDetails(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissfetchFolderSpecificFileDetailsFeedback());
  }, [dispatch]);

  return {
    fetchFolderSpecificFileDetails: boundAction,
    // fetchFolderSpecificFileDetailsPending,
    // fetchFolderSpecificFileDetailsFeedback,
    dismissfetchFolderSpecificFileDetailsFeedback: boundDismissFeedback,
  };
}

export function* watchFetchFolderSpecificFileDetails() {
  yield takeLatest(
    fetchFolderSpecificFileDetails,
    withCurrentCaseId(doFetchFolderSpecificFileDetails),
  );
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchFolderSpecificFileDetailsPending: true,
        fetchFolderSpecificFileDetailsFeedback: null,
      };

    case CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchFolderSpecificFileDetailsPending: false,
        fetchFolderSpecificFileDetailsFeedback: action.feedback,
      };

    case CASE_FETCH_FOLDER_SPECIFIC_FILE_DETAILS_FAILURE:
      return {
        ...state,
        fetchFolderSpecificFileDetailsPending: false,
        fetchFolderSpecificFileDetailsFeedback: action.feedback,
      };

    default:
      return state;
  }
}
