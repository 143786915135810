import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_UPDATE_TRANSCRIPT_ITEM_BEGIN,
  VIEWING_UPDATE_TRANSCRIPT_ITEM_SUCCESS,
  VIEWING_UPDATE_TRANSCRIPT_ITEM_FAILURE,
  VIEWING_UPDATE_TRANSCRIPT_ITEM_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { replaceItemImmutable } from 'utils/arrays';

export const updateTranscriptItem = createPromiseAction(VIEWING_UPDATE_TRANSCRIPT_ITEM_BEGIN);

export function dismissUpdateTranscriptItemFeedback() {
  return {
    type: VIEWING_UPDATE_TRANSCRIPT_ITEM_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_TRANSCRIPT_ITEM_BEGIN actions
export function* doUpdateTranscriptItem(action) {
  const {
    payload: { caseId, fileId, transcriptItem, transcriptId },
  } = action;
  const res = yield call(
    api.put,
    `/cases/${caseId}/recordings/${fileId}/transcript/${transcriptId}`,
    transcriptItem,
  );

  if (res && res.error) {
    yield put({
      type: VIEWING_UPDATE_TRANSCRIPT_ITEM_FAILURE,
      feedback: {
        message: 'feedback.updateTranscriptItemFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_UPDATE_TRANSCRIPT_ITEM_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateTranscriptItem() {
  yield takeLatest(updateTranscriptItem, withCurrentCaseId(doUpdateTranscriptItem));
}

export function useUpdateTranscriptItem() {
  const dispatch = useDispatch();

  const { updateTranscriptItemPending, recording, updateTranscriptItemFeedback } = useSelector(
    state => ({
      recording: state.viewing.recording,
      updateTranscriptItemPending: state.viewing.updateTranscriptItemPending,
      updateTranscriptItemFeedback: state.viewing.updateTranscriptItemFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateTranscriptItem(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateTranscriptItemFeedback());
  }, [dispatch]);

  return {
    recording,
    updateTranscriptItem: boundAction,
    updateTranscriptItemPending,
    updateTranscriptItemFeedback,
    dismissUpdateTranscriptItemFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  const getIndex = transcriptId =>
    state.recording.transcript.findIndex(({ id }) => id === transcriptId);
  switch (action.type) {
    case VIEWING_UPDATE_TRANSCRIPT_ITEM_BEGIN + '.TRIGGER':
      return {
        ...state,
        updateTranscriptItemPending: true,
        prevRecording: state.recording,
        recording: {
          ...state.recording,
          transcript: replaceItemImmutable(
            state.recording.transcript,
            action.payload.transcriptItem,
            getIndex(action.payload.transcriptId),
          ),
        },
        updateTranscriptItemFeedback: null,
      };

    case VIEWING_UPDATE_TRANSCRIPT_ITEM_SUCCESS:
      return {
        ...state,
        updateTranscriptItemPending: false,
        prevRecording: null,
        recording: {
          ...state.recording,
          transcript: replaceItemImmutable(
            state.recording.transcript,
            action.data,
            getIndex(action.data.id),
          ),
        },
        updateTranscriptItemFeedback: action.feedback,
      };

    case VIEWING_UPDATE_TRANSCRIPT_ITEM_FAILURE:
      return {
        ...state,
        updateTranscriptItemPending: false,
        recording: state.prevRecording,
        updateTranscriptItemFeedback: action.feedback,
      };

    default:
      return state;
  }
}
