import React, { useCallback, useState, useEffect } from 'react';
import fscreen from 'fscreen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import T from 'i18n';

export default ({ className }) => {
  const [isFullScreen, setIsFullScreen] = useState(!!fscreen.fullscreenElement);

  useEffect(() => {
    const onFullScreenChange = () => setIsFullScreen(!!fscreen.fullscreenElement);
    fscreen.addEventListener('fullscreenchange', onFullScreenChange);
    return () => {
      fscreen.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, []);

  const toggleFullScreen = useCallback(() => {
    isFullScreen ? fscreen.exitFullscreen() : fscreen.requestFullscreen(document.documentElement);
  }, [isFullScreen]);

  return (
    <div className={className}>
      <OverlayTrigger
        placement="left"
        delay={{ show: 1000, hide: 400 }}
        overlay={
          <Popover>
            <Popover.Content>
              {T.translate(isFullScreen ? 'case.exitFullScreen' : 'case.expandToFullScreen')}
            </Popover.Content>
          </Popover>
        }
      >
        <Nav.Link className="d-inline-flex" onClick={toggleFullScreen}>
          <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
          <p className="d-none  ml-3">{T.translate('case.theatre')}</p>
        </Nav.Link>
      </OverlayTrigger>
    </div>
  );
};
