import React from 'react';
import T from 'i18n';
import { Chip, IconButton, Stack, TextField, Typography } from '@mui/material';
import { FilterListOutlined, SendOutlined, TuneOutlined } from '@mui/icons-material';
import { useFetchMatches } from '../redux/fetchMatches';
import { useGenerateAnswers } from '../redux/generateAnswers';
import SearchOptions from './SearchOptions';
import RecentSearchesList from './RecentSearchesList';
import TrialBundlesMenu from './TrialBundlesMenu';
import MatchesMenu from './MatchesMenu';

interface SearchInputProps {
  filters: any;
  handleChangeFilters: Function;
  handleSearch: Function;
  isAnswersMode?: boolean;
  isNewSearch?: boolean;
  containsMatches?: boolean;
  matches?: Array<any>;
}

const SearchFiltersChip = ({ filters, isAnswersMode }: any) => {
  return (
    <>
      {filters.location.length === 0 && filters.files.length === 0 && 'Filter'}
      {!isAnswersMode && filters.location.length > 0 && (
        <Typography sx={{ display: 'inline', color: 'GrayText' }}>
          <b>{T.translate('case.folders')}</b> : {filters.location[0].name}
          {filters.location.length > 1 ? ` & ${filters.location.length - 1} more ...` : ''}{' '}
        </Typography>
      )}
      {!isAnswersMode && filters.files.length > 0 && (
        <Typography sx={{ display: 'inline', color: 'GrayText' }}>
          <b>{T.translate('case.files')}</b> : {filters.files[0].id}
          {filters.files.length > 1 ? ` & ${filters.files.length - 1} more ...` : ''}
        </Typography>
      )}
      {isAnswersMode && filters.location.length > 0 && (
        <Typography sx={{ display: 'inline', color: 'GrayText' }}>
          <b>{T.translate('case.matches')}</b> : {filters.location[0].name}
          {filters.location.length > 1 ? ` & ${filters.location.length - 1} more ...` : ''}{' '}
        </Typography>
      )}
    </>
  );
};

export default function SearchInput({
  filters,
  handleChangeFilters,
  handleSearch,
  isAnswersMode,
  isNewSearch,
  containsMatches,
  matches,
}: SearchInputProps) {
  const inpRef = React.useRef<HTMLInputElement>(null);
  const { fetchDocumentsPending } = useFetchMatches();
  const { generateAnswersPending } = useGenerateAnswers();

  const [searchOptionsAnchorEl, setSearchOptionsAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const openSearchOptions = Boolean(searchOptionsAnchorEl);
  const [recentSearchesAnchorEl, setRecentSearchesAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const openRecentSearches = Boolean(recentSearchesAnchorEl);
  const [filtersMenu, setFiltersMenu] = React.useState<null | HTMLElement>(null);
  const openFiltersMenu = Boolean(filtersMenu);

  const handleRecentSearchesMenuClose = () => {
    setRecentSearchesAnchorEl(null);
  };

  return (
    <div
      style={{
        border: '1px  solid #212529',
        borderRadius: '0.5rem',
        height: '8rem',
        padding: '0.5rem',
      }}
    >
      <TextField
        inputRef={inpRef}
        id="ai-search-input"
        variant={'standard'}
        fullWidth
        placeholder={
          isNewSearch
            ? T.translate('case.topicInputPlaceholder')
            : T.translate('case.questionInputPlaceholder')
        }
        sx={{
          borderRadius: '1rem',
          '.MuiOutlinedInput-root': { p: 0 },
          padding: '0.5rem',
        }}
        value={filters.phrase}
        onChange={e => {
          handleChangeFilters({ phrase: e.target.value });
          if (e.target.value && e.target.value.length < 3 && !openRecentSearches) {
            setRecentSearchesAnchorEl(e.currentTarget);
          } else if (openRecentSearches && e.target.value.length >= 3) {
            setRecentSearchesAnchorEl(null);
          }
        }}
        onKeyDown={(e: { key: string; stopPropagation: Function; target: any }) => {
          e.stopPropagation();
          if (e.target.value && e.key === 'Enter') {
            handleSearch();
          }
        }}
        onClick={() => inpRef.current?.focus()}
        autoFocus
        autoComplete="off"
        disabled={fetchDocumentsPending || generateAnswersPending}
        InputProps={{ disableUnderline: true, sx: { fontSize: '1.3rem' } }}
      />
      <div
        style={{
          marginTop: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            icon={<FilterListOutlined />}
            sx={{ backgroundColor: '#F7F7F8', fontSize: '1rem' }}
            label={<SearchFiltersChip filters={filters} isAnswersMode={isAnswersMode} />}
            clickable
            onClick={e => setFiltersMenu(e.currentTarget)}
          />
          <Chip
            size="small"
            icon={<TuneOutlined />}
            sx={{ backgroundColor: '#F7F7F8', fontSize: '1rem' }}
            label="Options"
            clickable
            onClick={e => setSearchOptionsAnchorEl(e.currentTarget)}
          />
        </Stack>
        <IconButton disabled={filters.phrase?.length === 0} onClick={() => handleSearch()}>
          <SendOutlined />
        </IconButton>
      </div>
      {openSearchOptions && searchOptionsAnchorEl && (
        <SearchOptions
          open={openSearchOptions}
          anchorEl={searchOptionsAnchorEl}
          handleClose={() => {
            setSearchOptionsAnchorEl(null);
            const searchInp = document.getElementById('ai-search-input');
            if (searchInp) {
              searchInp.click();
            }
          }}
        />
      )}
      {filtersMenu &&
        openFiltersMenu &&
        (isAnswersMode ? (
          <MatchesMenu
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            filtersMenu={filtersMenu}
            openFiltersMenu={openFiltersMenu}
            setFiltersMenu={setFiltersMenu}
            containsMatches={containsMatches}
            matches={matches}
          />
        ) : (
          <TrialBundlesMenu
            value={filters.location}
            onChange={(newValue: any) => handleChangeFilters({ location: newValue, files: [] })}
            anchorEl={filtersMenu}
            isOpen={openFiltersMenu}
            onClose={() => setFiltersMenu(null)}
          />
        ))}
      {openRecentSearches && recentSearchesAnchorEl && (
        <RecentSearchesList
          handleRecentSearchesMenuClose={handleRecentSearchesMenuClose}
          anchorEl={recentSearchesAnchorEl}
          openRecentSearchesMenu={openRecentSearches}
          isAnswersMode={isAnswersMode}
          handleChangeFilters={handleChangeFilters}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
}
