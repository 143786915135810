import React, { Component } from 'react';
import T from 'i18n';
import TableWrapper from './TableWrapper';
import AnnotationsTableWrapper from './AnnotationsTableWrapper';

export default class QuickLinksPage extends Component {
  static propTypes = {};

  render() {
    return this.props.quickLink &&
      (this.props.quickLink === 'tags' ||
        this.props.quickLink === 'annotations' ||
        this.props.quickLink === 'timelines') ? (
      <AnnotationsTableWrapper category={T.translate('case.quickLinks')} />
    ) : (
      <TableWrapper category={T.translate('case.quickLinks')} />
    );
  }
}
