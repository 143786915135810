import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CONFERENCE_FETCH_HEARING_ROOM_SESSION_BEGIN,
  CONFERENCE_FETCH_HEARING_ROOM_SESSION_SUCCESS,
  CONFERENCE_FETCH_HEARING_ROOM_SESSION_FAILURE,
  CONFERENCE_FETCH_HEARING_ROOM_SESSION_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const fetchHearingRoomSession = createPromiseAction(
  CONFERENCE_FETCH_HEARING_ROOM_SESSION_BEGIN,
);

export function dismissFetchHearingRoomSessionFeedback() {
  return {
    type: CONFERENCE_FETCH_HEARING_ROOM_SESSION_DISMISS_FEEDBACK,
  };
}

export function* doFetchHearingRoomSession(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.get, `/t2/cases/${caseId}/hearing-room-state`);

  if (res && res.error) {
    yield put({
      type: CONFERENCE_FETCH_HEARING_ROOM_SESSION_FAILURE,
      feedback: {
        message: 'feedback.fetchHearingRoomSessionFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CONFERENCE_FETCH_HEARING_ROOM_SESSION_SUCCESS,
    data: {
      hearingRoomSessionStarted: res.sessionState === 'started',
      hearingRoomMessage: res.message,
    },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchHearingRoomSession() {
  yield takeLatest(fetchHearingRoomSession, withCurrentCaseId(doFetchHearingRoomSession));
}

export function useFetchHearingRoomSession() {
  const dispatch = useDispatch();

  const {
    fetchHearingRoomSessionPending,
    fetchHearingRoomSessionFeedback,
    hearingRoomSessionStarted,
    hearingRoomMessage,
  } = useSelector(
    state => ({
      hearingRoomMessage: state.conference.hearingRoomMessage,
      hearingRoomSessionStarted: state.conference.hearingRoomSessionStarted,
      fetchHearingRoomSessionPending: state.conference.fetchHearingRoomSessionPending,
      fetchHearingRoomSessionFeedback: state.conference.fetchHearingRoomSessionFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchHearingRoomSession(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchHearingRoomSessionFeedback());
  }, [dispatch]);

  return {
    hearingRoomMessage,
    hearingRoomSessionStarted,
    fetchHearingRoomSession: boundAction,
    fetchHearingRoomSessionPending,
    fetchHearingRoomSessionFeedback,
    dismissFetchHearingRoomSessionFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONFERENCE_FETCH_HEARING_ROOM_SESSION_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchHearingRoomSessionPending: true,
        fetchHearingRoomSessionFeedback: null,
      };

    case CONFERENCE_FETCH_HEARING_ROOM_SESSION_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchHearingRoomSessionPending: false,
        fetchHearingRoomSessionFeedback: action.feedback,
        hearingRoomSessionStarted: action.data.hearingRoomSessionStarted,
        hearingRoomMessage: action.data.hearingRoomMessage,
      };

    case CONFERENCE_FETCH_HEARING_ROOM_SESSION_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchHearingRoomSessionPending: false,
        fetchHearingRoomSessionFeedback: action.feedback,
      };

    case CONFERENCE_FETCH_HEARING_ROOM_SESSION_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchHearingRoomSessionFeedback: null,
      };

    default:
      return state;
  }
}
