import {
  CASE_ADD_SYSTEM_TEST_BEGIN,
  CASE_ADD_SYSTEM_TEST_SUCCESS,
  CASE_ADD_SYSTEM_TEST_FAILURE,
  CASE_ADD_SYSTEM_TEST_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const addSystemTest = createPromiseAction(CASE_ADD_SYSTEM_TEST_BEGIN);

export function dismissAddSystemTestFeedback() {
  return {
    type: CASE_ADD_SYSTEM_TEST_DISMISS_FEEDBACK,
  };
}

export function* doAddSystemTest(action) {
  const { caseId, results, noFeedback } = action.payload;

  let res = yield call(api.post, `/cases/${caseId}/diagnostics`, results);

  if (res && res.error) {
    yield put({
      type: CASE_ADD_SYSTEM_TEST_FAILURE,
      ...(!noFeedback && {
        feedback: {
          message: 'feedback.addSystemTestFailure',
          error: res.error,
          retryAction: action,
        },
      }),
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_ADD_SYSTEM_TEST_SUCCESS,
    ...(!noFeedback && {
      feedback: {
        message: 'feedback.addSystemTestSuccess',
      },
    }),
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchAddSystemTest() {
  yield takeEvery(addSystemTest, withCurrentCaseId(doAddSystemTest));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_ADD_SYSTEM_TEST_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        addSystemTestPending: true,
        addSystemTestFeedback: null,
      };

    case CASE_ADD_SYSTEM_TEST_SUCCESS:
      // The request is success
      return {
        ...state,
        addSystemTestPending: false,
        addSystemTestFeedback: action.feedback,
      };

    case CASE_ADD_SYSTEM_TEST_FAILURE:
      // The request is failed
      return {
        ...state,
        addSystemTestPending: false,
        addSystemTestFeedback: action.feedback,
      };

    case CASE_ADD_SYSTEM_TEST_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        addSystemTestFeedback: null,
      };

    default:
      return state;
  }
}
