import {
  COMMON_UPDATE_USER_SETTINGS_BEGIN,
  COMMON_UPDATE_USER_SETTINGS_SUCCESS,
  COMMON_UPDATE_USER_SETTINGS_FAILURE,
  COMMON_UPDATE_USER_SETTINGS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const updateUserSettings = createPromiseAction(COMMON_UPDATE_USER_SETTINGS_BEGIN);

export function dismissUpdateUserSettingsFeedback() {
  return {
    type: COMMON_UPDATE_USER_SETTINGS_DISMISS_FEEDBACK,
  };
}

export function* doUpdateUserSettings(action) {
  const {
    payload: { userSettings },
  } = action;

  let res = yield call(api.put, '/user-settings', userSettings);

  if (res && res.error) {
    yield put({
      type: COMMON_UPDATE_USER_SETTINGS_FAILURE,
      feedback: {
        message: 'feedback.updateUserSettingsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_UPDATE_USER_SETTINGS_SUCCESS,
    data: { userSettings: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateUserSettings() {
  yield takeLatest(updateUserSettings, doUpdateUserSettings);
}

export function useUpdateUserSettings() {
  const dispatch = useDispatch();

  const { userSettings, updateUserSettingsPending, updateUserSettingsFeedback } = useSelector(
    state => ({
      userSettings: state.common.userSettings,
      updateUserSettingsPending: state.common.updateUserSettingsPending,
      updateUserSettingsFeedback: state.common.updateUserSettingsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateUserSettings(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateUserSettingsFeedback());
  }, [dispatch]);

  return {
    userSettings,
    updateUserSettings: boundAction,
    updateUserSettingsPending,
    updateUserSettingsFeedback,
    dismissUpdateUserSettingsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_UPDATE_USER_SETTINGS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateUserSettingsPending: true,
        updateUserSettingsFeedback: null,
      };

    case COMMON_UPDATE_USER_SETTINGS_SUCCESS:
      // The request is success
      return {
        ...state,
        updateUserSettingsPending: false,
        updateUserSettingsFeedback: action.feedback,
        userSettings: action.data.userSettings,
      };

    case COMMON_UPDATE_USER_SETTINGS_FAILURE:
      // The request is failed
      return {
        ...state,
        updateUserSettingsPending: false,
        updateUserSettingsFeedback: action.feedback,
      };

    case COMMON_UPDATE_USER_SETTINGS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateUserSettingsFeedback: null,
      };

    default:
      return state;
  }
}
