import React from 'react';
import { useDragLayer } from 'react-dnd';

const moveDragRowFirst = (documentIds: any, dragRow: any) => {
  // TO-DO Nested search, this is covering just one level
  const indexOfTheDragRow = documentIds.findIndex(
    (compositeKey: any) => compositeKey === dragRow.compositeKey || compositeKey === dragRow.id,
  );

  return [
    dragRow.id,
    ...((indexOfTheDragRow > 0 && documentIds.slice(0, indexOfTheDragRow)) || []),
    ...(indexOfTheDragRow < documentIds.length && documentIds.slice(indexOfTheDragRow + 1)),
  ];
};

const RowsDragPreview = ({ documentIds, dragRow }: any) => {
  return (
    <div style={{ display: 'flex' }}>
      <div>
        {moveDragRowFirst(documentIds, dragRow).map((id, i) => (
          <div
            key={i}
            style={{
              zIndex: documentIds.length - i,
              width: 'auto',
              transform: `translateY(${i * 5}px)`,
              background: '#7BA0F4',
              borderRadius: '1rem',
              color: 'white',
              padding: '0.5rem',
              paddingBottom: '0.25rem',
              paddingTop: '0.25rem',
            }}
          >
            {id}
          </div>
        ))}
      </div>
      <span
        style={{
          background: 'red',
          borderRadius: '1rem',
          marginLeft: '0.5rem',
          height: '2rem',
          width: '2rem',
          padding: '0.25rem',
          textAlign: 'center',
          color: 'white',
        }}
      >
        {documentIds.length}
      </span>
    </div>
  );
};

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
};

const getItemStyles = (currentOffset: any) => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }
  const { x, y } = currentOffset;
  return {
    transform: `translate(${x}px, ${y}px)`,
    filter: 'drop-shadow(0 2px 12px rgba(0,0,0,0.45))',
  };
};

export default () => {
  const { item, itemType, currentOffset, isDragging } = useDragLayer((monitor: any) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    // initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const renderItem = () => {
    switch (itemType) {
      case 'DOCUMENT':
      case 'ANNOTATION':
      case 'EVENT':
        return <RowsDragPreview {...item} />;
      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <div className="case-custom-drag-layer" style={layerStyles as any}>
      <div style={getItemStyles(currentOffset)}>{renderItem()}</div>
    </div>
  );
};
