export const validFileExts = [
  "pdf", "doc", "dot", "docx", "docm", "dotx", "dotm", "rtf", "xls", "xlt", "xlsx", 
  "xlsm", "xltx", "xltm", "ppt", "pot", "pps", "pptx", "pptm", "potx", "potm", 
  "ppsx", "ppsm", "odt", "ott", "fodt", "ods", "ots", "fods", "odp", "otp", 
  "fodp", "odf", "odg", "otg", "fodg", "dwg", "dxf", "dwf", "dgn", "html", 
  "htm", "xhtml", "xhtm", "msg", "eml", "txt", "csv", "dcm", "dicom", "dcim", 
  "dicm", "tif", "tiff", "jpg", "jpeg", "jp2", "jpc", "gif", "png", "psd", "psb", 
  "bmp", "dib", "pct", "pic", "pict", "wmf", "emf", "pcx", "dcx", "ras", "pcd", 
  "tga", "tpic", "cal", "cals", "ico", "cur", "ncr", "xwd", "sgi", "wbmp", "sct", 
  "wpg", "xbm", "pbm", "pgm", "ppm", "img", "cut", "mp4"
];

export const validFileExtsShareOnly = ["lp", "do", "zip", "xer", "plf", "inc", "mpp"];