import {
  CASE_UPDATE_CONFIDENTIALITY_RING_BEGIN,
  CASE_UPDATE_CONFIDENTIALITY_RING_SUCCESS,
  CASE_UPDATE_CONFIDENTIALITY_RING_FAILURE,
  CASE_UPDATE_CONFIDENTIALITY_RING_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import { replaceItemImmutable } from 'utils/arrays';

export const updateConfidentialityRing = createPromiseAction(
  CASE_UPDATE_CONFIDENTIALITY_RING_BEGIN,
);

export function dismissUpdateConfidentialityRingFeedback() {
  return {
    type: CASE_UPDATE_CONFIDENTIALITY_RING_DISMISS_FEEDBACK,
  };
}

export function* doUpdateConfidentialityRing(action) {
  const { caseId, fileId, ring } = action.payload;

  let res = yield call(api.put, `/cases/${caseId}/files/${fileId}/rings/${ring.id}`, ring.ring);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_CONFIDENTIALITY_RING_FAILURE,
      feedback: {
        message: 'feedback.updateConfidentialityRingFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_CONFIDENTIALITY_RING_SUCCESS,
    data: { fileRings: res, fileId },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateConfidentialityRing() {
  yield takeLatest(updateConfidentialityRing, withCurrentCaseId(doUpdateConfidentialityRing));
}

export function useUpdateConfidentialityRing() {
  const dispatch = useDispatch();

  const { updateConfidentialityRingPending, updateConfidentialityRingFeedback } = useSelector(
    state => ({
      updateConfidentialityRingPending: state.case.updateConfidentialityRingPending,
      updateConfidentialityRingFeedback: state.case.updateConfidentialityRingFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateConfidentialityRing(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateConfidentialityRingFeedback());
  }, [dispatch]);

  return {
    updateConfidentialityRing: boundAction,
    updateConfidentialityRingPending,
    updateConfidentialityRingFeedback,
    dismissUpdateConfidentialityRingFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_CONFIDENTIALITY_RING_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateConfidentialityRingPending: true,
        updateConfidentialityRingFeedback: null,
      };

    case CASE_UPDATE_CONFIDENTIALITY_RING_SUCCESS:
      // The request is success
      const getIndex = documentId => state.documents.findIndex(({ id }) => id === documentId);
      return {
        ...state,
        updateConfidentialityRingPending: false,
        documents: addSortNumberToDocuments(
          replaceItemImmutable(
            state.documents,
            {
              ...state.documents[getIndex(action.data.fileId)],
              confidentialityRing: action.data.fileRings.ring,
            },
            getIndex(action.data.fileId),
          ),
          action.data.zeroBasedIndex,
        ),
        updateConfidentialityRingFeedback: action.feedback,
      };

    case CASE_UPDATE_CONFIDENTIALITY_RING_FAILURE:
      // The request is failed
      return {
        ...state,
        updateConfidentialityRingPending: false,
        updateConfidentialityRingFeedback: action.feedback,
      };

    case CASE_UPDATE_CONFIDENTIALITY_RING_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        updateConfidentialityRingFeedback: null,
      };

    default:
      return state;
  }
}
