import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Table from 'features/common/Table';
import SortHeader from 'features/case/TableCase/SortHeader';

interface HealthCheckTableProps {
  healthCheckResult: any;
  tableHeight: string;
  outsideTableMargin: string;
}

const columnSelector = () => {
  return [
    {
      id: 'tab',
      Header: () => <SortHeader text="case.tab" />,
      Cell: (doc: any) => doc.tab,
      minWidth: '50px',
      width: '10rem',
    },
    {
      id: 'id',
      Header: () => <SortHeader text="case.documentId" />,
      Cell: (doc: any) => doc.id || '',
      minWidth: '50px',
      width: '10rem',
    },
    {
      id: 'name',
      Header: () => <SortHeader text="case.name" />,
      Cell: (doc: any) => doc.name || '',
      minWidth: '150px',
      width: 'calc(100% - 34rem)',
    },
    {
      id: 'pages',
      Header: () => <SortHeader text="case.pages" />,
      Cell: (doc: any) => doc.pageCount || '',
      minWidth: '30px',
      width: '7rem',
    },
    {
      id: 'type',
      Header: () => <SortHeader text="case.type" />,
      Cell: (doc: any) => doc.type || '',
      minWidth: '30px',
      width: '7rem',
    },
  ];
};

const HealthCheckTable = ({
  healthCheckResult,
  tableHeight,
  outsideTableMargin,
}: HealthCheckTableProps) => {
  const [data, setData] = useState([]);
  const columns = columnSelector();
  useEffect(() => {
    const updatedData = healthCheckResult.map((doc: any, idx: number) => {
      return {
        id: idx,
        ...doc,
      };
    });
    setData(updatedData);
  }, [healthCheckResult]);
  return (
    healthCheckResult && (
      <Card
        style={{
          height: tableHeight,
          margin: outsideTableMargin,
        }}
      >
        <Table
          key={Math.random()}
          data={data}
          columns={columns}
          filter={null}
          filterByColumns={[]}
          onDoubleClick={() => {}}
          sortMode={false}
          onRowSelected={() => {}}
          updateSort={() => {}}
        />
      </Card>
    )
  );
};

export default HealthCheckTable;
