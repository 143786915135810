import React, { PureComponent, useState, useRef, useContext } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from './redux/actions';
import T from 'i18n';
import {
  faUserFriends,
  faBook,
  faPlusCircle,
  faTrashAlt,
  faEdit,
  faShareAlt,
  faLock,
  faArrowCircleUp,
  faArrowCircleDown,
  faHistory,
  faSync,
  faLayerGroup,
  faListOl,
  faLaptopMedical,
  faFileExcel,
  faDownload,
  faClone,
  faExchangeAlt,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { createSelector } from 'reselect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion';

import SidebarItem from './SidebarItem';
import Sidebar from 'features/common/Sidebar';
import { folderModalActions } from './enums';
import {
  selectPermissions,
  selectSpecificParam,
  selectIsAdmin,
  selectCurrentFolder,
  selectMatchedUrl,
  selectCurrentFile,
  selectMatchedPath,
  selectParams,
  selectCurrentCaseId,
  selectCustomContainers,
  selectContainers,
  selectFolders,
  selectUserSettingPresentMode,
  selectUserSettingSearchMode,
  selectUserSettingDocFinder,
  selectUserSettingAllDocs,
  selectDeepSearch,
  selectCrossRef,
  selectTagsFeature,
  selectCurrentCaseTeamId,
  selectUserSettingAllAnnotations,
  selectUserSettingPageRefs,
  selectUserAiTools,
  selectShowAllDocuments,
  selectDocuments,
  selectGroups,
  selectShowGroupBundles,
} from '../../common/selectors';
import FolderModal from './FolderModal';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import AccordionContext from 'react-bootstrap/AccordionContext';
import SelectableContext from 'react-bootstrap/SelectableContext';
import history from 'common/history';
import { generatePath } from 'react-router-dom';
import { findFolderDepth, findIndexOfDeepNestedFolder } from 'utils/arrays';
import { formatLabel } from 'utils/formatLabel';
import { DownloadModal, ModalMerge } from './DocumentsModal';
import DraggableBundles from './DraggableBundles';
import * as actionsV from 'features/viewing/redux/actions';
import saveAs from 'save-as';
import _ from 'lodash';

export function CustomToggle({ children, eventKey, className }) {
  const currentEventKey = useContext(AccordionContext);
  const isOpened = currentEventKey === eventKey;

  let timeout;
  const [mouseOver, setMouseOver] = useState(false);
  const decoratedOnClick = useAccordionToggle(eventKey);

  const mouseOverRef = useRef(mouseOver);
  mouseOverRef.current = mouseOver;

  const openMenuIfMouseOver = () => mouseOverRef.current && !isOpened && decoratedOnClick();

  const handleOnMouseEnter = () => {
    setMouseOver(true);
    timeout = setTimeout(openMenuIfMouseOver, 1000);
  };

  const handleOnMouseLeave = () => {
    setMouseOver(false);
    clearTimeout(timeout);
  };

  return (
    <button
      type="button"
      className={className}
      onClick={decoratedOnClick}
      onDragEnter={handleOnMouseEnter}
      onDragLeave={handleOnMouseLeave}
      //onKeyUp={e => e && e.key === 'Enter' && decoratedOnClick()}
    >
      {React.Children.map(children, element => {
        if (!element) return;
        if (element.type === 'span')
          return React.cloneElement(element, {
            style: { pointerEvents: 'none' },
          });
        else return element;
      })}
    </button>
  );
}

class SidebarCase extends PureComponent {
  state = {
    isLocked: true,
    teamBundlesLocked: true,
    privateBundlesLocked: true,
    showModal: false,
    showMergeModal: false,
    showDownloadModal: false,

    modalAction: null,
    folder: null,
  };

  componentDidMount() {
    this.props.actions.fetchContainers();
    if (this.props.showGroupBundles) this.props.actions.fetchGroups();
  }

  toggleLock = () => {
    this.setState(prevState => ({ isLocked: !prevState.isLocked }));
  };
  toggleTeamBundlesLock = () => {
    this.setState(prevState => ({ teamBundlesLocked: !prevState.teamBundlesLocked }));
  };

  togglePrivateBundlesLock = () => {
    this.setState(prevState => ({ privateBundlesLocked: !prevState.privateBundlesLocked }));
  };

  handleClose = () =>
    this.setState({
      showModal: false,
      showMergeModal: false,
      showDownloadModal: false,
      folder: null,
    });

  handleShow = (modalAction, folder) => {
    if (modalAction === folderModalActions.refresh) {
      this.handleSave({}, modalAction, folder);
    } else if (
      modalAction === folderModalActions.mergeTrialbook ||
      modalAction === folderModalActions.mergeBundle
    ) {
      this.setState({ showMergeModal: true, modalAction, folder });
    } else if (modalAction === folderModalActions.downloadFolder) {
      this.setState({ showDownloadModal: true, modalAction, folder });
    } else {
      this.setState({ showModal: true, modalAction, folder });
    }
  };

  handleDefaultBehav = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleUpdateFolder = (res, folderToUpdate) => {
    const { currentFile, caseId } = this.props;
    let { matchedPath, matchedUrl } = this.props;
    const { folder4, folder3, folder2, folder1, folder } = this.props.params;
    const isChildFolder =
      findIndexOfDeepNestedFolder([folderToUpdate], res.currentFolder) < 0 ? false : true;
    const isChildOfMovingFolder = isChildFolder && res.currentFolder.type !== folderToUpdate.type;
    const isCurrentFolderIndexFolder = res.currentFolder.name === 'index';
    const isCurrentIndexFolderImmediateChildOfFolderToUpdate =
      isCurrentFolderIndexFolder &&
      folderToUpdate.folders &&
      folderToUpdate.folders.length > 0 &&
      res.currentFolder.id === folderToUpdate.folders[0].id;
    const isCurrentFolder =
      res.currentFolder.name === 'index'
        ? res.currentFolder.name === folderToUpdate.name &&
          res.currentFolder.id !== folderToUpdate.id
        : res.currentFolder.id === folderToUpdate.id;
    if (
      [
        'bundle',
        'team-bundle',
        'private-bundle',
        'court-bundle',
        'group-bundle',
        'trialbook',
      ].includes(res.type) &&
      (isCurrentFolder || isChildFolder)
    ) {
      const updateFolderMeta =
        (isCurrentFolder || isChildFolder) &&
        !isChildOfMovingFolder &&
        (res.currentFolder.name !== folderToUpdate.name ||
          res.currentFolder.zeroBasedIndex !== folderToUpdate.zeroBasedIndex ||
          res.currentFolder.generateIndexFolder !== folderToUpdate.generateIndexFolder ||
          res.currentFolder.globalPaging !== folderToUpdate.globalPaging ||
          res.currentFolder.globalPagePrefix !== folderToUpdate.globalPagePrefix ||
          res.currentFolder.predictiveIndex !== folderToUpdate.predictiveIndex ||
          res.currentFolder.agreed !== folderToUpdate.agreed);
      if (!updateFolderMeta) {
        const isTrialBook = matchedPath.includes('trialbundles');
        const isTeamBundle = matchedPath.includes('teamBundle');
        const isPrivateBundle = matchedPath.includes('privateBundle');
        const isGroupBundle = matchedPath.includes('groupBundle');
        if (res.type === 'court-bundle') {
          matchedPath = matchedPath.replace(
            isTeamBundle ? ':teamBundle' : isPrivateBundle ? ':privateBundle' : ':bundle',
            ':courtBundle',
          );
          matchedPath = matchedPath.replace(
            isTeamBundle ? 'teamBundles' : isPrivateBundle ? 'privateBundles' : 'bundles',
            'court-bundles',
          );
        } else if (res.type === 'group-bundle') {
          matchedPath = matchedPath.replace(':teamBundle', ':groupBundle');
          matchedPath = matchedPath.replace('teamBundles', 'group-bundles');
        } else if (res.type === 'bundle') {
          matchedPath = matchedPath.replace(isTrialBook ? ':trialbook' : ':courtBundle', ':bundle');
          matchedPath = matchedPath.replace(
            isTrialBook ? 'trialbundles' : 'court-bundles',
            'bundles',
          );
        } else if (res.type === 'team-bundle') {
          matchedPath = matchedPath.replace(
            isTrialBook
              ? ':trialbook'
              : isPrivateBundle
              ? ':privateBundle'
              : isGroupBundle
              ? ':groupBundle'
              : ':courtBundle',
            ':teamBundle',
          );
          matchedPath = matchedPath.replace(
            isTrialBook
              ? 'trialbundles'
              : isPrivateBundle
              ? 'privateBundles'
              : isGroupBundle
              ? 'group-bundles'
              : 'court-bundles',
            'teamBundles',
          );
        } else if (res.type === 'trialbook' && !isTrialBook) {
          matchedPath = matchedPath.replace(
            isTeamBundle ? ':teamBundle' : isPrivateBundle ? ':privateBundle' : ':bundle',
            ':trialbook',
          );
          matchedPath = matchedPath.replace(
            isTeamBundle ? 'teamBundles' : isPrivateBundle ? 'privateBundles' : 'bundles',
            'trialbundles',
          );
        }
        const path = generatePath(matchedPath, {
          case: caseId,
          ...(res.type === 'trialbook' && { trialbook: res.code }),
          ...(res.type === 'court-bundle' && { courtBundle: res.code }),
          ...(res.type === 'group-bundle' && { groupBundle: res.code }),
          ...(res.type === 'bundle' && { bundle: res.code }),
          ...(res.type === 'team-bundle' && { teamBundle: res.code }),
          ...(res.type === 'private-bundle' && { privateBundle: res.code }),
          folder4,
          folder3,
          folder2,
          folder1,
          folder,
          file: currentFile,
        });
        history.replace(path);
      } else {
        let path;
        if (isCurrentIndexFolderImmediateChildOfFolderToUpdate)
          path = matchedUrl
            .replace(folderToUpdate.code, res.code)
            .replace(res.currentFolder.code, res.folders[0].code);
        else path = matchedUrl.replace(folderToUpdate.code, res.code);
        history.replace(path);
      }
    }
  };

  handleSave = ({ name, ...restParams }, action, folder = {}) => {
    switch (action) {
      case folderModalActions.createPrivateBundle:
      case folderModalActions.createTeamBundle:
      case folderModalActions.createBundle:
        const type =
          action === folderModalActions.createPrivateBundle
            ? 'private-bundle'
            : action === folderModalActions.createTeamBundle
            ? 'team-bundle'
            : 'bundle';
        this.props.actions
          .addFolder({
            name,
            type,
            parentFolderId: folder.id,
          })
          .then(res => {
            if (this.props.currentFolder.id && this.props.currentFolder.id === folder.id) {
              const path = this.props.matchedUrl.concat(`/${res.code}`);
              history.replace(path);
            }
            this.props.actions.fetchContainer({ folder: { type } });
          })
          .catch(error => {
            console.error('Error occured while adding folder', error);
          });
        break;
      case folderModalActions.createTrialbook:
        this.props.actions
          .addFolder({
            name,
            type: 'trialbook',
            parentFolderId: folder.id,
          })
          .then(() => this.props.actions.fetchContainer({ folder: { type: 'trialbook' } }));
        break;
      case folderModalActions.updateBundle:
      case folderModalActions.updateTrialbook:
        const updateAgreed = folder.agreed !== restParams.agreed;
        this.props.actions
          .updateFolder({ ...folder, name, ...restParams, ...(updateAgreed && updateAgreed) })
          .then(res => {
            const isChildOfMovingFolder =
              findIndexOfDeepNestedFolder([{ ...folder, name, ...restParams }], res.currentFolder) <
              0
                ? false
                : true;
            this.handleUpdateFolder(res, { ...folder, name, ...restParams });
            if (
              (folder.id === res.currentFolder.id || isChildOfMovingFolder) &&
              folder.name === name
            ) {
              this.props.actions.fetchDocuments({});
            }
            this.props.actions.fetchContainer({ folder });
          })
          .catch(error => {
            console.error('Error occured while updating folder', error);
          });
        break;
      case folderModalActions.moveToTrialBooks:
      case folderModalActions.removeFromTrialBooks:
      case folderModalActions.moveToCourtBundles:
      case folderModalActions.removeFromCourtBundles:
      case folderModalActions.share:
      case folderModalActions.shareWithGroup:
      case folderModalActions.removeFromGroupBundles:
        this.props.actions
          .updateFolder(
            action === folderModalActions.shareWithGroup
              ? { ...folder, groupId: restParams.groupId }
              : folder,
          )
          .then(res => {
            this.handleUpdateFolder(res, folder);
            this.props.actions.fetchContainers();
          })
          .catch(error => {
            console.error('Error occured while updating folder', error);
          });
        break;
      case folderModalActions.deleteBundle:
      case folderModalActions.deleteTrialbook:
      case folderModalActions.deleteAll:
        const isCurrentFolder = folder.id === this.props.currentFolder.id;
        this.props.actions.deleteFolder(folder).then(() => {
          this.props.actions.fetchContainer({ folder });
          if (isCurrentFolder && action === folderModalActions.deleteAll)
            this.props.actions.clearDocuments();
        });
        break;
      case folderModalActions.refresh:
        const { quickLink } = this.props.params;
        const isAllAnnotations = this.props.matchedUrl.includes('all-annotations');
        this.props.actions.clearDocuments();
        if (quickLink === 'tags' || isAllAnnotations)
          this.props.actions.fetchAnnotations({
            tagId: this.props.currentFolder.id,
            isAllAnnotations,
          });
        else this.props.actions.fetchDocuments({});
        if (folder && folder.id && !quickLink)
          this.props.actions.fetchFolder({ folderId: folder.id });
        break;
      case folderModalActions.exportFolder:
        this.props.actions.exportFolder({ folderId: folder.id }).then(response => {
          const timeStamp = `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getMonth() +
            1}--${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
          const splitFileName = response.fileName.split('.csv');
          response.blob.fileName = `${splitFileName[0]}_${timeStamp}.csv`;

          return response && saveAs(response.blob, `${response.blob.fileName || folder.name}`);
        });
        break;
      case folderModalActions.createTimeline:
        this.props.actions.createTimelines({ timelines: { name } }).then(() => {
          this.props.actions.fetchContainer({ folder: { type: 'timelines' } });
        });
        break;
      case folderModalActions.updateTimeline:
        this.props.actions
          .updateTimelines({ timeline: { ...this.props.timelines, name } })
          .then(res => {
            if (res.code && res.code !== folder.code)
              history.push(history.location.pathname.replace(folder.code, res.code));
            this.props.actions.fetchContainer({ folder: { type: 'timelines' } });
          });
        break;
      case folderModalActions.deleteTimeline:
        this.props.actions.deleteTimelines({ timelinesId: folder.id }).then(() => {
          if (folder.id === this.props.currentFolder.id)
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) + `/case/${this.props.caseId}`,
            );
          this.props.actions.fetchContainer({ folder: { type: 'timelines' } });
        });
        break;
      case folderModalActions.createTag:
        this.props.actions.createTag({ tag: { name } }).then(() => {
          this.props.actions.fetchContainer({ folder: { type: 'tags' } });
        });
        break;
      case folderModalActions.updateTag:
        this.props.actions.updateTag({ tag: { ...folder, name } }).then(res => {
          if (res.code && res.code !== folder.code)
            history.push(history.location.pathname.replace(folder.code, res.code));
          this.props.actions.fetchContainer({ folder: { type: 'tags' } });
        });
        break;
      case folderModalActions.deleteTag:
        this.props.actions.deleteTag({ tagId: folder.id }).then(() => {
          if (folder.id === this.props.currentFolder.id)
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) + `/case/${this.props.caseId}`,
            );
          this.props.actions.fetchContainer({ folder: { type: 'tags' } });
        });
        break;
      case folderModalActions.burnCrossRef:
        this.props.actions.burnCrossReferences({ folderId: folder.id });
        break;
      case folderModalActions.reIndex:
        this.props.actions.reIndexBundle({ folderId: folder.id, data: restParams });
        break;
      case folderModalActions.exportTimeline:
        this.props.actions.exportTimeline({ timelineId: folder.id }).then(response => {
          return response && saveAs(response.blob, response.fileName || folder.name);
        });
        break;
      case folderModalActions.clone:
        this.props.actions
          .addFolder({ name, cloneFolderId: folder.id, ...restParams })
          .then(() => {
            this.props.actions.fetchContainer({ folder: { type: restParams.type } });
          })
          .catch(error => {
            console.error('Error occurred while cloning folder', error);
          });
        break;
      case folderModalActions.convertToBundle:
        const bundleType = folder.type;
        const documents = this.props.documents;
        const foldersToSearch =
          this.props.currentFolder.type === 'team-bundle'
            ? this.props.teamBundles
            : this.props.currentFolder.type === 'private-bundle'
            ? this.props.privateBundles
            : this.props.trialbooks;

        // RECURSIVE SEARCH FOR PARENT FOLDER ID
        const findParentFolderId = (folders, targetFolderId) => {
          let parentFolderId = null;
          _.forEach(folders, parentFolder => {
            if (parentFolder.folders) {
              const found = _.find(parentFolder.folders, { id: targetFolderId });

              if (found) {
                parentFolderId = parentFolder.id;
                return false;
              }

              parentFolderId = findParentFolderId(parentFolder.folders, targetFolderId);

              if (parentFolderId) {
                return false;
              }
            }
          });
          return parentFolderId;
        };

        const parentFolderId = findParentFolderId(foldersToSearch, folder.id);

        this.props.actions
          .addFolder({
            name: folder.name,
            type: bundleType,
            parentFolderId: parentFolderId,
          })
          .then(replica => {
            return this.props.actions.addFolder({
              name: name,
              type: bundleType,
              parentFolderId: replica.id,
            });
          })
          .then(newFolder => {
            this.props.actions
              .assignDocuments({
                documentIds: documents.map(({ compositeKey }) => compositeKey),
                folderId: newFolder?.id,
              })
              .then(() => {
                this.props.actions.fetchContainer({ folder: { type: bundleType } });
              })
              .catch(error => {
                console.error('Error occurred while assigning documents', error);
              });
          })
          .then(() => {
            this.props.actions.deleteFolder(folder);
          });
        break;
      default:
    }
  };

  buttonActionsCourtBundles = ({ folder }) => {
    const {
      canDelete: cd,
      canReIndex,
      canHealthCheck,
      canExport,
      canDownload,
      canClone,
    } = this.props.buttonActionsPermissions;
    const canDelete = (cd && cd(folder)) || this.props.isSystemAdmin;
    const canReIndexBundle = (canReIndex && canReIndex(folder)) || this.props.isSystemAdmin;
    return [
      canDelete &&
        folder.level === 0 && {
          title: this.props.containers
            ? T.translate('case.removeFromCourtBundles').replace(
                T.translate('case.courtBundles').toLowerCase(),
                this.props.containers[1].toLowerCase(),
              )
            : T.translate('case.removeFromCourtBundles'),
          handler: () =>
            this.handleShow(folderModalActions.removeFromCourtBundles, {
              ...folder,
              type: this.props.allFolderTitles.includes('bundles') ? 'bundle' : 'team-bundle',
            }),
          icon: faArrowCircleDown,
        },
      canDownload &&
        folder.fileCount > 0 &&
        !folder.readOnly && {
          title: T.translate('generic.download'),
          handler: () => this.handleShow(folderModalActions.downloadFolder, folder),
          icon: faDownload,
        },
      canClone &&
        !folder.readOnly && {
          title: T.translate('generic.clone'),
          handler: () => this.handleShow(folderModalActions.clone, folder),
          icon: faClone,
        },
      canExport &&
        folder.fileCount > 0 && {
          title: T.translate('generic.export'),
          handler: () => this.handleShow(folderModalActions.exportFolder, folder),
          icon: faFileExcel,
        },
      (canDelete || canClone || canExport || canDownload) && { divider: true },
      canReIndexBundle &&
        (folder.fileCount > 0 || folder.folders.length > 0 || folder.readOnly) && {
          title: T.translate('generic.reIndex'),
          handler: () => this.handleShow(folderModalActions.reIndex, folder),
          icon: faListOl,
        },
      canHealthCheck && {
        title: T.translate('generic.healthCheck'),
        handler: () => this.handleShow(folderModalActions.healthCheck, folder),
        icon: faLaptopMedical,
      },
      (canReIndexBundle || canHealthCheck) && !folder.readOnly && { divider: true },
      {
        title: T.translate('generic.history'),
        handler: () => this.handleShow(folderModalActions.showHistory, folder),
        icon: faHistory,
      },

      folder.id === this.props.currentFolder.id &&
        (folder.id || folder.readOnly) && {
          title: T.translate('generic.refresh'),
          handler: () => this.handleShow(folderModalActions.refresh, folder),
          icon: faSync,
        },
      folder.id === this.props.currentFolder.id &&
        folder.fileCount > 0 &&
        this.props.canCreateBundle &&
        !this.props.currentFolder.agreed &&
        !folder.readOnly && {
          title: T.translate('case.convertToBundle'),
          handler: () => this.handleShow(folderModalActions.convertToBundle, folder),
          icon: faExchangeAlt,
        },
    ].filter(Boolean);
  };

  buttonActionsGroupBundles = ({ folder }) => {
    const { canDelete: cd } = this.props.buttonActionsPermissions;
    const canDelete = (cd && cd(folder)) || this.props.isSystemAdmin;
    return [
      canDelete &&
        this.props.isGroupParticipant &&
        folder.level === 0 && {
          title: T.translate('case.removeFromGroupBundles'),
          handler: () =>
            this.handleShow(folderModalActions.removeFromGroupBundles, {
              ...folder,
              type: 'team-bundle',
            }),
          icon: faArrowCircleDown,
        },
    ].filter(Boolean);
  };

  handleAiToolsClicked = folder => {
    this.props.actions.setAiToolsFilters({ folder });
    history.push(
      history.location.pathname.replace(/\/case.*/, ``) + `/case/${this.props.caseId}/ai-tools`,
    );
  };

  buttonActionsTrialbooks = ({ folder }) => {
    const foldersToSearch =
      folder.type === 'team-bundle'
        ? this.props.teamBundles
        : this.props.currentFolder.type === 'private-bundle'
        ? this.props.privateBundles
        : this.props.trialbooks;
    const currentDepth = findFolderDepth(foldersToSearch, folder.id);

    const {
      canDelete: cd,
      canUpdate: cu,
      canReIndex,
      canHealthCheck,
      canExport,
      canDownload,
      canDeleteWithFiles,
      canClone,
      canConvertToSubBundle,
    } = this.props.buttonActionsPermissions;

    const canDelete = (cd && cd(folder)) || this.props.isSystemAdmin,
      canUpdate = (cu && cu(folder)) || this.props.isSystemAdmin;
    const canReIndexBundle = (canReIndex && canReIndex(folder)) || this.props.isSystemAdmin;
    return [
      this.props.canCreateTrialbook &&
        !(folder.fileCount > 0) &&
        !folder.readOnly && {
          title: T.translate('case.addTrialbook'),
          handler: () =>
            this.handleShow(folderModalActions.createTrialbook, {
              id: folder.id,
            }),
          icon: faPlusCircle,
        },
      canUpdate &&
        !folder.readOnly && {
          title: T.translate('case.updateTrialbook'),
          handler: () => this.handleShow(folderModalActions.updateTrialbook, folder),
          icon: faEdit,
        },
      canDelete &&
        !folder.folders.length > 0 &&
        !folder.fileCount > 0 &&
        !folder.readOnly && {
          title: T.translate('case.deleteTrialbook'),
          handler: () => this.handleShow(folderModalActions.deleteTrialbook, folder),
          icon: faTrashAlt,
        },
      canDeleteWithFiles &&
        folder.folders.length === 0 &&
        folder.fileCount > 0 &&
        !folder.readOnly && {
          title: T.translate('case.deleteAll'),
          handler: () => this.handleShow(folderModalActions.deleteAll, folder),
          icon: faTrashAlt,
        },
      this.props.canMergeTrialbook &&
        !folder.readOnly &&
        (folder.folders.length > 0 || folder.fileCount > 0) && {
          title: T.translate('case.mergeTrialbook'),
          handler: () => this.handleShow(folderModalActions.mergeTrialbook, folder),
          icon: faLayerGroup,
        },
      // this.props.isCrossRefMode &&
      //   this.props.canBurnCrossRefTrialbook &&
      //   folder.fileCount > 0 &&
      //   !folder.readOnly && {
      //     title: T.translate('case.saveCrossRefMsg'),
      //     handler: () => this.handleShow(folderModalActions.burnCrossRef, folder),
      //     icon: faSave,
      //   },
      canDownload &&
        folder.fileCount > 0 &&
        !folder.readOnly && {
          title: T.translate('generic.download'),
          handler: () => this.handleShow(folderModalActions.downloadFolder, folder),
          icon: faDownload,
        },
      canClone &&
        !folder.readOnly && {
          title: T.translate('generic.clone'),
          handler: () => this.handleShow(folderModalActions.clone, folder),
          icon: faClone,
        },
      canExport &&
        folder.fileCount > 0 && {
          title: T.translate('generic.export'),
          handler: () => this.handleShow(folderModalActions.exportFolder, folder),
          icon: faFileExcel,
        },
      (canDelete ||
        canDeleteWithFiles ||
        canUpdate ||
        canClone ||
        this.props.canCreateTrialbook ||
        this.props.canMergeTrialbook) &&
        !folder.readOnly && { divider: true },
      canDelete &&
        folder.level === 0 && {
          title: this.props.containers
            ? T.translate('case.removeFromTrialBooks').replace(
                T.translate('case.trialbooks').toLowerCase(),
                this.props.containers[0].toLowerCase(),
              )
            : T.translate('case.removeFromTrialBooks'),
          handler: () =>
            this.handleShow(folderModalActions.removeFromTrialBooks, {
              ...folder,
              type: this.props.allFolderTitles.includes('bundles') ? 'bundle' : 'team-bundle',
            }),
          icon: faArrowCircleDown,
        },
      canReIndexBundle &&
        (folder.fileCount > 0 || folder.folders.length > 0 || folder.readOnly) && {
          title: T.translate('generic.reIndex'),
          handler: () => this.handleShow(folderModalActions.reIndex, folder),
          icon: faListOl,
        },
      canHealthCheck && {
        title: T.translate('generic.healthCheck'),
        handler: () => this.handleShow(folderModalActions.healthCheck, folder),
        icon: faLaptopMedical,
      },
      (canReIndexBundle || canHealthCheck) && !folder.readOnly && { divider: true },
      !folder.readOnly && {
        title: T.translate('generic.properties'),
        handler: () => this.handleShow(folderModalActions.showHistory, folder),
        icon: faHistory,
      },
      folder.id === this.props.currentFolder.id &&
        (folder.id || folder.readOnly) && {
          title: T.translate('generic.refresh'),
          handler: () => this.handleShow(folderModalActions.refresh, folder),
          icon: faSync,
        },
      canConvertToSubBundle &&
        folder.id === this.props.currentFolder.id &&
        folder.fileCount > 0 &&
        !this.props.currentFolder.agreed &&
        currentDepth < 5 &&
        !folder.readOnly && {
          title: T.translate('case.convertToBundle'),
          handler: () => this.handleShow(folderModalActions.convertToBundle, folder),
          icon: faExchangeAlt,
        },

      this.props.showAiTools && { divider: true },
      this.props.showAiTools && {
        title: T.translate('case.askAI'),
        handler: () => this.handleAiToolsClicked(folder),
        icon: { type: 'muiIcon', component: <AutoAwesomeIcon fontSize="large" /> },
      },
    ].filter(Boolean);
  };

  buttonActionsBundles = ({ folder }) => {
    const {
      canShareBundle: csb,
      canPublishBundle: cpb,
      canPublishBundleToTrialbook: cpbt,
      canDelete: cd,
      canUpdate: cu,
      canReIndex,
      canHealthCheck,
      canExport,
      canDownload,
      canDeleteWithFiles,
      canClone,
      canConvertToSubBundle,
      canPublishToGroupBundles: cpbg,
    } = this.props.buttonActionsPermissions;
    const canShareBundle = (csb && csb(folder)) || this.props.isSystemAdmin,
      canPublishBundle = (cpb && cpb(folder)) || this.props.isSystemAdmin,
      canPublishBundleToTrialbook = (cpbt && cpbt(folder)) || this.props.isSystemAdmin,
      canPublishToGroupBundles = (cpbg && cpbg(folder)) || this.props.isSystemAdmin,
      canDelete = (cd && cd(folder)) || this.props.isSystemAdmin,
      canUpdate = (cu && cu(folder)) || this.props.isSystemAdmin;
    const canReIndexBundle = (canReIndex && canReIndex(folder)) || this.props.isSystemAdmin;
    const foldersToSearch =
      folder.type === 'team-bundle'
        ? this.props.teamBundles
        : this.props.currentFolder.type === 'private-bundle'
        ? this.props.privateBundles
        : this.props.trialbooks;

    const currentDepth = findFolderDepth(foldersToSearch, folder.id);

    return [
      !folder.fileCount > 0 &&
        folder.level !== 5 &&
        !folder.readOnly && {
          title: T.translate('case.addABundle'),
          handler: () =>
            this.handleShow(
              folder.type === 'bundle'
                ? folderModalActions.createBundle
                : folder.type === 'team-bundle'
                ? folderModalActions.createTeamBundle
                : folderModalActions.createPrivateBundle,
              {
                id: folder.id,
              },
            ),
          icon: faPlusCircle,
        },
      canPublishBundleToTrialbook &&
        folder.level === 0 &&
        !folder.readOnly && {
          title: this.props.containers
            ? T.translate('case.moveToTrialBooks').replace(
                T.translate('case.trialbooks').toLowerCase(),
                this.props.containers[0].toLowerCase(),
              )
            : T.translate('case.moveToTrialBooks'),
          handler: () =>
            this.handleShow(folderModalActions.moveToTrialBooks, {
              ...folder,
              type: 'trialbook',
            }),
          icon: faArrowCircleUp,
        },
      typeof this.props.courtBundles !== 'undefined' &&
        canPublishBundle &&
        folder.level === 0 &&
        !folder.readOnly && {
          title: this.props.containers
            ? T.translate('case.moveToCourtBundles').replace(
                T.translate('case.courtBundles').toLowerCase(),
                this.props.containers[1].toLowerCase(),
              )
            : T.translate('case.moveToCourtBundles'),
          handler: () =>
            this.handleShow(folderModalActions.moveToCourtBundles, {
              ...folder,
              type: 'court-bundle',
            }),
          icon: faArrowCircleUp,
        },
      typeof this.props.groupBundles !== 'undefined' &&
        this.props.isGroupParticipant &&
        canPublishToGroupBundles &&
        folder.level === 0 &&
        folder.type === 'team-bundle' &&
        !folder.readOnly && {
          title: T.translate('case.moveToGroupBundles'),
          handler: () =>
            this.handleShow(folderModalActions.shareWithGroup, {
              ...folder,
              type: 'group-bundle',
            }),
          icon: faArrowCircleUp,
        },
      canShareBundle &&
        folder.level === 0 &&
        (folder.type === 'bundle' || folder.type === 'private-bundle') &&
        !folder.readOnly && {
          title: T.translate('case.shareWithTeam'),
          handler: () =>
            this.handleShow(folderModalActions.share, {
              ...folder,
              shared: true,
              ...(folder.type === 'private-bundle' && { type: 'team-bundle' }),
            }),
          icon: faShareAlt,
        },
      (canPublishBundleToTrialbook || canPublishBundle || canShareBundle) &&
        folder.level !== 3 &&
        !folder.fileCount > 0 &&
        (canUpdate || canDelete || canDeleteWithFiles) &&
        !folder.readOnly && { divider: true },
      canUpdate &&
        !folder.readOnly && {
          title: T.translate('generic.update'),
          handler: () => this.handleShow(folderModalActions.updateBundle, folder),
          icon: faEdit,
        },
      canDelete &&
        !folder.folders.length > 0 &&
        !folder.fileCount > 0 &&
        !folder.readOnly && {
          title: T.translate('generic.delete'),
          handler: () => this.handleShow(folderModalActions.deleteBundle, folder),
          icon: faTrashAlt,
        },
      canDeleteWithFiles &&
        folder.folders.length === 0 &&
        folder.fileCount > 0 &&
        !folder.readOnly && {
          title: T.translate('case.deleteAll'),
          handler: () => this.handleShow(folderModalActions.deleteAll, folder),
          icon: faTrashAlt,
        },
      this.props.canMergeTrialbook &&
        (folder.folders.length > 0 || folder.fileCount > 0) &&
        !folder.readOnly && {
          title: T.translate('case.mergeTrialbook'),
          handler: () => this.handleShow(folderModalActions.mergeBundle, folder),
          icon: faLayerGroup,
        },
      canDownload &&
        folder.fileCount > 0 &&
        !folder.readOnly && {
          title: T.translate('generic.download'),
          handler: () => this.handleShow(folderModalActions.downloadFolder, folder),
          icon: faDownload,
        },
      canClone &&
        !folder.readOnly && {
          title: T.translate('generic.clone'),
          handler: () => this.handleShow(folderModalActions.clone, folder),
          icon: faClone,
        },
      canExport &&
        folder.fileCount > 0 && {
          title: T.translate('generic.export'),
          handler: () => this.handleShow(folderModalActions.exportFolder, folder),
          icon: faFileExcel,
        },
      (canUpdate || canDelete || canDeleteWithFiles || this.props.canMergeTrialbook) &&
        !folder.readOnly && { divider: true },
      canReIndexBundle &&
        (folder.fileCount > 0 || folder.folders.length > 0 || folder.readOnly) && {
          title: T.translate('generic.reIndex'),
          handler: () => this.handleShow(folderModalActions.reIndex, folder),
          icon: faListOl,
        },
      canHealthCheck && {
        title: T.translate('generic.healthCheck'),
        handler: () => this.handleShow(folderModalActions.healthCheck, folder),
        icon: faLaptopMedical,
      },
      (canReIndexBundle || canHealthCheck) && !folder.readOnly && { divider: true },
      !folder.readOnly && {
        title: T.translate('generic.properties'),
        handler: () => this.handleShow(folderModalActions.showHistory, folder),
        icon: faHistory,
      },
      folder.id === this.props.currentFolder.id &&
        (folder.id || folder.readOnly) && {
          title: T.translate('generic.refresh'),
          handler: () => this.handleShow(folderModalActions.refresh, folder),
          icon: faSync,
        },
      canConvertToSubBundle &&
        folder.fileCount > 0 &&
        !this.props.currentFolder.agreed &&
        currentDepth < 5 &&
        !folder.readOnly && {
          title: T.translate('case.convertToBundle'),
          handler: () => this.handleShow(folderModalActions.convertToBundle, folder),
          icon: faExchangeAlt,
        },
      this.props.showAiTools && { divider: true },
      this.props.showAiTools && {
        title: T.translate('case.askAI'),
        handler: () => this.handleAiToolsClicked(folder),
        icon: { type: 'muiIcon', component: <AutoAwesomeIcon fontSize="large" /> },
      },
    ].filter(Boolean);
  };

  buttonActionsTags = ({ folder }) => {
    return [
      folder.level === 0 &&
        folder.type === 'timelines' && {
          title: T.translate('generic.createTimeline'),
          handler: () => this.handleShow(folderModalActions.createTimeline),
          icon: faPlusCircle,
        },
      folder.level === 1 &&
        folder.type === 'timelines' && {
          title: T.translate('generic.update'),
          handler: () => this.handleShow(folderModalActions.updateTimeline, folder),
          icon: faEdit,
        },
      folder.level === 1 &&
        folder.type === 'timelines' && {
          title: T.translate('generic.delete'),
          handler: () => this.handleShow(folderModalActions.deleteTimeline, folder),
          icon: faTrashAlt,
        },
      folder.level === 1 &&
        folder.type === 'timelines' && {
          title: T.translate('generic.export'),
          handler: () => this.handleShow(folderModalActions.exportTimeline, folder),
          icon: faFileExcel,
        },
      folder.level === 0 &&
        folder.type === 'tags' && {
          title: T.translate('generic.createTag'),
          handler: () => this.handleShow(folderModalActions.createTag),
          icon: faPlusCircle,
        },
      folder.level === 1 &&
        folder.type === 'tags' && {
          title: T.translate('generic.update'),
          handler: () => this.handleShow(folderModalActions.updateTag, folder),
          icon: faEdit,
        },
      folder.level === 1 &&
        folder.type === 'tags' &&
        folder.annotationCount === 0 && {
          title: T.translate('generic.delete'),
          handler: () => this.handleShow(folderModalActions.deleteTag, folder),
          icon: faTrashAlt,
        },
      (folder.id || folder.readOnly) && {
        title: T.translate('generic.refresh'),
        handler: () => this.handleShow(folderModalActions.refresh, folder),
        icon: faSync,
      },
    ].filter(Boolean);
  };

  buttonActionsAllDocs = ({ folder }) => {
    return [
      this.props.matchedUrl.includes('all-documents') && {
        title: T.translate('generic.refresh'),
        handler: () => this.handleShow(folderModalActions.refresh, folder),
        icon: faSync,
      },
    ].filter(Boolean);
  };

  buttonActionsAllAnnotations = ({ folder }) => {
    return [
      this.props.matchedUrl.includes('all-annotations') && {
        title: T.translate('generic.refresh'),
        handler: () => this.handleShow(folderModalActions.refresh, folder),
        icon: faSync,
      },
    ].filter(Boolean);
  };

  render() {
    const outside = (
      <ul className="nav all-documents">
        {this.props.showPresentModeWithSearch && (
          <SidebarItem
            folder={{ name: T.translate('case.presentMode'), code: 'present-mode' }}
            className="active"
            to={`/case/${this.props.currentCase}`}
          />
        )}
        {this.props.showSearchMode && (
          <SidebarItem
            folder={{ name: T.translate('generic.searchMode'), code: 'search-mode' }}
            className="active"
            to={`/case/${this.props.currentCase}`}
          />
        )}
        {this.props.showAiTools && (
          <SidebarItem
            folder={{ name: T.translate('generic.aiTools'), code: 'ai-tools' }}
            className="active"
            to={`/case/${this.props.currentCase}`}
          />
        )}
        {this.props.showDocFinder && (
          <SidebarItem
            folder={{ name: T.translate('case.docFinder'), code: 'docFinder' }}
            className="active"
            to={`/case/${this.props.currentCase}`}
          />
        )}
        {this.props.ShowPageRefs && (
          <SidebarItem
            folder={{ name: T.translate('case.pageRefs'), code: 'page-refs' }}
            className="active"
            to={`/case/${this.props.currentCase}`}
            //buttonActions={this.buttonActionsAllAnnotations}
          />
        )}
        {this.props.showAllAnnotations && (
          <SidebarItem
            folder={{ name: T.translate('case.allAnnotations'), code: 'all-annotations' }}
            className="active"
            to={`/case/${this.props.currentCase}`}
            buttonActions={this.buttonActionsAllAnnotations}
          />
        )}
        {this.props.showAllDocuments && this.props.caseSettingsShowAllDocuments && (
          <SidebarItem
            folder={{ name: T.translate('case.allDocuments'), code: 'all-documents' }}
            className="active"
            to={`/case/${this.props.currentCase}`}
            buttonActions={this.buttonActionsAllDocs}
          />
        )}
      </ul>
    );

    return (
      <Sidebar
        outside={outside}
        NavBarLinks={this.props.NavBarLinks}
        isSidebarOpen={this.props.isSidebarOpen}
        updateWindowWidth={this.props.updateWindowWidth}
        outsideFolderCount={this.props.folderCount}
      >
        {(!this.props.fetchFoldersPending || !this.props.fetchContainersPending) &&
          !this.props.error && (
            <>
              {(this.props.canCreateTrialbook || this.props.trialbooks.length > 0) && (
                <Accordion defaultActiveKey="trialbooks">
                  <CustomToggle className="title" eventKey="trialbooks">
                    <span>
                      {this.props.containers
                        ? formatLabel(this.props.containers[0])
                        : T.translate('case.trialbooks')}
                    </span>
                    <div
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      {this.props.canUpdateTrialbundlesContainer &&
                        this.props.trialbooks.length > 1 && (
                          <FontAwesomeIcon
                            onClick={e => {
                              e.stopPropagation();
                              this.toggleLock();
                            }}
                            style={{ width: '30px' }}
                            icon={this.state.isLocked ? faLock : faUnlock}
                            alt={this.state.isLocked ? faLock.iconName : faUnlock.iconName}
                          />
                        )}
                      {this.props.trialbooks.length > 0 && this.props.canCreateTrialbook && (
                        <FontAwesomeIcon
                          onClick={e => {
                            this.handleShow(folderModalActions.createTrialbook);
                            this.handleDefaultBehav(e);
                          }}
                          icon={faPlusCircle}
                          alt={faPlusCircle.iconName}
                        />
                      )}
                    </div>
                  </CustomToggle>
                  <Accordion.Collapse eventKey="trialbooks">
                    <SelectableContext.Provider value={false}>
                      <ul className="nav">
                        {this.props.trialbooks.length > 0 ? (
                          this.props.canUpdateTrialbundlesContainer ? (
                            <DraggableBundles
                              bundles={this.props.trialbooks}
                              buttonActions={this.buttonActionsTrialbooks}
                              to={`/case/${this.props.currentCase}/trialbundles`}
                              code={'trialbooks'}
                              isLocked={this.state.isLocked}
                            />
                          ) : (
                            this.props.trialbooks.map((prop, key) => (
                              <SidebarItem
                                folder={prop}
                                key={key}
                                to={`/case/${this.props.currentCase}/trialbundles`}
                                code="trialbooks"
                                buttonActions={this.buttonActionsTrialbooks}
                                isLocked={this.state.isLocked}
                              />
                            ))
                          )
                        ) : (
                          this.props.canCreateTrialbook && (
                            <div
                              className="add"
                              onClick={e => {
                                this.handleShow(folderModalActions.createTrialbook);
                                this.handleDefaultBehav(e);
                              }}
                            >
                              <FontAwesomeIcon icon={faPlusCircle} alt={faPlusCircle.iconName} />
                              <span>{T.translate('case.addTrialbook')}</span>
                            </div>
                          )
                        )}
                      </ul>
                    </SelectableContext.Provider>
                  </Accordion.Collapse>
                </Accordion>
              )}
              {typeof this.props.courtBundles !== 'undefined' &&
                (this.props.teamBundles.length > 0 ||
                  this.props.privateBundles.length > 0 ||
                  this.props.bundles.length > 0 ||
                  (this.props.courtBundles && this.props.courtBundles.length > 0)) && (
                  <div>
                    <div className="divider-with-icon">
                      <div className="divider" />
                      <div className="icon">
                        <FontAwesomeIcon icon={faBook} alt={faBook.iconName} />
                      </div>
                      <div className="divider" />
                    </div>
                    <Accordion defaultActiveKey="court-bundles">
                      <CustomToggle className="title" eventKey="court-bundles">
                        <span>
                          {this.props.containers
                            ? formatLabel(this.props.containers[1])
                            : T.translate('case.courtBundles')}
                        </span>
                      </CustomToggle>
                      <Accordion.Collapse eventKey="court-bundles">
                        <SelectableContext.Provider value={false}>
                          <ul className="nav court-bundles">
                            {this.props.courtBundles && this.props.courtBundles.length > 0 ? (
                              this.props.courtBundles.map((prop, key) => (
                                <SidebarItem
                                  folder={prop}
                                  key={key}
                                  to={`/case/${this.props.currentCase}/court-bundles`}
                                  type={prop.type}
                                  buttonActions={this.buttonActionsCourtBundles}
                                  isLocked={this.state.isLocked}
                                />
                              ))
                            ) : (
                              <div>{T.translate('case.moveMeHere')}</div>
                            )}
                          </ul>
                        </SelectableContext.Provider>
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                )}
              {typeof this.props.groupBundles !== 'undefined' &&
                (this.props.teamBundles.length > 0 ||
                  (this.props.groupBundles && this.props.groupBundles.length > 0)) && (
                  <div>
                    <div className="divider-with-icon">
                      <div className="divider" />
                      <div className="icon">
                        <FontAwesomeIcon icon={faBook} alt={faBook.iconName} />
                      </div>
                      <div className="divider" />
                    </div>
                    <Accordion defaultActiveKey="group-bundles">
                      <CustomToggle className="title" eventKey="group-bundles">
                        <span>{T.translate('case.groupBundles')}</span>
                      </CustomToggle>
                      <Accordion.Collapse eventKey="group-bundles">
                        <SelectableContext.Provider value={false}>
                          <ul className="nav court-bundles">
                            {this.props.groupBundles && this.props.groupBundles.length > 0 ? (
                              this.props.groupBundles.map((prop, key) => (
                                <SidebarItem
                                  folder={prop}
                                  key={key}
                                  to={`/case/${this.props.currentCase}/group-bundles`}
                                  type={prop.type}
                                  buttonActions={this.buttonActionsGroupBundles}
                                />
                              ))
                            ) : (
                              <div>{T.translate('case.moveMeHere')}</div>
                            )}
                          </ul>
                        </SelectableContext.Provider>
                      </Accordion.Collapse>
                    </Accordion>
                  </div>
                )}
              {!this.props.allFolderTitles.includes('bundles') ? (
                <>
                  <div className="divider" />
                  <Accordion defaultActiveKey="teamBundles">
                    <CustomToggle className="title" eventKey="teamBundles">
                      <span>
                        {this.props.containers
                          ? formatLabel(this.props.containers[2])
                          : T.translate('case.teamBundles')}
                      </span>
                      <div
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {this.props.canUpdateTeamBundlesContainer &&
                          this.props.teamBundles.length > 1 && (
                            <FontAwesomeIcon
                              onClick={e => {
                                e.stopPropagation();
                                this.toggleTeamBundlesLock();
                              }}
                              style={{ width: '30px' }}
                              icon={this.state.teamBundlesLocked ? faLock : faUnlock}
                              alt={
                                this.state.teamBundlesLocked ? faLock.iconName : faUnlock.iconName
                              }
                            />
                          )}
                        {this.props.teamBundles.length > 0 && this.props.canCreateTeamBundle && (
                          <FontAwesomeIcon
                            onClick={e => {
                              this.handleShow(folderModalActions.createTeamBundle);
                              this.handleDefaultBehav(e);
                            }}
                            icon={faPlusCircle}
                            alt={faPlusCircle.iconName}
                          />
                        )}
                      </div>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="teamBundles">
                      <SelectableContext.Provider value={false}>
                        <ul className="nav">
                          {this.props.teamBundles.length > 0 ? (
                            this.props.canUpdateTeamBundlesContainer ? (
                              <DraggableBundles
                                bundles={this.props.teamBundles}
                                buttonActions={this.buttonActionsBundles}
                                to={`/case/${this.props.currentCase}/teamBundles`}
                                code={'teamBundles'}
                                isLocked={this.state.teamBundlesLocked}
                              />
                            ) : (
                              this.props.teamBundles.map((prop, key) => {
                                return (
                                  <SidebarItem
                                    key={key}
                                    folder={prop}
                                    to={`/case/${this.props.currentCase}/teamBundles`}
                                    type={'team-bundle'}
                                    buttonActions={this.buttonActionsBundles}
                                    isLocked={this.state.teamBundlesLocked}
                                  />
                                );
                              })
                            )
                          ) : (
                            this.props.canCreateTeamBundle && (
                              <div
                                className="add"
                                onClick={e => {
                                  this.handleShow(folderModalActions.createTeamBundle);
                                  this.handleDefaultBehav(e);
                                }}
                              >
                                <FontAwesomeIcon icon={faPlusCircle} alt={faPlusCircle.iconName} />
                                <span>{T.translate('case.addABundle')}</span>
                              </div>
                            )
                          )}
                        </ul>
                      </SelectableContext.Provider>
                    </Accordion.Collapse>
                  </Accordion>
                  <div className="divider" />
                  <Accordion defaultActiveKey="privateBundles">
                    <CustomToggle className="title" eventKey="privateBundles">
                      <span>
                        {this.props.containers
                          ? formatLabel(this.props.containers[3])
                          : T.translate('case.privateBundles')}
                      </span>
                      <div
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {this.props.canUpdatePrivateBundlesContainer &&
                          this.props.privateBundles.length > 1 && (
                            <FontAwesomeIcon
                              onClick={e => {
                                e.stopPropagation();
                                this.togglePrivateBundlesLock();
                              }}
                              style={{ width: '30px' }}
                              icon={this.state.privateBundlesLocked ? faLock : faUnlock}
                              alt={
                                this.state.privateBundlesLocked
                                  ? faLock.iconName
                                  : faUnlock.iconName
                              }
                            />
                          )}
                        {this.props.privateBundles.length > 0 && this.props.canCreatePrivateBundle && (
                          <FontAwesomeIcon
                            onClick={e => {
                              this.handleShow(folderModalActions.createPrivateBundle);
                              this.handleDefaultBehav(e);
                            }}
                            icon={faPlusCircle}
                            alt={faPlusCircle.iconName}
                          />
                        )}
                      </div>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="privateBundles">
                      <SelectableContext.Provider value={false}>
                        <ul className="nav">
                          {this.props.privateBundles.length > 0 ? (
                            this.props.canUpdatePrivateBundlesContainer ? (
                              <DraggableBundles
                                bundles={this.props.privateBundles}
                                buttonActions={this.buttonActionsBundles}
                                to={`/case/${this.props.currentCase}/privateBundles`}
                                code={'privateBundles'}
                                isLocked={this.state.privateBundlesLocked}
                              />
                            ) : (
                              this.props.privateBundles.map((prop, key) => {
                                return (
                                  <SidebarItem
                                    key={key}
                                    folder={prop}
                                    to={`/case/${this.props.currentCase}/privateBundles`}
                                    type={'private-bundle'}
                                    buttonActions={this.buttonActionsBundles}
                                    isLocked={this.state.privateBundlesLocked}
                                  />
                                );
                              })
                            )
                          ) : (
                            this.props.canCreatePrivateBundle && (
                              <div
                                className="add"
                                onClick={e => {
                                  this.handleShow(folderModalActions.createPrivateBundle);
                                  this.handleDefaultBehav(e);
                                }}
                              >
                                <FontAwesomeIcon icon={faPlusCircle} alt={faPlusCircle.iconName} />
                                <span>{T.translate('case.addABundle')}</span>
                              </div>
                            )
                          )}
                        </ul>
                      </SelectableContext.Provider>
                    </Accordion.Collapse>
                  </Accordion>
                </>
              ) : (
                <>
                  <div className="divider" />
                  <Accordion defaultActiveKey="bundles">
                    <CustomToggle className="title" eventKey="bundles">
                      <span>
                        {this.props.containers
                          ? formatLabel(this.props.containers[2])
                          : T.translate('case.bundles')}
                      </span>
                      {this.props.bundles.length > 0 && this.props.canCreateBundle && (
                        <FontAwesomeIcon
                          onClick={e => {
                            this.handleShow(folderModalActions.createBundle);
                            this.handleDefaultBehav(e);
                          }}
                          icon={faPlusCircle}
                          alt={faPlusCircle.iconName}
                        />
                      )}
                    </CustomToggle>
                    <Accordion.Collapse eventKey="bundles">
                      <SelectableContext.Provider value={false}>
                        <ul className="nav">
                          {this.props.bundles.length > 0
                            ? this.props.bundles.map((prop, key) => {
                                return (
                                  <SidebarItem
                                    key={key}
                                    folder={prop}
                                    to={`/case/${this.props.currentCase}/bundles`}
                                    icon={prop.shared ? faUserFriends : faLock}
                                    type={prop.shared ? 'team-bundle' : 'private-bundle'}
                                    buttonActions={this.buttonActionsBundles}
                                    isLocked={this.state.isLocked}
                                  />
                                );
                              })
                            : this.props.canCreateBundle && (
                                <div
                                  className="add"
                                  onClick={e => {
                                    this.handleShow(folderModalActions.createBundle);
                                    this.handleDefaultBehav(e);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlusCircle}
                                    alt={faPlusCircle.iconName}
                                  />
                                  <span>{T.translate('case.addABundle')}</span>
                                </div>
                              )}
                        </ul>
                      </SelectableContext.Provider>
                    </Accordion.Collapse>
                  </Accordion>
                </>
              )}
              {this.props.quickLinks.length > 0 && (
                <>
                  <div className="divider" />
                  <Accordion defaultActiveKey="quick-links">
                    <CustomToggle className="title" eventKey="quick-links">
                      <span>{T.translate('case.quickLinks')}</span>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="quick-links">
                      <SelectableContext.Provider value={false}>
                        <ul className="nav">
                          {this.props.quickLinks.map((prop, key) => (
                            <SidebarItem
                              key={key}
                              folder={prop}
                              to={`/case/${this.props.currentCase}/quick-links`}
                              buttonActions={this.buttonActionsTags}
                            />
                          ))}
                        </ul>
                      </SelectableContext.Provider>
                    </Accordion.Collapse>
                  </Accordion>
                </>
              )}
              {this.state.showModal && (
                <FolderModal
                  handleClose={this.handleClose}
                  handleSave={this.handleSave}
                  show={this.state.showModal}
                  action={this.state.modalAction}
                  folder={this.state.folder}
                />
              )}
              {this.state.showMergeModal && (
                <ModalMerge
                  handleClose={this.handleClose}
                  show={this.state.showMergeModal}
                  folder={this.state.folder}
                  containers={this.props.containers}
                />
              )}
              {this.state.showDownloadModal && (
                <DownloadModal
                  handleClose={this.handleClose}
                  show={this.state.showDownloadModal}
                  folder={this.state.folder}
                  containers={this.props.containers}
                />
              )}
            </>
          )}
      </Sidebar>
    );
  }
}

const buttonActionsPermissions = createSelector(
  selectPermissions,
  ({
    folders: {
      canShareBundle,
      canPublishBundle,
      canPublishBundleToTrialbook,
      canDelete,
      canUpdate,
      canReIndex,
      canHealthCheck,
      canExport,
      canDownload,
      canDeleteWithFiles,
      canClone,
      canConvertToSubBundle,
      canPublishToGroupBundles,
    } = {},
  }) => ({
    canShareBundle,
    canPublishBundle,
    canPublishBundleToTrialbook,
    canDelete,
    canUpdate,
    canReIndex,
    canHealthCheck,
    canExport,
    canDownload,
    canDeleteWithFiles,
    canClone,
    canConvertToSubBundle,
    canPublishToGroupBundles,
  }),
);

const canCreateBundleSelector = createSelector(
  selectPermissions,
  ({ folders: { canCreateBundle } = {} }) => canCreateBundle,
);

const canCreateTeamBundleSelector = createSelector(
  selectPermissions,
  ({ folders: { canCreateTeamBundle } = {} }) => canCreateTeamBundle,
);

const canCreatePrivateBundleSelector = createSelector(
  selectPermissions,
  ({ folders: { canCreatePrivateBundle } = {} }) => canCreatePrivateBundle,
);

const canCreateTrialbookSelector = createSelector(
  selectPermissions,
  ({ folders: { canCreateTrialbook } = {} }) => canCreateTrialbook,
);

const canMergeTrialbookSelector = createSelector(
  selectPermissions,
  ({ documents: { canMergeTrialBook } = {} }) => canMergeTrialBook,
);

const canBurnCrossRefTrialbookSelector = createSelector(
  selectPermissions,
  ({ documents: { canBurnCrossRef } = {} }) => canBurnCrossRef && canBurnCrossRef(),
);

const canUpdateTrialbundlesContainerSelector = createSelector(
  selectPermissions,
  ({ containers: { canUpdate } = {} }) => canUpdate && canUpdate('trialbooks'),
);

const canUpdateTeamBundlesContainerSelector = createSelector(
  selectPermissions,
  ({ containers: { canUpdate } = {} }) => canUpdate && canUpdate('team-bundles'),
);

const canUpdatePrivateBundlesContainerSelector = createSelector(
  selectPermissions,
  ({ containers: { canUpdate } = {} }) => canUpdate && canUpdate('private-bundles'),
);

/* istanbul ignore next */
const mapStateToProps = state => {
  const {
    case: {
      fetchFoldersPending,
      fetchContainersPending,
      fetchFoldersFeedback,
      fetchContainersFeedback,
      timelines,
    },
  } = state;
  const { containers: { canUpdate } = {} } = selectPermissions(state);
  const isSystemAdmin = selectIsAdmin(state);
  const isCustomContainers = selectCustomContainers(state);
  const currentFolder = selectCurrentFolder(state);
  const allFolderTitles = Object.keys(selectFolders(state));
  const isDeepSearch = selectDeepSearch(state);
  const showPresentModeWithSearch = selectUserSettingPresentMode(state);

  const showAiTools = selectUserAiTools(state);
  const showSearchMode = selectUserSettingSearchMode(state) && isDeepSearch;
  const showDocFinder = selectUserSettingDocFinder(state) && isDeepSearch;
  const showAllDocuments = selectUserSettingAllDocs(state);
  const caseSettingsShowAllDocuments = selectShowAllDocuments(state);
  const showAllAnnotations = selectUserSettingAllAnnotations(state);
  const ShowPageRefs = selectUserSettingPageRefs(state);
  const documents = selectDocuments(state);
  const showGroupBundles = selectShowGroupBundles(state);
  const currentTeamId = selectCurrentCaseTeamId(state);
  const groups = selectGroups(state);
  const isGroupParticipant = groups.filter(gp => gp.teams.includes(currentTeamId)).length > 0;

  const folderCount = [
    showPresentModeWithSearch,
    showSearchMode,
    showDocFinder,
    showAllDocuments,
    showAllAnnotations,
    ShowPageRefs,
    showAiTools,
  ].filter(Boolean).length;
  const {
    bundles = [],
    teamBundles = [],
    privateBundles = [],
    trialbooks = [],
    courtBundles,
    groupBundles,
    quickLinks = [],
  } = selectFolders(state);
  return {
    currentCase: selectSpecificParam(state, 'case'),
    fetchFoldersPending,
    fetchContainersPending,
    error:
      (fetchFoldersFeedback && fetchFoldersFeedback.error) ||
      (fetchContainersFeedback && fetchContainersFeedback.error),
    trialbooks,
    bundles,
    courtBundles,
    groupBundles,
    teamBundles,
    privateBundles,
    quickLinks,
    canCreateBundle: canCreateBundleSelector(state) || isSystemAdmin,
    canCreateTeamBundle: canCreateTeamBundleSelector(state) || isSystemAdmin,
    canCreatePrivateBundle: canCreatePrivateBundleSelector(state) || isSystemAdmin,
    canCreateTrialbook: canCreateTrialbookSelector(state) || isSystemAdmin,
    canMergeTrialbook: canMergeTrialbookSelector(state),
    canBurnCrossRefTrialbook: canBurnCrossRefTrialbookSelector(state),
    isCrossRefMode: selectCrossRef(state),
    canUpdateTeamBundlesContainer: canUpdateTeamBundlesContainerSelector(state) || isSystemAdmin,
    canUpdatePrivateBundlesContainer:
      canUpdatePrivateBundlesContainerSelector(state) || isSystemAdmin,
    canUpdateTrialbundlesContainer: canUpdateTrialbundlesContainerSelector(state) || isSystemAdmin,
    buttonActionsPermissions: buttonActionsPermissions(state),
    isSystemAdmin,
    currentFolder,
    currentFile: selectCurrentFile(state),
    matchedUrl: selectMatchedUrl(state),
    matchedPath: selectMatchedPath(state),
    params: selectParams(state),
    caseId: selectCurrentCaseId(state),
    ...(isCustomContainers && { containers: selectContainers(state) }),
    globalPaging: currentFolder && currentFolder.globalPaging,
    allFolderTitles,
    showPresentModeWithSearch,
    showAiTools,
    showSearchMode,
    showDocFinder,
    showAllDocuments,
    showAllAnnotations,
    ShowPageRefs,
    folderCount,
    isTags: selectTagsFeature(state),
    currentTeamId: selectCurrentCaseTeamId(state),
    timelines,
    caseSettingsShowAllDocuments,
    documents,
    isGroupParticipant,
    showGroupBundles,
  };
};

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions }, dispatch),
  actionsV: bindActionCreators({ ...actionsV }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarCase);
