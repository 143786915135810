import React, { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { objectValueFromPath } from 'utils/object';
import ErrorFeedback from './ErrorFeedback';
// import PropTypes from 'prop-types';

const TextForm = React.forwardRef(
  (
    {
      placeholder,
      errors,
      onChange,
      onBlur,
      onFocus,
      onClick,
      defaultValue,
      name,
      label,
      readOnly,
      value,
      type,
      as,
      autofocus,
      autocomplete,
      disabled,
      style,
      onKeyDown,
    },
    ref,
  ) => {
    const firstInputRef = useRef();

    useEffect(
      () =>
        autofocus &&
        firstInputRef.current &&
        firstInputRef.current.focus &&
        firstInputRef.current.focus(),
      [autofocus, firstInputRef],
    );

    return (
      <>
        {label && (
          <Form.Label style={{ ...(typeof label !== 'string' && { width: '100%' }) }}>
            {label}
          </Form.Label>
        )}
        <Form.Control
          as={as}
          ref={el => {
            ref && ref(el);
            firstInputRef.current = el;
          }}
          name={name}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onClick={onClick}
          isInvalid={!!objectValueFromPath(name, errors)}
          readOnly={readOnly}
          value={value}
          type={type}
          autoComplete={autocomplete ? 'on' : 'off'}
          disabled={disabled}
          style={style}
        />
        <ErrorFeedback name={name} errors={errors} />
      </>
    );
  },
);

TextForm.propTypes = {};
TextForm.defaultProps = {};

export default TextForm;
