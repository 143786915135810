import {
  CASE_UPDATE_NOTIFICATION_BEGIN,
  CASE_UPDATE_NOTIFICATION_SUCCESS,
  CASE_UPDATE_NOTIFICATION_FAILURE,
  CASE_UPDATE_NOTIFICATION_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { deleteItemImmutable, replaceItemImmutable } from 'utils/arrays';

export const updateNotification = createPromiseAction(CASE_UPDATE_NOTIFICATION_BEGIN);

export function dismissUpdateNotificationFeedback() {
  return {
    type: CASE_UPDATE_NOTIFICATION_DISMISS_FEEDBACK,
  };
}

export function* doUpdateNotification(action) {
  const {
    payload: { caseId, notification },
  } = action;

  let res = yield call(api.put, `/cases/${caseId}/notifications/${notification.id}`, notification);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_NOTIFICATION_FAILURE,
      feedback: {
        message: 'feedback.updateNotificationFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_NOTIFICATION_SUCCESS,
    data: { notification: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateNotification() {
  yield takeLatest(updateNotification, withCurrentCaseId(doUpdateNotification));
}

export function useUpdateNotification() {
  const dispatch = useDispatch();

  const { notifications, updateNotificationPending, updateNotificationFeedback } = useSelector(
    state => ({
      notifications: state.case.notifications,
      updateNotificationPending: state.case.updateNotificationPending,
      updateNotificationFeedback: state.case.updateNotificationFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateNotification(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateNotificationFeedback());
  }, [dispatch]);

  return {
    notifications,
    updateNotification: boundAction,
    updateNotificationPending,
    updateNotificationFeedback,
    dismissUpdateNotificationFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_NOTIFICATION_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateNotificationPending: true,
        updateNotificationFeedback: null,
      };

    case CASE_UPDATE_NOTIFICATION_SUCCESS:
      // The request is success
      const indexOfNotificationToUpdate = state.notifications.findIndex(
        x => x.id === action.data.notification.id,
      );
      return {
        ...state,
        updateNotificationPending: false,
        updateNotificationFeedback: action.feedback,
        notifications: action.data.notification.hide
          ? deleteItemImmutable(state.notifications, indexOfNotificationToUpdate)
          : replaceItemImmutable(
              state.notifications,
              action.data.notification,
              indexOfNotificationToUpdate,
            ),
      };

    case CASE_UPDATE_NOTIFICATION_FAILURE:
      // The request is failed
      return {
        ...state,
        updateNotificationPending: false,
        updateNotificationFeedback: action.feedback,
      };

    case CASE_UPDATE_NOTIFICATION_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        updateNotificationFeedback: null,
      };

    default:
      return state;
  }
}
