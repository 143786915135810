import {
  CASE_FETCH_TEAMS_BEGIN,
  CASE_FETCH_TEAMS_SUCCESS,
  CASE_FETCH_TEAMS_FAILURE,
  CASE_FETCH_TEAMS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchTeams = createPromiseAction(CASE_FETCH_TEAMS_BEGIN, payload => ({
  inputData: payload,
}));

export function dismissFetchTeamsFeedback() {
  return {
    type: CASE_FETCH_TEAMS_DISMISS_FEEDBACK,
  };
}

export function* doFetchTeams(action) {
  const { caseId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/teams`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_TEAMS_FAILURE,
      feedback: { message: 'feedback.fetchTeamsFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_TEAMS_SUCCESS,
    data: { teams: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchTeams() {
  yield takeEvery(fetchTeams, withCurrentCaseId(doFetchTeams));
}

export function useFetchTeams() {
  const dispatch = useDispatch();

  const { teams, fetchTeamsPending, fetchTeamsFeedback } = useSelector(
    state => ({
      teams: state.case.teams,
      fetchTeamsPending: state.case.fetchTeamsPending,
      fetchTeamsFeedback: state.case.fetchTeamsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchTeams(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchTeamsFeedback());
  }, [dispatch]);

  return {
    teams,
    fetchTeams: boundAction,
    fetchTeamsPending,
    fetchTeamsFeedback,
    dismissFetchTeamsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_TEAMS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchTeamsPending: true,
        fetchTeamsFeedback: null,
      };

    case CASE_FETCH_TEAMS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchTeamsPending: false,
        fetchTeamsFeedback: action.feedback,
        teams: action.data.teams,
      };

    case CASE_FETCH_TEAMS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchTeamsPending: false,
        fetchTeamsFeedback: action.feedback,
      };

    case CASE_FETCH_TEAMS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchTeamsFeedback: null,
      };

    default:
      return state;
  }
}
