import React, { useState } from 'react';
import { IconButton, Link, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import history from 'common/history';
import { useUpdateNotification } from 'features/case/redux/updateNotification';
import { Clear, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { notificationsExport } from 'features/case/TableWrapper/utils';

const useStyles = makeStyles(() => ({
  notificationItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  notificationTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const NotificationItem = ({ notificationItem, handleClose }) => {
  const classes = useStyles();
  const { updateNotification } = useUpdateNotification();
  const [showMessage, setShowMessage] = useState(false);
  const readNotification = item => {
    setShowMessage(!showMessage);
    !item.read && updateNotification({ notification: { ...item, read: true } });
  };

  const handleOpenNotificationClicked = itm => {
    history.push(history.location.pathname.replace(/\/case.*/, ``) + `/case${itm.link}`);
  };

  const downloadReport = async () => {
    if (notificationItem?.link && notificationItem?.type === 'batch-upload') {
      await notificationsExport(notificationItem.link);
    }
  };
  return (
    <ListItemButton
      className={!notificationItem.read && classes.notificationItemBgd}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        readNotification(notificationItem);
      }}
      disableRipple
    >
      <ListItemText
        primary={
          <div className={classes.notificationItem}>
            <div className={classes.notificationTitle}>
              <div>
                <span>{notificationItem.title}</span>
                <IconButton
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    readNotification(notificationItem);
                  }}
                  size="small"
                >
                  {showMessage ? (
                    <KeyboardArrowDown fontSize="small" />
                  ) : (
                    <KeyboardArrowRight fontSize="small" />
                  )}
                </IconButton>
              </div>
              {showMessage && (
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={async e => {
                    if (notificationItem.type === 'batch-upload') {
                      await downloadReport();
                    } else {
                      handleOpenNotificationClicked(notificationItem);
                    }
                    handleClose(e);
                  }}
                >
                  {notificationItem.message}
                </Link>
              )}
            </div>
            <IconButton
              size="small"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                updateNotification({ notification: { ...notificationItem, hide: true } });
              }}
            >
              <Clear fontSize="small" />
            </IconButton>
          </div>
        }
        secondary={moment(notificationItem.createdBy.date).fromNow()}
        primaryTypographyProps={{
          sx: {
            fontSize: '1rem',
            fontWeight: `${!notificationItem.read ? '600' : '400'}`,
          },
        }}
        secondaryTypographyProps={{
          sx: {
            pt: '0.5rem',
            fontWeight: `${!notificationItem.read ? '600' : '400'}`,
          },
        }}
      ></ListItemText>
    </ListItemButton>
  );
};

export default NotificationItem;
