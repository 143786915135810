import { call, put, take, takeLatest } from 'redux-saga/effects';

import {
  CASE_EXPORT_FOLDER_BEGIN,
  CASE_EXPORT_FOLDER_SUCCESS,
  CASE_EXPORT_FOLDER_FAILURE,
  CASE_EXPORT_FOLDER_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const exportFolder = createPromiseAction(CASE_EXPORT_FOLDER_BEGIN);

export function dismissExportFolderFeedback() {
  return {
    type: CASE_EXPORT_FOLDER_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doExportFolder(action) {
  const {
    payload: { caseId, folderId },
  } = action;

  const channel = yield call(
    api.createDownloadFileChannel,
    `/cases/${caseId}/folders/${folderId}/export`,
  );

  while (true) {
    const { error, success, response: document } = yield take(channel);
    if (error) {
      yield put({
        type: CASE_EXPORT_FOLDER_FAILURE,
        feedback: {
          message: 'feedback.exportFolderFailure',
          error: error,
          retryAction: action,
        },
      });
      return yield call(rejectPromiseAction, action, error);
    }

    if (success) {
      yield put({
        type: CASE_EXPORT_FOLDER_SUCCESS,
        data: document,
      });
      yield call(resolvePromiseAction, action, document);
      return;
    }
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchExportFolder() {
  yield takeLatest(exportFolder, withCurrentCaseId(doExportFolder));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_EXPORT_FOLDER_BEGIN + '.TRIGGER':
      return {
        ...state,
        exportFolderPending: true,
        exportFolderFeedback: null,
      };

    case CASE_EXPORT_FOLDER_SUCCESS:
      return {
        ...state,
        exportFolderPending: false,
        exportFolderFeedback: action.feedback,
      };

    case CASE_EXPORT_FOLDER_FAILURE:
      return {
        ...state,
        exportFolderPending: false,
        exportFolderFeedback: action.feedback,
      };

    case CASE_EXPORT_FOLDER_DISMISS_FEEDBACK:
      return {
        ...state,
        exportFolderFeedback: null,
      };

    default:
      return state;
  }
}
