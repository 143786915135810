import {
  isIOS,
  isIOS13,
  isIPhone13,
  isIPad13,
  isIPod13,
  isChrome,
  isEdge,
  browserName,
  browserVersion,
} from 'react-device-detect';

export function openNewBackgroundTab(url) {
  window.open(url, '_blank');
}

export const clearSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};

const _isIOS = isIOS || isIOS13 || isIPhone13 || isIPad13 || isIPod13;

export { _isIOS as isIOS };

// for IOS devices this will always return TRUE because
// all browsers on iOS are built using safari's web engine
// therefore we check by Name
export const isSafariOnIOS = _isIOS && browserName.toLowerCase().includes('safari');

export const isSafari = browserName.toLowerCase().includes('safari');

export const isChromeOrEdgeOnNonIOS = !_isIOS && ((isChrome && browserVersion > 80) || isEdge);

export const isBrowserBlacklisted = (() => {
  return false; //!(isSafariOnIOS || isChromeOrEdgeOnNonIOS);
})();
