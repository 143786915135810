import React, { PureComponent } from 'react';
import T from 'i18n';
import TableWrapper from './TableWrapper';

export default class AllDocuments extends PureComponent {
  static propTypes = {};

  render() {
    return <TableWrapper category={T.translate('case.allDocuments')} />;
  }
}
