import React from 'react';
import { Avatar, Box, Chip, Tooltip, Typography } from '@mui/material';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  const splitResult = name.split(' ');
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${splitResult[0][0]}${
      splitResult.length > 1 ? name.split(' ')[1][0] : ''
    }`.toUpperCase(),
  };
}

export const FilterArrayChip = ({ label, items, deleteHandler, labelFunction }: any) => {
  return (
    <Tooltip
      title={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 320,
            justifyContent: 'center',
            p: 1,
          }}
        >
          <Typography component="h4" color="gray" style={{ marginBottom: '0.5rem' }}>
            {label}
          </Typography>
          {items.map((item: any, index: number) => {
            return (
              <Chip
                key={index}
                label={labelFunction ? labelFunction(item) : item}
                variant="filled"
                size="small"
                sx={{
                  justifyContent: 'space-between',
                  fontSize: '1rem',
                  margin: '0.25rem',
                  cursor: 'initial !important',
                }}
                onDelete={() => deleteHandler(item)}
              />
            );
          })}
        </Box>
      }
    >
      <Avatar style={{ width: 24, height: 24, marginRight: '0.5rem' }} {...stringAvatar(label)} />
    </Tooltip>
  );
};
