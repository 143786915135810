import { put, select, call, takeLatest } from 'redux-saga/effects';
import {
  CASE_FETCH_SEARCHED_FILES_BEGIN,
  CASE_FETCH_SEARCHED_FILES_SUCCESS,
  CASE_FETCH_SEARCHED_FILES_FAILURE,
  CASE_FETCH_SEARCHED_FILES_DISMISS_FEEDBACK,
  CASE_FETCH_SEARCHED_FILES_CLEAR,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from 'common/selectors';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import { getNonce } from 'utils/nonce';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

const clearHighlights = documents => {
  for (let i = 0; i < documents.length; i++) {
    documents[i].highlights = null;
  }
  return documents;
};

export const fetchSearchedFiles = createPromiseAction(CASE_FETCH_SEARCHED_FILES_BEGIN);

export const clearSearchedFiles = () => ({ type: CASE_FETCH_SEARCHED_FILES_CLEAR });

export function dismissFetchSearchedFilesFeedback() {
  return {
    type: CASE_FETCH_SEARCHED_FILES_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCUMENTS_BEGIN actions
export function* doFetchSearchedFiles(action) {
  const {
    payload: { caseId, searchTerm, isDocFinderPage, isSearchModePage, isPresentModeWithBackEnd },
  } = action;

  if ((isSearchModePage || isDocFinderPage) && !searchTerm) {
    yield put({
      type: CASE_FETCH_SEARCHED_FILES_SUCCESS,
      data: {
        documents: [],
        answer: null,
        zeroBasedIndex: false,
        isDocFinderPage,
        isSearchModePage,
        isDeepSearchPerformed: false,
      },
    });
    return yield call(resolvePromiseAction, action, []);
  } else if (!searchTerm) {
    yield put({
      type: CASE_FETCH_SEARCHED_FILES_SUCCESS,
      data: {
        isDeepSearchPerformed: false,
      },
    });
    return yield call(resolvePromiseAction, action, []);
  }
  const nonce = getNonce(6);
  const currentFolder = yield select(selectCurrentFolder);

  const res = yield call(
    api.get,
    `/cases/${caseId}/files?nonce=${nonce}&search=${encodeURIComponent(searchTerm)}${
      isDocFinderPage ? '&docfinder=true' : ''
    }${isPresentModeWithBackEnd ? '&presentMode=true' : ''}`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_SEARCHED_FILES_FAILURE,
      feedback: {
        message: 'feedback.fetchSearchedFilesFailure',
        error: res.error,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  if (res.nonce === nonce) {
    yield put({
      type: CASE_FETCH_SEARCHED_FILES_SUCCESS,
      data: {
        documents: res.results,
        answer: res.answer,
        globalPageLookup: res.globalPageLookup,
        zeroBasedIndex: currentFolder.zeroBasedIndex,
        isDocFinderPage,
        isSearchModePage,
        isDeepSearchPerformed: true,
      },
    });
    yield call(resolvePromiseAction, action, res);
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchSearchedFiles() {
  yield takeLatest(fetchSearchedFiles, withCurrentCaseId(doFetchSearchedFiles));
}

export function useFetchSearchedFiles() {
  const dispatch = useDispatch();

  const { documents, answer, fetchDocumentsPending, fetchDocumentsFeedback } = useSelector(
    state => ({
      documents: state.case.documents,
      answer: state.case.answer,
      fetchDocumentsPending: state.case.fetchDocumentsPending,
      fetchDocumentsFeedback: state.case.fetchDocumentsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchSearchedFiles(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchSearchedFilesFeedback());
  }, [dispatch]);

  return {
    documents,
    answer,
    fetchSearchedFiles: boundAction,
    fetchDocumentsPending,
    fetchDocumentsFeedback,
    dismissFetchSearchedFilesFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_SEARCHED_FILES_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchDocumentsPending: true,
        globalPageLookup: null,
        fetchDocumentsFeedback: action.feedback,
        isDeepSearchPerformed: false,
        ...(action.payload.isDocFinderPage || action.payload.isSearchModePage
          ? {
              documents: [],
              answer: null,
              totalDocuments: 0,
            }
          : { documents: clearHighlights(state.documents) }),
      };

    case CASE_FETCH_SEARCHED_FILES_SUCCESS:
      // const addPrevSortNumberToDocuments = (prevItems, newItems) => {
      //   return newItems.map(item => {
      //     const prevItem = prevItems.find(
      //       itm => itm.compositeKey === item.compositeKey || itm.id === item.id,
      //     );
      //     return { ...item, sortOrder: (prevItem && prevItem.sortOrder) || prevItems.length + 1 };
      //   });
      // };
      return {
        ...state,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        ...(action.data.isDocFinderPage || action.data.isSearchModePage
          ? {
              documents: addSortNumberToDocuments(
                action.data.documents,
                action.data.zeroBasedIndex,
              ),
              answer: action.data.answer,
              totalDocuments: action.data.totalDocuments,
              isDeepSearchPerformed: action.data.isDeepSearchPerformed,
            }
          : {
              isDeepSearchPerformed: false,
              ...(action.data.isDeepSearchPerformed &&
                !action.data.globalPageLookup && {
                  documents: state.documents.reduce((sf, item) => {
                    const searchedFile = sf.find(itm => itm.id === item.id);
                    if (searchedFile) {
                      return [
                        ...sf.filter(itm => itm.id !== searchedFile.id),
                        { ...item, highlights: searchedFile.highlights },
                      ];
                    } else return [...sf, item];
                  }, action.data.documents),
                }),
              ...(action.data.isDeepSearchPerformed &&
                action.data.globalPageLookup &&
                action.data.documents.length > 0 && {
                  documents: state.documents.map(doc => {
                    if (doc.id === action.data.documents[0].id)
                      return {
                        ...action.data.documents[0],
                        sortOrder: doc.sortOrder,
                      };
                    else return doc;
                  }),
                }),
            }),
        globalPageLookup: action.data.globalPageLookup ? action.data.documents[0].id : null,
      };

    case CASE_FETCH_SEARCHED_FILES_CLEAR:
      return {
        ...state,
        documents: [],
        answer: null,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_SEARCHED_FILES_FAILURE:
      return {
        ...state,
        fetchDocumentsPending: false,
        globalPageLookup: null,
        fetchDocumentsFeedback: action.feedback,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_SEARCHED_FILES_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
