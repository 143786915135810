import { all } from 'redux-saga/effects';

import * as commonSagas from '../features/common/redux/sagas';
import * as homeSagas from '../features/home/redux/sagas';
import * as caseSagas from '../features/case/redux/sagas';
import * as authSagas from '../features/auth/redux/sagas';
import * as matcherSagas from '../features/matcher/redux/sagas';
import * as viewingSagas from '../features/viewing/redux/sagas';
import * as conferenceSagas from '../features/conference/redux/sagas';
// This file is auto maintained by rekit-plugin-redux-saga,
// you usually don't need to manually edit it.

// NOTE: DO NOT chanage featureSagas declearation pattern, it's used by rekit-plugin-redux-saga.
const featureSagas = [
  commonSagas,
  homeSagas,
  caseSagas,
  authSagas,
  matcherSagas,
  viewingSagas,
  conferenceSagas,
];

const sagas = featureSagas
  .reduce((prev, curr) => [...prev, ...Object.keys(curr).map(k => curr[k])], [])
  // a saga should be function, below filter avoids error if redux/sagas.js is empty;
  .filter(s => typeof s === 'function');

function* rootSaga() {
  yield all(sagas.map(saga => saga()));
}

export default rootSaga;
