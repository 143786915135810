import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useOriginalCopy } from '@huse/previous-value';
import { usePrevious, useFunctionAsState } from 'utils/hooks';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import ListRow from './ListRow';
import DroppableBody from './DroppableBody';
import moment from 'moment';
import { getSortFunction } from './utils';
import DroppableBodyContainerProvider from './DroppableBodyContainerProvider';
import CustomScrollbarsVirtualList from './CustomScrollbarsVirtualList';
import NoDataMessage from './NoDataMessage';
import _ from 'lodash';
import T from 'i18n';
import equal from 'react-fast-compare';
import { isObject } from 'utils/object';
import { isEmpty } from 'utils/objects';

export const getStringValue = (row: any, column: any) => {
  const id = typeof column === 'object' ? column.id : column;
  const value = typeof column === 'object' ? column.accessor(row) : row[id];

  if (!value) return '';

  if (typeof value === 'object') return JSON.stringify(value);

  return value.toString();
};

type FilterProps = {
  data: Array<any>;
  filter: any;
  filterByColumns: Array<any>;
};

// TO-DO remove business logic - no filter by this by that shite

const filterByGlobalPageIdx = (row: any, goToGlobalPageIdx: any) => {
  if (goToGlobalPageIdx) {
    if (!goToGlobalPageIdx.includes('.') && !row['startPage'].includes('.')) {
      const startPage = parseInt(row['startPage'], 10);
      const endPage = parseInt(row['lastPage'], 10);
      const goToPage = parseInt(goToGlobalPageIdx, 10);
      return goToPage >= startPage && goToPage <= endPage;
    } else if (
      (!goToGlobalPageIdx.includes('.') && row['startPage'].includes('.')) ||
      (goToGlobalPageIdx.includes('.') && !row['startPage'].includes('.'))
    ) {
      return false;
    } else if (goToGlobalPageIdx.includes('.') && row['startPage'].includes('.')) {
      const goToGlobalPage = goToGlobalPageIdx.split('.');
      const startPageVal = row['startPage'].split('.');
      const endPageVal = row['lastPage'].split('.');
      const isStartPageFirstDigitAfterDecimalZero =
        startPageVal && startPageVal.length > 1 && startPageVal[1].startsWith('0');
      const isFilterTermFirstDigitAfterDecimalZero =
        goToGlobalPage && goToGlobalPage.length > 1 && goToGlobalPage[1].startsWith('0');
      if (goToGlobalPage.length === 2 && goToGlobalPage[0] === startPageVal[0]) {
        if (isStartPageFirstDigitAfterDecimalZero && isFilterTermFirstDigitAfterDecimalZero) {
          //condition to filter late late inserts - when startpage starts with e.g. 55.01
          const startPage = startPageVal[1].slice(1, startPageVal[1].length);
          const goToPage = goToGlobalPage[1].slice(1, goToGlobalPage[1].length);
          const endPage = endPageVal[1].slice(1, endPageVal[1].length);
          return goToPage >= startPage && goToPage <= endPage;
        } else if (
          !isStartPageFirstDigitAfterDecimalZero &&
          !isFilterTermFirstDigitAfterDecimalZero
        ) {
          const startPage = parseInt(startPageVal[1], 10);
          const endPage = parseInt(endPageVal[1], 10);
          const goToPage = parseInt(goToGlobalPage[1], 10);
          return goToPage >= startPage && goToPage <= endPage;
        }
      }
      return false;
    }
  }
  return true;
};

const filterByArray = (row: any, arr: any, rowKey: any) => {
  if (arr && Object.entries(arr).length > 0) {
    const array: string[] = [];
    for (const key in arr) {
      if (!arr[key]) continue;
      const item = arr[key].toLowerCase();
      array.push(item);
    }
    return (
      row[rowKey] &&
      array.find((item: any) =>
        row[rowKey] && Array.isArray(row[rowKey])
          ? row[rowKey].map((i: any) => i && i.toLowerCase()).includes(item)
          : row[rowKey].toLowerCase().includes(item),
      )
    );
  } else return true;
};

const filterByDate = (row: any, dateFrom: any, dateTo: any) => {
  const rowDate = row['docDate']
    ? new Date(moment(row['docDate'], 'DD/MM/YYYY HH:mm:ss').toDate())
    : null;

  const startDate = dateFrom ? new Date(dateFrom) : null;
  const endDate = dateTo ? new Date(dateTo) : null;

  if (startDate && endDate) {
    return rowDate ? rowDate >= startDate && rowDate <= endDate : false;
  } else if (startDate) {
    return rowDate ? rowDate >= startDate : false;
  } else if (endDate) {
    return rowDate ? rowDate <= endDate : false;
  }
  return true;
};

const filterByAnnotations = (row: any, withAnnotations: any, withoutAnnotations: any) => {
  if (withAnnotations) return row['hasAnnotations'];
  else if (withoutAnnotations) return !row['hasAnnotations'];
  else return true;
};

const filterByShared = (row: any, shared: any, notShared: any) => {
  if (shared) return row['shared'];
  else if (notShared) return !row['shared'];
  else return true;
};

const filterByVisibility = (row: any, publicFiles: any, privateFiles: any) => {
  if (publicFiles) return !row['private'];
  else if (privateFiles) return row['private'];
  else return true;
};

const filterByPublicHyperlinks = (
  row: any,
  withPublicHyperlinks: any,
  withoutPublicHyperlinks: any,
) => {
  if (withPublicHyperlinks) return row['hasPublicHyperlinks'];
  else if (withoutPublicHyperlinks) return !row['hasPublicHyperlinks'];
  else return true;
};

export const filterByLocalPage = (row: any, filterVal: any) => {
  const pageNo = parseInt(filterVal[1], 10);
  if (
    getStringValue(row, 'id')
      .toLowerCase()
      .includes(filterVal[0].toLowerCase())
  ) {
    return pageNo >= 1 && pageNo <= parseInt(row['pageCount'], 10);
  } else return false;
};

export const filterByGlobalPage = (row: any, filterTerm: any) => {
  if (filterTerm) {
    if (/^[a-zA-Z]{1,2}[0-9.]{1,9}?$/.test(filterTerm) && !!row['globalPagePrefix']) {
      const index = filterTerm.toLowerCase().indexOf(row.globalPagePrefix.toLowerCase());
      if (index === 0) {
        const valueAfterPrefix = filterTerm
          .toLowerCase()
          .replace(row.globalPagePrefix.toLowerCase(), '');
        if (!isNaN(valueAfterPrefix)) {
          return filterByGlobalPageIdx(row, valueAfterPrefix);
        } else return false;
      } else return false;
    } else return false;
  }
  return true;
};

export const filterByTrialBundles = (row: any, arr: any) => {
  if (arr && Object.entries(arr).length > 0) {
    const array: string[] = [];
    for (const key in arr) {
      if (!arr[key]) continue;
      const item = arr[key];
      array.push(item);
    }

    return !!(
      row.bundleLocations.length > 0 &&
      row.bundleLocations.find((itm: any) => array.includes(itm.folderId))
    );
  } else return true;
};

export const filterByFilterTerm = (row: any, filterByColumns: any, term: string) => {
  if (term) {
    const columns = filterByColumns ? filterByColumns : Object.keys(row);

    if (/^[0-9]{3,6}[/][0-9]{1,7}?$/.test(term)) return filterByLocalPage(row, term.split('/'));

    // check if global page
    if (/^[a-zA-Z]{1,2}[0-9.]{1,9}?$/.test(term) && filterByGlobalPage(row, term)) return true;

    // check other columns
    for (let i = 0; i < columns.length; i++) {
      if (
        getStringValue(row, columns[i])
          .toLowerCase()
          .includes(term.toLowerCase())
      )
        return true;
    }
    return false;
  } else return true;
};

const filterData = ({ data, filter, filterByColumns }: FilterProps) => {
  if (isEmpty(filter)) {
    return null;
  } else {
    const {
      term,
      goToGlobalPageIdx,
      authors = [],
      recipients = [],
      createdBy = [],
      tags = [],
      dateFrom,
      dateTo,
      withAnnotations,
      withoutAnnotations,
      shared,
      notShared,
      public: publicFiles,
      private: privateFiles,
      withPublicHyperlinks,
      withoutPublicHyperlinks,
      trialBundles = [],
    } = filter;

    const hasOnlyTerm =
      term &&
      !goToGlobalPageIdx &&
      !authors.length &&
      !recipients.length &&
      !dateFrom &&
      !dateTo &&
      !withAnnotations &&
      !withoutAnnotations &&
      !shared &&
      !notShared &&
      !publicFiles &&
      !privateFiles &&
      !withPublicHyperlinks &&
      !withoutPublicHyperlinks &&
      !trialBundles.length;

    const filteredData = data.filter(row => {
      const isInGrid =
        filterByFilterTerm(row, filterByColumns, term) ||
        (row && row.highlights && row.highlights.length > 0) ||
        (row.matches && row.matches.length > 0);

      if (hasOnlyTerm) {
        return isInGrid;
      } else {
        const isInCategory =
          filterByGlobalPageIdx(row, goToGlobalPageIdx) &&
          filterByTrialBundles(row, trialBundles) &&
          filterByArray(row, authors, 'author') &&
          filterByArray(row, recipients, 'recipient') &&
          filterByArray(row.createdBy, createdBy, 'id') &&
          filterByArray(row, tags, 'tags') &&
          filterByDate(row, dateFrom, dateTo) &&
          filterByAnnotations(row, withAnnotations, withoutAnnotations) &&
          filterByShared(row, shared, notShared) &&
          filterByVisibility(row, publicFiles, privateFiles) &&
          filterByPublicHyperlinks(row, withPublicHyperlinks, withoutPublicHyperlinks);

        const entitiesIsInCategoryAndGrid =
          row.entities && isObject(row.entities)
            ? Object.keys(row.entities).reduce(
                (acc, key) =>
                  acc && (key === 'cost' ? true : filterByArray(row.entities, filter[key], key)),
                true,
              )
            : true;

        return isInGrid && isInCategory && entitiesIsInCategoryAndGrid;
      }
    });
    return filteredData;
  }
};

type TableProps = FilterProps & {
  onDoubleClick: (row: any) => void;
  columns: Array<any>;
  sortMode: boolean;
  additionalDragInfo?: Object;
  onRowSelected: (selectedRows: Array<any>) => void;
  updateSort: (index: any, ids: any) => void;
  onFilterChanged?: (filteredRows: Array<any> | null, value: string | null) => void;
  rowHeight?: number;
  readOnly?: boolean;
  defaultMessage?: string;
  setGridList?: Function;
};

export default React.memo(
  React.forwardRef(
    (
      {
        data: dataIn,
        onDoubleClick,
        columns,
        sortMode,
        additionalDragInfo,
        onRowSelected,
        updateSort,
        filter,
        filterByColumns,
        onFilterChanged,
        rowHeight = 37,
        readOnly,
        defaultMessage,
        setGridList,
      }: TableProps,
      ref,
    ) => {
      const [sortFn, setSortFn] = useFunctionAsState(null);
      const [sortStuff, setSortStuff] = useState<any>({});

      const distinctData = useOriginalCopy(dataIn, _.isEqual);

      const prevDistinctData = usePrevious(distinctData);
      const prevFilter = usePrevious(filter);
      const prevSortFn = usePrevious(sortFn);

      const [filteredData, setFilteredData] = useState<null | Array<any>>(null);
      const [sortedData, setSortedData] = useState<null | Array<any>>(null);

      const data = filteredData ? filteredData : sortedData ? sortedData : distinctData;
      const prevData = usePrevious(data, []) as Array<any>;

      useEffect(() => {
        if (!equal(prevData, data)) {
          setGridList && setGridList(data);
        }
      }, [data, prevData, setGridList]);

      // is new filter condition
      useEffect(() => {
        if (_.isEqual(prevFilter, filter)) return;

        // preserve selected rows - if not the same source that we use to filter
        if (data !== (sortedData || distinctData)) {
          (sortedData || distinctData).forEach(item => {
            const foundItem = data.find(
              ({ compositeKey, id }: any) =>
                (compositeKey || id) === (item.compositeKey || item.id),
            );
            if (foundItem) item.selected = foundItem.selected;
            else item.selected = false;
          });
        }

        if (isEmpty(filter)) {
          setFilteredData(null);
          // call on filter change
          onFilterChanged && onFilterChanged(null, null);
          return;
        }

        // filter data
        const filteredDataTemp = filterData({
          data: sortedData || distinctData,
          filter,
          filterByColumns,
        });
        setFilteredData(filteredDataTemp);

        // call on filter change
        onFilterChanged && onFilterChanged(filteredDataTemp, filter);
      }, [prevFilter, filter, filterByColumns, distinctData, sortedData, onFilterChanged, data]);

      // is new sort condition
      useEffect(() => {
        if (prevSortFn === sortFn) return;

        if (!sortFn) {
          // preserve selected rows
          distinctData.forEach(item => {
            const foundItem = data.find(
              ({ compositeKey, id }: any) =>
                (compositeKey || id) === (item.compositeKey || item.id),
            );
            if (foundItem) item.selected = foundItem.selected;
            else item.selected = false;
          });
          // clear sort data
          setSortedData(null);
          // if filtered re-filter regular order
          if (filteredData) {
            const filteredDataTemp = filterData({ data: distinctData, filter, filterByColumns });
            setFilteredData(filteredDataTemp);
          }
          return;
        }

        // sort data
        const sortedDataTemp = distinctData.slice().sort(sortFn);
        setSortedData(sortedDataTemp);
        // sort filtered data
        if (filteredData) {
          const filteredDataTemp = filteredData.slice().sort(sortFn);
          setFilteredData(filteredDataTemp);
        }
      }, [prevSortFn, sortFn, distinctData, filteredData, filter, filterByColumns, data]);

      // is new data
      useEffect(() => {
        if (prevDistinctData === distinctData) return;

        const isSorted = !!sortFn;
        const isFiltered = !!filter;

        // preserve selected rows
        distinctData.forEach(item => {
          const foundItem = prevData?.find(
            ({ compositeKey }: { compositeKey: string }) => compositeKey === item.compositeKey,
          );
          if (foundItem) item.selected = foundItem.selected;
          else item.selected = false;
        });

        // if it's sorted we re-sort and it will get re-filtered automatically
        if (isSorted) {
          setSortedData(distinctData.slice().sort(sortFn));
        }
        // if it's filtered then filter the new data
        else if (isFiltered) {
          setFilteredData(filterData({ data: distinctData, filter, filterByColumns }));
        }

        // update row selected
        onRowSelected &&
          onRowSelected(distinctData.filter(({ selected }: { selected: boolean }) => !!selected));
        return () => {
          if (distinctData.length === 0) {
            setSortFn(null);
            setSortStuff({});
          }
        };
      }, [
        prevDistinctData,
        distinctData,
        filter,
        filterByColumns,
        sortFn,
        onRowSelected,
        prevData,
        setSortFn,
      ]);

      const listRef = useRef();

      const onSortHandler = useCallback(
        ({ sortByColumn, sortDirection, onCustomSort }) => {
          setSortFn(getSortFunction(sortByColumn, sortDirection, onCustomSort));
          setSortStuff({ sortedColumn: sortByColumn, sortedDirection: sortDirection });
        },
        [setSortFn],
      );

      return (
        <div className="common-table">
          {
            //needed so that it doesn't go outside card boundaries
            <div style={{ height: '100%' }}>
              {data && data.length > 0 ? (
                <AutoSizer>
                  {({ height, width }: { height: number; width: number }) => (
                    <DroppableBodyContainerProvider
                      updateSort={updateSort}
                      columns={columns}
                      data={data}
                      sortMode={sortMode}
                      forwardedRef={listRef}
                      onSortHandler={onSortHandler}
                      sortedColumn={sortStuff.sortedColumn}
                      sortedDirection={sortStuff.sortedDirection}
                    >
                      <List
                        outerElementType={CustomScrollbarsVirtualList}
                        innerElementType={DroppableBody}
                        outerRef={listRef}
                        height={height}
                        width={width}
                        itemCount={data.length}
                        itemSize={rowHeight}
                        // useIsScrolling
                        itemData={{
                          data,
                          onDoubleClick,
                          columns,
                          sortMode,
                          additionalDragInfo,
                          onRowSelected,
                          readOnly,
                          forwardedRef: ref,
                        }}
                      >
                        {ListRow}
                      </List>
                    </DroppableBodyContainerProvider>
                  )}
                </AutoSizer>
              ) : (
                <>
                  {filteredData && <NoDataMessage message={T.translate('case.filterNoMatch')} />}
                  {!filteredData && defaultMessage && <NoDataMessage message={defaultMessage} />}
                </>
              )}
            </div>
          }
        </div>
      );
    },
  ),
);
