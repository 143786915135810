import {
  CASE_FETCH_GROUPS_BEGIN,
  CASE_FETCH_GROUPS_SUCCESS,
  CASE_FETCH_GROUPS_FAILURE,
  CASE_FETCH_GROUPS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchGroups = createPromiseAction(CASE_FETCH_GROUPS_BEGIN, payload => ({
  inputData: payload,
}));

export function dismissFetchGroupsFeedback() {
  return {
    type: CASE_FETCH_GROUPS_DISMISS_FEEDBACK,
  };
}

export function* doFetchGroups(action) {
  const { caseId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/groups`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_GROUPS_FAILURE,
      feedback: { message: 'feedback.fetchGroupsFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_GROUPS_SUCCESS,
    data: { groups: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchGroups() {
  yield takeEvery(fetchGroups, withCurrentCaseId(doFetchGroups));
}

export function useFetchGroups() {
  const dispatch = useDispatch();

  const { groups, fetchGroupsPending, fetchGroupsFeedback } = useSelector(
    state => ({
      groups: state.case.groups,
      fetchGroupsPending: state.case.fetchGroupsPending,
      fetchGroupsFeedback: state.case.fetchGroupsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchGroups(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchGroupsFeedback());
  }, [dispatch]);

  return {
    groups,
    fetchGroups: boundAction,
    fetchGroupsPending,
    fetchGroupsFeedback,
    dismissFetchGroupsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_GROUPS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchGroupsPending: true,
        fetchGroupsFeedback: null,
      };

    case CASE_FETCH_GROUPS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchGroupsPending: false,
        fetchGroupsFeedback: action.feedback,
        groups: action.data.groups,
      };

    case CASE_FETCH_GROUPS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchGroupsPending: false,
        fetchGroupsFeedback: action.feedback,
      };

    case CASE_FETCH_GROUPS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchGroupsFeedback: null,
      };

    default:
      return state;
  }
}
