import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Auth from '@aws-amplify/auth';
import { Controller, useForm } from 'react-hook-form';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Logger from 'utils/logger';
import CircularProgress from '@mui/material/CircularProgress';
import { password as passwordRgex } from 'utils/regex';
import T from 'i18n';
import { OtpForm } from 'features/common';
import { otpLength } from './Otp';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    backgroundColor: '#f50057 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  magicText: {
    display: 'flex',
    padding: '1rem 2rem 1rem 1rem',
    backgroundColor: '#F3F3F3',
    borderRadius: '0.25rem',
    marginBottom: '2rem',
    marginTop: '1.5rem',
  },
  error: { height: '1rem' },
});

function ForgotPassword({
  onForgotPasswordCompleted,
  onSignInClick,
  email,
  onForgotPasswordClicked,
}) {
  const classes = useStyles();

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [passwordValue, setPasswordValue] = useState('');
  const [otpValue, setOtpValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassoword, setShowConfirmPassword] = useState(false);

  const verifyForgotPassword = ({ password }) => {
    setLoading(true);
    const username = email;
    const passwordResetCode = otpValue;
    Auth.forgotPasswordSubmit(username, passwordResetCode, password)
      .then(result => {
        onForgotPasswordCompleted(result);
      })
      .catch(err => {
        Logger.ERROR(err);
        if (err.code === 'ExpiredCodeException') setMessage(T.translate('auth.codeExpiredMessage'));
        else if (err.code === 'CodeMismatchException')
          setMessage(T.translate('auth.codeMissmatchMessage'));
        else setMessage(T.translate('auth.otherIssueMessage'));
      })
      .finally(() => setLoading(false));
  };

  const reSendCode = event => {
    event.preventDefault();
    const username = email;
    setLoading(true);
    Auth.forgotPassword(username)
      .then(result => {
        onForgotPasswordClicked(email, result);
      })
      .catch(e => {
        Logger.ERROR(e);
        if (e.code === 'UserNotFoundException') onForgotPasswordClicked(email, {});
        else setMessage(T.translate('auth.otherIssueMessage'));
      })
      .finally(() => setLoading(false));
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Avatar className={classes.avatar} sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {T.translate('auth.setPassword')}
        </Typography>
        <Typography className={classes.error} color="error" component="h1" variant="h6">
          {message}
        </Typography>
        <form
          onSubmit={handleSubmit(verifyForgotPassword)}
          className={classes.form}
          style={{ marginTop: '2rem' }}
          noValidate
        >
          <OtpForm
            defaultValue={otpValue}
            label={T.translate('auth.passwordResetCode')}
            setOtpValue={setOtpValue}
            setDisableSubmit={setDisableSubmit}
          />
          <Box className={classes.magicText}>
            <span>{T.translate('auth.otpMsg')}</span>
          </Box>
          <Controller
            name="password"
            rules={{
              required: true,
              pattern: passwordRgex.eightCharsNumberLowerUpperSpecial,
            }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={T.translate('auth.newPassword')}
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                onChange={e => {
                  setPasswordValue(e.target.value);
                  field.onChange(e.target.value);
                }}
                error={errors.password}
                helperText={
                  <>
                    {!errors.password && (
                      <span style={{ fontSize: '0.9rem' }}>
                        {T.translate('auth.passwordInvalid')}
                      </span>
                    )}
                    {errors.password &&
                      T.translate(
                        errors.password.type === 'required'
                          ? 'auth.passwordRequired'
                          : 'auth.passwordInvalid',
                      )}
                  </>
                }
                disabled={otpValue.length !== otpLength}
                style={{ minHeight: '6rem', marginTop: 0 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={handleMouseDownPassword}
                        disabled={otpValue.length !== otpLength}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            rules={{
              required: true,
              validate: {
                noMatch: value => value && value === passwordValue,
              },
            }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="confirmPassword"
                variant="outlined"
                required
                fullWidth
                label={T.translate('auth.confirmPassword')}
                type={showConfirmPassoword ? 'text' : 'password'}
                autoComplete="new-confirm-password"
                error={errors.confirmPassword}
                helperText={
                  errors.confirmPassword &&
                  T.translate(
                    errors.confirmPassword.type === 'noMatch'
                      ? 'auth.passwordNotMatch'
                      : 'auth.confirmPasswordRequired',
                  )
                }
                disabled={otpValue.length !== otpLength}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassoword)}
                        onMouseDown={handleMouseDownPassword}
                        disabled={otpValue.length !== otpLength}
                      >
                        {showConfirmPassoword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ minHeight: '6rem', marginTop: '0.7rem' }}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mb: 2 }}
            disabled={loading || disableSubmit}
          >
            {loading && <CircularProgress size={17} />}
            {!loading && T.translate('auth.submit')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link component="button" variant="body2" onClick={onSignInClick}>
                {T.translate('auth.backToSignIn')}
              </Link>
            </Grid>
            <Grid item>
              <Link component="button" variant="body2" onClick={reSendCode}>
                {T.translate('auth.reSendCode')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

ForgotPassword.propTypes = {};
ForgotPassword.defaultProps = {};

export default ForgotPassword;
