import React from 'react';
import T from 'i18n';
import { Grid, IconButton } from '@mui/material';
import NorthIcon from '@mui/icons-material/North';
import TwitterIcon from '@mui/icons-material/Twitter';
import TrialView_Logo from 'images/TrialView_Logo.png';
import { easeUpToAValue } from 'utils/animate';
import fsqs from 'images/fsqs.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const FooterAddress = ({ currentWindowWidth }) => {
  return (
    <>
      <Grid
        item
        xs={currentWindowWidth > 767 ? 4 : 'auto'}
        sx={{ marginTop: '1.2rem', ...(currentWindowWidth < 767 && { ml: '1.2rem' }) }}
      >
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item sx={{ mb: '1.5rem' }}>
            <span style={{ fontWeight: 500, lineHeight: '1.6rem' }}>
              {T.translate('home.dublinAddress')}
            </span>
          </Grid>
          <Grid item sx={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: 500, lineHeight: '1.6rem' }}>
              {T.translate('home.dublinContact')}
            </span>
            <a
              style={{ color: 'inherit' }}
              href={`mailto:${T.translate('home.dublinEmail')}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {T.translate('home.dublinEmail')}
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={currentWindowWidth > 767 ? 4 : 'auto'}
        sx={{ marginTop: '1.2rem', ...(currentWindowWidth < 767 && { ml: '1.2rem' }) }}
      >
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item sx={{ mb: '1.5rem' }}>
            <span style={{ fontWeight: 500, lineHeight: '1.6rem' }}>
              {T.translate('home.londonAddress')}
            </span>
          </Grid>
          <Grid item>
            <span style={{ fontWeight: 500, lineHeight: '1.6rem' }}>
              {T.translate('home.londonContact')}
            </span>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const TVFooter = () => {
  const currentWindowWidth = window.innerWidth;

  const scrollToTop = () => {
    const cnt = document.getElementById('home-page-container-scroll');
    if (cnt) {
      const centerElement = cnt.clientHeight / 2;
      const startPoint = cnt.scrollTop + centerElement;
      const maxPoint = cnt.scrollHeight - centerElement;
      easeUpToAValue(
        startPoint,
        0,
        value => cnt.scrollTo(cnt.scrollLeft, value - centerElement),
        maxPoint,
      ).catch((error) => {
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  return (
    <>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#0B022E',
          color: '#FFFFFF',
          paddingTop: '2%',
          paddingLeft: '7%',
          paddingRight: '7%',
          paddingBottom: '1%',
        }}
      >
        <Grid item xs={currentWindowWidth > 767 ? 11 : 6} id="home-page-container-scroll">
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: `${currentWindowWidth > 767 ? 'row' : 'column'}`,
            }}
          >
            <Grid item xs={currentWindowWidth > 767 ? 4 : 'auto'}>
              <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid item>
                  <img src={TrialView_Logo} alt="TV logo" />
                </Grid>
                <Grid item sx={{ marginLeft: '1.2rem' }}>
                  Build: {process.env.REACT_APP_VERSION}
                </Grid>
                <Grid item sx={{ marginTop: '2rem' }}>
                  <IconButton
                    sx={{
                      color: '#EFF5FB',
                      backgroundColor: '#D86B57',
                      marginLeft: '1.2rem',
                      '&:hover': { backgroundColor: '#D86B57' },
                    }}
                    size="large"
                    onClick={() => window.open('https://www.linkedin.com/company/trialview/')}
                  >
                    <FontAwesomeIcon
                      icon={faLinkedinIn}
                      alt="LinkedIn"
                      style={{ fontSize: '1.5rem', height: '1.5rem', width: '1.5rem' }}
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      color: '#EFF5FB',
                      backgroundColor: '#D86B57',
                      marginLeft: '1.2rem',
                      '&:hover': { backgroundColor: '#D86B57' },
                    }}
                    size="large"
                    onClick={() => window.open('https://twitter.com/trialviewltd')}
                  >
                    <TwitterIcon />
                  </IconButton>
                </Grid>
                <Grid item sx={{ marginTop: '2rem', marginLeft: '1.2rem' }}>
                  <a
                    style={{ color: 'inherit' }}
                    href={T.translate('home.securityTextLink')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {T.translate('home.privacyPolicy')}
                  </a>
                </Grid>
              </Grid>
            </Grid>
            {currentWindowWidth > 767 && <FooterAddress currentWindowWidth={currentWindowWidth} />}
          </Grid>
        </Grid>
        <Grid item xs={currentWindowWidth > 767 ? 1 : 6}>
          <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item>
              <IconButton
                sx={{
                  color: '#D86B57',
                  backgroundColor: '#EFF5FB',
                  float: 'right',
                  marginTop: '1.2rem',
                  marginRight: '0.5rem',
                  ...(currentWindowWidth < 767 && { ml: '1.2rem' }),
                  '&:hover': { backgroundColor: '#EFF5FB' },
                }}
                size="large"
                onClick={scrollToTop}
              >
                <NorthIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <img src={fsqs} alt="FSQS" style={{ float: 'right', marginTop: '2rem' }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {currentWindowWidth < 767 && (
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            color: '#FFFFFF',
            backgroundColor: '#0B022E',
            paddingLeft: '7%',
            paddingRight: '7%',
            paddingBottom: '7%',
            marginTop: '-0.3%',
          }}
        >
          <FooterAddress currentWindowWidth={currentWindowWidth} />
        </Grid>
      )}
    </>
  );
};

export default TVFooter;
