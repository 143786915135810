import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_FETCH_FOLDERS_BEGIN,
  CASE_FETCH_FOLDERS_SUCCESS,
  CASE_FETCH_FOLDERS_FAILURE,
  CASE_FETCH_FOLDERS_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export function fetchFolders() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_FETCH_FOLDERS_BEGIN,
  };
}

export function dismissFetchFoldersFeedback() {
  return {
    type: CASE_FETCH_FOLDERS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doFetchFolders(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/folders`);

  if (res && res.error) {
    return yield put({
      type: CASE_FETCH_FOLDERS_FAILURE,
      feedback: { message: 'feedback.fetchFoldersFailure', error: res.error, retryAction: action },
    });
  }

  yield put({
    type: CASE_FETCH_FOLDERS_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchFolders() {
  yield takeLatest([CASE_FETCH_FOLDERS_BEGIN], withCurrentCaseId(doFetchFolders));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_FOLDERS_BEGIN:
      return {
        ...state,
        fetchFoldersPending: true,
        fetchFoldersFeedback: null,
      };

    case CASE_FETCH_FOLDERS_SUCCESS:
      return {
        ...state,
        fetchFoldersPending: false,
        fetchFoldersFeedback: action.feedback,
        folders: action.data,
      };

    case CASE_FETCH_FOLDERS_FAILURE:
      return {
        ...state,
        fetchFoldersPending: false,
        fetchFoldersFeedback: action.feedback,
      };

    case CASE_FETCH_FOLDERS_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchFoldersFeedback: null,
      };

    default:
      return state;
  }
}
