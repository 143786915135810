// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  theatreData: {},
  privateTheatreData: {},
  annotations: [],
  filteredAnnotations: [],
  downloadedDocuments: [],
  document: null,
  videoLocation: null,
  goToPageNumber: null,
  docSearch: '',
  docSearchLocation: null,
  currentPageNumber: null,
  sendMessagePending: false,
  sendMessageFeedback: null,
  fetchTheatreDataPending: false,
  fetchTheatreDataFeedback: null,
  startPresentingPending: false,
  startPresentingFeedback: null,
  leaveAGroupPending: false,
  leaveAGroupFeedback: null,
  fetchViewingSessionIdPending: false,
  fetchViewingSessionIdFeedback: null,
  fetchAnnotationsPending: false,
  fetchAnnotationsFeedback: null,
  addAnnotationPending: false,
  addAnnotationFeedback: null,
  deleteAnnotationPending: false,
  deleteAnnotationFeedback: null,
  updateAnnotationPending: false,
  updateAnnotationFeedback: null,
  downloadDocumentsPending: false,
  downloadDocumentsFeedback: null,
  fetchDocumentsLookupPending: false,
  fetchDocumentsLookupFeedback: null,
  fetchFileLocationPending: false,
  fetchFileLocationFeedback: null,
  burnAnnotationPending: false,
  burnAnnotationFeedback: null,
  updateAnnotationSeqPending: false,
  updateAnnotationSeqFeedback: null,
  fetchRecordingPending: false,
  recording: null,
  prevRecording: null,
  fetchRecordingFeedback: null,
  updateRecordingPending: false,
  updateRecordingFeedback: null,
  updateTranscriptItemPending: false,
  updateTranscriptItemFeedback: null,
};

export default initialState;
