import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'i18n';
import { Button, Grid, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DataRoomViewer from 'images/DataRoomViewer.png';
import DataRoom from 'images/DataRoom.png';
import HearingRoom from 'images/HearingRoom.png';
import folderStructure from 'images/folderStructure.png';
import * as actions from 'features/auth/redux/actions';
import withAuth from 'features/auth/withAuth';
import {
  selectFetchCasesPending,
  selectIsAdmin,
  selectIsAuthenticated,
  selectMatchedUrl,
  selectUserName,
} from 'common/selectors';
import Cases from './Cases';
import { Spinner, TVFooter, TVHeader } from 'features/common';
import { homePageMode } from './enums';
import { useFetchTemplates } from 'features/case/redux/fetchTemplates';
import Templates from './Templates';
import PerfectScrollbar from 'react-perfect-scrollbar';

const defaultSmallScreenSize = 991;

const useStyles = makeStyles({
  headerGrid: {
    left: '0',
    right: '0',
    top: '0',
    background: 'linear-gradient(90deg, #4567E5 0%, #7BA0F4 100%)',
    backgroundBlendMode: 'multiply',
  },
  welcomeMsg: {
    marginBottom: '2rem',
    fontWeight: 600,
    fontSize: '5rem',
    lineHeight: '6.2rem',
  },
  welcomeMsgSubTitle: {
    marginBottom: '2rem',
    fontWeight: 400,
    fontSize: '2rem',
    lineHeight: '3rem',
  },
  accessCasesBtnContainer: {
    marginBottom: '1.5rem',
  },
  accessCasesBtn: {
    borderRadius: '14.5px',
    backgroundColor: '#D86B57',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '2rem',
    marginBottom: '2rem',
    '&:hover': {
      backgroundColor: '#D86B57',
      color: '#FFFFFF',
      borderColor: '#D86B57',
    },
  },
  dataRoomViewerImg: {
    position: 'absolute',
    marginTop: '-10%',
    marginLeft: '22%',
    height: '20vw',
    width: '35vw',
  },
  dataRoomImg: {
    position: 'absolute',
    marginTop: '11%',
    marginLeft: '14%',
    height: '20vw',
    width: '35vw',
  },
  hearingRoomImg: {
    position: 'absolute',
    marginTop: '30%',
    marginLeft: '5%',
    height: '20vw',
    width: '35vw',
  },
  folderStructureImg: {
    float: 'right',
    width: '40vh',
  },
  '@media (max-width: 991px)': {
    dataRoomViewerImg: {
      position: 'absolute',
      marginTop: 0,
      marginLeft: '19%',
      height: '30vw',
      width: '55vw',
    },
    dataRoomImg: {
      position: 'absolute',
      marginTop: '16%',
      marginLeft: '12%',
      height: '30vw',
      width: '55vw',
    },
    hearingRoomImg: {
      position: 'absolute',
      marginTop: '31%',
      marginLeft: '4%',
      height: '30vw',
      width: '55vw',
    },
    folderStructureImg: {
      float: 'none',
      marginTop: '2rem',
      width: '30vh',
      height: '30vh',
    },
  },
  '@media (max-width: 550px)': {
    welcomeMsg: {
      marginBottom: '1.5rem',
      fontWeight: 600,
      fontSize: '3rem',
      lineHeight: '4rem',
    },
    welcomeMsgSubTitle: {
      marginBottom: '1.5rem',
      fontWeight: 500,
      fontSize: '1.6rem',
      lineHeight: '2.5rem',
    },
    accessCasesBtnContainer: {
      marginBottom: '2.5rem',
    },
    dataRoomViewerImg: {
      position: 'absolute',
      marginTop: 0,
      marginLeft: '19%',
      height: '15vh',
      width: '30vh',
    },
    dataRoomImg: {
      position: 'absolute',
      marginTop: '19%',
      marginLeft: '12%',
      height: '15vh',
      width: '30vh',
    },
    hearingRoomImg: {
      position: 'absolute',
      marginTop: '36%',
      marginLeft: '4%',
      height: '15vh',
      width: '30vh',
    },
    folderStructureImg: {
      float: 'none',
      marginTop: '2rem',
      width: '25vh',
      height: '25vh',
    },
  },
});

const HomePage = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const loginType = useSelector(selectMatchedUrl).replace('/', '');
  const fetchCasesPending = useSelector(selectFetchCasesPending);
  const { fetchTemplatesPending } = useFetchTemplates();
  const userName = useSelector(selectUserName);
  const isSystemAdmin = useSelector(selectIsAdmin);
  const [screenWidth, setScreenWidth] = useState('');
  const [homePageView, setHomePageView] = useState(homePageMode.cases);

  const handleTabChange = (e, val) => setHomePageView(val);

  const onMount = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(onMount, []);

  useEffect(() => {
    dispatch(actions.loginRequired(loginType));
  }, [loginType, dispatch]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
    <PerfectScrollbar className={styles.scrollContainer} id="home-page-container-scroll">
      <Grid className={styles.headerGrid} sx={{ ...(!isAuthenticated && { height: '95%' }) }}>
        <TVHeader isHomePage={true} isMobileScreen={screenWidth < 551} />
        <Grid
          container
          sx={{
            display: 'flex',
            flexDirection: screenWidth > defaultSmallScreenSize ? 'row' : 'column',
            ...(isAuthenticated ? { padding: '1% 8% 3% 12%' } : { margin: '5% 8% 5% 12%' }),
            width: 'auto',
          }}
        >
          <Grid
            item
            sx={{ color: '#FFFFFF' }}
            xs={screenWidth > defaultSmallScreenSize ? 6 : 'auto'}
          >
            <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
              <Grid item className={styles.welcomeMsg}>
                {isAuthenticated
                  ? T.translate('home.welcomeUserMsg', { name: userName })
                  : T.translate('home.welcomeMsg')}
              </Grid>
              <Grid item className={styles.welcomeMsgSubTitle}>
                {isAuthenticated
                  ? T.translate('home.welcomeUserMsgSubTitle')
                  : T.translate('home.welcomeMsgSubTitle')}
              </Grid>
              {!isAuthenticated && (
                <Grid item className={styles.accessCasesBtnContainer}>
                  <Button
                    variant="outlined"
                    className={styles.accessCasesBtn}
                    onClick={e => {
                      dispatch(actions.loginRequired());
                      e.preventDefault();
                    }}
                  >
                    {T.translate('home.accessCases')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {screenWidth > 380 &&
            (isAuthenticated ? (
              <Grid item xs={screenWidth > defaultSmallScreenSize ? 6 : 'auto'}>
                <img
                  src={folderStructure}
                  alt="folder structure"
                  className={styles.folderStructureImg}
                />
              </Grid>
            ) : (
              <Grid
                item
                sx={{ position: 'relative' }}
                xs={screenWidth > defaultSmallScreenSize ? 6 : 'auto'}
              >
                <img
                  src={DataRoomViewer}
                  alt="data room with viewer"
                  className={styles.dataRoomViewerImg}
                />
                <img src={DataRoom} alt="data room" className={styles.dataRoomImg} />
                <img src={HearingRoom} alt="hearing room" className={styles.hearingRoomImg} />
              </Grid>
            ))}
        </Grid>
      </Grid>
      {isAuthenticated && (
        <Grid
          container
          sx={{
            position: 'relative',
            justifyContent: 'center',
            ...(!isSystemAdmin && { mt: '2rem' }),
            ...((fetchCasesPending || fetchTemplatesPending) && { mb: '2rem' }),
          }}
        >
          {isSystemAdmin && (
            <Grid item sx={{ ...(homePageView === homePageMode.cases && { mb: '2rem' }) }}>
              <Tabs value={homePageView} onChange={handleTabChange}>
                <Tab value={homePageMode.cases} label="Cases" sx={{ fontSize: '1rem' }} />
                <Tab value={homePageMode.templates} label="Templates" sx={{ fontSize: '1rem' }} />
              </Tabs>
            </Grid>
          )}
          {homePageView === homePageMode.cases ? (
            <Grid item>{fetchCasesPending ? <Spinner /> : <Cases />}</Grid>
          ) : (
            <Grid item xs={12}>
              {fetchTemplatesPending ? <Spinner /> : <Templates />}
            </Grid>
          )}
        </Grid>
      )}
      <Grid container>
        <TVFooter />
      </Grid>
      </PerfectScrollbar>
    </div>
    
  );
};

export default withAuth(HomePage, { forceLogin: false });
