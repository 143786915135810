import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CASE_ACCEPT_TSANDCS_BEGIN,
  CASE_ACCEPT_TSANDCS_SUCCESS,
  CASE_ACCEPT_TSANDCS_FAILURE,
  CASE_ACCEPT_TSANDCS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const acceptTsAndCs = createPromiseAction(CASE_ACCEPT_TSANDCS_BEGIN, payload => ({
  caseId: payload,
}));

export function dismissAcceptTsAndCsFeedback() {
  return {
    type: CASE_ACCEPT_TSANDCS_FEEDBACK,
  };
}

// worker Saga: will be fired on HOME_FETCH_TSANDCS_BEGIN actions
export function* doAcceptTsAndCs(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.post, `/cases/${caseId}/terms-accept`);

  if (res && res.error) {
    yield put({
      type: CASE_ACCEPT_TSANDCS_FAILURE,
      feedback: {
        message: 'feedback.acceptTsAndCsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_ACCEPT_TSANDCS_SUCCESS,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchAcceptTsAndCs() {
  yield takeLatest(acceptTsAndCs, doAcceptTsAndCs);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_ACCEPT_TSANDCS_BEGIN + '.TRIGGER':
      return {
        ...state,
        acceptTsAndCsPending: true,
        acceptTsAndCsFeedback: null,
      };

    case CASE_ACCEPT_TSANDCS_SUCCESS:
      return {
        ...state,
        acceptTsAndCsPending: false,
        acceptTsAndCsFeedback: action.feedback,
      };

    case CASE_ACCEPT_TSANDCS_FAILURE:
      return {
        ...state,
        acceptTsAndCsPending: false,
        acceptTsAndCsFeedback: action.feedback,
      };

    default:
      return state;
  }
}
