import { selectDocuments } from 'common/selectors';
import React, { useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector } from 'react-redux';

type CustomScrollbarsProps = {
  onScroll: () => void;
  style: React.CSSProperties;
  children: JSX.Element;
};

export const CustomScrollbars = ({
  onScroll,
  forwardedRef,
  style,
  children,
}: CustomScrollbarsProps & { forwardedRef: any }) => {
  const documents = useSelector(selectDocuments);
  const refSetter = useCallback(
    scrollbarsRef => {
      if (scrollbarsRef) {
        const currentUrlHashVal = window.location.hash;
        const hashFileId = currentUrlHashVal.split('#')[1];
        const fileIndex = documents.findIndex((item: any) => item.id === hashFileId);
        if (fileIndex > 10) scrollbarsRef.scrollTop = 37 * (fileIndex - 10);
        forwardedRef(scrollbarsRef);
      } else {
        forwardedRef(null);
      }
    },
    [forwardedRef, documents],
  );

  return (
    <PerfectScrollbar
      containerRef={refSetter}
      style={style}
      options={{ minScrollbarLength: 20 }}
      onScroll={onScroll}
    >
      {children}
    </PerfectScrollbar>
  );
};

export default React.forwardRef((props: CustomScrollbarsProps, ref: any) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));
