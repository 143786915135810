import { shallowEqual, useDispatch } from 'react-redux';
import { CASE_SET_FILTER_OPTIONS } from './constants';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';

export function setFilterOptions(filterOptions) {
  return {
    type: CASE_SET_FILTER_OPTIONS,
    filterOptions,
  };
}

export function useSetFilterOptions() {
  const dispatch = useDispatch();

  const { filterOptions } = useSelector(
    state => ({
      filterOptions: state.case.filterOptions,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(setFilterOptions(...args));
    },
    [dispatch],
  );

  return {
    setFilterOptions: boundAction,
    filterOptions: filterOptions,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_SET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      };

    default:
      return state;
  }
}
