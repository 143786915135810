import {
  CASE_UPDATE_TEAM_MEMBER_BEGIN,
  CASE_UPDATE_TEAM_MEMBER_SUCCESS,
  CASE_UPDATE_TEAM_MEMBER_FAILURE,
  CASE_UPDATE_TEAM_MEMBER_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

export const updateTeamMember = createPromiseAction(CASE_UPDATE_TEAM_MEMBER_BEGIN);

export function dismissUpdateTeamMemberFeedback() {
  return {
    type: CASE_UPDATE_TEAM_MEMBER_DISMISS_FEEDBACK,
  };
}

export function* doUpdateTeamMember(action) {
  const { caseId, teamId, user } = action.payload;

  let res = yield call(api.put, `/cases/${caseId}/teams/${teamId}/users/${user.id}`, user);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_TEAM_MEMBER_FAILURE,
      feedback: {
        message: 'feedback.updateTeamMemberFailure',
        params: { userEmail: user.mail },
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  const updatedUser = res.users.find(item => item.id === user.id);

  yield put({
    type: CASE_UPDATE_TEAM_MEMBER_SUCCESS,
    data: { team: res, updatedUser, user },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateTeamMember() {
  yield takeEvery(updateTeamMember, withCurrentCaseId(doUpdateTeamMember));
}

export function reducer(state, action) {
  const getIndex = userId => state.team && state.team.users.findIndex(({ id }) => id === userId);
  const getTeamIndex = teamId => state.teams && state.teams.findIndex(({ id }) => id === teamId);

  switch (action.type) {
    case CASE_UPDATE_TEAM_MEMBER_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateTeamMemberPending: true,
        updateTeamMemberFeedback: null,
      };

    case CASE_UPDATE_TEAM_MEMBER_SUCCESS:
      // The request is success
      return {
        ...state,
        updateTeamMemberPending: false,
        updateTeamMemberFeedback: action.feedback,
        ...(state.team &&
          state.team.id === action.data.team.id && {
            team: {
              ...action.data.team,
              users: replaceItemImmutable(
                state.team.users,
                action.data.updatedUser,
                getIndex(action.data.user.id),
              ),
            },
          }),
        ...(state.teams && {
          teams: replaceItemImmutable(
            state.teams,
            action.data.team,
            getTeamIndex(action.data.team.id),
          ),
        }),
      };

    case CASE_UPDATE_TEAM_MEMBER_FAILURE:
      // The request is failed
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        updateTeamMemberPending: false,
        updateTeamMemberFeedback: action.feedback,
        ...(concurencyError && {
          team: {
            ...action.data.team,
            users: replaceItemImmutable(
              state.team.users,
              action.feedback.error.body.users.find(item => item.id === action.data.user.id),
              getIndex(action.data.user.id),
            ),
          },
        }),
      };

    case CASE_UPDATE_TEAM_MEMBER_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateTeamMemberFeedback: null,
      };

    default:
      return state;
  }
}
