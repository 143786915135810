import './wdyr.js';
import 'symbol-observable';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
// styles import / css import / import styles / import css
import './styles/index.scss';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './i18n';

ReactDOM.render(<Root />, document.getElementById('root'));

/* istanbul ignore if  */
if (module.hot) {
  // Enable Webpack hot module replacement for translation
  module.hot.accept('./i18n', () => {
    ReactDOM.render(<Root />, document.getElementById('root'));
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
