import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTv, faLock, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import { connect } from 'react-redux';
import classnames from 'classnames';

import StickyDropdown from './StickyDropdown';
import { selectCurrentCase, selectPermissions, selectEvidenceViewEnabled } from 'common/selectors';
import { updateUserBehaviour } from 'features/common/redux/updateUserBehaviour';

class HearingRoom extends Component {
  static propTypes = {};

  trackDropDownButtonClick = labelKey => {
    this.props.actions.updateUserBehaviour({
      caseId: this.props.caseId,
      name: `Drop Down ${T.translate(labelKey)} Clicked`,
    });
  };

  render() {
    const notification = (
      <div className="d-inline-flex">
        <FontAwesomeIcon icon={faTv} />
        <p className="d-none  ml-3">{T.translate('case.theatre')}</p>
      </div>
    );

    const showPrivateHearingRoom =
      (this.props.canJoinConference('private') || this.props.canStream('private')) &&
      this.props.canViewDocuments('private');
    const showPublicHearingRoom =
      (this.props.canJoinConference('public') || this.props.canStream('public')) &&
      this.props.canViewDocuments('public');
    const showDocumentsOnlyPublic = this.props.canViewDocuments('public');
    const showConferenceOnlyPublic =
      (!(
        this.props.isEvidenceViewEnabled &&
        this.props.conferenceType &&
        this.props.conferenceType('public')
      ) &&
        this.props.canJoinConference('public')) ||
      this.props.canStream('public');

    const openInNewTab =
      !window.location.href.includes('private-team-room') &&
      !window.location.href.includes('public-hearing-room');

    const children = (
      <React.Fragment>
        {showPrivateHearingRoom && (
          <NavDropdown.Item
            {...(openInNewTab && { target: '_blank' })}
            href={`/case/${this.props.caseId}/private-team-room`}
            onClick={() => this.trackDropDownButtonClick('home.privateRoom')}
          >
            <div className="d-inline-flex">
              <span className="fa-layers fa-fw mr-3" style={{ width: '22px', alignSelf: 'center' }}>
                <FontAwesomeIcon icon={faTv} />
                <FontAwesomeIcon icon={faLock} transform="shrink-5 up-4 right-6" />
              </span>
              <p>{T.translate('case.privateHearingRoom')}</p>
            </div>
          </NavDropdown.Item>
        )}
        {showPrivateHearingRoom &&
          (showPublicHearingRoom || showDocumentsOnlyPublic || showConferenceOnlyPublic) && (
            <NavDropdown.Divider />
          )}
        {showPublicHearingRoom && (
          <NavDropdown.Item
            {...(openInNewTab && { target: '_blank' })}
            href={`/case/${this.props.caseId}/public-hearing-room`}
            onClick={() => this.trackDropDownButtonClick('home.hearingRoom')}
          >
            <div className="d-inline-flex">
              <FontAwesomeIcon
                className="mr-3"
                style={{ width: '22px', alignSelf: 'center' }}
                icon={faTv}
              />
              <p>{T.translate('case.publicHearingRoom')}</p>
            </div>
          </NavDropdown.Item>
        )}
        {showConferenceOnlyPublic && (
          <NavDropdown.Item
            {...(openInNewTab && { target: '_blank' })}
            href={`/case/${this.props.caseId}/public-hearing-room/conference`}
            onClick={() => this.trackDropDownButtonClick('case.publicHearingRoomConferenceOnly')}
          >
            <div className="d-inline-flex">
              <FontAwesomeIcon
                className="mr-3"
                style={{ width: '22px', alignSelf: 'center' }}
                icon={faTv}
              />
              <p>{T.translate('case.publicHearingRoomConferenceOnly')}</p>
            </div>
          </NavDropdown.Item>
        )}
        {showDocumentsOnlyPublic && (
          <NavDropdown.Item
            href={`/case/${this.props.caseId}/public-hearing-room/document-presentation`}
            target="_blank"
            onClick={() => this.trackDropDownButtonClick('home.hearingRoomDocsOnly')}
          >
            <div className="d-inline-flex">
              <FontAwesomeIcon
                className="mr-3"
                style={{ width: '22px', alignSelf: 'center' }}
                icon={faFileAlt}
              />
              <p>{T.translate('case.publicHearingRoomDocumentOnly')}</p>
            </div>
          </NavDropdown.Item>
        )}
      </React.Fragment>
    );

    return (
      <div className={classnames(this.props.className, 'common-hearing-room')}>
        <StickyDropdown alignRight title={notification} className="d-none">
          {children}
        </StickyDropdown>
        <NavDropdown alignRight title={notification} className="d-l-none">
          {children}
        </NavDropdown>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const {
    hearingRoom: { canJoinConference, canViewDocuments, canStream, conferenceType },
  } = selectPermissions(state);

  const isEvidenceViewEnabled = selectEvidenceViewEnabled(state);
  return {
    caseId: selectCurrentCase(state).id,
    canJoinConference,
    canViewDocuments,
    canStream,
    isEvidenceViewEnabled,
    conferenceType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateUserBehaviour }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HearingRoom);
