import React, { useState } from 'react';
import T from 'i18n';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { FiltersStyles } from 'features/common/Filters/FiltersStyles.js';
import TextField from '@mui/material/TextField';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { TimePicker } from '@mui/x-date-pickers';
import { startOfDay, endOfDay } from 'date-fns';

const date = new Date();
export const defaultFilter = { from: startOfDay(date), to: endOfDay(date) };

const FilterPresentedDocuments = React.memo(({ documentsFilter, setDocumentsFilter }) => {
  const classes = FiltersStyles();
  const [value, setValue] = useState(documentsFilter);

  const clearFilters = () => {
    setValue(defaultFilter);
    setDocumentsFilter(defaultFilter);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <List
          subheader={
            <ListSubheader
              style={{ position: 'relative' }}
              className={classes.listSubheaderHeading}
            >
              {T.translate('filters.filters')}
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.clearButton}
                onClick={clearFilters}
              >
                {T.translate('filters.clear')}
              </Button>
            </ListSubheader>
          }
          className={classes.paper}
        >
          <Divider style={{ marginBottom: '0.5rem' }} />
          <ListItem>
            <TimePicker
              label={T.translate('generic.from')}
              value={value.from}
              onChange={(v, iv) => {
                setValue(prev => ({ ...prev, from: v }));
                setDocumentsFilter(prev => ({ ...prev, from: v }));
              }}
              renderInput={params => <TextField {...params} size="small" />}
            />
          </ListItem>
          <ListItem style={{ marginBottom: '0.5rem' }}>
            <TimePicker
              label={T.translate('generic.to')}
              value={value.to}
              onChange={(v, iv) => {
                setValue(prev => ({ ...prev, to: v }));
                setDocumentsFilter(prev => ({ ...prev, to: v }));
              }}
              renderInput={params => <TextField {...params} size="small" />}
            />
          </ListItem>
        </List>
      </Box>
    </LocalizationProvider>
  );
});

export default FilterPresentedDocuments;
