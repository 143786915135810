import React, { useState, useEffect, useCallback } from 'react';

interface TypingEffectProps {
  responseRef: any;
  response: string;
  typingSpeed: number;
  scrollBarRef: any;
  elementsAfterText?: React.ReactNode[];
}

export default function TypingEffect({
  responseRef,
  response,
  typingSpeed,
  scrollBarRef,
  elementsAfterText,
}: TypingEffectProps) {
  const [typedText, setTypedText] = useState<string>('');
  const [charIndex, setCharIndex] = useState<number>(0);

  const updateScrollBar = useCallback(() => {
    if (scrollBarRef && scrollBarRef.current) {
      scrollBarRef.current.scrollTop = scrollBarRef.current.scrollHeight;
    }
  }, [scrollBarRef]);

  useEffect(() => {
    if (charIndex < response.length) {
      const timer = setTimeout(() => {
        setTypedText(prev => prev + response[charIndex]);
        setCharIndex(charIndex + 1);
        updateScrollBar();
      }, typingSpeed);

      return () => clearTimeout(timer);
    }
  }, [charIndex, response, typingSpeed, updateScrollBar]);

  return (
    <>
      <span ref={responseRef}>{typedText.split('\n').map(line => line)}</span>
      {charIndex === response.length && elementsAfterText && elementsAfterText}
    </>
  );
}
