import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  CASE_FETCH_FOLDER_BEGIN,
  CASE_FETCH_FOLDER_SUCCESS,
  CASE_FETCH_FOLDER_FAILURE,
  CASE_FETCH_FOLDER_DISMISS_FEEDBACK,
  CASE_ASSIGN_ALL_DOCUMENTS_SUCCESS,
  CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS,
  CASE_UPDATE_DOCUMENT_SUCCESS,
  CASE_UPDATE_DOCUMENT_TAB_SUCCESS,
  CASE_UPDATE_DOCUMENT_SORT_SUCCESS,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { selectCurrentFolder, withCurrentCaseId } from '../../../common/selectors';
import { replaceItemImmutableDeepNested } from 'utils/arrays';

export const fetchFolder = createPromiseAction(CASE_FETCH_FOLDER_BEGIN);

export function dismissFetchFolderFeedback() {
  return {
    type: CASE_FETCH_FOLDER_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_FOLDER_BEGIN actions
export function* doFetchFolder(action) {
  const {
    payload: { folderId, noPromise = true, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);

  if (!currentFolder.id && !folderId) return;
  const res = yield call(api.get, `/cases/${caseId}/folders/${folderId || currentFolder.id}`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_FOLDER_FAILURE,
      feedback: { message: 'feedback.fetchFolderFailure', error: res.error, retryAction: action },
    });
    if (!noPromise) return yield call(rejectPromiseAction, action, res.error);
    else return;
  }

  yield put({
    type: CASE_FETCH_FOLDER_SUCCESS,
    data: res,
  });
  if (!noPromise) yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchFolder() {
  yield takeLatest(
    [
      fetchFolder,
      CASE_FETCH_FOLDER_BEGIN,
      CASE_ASSIGN_ALL_DOCUMENTS_SUCCESS,
      CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS,
      CASE_UPDATE_DOCUMENT_SUCCESS,
      CASE_UPDATE_DOCUMENT_TAB_SUCCESS,
      CASE_UPDATE_DOCUMENT_SORT_SUCCESS,
    ],
    withCurrentCaseId(doFetchFolder),
  );
}

const getNewFolders = (folders, folder) => {
  const folderTypeMap = {
    bundle: 'bundles',
    'team-bundle': 'teamBundles',
    'private-bundle': 'privateBundles',
    'court-bundle': 'courtBundles',
    trialbook: 'trialbooks',
  };
  return {
    ...folders,
    [folderTypeMap[folder.type]]: replaceItemImmutableDeepNested(
      folders[folderTypeMap[folder.type]],
      folder,
    ),
  };
};

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_FOLDER_BEGIN:
      return {
        ...state,
        fetchFolderPending: true,
        fetchFolderFeedback: null,
      };

    case CASE_FETCH_FOLDER_SUCCESS:
      return {
        ...state,
        fetchFolderPending: false,
        fetchFolderFeedback: action.feedback,
        folders: getNewFolders(state.folders, action.data),
        fetchFolderAfterSortPending: false,
      };

    case CASE_FETCH_FOLDER_FAILURE:
      return {
        ...state,
        fetchFolderPending: false,
        fetchFolderFeedback: action.feedback,
        fetchFolderAfterSortPending: false,
      };

    case CASE_FETCH_FOLDER_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchFolderFeedback: null,
      };

    default:
      return state;
  }
}
