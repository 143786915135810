// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as sendMessageReducer } from './sendMessage';
import { reducer as fetchTheatreDataReducer } from './fetchTheatreData';
import { reducer as startPresentingReducer } from './startPresenting';
import { reducer as leaveAGroupReducer } from './leaveAGroup';
import { reducer as fetchViewingSessionIdReducer } from './fetchViewingSessionId';
import { reducer as downloadDocumentReducer } from './downloadDocument';
import { reducer as fetchAnnotationsReducer } from './fetchAnnotations';
import { reducer as addAnnotationReducer } from './addAnnotation';
import { reducer as deleteAnnotationReducer } from './deleteAnnotation';
import { reducer as updateAnnotationReducer } from './updateAnnotation';
import { reducer as fetchDocumentsLookupReducer } from './fetchDocumentsLookup';
import { reducer as fetchFileLocationReducer } from './fetchFileLocation';
import { reducer as burnAnnotationReducer } from './burnAnnotation';
import { reducer as clearAnnotationsReducer } from './clearAnnotations';
import { reducer as updateAnnotationTagsReducer } from './updateAnnotationTags';
import { reducer as setPageNumberReducer } from './setPageNumber';
import { reducer as updateAnnotationSeqReducer } from './updateAnnotationSeq';
import { reducer as setDocSearchReducer } from './setDocSearch';
import { reducer as fetchRecordingReducer } from './fetchRecording';
import { reducer as fetchDocsPresentedReducer } from './fetchDocsPresented';
import { reducer as updateRecordingReducer } from './updateRecording';
import { reducer as updateTranscriptItemReducer } from './updateTranscriptItem';

const reducers = [
  sendMessageReducer,
  fetchTheatreDataReducer,
  startPresentingReducer,
  leaveAGroupReducer,
  fetchViewingSessionIdReducer,
  downloadDocumentReducer,
  fetchAnnotationsReducer,
  addAnnotationReducer,
  deleteAnnotationReducer,
  updateAnnotationReducer,
  fetchDocumentsLookupReducer,
  fetchFileLocationReducer,
  burnAnnotationReducer,
  clearAnnotationsReducer,
  updateAnnotationTagsReducer,
  setPageNumberReducer,
  updateAnnotationSeqReducer,
  fetchRecordingReducer,
  setDocSearchReducer,
  fetchDocsPresentedReducer,
  updateRecordingReducer,
  updateTranscriptItemReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
