import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import T from 'i18n';
import { faColumns } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

export default ({ toggle, shown, className }) => {
  return (
    <div className={classnames({ shown }, className)}>
      <OverlayTrigger
        placement="left"
        delay={{ show: 1000, hide: 400 }}
        overlay={
          <Popover>
            <Popover.Content>
              {T.translate(shown ? 'case.hideDocument' : 'case.showDocument')}
            </Popover.Content>
          </Popover>
        }
      >
        <Nav.Link className="d-inline-flex" onClick={toggle}>
          <FontAwesomeIcon icon={faColumns} />
        </Nav.Link>
      </OverlayTrigger>
    </div>
  );
};
