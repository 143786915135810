export const COMMON_FETCH_NOTIFICATIONS_BEGIN = 'COMMON_FETCH_NOTIFICATIONS_BEGIN';
export const COMMON_FETCH_NOTIFICATIONS_SUCCESS = 'COMMON_FETCH_NOTIFICATIONS_SUCCESS';
export const COMMON_FETCH_NOTIFICATIONS_FAILURE = 'COMMON_FETCH_NOTIFICATIONS_FAILURE';
export const COMMON_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK =
  'COMMON_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK';
export const COMMON_FETCH_NOTIFICATION_SUCCESS = 'COMMON_FETCH_NOTIFICATION_SUCCESS';
export const COMMON_SELECT_ROWS = 'COMMON_SELECT_ROWS';
export const COMMON_FETCH_USERS_BEGIN = 'COMMON_FETCH_USERS_BEGIN';
export const COMMON_FETCH_USERS_SUCCESS = 'COMMON_FETCH_USERS_SUCCESS';
export const COMMON_FETCH_USERS_FAILURE = 'COMMON_FETCH_USERS_FAILURE';
export const COMMON_FETCH_USERS_DISMISS_FEEDBACK = 'COMMON_FETCH_USERS_DISMISS_FEEDBACK';
export const COMMON_FETCH_ROLES_BEGIN = 'COMMON_FETCH_ROLES_BEGIN';
export const COMMON_FETCH_ROLES_SUCCESS = 'COMMON_FETCH_ROLES_SUCCESS';
export const COMMON_FETCH_ROLES_FAILURE = 'COMMON_FETCH_ROLES_FAILURE';
export const COMMON_FETCH_ROLES_DISMISS_FEEDBACK = 'COMMON_FETCH_ROLES_DISMISS_FEEDBACK';
export const COMMON_NON_SUPPORTED_BROWSER = 'COMMON_NON_SUPPORTED_BROWSER';
export const COMMON_FETCH_USER_SETTINGS_BEGIN = 'COMMON_FETCH_USER_DETAILS_BEGIN';
export const COMMON_FETCH_USER_SETTINGS_SUCCESS = 'COMMON_FETCH_USER_DETAILS_SUCCESS';
export const COMMON_FETCH_USER_SETTINGS_FAILURE = 'COMMON_FETCH_USER_DETAILS_FAILURE';
export const COMMON_FETCH_USER_SETTINGS_DISMISS_FEEDBACK =
  'COMMON_FETCH_USER_DETAILS_DISMISS_FEEDBACK';
export const COMMON_UPDATE_USER_SETTINGS_BEGIN = 'COMMON_UPDATE_USER_SETTINGS_BEGIN';
export const COMMON_UPDATE_USER_SETTINGS_SUCCESS = 'COMMON_UPDATE_USER_SETTINGS_SUCCESS';
export const COMMON_UPDATE_USER_SETTINGS_FAILURE = 'COMMON_UPDATE_USER_SETTINGS_FAILURE';
export const COMMON_UPDATE_USER_SETTINGS_DISMISS_FEEDBACK =
  'COMMON_UPDATE_USER_SETTINGS_DISMISS_FEEDBACK';
export const COMMON_FETCH_USER_DETAILS_BEGIN = 'COMMON_FETCH_USER_DETAILS_BEGIN';
export const COMMON_FETCH_USER_DETAILS_SUCCESS = 'COMMON_FETCH_USER_DETAILS_SUCCESS';
export const COMMON_FETCH_USER_DETAILS_FAILURE = 'COMMON_FETCH_USER_DETAILS_FAILURE';
export const COMMON_FETCH_USER_DETAILS_DISMISS_FEEDBACK =
  'COMMON_FETCH_USER_DETAILS_DISMISS_FEEDBACK';
export const COMMON_FETCH_SYSTEM_STATUS_BEGIN = 'COMMON_FETCH_SYSTEM_STATUS_BEGIN';
export const COMMON_FETCH_SYSTEM_STATUS_SUCCESS = 'COMMON_FETCH_SYSTEM_STATUS_SUCCESS';
export const COMMON_FETCH_SYSTEM_STATUS_FAILURE = 'COMMON_FETCH_SYSTEM_STATUS_FAILURE';
export const COMMON_FETCH_SYSTEM_STATUS_DISMISS_FEEDBACK =
  'COMMON_FETCH_SYSTEM_STATUS_DISMISS_FEEDBACK';
export const UPDATE_USER_BEHAVIOUR_BEGIN = 'UPDATE_USER_BEHAVIOUR_BEGIN';
export const UPDATE_USER_BEHAVIOUR_SUCCESS = 'UPDATE_USER_BEHAVIOUR_SUCCESS';
export const UPDATE_USER_BEHAVIOUR_FAILURE = 'UPDATE_USER_BEHAVIOUR_FAILURE';
export const UPDATE_USER_BEHAVIOUR_DISMISS_FEEDBACK = 'UPDATE_USER_BEHAVIOUR_DISMISS_FEEDBACK';
export const COMMON_FETCH_ALLPERMISSIONS_BEGIN = 'COMMON_FETCH_ALLPERMISSIONS_BEGIN';
export const COMMON_FETCH_ALLPERMISSIONS_SUCCESS = 'COMMON_FETCH_ALLPERMISSIONS_SUCCESS';
export const COMMON_FETCH_ALLPERMISSIONS_FAILURE = 'COMMON_FETCH_ALLPERMISSIONS_FAILURE';
export const COMMON_FETCH_ALLPERMISSIONS_DISMISS_FEEDBACK =
  'COMMON_FETCH_ALLPERMISSIONS_DISMISS_FEEDBACK';
export const COMMON_FETCH_ROLES_BY_TYPE_BEGIN = 'COMMON_FETCH_ROLES_BY_TYPEBEGIN';
export const COMMON_FETCH_ROLES_BY_TYPE_SUCCESS = 'COMMON_FETCH_ROLES_BY_TYPESUCCESS';
export const COMMON_FETCH_ROLES_BY_TYPE_FAILURE = 'COMMON_FETCH_ROLES_BY_TYPEFAILURE';
export const COMMON_FETCH_ROLES_BY_TYPE_DISMISS_FEEDBACK =
  'COMMON_FETCH_ROLES_BY_TYPEDISMISS_FEEDBACK';
export const COMMON_FETCH_HEALTHCHECK_BEGIN = 'COMMON_FETCH_HEALTHCHECK_BEGIN';
export const COMMON_FETCH_HEALTHCHECK_SUCCESS = 'COMMON_FETCH_HEALTHCHECK_SUCCESS';
export const COMMON_FETCH_HEALTHCHECK_FAILURE = 'COMMON_FETCH_HEALTHCHECK_FAILURE';
export const COMMON_FETCH_HEALTHCHECK_DISMISS_FEEDBACK =
  'COMMON_FETCH_HEALTHCHECK_DISMISS_FEEDBACK';
export const COMMON_ADD_BOOKMARK_BEGIN = 'COMMON_ADD_BOOKMARK_BEGIN';
export const COMMON_ADD_BOOKMARK_SUCCESS = 'COMMON_ADD_BOOKMARK_SUCCESS';
export const COMMON_ADD_BOOKMARK_FAILURE = 'COMMON_ADD_BOOKMARK_FAILURE';
export const COMMON_ADD_BOOKMARK_DISMISS_FEEDBACK = 'COMMON_ADD_BOOKMARK_DISMISS_FEEDBACK';
export const COMMON_DELETE_BOOKMARK_BEGIN = 'COMMON_DELETE_BOOKMARK_BEGIN';
export const COMMON_DELETE_BOOKMARK_SUCCESS = 'COMMON_DELETE_BOOKMARK_SUCCESS';
export const COMMON_DELETE_BOOKMARK_FAILURE = 'COMMON_DELETE_BOOKMARK_FAILURE';
export const COMMON_DELETE_BOOKMARK_DISMISS_FEEDBACK = 'COMMON_DELETE_BOOKMARK_DISMISS_FEEDBACK';
export const COMMON_FETCH_BOOKMARKS_BEGIN = 'COMMON_FETCH_BOOKMARKS_BEGIN';
export const COMMON_FETCH_BOOKMARKS_SUCCESS = 'COMMON_FETCH_BOOKMARKS_SUCCESS';
export const COMMON_FETCH_BOOKMARKS_FAILURE = 'COMMON_FETCH_BOOKMARKS_FAILURE';
export const COMMON_FETCH_BOOKMARKS_DISMISS_FEEDBACK = 'COMMON_FETCH_BOOKMARKS_DISMISS_FEEDBACK';
