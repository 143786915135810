import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Auth from '@aws-amplify/auth';
import { useForm } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';
import T from 'i18n';
import Box from '@mui/material/Box';
import { OtpForm } from 'features/common';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1rem',
  },
  avatar: {
    backgroundColor: '#f50057 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '2rem',
  },
  magicText: {
    display: 'flex',
    padding: '1rem 2rem 1rem 1rem',
    backgroundColor: '#F3F3F3',
    borderRadius: '0.25rem',
    marginTop: '1.5rem',
  },
  error: { height: '1rem' },
});

function ConfirmSignUp({
  email,
  resendCode,
  confirmSignUpCompleted,
  onSignInClick,
  onResendVerificationEmailClicked,
}) {
  const classes = useStyles();

  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [message, setMessage] = useState(null);
  const [otpValue, setOtpValue] = useState('');

  useEffect(() => {
    if (resendCode) resendVerificationCode();
  }, [resendCode]);

  const confirmSignUpPassword = data => {
    setLoading(true);
    Auth.confirmSignUp(email, otpValue)
      .then(result => {
        confirmSignUpCompleted(result);
      })
      .catch(err => {
        console.error(err);
        if (err.code === 'NotAuthorizedException')
          setMessage(T.translate('auth.codeExpiredMessage'));
        else if (err.code === 'CodeMismatchException')
          setMessage(T.translate('auth.codeMissmatchMessage'));
        else setMessage(T.translate('auth.otherIssueMessage'));
      })
      .finally(() => setLoading(false));
  };

  const resendVerificationCode = event => {
    if (!resendCode) event.preventDefault();
    setLoading(true);
    const username = email;
    Auth.resendSignUp(username)
      .then(result => {
        onResendVerificationEmailClicked(email, result);
      })
      .catch(e => {
        console.error(e);
        setMessage(T.translate('auth.otherIssueMessage'));
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Avatar className={classes.avatar} sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {T.translate('auth.verify')}
        </Typography>
        <Typography className={classes.error} color="error" component="h1" variant="h6">
          {message}
        </Typography>
        <form onSubmit={handleSubmit(confirmSignUpPassword)} className={classes.form} noValidate>
          <OtpForm
            defaultValue={otpValue}
            label={T.translate('auth.verificationCode')}
            setOtpValue={setOtpValue}
            setDisableSubmit={setDisableSubmit}
          />
          <Box className={classes.magicText}>
            <span>{T.translate('auth.otpVerificationMsg')}</span>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, fontSize: '1rem' }}
            disabled={loading || disableSubmit}
          >
            {loading && <CircularProgress size={17} />}
            {!loading && T.translate('auth.confirmCode')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component="button"
                variant="body2"
                onClick={onSignInClick}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.backToSignIn')}
              </Link>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={resendVerificationCode}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.reSendCode')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

ConfirmSignUp.propTypes = {};
ConfirmSignUp.defaultProps = {};

export default ConfirmSignUp;
