export const playerActions = {
  PLAY: 'PLAY',
  PAUSE: 'PAUSE',
  MUTE: 'MUTE',
  UNMUTE: 'UNMUTE',
  SEEK: 'SEEK',
  SHOWCONTROLS: 'SHOW CONTROLS',
  HIDECONTROLS: 'HIDE CONTROLS',
  SHOWSUBTITLES: 'SHOW SUBTITLES',
  HIDESUBTITLES: 'HIDE SUBTITLES',
};

export const subtitlesModes = {
  SHOWING: 'showing',
  HIDDEN: 'hidden',
};

export const transcriptModes = {
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  VIEWOPTIONS: 'VIEWOPTIONS',
  ASSIGNSPEAKERS: 'ASSIGNSPEAKERS',
};
