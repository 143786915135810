import {
  CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_BEGIN,
  CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_SUCCESS,
  CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_FAILURE,
  CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import { replaceItemImmutable } from 'utils/arrays';

const getFileProps = (file, ring) => ({
  path: file.path,
  name: file.name,
  lastModified: file.lastModified,
  size: file.size,
  type: file.type,
  confidentialityRing: ring,
});

export const prepareFile = (file, ring) => {
  const meta = new Blob([JSON.stringify(getFileProps(file, ring))], {
    type: 'application/json',
  });

  const formData = new FormData();
  formData.append('', file);
  formData.append('', meta);

  return formData;
};

export const uploadConfidentialityRingFiles = createPromiseAction(
  CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_BEGIN,
);

export function dismissUploadConfidentialityRingFilesFeedback() {
  return {
    type: CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_DISMISS_FEEDBACK,
  };
}

export function* doUploadConfidentialityRingFiles(action) {
  const { caseId, fileId, file, ring } = action.payload;
  const fileToSend = prepareFile(file, ring);

  const channel = yield call(
    api.createUploadFileChannel,
    'POST',
    `/cases/${caseId}/files/${fileId}/rings`,
    fileToSend,
  );

  while (true) {
    const { error, success, response: document } = yield take(channel);
    if (error) {
      yield put({
        type: CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_FAILURE,
        feedback: {
          message: 'feedback.uploadConfidentialityRingFilesFailure',
          error: error.body,
          retryAction: action,
        },
      });
      return yield call(rejectPromiseAction, action, error);
    }
    if (success) {
      yield put({
        type: CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_SUCCESS,
        data: { doc: document },
      });
      yield call(resolvePromiseAction, action, document);
    }
  }
}

export function* watchUploadConfidentialityRingFiles() {
  yield takeLatest(
    uploadConfidentialityRingFiles,
    withCurrentCaseId(doUploadConfidentialityRingFiles),
  );
}

export function useUploadConfidentialityRingFiles() {
  const dispatch = useDispatch();

  const {
    uploadConfidentialityRingFilesPending,
    uploadConfidentialityRingFilesFeedback,
  } = useSelector(
    state => ({
      uploadConfidentialityRingFilesPending: state.case.uploadConfidentialityRingFilesPending,
      uploadConfidentialityRingFilesFeedback: state.case.uploadConfidentialityRingFilesFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(uploadConfidentialityRingFiles(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUploadConfidentialityRingFilesFeedback());
  }, [dispatch]);

  return {
    uploadConfidentialityRingFiles: boundAction,
    uploadConfidentialityRingFilesPending,
    uploadConfidentialityRingFilesFeedback,
    dismissUploadConfidentialityRingFilesFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        uploadConfidentialityRingFilesPending: true,
        uploadConfidentialityRingFilesFeedback: null,
      };

    case CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_SUCCESS:
      // The request is success
      const getIndex = documentId => state.documents.findIndex(({ id }) => id === documentId);
      return {
        ...state,
        uploadConfidentialityRingFilesPending: false,
        documents: addSortNumberToDocuments(
          replaceItemImmutable(
            state.documents,
            {
              ...state.documents[getIndex(action.data.doc.id)],
              confidentialityRing: action.data.doc.confidentialityRing,
            },
            getIndex(action.data.doc.id),
          ),
          action.data.zeroBasedIndex,
        ),
        uploadConfidentialityRingFilesFeedback: action.feedback,
      };

    case CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_FAILURE:
      // The request is failed
      return {
        ...state,
        uploadConfidentialityRingFilesPending: false,
        uploadConfidentialityRingFilesFeedback: action.feedback,
      };

    case CASE_UPLOAD_CONFIDENTIALITY_RING_FILES_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        uploadConfidentialityRingFilesFeedback: null,
      };

    default:
      return state;
  }
}
