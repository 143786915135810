import React from 'react';
import T from 'i18n';
import List from '@mui/material/List';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';
import { Box, ListItemButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { FiltersStyles } from '../../../../common/Filters/FiltersStyles.js';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { selectDocumentAnnotationsFilter } from 'common/selectors';

type FilterAnnotationsProps = {
  filterHandler: (filter: any) => void;
};

export default ({ filterHandler }: FilterAnnotationsProps) => {
  const classes = FiltersStyles() as any;
  const filter = useSelector(selectDocumentAnnotationsFilter);
  const createdByValues = filter.createdBy ? Object.values(filter.createdBy) : [];
  const createdByChecked = createdByValues.every(v => v)
    ? true
    : createdByValues.some(v => v)
    ? null
    : false;

  const setFilters = (newFilter: any) => {
    filterHandler(newFilter);
  };

  const clearFilters = () => {
    const clearFilter = {
      createdBy: Object.keys(filter.createdBy).reduce((a, key) => ({ ...a, [key]: true }), {}),
      hyperlinks: true,
      crossReference: true,
    };
    filterHandler(clearFilter);
  };

  const handleToggle = (e: any) => {
    const newFilterCategory = { ...filter, [e.target.name]: e.target.checked };
    setFilters(newFilterCategory);
  };

  const handleToggleCreatedBy = (e: any) => {
    const newFilterCategory = {
      ...filter,
      createdBy: { ...filter.createdBy, [e.target.name]: e.target.checked },
    };
    setFilters(newFilterCategory);
  };

  const handleToggleCreatedByAll = (e: any) => {
    const newFilterCategory = {
      ...filter,
      createdBy: Object.keys(filter.createdBy).reduce(
        (a, key) => ({ ...a, [key]: !createdByChecked }),
        {},
      ),
    };
    setFilters(newFilterCategory);
  };

  return (
    <Box>
      <List
        subheader={
          <ListSubheader className={classes.listSubheaderHeading}>
            {T.translate('filters.filters')}
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.clearButton}
              onClick={clearFilters}
            >
              {T.translate('filters.clear')}
            </Button>
          </ListSubheader>
        }
        className={classes.paper}
      >
        <Divider key="FilterAnnotationsDivider" />
        <PerfectScrollbar
          key="FilterAnnotationsContainer"
          options={{ suppressScrollX: true }}
          style={{ maxHeight: '25rem' }}
        >
          {filter &&
            Object.keys(filter).map((filterKey, index) =>
              filterKey === 'createdBy' ? (
                <React.Fragment key={`FilterAnnotationsFragmentCreatedBy${index}`}>
                  <Divider key={`FilterAnnotationsDividerCreatedBy${index}`} />
                  <ListItemButton key={`FilterAnnotationsListItemButton${index}`}>
                    <ListItemText
                      primary={'Created by '}
                      primaryTypographyProps={{
                        style: { fontWeight: 'bold', fontSize: '0.95rem' },
                      }}
                    />
                    <ListItemSecondaryAction>
                      {createdByChecked === null ? (
                        <div
                          style={{
                            backgroundColor: '#1976d2',
                            borderRadius: '7px',
                            width: '26px',
                            opacity: 0.5,
                            height: '10px',
                            margin: '7px',
                            color: 'white',
                            fontSize: '2rem',
                            textAlign: 'center',
                            lineHeight: 0.25,
                          }}
                          onClick={handleToggleCreatedByAll}
                        >
                          -
                        </div>
                      ) : (
                        <Switch
                          name={'ceatedBy'}
                          size="small"
                          color="primary"
                          onChange={handleToggleCreatedByAll}
                          checked={createdByChecked}
                          sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': { color: '#7BA0F4' },
                          }}
                        />
                      )}
                    </ListItemSecondaryAction>
                  </ListItemButton>
                  {Object.keys(filter.createdBy).map(key => (
                    <ListItemButton key={`listItemButton${index}-${key}`}>
                      <ListItemText
                        primary={key}
                        primaryTypographyProps={{
                          style: { paddingLeft: '1rem', fontSize: '0.9rem' },
                        }}
                      />
                      <ListItemSecondaryAction>
                        <Switch
                          name={key}
                          size="small"
                          color="primary"
                          onChange={handleToggleCreatedBy}
                          checked={filter.createdBy[key]}
                          sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: '#7BA0F4' } }}
                        />
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  ))}
                </React.Fragment>
              ) : (
                <React.Fragment key={`FilterAnnotationsFragment${filterKey}${index}`}>
                  <Divider key={`FilterAnnotationsDivider${filterKey}${index}`} />
                  <ListItemButton key={`FilterAnnotationsListItemButton${filterKey}${index}`}>
                    <ListItemText primary={T.translate(`filters.${filterKey}`)} />
                    <ListItemSecondaryAction>
                      <Switch
                        name={filterKey}
                        size="small"
                        color="primary"
                        onChange={handleToggle}
                        checked={filter[filterKey]}
                        sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: '#7BA0F4' } }}
                      />
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </React.Fragment>
              ),
            )}
        </PerfectScrollbar>
      </List>
    </Box>
  );
};
