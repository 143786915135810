import React from 'react';
import { selectPermissions, selectWhepStreamingEnabled, selectWhepStreamingUrls } from 'common/selectors';
import { useSelector } from 'react-redux';

import ToggleStreamingButton from './components/Buttons/ToggleStreamingButton'

export default function Streaming ({ className }) {

  const { moderator } = useSelector(selectPermissions);
  const whepStreamingEnabled = useSelector(selectWhepStreamingEnabled);
  const { whipUrl } = useSelector(selectWhepStreamingUrls);


  return whepStreamingEnabled && moderator.canStartStopLiveStream ? (
    <div className={className}>
      <ToggleStreamingButton whipUrl={whipUrl} />
    </div>
  ) : null;
};