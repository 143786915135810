import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  CASE_DELETE_FOLDER_BEGIN,
  CASE_DELETE_FOLDER_SUCCESS,
  CASE_DELETE_FOLDER_FAILURE,
  CASE_DELETE_FOLDER_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { deleteItemImmutableDeepNested } from 'utils/arrays';
import { withCurrentCaseId, selectMatchedPath, selectCurrentFolder } from 'common/selectors';
import { generatePath } from 'react-router-dom';
import history from 'common/history';

export const deleteFolder = createPromiseAction(CASE_DELETE_FOLDER_BEGIN, payload => ({
  folder: payload,
}));

export function dismissDeleteFolderFeedback() {
  return {
    type: CASE_DELETE_FOLDER_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_REMOVE_BUNDLE_BEGIN actions
export function* doDeleteFolder(action) {
  const {
    payload: { folder, caseId },
  } = action;
  const res = yield call(api.del, `/cases/${caseId}/folders/${folder.id}`);

  if (res && res.error) {
    yield put({
      type: CASE_DELETE_FOLDER_FAILURE,
      feedback: { message: 'feedback.deleteFolderFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  const currentFolder = yield select(selectCurrentFolder);
  let matchedPath = yield select(selectMatchedPath);
  if (currentFolder.id === folder.id) {
    if (folder.type === 'private-bundle') {
      matchedPath = matchedPath.replace(/\/privateBundles\/(.*)/, '');
    } else if (folder.type === 'team-bundle') {
      matchedPath = matchedPath.replace(/\/teamBundles\/(.*)/, '');
    } else if (folder.type === 'bundle') {
      matchedPath = matchedPath.replace(/\/bundles\/(.*)/, '');
    } else if (folder.type === 'trialbook') {
      matchedPath = matchedPath.replace(/\/trialbundles\/(.*)/, '');
    }
    const path = generatePath(matchedPath, {
      case: caseId,
      ...(folder.type === 'team-bundle' && { teamBundle: folder.code }),
      ...(folder.type === 'private-bundle' && { privateBundle: folder.code }),
      ...(folder.type === 'trialbook' && { trialbook: folder.code }),
      ...(folder.type === 'bundle' && { bundle: folder.code }),
    });
    history.replace(path);
  }

  yield put({
    type: CASE_DELETE_FOLDER_SUCCESS,
    data: { folder },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchDeleteFolder() {
  yield takeEvery(deleteFolder, withCurrentCaseId(doDeleteFolder));
}

const getNewFolders = (folders, folder) => {
  switch (folder.type) {
    case 'team-bundle':
      return {
        ...folders,
        teamBundles: deleteItemImmutableDeepNested(folders.teamBundles, folder.id),
      };
    case 'private-bundle':
      return {
        ...folders,
        privateBundles: deleteItemImmutableDeepNested(folders.privateBundles, folder.id),
      };
    case 'bundle':
      return {
        ...folders,
        bundles: deleteItemImmutableDeepNested(folders.bundles, folder.id),
      };
    case 'courtBundle':
      return {
        ...folders,
        courtBundles: deleteItemImmutableDeepNested(folders.courtBundles, folder.id),
      };
    case 'trialbook':
      return {
        ...folders,
        trialbooks: deleteItemImmutableDeepNested(folders.trialbooks, folder.id),
      };
    default:
      return folders;
  }
};

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_DELETE_FOLDER_BEGIN + '.TRIGGER':
      return {
        ...state,
        deleteFolderPending: true,
        deleteFolderFeedback: null,
      };

    case CASE_DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        deleteFolderPending: false,
        deleteFolderFeedback: action.feedback,
        //folders: getNewFolders(state.folders, action.data.folder),
      };

    case CASE_DELETE_FOLDER_FAILURE:
      return {
        ...state,
        deleteFolderPending: false,
        deleteFolderFeedback: action.feedback,
      };

    case CASE_DELETE_FOLDER_DISMISS_FEEDBACK:
      return {
        ...state,
        deleteFolderFeedback: null,
      };

    default:
      return state;
  }
}
