import React, { useState, useEffect, useCallback } from 'react';
// import PropTypes from 'prop-types';
import { useLoginRequired } from 'features/auth/redux/hooks';
import Dialog from '@mui/material/Dialog';
import { SignIn, SignUp, ConfirmSignUp, AddPhone, Otp, ForgotPassword } from '.';
import { makeStyles } from '@mui/styles';
import Auth from '@aws-amplify/auth';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from './redux/logout';
import { useUpdateUserSession } from './redux/updateUserSession';
import { login } from './redux/login';
import TroubleSigningIn from './TroubleSigningIn';
import Logger from 'utils/logger';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { selectIsAuthenticated, selectQuery } from 'common/selectors';
import * as actions from 'features/case/redux/actions';
import history from 'common/history';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '1rem',
  },
}));

const FLOW = {
  signUp: 'signUp',
  signIn: 'signIn',
  otp: 'otp',
  authenticated: 'authenticated',
  confirm: 'confirm',
  addPhone: 'addPhone',
  forgotPassword: 'forgotPassword',
  troubleSigningIn: 'troubleSigningIn',
};

const getFlow = (flow, setFlow, verifyAuth) => {
  switch (flow.step) {
    case FLOW.signUp:
      return (
        <SignUp
          onSignInClick={() => setFlow({ step: FLOW.signIn })}
          onSignUpCompleted={email => setFlow({ step: FLOW.confirm, data: { email } })}
        />
      );
    case FLOW.otp:
      return (
        <Otp
          onSignInCompleted={() => {
            setFlow({});
            verifyAuth();
          }}
          password={flow.data.password}
          session={flow.session}
          onSignInClick={() => setFlow({ step: FLOW.signIn })}
          onMfa={(user, password) => setFlow({ step: FLOW.otp, session: user, data: { password } })}
        />
      );
    case FLOW.authenticated:
      return null;
    case FLOW.confirm:
      return (
        <ConfirmSignUp
          email={flow.data.email}
          resendCode={flow.data.resendCode}
          confirmSignUpCompleted={() => setFlow({ step: FLOW.signIn })}
          onSignInClick={() => setFlow({ step: FLOW.signIn })}
          onResendVerificationEmailClicked={email =>
            setFlow({ step: FLOW.confirm, data: { email } })
          }
        />
      );
    case FLOW.addPhone:
      return (
        <AddPhone
          onSavePhoneCompleted={() => {
            setFlow({});
            verifyAuth();
          }}
          user={flow.session}
        />
      );
    case FLOW.forgotPassword:
      return (
        <ForgotPassword
          email={flow.data.email}
          onForgotPasswordCompleted={() => setFlow({ step: FLOW.signIn })}
          onSignInClick={() => setFlow({ step: FLOW.signIn })}
          onForgotPasswordClicked={email => setFlow({ step: FLOW.forgotPassword, data: { email } })}
        />
      );
    case FLOW.troubleSigningIn:
      return (
        <TroubleSigningIn
          onSignupClick={() => setFlow({ step: FLOW.signUp })}
          onSignInClick={() => setFlow({ step: FLOW.signIn })}
          onResendVerificationEmailClicked={email =>
            setFlow({ step: FLOW.confirm, data: { email } })
          }
          onForgotPasswordClicked={email => setFlow({ step: FLOW.forgotPassword, data: { email } })}
        />
      );
    case FLOW.signIn:
      return (
        <SignIn
          onTroubleSigningIn={() => setFlow({ step: FLOW.troubleSigningIn })}
          onSignupClick={() => setFlow({ step: FLOW.signUp })}
          onMfa={(user, password) => setFlow({ step: FLOW.otp, session: user, data: { password } })}
          onRequirePhoneNumber={user => setFlow({ step: FLOW.addPhone, session: user })}
          onSignInCompleted={() => {
            setFlow({});
            verifyAuth();
          }}
          onConfirmUser={(email, resendCode) =>
            setFlow({ step: FLOW.confirm, data: { email, resendCode } })
          }
        />
      );
    default:
      return null;
  }
};

export default function Cognito({ children, forceLogin, cognitoSettings }) {
  const { loginRequired, showScreen, removeLoginRequired } = useLoginRequired();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [flow, setFlow] = useState({});
  const [isVerifyAuth, setIsVerifyAuth] = useState(false);

  const { userSession, updateUserSession } = useUpdateUserSession();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const queryParams = useSelector(selectQuery);

  const acceptTeamInvite = useCallback(() => {
    if (showScreen === 'signin' || showScreen === 'signup') {
      dispatch(
        actions.acceptTeamInvite({
          emailId: queryParams.email,
          caseId: queryParams.caseId,
          teamId: queryParams.teamId,
          inviteId: queryParams.inviteId,
          countryCode: queryParams.countryCode,
        }),
      )
        .then(res => {
          history.push(history.location.pathname.replace(/\/sign.*/, ``));
        })
        .catch(err => {
          history.push(history.location.pathname.replace(/\/sign.*/, ``));
          console.error(err);
        });
    }
  }, [dispatch, queryParams, showScreen]);

  const verifyAuth = useCallback(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        dispatch(login(JSON.parse(JSON.stringify(user)), showScreen));
        if (!user.attributes.phone_number) setFlow({ step: FLOW.addPhone, session: user });
        else {
          acceptTeamInvite();
          setFlow({});
        }
        removeLoginRequired();
        setIsVerifyAuth(false);
      })
      .catch(err => {
        if (forceLogin || loginRequired) {
          if (showScreen === 'signin') setFlow({ step: FLOW.signIn });
          else if (showScreen === 'signup') setFlow({ step: FLOW.signUp });
          else setFlow({ step: FLOW.signIn });
        }
        Logger.ERROR(err);
        setIsVerifyAuth(false);
      });
  }, [forceLogin, removeLoginRequired, dispatch, loginRequired, acceptTeamInvite, showScreen]);

  useEffect(() => {
    if (!isAuthenticated) return;

    const interval = parseInt(process.env.REACT_APP_COGNITO_REFRESH_TOKEN_CHECK_INTERVAL);
    if (!isNaN(interval)) {
      const intervalId = setInterval(() => {
        const accessToken = userSession.accessToken;
        const refreshToken = new CognitoRefreshToken({
          RefreshToken: userSession.refreshToken.token,
        });

        const currentTimeSeconds = Math.round(+new Date() / 1000);

        if (currentTimeSeconds + interval * 2 > accessToken.payload.exp)
          setTimeout(() => {
            Auth.currentAuthenticatedUser().then(res => {
              res.refreshSession(refreshToken, (err, data) => {
                if (err) {
                  Logger.ERROR(err);
                  if (err.code === 'NotAuthorizedException') dispatch(logout());
                } else {
                  Logger.INFO('Token refreshed ' + data);
                  updateUserSession(data);
                }
              });
            });
          }, Math.floor(Math.random() * interval));
      }, interval * 1000);
      return () => intervalId && clearInterval(intervalId);
    }
  }, [dispatch, isAuthenticated, userSession, updateUserSession]);

  const onMount = () => {
    Auth.configure({
      aws_project_region: 'eu-west-1',
      aws_cognito_region: 'eu-west-1',
      // aws_cognito_identity_pool_id: 'eu-west-1:cd33f2ec-6af5-4d75-8088-3e623f829f28',
      // aws_user_pools_id: 'eu-west-1_FDWlNQuxP',
      // aws_user_pools_web_client_id: '6438d76sjhrua3ltbu9q09m760',
      oauth: {},
      ...(process.env.REACT_APP_COGNITO_MIGRATION_FLOW === 'true' && {
        authenticationFlowType: 'USER_PASSWORD_AUTH',
      }),
      ...cognitoSettings,
    });
    setIsVerifyAuth(true);
    verifyAuth();
  };

  useEffect(onMount, []);

  useEffect(() => {
    loginRequired && !isVerifyAuth && verifyAuth();
  }, [loginRequired, isVerifyAuth, verifyAuth]);

  return (
    <div className="auth-cognito">
      {children}
      {flow.step && (
        <Dialog
          open={flow.step === FLOW.addPhone || (!isAuthenticated && (forceLogin || loginRequired))}
          disableEscapeKeyDown={forceLogin}
          onClose={(e, reason) => {
            if (reason !== 'backdropClick') !forceLogin && removeLoginRequired();
          }}
          classes={{
            paper: classes.paper,
            margin: 0,
          }}
        >
          <IconButton
            style={{ marginLeft: 'auto' }}
            onClick={() => !forceLogin && removeLoginRequired()}
          >
            <CloseIcon />
          </IconButton>
          {getFlow(flow, setFlow, verifyAuth)}
        </Dialog>
      )}
    </div>
  );
}

Cognito.propTypes = {};
Cognito.defaultProps = {};
