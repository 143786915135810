import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import { Controller, useForm } from 'react-hook-form';
import Auth from '@aws-amplify/auth';
import CircularProgress from '@mui/material/CircularProgress';
import { emailValidation } from 'utils/regex';
import Logger from 'utils/logger';
import T from 'i18n';
import { useSelector } from 'react-redux';
import { selectQuery } from 'common/selectors';
import { Trans } from 'react-i18next';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1rem',
  },
  avatar: {
    backgroundColor: '#f50057 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  magicText: {
    display: 'flex',
    padding: '1rem 2rem 1rem 1rem',
    backgroundColor: '#F3F3F3',
    borderRadius: '0.25rem',
  },
  // error: { height: '1rem' },
});

function SignIn({
  onSignupClick,
  onTroubleSigningIn,
  onMfa,
  onRequirePhoneNumber,
  onSignInCompleted,
  onConfirmUser,
}) {
  const classes = useStyles();
  const queryParams = useSelector(selectQuery);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ defaultValues: { email: queryParams.email } });

  const signInPassword = data => {
    const { email, password } = data;

    const resetPasswordLink = T.translate('auth.resetPasswordLink');

    // prettier-ignore
    const invalidCredentialsNoUserMessage = (
      <Trans i18nKey={'auth.invalidCredentialsNoUserMessage'}>
        Your email or password is incorrect. If you forgot your password, please <Link component="button" variant="h6" onClick={onTroubleSigningIn}>
          {{ resetPasswordLink }}
        </Link> now
      </Trans>
    );

    setLoading(true);
    Auth.signIn(email, password)
      .then(user => {
        if (user.challengeName) {
          onMfa(user, password);
        } else {
          if (user.attributes.phone_number) {
            onSignInCompleted();
          } else {
            onRequirePhoneNumber(user);
          }
        }
      })
      .catch(e => {
        Logger.ERROR(e);
        if (e.code === 'UserNotFoundException') {
          // prettier-ignore
          setMessage(
            invalidCredentialsNoUserMessage
          );
        } else if (e.code === 'InvalidParameterException') {
          setMessage(T.translate('auth.otherIssueMessage'));
        } else if (e.code === 'NotAuthorizedException') {
          setMessage(invalidCredentialsNoUserMessage);
        } else if (
          e.code === 'UserLambdaValidationException' &&
          e.name === 'UserLambdaValidationException'
        ) {
          onMfa({});
        } else if (e.code === 'UserNotConfirmedException') {
          onConfirmUser(email, true);
        } else {
          setMessage(T.translate('auth.otherIssueMessage'));
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Avatar className={classes.avatar} sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {T.translate('auth.signIn')}
        </Typography>
        <Typography className={classes.error} color="error" component="h1" variant="h6">
          {message}
        </Typography>
        <form
          onSubmit={handleSubmit(signInPassword)}
          className={classes.form}
          style={{ marginTop: '24px' }}
          noValidate
        >
          <Controller
            name="email"
            rules={{ required: true, pattern: emailValidation }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="email"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={T.translate('auth.email')}
                autoComplete="email"
                autoFocus
                error={errors.email}
                helperText={
                  errors.email &&
                  T.translate(
                    errors.email.type === 'required' ? 'auth.emailRequired' : 'auth.emailInvalid',
                  )
                }
                style={{ minHeight: '6rem', marginTop: 0 }}
                InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                InputProps={{ style: { fontSize: '1.2rem' } }}
              />
            )}
          />

          <Controller
            name="password"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                id="password"
                margin="normal"
                required
                fullWidth
                label={T.translate('auth.password')}
                type="password"
                autoComplete="current-password"
                error={errors.password}
                helperText={errors.password && T.translate('auth.passwordRequired')}
                style={{ minHeight: '6rem', marginTop: 0 }}
                InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                InputProps={{ style: { fontSize: '1.2rem' } }}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3, mb: 2, fontSize: '1rem' }}
          >
            {loading && <CircularProgress size={17} />}
            {!loading && T.translate('auth.signIn')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component="button"
                variant="body2"
                onClick={onTroubleSigningIn}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.forgotPasswordLink')}
              </Link>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={onSignupClick}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.dontHaveAnAccount')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

SignIn.propTypes = {};
SignIn.defaultProps = {};

export default SignIn;
