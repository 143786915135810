import React from 'react';

interface SearchOptionsTooltipProps {
  options: any;
}

export const searchOptionsLabel = (key: string) => {
  const labels: { [key: string]: string } = {
    numMatches: 'Maximum number of matches',
    multiQuery: 'Multi query',
    maxMarginalRelevance: 'Max marginal relevance',
    index: 'Index',
    useDefaultPrompt: 'Use default prompt',
    systemPrompt: 'System prompt',
    sourcePrompt: 'Source prompt',
  };
  return labels[key];
};

export const SearchOptionsTooltip = ({ options }: SearchOptionsTooltipProps) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {Object.entries(options).map(([key, value]: any) => (
        <div key={key} style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ fontWeight: 600, marginRight: '0.5rem' }}>{searchOptionsLabel(key)}: </div>
          <div>{(value || '').toString()}</div>
        </div>
      ))}
    </div>
  );
};
