export const isValidKey = event => {
  const key = window.event ? event.keyCode : event.which;
  const {
    keyCode,
    ctrlKey,
    metaKey,
    shiftKey,
    target: { value },
  } = event;
  if (
    (!value ||
      (value.match(new RegExp('/', 'g')) || []).length === 2 ||
      (value.match(new RegExp(':', 'g')) || []).length === 2 ||
      value[value.length - 1] === ':' ||
      value[value.length - 1] === '/') &&
    (keyCode === 110 || keyCode === 111 || keyCode === 190 || keyCode === 191)
  )
    event.preventDefault();
  else if (
    keyCode === 8 ||
    keyCode === 9 ||
    keyCode === 46 ||
    keyCode === 37 ||
    keyCode === 39 ||
    keyCode === 35 ||
    keyCode === 36 ||
    keyCode === 190 ||
    keyCode === 191 ||
    keyCode === 110 ||
    keyCode === 111 ||
    ((ctrlKey || metaKey) && (keyCode === 65 || keyCode === 67 || keyCode === 86))
  )
    return true;
  else if ((shiftKey || key < 48 || key > 57) && (key < 96 || key > 105)) event.preventDefault();
  else return true;
};
