import api from "common/api";
import { FileMetaData, FolderNode } from "./types";

export const getMimeType = (extension: string): string => {
  const mimeTypes: {
    [key: string]: string;
  } = {
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    txt: 'text/plain',
    doc: 'application/msword',
    dot: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    xls: 'application/vnd.ms-excel',
    xla: 'application/vnd.ms-excel',
    xlt: 'application/vnd.ms-excel',
    ppt: 'application/vnd.ms-powerpoint',
    ppa: 'application/vnd.ms-powerpoint',
    pot: 'application/vnd.ms-powerpoint',
    pps: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    tiff: 'image/tiff',
  };

  return mimeTypes[extension.toLowerCase()] || 'application/octet-stream'; // default from mimetypes.cs
};

export const getFileExtension = (mimeType: string): string => {
  const mimeExtensions: { [key: string]: string } = {
    'application/pdf': 'pdf',
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'text/plain': 'txt',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'image/tiff': 'tiff',
  };

  return mimeExtensions[mimeType] || '';
};

export const buildFolderTree = (files: FileMetaData[]) => {
  const root: FolderNode = { name: 'root', path: '', files: [], subfolders: {} };
  const folderPathsSet = new Set<string>();

  files.forEach(file => {
    const pathParts = file.folderPath ? file.folderPath.split('/') : [];
    let currentNode = root;
    let currentPath = '';

    pathParts.forEach(part => {
      currentPath = currentPath ? `${currentPath}/${part}` : part;
      folderPathsSet.add(currentPath);

      if (!currentNode.subfolders[part]) {
        currentNode.subfolders[part] = {
          name: part,
          path: currentPath,
          files: [],
          subfolders: {},
        };
      }
      currentNode = currentNode.subfolders[part];
    });

    currentNode.files.push(file);
  });

  folderPathsSet.forEach(folderPath => {
    const pathParts = folderPath.split('/');
    let currentNode = root;
    let currentPath = '';

    pathParts.forEach(part => {
      currentPath = currentPath ? `${currentPath}/${part}` : part;

      if (!currentNode.subfolders[part]) {
        currentNode.subfolders[part] = {
          name: part,
          path: currentPath,
          files: [],
          subfolders: {},
        };
      }
      currentNode = currentNode.subfolders[part];
    });
  });

  return root;
};

  export const flattenFolderTree = (root: FolderNode) => {
    const result: { node: FolderNode; depth: number }[] = [];
    const stack: { node: FolderNode; depth: number }[] = [{ node: root, depth: 0 }];

    while (stack.length > 0) {
      const { node, depth } = stack.pop()!;
      result.push({ node, depth });

      const subfolderNames = Object.keys(node.subfolders)
        .sort()
        .reverse();
      subfolderNames.forEach(subfolderName => {
        stack.push({ node: node.subfolders[subfolderName], depth: depth + 1 });
      });
    }

    return result;
  };

export const findNodeByPath = (node: FolderNode, path: string): FolderNode | null => {
  if (node.path === path) {
    return node;
  }
  for (const subfolder of Object.values(node.subfolders)) {
    const result = findNodeByPath(subfolder, path);
    if (result) {
      return result;
    }
  }
  return null;
};

export const getAllSubfolderPaths = (node: FolderNode): string[] => {
  let paths: string[] = [];
  Object.values(node.subfolders).forEach(subfolder => {
    paths.push(subfolder.path);
    paths = paths.concat(getAllSubfolderPaths(subfolder));
  });
  return paths;
};

 export const areAllFilesUploaded = (node: FolderNode): boolean => {
    const allFilesInCurrentFolderUploaded = node.files.every(
      file => file.status === 'uploaded' || file.status === 'cancelled',
    );

    const allSubfoldersUploaded = Object.values(node.subfolders).every(subfolder =>
      areAllFilesUploaded(subfolder),
    );

    return allFilesInCurrentFolderUploaded && allSubfoldersUploaded;
  };

  export const hasUnsupportedFiles = (node: FolderNode): boolean => {
  const hasUnsupportedInCurrentFolder = node.files.some(
    file => file.status === 'unsupported'
  );

  const hasUnsupportedInSubfolders = Object.values(node.subfolders).some(subfolder =>
    hasUnsupportedFiles(subfolder)
  );

  return hasUnsupportedInCurrentFolder || hasUnsupportedInSubfolders;
};

export const getExportLink = async (caseId: string, currentBatchId: number | null) => {
    try {
      const response: any = await api.get(`/cases/${caseId}/batch/${currentBatchId}?export=csv`);
      const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }));
      const timeStamp = `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getMonth() +
        1}--${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Batch-upload-${timeStamp}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  export const notificationsExport = async (exportLink: string) => {
    try {
      const response: any = await api.get(exportLink);
      const url = window.URL.createObjectURL(new Blob([response], { type: 'text/csv' }));
      const timeStamp = `${new Date().getFullYear()}-${new Date().getDate()}-${new Date().getMonth() +
        1}--${new Date().getHours()}-${new Date().getMinutes()}-${new Date().getSeconds()}`;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Batch-upload-${timeStamp}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

export const hasProtectedFiles = (node: FolderNode): boolean => {
  const hasInvalidFilesInCurrentFolder = node.files.some(
    file => file.status === 'protected'
  );

  const hasInvalidFilesInSubfolders = Object.values(node.subfolders).some(subfolder =>
    hasProtectedFiles(subfolder)
  );

  return hasInvalidFilesInCurrentFolder || hasInvalidFilesInSubfolders;
};

export const isPDFEncrypted = (file: File): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    if (file.type !== 'application/pdf') {
      resolve(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target?.result as ArrayBuffer;
      const uint8Array = new Uint8Array(data);
      const text = new TextDecoder('utf-8').decode(uint8Array);
      if (text.includes('/Encrypt')) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    reader.onerror = () => {
      resolve(false);
    };
    reader.readAsArrayBuffer(file.slice(0, 1024)); // Read first 1KB
  });
};