import React, { Component } from 'react';
import classnames from 'classnames';

export default class Spinner extends Component {
  static propTypes = {};

  render() {
    const { message, className, ...rest } = this.props;

    return (
      <div {...rest} className={classnames(className, 'common-spinner')}>
        <div className="sk-fading-circle">
          <div className="sk-circle1 sk-circle" />
          <div className="sk-circle2 sk-circle" />
          <div className="sk-circle3 sk-circle" />
          <div className="sk-circle4 sk-circle" />
          <div className="sk-circle5 sk-circle" />
          <div className="sk-circle6 sk-circle" />
          <div className="sk-circle7 sk-circle" />
          <div className="sk-circle8 sk-circle" />
          <div className="sk-circle9 sk-circle" />
          <div className="sk-circle10 sk-circle" />
          <div className="sk-circle11 sk-circle" />
          <div className="sk-circle12 sk-circle" />
        </div>
        {message && <span>{message}</span>}
      </div>
    );
  }
}
