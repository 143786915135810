import React from 'react';
import { useSelector } from 'react-redux';
import { selectParams } from 'common/selectors';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import AiToolsResponse from './AiToolsResponse';
import ViewerWrapper from './ViewerWrapper';

const AiToolsResponseWrapper = () => {
  const { file } = useSelector(selectParams);
  return (
    <Splitter
      direction={SplitDirection.Horizontal}
      initialSizes={file ? [40, 60] : [100, 0]}
      minWidths={file ? [250, 400] : [650, 0]}
    >
      <AiToolsResponse />
      {file && <ViewerWrapper fileId={file} fileType={'pdf'} />}
    </Splitter>
  );
};

export default AiToolsResponseWrapper;
