import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as actions from '../redux/actions';
import T from 'i18n';
import CustomViewerButton from 'features/common/CustomViewerButton';
import CustomViewerMultiActionButton from 'features/common/CustomViewerMultiActionButton';
import Tooltip from 'features/common/SimpleTooltip';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import {
  selectPermissions,
  selectCurrentFileMetData,
  selectSessionStarted,
  selectQuery,
  selectTheatreData,
  selectUserId,
} from 'common/selectors';
import { makeCancelable } from 'utils/promises';
import history from 'common/history';
import qs from 'qs';
import { playerActions } from './enums';
import InfoText from '../InfoText';
import { TooltipWrapper } from '../TooltipWrapper';

class VideoViewerToolbox extends Component {
  static propTypes = {
    mode: PropTypes.string,
    fileId: PropTypes.object,
  };

  state = {
    present: this.props.present || null,
    presentMode: this.props.present || null,
  };

  sendMessage = message => {
    if (!this.state.present) return;
    const fullMessage = {
      target: 'present',
      room: this.state.present,
      message,
    };
    this.props.actions.sendMessage(fullMessage);
  };

  getFullMessage = (action = playerActions.PAUSE, time = 0) => {
    return {
      fileId: this.props.fileId,
      fileType: 'mp4',
      videoAction: action,
      videoTime: time,
    };
  };

  sendFullMessage = (action = playerActions.PAUSE, time = 0) => {
    this.sendMessage(this.getFullMessage(action, time));
  };

  present = (mode, forcePresent) => {
    const containsFolderId = Object.keys(this.props.queryParam).includes('folderId');
    const folderIdParamStr = `folderId=${this.props.queryParam.folderId}`;
    if (
      this.state.present &&
      (!forcePresent || (this.state.present === 'public' && this.props.currentFileMetaData.private))
    ) {
      this.leaveGroupSafe = makeCancelable(
        new Promise(r => this.props.actions.leaveAGroup('present').then(() => r())),
      );
      this.leaveGroupSafe.promise.then(() => {
        history.push(
          history.location.pathname.replace(/\?present.*/, ``) +
            `${containsFolderId ? `?${folderIdParamStr}` : ''}`,
        );
        this.setState({ present: null, presentMode: null });
      });
    } else {
      this.startPresentingSafe = makeCancelable(
        new Promise(r =>
          this.props.actions
            .startPresenting({ hearingRoomMode: mode, message: this.getFullMessage() })
            .then(() => r()),
        ),
      );
      this.startPresentingSafe.promise.then(() => {
        this.setState({ present: mode, presentMode: mode });
        history.push(
          history.location.pathname.replace(/\?present.*/, ``) +
            `?${containsFolderId ? `${folderIdParamStr}&` : ''}present=${mode}`,
        );
        this.props.startPlayerPresenting();
      });
    }
  };

  componentDidMount() {
    const { isTheatreMode } = this.props;
    if (this.state.present) {
      this.present(this.state.present, true);
    }
    if (isTheatreMode) {
      this.props.updatePlayerState({ videoAction: playerActions.PAUSE, videoTime: 0 });
    }
  }

  componentDidUpdate(prevProps) {
    const { isTheatreMode } = this.props;
    if (
      isTheatreMode &&
      (prevProps.theatreVideoAction !== this.props.theatreVideoAction ||
        prevProps.theatreVideoTime !== this.props.theatreVideoTime)
    ) {
      this.props.playerRef.current &&
        this.props.playerRef.current.seekTo(this.props.theatreVideoTime);

      this.props.updatePlayerState({
        videoAction: this.props.theatreVideoAction,
      });
    } else {
      if (
        this.state.present &&
        (prevProps.fileId !== this.props.fileId ||
          (prevProps.playerAction !== this.props.playerAction &&
            this.props.playerAction !== playerActions.SHOWCONTROLS &&
            this.props.playerAction !== playerActions.HIDECONTROLS))
      ) {
        const playedSeconds = Math.round(
          (this.props.playerRef.current && this.props.playerRef.current.getCurrentTime()) || 0,
        );

        this.sendFullMessage(this.props.playerAction, playedSeconds);
      }
    }
    if (prevProps.presenterConnectionId !== this.props.presenterConnectionId) {
      const shouldIBePresenting = this.props.presenterConnectionId === this.props.connectionId;

      if (!shouldIBePresenting && this.state.present) this.present();
    }
    if (
      this.state.present === 'public' &&
      prevProps.hearingRoomStarted !== this.props.hearingRoomStarted &&
      !this.props.hearingRoomStarted
    )
      this.present();
  }

  componentWillUnmount() {
    const newUrlQuery = qs.parse(window.location.search && window.location.search.substr(1)) || {};
    if (this.state.present && !newUrlQuery.present) this.props.actions.leaveAGroup('present');
    this.startPresentingSafe && this.startPresentingSafe.cancel();
    this.leaveGroupSafe && this.leaveGroupSafe.cancel();
  }

  render() {
    return (
      <div className="viewing-custom-viewer-toolbox">
        <div className="document-options top-right">
          {this.props.isTheatreMode ? (
            <InfoText
              pauseTheatreMode={this.props.pauseTheatreMode}
              presenter={this.props.presenter}
              fileId={this.props.fileId}
            />
          ) : !!this.state.presentMode ? (
            <TooltipWrapper presentMode={this.state.presentMode}>
              <CustomViewerButton
                icon={faChromecast}
                icon2={this.state.presentMode === 'private' && faLock}
                className={classnames({ present: !!this.state.presentMode })}
                onClick={() => {
                  this.present();
                }}
              />
            </TooltipWrapper>
          ) : (
            (this.props.permissions.hearingRoom.canPresent('private') ||
              this.props.permissions.hearingRoom.canPresent('public')) && (
              <CustomViewerMultiActionButton direction="down">
                <CustomViewerButton icon={faChromecast} onClick={() => {}} />
                <Tooltip title={T.translate('case.presentToPrivateRoomTooltip')}>
                  <CustomViewerButton
                    icon={faChromecast}
                    icon2={faLock}
                    onClick={() => {
                      this.present('private');
                    }}
                    disabled={!this.props.permissions.hearingRoom.canPresent('private')}
                  />
                </Tooltip>
                <Tooltip title={T.translate('case.presentToPublicRoomTooltip')}>
                  <CustomViewerButton
                    icon={faChromecast}
                    onClick={() => this.present('public')}
                    disabled={!this.props.permissions.hearingRoom.canPresent('public')}
                  />
                </Tooltip>
              </CustomViewerMultiActionButton>
            )
          )}
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state, props) {
  const {
    auth: { hubConnectionId },
    matcher: {
      query: { present: mode },
    },
  } = state;
  const userId = selectUserId(state);
  const permissions = selectPermissions(state);
  const currentFileMetaData = selectCurrentFileMetData(state);
  const hearingRoomStarted = selectSessionStarted(state);
  const queryParam = selectQuery(state);
  const theatreData = selectTheatreData(state, mode);
  const { connectionId } =
    (props.file &&
      (theatreData.messages || []).find(
        message => message.fileId === props.file && userId === message.presenterId,
      )) ||
    {};
  const { videoAction: theatreVideoAction, videoTime: theatreVideoTime = 0 } =
    theatreData &&
    ((theatreData.messages || [{}])[0] || { videoAction: playerActions.PAUSE, videoTime: 0 });

  return {
    connectionId: hubConnectionId,
    permissions,
    presenterConnectionId: connectionId,
    currentFileMetaData,
    hearingRoomStarted,
    queryParam,
    theatreVideoAction,
    theatreVideoTime,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoViewerToolbox);
