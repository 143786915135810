import { put, takeEvery, select, call, all, take } from 'redux-saga/effects';
import hash from 'object-hash';
import {
  CASE_UPLOAD_FILES_BEGIN,
  CASE_UPLOAD_FILES_SUCCESS,
  CASE_UPLOAD_FILES_FAILURE,
  CASE_UPLOAD_FILES_PROGRESS,
  CASE_UPLOAD_FILES_DISMISS_FEEDBACK,
  CASE_UPLOAD_FILES_REJECT,
  CASE_UPLOAD_FILE_TOO_LARGE,
  CASE_UPLOAD_FILES_DISMISS_TOO_LARGE_FEEDBACK,
  CASE_ASSIGN_DOCUMENTS_SUCCESS,
  CASE_UPLOAD_FILES_CLOSE_PROGRESS,
  CASE_FETCH_FOLDER_BEGIN,
  CASE_UPLOAD_DUPLICATE_FILE,
  CASE_FETCH_DOCUMENTS_BEGIN,
} from './constants';
import api from 'common/api';
import {
  withCurrentCaseId,
  selectUploadFiles,
  selectCurrentFolder,
} from '../../../common/selectors';
import { replaceItemImmutable, addItemImmutable } from 'utils/arrays';

export function uploadFiles(files, folderId, uploadInnerFile = false) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_UPLOAD_FILES_BEGIN,
    payload: { files, folderId, uploadInnerFile },
  };
}

export function dismissUploadFilesFeedback() {
  return {
    type: CASE_UPLOAD_FILES_DISMISS_FEEDBACK,
  };
}

export function closeUploadFilesProgress() {
  return {
    type: CASE_UPLOAD_FILES_CLOSE_PROGRESS,
  };
}

const getFileProps = file => ({
  path: file.path,
  name: file.name,
  lastModified: file.lastModified,
  size: file.size,
  type: file.type,
});

export const prepareFile = file => {
  const meta = new Blob([JSON.stringify(getFileProps(file))], {
    type: 'application/json',
  });

  const formData = new FormData();
  formData.append('', file);
  formData.append('', meta);

  return formData;
};

// worker Saga: will be fired on CASE_UPLOAD_FILE_BEGIN actions
function* uploadFile({ payload: { file, folderId, uploadInnerFile, fetchFolder, caseId } }) {
  const fileProps = getFileProps(file);
  const filePropsWithHash = { hash: hash(fileProps), ...fileProps };
  const files = yield select(selectUploadFiles);
  const index = files.findIndex(x => x.hash === filePropsWithHash.hash);

  if (index > -1) {
    yield put({
      type: CASE_UPLOAD_FILES_REJECT,
    });
    return;
  }

  const fileToSend = prepareFile(file);
  const channel = yield call(
    api.createUploadFileChannel,
    'POST',
    folderId
      ? `/cases/${caseId}/folders/${folderId}/files${uploadInnerFile ? '?ring=inner' : ''}`
      : `/cases/${caseId}/files${uploadInnerFile ? '?ring=inner' : ''}`,
    fileToSend,
  );

  while (true) {
    const { progress = 0, error, success, response: document } = yield take(channel);

    if (error) {
      if (error.status === 409 && folderId) {
        yield put({
          type: CASE_UPLOAD_DUPLICATE_FILE,
          data: { file: { error, ...filePropsWithHash, progress: '100.00' }, error },
        });
      } else
        yield put({
          type: CASE_UPLOAD_FILES_FAILURE,
          data: { file: { error, ...filePropsWithHash, progress: '100.00' }, error },
        });

      // if it's a duplicate still assign
      // if (error.status === 409 && folderId)
      //   yield put(assignDocuments([error.body.file.id], folderId));
      //commented as BE is returning error msg not file
      if (fetchFolder && folderId) {
        yield put({ type: CASE_FETCH_FOLDER_BEGIN, payload: { folderId, noPromise: true } });
        yield put({ type: CASE_FETCH_DOCUMENTS_BEGIN });
      }
      return;
    }

    if (success) {
      const currentFolder = yield select(selectCurrentFolder);
      const shouldAssign =
        (folderId && folderId === currentFolder.id) || Object.keys(currentFolder).length === 0;

      yield put({
        type: CASE_ASSIGN_DOCUMENTS_SUCCESS,
        data: {
          document,
          shouldAssign,
          zeroBasedIndex: currentFolder && currentFolder.zeroBasedIndex,
        },
      });

      yield put({
        type: CASE_UPLOAD_FILES_SUCCESS,
        data: { file: { ...filePropsWithHash, ...document, progress: '100.00' } },
      });
      if (fetchFolder && folderId) {
        yield put({ type: CASE_FETCH_FOLDER_BEGIN, payload: { folderId, noPromise: true } });
        yield put({ type: CASE_FETCH_DOCUMENTS_BEGIN });
      }
      return;
    }

    yield put({
      type: CASE_UPLOAD_FILES_PROGRESS,
      data: { file: { progress, ...filePropsWithHash } },
    });
  }
}

// worker Saga: will be fired on CASE_UPLOAD_FILE_BEGIN actions
export function* doUploadFiles({ payload: { files, folderId, uploadInnerFile } }) {
  const largeFiles = files.filter(file => file.size / 1024 / 1024 >= 500);
  const totalFilesCount = largeFiles.length > 0 ? files.length - largeFiles.length : files.length;
  if (largeFiles.length > 0) {
    yield put({ type: CASE_UPLOAD_FILES_DISMISS_TOO_LARGE_FEEDBACK, data: largeFiles });
    yield put({
      type: CASE_UPLOAD_FILE_TOO_LARGE,
      data: largeFiles,
      feedback: {
        message: 'feedback.uploadFileTooLarge',
        params: {
          fileName: [...largeFiles.map(file => ' ' + file.name)],
        },
        error: { hideError: true, status: 413, statusText: 'File Size too large' },
      },
    });
  }

  yield all(
    files.map(
      (file, idx) =>
        file.size / 1024 / 1024 < 500 &&
        call(withCurrentCaseId(uploadFile), {
          payload: {
            file,
            folderId,
            uploadInnerFile,
            fetchFolder: totalFilesCount - 1 === idx ? true : false,
          },
        }),
    ),
  );
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUploadFiles() {
  yield takeEvery(CASE_UPLOAD_FILES_BEGIN, doUploadFiles);
}

// Redux reducer
export function reducer(state, action) {
  const clearFilesAndFeedbacks = (shouldClear = true) =>
    shouldClear && {
      uploadFilesPending: 0,
      uploadFiles: [],
      uploadFilesFeedback: null,
    };

  switch (action.type) {
    case CASE_UPLOAD_FILES_BEGIN:
      return {
        ...state,
        uploadFilesPending: state.uploadFilesPending + action.payload.files.length,
        uploadFilesProgressClosed: false,
      };

    case CASE_UPLOAD_FILES_SUCCESS:
      const indexS = state.uploadFiles.findIndex(x => x.hash === action.data.file.hash);
      return {
        ...state,
        uploadFilesPending: state.uploadFilesPending - 1,
        uploadFiles:
          indexS < 0
            ? addItemImmutable(state.uploadFiles, action.data.file)
            : replaceItemImmutable(state.uploadFiles, action.data.file, indexS),
        ...clearFilesAndFeedbacks(
          state.uploadFilesProgressClosed && state.uploadFilesPending - 1 === 0,
        ),
      };

    case CASE_UPLOAD_FILES_PROGRESS:
      const indexP = state.uploadFiles.findIndex(x => x.hash === action.data.file.hash);
      return {
        ...state,
        uploadFiles:
          indexP < 0
            ? addItemImmutable(state.uploadFiles, action.data.file)
            : replaceItemImmutable(state.uploadFiles, action.data.file, indexP),
      };

    case CASE_UPLOAD_FILES_FAILURE:
      const indexF = state.uploadFiles.findIndex(x => x.hash === action.data.file.hash);
      return {
        ...state,
        uploadFilesPending: state.uploadFilesPending - 1,
        uploadFilesFeedback: {
          message: 'feedback.uploadFilesFailure',
          error: typeof action.data.error.body === 'string' && action.data.error,
          warning: true,
        },
        uploadFiles:
          indexF < 0
            ? addItemImmutable(state.uploadFiles, action.data.file)
            : replaceItemImmutable(state.uploadFiles, action.data.file, indexF),
        ...clearFilesAndFeedbacks(
          state.uploadFilesProgressClosed && state.uploadFilesPending - 1 === 0,
        ),
      };

    case CASE_UPLOAD_DUPLICATE_FILE:
      const indexD = state.uploadFiles.findIndex(x => x.hash === action.data.file.hash);
      return {
        ...state,
        uploadFilesPending: state.uploadFilesPending - 1,
        uploadFilesFeedback: null,
        uploadFiles:
          indexD < 0
            ? addItemImmutable(state.uploadFiles, action.data.file)
            : replaceItemImmutable(state.uploadFiles, action.data.file, indexD),
        ...clearFilesAndFeedbacks(
          state.uploadFilesProgressClosed && state.uploadFilesPending - 1 === 0,
        ),
      };

    case CASE_UPLOAD_FILES_DISMISS_FEEDBACK:
      return {
        ...state,
        uploadFilesPending: 0,
        uploadFiles: [],
        uploadFilesFeedback: null,
        uploadFilesTooLargeFeedback: null,
      };

    case CASE_UPLOAD_FILES_CLOSE_PROGRESS:
      return {
        ...state,
        uploadFilesProgressClosed: true,
        ...clearFilesAndFeedbacks(state.uploadFilesPending === 0),
      };

    case CASE_UPLOAD_FILES_REJECT:
      return {
        ...state,
        uploadFilesPending: state.uploadFilesPending - 1,
        ...clearFilesAndFeedbacks(
          state.uploadFilesProgressClosed && state.uploadFilesPending - 1 === 0,
        ),
      };

    case CASE_UPLOAD_FILE_TOO_LARGE:
      return {
        ...state,
        uploadFilesTooLargeFeedback: action.feedback,
      };

    case CASE_UPLOAD_FILES_DISMISS_TOO_LARGE_FEEDBACK:
      return {
        ...state,
        uploadFilesPending: state.uploadFilesPending - action.data.length,
        uploadFilesTooLargeFeedback: null,
        ...clearFilesAndFeedbacks(
          state.uploadFilesProgressClosed && state.uploadFilesPending - 1 === 0,
        ),
      };

    default:
      return state;
  }
}
