import { call, put, takeLatest } from 'redux-saga/effects';
import {
  UPDATE_USER_BEHAVIOUR_BEGIN,
  UPDATE_USER_BEHAVIOUR_SUCCESS,
  UPDATE_USER_BEHAVIOUR_FAILURE,
  UPDATE_USER_BEHAVIOUR_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';

export function updateUserBehaviour(userBehaviour) {
  return {
    type: UPDATE_USER_BEHAVIOUR_BEGIN,
    payload: { userBehaviour },
  };
}

export function dismissUpdateUserBehaviourFeedback() {
  return {
    type: UPDATE_USER_BEHAVIOUR_DISMISS_FEEDBACK,
  };
}

export function* doUpdateUserBehaviour(action) {
  const {
    payload: { userBehaviour },
  } = action;

  const res = yield call(api.post, `/user-behaviour`, userBehaviour);

  if (res && res.error) {
    yield put({
      type: UPDATE_USER_BEHAVIOUR_FAILURE,
      feedback: { message: 'feedback.updateUserBehaviour', error: res.error, retryAction: action },
    });
  }
  yield put({
    type: UPDATE_USER_BEHAVIOUR_SUCCESS,
    data: { userBehaviour: res },
  });
}

export function* watchUpdateUserBehaviour() {
  yield takeLatest(UPDATE_USER_BEHAVIOUR_BEGIN, doUpdateUserBehaviour);
}

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_USER_BEHAVIOUR_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        updateUserBehaviourPending: true,
        updateUserBehaviourFeedback: null,
      };

    case UPDATE_USER_BEHAVIOUR_SUCCESS:
      // The request is success
      return {
        ...state,
        updateUserBehaviourPending: false,
        updateUserBehaviourFeedback: action.feedback,
        userBehaviour: action.data,
      };

    case UPDATE_USER_BEHAVIOUR_FAILURE:
      // The request is failed
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        updateUserBehaviourPending: false,
        updateUserBehaviourFeedback: action.feedback,
        ...(concurencyError && {
          userBehaviour: action.feedback.error.body,
        }),
      };

    case UPDATE_USER_BEHAVIOUR_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateUserBehaviourFeedback: null,
      };

    default:
      return state;
  }
}
