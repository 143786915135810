import React from 'react';
import {
  Autocomplete,
  Chip,
  LinearProgress,
  ListSubheader,
  Popper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Unstable_Grid2';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { makeStyles, styled } from '@mui/styles';
import { CustomScrollbars } from '../Table/CustomScrollbarsVirtualList';
import T from 'i18n';
import PerfectScrollbar from 'react-perfect-scrollbar';

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];

  const inlineStyle = {
    ...style,
    width: 'max-content',
    lineHeight: style.height + 'px',
    top: style.top as number,
    fontWeight: 'lighter',
    zIndex: 0,
  };

  if (dataSet[3].hasOwnProperty('group') && dataSet[3].group) {
    return (
      <ListSubheader
        key={dataSet.key}
        component="div"
        style={{
          ...inlineStyle,
          marginLeft: `${dataSet[3].level || 0}rem`,
          fontSize: '1.1rem',
          fontWeight: 'bold',
        }}
        onClick={() => {
          const value = dataSet[4] || [];

          const onChange = dataSet[5];
          const setFilters = dataSet[6];
          const valKey = dataSet[7];
          const filterCategory = dataSet[8];
          const filterKey = dataSet[9];

          const newValue = [
            ...(valKey ? value.map((val: any) => val[valKey]) : value),
            ...(dataSet[3].items || []),
          ];
          onChange && onChange(newValue);
          setFilters &&
            setFilters({
              ...filterCategory,
              [filterKey]: newValue,
            });
        }}
      >
        {dataSet[1]}
      </ListSubheader>
    );
  }

  return (
    <Typography
      key={index}
      component="li"
      {...dataSet[0]}
      noWrap
      style={{ ...inlineStyle, marginLeft: `${dataSet[3].level || 0}rem` }}
    >
      {dataSet[1]}
    </Typography>
  );
}

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  return (
    <div ref={ref} {...props} {...outerProps}>
      <CustomScrollbars
        {...(props as any)}
        style={{ height: 'calc(100% - 1px)' }}
        forwardedRef={ref}
      />
    </div>
  );
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];

    (children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
      noSsr: true,
    });
    const itemCount = itemData.length;
    const itemSize = smUp ? 30 : 42;

    const getChildSize = (child: React.ReactElement) => {
      if (child.hasOwnProperty('group')) {
        return 42;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * 8}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={index => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  noPadding: {
    padding: 0,
  },
  inline: {
    '& .MuiInputBase-root': {
      flexWrap: 'nowrap',
    },
    '& .MuiButtonBase-root': {
      height: '1.6rem',
    },
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: '0 9px',
    },
    '& .Mui-readOnly': {
      backgroundColor: '#f5f5f5',
      cursor: 'not-allowed',
    },
  },
}));

export default React.forwardRef(
  (
    {
      idx,
      originalArray,
      filterName,
      filterKey,
      valKey,
      labelKey,
      setFilters,
      filterCategory,
      loading,
      onChange,
      value,
      inline,
      placeholder,
    }: {
      idx?: number;
      originalArray: any;
      filterName?: string;
      filterKey: string;
      valKey?: string;
      labelKey?: string;
      filterCategory?: any;
      setFilters?: (newFilterCategory: any) => void;
      loading?: boolean | null;
      onChange?: any;
      value?: any;
      inline?: boolean;
      placeholder?: string;
    },
    ref: any,
  ) => {
    const [inputValue, setInputValue] = React.useState('');
    const classes = useStyles();

    const actualValue = value
      ? originalArray.filter((val: any) => value.includes(valKey ? val[valKey] : val))
      : valKey
      ? (originalArray || []).filter(
          (val: any) =>
            filterCategory &&
            filterCategory[filterKey] &&
            filterCategory[filterKey].includes(val[valKey]),
        )
      : (filterCategory && filterCategory[filterKey]) || [];

    return (
      <React.Fragment key={idx}>
        <Grid xs={3}>
          <Typography
            style={{
              marginLeft: 0,
              alignSelf: 'center',
              marginTop: '0.5rem',
              fontWeight: 'bold',
            }}
          >
            {T.translate(filterName || filterKey)}
          </Typography>
        </Grid>
        <Grid xs={9}>
          {loading ? (
            <LinearProgress
              sx={{
                marginTop: '1rem',
              }}
            />
          ) : (
            <Autocomplete
              multiple
              inputValue={inputValue}
              onClose={() => {
                setInputValue('');
              }}
              onInputChange={(event, value, reason) => {
                if (reason !== 'reset') {
                  setInputValue(value);
                }
              }}
              placeholder={placeholder}
              size="small"
              options={originalArray || []}
              getOptionLabel={(option: any) => (labelKey ? option[labelKey] : option)}
              disableCloseOnSelect
              renderInput={params => (
                <TextField {...params} placeholder={placeholder} variant="standard" />
              )}
              value={actualValue}
              onChange={(e, value: any) => {
                onChange && onChange(valKey ? value.map((val: any) => val[valKey]) : value);
                setFilters &&
                  setFilters({
                    ...filterCategory,
                    [filterKey]: valKey ? value.map((val: any) => val[valKey]) : value,
                  });
              }}
              ListboxComponent={ListboxComponent}
              disableListWrap
              PopperComponent={StyledPopper}
              renderOption={(props, option: any, state) => {
                return [
                  props,
                  labelKey ? option[labelKey] : option,
                  state.index,
                  option,
                  actualValue,
                  onChange,
                  setFilters,
                  valKey,
                  filterCategory,
                  filterKey,
                ] as React.ReactNode;
              }}
              renderGroup={params => params as any}
              classes={{ root: inline ? classes.inline : '' }}
              {...(inline && {
                renderTags: (values, getTagProps, owner) => (
                  <PerfectScrollbar
                    options={{ suppressScrollY: true }}
                    style={{
                      display: 'flex',
                      height: '1.7rem',
                      maxWidth: 'calc(100% - 2rem)',
                      margin: '0.25rem',
                      marginRight: '1rem',
                    }}
                  >
                    {values &&
                      values.map((value, index) => (
                        <Chip
                          label={owner.getOptionLabel(value)}
                          size={owner.size}
                          {...getTagProps({ index })}
                          {...owner.ChipProps}
                        />
                      ))}
                  </PerfectScrollbar>
                ),
              })}
            />
          )}
        </Grid>
      </React.Fragment>
    );
  },
);
