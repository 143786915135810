import React from 'react';
import {
  TrialbooksPage,
  BundlesPage,
  TeamBundlesPage,
  PrivateBundlesPage,
  CourtBundlesPage,
  QuickLinksPage,
  AllDocuments,
  PresentModePage,
  SearchPage,
  DocFinder,
  AllAnnotationsPage,
} from '.';
import PageRefsPage from './PageRefsPage';
import AiPage from './AiPage';
import GroupBundlesPage from './GroupBundlesPage';

interface TablePortionProps {
  isBundle: boolean;
  isTeamBundle: boolean;
  isPrivateBundle: boolean;
  isTrialbook: boolean;
  isCourtBundle: boolean;
  isGroupBundle: boolean;
  isQuickLink: boolean;
  isPresentMode: boolean;
  isPresentModeWithBackEnd: boolean;
  isAiTools: boolean;
  isSearchMode: boolean;
  isDocFinder: boolean;
  isPageRefs: boolean;
  isAllAnnotations: boolean;
  quickLink: string;
}

export default ({
  isBundle,
  isTeamBundle,
  isPrivateBundle,
  isTrialbook,
  isCourtBundle,
  isGroupBundle,
  isQuickLink,
  isPresentMode,
  isPresentModeWithBackEnd,
  isAiTools,
  isSearchMode,
  isDocFinder,
  isAllAnnotations,
  isPageRefs,
  quickLink,
}: TablePortionProps) => {
  return (
    <>
      {isBundle && <BundlesPage key="bundles-page" />}
      {isTeamBundle && <TeamBundlesPage key="teamBundles-page" />}
      {isPrivateBundle && <PrivateBundlesPage key="privateBundles-Page" />}
      {isTrialbook && <TrialbooksPage key="trialbooks-page" />}
      {isCourtBundle && <CourtBundlesPage key="courtbundles-page" />}
      {isGroupBundle && <GroupBundlesPage key="groupBundles-page" />}
      {isAllAnnotations && <AllAnnotationsPage key="all-annotations-page" />}
      {isQuickLink && <QuickLinksPage key="quickLinks-page" quickLink={quickLink} />}
      {isPresentMode && <PresentModePage />}
      {isAiTools && <AiPage />}
      {isSearchMode && <SearchPage />}
      {(isDocFinder || isPresentModeWithBackEnd) && (
        <DocFinder isPresentModeWithBackEnd={isPresentModeWithBackEnd} />
      )}
      {isPageRefs && <PageRefsPage />}
      {!(
        isCourtBundle ||
        isGroupBundle ||
        isTrialbook ||
        isBundle ||
        isTeamBundle ||
        isPrivateBundle ||
        isQuickLink ||
        isPresentMode ||
        isAiTools ||
        isSearchMode ||
        isDocFinder ||
        isPresentModeWithBackEnd ||
        isPageRefs ||
        isAllAnnotations
      ) && <AllDocuments key="alldocuments-page" />}
    </>
  );
};
