import React from 'react';
import { Chip } from '@mui/material';

export default ({ idx, label, deleteHandler, clickHandler }: any) => {
  return (
    <Chip
      key={idx}
      label={label}
      size="small"
      sx={{
        fontSize: '1rem',
        mr: '0.5rem',
        cursor: clickHandler ? 'pointer' : 'initial !important',
      }}
      onDelete={() => deleteHandler && deleteHandler()}
      onClick={(e: any) => clickHandler && clickHandler(e)}
    />
  );
};
