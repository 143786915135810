import React, { useState, forwardRef } from 'react';
import ReactSelect, { components } from 'react-select';
import classnames from 'classnames';
// import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const ClearIndicator = props => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <FontAwesomeIcon icon={faTimes} alt={faTimes.iconName} />
    </div>
  );
};

const renderNestedOption = (props, label, nestedOptions, labelKey, valueKey, index) => {
  const { getStyles, innerProps, selectOption } = props;

  return (
    <div key={`${label}-${index}`}>
      <div style={{ ...getStyles('groupHeading', props), paddingLeft: index * 12 }}>{label}</div>
      {nestedOptions.map(nestedOption => {
        if (nestedOption.options) {
          return renderNestedOption(
            props,
            nestedOption.label,
            nestedOption.options,
            labelKey,
            valueKey,
            index + 1,
          );
        }

        return (
          <div style={{ paddingLeft: (index - 1) * 12 }} key={nestedOption[valueKey]}>
            <components.Option
              {...props}
              innerProps={{
                ...innerProps,
                onClick: () => selectOption(nestedOption),
              }}
            >
              {nestedOption[labelKey]}
            </components.Option>
          </div>
        );
      })}
    </div>
  );
};

const Select = forwardRef(
  (
    {
      defaultValue,
      name,
      onChange,
      className,
      labelKey = 'label',
      valueKey = 'value',
      options,
      isMulti = false,
      getOptionLabel,
      placeholder,
      style,
      isClearable = true,
      readOnly,
      customOption,
      customSingleValue,
      noIndicator,
      isSearchable = true,
      minimal,
      menuPortalTarget,
      menuPosition,
      backspaceRemovesValue = true,
      openMenuOnFocus = true,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const styles = {
      container: base => ({
        ...base,
        ...style,
        ...(minimal && { padding: 0, height: 'fit-content' }),
      }),
      control: (base, state) => ({
        ...base,
        border: 0,
        // This line disable the blue border
        boxShadow: 'none',
        ...(state.isDisabled && { backgroundColor: '#F5F5F5' }),
        ...(minimal && { minHeight: 'fit-content', height: 'fit-content' }),
      }),
      option: (base, state) => ({
        ...base,
        display: state.isDisabled ? 'none' : 'block',
        backgroundColor: 'inherit',
        color: 'black',
        ':active': {
          backgroundColor: '#7BA0F4',
        },
        ':hover': {
          backgroundColor: '#7BA0F430',
        },
      }),
      groupHeading: (base, state) => ({
        ...base,
        fontSize: '1rem',
        paddingTop: '8px',
      }),
      placeholder: (base, state) => ({
        ...base,
        color: '#DDDDDD',
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        ...(minimal && { padding: '0 0.5rem' }),
      }),
      indicatorsContainer: (provided, state) => ({
        ...provided,
        ...(minimal && { height: 'fit-content', alignSelf: 'center' }),
      }),
      input: (provided, state) => ({
        ...provided,
        ...(minimal && { height: 'fit-content' }),
      }),
      clearIndicator: (provided, state) => ({
        ...provided,
        ...(minimal && { padding: '0 0.5rem' }),
      }),
      menu: (provided, state) => ({ ...provided, width: 'auto' }),
      menuPortal: base => ({
        // fix for layout settings
        ...base,
        zIndex: 99999,
      }),
      multiValueLabel: (base, state) => {
        return state.data.isFixed ? { ...base, color: '#888888', paddingRight: 6 } : base;
      },
      multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
      },
    };

    const onFocusHandle = () => setIsFocused(true);

    const onBlurHandle = () => setIsFocused(false);

    const Option = props => {
      const { children, data } = props;
      const nestedOptions = data.options;

      if (nestedOptions) {
        const label = data.label;
        return renderNestedOption(props, label, nestedOptions, labelKey, valueKey, 2);
      }

      return <components.Option {...props}>{children}</components.Option>;
    };

    return (
      <ReactSelect
        name={name}
        ref={ref}
        options={options}
        styles={styles}
        className={classnames(className, { focused: !!isFocused }, 'common-lookup')}
        isMulti={isMulti}
        onFocus={onFocusHandle}
        onBlur={onBlurHandle}
        onChange={onChange}
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel || (option => option[labelKey])}
        getOptionValue={option => option[valueKey]}
        placeholder={placeholder}
        isClearable={isClearable}
        isDisabled={readOnly}
        isSearchable={isSearchable}
        backspaceRemovesValue={backspaceRemovesValue}
        components={{
          Option: customOption ? customOption : Option,
          ...(customSingleValue && { SingleValue: customSingleValue }),
          ...(minimal && { ClearIndicator }),
          ...(noIndicator && { IndicatorSeparator: () => null }),
          ...(noIndicator && { DropdownIndicator: () => null }),
        }}
        menuPlacement="auto"
        menuPortalTarget={menuPortalTarget} // fix for layout settings
        menuPosition={menuPosition} // fix for layout settings
        openMenuOnFocus={openMenuOnFocus}
      />
    );
  },
);

Select.propTypes = {};
Select.defaultProps = {};

export default Select;
