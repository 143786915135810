import { put, takeEvery, take, call } from 'redux-saga/effects';
import {
  CASE_UPLOAD_TRANSCRIPT_NOTES_BEGIN,
  CASE_UPLOAD_TRANSCRIPT_NOTES_SUCCESS,
  CASE_UPLOAD_TRANSCRIPT_NOTES_FAILURE,
  CASE_UPLOAD_TRANSCRIPT_NOTES_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { prepareFile } from './uploadFiles';

export const uploadTranscriptNotes = createPromiseAction(
  CASE_UPLOAD_TRANSCRIPT_NOTES_BEGIN,
  (payloadFile, payloadOnBehalfOfUserId, payloadId) => ({
    newFile: payloadFile,
    onBehalfOfUserId: payloadOnBehalfOfUserId,
    fileId: payloadId,
  }),
);

export function dismissUploadTranscriptNotesFeedback() {
  return {
    type: CASE_UPLOAD_TRANSCRIPT_NOTES_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPLOAD_FILE_BEGIN actions
function* doUploadTranscriptNotes(action) {
  const {
    payload: { newFile, fileId, onBehalfOfUserId, caseId },
  } = action;
  const fileToSend = prepareFile(newFile);

  const channel = yield call(
    api.createUploadFileChannel,
    'POST',
    `/cases/${caseId}/files/${fileId}/transcriptNotes${
      onBehalfOfUserId ? `?onBehalfOfUserId=${onBehalfOfUserId}` : ''
    }`,
    fileToSend,
  );

  while (true) {
    const { error, success, response } = yield take(channel);
    if (error) {
      yield put({
        type: CASE_UPLOAD_TRANSCRIPT_NOTES_FAILURE,
        feedback: {
          message: 'feedback.uploadTranscriptNotesFailure',
          error: error,
          retryAction: action,
        },
        fileId,
      });
      return yield call(rejectPromiseAction, action, error);
    }
    if (success) {
      yield put({
        type: CASE_UPLOAD_TRANSCRIPT_NOTES_SUCCESS,
        feedback: {
          message:
            response && typeof response === 'string'
              ? response
              : 'feedback.uploadTranscriptNotesSuccess',
          params: { fileId },
        },
      });
      return yield call(resolvePromiseAction, action, success);
    }
  }
}

export function* watchUploadTranscriptNotes() {
  yield takeEvery(uploadTranscriptNotes, withCurrentCaseId(doUploadTranscriptNotes));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPLOAD_TRANSCRIPT_NOTES_BEGIN + '.TRIGGER':
      return {
        ...state,
        uploadTranscriptNotesPending: true,
        uploadTranscriptNotesFeedback: null,
      };

    case CASE_UPLOAD_TRANSCRIPT_NOTES_SUCCESS:
      return {
        ...state,
        uploadTranscriptNotesPending: false,
        uploadTranscriptNotesFeedback: action.feedback,
      };

    case CASE_UPLOAD_TRANSCRIPT_NOTES_FAILURE:
      return {
        ...state,
        uploadTranscriptNotesPending: false,
        uploadTranscriptNotesFeedback: action.feedback,
      };

    case CASE_UPLOAD_TRANSCRIPT_NOTES_DISMISS_FEEDBACK:
      return {
        ...state,
        uploadTranscriptNotesPending: false,
        uploadTranscriptNotesFeedback: null,
      };

    default:
      return state;
  }
}
