import { faHighlighter, faSquare } from '@fortawesome/free-solid-svg-icons';
import { CustomViewerButton, SimpleTooltip } from 'features/common';
import React from 'react';
import { MouseToolType } from '../../types';
import T from 'i18n';

export default ({
  mouseTool,
  selectedMouseTool,
  currentPageNumber,
}: {
  mouseTool: any;
  selectedMouseTool: string;
  currentPageNumber: number;
}) => {
  return (
    <React.Fragment>
      <>
        <SimpleTooltip title={T.translate('case.highlightAnnotation')}>
          <CustomViewerButton
            icon={faHighlighter}
            className={selectedMouseTool === MouseToolType.HighlightAnnotation ? 'selected' : ''}
            onClick={(e: any) => {
              mouseTool(MouseToolType.HighlightAnnotation);
            }}
          />
        </SimpleTooltip>
        <SimpleTooltip title={T.translate('case.rectangleAnnotationTooltip')}>
          <CustomViewerButton
            icon={faSquare}
            className={selectedMouseTool === MouseToolType.RectangleAnnotation ? 'selected' : ''}
            onClick={(e: any) => {
              mouseTool(MouseToolType.RectangleAnnotation);
            }}
          />
        </SimpleTooltip>
      </>
    </React.Fragment>
  );
};
