import React, { useEffect, useMemo, useState } from 'react';
import {
  TextField,
  Checkbox,
  InputAdornment,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material';
import PerfectScrollbar from 'perfect-scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import { containsText } from '../../utils';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useFetchTags } from 'features/case/redux/fetchTags';
import { createTag } from 'features/case/redux/CreateTag';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
}));

export default ({ value, placeholder, onChange, onBlur, readOnly, ...rest }: any) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<any>>(value);
  const [searchText, setSearchText] = useState('');

  const classes = useStyles();

  const dispatch = useDispatch();

  const { fetchTags, tags } = useFetchTags();

  //MOUNT
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => fetchTags(), []);

  const displayedOptions = useMemo(
    () => tags.filter((tag: any) => containsText(tag.name, searchText)),
    [searchText, tags],
  );

  const handleClickCreateTag = () => {
    dispatch(createTag({ tag: { name: searchText } }));
  };

  return (
    <Select
      // Disables auto focus on MenuItems and allows TextField to be in focus
      MenuProps={{
        autoFocus: false,
      }}
      variant="outlined"
      sx={{
        boxShadow: 'none',
        '.MuiSelect-select': {
          ...(readOnly && { cursor: 'default' }),
        },
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      }}
      value={selectedOptions}
      label="Options"
      onBlur={onBlur}
      onChange={e => {
        setSelectedOptions(e.target.value as any);
        onChange && onChange(e.target.value as any);
      }}
      onOpen={e => {
        // only way I could find to get perfect scrollbar in
        let perfectScrollbar = null as any;
        setTimeout(() => {
          const paper = document.getElementsByClassName('MuiPaper-root')[0];
          perfectScrollbar = new PerfectScrollbar(paper);
        });
        setTimeout(() => perfectScrollbar?.update(), 250);
      }}
      onClose={() => {
        setSearchText('');
      }}
      displayEmpty
      // This prevents rendering empty string in Select's value
      // if search text would exclude currently selected option.
      renderValue={selected => {
        // we don't use selected cause we can have [undefined]
        if (selectedOptions.length === 0) {
          return <span style={{ opacity: 0.4, color: 'rgba(0, 0, 0, 0.87)' }}>{placeholder}</span>;
        }
        return tags.length > 0
          ? selectedOptions
              .map(option => {
                const tag = tags.find(({ id }: any) => option === id);
                return tag?.name;
              })
              .join(', ')
          : '';
      }}
      multiple
      readOnly={readOnly}
      {...(readOnly && { IconComponent: () => null })}
    >
      {/* TextField is put into ListSubheader so that it doesn't
               act as a selectable item in the menu
               i.e. we can click the TextField without triggering any selection.*/}
      <ListSubheader sx={{ zIndex: 0, lineHeight: '1rem' }}>
        <TextField
          size="small"
          // Autofocus on textfield
          autoFocus
          autoComplete="off"
          variant="outlined"
          placeholder={placeholder}
          fullWidth
          InputProps={{
            classes: { notchedOutline: classes.noBorder },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchText &&
                  !displayedOptions
                    .map((tag: any) => tag.name.toLowerCase())
                    .includes(searchText.toLowerCase()) && (
                    <IconButton onClick={handleClickCreateTag} edge="end">
                      <AddBoxIcon />
                    </IconButton>
                  )}
              </InputAdornment>
            ),
          }}
          onChange={e => {
            setSearchText(e.target.value);
          }}
          onKeyDown={e => {
            if (e.key !== 'Escape') {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      {/* needs to be a flat list for select control */}
      <ListSubheader sx={{ zIndex: 0, lineHeight: '1rem' }}>
        {searchText ? 'Search Results' : 'Tags'}
      </ListSubheader>
      {displayedOptions.map((tag: any) => (
        <MenuItem key={tag.id} value={tag.id}>
          <Checkbox checked={selectedOptions.includes(tag.id)} sx={{ padding: '0.25rem 0.5rem' }} />
          <ListItemText primary={tag.name} />
        </MenuItem>
      ))}
    </Select>
  );
};
