import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Badge,
  Checkbox,
  Collapse,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { searchCenterLocation } from './enums';
import { isMobile } from 'utils/device';

export const getSelectedLocation = (folder: any) => {
  if (folder.folders?.length > 0) {
    return [
      { id: folder.id, name: folder.name },
      ...folder.folders.reduce((acc: any, itm: any) => {
        if (itm.folders?.length > 0) {
          return [...acc, ...getSelectedLocation(itm)];
        }
        return [...acc, { id: itm.id, name: itm.name }];
      }, []),
    ];
  }
  return [{ id: folder.id, name: folder.name }];
};

const removeSelectedLocation = (folder: any, filters: any) => {
  // Remove the folder itself
  filters.location = filters.location.filter((loc: any) => loc.id !== folder.id);
  // Recursively remove all child folders
  if (folder.folders) {
    folder.folders.forEach((child: any) => {
      removeSelectedLocation(child, filters);
    });
  }
  return filters.location;
};

const isAnyFolderSelected = (folder: any, filters: any) => {
  if (filters.location.find((loc: any) => loc.id === folder.id)) {
    return true;
  }
  if (folder.folders) {
    return folder.folders.some((child: any) => isAnyFolderSelected(child, filters));
  }
  // If no folder is selected
  return false;
};

const LocationItem = ({ item, filters, handleChangeFilters, isAnswersMode }: any) => {
  return (
    <ListItem
      key={item.id}
      value={item.id}
      disabled={
        item.id === 'trialBundle' ||
        item.id === 'teamBundles' ||
        item.id === 'privateBundles' ||
        (item.id === searchCenterLocation.selectedMatches && item.matches?.length === 0)
      }
      sx={{
        maxWidth: isMobile ? '90vw' : '50vw',
        justifyContent: 'space-between',
        py: 0,
        ...(!!filters.location.find((loc: any) => loc.id === item.id) && {
          backgroundColor: '#E7EEF9',
        }),
      }}
    >
      <span
        style={{ width: item.matches?.length > 0 ? 'calc(100% - 11rem)' : 'calc(100% - 4rem)' }}
      >
        <Tooltip title={isAnswersMode ? item.id : null} placement="top" arrow>
          <Typography
            sx={{
              maxWidth: isMobile ? '50vw' : '30vw',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {'\u00A0'.repeat(item.level * 2) + item.name}
          </Typography>
        </Tooltip>
      </span>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {item.matches?.length > 0 && (
          <Typography sx={{ width: isMobile ? 'auto' : '7rem' }}>
            ` (${item.matches.length} matches)
          </Typography>
        )}
        {item.id !== 'trialBundle' && item.id !== 'teamBundles' && item.id !== 'privateBundles' && (
          <Checkbox
            color="primary"
            name={item.id}
            disabled={
              item.id === searchCenterLocation.selectedMatches && item.matches?.length === 0
            }
            onChange={e => {
              if (isAnswersMode) {
                if (
                  e.target.name === searchCenterLocation.allMatches ||
                  e.target.name === searchCenterLocation.selectedMatches
                ) {
                  if (e.target.checked)
                    handleChangeFilters({
                      location:
                        e.target.name === searchCenterLocation.allMatches
                          ? [{ id: 'allMatches', name: 'All Matches', type: 'matches' }]
                          : [item],
                    });
                } else {
                  handleChangeFilters({
                    location: e.target.checked
                      ? [
                          ...filters.location.filter(
                            (itm: any) => itm.id !== 'selectedMatches' && itm.id !== 'allMatches',
                          ),
                          item,
                        ]
                      : filters.location.filter((itm: any) => itm.id !== item.id),
                  });
                }
              } else {
                handleChangeFilters({
                  location: e.target.checked
                    ? [...filters.location, ...getSelectedLocation(item)]
                    : removeSelectedLocation(item, filters),
                });
              }
            }}
            checked={!!filters.location.find((loc: any) => loc.id === item.id)}
            sx={{ '&.Mui-checked': { color: '#7BA0F4' } }}
          />
        )}
      </span>
    </ListItem>
  );
};

const CollapseLocationItem = ({
  item,
  filters,
  handleChangeFilters,
  expandedLocationsList,
  setExpandedLocationsList,
  isAnswersMode,
  filtersScrollbarRef,
  lastFolderFromList,
}: any) => {
  return (
    <>
      <ListItem
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          ...(!!filters.location.find((loc: any) => loc.id === item.id) && {
            backgroundColor: '#E7EEF9',
          }),
        }}
      >
        <span
          style={{
            ...(item.folders?.length > 0 && {
              height: '3rem',
              display: 'flex',
              alignItems: 'center',
            }),
          }}
        >
          {'\u00A0'.repeat(item.level * 2) + item.name}
        </span>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Badge
            color="primary"
            overlap="circular"
            variant="dot"
            badgeContent={isAnyFolderSelected(item, filters) ? '' : 0}
          >
            <IconButton
              size="small"
              onClick={() => {
                setExpandedLocationsList(
                  expandedLocationsList.includes(item.id)
                    ? expandedLocationsList.filter((itm: any) => itm !== item.id)
                    : [...expandedLocationsList, item.id],
                );
                if (lastFolderFromList && !expandedLocationsList.includes(item.id)) {
                  setTimeout(() => {
                    if (filtersScrollbarRef.current) {
                      const container = filtersScrollbarRef.current;
                      container.scrollTop = container.scrollHeight + 100;
                    }
                  }, 250);
                }
              }}
            >
              {expandedLocationsList.includes(item.id) ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>
          </Badge>
          {item.id !== 'trialBundle' && item.id !== 'teamBundles' && item.id !== 'privateBundles' && (
            <Checkbox
              color="primary"
              name={item.id}
              onChange={e => {
                if (
                  e.target.name === searchCenterLocation.allMatches ||
                  e.target.name === searchCenterLocation.selectedMatches
                ) {
                  if (e.target.checked) {
                    handleChangeFilters({
                      location:
                        e.target.name === searchCenterLocation.allMatches
                          ? [{ id: 'allMatches', name: 'All Matches', type: 'matches' }]
                          : [{ id: 'selectedMatches', name: 'Selected Matches', type: 'matches' }],
                    });
                  }
                } else {
                  handleChangeFilters({
                    location: e.target.checked
                      ? [...filters.location, ...getSelectedLocation(item)]
                      : removeSelectedLocation(item, filters),
                  });
                }
              }}
              checked={!!filters.location.find((loc: any) => loc.id === item.id)}
              sx={{ '&.Mui-checked': { color: '#7BA0F4' } }}
            />
          )}
        </div>
      </ListItem>
      <Collapse in={expandedLocationsList.includes(item.id)}>
        {expandedLocationsList.includes(item.id) &&
          item.folders.map((itm: any) =>
            itm.folders?.length > 0 ? (
              <CollapseLocationItem
                key={itm.id}
                item={itm}
                filters={filters}
                handleChangeFilters={handleChangeFilters}
                expandedLocationsList={expandedLocationsList}
                setExpandedLocationsList={setExpandedLocationsList}
                isAnswersMode={isAnswersMode}
                filtersScrollbarRef={filtersScrollbarRef}
                lastFolderFromList={
                  lastFolderFromList && item.folders[item.folders.length - 1].id === itm.id
                }
              />
            ) : (
              <LocationItem
                key={itm.id}
                item={itm}
                filters={filters}
                handleChangeFilters={handleChangeFilters}
                isAnswersMode={isAnswersMode}
              />
            ),
          )}
      </Collapse>
    </>
  );
};

const SearchInputLocations = ({
  locationsMenu,
  filters,
  handleChangeFilters,
  isAnswersMode,
  filtersScrollbarRef,
}: any) => {
  const [expandedLocationsList, setExpandedLocationsList] = React.useState<any[]>([]);
  return locationsMenu.map((item: any) =>
    item.folders?.length > 0 ? (
      <CollapseLocationItem
        key={item.id}
        item={item}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
        expandedLocationsList={expandedLocationsList}
        setExpandedLocationsList={setExpandedLocationsList}
        isAnswersMode={isAnswersMode}
        filtersScrollbarRef={filtersScrollbarRef}
        lastFolderFromList={locationsMenu[locationsMenu.length - 1].id === item.id}
      />
    ) : (
      <LocationItem
        key={item.id}
        item={item}
        filters={filters}
        handleChangeFilters={handleChangeFilters}
        isAnswersMode={isAnswersMode}
      />
    ),
  );
};

export default SearchInputLocations;
