import React, { useState } from 'react';
import { Typography } from '@mui/material';
import Duration from 'features/common/Duration';

export default ({ duration, playerRef }: any) => {
  const [seconds, setSeconds] = useState(0);

  setInterval(() => {
    const newSeconds = Math.round((playerRef.current && playerRef.current.getCurrentTime()) || 0);
    if (seconds !== newSeconds) setSeconds(newSeconds);
  }, 1000);

  return (
    <Typography sx={{ color: 'whitesmoke' }}>
      <Duration seconds={seconds} /> / <Duration seconds={duration} />
    </Typography>
  );
};
