import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_ASSIGN_EVENTS_TO_TIMELINE_BEGIN,
  CASE_ASSIGN_EVENTS_TO_TIMELINE_SUCCESS,
  CASE_ASSIGN_EVENTS_TO_TIMELINE_FAILURE,
  CASE_ASSIGN_EVENTS_TO_TIMELINE_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const assignEventsToTimeline = createPromiseAction(CASE_ASSIGN_EVENTS_TO_TIMELINE_BEGIN);

export function dismissAssignEventsToTimelineFeedback() {
  return {
    type: CASE_ASSIGN_EVENTS_TO_TIMELINE_DISMISS_FEEDBACK,
  };
}

export function* doAssignEventsToTimeline(action) {
  const {
    payload: { eventsToCopy, timelineId, caseId },
  } = action;
  // if no folder no assign
  if (!timelineId) {
    return yield put({
      type: CASE_ASSIGN_EVENTS_TO_TIMELINE_FAILURE,
      feedback: {
        message: 'feedback.assignEventsToTimelineFailure',
        params: { unsuccessful: eventsToCopy.length },
        error: 'No folder id specified',
      },
    });
  }

  const res = yield call(api.post, `/cases/${caseId}/timelines/${timelineId}/events`, eventsToCopy);

  if (res && res.error) {
    yield put({
      type: CASE_ASSIGN_EVENTS_TO_TIMELINE_FAILURE,
      feedback: {
        message: 'feedback.assignEventsToTimelineFailure',
        params: { unsuccessful: eventsToCopy.length },
        error: res.error,
      },
      /*retryAction: action,*/
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_ASSIGN_EVENTS_TO_TIMELINE_SUCCESS,
    data: res,
    feedback: {
      message: 'feedback.assignEventsToTimelineSuccess',
      params: { successful: eventsToCopy.length },
    },
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAssignEventsToTimeline() {
  yield takeEvery(assignEventsToTimeline, withCurrentCaseId(doAssignEventsToTimeline));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_ASSIGN_EVENTS_TO_TIMELINE_BEGIN + '.TRIGGER':
      return {
        ...state,
        assignEventsToTimelinePending: true,
        assignEventsToTimelineFeedback: null,
      };

    case CASE_ASSIGN_EVENTS_TO_TIMELINE_SUCCESS:
      return {
        ...state,
        assignEventsToTimelinePending: false,
        assignEventsToTimelineFeedback: action.feedback,
      };

    case CASE_ASSIGN_EVENTS_TO_TIMELINE_FAILURE:
      return {
        ...state,
        assignEventsToTimelinePending: false,
        assignEventsToTimelineFeedback: action.feedback,
      };

    case CASE_ASSIGN_EVENTS_TO_TIMELINE_DISMISS_FEEDBACK:
      return {
        ...state,
        assignEventsToTimelineFeedback: null,
      };

    default:
      return state;
  }
}
