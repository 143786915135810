import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import T from 'i18n';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import * as actions from './redux/actions';
import Table from '../common/Table';
import SortHeader from './TableCase/SortHeader';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F7F7F8',
    height: '100%',
    padding: '0 0.7rem',
    overflow: 'overlay',
  },
  fullsize: {
    height: '100%',
  },
}));

const columnSelector = () => {
  return [
    {
      id: 'action',
      Header: () => <SortHeader text="common.action" />,
      Cell: log => (
        <div className="customGrid">
          <span style={{ overflowWrap: 'anywhere' }}>{log.action || ''}</span>
        </div>
      ),
      minWidth: '250px',
      width: '30rem',
    },
    {
      id: 'name',
      Header: () => <SortHeader text="common.who" />,
      Cell: log => (
        <div className="customGrid">
          <span style={{ overflowWrap: 'anywhere' }}>{log.name || ''}</span>
        </div>
      ),
      minWidth: '150px',
      width: '15rem',
      sort: false,
    },
    {
      id: 'date',
      Header: () => <SortHeader text="common.when" />,
      Cell: log => (
        <div className="customGrid">
          <span>
            {moment(log.date)
              .utc()
              .format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
      ),
      minWidth: '150px',
      width: '15rem',
    },
    {
      id: 'additionalData',
      Header: () => <SortHeader text="common.additionalData" />,
      Cell: log => (
        <div className="customGrid">
          <span>
            {log.previousData
              ? Object.entries(log.previousData).map(
                  data => `${data[0].charAt(0).toUpperCase() + data[0].slice(1)}: "${data[1]}", `,
                )
              : ''}
          </span>
        </div>
      ),
      minWidth: '250px',
      width: 'calc(100% - 60rem)',
      sort: false,
    },
  ];
};

const HearingRoomLogs = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [hearingRoomLogs, setHearingRoomLogs] = useState(null);
  const columns = columnSelector();

  useEffect(() => {
    if (!hearingRoomLogs)
      dispatch(actions.fetchHearingRoomLogs({ roomType: 'public' })).then(res =>
        setHearingRoomLogs(res),
      );
  }, [hearingRoomLogs, dispatch]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.fullsize}>
        <Grid container item xs={12}>
          <div className="case-table-wrapper admin-table-wrapeer" key="table-wrapper">
            <Container fluid className="h-100">
              <Row className="justify-content-between align-items-center p-2 px-3">
                <Breadcrumb>
                  <Breadcrumb.Item active={true}>
                    {T.translate('case.hearingRoomLogs')}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Row>
              <Row className="secondRow">
                <Col md={12} className="h-100">
                  <Card>
                    {hearingRoomLogs && hearingRoomLogs.length > 0 && (
                      <Table
                        key={Math.random()}
                        data={hearingRoomLogs}
                        columns={columns}
                        isItemLoaded={({ index }) => !!hearingRoomLogs[index]}
                        loadMoreItems={() => {}}
                        totalDocumentsCount={0}
                        rowHeight={60}
                        readOnly
                      />
                    )}
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HearingRoomLogs;
