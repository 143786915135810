import React from 'react';
import T from 'i18n';
import { HelpOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Modal } from 'features/common/MaterialBasedComponents';

const SearchInfo = ({ isNewSearch }: any) => {
  const [showInfoPanel, setShowInfoPanel] = React.useState(false);
  return (
    <>
      <div
        style={{ ...(isNewSearch && { paddingBottom: 0 }) }}
        onClick={() => setShowInfoPanel(true)}
      >
        <HelpOutline fontSize="large" color="primary" />
      </div>
      {showInfoPanel && (
        <Modal
          show={showInfoPanel}
          handleClose={() => setShowInfoPanel(false)}
          title="AI tools Info"
          buttons={null}
          handleSave={null}
        >
          <Typography variant="body1">
            {isNewSearch
              ? T.translate('case.aiToolsHelpCenterForTopics')
              : T.translate('case.aiToolsHelpCenterForQuestions')}
          </Typography>
        </Modal>
      )}
    </>
  );
};

export default SearchInfo;
