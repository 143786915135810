import React from 'react';
export const SuccessStatus = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" fillOpacity="0.01" />
    <circle cx="10" cy="10" r="6" fill="#14B053" />
  </svg>
);

export const ErrorStatus = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H20V20H0V0Z" fill="white" fillOpacity="0.01" />
    <rect
      x="10.0713"
      y="3"
      width="10"
      height="10"
      transform="rotate(45 10.0713 3)"
      fill="#D61F1F"
    />
  </svg>
);

export const WarningStatus = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H20V20H0V0Z" fill="white" fillOpacity="0.01" />
    <rect
      x="10.0713"
      y="3"
      width="10"
      height="10"
      transform="rotate(45 10.0713 3)"
      fill="#F5A623"
    />
  </svg>
);
