import {
  CASE_UPDATE_TIMELINES_BEGIN,
  CASE_UPDATE_TIMELINES_SUCCESS,
  CASE_UPDATE_TIMELINES_FAILURE,
  CASE_UPDATE_TIMELINES_DISMISS_FEEDBACK,
} from './constants';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { selectCurrentFolderId, withCurrentCaseId } from '../../../common/selectors';

export const updateTimelines = createPromiseAction(CASE_UPDATE_TIMELINES_BEGIN);

export function dismissUpdateTimelinesFeedback() {
  return {
    type: CASE_UPDATE_TIMELINES_DISMISS_FEEDBACK,
  };
}

export function* doUpdateTimelines(action) {
  const { caseId, timeline, showSuccessToast = false } = action.payload;
  const currentFolderId = yield select(selectCurrentFolderId);
  let res = yield call(api.put, `/cases/${caseId}/timelines/${timeline.id}`, timeline);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_TIMELINES_FAILURE,
      feedback: { message: 'feedback.updateTimelinesFailure', error: true, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_TIMELINES_SUCCESS,
    data: { timeline: res, isCurrentFolder: currentFolderId === res.id },
    feedback:
      showSuccessToast && currentFolderId !== res.id
        ? { message: 'feedback.updateTimelinesSuccess', params: { timelineName: res.name } }
        : null,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateTimelines() {
  yield takeLatest(updateTimelines, withCurrentCaseId(doUpdateTimelines));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_TIMELINES_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateTimelinesPending: true,
        updateTimelinesFeedback: null,
      };

    case CASE_UPDATE_TIMELINES_SUCCESS:
      // The request is success
      return {
        ...state,
        updateTimelinesPending: false,
        ...(action.data && action.data.isCurrentFolder && { timeline: action.data.timeline }),
        updateTimelinesFeedback: action.feedback,
      };

    case CASE_UPDATE_TIMELINES_FAILURE:
      // The request is failed
      return {
        ...state,
        updateTimelinesPending: false,
        updateTimelinesFeedback: action.feedback,
      };

    case CASE_UPDATE_TIMELINES_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateTimelinesFeedback: null,
      };

    default:
      return state;
  }
}
