import React, { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import ToggleButton from './ToggleButton';
// import PropTypes from 'prop-types';

const ToggleForm = forwardRef(
  ({ label, name, defaultValue, onChange, readOnly, value, disabled }, ref) => (
    <Form.Control
      as={ToggleButton}
      style={{ justifyContent: 'space-between', alignItems: 'center' }}
      name={name}
      ref={ref}
      defaultValue={defaultValue}
      label={label}
      onChange={onChange}
      readOnly={readOnly}
      checked={value}
      disabled={disabled}
    />
  ),
);

ToggleForm.propTypes = {};
ToggleForm.defaultProps = {};

export default ToggleForm;
