import React, { Component } from 'react';
import AnnotationsTableWrapper from './AnnotationsTableWrapper';
import T from 'i18n';

export default class AllAnnotationsPage extends Component {
  static propTypes = {};

  render() {
    return <AnnotationsTableWrapper category={T.translate('case.allAnnotations')} />;
  }
}
