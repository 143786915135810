import t from 'typy';

export const objectValueFromPath = (path, object) => t(object, path).safeObject;

export const isObject = obj => {
  return obj === Object(obj);
};

export const isString = x => {
  return Object.prototype.toString.call(x) === '[object String]';
};
