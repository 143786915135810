import { Button, Grid, Typography } from '@mui/material';
import T from 'i18n';
import React from 'react';

const SearchErrorPage = ({ errorText, startNewSearch }: any) => {
  return (
    <Grid item xs={12}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant={'h6'}>{errorText}</Typography>
        <Button size="large" variant="text" color="primary" onClick={() => startNewSearch()}>
          {T.translate('case.newSearch')}
        </Button>
      </div>
    </Grid>
  );
};

export default SearchErrorPage;
