import React, { useEffect } from 'react';
import T from 'i18n';
import { MenuItem, Menu, Typography, IconButton, TextField, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchSearchHistory, useFetchSearchHistory } from '../redux/fetchSearchHistory';
import { Spinner } from 'features/common';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Delete, Edit, Group } from '@mui/icons-material';
import { useUpdateSearchHistoryItem } from '../redux/updateSearchHistoryItem';
import { useDeleteSearchHistoryItem } from '../redux/deleteSearchHistoryItem';
import { groupByDateLabel, groupDataByDate } from 'utils/groupDataByDate';

interface SearchHistoryProps {
  open: boolean;
  anchorEl: any;
  handleSearchHistoryClose: any;
  handleSearchItemClick: Function;
}

const SearchHistory = ({
  open,
  anchorEl,
  handleSearchHistoryClose,
  handleSearchItemClick,
}: SearchHistoryProps) => {
  const dispatch = useDispatch();
  const {
    searchHistory: searchHistoryOriginal,
    fetchSearchHistoryPending,
  } = useFetchSearchHistory();
  const { updateSearchHistoryItemPending, updateSearchHistoryItem } = useUpdateSearchHistoryItem();
  const { deleteSearchHistoryItemPending, deleteSearchHistoryItem } = useDeleteSearchHistoryItem();
  const [selectedItem, setSelectedItem] = React.useState<any>(null);
  const [searchHistory, setSearchHistory] = React.useState<any>({});

  const updateItem = (searchHistoryItem: any) => {
    updateSearchHistoryItem({ searchHistoryItem });
    setSelectedItem(null);
  };

  const areAllGroupsEmpty = (groups: any) => {
    return Object.values(groups).every((group: any) => group.length === 0);
  };

  useEffect(() => {
    dispatch(fetchSearchHistory()).then((res: any) => {
      const formattedData = groupDataByDate(res);
      setSearchHistory(formattedData);
    });
  }, [dispatch]);

  useEffect(() => {
    const formattedData = groupDataByDate(searchHistoryOriginal);
    setSearchHistory(formattedData);
  }, [searchHistoryOriginal]);

  return (
    <Menu
      id="search-history-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleSearchHistoryClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
        //sx: { height: '60vh', width: '30vw' },
      }}
    >
      {fetchSearchHistoryPending ||
      deleteSearchHistoryItemPending ||
      updateSearchHistoryItemPending ? (
        <MenuItem disabled sx={{ width: '30vw', justifyContent: 'center' }}>
          <Spinner />
        </MenuItem>
      ) : searchHistory && !areAllGroupsEmpty(searchHistory) ? (
        <PerfectScrollbar style={{ maxHeight: '60vh' }}>
          {Object.entries(searchHistory).map(
            ([key, value]: any, index: number) =>
              value.length > 0 && (
                <React.Fragment key={index}>
                  <MenuItem disabled>
                    <Typography variant="subtitle1">{groupByDateLabel(key)}</Typography>
                  </MenuItem>
                  {value.map((searchHistoryItm: any, indexV: number) => (
                    <MenuItem
                      key={indexV}
                      onClick={() => handleSearchItemClick(searchHistoryItm.id)}
                      sx={{ justifyContent: 'space-between' }}
                    >
                      {selectedItem && selectedItem.id === searchHistoryItm.id ? (
                        <TextField
                          autoFocus
                          size="small"
                          defaultValue={selectedItem.name}
                          placeholder={T.translate('case.searchHistoryItemPlaceholder')}
                          required
                          onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          onBlur={(e: any) =>
                            e.target.value &&
                            selectedItem.name !== e.target.value &&
                            updateItem({ ...selectedItem, name: e.target.value })
                          }
                          onKeyDown={(e: any) => {
                            if (
                              e.key === 'Enter' &&
                              e.target.value &&
                              selectedItem.name !== e.target.value
                            )
                              updateItem({ ...selectedItem, name: e.target.value });
                          }}
                          fullWidth
                        />
                      ) : (
                        <>
                          <Typography
                            sx={{
                              width: '25vw',
                              wordWrap: 'break-word',
                              whiteSpace: 'normal',
                              textOverflow: 'clip',
                            }}
                          >
                            {searchHistoryItm.name}
                          </Typography>
                          <div>
                            {searchHistoryItm.contributors &&
                              searchHistoryItm.contributors.length > 0 && (
                                <Tooltip
                                  title={searchHistoryItm.contributors
                                    .map((itm: any) => itm.name)
                                    .join(', ')}
                                  placement="top"
                                  arrow
                                >
                                  <Group
                                    fontSize="small"
                                    sx={{ cursor: 'default', color: 'GrayText' }}
                                  />
                                </Tooltip>
                              )}
                            <IconButton
                              size="small"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                setSelectedItem(searchHistoryItm);
                              }}
                            >
                              <Edit fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                deleteSearchHistoryItem({ searchHistoryItm });
                                selectedItem && setSelectedItem(null);
                              }}
                            >
                              <Delete fontSize="small" />
                            </IconButton>
                          </div>
                        </>
                      )}
                    </MenuItem>
                  ))}
                </React.Fragment>
              ),
          )}
        </PerfectScrollbar>
      ) : (
        <MenuItem disabled>{T.translate('case.noSearchHistory')}</MenuItem>
      )}
    </Menu>
  );
};

export default SearchHistory;
