import React, { Component } from 'react';

import T from 'i18n';

export default class ViewerError extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="viewing-viewer-error">
        <h1 className="no-compute-text">{T.translate('viewing.doesNotCompute')}</h1>
      </div>
    );
  }
}
