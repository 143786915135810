import { useContext } from 'react';
import { DroppableBodyContainerContext } from './DroppableBodyContainerProvider';

export default function useDroppableBodyContanerContext() {
  const context = useContext(DroppableBodyContainerContext);
  if (!context) {
    throw new Error(
      'useDroppableBodyContanerContext must be used within a DroppableBodyContanerProvider',
    );
  }
  return context;
}
