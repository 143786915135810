import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import history from './history';
import configuredStore from 'common/configStore';
import { selectCountryCode } from './selectors';

// const getCountryCode = () => {
//   return selectCountryCode(configuredStore.store.getState());
// };

// const countryCode = getCountryCode();
// const reactPlugin = new ReactPlugin();
// const ai = new ApplicationInsights({
//   config: {
//     instrumentationKey: process.env.REACT_APP_INSIGHTS_KEY,
//     endpointUrl: process.env[`REACT_APP_AI_${countryCode}`],
//     extensions: [reactPlugin],
//     extensionConfig: {
//       [reactPlugin.identifier]: { history },
//     },
//     isCookieUseDisabled: false,
//   },
// });
// ai.loadAppInsights();

export default Component => Component; //withAITracking(reactPlugin, Component, '', 'h-100');
export const appInsights = null; //ai.appInsights;
