import React from 'react';
// import PropTypes from 'prop-types';
import GL from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';
import classnames from 'classnames';

const NUM_ROWS = 50;
const NUM_COLS = 50;

export default function GridLayout({
  className,
  style,
  children,
  layout,
  onLayoutChange,
  resizable = false,
  grow = false,
}) {
  const newChildren = [];

  const mapNewChildren = children =>
    React.Children.forEach(children, element => {
      if (!element) return;
      if (Array.isArray(element)) {
        mapNewChildren(element);
      } else
        newChildren.push(
          React.cloneElement(
            element,
            element &&
              element.props['data-grid'] && {
                'data-grid': { ...element.props['data-grid'], static: !resizable },
              },
          ),
        );
    });
  mapNewChildren(children);

  const modifiedLayout =
    layout &&
    layout.map((item, index) => ({
      i: (newChildren[index] || {}).key,
      ...item,
      static: !resizable,
    }));

  let lastGoodNewItem;

  const handleOnDrag = (layout, oldItem, newItem, placeholder, e, element) => {
    if (newItem.y + newItem.h > NUM_ROWS) {
      const newY = NUM_ROWS - newItem.h;
      const any = layout.find(
        ({ x, y, w, h, i }) =>
          newItem.i !== i &&
          !(
            x + w <= newItem.x ||
            x >= newItem.x + newItem.w ||
            y + h <= newY ||
            y >= newY + newItem.h
          ),
      );
      //if any other item in that area then revert to lastGoodNewItem
      if (!!any) {
        newItem.y = lastGoodNewItem.y;
        newItem.x = lastGoodNewItem.x;
      } else {
        lastGoodNewItem = {
          ...lastGoodNewItem,
          y: (newItem.y = NUM_ROWS - newItem.h),
          x: newItem.x,
        };
      }
    } else {
      lastGoodNewItem = newItem;
    }
  };

  return (
    <SizeMe monitorHeight>
      {({ size }) => {
        return (
          <GL
            useCSSTransforms={true} //to avoid using transform for positioning elements
            className={classnames('common-grid-layout', className)}
            style={style}
            layout={modifiedLayout}
            cols={NUM_COLS}
            rowHeight={grow ? 10 : size.height / NUM_ROWS}
            width={size.width || 0}
            autoSize={grow}
            compactType={null}
            margin={[0, 0]}
            preventCollision={true}
            // // USE TO GET THE LAYOUT
            // onLayoutChange={layout => {
            //   const mapped = layout.map(({ i, static: bla, moved, ...rest }) => rest);
            //   Logger.INFO(JSON.stringify(mapped));
            // }}
            onLayoutChange={onLayoutChange}
            {...(!grow && {
              onResize: (layout, oldItem, newItem, placeholder, e, element) => {
                if (newItem.y + newItem.h > NUM_ROWS) {
                  newItem.h = NUM_ROWS - newItem.y;
                  newItem.maxH = newItem.h;
                }
              },
              onResizeStop: (layout, oldItem, newItem, placeholder, e, element) => {
                newItem.maxH = undefined;
              },
              onDragStart: (layout, oldItem, newItem, placeholder, e, element) => {
                lastGoodNewItem = newItem;
              },
              onDrag: handleOnDrag,
              onDragStop: handleOnDrag,
            })}
          >
            {newChildren}
          </GL>
        );
      }}
    </SizeMe>
  );
}

GridLayout.propTypes = {};
GridLayout.defaultProps = {};
