import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getDocumentRotationInfo,
  getScrollContainer,
  getScrollInfo,
  getZoomScaleInfo,
  makeMarkDisappear,
} from '../utils';
import { useSelector } from 'react-redux';
import {
  selectCurrentCase,
  selectCurrentFolder,
  selectCurrentSelectedFileMetaData,
  selectIsGlobalPageLookup,
  selectMarkBasedScrolling,
  selectMatchedUrl,
  selectPresentQuery,
  selectQuery,
} from 'common/selectors';
import { HIGHLIGHTSDISAPPEAR, MARK_TYPE, PRESENT_COLOR } from '../constants';
import { MouseTool, MouseTools, Mark } from '@prizmdoc/viewer-core';
import { useStartPresenting } from 'features/viewing/redux/startPresenting';
import { useSendMessage } from 'features/viewing/redux/sendMessage';
import history from 'common/history';
import { makeCancelable } from 'utils/promises';
import { useLeaveAGroup } from 'features/viewing/redux/leaveAGroup';
import logger from 'utils/logger';

export const useSendMessages = ({
  passViewingSessionIdToReceivers,
  firstParentTriaBundleFileDetails,
  fileId,
  options,
  viewingFilePrivate,
  viewerControl,
  setCurrentMouseTool,
  selectedMouseTool,
  deleteMarkToBeCreated,
}: any) => {
  const { sendMessage: sendMessageApi } = useSendMessage();
  const { leaveAGroup } = useLeaveAGroup();
  const { startPresenting } = useStartPresenting();

  const [otherChanges, setOtherChanges] = useState<any>(null);
  const [markToDisappear, setMarkToDisappear] = useState<any | null>(null);

  const present = useSelector(selectPresentQuery);
  const currentCase = useSelector(selectCurrentCase);
  const currentFolder = useSelector(selectCurrentFolder);
  const currentFileMetaData = useSelector(selectCurrentSelectedFileMetaData) as any;
  const isGlobalPageLookup = useSelector(selectIsGlobalPageLookup);
  const queryParam = useSelector(selectQuery);
  const currentUrl = useSelector(selectMatchedUrl);
  const markBasedScrolling = useSelector(selectMarkBasedScrolling);

  const showGlobalPaging = currentFolder && currentFolder.globalPaging;
  const isPresentModePage = currentUrl.includes(`${currentCase.id}/present-`);
  const isPresenting = !!present;

  const leaveGroupSafeRef = useRef<any>(null);
  const startPresentingSafeRef = useRef<any>(null);
  const addCenterHelperMarkSafeRef = useRef<any>(null);

  const sendMessage = useCallback(
    (message: any) => {
      if (!isPresenting) return;
      const fullMessage = {
        fileId,
        target: 'present',
        room: present,
        message,
      };
      sendMessageApi({ message: fullMessage });
    },
    [fileId, isPresenting, present, sendMessageApi],
  );

  const getFullMessageForStartPresenting = useCallback(() => {
    const currentFileDetails =
      showGlobalPaging ||
      isGlobalPageLookup ||
      (firstParentTriaBundleFileDetails &&
        Object.keys(firstParentTriaBundleFileDetails).length > 0) ||
      (isPresentModePage &&
        currentFileMetaData &&
        Object.keys(currentFileMetaData).includes('folderId'))
        ? {
            ...currentFileMetaData,
            ...(Object.keys(firstParentTriaBundleFileDetails).length > 0 &&
              firstParentTriaBundleFileDetails),
          }
        : Object.keys(queryParam).includes('startPage')
        ? queryParam
        : null;

    if (!viewerControl) return {};

    const rest = {
      ...(passViewingSessionIdToReceivers && {
        viewingSessionId: options.documentID,
        prizmApiUrl: options.imageHandlerUrl,
        viewingFilePrivate: viewingFilePrivate,
      }),
      fileId,
      fileType: 'doc',
      showGlobalPaging:
        showGlobalPaging ||
        Object.keys(queryParam).includes('startPage') ||
        isGlobalPageLookup ||
        (firstParentTriaBundleFileDetails &&
          Object.keys(firstParentTriaBundleFileDetails).length > 0) ||
        (isPresentModePage &&
          currentFileMetaData &&
          Object.keys(currentFileMetaData).includes('folderId')),
      ...(currentFileDetails && {
        currentFileDetails: {
          globalPagePrefix: currentFileDetails.globalPagePrefix,
          startPage: currentFileDetails.startPage,
          pageCount: currentFileDetails.pageCount,
        },
      }),
      ...getScrollInfo(getScrollContainer(viewerControl)),
      ...getZoomScaleInfo(viewerControl),
      ...getDocumentRotationInfo(viewerControl),
    };

    return { markObjects: [], ...rest };
  }, [
    currentFileMetaData,
    fileId,
    firstParentTriaBundleFileDetails,
    isGlobalPageLookup,
    isPresentModePage,
    options.documentID,
    options.imageHandlerUrl,
    passViewingSessionIdToReceivers,
    queryParam,
    showGlobalPaging,
    viewerControl,
    viewingFilePrivate,
  ]);

  const addCenterHelperMark = useCallback(
    (otherChng?: any, offset = 0) => {
      let lastMarkHelper: any;

      const markCreated = ({ mark }: any) => {
        const scrollMark = mark.type === MARK_TYPE;
        if (scrollMark) lastMarkHelper = mark;
      };

      try {
        const myMouseToolName = 'scrollHelperTool';
        const myMouseTool = MouseTools.createMouseTool(
          myMouseToolName,
          MouseTool.Type.PlaceSignature,
        );
        myMouseTool.getTemplateMark().setSignature({
          // type: MARK_TYPE,
          text:
            '............................................................................................',
          fontName: 'Aerial',
          // path: 'M0,0',
          // color: '#FFFFFF', NOT WORKING RETARDED ACUSOFT
        });
        myMouseTool.getTemplateMark().interactionMode = Mark.InteractionMode.SelectionDisabled;

        const clientX =
          getScrollContainer(viewerControl)?.getBoundingClientRect().x +
          getScrollContainer(viewerControl)?.clientWidth / 2;
        const clientY =
          getScrollContainer(viewerControl)?.getBoundingClientRect().y +
          getScrollContainer(viewerControl)?.clientHeight / 2;

        const createMarkHelper = () => {
          viewerControl?.setCurrentMouseTool(myMouseToolName);

          getScrollContainer(viewerControl)?.dispatchEvent(
            new MouseEvent('mousedown', {
              clientX,
              clientY: clientY + offset,
              view: window,
            }),
          );
          getScrollContainer(viewerControl)?.dispatchEvent(
            new MouseEvent('mouseup', {
              clientX,
              clientY: clientY + offset,
              view: window,
            }),
          );
          setCurrentMouseTool(selectedMouseTool);
        };

        setOtherChanges(otherChng);

        viewerControl?.on('MarkCreated', markCreated);

        createMarkHelper();
      } catch {
        lastMarkHelper = 'error';
      } finally {
        setTimeout(() => {
          viewerControl?.off('MarkCreated', markCreated);
          if (!lastMarkHelper) {
            // DO ANOTHER ONE IF IN THE MIDDLE OF THE SCREEN
            addCenterHelperMark(otherChng, 30);
          }
        }, 250); // needed timeout so set state get's set
      }
    },
    [viewerControl, setCurrentMouseTool, selectedMouseTool],
  );

  const updateScrollPositionForReceivers = useCallback(
    (otherChng?: any) => {
      if (!viewerControl || !isPresenting) return;

      if (!markBasedScrolling) {
        sendMessage({
          ...otherChng,
          ...getScrollInfo(getScrollContainer(viewerControl)),
        });
      } else {
        addCenterHelperMarkSafeRef.current = makeCancelable(
          new Promise<void>(r => {
            addCenterHelperMark(otherChng);
            r();
          }),
        );
        addCenterHelperMarkSafeRef.current.promise.then(() => {});
      }
    },
    [addCenterHelperMark, isPresenting, markBasedScrolling, sendMessage, viewerControl],
  );

  const deleteMarkToDisappear = useCallback(() => {
    if (markToDisappear) {
      viewerControl.deleteMarks([markToDisappear]);
      setMarkToDisappear(null);
    }
  }, [markToDisappear, viewerControl]);

  const deletePresentMarks = useCallback(() => {
    try {
      deleteMarkToDisappear();
      const presentMarks = viewerControl
        ?.getAllMarks()
        .filter((x: any) => x.fillColor === PRESENT_COLOR);
      if (presentMarks?.length > 0) {
        viewerControl?.deleteMarks(presentMarks);
      }
    } catch (e) {
      logger.ERROR(e);
    }
  }, [deleteMarkToDisappear, viewerControl]);

  const startStopPresentHandler = useCallback(
    (mode?: 'private' | 'public', forcePresent?: boolean) => {
      const containsGpParam = Object.keys(queryParam).includes('startPage');
      const containsFolderId = Object.keys(queryParam).includes('folderId');
      const gpParamStr = `startPage=${queryParam.startPage}&pageCount=${queryParam.pageCount}&globalPagePrefix=${queryParam.globalPagePrefix}`;
      const folderIdParamStr = `folderId=${queryParam.folderId}`;
      deleteMarkToBeCreated();
      if (
        isPresenting &&
        (!forcePresent || (present === 'public' && currentFileMetaData.private))
      ) {
        leaveGroupSafeRef.current = makeCancelable(
          new Promise((r: any) => leaveAGroup('present').then(() => r())),
        );
        leaveGroupSafeRef.current.promise.then(() => {
          history.push(
            history.location.pathname.replace(/\?present.*/, ``) +
              `${containsFolderId ? `?${folderIdParamStr}` : ''}${
                containsFolderId && containsGpParam ? '&' : ''
              }${containsGpParam ? `${gpParamStr}` : ''}`,
          );
          // TO-DO this might require useEffect to execute after present was set
          deletePresentMarks();
          setCurrentMouseTool(selectedMouseTool, false);
          // --------------------------------------------
        });
      } else {
        startPresentingSafeRef.current = makeCancelable(
          new Promise((r: any) => {
            startPresenting({
              hearingRoomMode: mode,
              message: getFullMessageForStartPresenting(),
            }).then(() => {
              if (markBasedScrolling) addCenterHelperMark();
              r();
            });
          }),
        );
        startPresentingSafeRef.current.promise.then(() => {
          setCurrentMouseTool(selectedMouseTool, true);
          history.push(
            history.location.pathname.replace(/\?present.*/, ``) +
              `?${containsFolderId ? `${folderIdParamStr}&` : ''}${
                containsGpParam ? `${gpParamStr}&` : ''
              }present=${mode}`,
          );
        });
      }
    },
    [
      addCenterHelperMark,
      currentFileMetaData.private,
      deleteMarkToBeCreated,
      deletePresentMarks,
      getFullMessageForStartPresenting,
      isPresenting,
      leaveAGroup,
      markBasedScrolling,
      present,
      queryParam,
      selectedMouseTool,
      setCurrentMouseTool,
      startPresenting,
    ],
  );

  const MarkCreated = useCallback(
    ({ mark }: any) => {
      if (isPresenting) {
        mark.interactionMode = Mark.InteractionMode.SelectionDisabled;
        mark.setData('visible', 'true');
        viewerControl.serializeMarks([mark]).then((markObjects: any) => {
          sendMessage({ ...otherChanges, markObjects });
          const scrollMark = mark.type === MARK_TYPE;

          if (scrollMark) {
            viewerControl.deleteMarks([mark]);
            setOtherChanges(null);
            return;
          }

          if (HIGHLIGHTSDISAPPEAR && markToDisappear) {
            const m = markToDisappear;
            makeMarkDisappear(
              m,
              () => viewerControl.deleteMarks([m]),
              () => !isPresenting,
            );
          }

          if (markToDisappear !== mark) setMarkToDisappear(mark);
        });
      }
    },
    [isPresenting, markToDisappear, otherChanges, sendMessage, viewerControl],
  );

  const MarkClickedHandler = useCallback(
    ({ mark }: any) => {
      if (mark && isPresenting) {
        mark.interactionMode = Mark.InteractionMode.SelectionDisabled;
        mark.visible = false;
        mark.setData('visible', 'false');
        if (HIGHLIGHTSDISAPPEAR) {
          viewerControl.serializeMarks([mark]).then((markObjects: any) => {
            sendMessage({ ...otherChanges, markObjects });
            viewerControl.deleteMarks([mark]);
            setMarkToDisappear(null);
          });
        }
      }
    },
    [isPresenting, otherChanges, sendMessage, viewerControl],
  );

  useEffect(() => {
    viewerControl?.on('MarkCreated', MarkCreated);
    viewerControl?.on('Click', MarkClickedHandler);
    return () => {
      viewerControl?.off('MarkCreated', MarkCreated);
      viewerControl?.off('Click', MarkClickedHandler);
    };
  }, [MarkCreated, viewerControl, MarkClickedHandler]);

  useEffect(() => {
    return () => {
      startPresentingSafeRef.current?.cancel();
      leaveGroupSafeRef.current?.cancel();
      addCenterHelperMarkSafeRef.current?.cancel();
    };
  }, []);

  return { startStopPresentHandler, updateScrollPositionForReceivers };
};
