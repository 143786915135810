import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from 'features/common/NavBar';
import Footer from 'features/common/Footer';
import classnames from 'classnames';
import T from 'i18n';
import {
  selectPermissions,
  selectDocumentsOnlyWhenInRoom,
  selectRoomToken,
  selectCurrentCase,
  selectEvidenceViewEnabled,
} from 'common/selectors';
import PageNotFound from 'features/common/PageNotFound';
import RefreshButton from './RefreshButton';
import FullScreenButton from './FullScreenButton';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import { useFetchTheatreData } from 'features/viewing/redux/fetchTheatreData';
import { useLeaveAGroup } from 'features/viewing/redux/leaveAGroup';
import { usePrevious } from 'utils/hooks';
import { Authenticated } from 'features/auth/withAuth';
import FileViewer from './FileViewer';

const TheatreDocOnly = ({ hearingRoomMode }) => {
  const [fullScreen, setFullScreen] = useState(!!document.fullscreenElement);
  const { fetchTheatreData, theatreData, privateTheatreData } = useFetchTheatreData();
  const { leaveAGroup } = useLeaveAGroup();
  const fetchCaseDetailsPending = useSelector(state => state.case.fetchCaseDetailsPending);
  const fetchCasesPending = useSelector(state => state.home.fetchCasesPending);
  const documentsOnlyWhenInRoom = useSelector(selectDocumentsOnlyWhenInRoom);
  const isJoinedToRoom = useSelector(state => selectRoomToken(state, hearingRoomMode));
  const { hearingRoom } = useSelector(selectPermissions);
  const aCase = useSelector(selectCurrentCase);
  const td = hearingRoomMode === 'public' ? theatreData : privateTheatreData;
  const messages = td?.messages;
  const hasMessages = messages?.length > 0;
  const [splitterSizes, setSplitterSizes] = useState(messages?.length > 1 ? [50, 50] : [100, 0]);

  const isEvidenceViewEnabled = useSelector(selectEvidenceViewEnabled);

  const prevDocumentsOnlyWhenInRoom = usePrevious(documentsOnlyWhenInRoom, null);
  const prevIsJoinedToRoom = usePrevious(isJoinedToRoom, null);

  function fullscreenchanged() {
    setFullScreen(!!document.fullscreenElement);
  }

  function resize() {
    setFullScreen(window.innerHeight === window.screen.height);
  }

  const onMount = () => {
    if (!documentsOnlyWhenInRoom || isJoinedToRoom) fetchTheatreData({ hearingRoomMode });
    document.addEventListener('fullscreenchange', fullscreenchanged);
    window.addEventListener('resize', resize);

    return () => {
      leaveAGroup('receive');
      document.removeEventListener('fullscreenchange', fullscreenchanged);
      window.removeEventListener('resize', resize);
    };
  };
  useEffect(onMount, []);

  useEffect(() => {
    if (fetchCaseDetailsPending) return;

    if (documentsOnlyWhenInRoom) {
      if (prevIsJoinedToRoom !== isJoinedToRoom) {
        if (isJoinedToRoom) {
          fetchTheatreData({ hearingRoomMode });
        } else {
          leaveAGroup('receive');
        }
      }
    } else {
      if (prevDocumentsOnlyWhenInRoom !== documentsOnlyWhenInRoom) {
        fetchTheatreData({ hearingRoomMode });
      }
    }
  }, [
    fetchCaseDetailsPending,
    prevDocumentsOnlyWhenInRoom,
    documentsOnlyWhenInRoom,
    prevIsJoinedToRoom,
    isJoinedToRoom,
    hearingRoomMode,
    fetchTheatreData,
    leaveAGroup,
  ]);

  useEffect(() => {
    setSplitterSizes(messages?.length > 1 ? [50, 50] : [100, 0]);
  }, [messages?.length]);

  if (fetchCasesPending || fetchCaseDetailsPending) return null;

  const isPublic = hearingRoomMode === 'public';
  const isPrivate = hearingRoomMode !== 'public';

  const showDocuments = hearingRoom.canViewDocuments(hearingRoomMode);

  if (!hearingRoom.isShow || !showDocuments) return <PageNotFound />;

  const modeText = T.translate(isPublic ? 'case.publicHearingRoom' : 'case.privateHearingRoom');
  const brandText = [
    [aCase.name + `${aCase.claimNumber ? ` ${aCase.claimNumber}` : ''}`, modeText]
      .filter(Boolean)
      .join(' - '),
    T.translate('case.documentPresentationOnly'),
  ]
    .filter(Boolean)
    .join(' ');

  function handleResizeFinished(gutterIdx, newSizes) {
    setSplitterSizes(newSizes);
  }

  return (
    <div
      className={classnames({ dark: isPublic, blue: isPrivate }, 'case-theatre', {
        isEvidenceViewEnabled,
      })}
    >
      {!fullScreen && (
        <NavBar
          showLogo
          theme={classnames({ dark: isPublic, blue: isPrivate })}
          brandText={brandText}
          additionalButton={[<RefreshButton />, <FullScreenButton />].filter(Boolean)}
        />
      )}
      <div
        className={classnames('content', { 'full-screen': fullScreen })}
        style={{
          background: isEvidenceViewEnabled ? 'black' : 'white',
          color: isEvidenceViewEnabled ? 'white' : 'inherit',
        }}
      >
        {documentsOnlyWhenInRoom && !isJoinedToRoom ? (
          <h4
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              lineHeight: '2rem',
            }}
          >
            {T.translate('case.needToJoinRoom')}
          </h4>
        ) : hasMessages ? (
          <Splitter
            direction={SplitDirection.Horizontal}
            initialSizes={splitterSizes}
            minWidths={[170, 170]}
            minHeights={[170, 170]}
            onResizeFinished={handleResizeFinished}
          >
            {messages.map(fileData => (
              <FileViewer fileData={fileData} hearingRoomMode={hearingRoomMode} />
            ))}
          </Splitter>
        ) : (
          <h4
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {T.translate('case.waitingForPresenter')}
          </h4>
        )}
      </div>
      {!fullScreen && <Footer theme={classnames({ dark: isPublic, blue: isPrivate })} />}
    </div>
  );
};

export default props => (
  <Authenticated forceLogin>
    <TheatreDocOnly {...props} />
  </Authenticated>
);
