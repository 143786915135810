import React, { useCallback, useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
//import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Auth from '@aws-amplify/auth';
import { useForm } from 'react-hook-form';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import T from 'i18n';
import { OtpForm } from 'features/common';

export const otpLength = 6;

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1rem',
  },
  avatar: {
    backgroundColor: '#f50057 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '2rem',
  },
  magicText: {
    display: 'flex',
    padding: '1rem 2rem 1rem 1rem',
    backgroundColor: '#F3F3F3',
    borderRadius: '0.25rem',
    marginTop: '1.5rem',
  },
  error: { height: '1rem' },
});

function Otp({ onSignInCompleted, onSignInClick, session, onMfa, password }) {
  const classes = useStyles();

  const { handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [otpValue, setOtpValue] = useState('');

  const reSendCode = e => {
    e.preventDefault();
    setLoading(true);
    Auth.signIn(session.username, password)
      .then(user => {
        if (user.challengeName) {
          onMfa(user, password);
        }
      })
      .catch(e => {
        console.error(e);
        setMessage(T.translate('auth.otherIssueMessage'));
      })
      .finally(() => setLoading(false));
  };

  const verifyOtp = useCallback(
    data => {
      setLoading(true);
      if (session.challengeName === 'SMS_MFA') {
        // in case of migration only
        if (!session.username && !session.challengeParam.USER_ID_FOR_SRP)
          session.username = session.challengeParam.USER_ID_FOR_SRP;
        Auth.confirmSignIn(session, otpValue)
          .then(user => {
            onSignInCompleted(user);
          })
          .catch(err => {
            if (err.code === 'NotAuthorizedException')
              setMessage(T.translate('auth.codeExpiredMessage'));
            else if (err.code === 'CodeMismatchException')
              setMessage(T.translate('auth.codeMissmatchMessage'));
            else setMessage(T.translate('auth.otherIssueMessage'));
          })
          .finally(() => setLoading(false));
      } else {
        Auth.sendCustomChallengeAnswer(session, otpValue)
          .then(result => {
            if (result.signInUserSession) onSignInCompleted(result);
            else setMessage(T.translate('auth.invalidCodeMessage'));
          })
          .catch(err => {
            if (err.code === 'NotAuthorizedException')
              setMessage(T.translate('auth.codeExpiredMessage'));
            else setMessage(T.translate('auth.otherIssueMessage'));
          })
          .finally(() => setLoading(false));
      }
    },
    [onSignInCompleted, otpValue, session],
  );

  useEffect(() => {
    if (otpValue && otpValue.length === 6) verifyOtp();
  }, [otpValue, verifyOtp]);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Avatar className={classes.avatar} sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {T.translate(session.challengeName === 'SMS_MFA' ? 'auth.mfaTitle' : 'auth.otpTitle')}
        </Typography>
        <Typography className={classes.error} color="error" component="h1" variant="h6">
          {message}
        </Typography>
        <form onSubmit={handleSubmit(verifyOtp)} className={classes.form} noValidate>
          <OtpForm
            defaultValue={otpValue}
            label={T.translate('auth.otp')}
            setOtpValue={setOtpValue}
            setDisableSubmit={setDisableSubmit}
          />
          {/* <br />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Box className={classes.magicText}>
            <span style={{ marginLeft: '1rem' }}>
              {T.translate(
                session.challengeName === 'SMS_MFA'
                  ? 'auth.verificationCodeHasBeenSentToYourPhoneMessage'
                  : 'auth.verificationCodeHasBeenSentToYourEmailMessage',
              )}
            </span>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, fontSize: '1rem' }}
            disabled={loading || disableSubmit}
          >
            {loading && <CircularProgress size={17} />}
            {!loading && T.translate('auth.submit')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                component="button"
                variant="body2"
                onClick={onSignInClick}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.backToSignIn')}
              </Link>
            </Grid>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={reSendCode}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.reSendCode')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

Otp.propTypes = {};
Otp.defaultProps = {};

export default Otp;
