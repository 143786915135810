import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Authenticated } from 'features/auth/withAuth';
import Conference from 'features/conference/Conference';
import NavBar from 'features/common/NavBar';
import Footer from 'features/common/Footer';
import classnames from 'classnames';
import Split from 'react-split';
import T from 'i18n';
import {
  selectPermissions,
  selectParams,
  selectDocumentsOnlyWhenInRoom,
  selectRoomToken,
  selectLeftSideDocument,
  selectEvidenceViewEnabled,
  selectCurrentCase,
  selectIsLiveStream,
} from 'common/selectors';
import PageNotFound from 'features/common/PageNotFound';
import { isIOS } from 'utils/browser';
import { fileTypes } from './enums';
import FileViewer from 'features/case/FileViewer';
import RefreshButton from './RefreshButton';
import FullScreenButton from './FullScreenButton';
import SeparateDocumentButton from './SeparateDocumentButton';
import ReJoinDocumentButton from './ReJoinDocumentButton';
import ShowHideDocumentButton from './ShowHideDocumentButton';
import Streaming from 'features/whipStreaming';
import { useFetchTheatreData } from 'features/viewing/redux/fetchTheatreData';
import { useLeaveAGroup } from 'features/viewing/redux/leaveAGroup';
import { usePrevious } from 'utils/hooks';
import Splitter, { SplitDirection } from '@devbookhq/splitter';
import history from 'common/history';
import fscreen from 'fscreen';
import ConferenceRoomType from 'features/conference/enums/ConferenceRoomType';

const Theatre = ({ hearingRoomMode }) => {
  const [fullScreen, setFullScreen] = useState(!!fscreen.fullscreenElement);
  const { fetchTheatreData, theatreData, privateTheatreData } = useFetchTheatreData();
  const { leaveAGroup } = useLeaveAGroup();
  const fetchCaseDetailsPending = useSelector(state => state.case.fetchCaseDetailsPending);
  const fetchCasesPending = useSelector(state => state.home.fetchCasesPending);
  const documentsOnlyWhenInRoom = useSelector(selectDocumentsOnlyWhenInRoom);
  const isJoinedToRoom = useSelector(state => selectRoomToken(state, hearingRoomMode));
  const { hearingRoom, moderator } = useSelector(selectPermissions);
  const aCase = useSelector(selectCurrentCase);
  const td = hearingRoomMode === 'public' ? theatreData : privateTheatreData;
  const messages = td?.messages;
  const hasMessages = messages?.length > 0;
  const [splitterSizes, setSplitterSizes] = useState(messages?.length > 1 ? [50, 50] : [100, 0]);
  const [showDocument, setShowDocument] = useState(true);
  const { type } = useSelector(selectParams);
  const isEvidenceViewEnabled = useSelector(selectEvidenceViewEnabled);
  const leftSideDocument = useSelector(selectLeftSideDocument);
  const isLiveStream = useSelector(selectIsLiveStream);

  const isEvidenceViewEnabledAndConference =
    isEvidenceViewEnabled &&
    hearingRoom.conferenceType &&
    hearingRoom.conferenceType(hearingRoomMode) === ConferenceRoomType.twilio;

  const prevDocumentsOnlyWhenInRoom = usePrevious(documentsOnlyWhenInRoom, null);
  const prevIsJoinedToRoom = usePrevious(isJoinedToRoom, null);

  function fullscreenchanged(event) {
    setFullScreen(!!fscreen.fullscreenElement);
  }

  const onMount = () => {
    if (!documentsOnlyWhenInRoom || isJoinedToRoom) fetchTheatreData({ hearingRoomMode });

    fscreen.addEventListener('fullscreenchange', fullscreenchanged);

    return () => {
      leaveAGroup('receive');
      fscreen.removeEventListener('fullscreenchange', fullscreenchanged);
    };
  };

  useEffect(onMount, []);

  useEffect(() => {
    if (fetchCaseDetailsPending) return;

    if (documentsOnlyWhenInRoom) {
      if (prevIsJoinedToRoom !== isJoinedToRoom) {
        if (isJoinedToRoom) {
          fetchTheatreData({ hearingRoomMode });
        } else {
          leaveAGroup('receive');
        }
      }
    } else {
      if (prevDocumentsOnlyWhenInRoom !== documentsOnlyWhenInRoom) {
        fetchTheatreData({ hearingRoomMode });
      }
    }
  }, [
    fetchCaseDetailsPending,
    prevDocumentsOnlyWhenInRoom,
    documentsOnlyWhenInRoom,
    prevIsJoinedToRoom,
    isJoinedToRoom,
    hearingRoomMode,
    fetchTheatreData,
    leaveAGroup,
  ]);

  useEffect(() => {
    setSplitterSizes(messages?.length > 1 ? [50, 50] : [100, 0]);
  }, [messages?.length]);

  if (fetchCasesPending || fetchCaseDetailsPending) return null;

  const isPublic = hearingRoomMode === 'public';
  const isPrivate = hearingRoomMode !== 'public';

  const conferenceOnly = type === 'conference';

  const showDocuments = hearingRoom.canViewDocuments(hearingRoomMode);

  const showConference =
    hearingRoom.canJoinConference(hearingRoomMode) || hearingRoom.canStream(hearingRoomMode);

  if (
    !hearingRoom.isShow ||
    (conferenceOnly && !showConference) ||
    (!showConference && !showDocuments)
  )
    return <PageNotFound />;

  const modeText = T.translate(isPublic ? 'case.publicHearingRoom' : 'case.privateHearingRoom');
  const conferenceOnlyText = conferenceOnly && T.translate('case.conferenceOnly');

  const brandText = [
    [aCase.name + `${aCase.claimNumber ? ` ${aCase.claimNumber}` : ''}`, modeText]
      .filter(Boolean)
      .join(' - '),
    conferenceOnlyText,
  ]
    .filter(Boolean)
    .join(' ');

  function handleResizeFinished(gutterIdx, newSizes) {
    setSplitterSizes(newSizes);
  }

  const showOnlyDocument = !hearingRoom.isConferenceShow(hearingRoomMode);
  const showOnlyConference =
    isEvidenceViewEnabledAndConference ||
    !hasMessages ||
    conferenceOnly ||
    !showDocument ||
    (documentsOnlyWhenInRoom && !isJoinedToRoom);
  const expandOutButtonShow = !conferenceOnly && !showOnlyDocument && !isIOS;
  const rejoinButtonShow = conferenceOnly && showDocuments;
  const showHideDocumentButtonShow = isIOS && !conferenceOnly && hasMessages;

  return (
    <div className={classnames({ dark: isPublic, blue: isPrivate }, 'case-theatre')}>
      {!fullScreen && (
        <NavBar
          showLogo
          theme={classnames({ dark: isPublic, blue: isPrivate })}
          brandText={brandText}
          additionalButton={[
            moderator.canStartStopLiveStream && isLiveStream && <Streaming />,
            <RefreshButton />,
            expandOutButtonShow && <SeparateDocumentButton history={history} />,
            rejoinButtonShow && <ReJoinDocumentButton history={history} />,
            showHideDocumentButtonShow && (
              <ShowHideDocumentButton
                toggle={() => setShowDocument(prevState => !prevState)}
                shown={showDocument}
              />
            ),
            <FullScreenButton />,
          ].filter(Boolean)}
          showHearingRoom
        />
      )}
      <div className={classnames('content', { 'full-screen': fullScreen })}>
        <Split
          sizes={!showOnlyConference ? [50, 50] : leftSideDocument ? [0, 100] : [100, 0]}
          minSize={!showOnlyConference ? 100 : 0}
          expandToMin={false}
          gutterSize={!showOnlyConference ? 10 : 0}
          gutterAlign="center"
          snapOffset={30}
          dragInterval={1}
          direction="horizontal"
          cursor="col-resize"
          className={classnames({ 'no-gutter': !!showOnlyConference }, 'case-table-and-viewer')}
        >
          {leftSideDocument ? (
            <>
              <div style={{ width: '100%', height: '100%' }}>
                {!showOnlyConference && showDocuments && (
                  <Splitter
                    direction={SplitDirection.Horizontal}
                    initialSizes={splitterSizes}
                    minWidths={[170, 170]}
                    minHeights={[170, 170]}
                    onResizeFinished={handleResizeFinished}
                  >
                    {messages?.map(fileData => (
                      <div
                        style={{ height: '100%' }}
                        className={classnames({
                          'theatre-video': fileData.fileType === fileTypes.mp4,
                        })}
                      >
                        {!showOnlyConference && showDocuments && (
                          <FileViewer fileData={fileData} hearingRoomMode={hearingRoomMode} />
                        )}
                      </div>
                    ))}
                  </Splitter>
                )}
              </div>
              <div>
                {showConference && (
                  <Conference
                    key={`conference-${hearingRoomMode}`}
                    hearingRoomMode={hearingRoomMode}
                    bigScreen={showOnlyConference}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <div>
                {showConference && (
                  <Conference
                    key={`conference-${hearingRoomMode}`}
                    hearingRoomMode={hearingRoomMode}
                    bigScreen={showOnlyConference}
                  />
                )}
              </div>
              <div style={{ width: '100%', height: '100%' }}>
                {!showOnlyConference && showDocuments && (
                  <Splitter
                    direction={SplitDirection.Horizontal}
                    initialSizes={splitterSizes}
                    minWidths={[170, 170]}
                    minHeights={[170, 170]}
                    onResizeFinished={handleResizeFinished}
                  >
                    {messages?.map(fileData => (
                      <div
                        style={{ height: '100%' }}
                        className={classnames({
                          'theatre-video': fileData.fileType === fileTypes.mp4,
                        })}
                      >
                        <FileViewer fileData={fileData} hearingRoomMode={hearingRoomMode} />
                      </div>
                    ))}
                  </Splitter>
                )}
              </div>
            </>
          )}
        </Split>
      </div>
      {!fullScreen && <Footer theme={classnames({ dark: isPublic, blue: isPrivate })} />}
    </div>
  );
};

export default props => (
  <Authenticated forceLogin>
    <Theatre {...props} />
  </Authenticated>
);
