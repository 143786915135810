// Rekit uses a new approach to organizing actions and reducers. That is
// putting related actions and reducers in one file. See more at:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da

import { AUTH_SET_HUB_CONNECTION_ID, AUTH_SET_HUB_CONNECTION_ID_FAILURE } from './constants';

export function setHubConnectionId(connectionId) {
  return {
    type: AUTH_SET_HUB_CONNECTION_ID,
    connectionId,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_SET_HUB_CONNECTION_ID:
      return {
        ...state,
        hubConnectionId: action.connectionId,
      };
    case AUTH_SET_HUB_CONNECTION_ID_FAILURE:
      return {
        ...state,
        hubConnectionFeedback: action.feedback,
      };

    default:
      return state;
  }
}
