import React, { PureComponent } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import NavBarLinks from 'features/common/NavBarLinks';
import Logo from 'features/common/Logo';
import classnames from 'classnames';

export default class Sidebar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
    };
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
    this.props.updateWindowWidth && this.props.updateWindowWidth(window.innerWidth);
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  render() {
    return (
      <div
        id="sidebar"
        className={classnames('sidebar', { removeSidebar: !this.props.isSidebarOpen })}
        data-color="azure"
      >
        <Logo />
        <PerfectScrollbar
          className="sidebar-wrapper"
          style={{
            height: `calc(100vh - ${
              this.props.outsideFolderCount > 0 ? (this.props.outsideFolderCount - 1) * 35 + 125 : 0
            }px)`,
          }}
        >
          {
            <ul className="nav">
              {this.state.width <= 991 ? this.props.NavBarLinks || <NavBarLinks /> : null}
            </ul>
          }
          {this.props.children}
        </PerfectScrollbar>
        {this.props.outside}
      </div>
    );
  }
}
