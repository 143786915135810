// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

import { showDocFinderBookmarks } from './setShowDocFinderBookmarks';
import { showDocFinderFilter } from './setShowDocFinderFilter';

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  columns: [],
  containers: [],
  folders: {},
  documents: [],
  matches: [],
  totalDocuments: 0,
  uploadFiles: [],
  tags: [],
  tagGroups: [],
  authorsList: null,
  recipientsList: null,
  annotations: [],
  pageRefs: [],
  answer: null,
  answers: null,
  searchHistory: [],
  groups: [],
  aiToolsResponse: [],
  aiToolsFilters: {},
  searchMatchesInput: [],
  searchAnsChat: [],
  searchSuggestedQuestions: null,
  timeline: null,
  timelines: null,
  typedResponseCount: 0,
  currentSelectedFile: null,
  caseRegionTemp: null,
  featuresAndPermissions: {},
  globalPageLookup: null,
  entities: null,
  sortDirection: null,
  sortByColumn: null,
  fetchFoldersPending: true,
  fetchFoldersFeedback: null,
  fetchContainersPending: false,
  fetchContainersFeedback: null,
  fetchFolderPending: false,
  fetchFolderFeedback: null,
  fetchFolderAfterSortPending: false,
  fetchDocumentsPending: false,
  fetchDocumentsFeedback: null,
  isDeepSearchPerformed: false,
  uploadFilesPending: false,
  uploadFilesFeedback: null,
  uploadFilesTooLargeFeedback: null,
  uploadFilesProgressClosed: false,
  assignAnnotationsPending: false,
  assignAnnotationsFeedback: null,
  assignDocumentsPending: false,
  assignDocumentsFeedback: null,
  unassignDocumentsPending: false,
  unassignDocumentsFeedback: null,
  unassignDocumentMultiplePending: false,
  unassignDocumentMultipleFeedback: null,
  addFolderPending: false,
  addFolderFeedback: null,
  deleteFolderPending: false,
  deleteFolderFeedback: null,
  updateFolderPending: false,
  updateFolderFeedback: null,
  fetchDocumentPending: false,
  fetchDocumentFeedback: null,
  fetchDocumentPartial: false,
  updateDocumentPending: false,
  updateDocumentFeedback: null,
  updateDocumentSortPending: false,
  updateDocumentSortFeedback: null,
  mergeDocumentsPending: false,
  mergeDocumentsFeedback: null,
  templates: null,
  fetchTemplatesPending: false,
  fetchTemplatesFeedback: null,
  fetchTeamsPending: false,
  fetchTeamsFeedback: null,
  getTeamActivityLogsPending: false,
  getTeamActivityLogsFeedback: null,
  getMoreInfoDocumentPending: false,
  getMoreInfoDocumentError: null,
  getMoreInfoFolderPending: false,
  getFolderStatsPending: false,
  getMoreInfoFolderError: null,
  getFolderStats: null,
  dismissGetFolderStatsFeedback: null,
  fetchLayoutsPending: false,
  fetchLayoutsError: null,
  updateLayoutsPending: false,
  updateLayoutsError: null,
  fetchCaseDetailsPending: true,
  fetchCaseDetailsError: null,
  updateCasePending: false,
  updateCaseError: null,
  updateDocumentMetaPending: false,
  updateDocumentMetaError: null,
  fetchTsAndCsPending: false,
  fetchTsAndCsFeedback: null,
  acceptTsAndCsPending: false,
  acceptTsAndCsFeedback: null,
  fetchFileDetailsPending: false,
  fetchFileDetailsFeedback: null,
  replaceFilePending: false,
  replaceFileFeedback: null,
  generateHyperlinksPending: false,
  generateHyperlinksFeedback: null,
  fetchUserDetailsPending: false,
  fetchUserDetailsFeedback: null,
  fetchTeamPending: false,
  fetchTeamFeedback: null,
  updateTeamMemberPending: false,
  updateTeamMemberFeedback: null,
  removeTeamMemberPending: false,
  removeTeamMemberFeedback: null,
  sendInvitePending: false,
  sendInviteFeedback: null,
  fetchInviteLinkPending: false,
  fetchInviteLinkFeedback: null,
  acceptTeamInvitePending: false,
  acceptTeamInviteFeedback: null,
  fetchAdminsPending: false,
  fetchAdminsFeedback: null,
  fetchModeratorsPending: false,
  fetchModeratorsFeedback: null,
  addCaseRolesUserPending: false,
  addCaseRolesUserFeedback: null,
  removeCaseRolesUserPending: false,
  removeCaseRolesUserFeedback: null,
  addSystemTestPending: false,
  addSystemTestFeedback: null,
  fetchSystemTestPending: false,
  fetchSystemTestFeedback: null,
  fetchTagsPending: false,
  fetchTagsFeedback: null,
  createTagPending: false,
  createTagFeedback: null,
  deleteTagPending: false,
  deleteTagFeedback: null,
  updateTagPending: false,
  updateTagFeedback: null,
  fetchFileVersionsPending: false,
  fetchFileVersionsFeedback: null,
  updateFileVersionsPending: false,
  updateFileVersionsFeedback: null,
  createFileVersionPending: false,
  createFileVersionFeedback: null,
  fetchDialInNumbersPending: false,
  fetchDialInNumbersFeedback: null,
  updateDialInNumbersPending: false,
  updateDialInNumbersFeedback: null,
  uploadConfidentialityRingFilesPending: false,
  uploadConfidentialityRingFilesFeedback: null,
  generateAnswersPending: false,
  generateAnswersFeedback: null,
  showDocFinderFilter: true,
  showDocFinderBookmarks: false,
};

export default initialState;
