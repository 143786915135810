import { Divider, List, ListItemButton } from '@mui/material';
import React from 'react';

const SuggestedQuestionsCard = ({ response, processQuestions }: any) => {
  return (
    <List>
      {response.map((item: any, index: number) => (
        <ListItemButton key={index} onClick={() => processQuestions(false, item.text)}>
          <div style={{ flex: 1 }}>
            <span>{item.text}</span>
            <Divider variant="fullWidth" />
          </div>
        </ListItemButton>
      ))}
    </List>
  );
};

export default SuggestedQuestionsCard;
