export const transformDate = (value, prevValue) => transformDateTime(value, prevValue, 'date', '/');

export const transformTime = (value, prevValue) => transformDateTime(value, prevValue, 'time', ':');

const transformDateTime = (value, prevValue, key, seprator) => {
  if ((key === 'date' && value.length > 10) || (key === 'time' && value.length > 8))
    return prevValue || '';
  else if (value.length < (prevValue || '').length) {
    return value;
  } else if (value.length === 2 || value.length === 5) {
    if (value.length === 2 && (value[1] === '/' || value[1] === '.')) {
      return '0' + value[0] + seprator;
    } else if (value.length === 5 && (value[4] === '/' || value[4] === '.')) {
      return value.slice(0, 3) + '0' + value[3] + seprator;
    } else return value + seprator;
  } else if (key === 'date' && value.length === 4 && parseInt(value[3]) > 1) {
    return value.slice(0, 3) + '0' + value[3] + seprator;
  } else if (value.length > 2 && value[2] !== seprator && !value.slice(0, 4).includes('/'))
    return value.slice(0, 2) + seprator + value.slice(2);
  else if (value.length > 5 && value[5] !== seprator && !value.slice(4, 7).includes('/'))
    return value.slice(0, 5) + seprator + value.slice(5);
  else {
    return value;
  }
};
