import React, { useEffect, useRef, useState } from 'react';
// import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import T from 'i18n';

export default function ConnectivityListener() {
  const { addToast, removeToast } = useToasts();
  const [isOnline, setOnline] = useState(window ? window.navigator.onLine : false);
  const toastId = useRef(null);
  const initialMount = useRef(true);

  useEffect(() => {
    const onlineHandler = () => setOnline(true);
    const offlineHandler = () => setOnline(false);

    window.addEventListener('online', onlineHandler);
    window.addEventListener('offline', offlineHandler);

    return () => {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, []);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      const content = (
        <>
          <strong>
            {T.translate(isOnline ? 'generic.backOnlineTitle' : 'generic.wentOfflineTitle')}
          </strong>
          <div>{T.translate(isOnline ? 'generic.backOnline' : 'generic.wentOffline')}</div>
        </>
      );

      // remove the existing offline notification if it exists, otherwise store
      // the added toast id for use later
      const callback = isOnline
        ? () => {
            removeToast(toastId.current);
            toastId.current = null;
          }
        : id => {
            toastId.current = id;
          };

      // add the applicable toast
      addToast(
        content,
        { appearance: 'info', autoDismiss: isOnline /*onDismiss: () => alert('VBal') */ },
        callback,
      );
    }
  }, [addToast, removeToast, isOnline]);

  return null;
}

ConnectivityListener.propTypes = {};
ConnectivityListener.defaultProps = {};
