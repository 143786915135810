import React from 'react';
import Tooltip from 'features/common/SimpleTooltip';
import T from 'i18n';

export const TooltipWrapper = ({
  children,
  isPresenting,
}: {
  children: any;
  isPresenting: boolean;
}) => {
  const info = (
    <>
      <div>{T.translate('case.stopPresentingTooltip')}</div>
    </>
  );

  return isPresenting ? (
    <Tooltip
      title={info}
      // style={{ width: 'auto', minWidth: '200px', maxWidth: '350px', fontSize: '0.75rem' }}
    >
      {children}
    </Tooltip>
  ) : (
    children
  );
};
