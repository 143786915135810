import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import T from 'i18n';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { updateCase } from './redux/actions';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {
  TextForm,
  MultiSelectForm,
  ToggleForm,
  Spinner,
  SelectForm,
  PageNotFound,
} from 'features/common';
import { Link, Typography } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { useFetchCaseDetails } from './redux/fetchCaseDetails';
import Collapsible from 'features/common/Collapsible';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RenderDimensions as RenderDimensionsOptions } from 'features/twilio/utils';
import { ThumbnailSize as ThumbnailSizeOptions } from 'features/twilio/utils';
import { selectIsAdmin, selectIsCaseAdmin, selectParams } from 'common/selectors';
import validation from 'utils/validation';
import { fetchRolesByType } from 'features/common/redux/actions';
import { complexValuesToOptions } from 'utils/arrays';
import { Trans } from 'react-i18next';
import { openNewBackgroundTab } from 'utils/browser';
import ConferenceRoomType from 'features/conference/enums/ConferenceRoomType';

const conferenceOptions = complexValuesToOptions(
  [{ value: ConferenceRoomType.twilio, label: T.translate('generic.twilio'), isFixed: true }],
  'generic',
);

const dataRoomColumnsOptions = complexValuesToOptions(
  [
    { value: 'index', isFixed: true },
    { value: 'id', isFixed: true },
    { value: 'name', isFixed: true },
    { value: 'info', isFixed: true },
    { value: 'description', isFixed: false },
    { value: 'externalId', isFixed: false },
    { value: 'discoveryId', isFixed: false },
    { value: 'docDate', isFixed: false },
    { value: 'altDate', isFixed: false },
    { value: 'type', isFixed: false },
    { value: 'pageCount', isFixed: false },
    { value: 'size', isFixed: false },
    { value: 'author', isFixed: false },
    { value: 'recipient', isFixed: false },
  ],
  'generic',
);

const GridBreak = styled('div')({
  width: '100%',
});

const link = T.translate('generic.here');

export const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: '#F7F7F8',
    padding: theme.spacing(2, 2),
    height: '100%',
  },
  form: {
    height: 'calc(100% - 10rem)',
  },
  caseSettings: {
    height: '100%',
    backgroundColor: '#FFFFFF',
    backgroundClip: 'initial',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '.25rem',
  },
  heading: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 400,
    color: '#9a9a9a',
    margin: '1rem',
  },
  saveButton: {
    margin: theme.spacing(2),
    fontSize: '1rem',
    right: 40,
    height: '2.5rem',
    position: 'fixed',
  },
  caseDetails: {
    width: '100%',
    padding: '1rem 2rem 0 2rem',
  },
  divider: {
    padding: '2rem',
    '& $caseDetails': {
      padding: '0',
    },
  },
  '@media (max-width: 600px)': {
    heading: {
      fontSize: '1.2rem',
      lineHeight: '1.25rem',
    },
    caseSettings: {
      overflow: 'scroll',
    },
  },
}));

const CaseSettingsPage = () => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
    setValue,
  } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSystemAdmin = useSelector(selectIsAdmin);
  const isCaseAdmin = useSelector(selectIsCaseAdmin);
  const { templateId } = useSelector(selectParams);
  const { caseDetails, fetchCaseDetails } = useFetchCaseDetails();
  const {
    id,
    name,
    shortName,
    claimNumber,
    trialviewFilePrefix,
    trialviewFilePrefixLength = process.env.REACT_APP_FILE_PREFIX_LENGTH || 3,
    trialviewFileSecondTriadDigits = process.env.REACT_APP_FILE_SECOND_TRIAD_DIGITS || 4,
    trialviewFileThirdTriadDigits = process.env.REACT_APP_FILE_THIRD_TRIAD_DIGITS || 0,
    active = true,
    etag,
    settings = {},
    features,
    //aiSettings = {},
  } = caseDetails || {};

  const defaultColumns = ['index', 'id', 'name', 'info', 'docDate'];
  const {
    columns = defaultColumns,
    columnsSmallView = defaultColumns,
    globalPagingTrialbooks = true,
    publicHyperlinks = true,
    generateHyperlinks = false,
    truncateLongFolderName = true,
    customContainers = false,
    containers = ['Trial Bundles', 'Court Bundles', 'Bundles', 'Private Bundles'],
    mergeFilesOptions = {},
    eSignature = false,
    crossReference = false,
    crossRefFontSize = '7',
    enableRedaction = false,
    publicCrossRefs = true,
    sideBySideDocs = false,
    maxPagePrefetch = 5,
    showCourtBundles = true,
    showGroupBundles = false,
    widerDateColumn = false,
    widerColumns = false,
    deepSearch = false,
    usePrizmDocCaching = true,
    tags = false,
    enablePrebuiltDocCache = false,
    enableNotifications = false,
    pySemanticSearch = false,
    automaticallySetDocDate = true,
    timeline = false,
    bbcDemo = false,
    autosetAuthorOrRecipientFromEmails = false,
    autosetDocDateFromEmails = false,
    confidentialityRing = false,
    preserveAttchedFileSortOrder = false,
    useOnlyIdColForPresentMode = false,
    useBackEndForPresentMode = false,
    disableSearchFromAllDocs = false,
    changeTeamForCaseAdmin = false,
    docFinderFilter = false,
    showAllDocuments = true,
    allowBatchUpload = false,
  } = settings.dataRoom || {};

  const [container1, container2, container3, container4] = containers;
  const {
    labelBetween = 'BETWEEN:-',
    labelVersus = '-V-',
    labelClaimant = 'Claimant',
    labelDefendant = 'Defendant',
    indexPageJurisdiction,
    indexPageClaimant,
    indexPageDefendant,
    indexPageColumnsToDisplay,
    indexPageTableDateFormat,
  } = mergeFilesOptions || {};

  // const { matches, indices } = aiSettings || {};
  // const { multiQuery = false, maxMarginalRelevance = false, numMatches = '10' } = matches || {};

  const {
    privateConference = ConferenceRoomType.twilio,
    publicConference = ConferenceRoomType.twilio,
  } = features || {};

  const {
    defaultHearingRoomMessage = T.translate('conference.waitingForHearingRoomOverlay'),
    streamUrl = '',
    streamAltUrl = '',
    transcriptLink = '',
    transcriptLinkPrivate = '',
    documentsOnlyWhenInRoom = true,
    leftSideDocument = false,
    renderDimensionLow = 'low',
    renderDimensionStandard = '540p',
    renderDimensionHigh = '960p',
    thumbnailSize = 'medium',
    additionalThumbnailSize = 'medium',
    screenShareEnabled = false,
    evidenceViewEnabled = false,
    fiftyPlusHearingRoom = false,
    allowBotUsers = false,
    enableRoomToBeRecorded = false,
    markBasedScrolling = false,
    useWhepStreaming = false,
  } = settings.twilio || {};

  const trialviewFilePrefixMask = trialviewFilePrefix || '#'.repeat(trialviewFilePrefixLength);
  const trialviewFileSecondTriadMask = '#'.repeat(trialviewFileSecondTriadDigits);
  const trialviewFileThirdTriadMask = '#'.repeat(trialviewFileThirdTriadDigits);
  const [privateConferenceState, setPrivateConferenceState] = useState(privateConference);
  const [publicConferenceState, setPublicConferenceState] = useState(publicConference);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showCustomContainers, setShowCustomContainers] = useState(customContainers);
  const [showGenerateHyperlinks, setShowGenerateHyperlinks] = useState(publicHyperlinks);
  const [showPySemanticSearch, setShowPySemanticSearch] = useState(deepSearch);
  const [showCrossReference, setShowCrossReference] = useState(crossReference);
  const [defaultHearingRoomRoles, setDefaultHearingRoomRoles] = useState([]);
  const [hearingRoomRolesOptions, setHearingRoomRolesOptions] = useState([]);
  const [defaultDataRoomRoles, setDefaultDataRoomRoles] = useState([]);
  const [dataRoomRolesOptions, setDataRoomRolesOptions] = useState([]);
  const {
    customHearingRoomRoles = defaultHearingRoomRoles,
    setHearingRoomRoles = false,
    customDataRoomRoles = defaultDataRoomRoles,
    setDataRoomRoles = false,
  } = settings.roles || {};
  const [showSelectHearingRoomRoles, setShowSelectHearingRoomRoles] = useState(setHearingRoomRoles);
  const [showSelectDataRoomRoles, setShowSelectDataRoomRoles] = useState(setDataRoomRoles);
  const showTwilioSettings =
    privateConferenceState === ConferenceRoomType.twilio ||
    publicConferenceState === ConferenceRoomType.twilio;
  const defaultIndexPageColumn =
    indexPageColumnsToDisplay ||
    [
      'trialviewId',
      'title',
      columns.includes('externalId') && 'externalId',
      columns.includes('docDate') && 'docDate',
    ].filter(Boolean);
  const [showIndexPageDateFormat, setShowIndexPageDateFormat] = useState(
    columns.includes('docDate') && defaultIndexPageColumn.includes('docDate'),
  );
  const defaultIndexPageTableDateFormat = indexPageTableDateFormat || 'dd/MM/yyyy';
  const indexPageColumnsOptions = complexValuesToOptions(
    [
      { value: 'trialviewId', isFixed: false },
      { value: 'title', isFixed: false },
      { value: 'externalId', isFixed: false },
      { value: 'discoveryId', isFixed: false },
      { value: 'docDate', isFixed: false },
    ],
    'generic',
  );
  const simpleValuesToOptions = options =>
    options.map(value => ({ label: moment().format(value.FE), value: value.BE }));
  const indexPageTableColumnsOptions = simpleValuesToOptions([
    { BE: 'dd/MM/yyyy HH:mm', FE: 'DD/MM/YYYY HH:mm' },
    { BE: 'dd/MM/yyyy', FE: 'DD/MM/YYYY' },
    { BE: 'dd MMM yyy', FE: 'DD MMM YYYY' },
  ]);
  const onColumnChange = val => {
    if (val && val.includes('docDate')) setShowIndexPageDateFormat(true);
    else setShowIndexPageDateFormat(false);
  };

  const getFlatOptions = options =>
    options.reduce(
      (acc, item) => (item.options ? [...acc, ...getFlatOptions(item.options)] : [...acc, item]),
      [],
    );

  const onMount = () => {
    if (!isSystemAdmin && !isCaseAdmin) return;

    setLoading(true);

    fetchCaseDetails({ noPending: true });
    //To-Do-V need single api call for data & hearing room role
    dispatch(
      fetchRolesByType({
        type: 'dataRoomRole',
        groupRoleByClass: true,
        includePermissions: false,
      }),
    ).then(res => {
      const flatRoles = getFlatOptions(res);
      setDefaultDataRoomRoles(flatRoles.map(role => role.id));
      setDataRoomRolesOptions(
        flatRoles.map(role => ({
          label: role.name,
          value: role.id,
        })),
      );
      dispatch(
        fetchRolesByType({
          type: 'hearingRoomRole',
          groupRoleByClass: true,
          includePermissions: false,
        }),
      ).then(resp => {
        const flatRolesH = getFlatOptions(resp);
        setDefaultHearingRoomRoles(flatRolesH.map(role => role.id));
        setHearingRoomRolesOptions(
          flatRolesH.map(role => ({
            label: role.name,
            value: role.id,
          })),
        );
        setLoading(false);
      });
    });
    setPrivateConferenceState(privateConference);
    setPublicConferenceState(publicConference);

    return () => reset({});
  };

  useEffect(onMount, []);

  const allowBatchUploadWatch = watch('settings.dataRoom.allowBatchUpload', allowBatchUpload);

  useEffect(() => {
    if (allowBatchUploadWatch) {
      setValue('settings.dataRoom.enableNotifications', true);
    }
  }, [allowBatchUploadWatch, setValue]);

  if (!isSystemAdmin && !isCaseAdmin && !templateId) return <PageNotFound />;

  const handleShowTwilioSettings = () => {
    const {
      features: { privateConference: privateConf, publicConference: publicConf },
    } = getValues();

    if (privateConf !== privateConferenceState) setPrivateConferenceState(privateConf);

    if (publicConf !== publicConferenceState) setPublicConferenceState(publicConf);
  };

  const handleUpdateCaseSettings = updatedCaseDetails => {
    const { dataRoom, twilio } = settings || {};
    const {
      trialviewFilePrefixMask: updatedTrialviewFilePrefixMask,
      trialviewFileSecondTriadMask: updatedTrialviewFileSecondTriadMask,
      trialviewFileThirdTriadMask: updatedTrialviewFileThirdTriadMask,
      settings: updatedSettings,
      features: updatedFeatures,
      //aiSettings: updatedAiSettings,
      ...restUpdatedCaseDetails
    } = updatedCaseDetails || {};
    const { twilio: updatedTwilio, dataRoom: updatedDataRoom } = updatedSettings || {};
    const {
      container1: updatedContainer1,
      container2: updatedContainer2,
      container3: updatedContainer3,
      container4: updatedContainer4,
    } = updatedSettings.containers || {};
    const updatedContainers = [
      updatedContainer1,
      updatedContainer2,
      updatedContainer3,
      updatedContainer4,
    ];
    const updatedData = {
      ...caseDetails,
      ...restUpdatedCaseDetails,
      ...(updatedTrialviewFilePrefixMask?.split('').every(c => c === '#')
        ? {
            trialviewFilePrefixLength: updatedTrialviewFilePrefixMask.length,
            trialviewFilePrefix: null,
          }
        : { trialviewFilePrefix: updatedTrialviewFilePrefixMask, trialviewFilePrefixLength: null }),
      trialviewFileSecondTriadDigits: updatedTrialviewFileSecondTriadMask?.length,
      trialviewFileThirdTriadDigits: updatedTrialviewFileThirdTriadMask?.length,
      settings: {
        ...settings,
        ...updatedSettings,
        dataRoom: {
          ...dataRoom,
          ...updatedDataRoom,
          ...(showCustomContainers && { containers: updatedContainers }),
          ...(!showPySemanticSearch && { pySemanticSearch: false }),
        },
        twilio: {
          ...twilio,
          ...updatedTwilio,
          maxAudioBitrate:
            updatedTwilio?.maxAudioBitrate && parseInt(updatedTwilio.maxAudioBitrate),
          maxTracks: updatedTwilio?.maxTracks && parseInt(updatedTwilio.maxTracks),
        },
      },
      features: {
        ...features,
        ...updatedFeatures,
        hearingRoomMode: [
          updatedFeatures?.privateConference !== '' && 'private',
          updatedFeatures?.publicConference !== '' && 'public',
        ].filter(Boolean),
      },
      // aiSettings: {
      //   ...aiSettings,
      //   ...updatedAiSettings,
      // },
      etag,
    };

    setLoadingButton(true);
    dispatch(updateCase(updatedData))
      .then(res => {
        setLoadingButton(false);
        reset({
          // trialviewFilePrefix: res.trialviewFilePrefix || '#'.repeat(res.trialviewFilePrefixLength),
          ...res,
        });
      })
      .catch(err => setLoadingButton(false));
  };

  return (
    <div className={classes.main}>
      {caseDetails && Object.keys(caseDetails).length > 0 && !loading ? (
        <div className={classes.caseSettings}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.heading} color="inherit" variant="h6" component="div">
                <Edit style={{ fontSize: '1.8rem' }} />
                {templateId
                  ? T.translate('case.editCaseTemplate')
                  : T.translate('case.editCaseSettings')}
              </Typography>
            </Grid>
          </Grid>
          <Form
            className={classes.form}
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
              <Form.Group>
                <Grid container className={classes.caseDetails} spacing={2}>
                  <Grid item xs={6}>
                    <TextForm
                      {...register('name', { required: true })}
                      label={T.translate('case.name')}
                      placeholder={T.translate('case.namePlaceholder')}
                      defaultValue={name}
                      errors={errors}
                      autofocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    {isSystemAdmin && (
                      <TextForm label={T.translate('case.id')} defaultValue={id} readOnly />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <TextForm
                      {...register('shortName')}
                      label={T.translate('case.shortName')}
                      placeholder={T.translate('case.shortNamePlaceholder')}
                      defaultValue={shortName}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextForm
                      {...register('claimNumber')}
                      label={T.translate('case.indexPageClaimNo')}
                      placeholder={T.translate('case.indexPageClaimNoPlaceholder')}
                      defaultValue={claimNumber}
                      errors={errors}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectForm
                      label={T.translate('case.privateConference')}
                      name="features.privateConference"
                      defaultValue={privateConference}
                      options={conferenceOptions}
                      control={control}
                      onChange={handleShowTwilioSettings}
                      isClearable
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectForm
                      label={T.translate('case.publicConference')}
                      name="features.publicConference"
                      defaultValue={publicConference}
                      options={conferenceOptions}
                      control={control}
                      onChange={handleShowTwilioSettings}
                      isClearable
                    />
                  </Grid>
                  {isSystemAdmin && (
                    <>
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('settings.roles.setDataRoomRoles', {
                            onChange: () => setShowSelectDataRoomRoles(!showSelectDataRoomRoles),
                          })}
                          defaultValue={showSelectDataRoomRoles}
                          label={T.translate(`generic.changeDefaultDataRoomRoles`)}
                        />
                      </Grid>
                      <GridBreak />
                      {showSelectDataRoomRoles && (
                        <Grid item xs={8}>
                          <MultiSelectForm
                            label={T.translate('generic.selectDataRoomRoles')}
                            defaultValue={customDataRoomRoles}
                            name="settings.roles.customDataRoomRoles"
                            readOnly={!showSelectDataRoomRoles}
                            options={dataRoomRolesOptions}
                            control={control}
                            errors={errors}
                            rules={{ required: true }}
                          />
                        </Grid>
                      )}
                      <GridBreak />
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('settings.roles.setHearingRoomRoles', {
                            onChange: () =>
                              setShowSelectHearingRoomRoles(!showSelectHearingRoomRoles),
                          })}
                          defaultValue={showSelectHearingRoomRoles}
                          label={T.translate(`generic.changeDefaultHearingRoomRoles`)}
                        />
                      </Grid>
                      <GridBreak />
                      {showSelectHearingRoomRoles && (
                        <Grid item xs={8}>
                          <MultiSelectForm
                            label={T.translate('generic.selectHearingRoomRoles')}
                            defaultValue={customHearingRoomRoles}
                            name="settings.roles.customHearingRoomRoles"
                            readOnly={!showSelectHearingRoomRoles}
                            options={hearingRoomRolesOptions}
                            control={control}
                            errors={errors}
                            rules={{ required: true }}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
                <Collapsible
                  className={classes.divider}
                  open
                  title={T.translate('generic.dataRoom')}
                >
                  <Grid container className={classes.caseDetails} spacing={2}>
                    <Grid item xs={8}>
                      <MultiSelectForm
                        label={T.translate('generic.dataRoomColumns')}
                        defaultValue={columns}
                        name="settings.dataRoom.columns"
                        options={dataRoomColumnsOptions}
                        control={control}
                        error={errors}
                        backspaceRemovesValue={false}
                      ></MultiSelectForm>
                    </Grid>
                    <Grid item xs={8}>
                      <MultiSelectForm
                        label={T.translate('generic.dataRoomColumnsSmallView')}
                        defaultValue={columnsSmallView}
                        name="settings.dataRoom.columnsSmallView"
                        options={dataRoomColumnsOptions}
                        control={control}
                        error={errors}
                        backspaceRemovesValue={false}
                      ></MultiSelectForm>
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <TextForm
                        {...register('settings.dataRoom.maxPagePrefetch')}
                        label={T.translate('case.maxPagePrefetch')}
                        type="number"
                        placeholder={T.translate('case.maxPagePrefetchPlaceholder')}
                        defaultValue={maxPagePrefetch}
                        errors={errors}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.truncateLongFolderName')}
                        defaultValue={truncateLongFolderName}
                        label={T.translate(`generic.truncateLongFolderName`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.customContainers', {
                          onChange: () => setShowCustomContainers(!showCustomContainers),
                        })}
                        defaultValue={customContainers}
                        label={T.translate(`generic.ChangeDefaultFolderContainers`)}
                      />
                    </Grid>
                    <GridBreak />
                    {showCustomContainers && (
                      <>
                        <Grid item xs={3}>
                          <TextForm
                            {...register('settings.containers.container1', { required: true })}
                            label={T.translate('generic.container1')}
                            placeholder={T.translate('generic.containerPlaceholder')}
                            defaultValue={container1}
                            readOnly={!showCustomContainers}
                            errors={errors}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextForm
                            {...register('settings.containers.container2', { required: true })}
                            label={T.translate('generic.container2')}
                            placeholder={T.translate('generic.containerPlaceholder')}
                            defaultValue={container2}
                            readOnly={!showCustomContainers}
                            errors={errors}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextForm
                            {...register('settings.containers.container3', { required: true })}
                            label={T.translate('generic.container3')}
                            placeholder={T.translate('generic.containerPlaceholder')}
                            defaultValue={container3}
                            readOnly={!showCustomContainers}
                            errors={errors}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <TextForm
                            {...register('settings.containers.container4', { required: true })}
                            label={T.translate('generic.container4')}
                            placeholder={T.translate('generic.containerPlaceholder')}
                            defaultValue={container4}
                            readOnly={!showCustomContainers}
                            errors={errors}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.globalPagingTrialbooks')}
                        defaultValue={globalPagingTrialbooks}
                        label={T.translate(`generic.globalPagingTrialbooks`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.publicHyperlinks', {
                          onChange: () => setShowGenerateHyperlinks(!showGenerateHyperlinks),
                        })}
                        defaultValue={publicHyperlinks}
                        label={T.translate(`generic.publicHyperlinks`)}
                      />
                    </Grid>
                    <GridBreak />
                    {showGenerateHyperlinks && (
                      <>
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.generateHyperlinks')}
                            defaultValue={generateHyperlinks}
                            label={T.translate(`case.generateHyperlinks`)}
                          />
                        </Grid>
                        <GridBreak />
                      </>
                    )}
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.eSignature')}
                        defaultValue={eSignature}
                        label={T.translate(`case.eSignature`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.crossReference', {
                          onChange: () => setShowCrossReference(!showCrossReference),
                        })}
                        defaultValue={showCrossReference}
                        label={T.translate(`case.crossReference`)}
                      />
                    </Grid>
                    {showCrossReference && (
                      <>
                        <GridBreak />
                        <Grid item xs={4}>
                          <TextForm
                            {...register('settings.dataRoom.crossRefFontSize')}
                            type="number"
                            defaultValue={crossRefFontSize}
                            label={T.translate(`case.crossRefFontSize`)}
                            placeholder={T.translate(`case.crossRefFontSizePlaceholder`)}
                          />
                        </Grid>
                      </>
                    )}
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.enableRedaction')}
                        defaultValue={enableRedaction}
                        label={T.translate(`case.enableRedaction`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.publicCrossRefs')}
                        defaultValue={publicCrossRefs}
                        label={T.translate(`case.publicCrossRefs`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.docFinderFilter')}
                        defaultValue={docFinderFilter}
                        label={T.translate('generic.docFinderFilter')}
                      />
                    </Grid>
                    <GridBreak />
                    {isSystemAdmin && (
                      <>
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.sideBySideDocs')}
                            defaultValue={sideBySideDocs}
                            label={T.translate(`case.sideBySideDocs`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.widerDateColumn')}
                            defaultValue={widerDateColumn}
                            label={T.translate(`generic.widerDateColumn`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.widerColumns')}
                            defaultValue={widerColumns}
                            label={T.translate(`generic.widerColumns`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.showCourtBundles')}
                            defaultValue={showCourtBundles}
                            label={T.translate(`generic.showCourtBundles`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.showGroupBundles')}
                            defaultValue={showGroupBundles}
                            label={T.translate(`generic.showGroupBundles`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.deepSearch', {
                              onChange: () => setShowPySemanticSearch(!showPySemanticSearch),
                            })}
                            defaultValue={deepSearch}
                            label={T.translate(`generic.deepSearch`)}
                          />
                        </Grid>
                        {showPySemanticSearch && (
                          <>
                            <GridBreak />
                            <Grid item xs={4}>
                              <ToggleForm
                                {...register('settings.dataRoom.pySemanticSearch')}
                                defaultValue={pySemanticSearch}
                                label={T.translate(`generic.pySemanticSearch`)}
                              />
                            </Grid>
                          </>
                        )}
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.usePrizmDocCaching')}
                            defaultValue={usePrizmDocCaching}
                            label={T.translate(`generic.usePrizmDocCaching`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.tags')}
                            defaultValue={tags}
                            label={T.translate(`generic.tags`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.enablePrebuiltDocCache')}
                            defaultValue={enablePrebuiltDocCache}
                            label={T.translate(`generic.enablePrebuiltDocCache`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.enableNotifications')}
                            defaultValue={enableNotifications}
                            label={T.translate(`generic.enableNotifications`)}
                            disabled={allowBatchUploadWatch}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.confidentialityRing')}
                            defaultValue={confidentialityRing}
                            label={T.translate(`generic.confidentialityRing`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.preserveAttchedFileSortOrder')}
                            defaultValue={preserveAttchedFileSortOrder}
                            label={T.translate(`generic.preserveAttchedFileSortOrder`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.useOnlyIdColForPresentMode')}
                            defaultValue={useOnlyIdColForPresentMode}
                            label={T.translate('generic.useOnlyIdColForPresentMode')}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.useBackEndForPresentMode')}
                            defaultValue={useBackEndForPresentMode}
                            label={T.translate('generic.useBackEndForPresentMode')}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.disableSearchFromAllDocs')}
                            defaultValue={disableSearchFromAllDocs}
                            label={T.translate('generic.disableSearchFromAllDocs')}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.changeTeamForCaseAdmin')}
                            defaultValue={changeTeamForCaseAdmin}
                            label={T.translate('generic.changeTeamForCaseAdmin')}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.bbcDemo')}
                            defaultValue={bbcDemo}
                            label={T.translate(`generic.bbcDemo`)}
                          />
                        </Grid>
                        <GridBreak />
                        <Grid item xs={4}>
                          <ToggleForm
                            {...register('settings.dataRoom.allowBatchUpload')}
                            defaultValue={allowBatchUpload}
                            label={T.translate(`generic.allowBatchUpload`)}
                          />
                        </Grid>
                      </>
                    )}
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.automaticallySetDocDate')}
                        defaultValue={automaticallySetDocDate}
                        label={T.translate(`generic.automaticallySetDocDate`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.timeline')}
                        defaultValue={timeline}
                        label={T.translate(`generic.timeline`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.autosetAuthorOrRecipientFromEmails')}
                        defaultValue={autosetAuthorOrRecipientFromEmails}
                        label={T.translate(`generic.autosetAuthorOrRecipientFromEmails`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.autosetDocDateFromEmails')}
                        defaultValue={autosetDocDateFromEmails}
                        label={T.translate(`generic.autosetDocDateFromEmails`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('settings.dataRoom.showAllDocuments')}
                        defaultValue={showAllDocuments}
                        label={T.translate(`generic.showAllDocuments`)}
                      />
                    </Grid>
                  </Grid>
                </Collapsible>
                <Collapsible
                  className={classes.divider}
                  open
                  title={T.translate('generic.mergeFilesOptions')}
                >
                  <Grid container className={classes.caseDetails} spacing={2}>
                    <Grid item xs={6}>
                      <TextForm
                        as="textarea"
                        label={T.translate('case.indexPageClaimNo')}
                        defaultValue={claimNumber}
                        readOnly
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('settings.dataRoom.mergeFilesOptions.indexPageJurisdiction')}
                        as="textarea"
                        label={T.translate('case.indexPageJurisdiction')}
                        placeholder={T.translate('case.indexPageJurisdictionPlaceholder')}
                        defaultValue={indexPageJurisdiction}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('settings.dataRoom.mergeFilesOptions.indexPageClaimant')}
                        as="textarea"
                        label={T.translate('case.indexPageClaimant')}
                        placeholder={T.translate('case.indexPageClaimantPlaceholder')}
                        defaultValue={indexPageClaimant}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('settings.dataRoom.mergeFilesOptions.indexPageDefendant')}
                        as="textarea"
                        label={T.translate('case.indexPageDefendant')}
                        placeholder={T.translate('case.indexPageDefendantPlaceholder')}
                        defaultValue={indexPageDefendant}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('settings.dataRoom.mergeFilesOptions.labelClaimant')}
                        label={T.translate('case.labelClaimant')}
                        placeholder={T.translate('case.labelPlaceholder')}
                        defaultValue={labelClaimant}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('settings.dataRoom.mergeFilesOptions.labelDefendant')}
                        label={T.translate('case.labelDefendant')}
                        placeholder={T.translate('case.labelPlaceholder')}
                        defaultValue={labelDefendant}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('settings.dataRoom.mergeFilesOptions.labelBetween')}
                        label={T.translate('case.labelBetween')}
                        placeholder={T.translate('case.labelPlaceholder')}
                        defaultValue={labelBetween}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('settings.dataRoom.mergeFilesOptions.labelVersus')}
                        label={T.translate('case.labelVersus')}
                        placeholder={T.translate('case.labelPlaceholder')}
                        defaultValue={labelVersus}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <MultiSelectForm
                        label={T.translate('case.heading4')}
                        name="settings.dataRoom.mergeFilesOptions.indexPageColumnsToDisplay"
                        defaultValue={defaultIndexPageColumn}
                        options={indexPageColumnsOptions}
                        onChange={onColumnChange}
                        control={control}
                        error={errors}
                      />
                    </Grid>
                    {showIndexPageDateFormat && (
                      <Grid item xs={6}>
                        <SelectForm
                          label={T.translate('case.IndexPageTableDateFormat')}
                          name="settings.dataRoom.mergeFilesOptions.indexPageTableDateFormat"
                          defaultValue={defaultIndexPageTableDateFormat}
                          options={indexPageTableColumnsOptions}
                          control={control}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Collapsible>
                {/* <Collapsible
                  className={classes.divider}
                  open
                  title={T.translate('generic.aiSettings')}
                >
                  <Grid container className={classes.caseDetails} spacing={2}>
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('aiSettings.matches.multiQuery')}
                        defaultValue={multiQuery}
                        label={T.translate(`case.multiQuery`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={4}>
                      <ToggleForm
                        {...register('aiSettings.matches.maxMarginalRelevance')}
                        defaultValue={maxMarginalRelevance}
                        label={T.translate(`case.maxMarginalRelevance`)}
                      />
                    </Grid>
                    <GridBreak />
                    <Grid item xs={6}>
                      <TextForm
                        type="number"
                        {...register('aiSettings.matches.numMatches', {
                          required: true,
                        })}
                        label={T.translate('case.maximumNumberofMatches')}
                        placeholder={T.translate('case.maximumNumberofMatchesPlaceholder')}
                        defaultValue={numMatches}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectForm
                        label={T.translate('case.indices')}
                        name="aiSettings.indices"
                        defaultValue={renderDimensionLow}
                        options={RenderDimensionsOptions}
                        control={control}
                      />
                    </Grid>
                  </Grid>
                </Collapsible> */}
                {showTwilioSettings && (
                  <Collapsible
                    className={classes.divider}
                    open
                    title={T.translate('generic.twilio')}
                  >
                    <Grid container className={classes.caseDetails} spacing={2}>
                      <Grid item xs={12}>
                        <TextForm
                          {...register('settings.twilio.defaultHearingRoomMessage', {
                            required: true,
                          })}
                          label={T.translate('case.defaultHearingRoomMessage')}
                          placeholder={T.translate('case.defaultHearingRoomMessagePlaceholder')}
                          defaultValue={defaultHearingRoomMessage}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectForm
                          label={T.translate('case.renderDimensionLow')}
                          name="settings.twilio.renderDimensionLow"
                          defaultValue={renderDimensionLow} //low
                          options={RenderDimensionsOptions}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectForm
                          label={T.translate('case.renderDimensionStandard')}
                          name="settings.twilio.renderDimensionStandard"
                          defaultValue={renderDimensionStandard} //720p
                          options={RenderDimensionsOptions}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectForm
                          label={T.translate('case.renderDimensionHigh')}
                          name="settings.twilio.renderDimensionHigh"
                          defaultValue={renderDimensionHigh} //wide1080p
                          options={RenderDimensionsOptions}
                          control={control}
                        />
                      </Grid>
                      <GridBreak />
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('settings.twilio.documentsOnlyWhenInRoom')}
                          defaultValue={documentsOnlyWhenInRoom}
                          label={T.translate('case.documentsOnlyWhenInRoom')}
                        />
                      </Grid>
                      <GridBreak />
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('settings.twilio.leftSideDocument')}
                          defaultValue={leftSideDocument}
                          label={T.translate('case.leftSideDocument')}
                        />
                      </Grid>
                      <GridBreak />
                      {isSystemAdmin && (
                        <>
                          <Grid item xs={4}>
                            <ToggleForm
                              {...register('settings.twilio.fiftyPlusHearingRoom')}
                              defaultValue={fiftyPlusHearingRoom} // true
                              label={T.translate(`case.fiftyPlusHearingRoom`)}
                            />
                          </Grid>
                          <GridBreak />
                          <Grid item xs={4}>
                            <ToggleForm
                              {...register('settings.twilio.allowBotUsers')}
                              defaultValue={allowBotUsers} // false
                              label={T.translate(`case.allowBotUsers`)}
                            />
                          </Grid>
                          <GridBreak />
                          <Grid item xs={4}>
                            <ToggleForm
                              {...register('settings.twilio.enableRoomToBeRecorded')}
                              defaultValue={enableRoomToBeRecorded}
                              label={T.translate(`generic.enableRoomToBeRecorded`)}
                            />
                          </Grid>
                          <GridBreak />
                        </>
                      )}
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('settings.twilio.screenShareEnabled')}
                          defaultValue={screenShareEnabled}
                          label={T.translate('case.screenShareEnabled')}
                        />
                      </Grid>
                      <GridBreak />
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('settings.twilio.evidenceViewEnabled')}
                          defaultValue={evidenceViewEnabled}
                          label={T.translate('case.evidenceViewEnabled')}
                        />
                      </Grid>
                      <GridBreak />
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('settings.twilio.markBasedScrolling')}
                          defaultValue={markBasedScrolling}
                          label={T.translate('case.markBasedScrolling')}
                        />
                      </Grid>
                      <GridBreak />
                      {isSystemAdmin && (
                        <>
                          <Grid item xs={4}>
                            <ToggleForm
                              {...register('settings.twilio.useWhepStreaming')}
                              defaultValue={useWhepStreaming}
                              label={T.translate('case.useWhepStreaming')}
                            />
                          </Grid>
                          <GridBreak />
                          <Grid item xs={6}>
                            <TextForm
                              {...register('settings.twilio.streamUrl', {
                                required: false,
                                validate: {
                                  isValidEntry: val =>
                                    !val || new RegExp(validation.link).test(val),
                                },
                              })}
                              label={T.translate('case.streamUrl')}
                              placeholder={T.translate('case.streamUrlPlaceholder')}
                              defaultValue={streamUrl}
                              errors={errors}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextForm
                              {...register('settings.twilio.streamAltUrl', {
                                required: false,
                                validate: {
                                  isValidEntry: val =>
                                    !val || new RegExp(validation.link).test(val),
                                },
                              })}
                              label={T.translate('case.streamAltUrl')}
                              placeholder={T.translate('case.streamAltUrlPlaceholder')}
                              defaultValue={streamAltUrl}
                              errors={errors}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={6}>
                        <TextForm
                          {...register('settings.twilio.transcriptLink', {
                            required: false,
                            validate: {
                              isValidEntry: val => !val || new RegExp(validation.link).test(val),
                            },
                          })}
                          label={T.translate('case.transcriptLink')}
                          placeholder={T.translate('case.transcriptLinkPlaceholder')}
                          defaultValue={transcriptLink}
                          errors={errors}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextForm
                          {...register('settings.twilio.transcriptLinkPrivate', {
                            required: false,
                            validate: {
                              isValidEntry: val => !val || new RegExp(validation.link).test(val),
                            },
                          })}
                          label={T.translate('case.transcriptLinkPrivate')}
                          placeholder={T.translate('case.transcriptLinkPrivatePlaceholder')}
                          defaultValue={transcriptLinkPrivate}
                          errors={errors}
                        />
                      </Grid>
                      <GridBreak />
                      <Grid item xs={6}>
                        <SelectForm
                          label={T.translate('case.thumbnailSize')}
                          name="settings.twilio.thumbnailSize"
                          defaultValue={thumbnailSize} //medium
                          options={ThumbnailSizeOptions}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectForm
                          label={T.translate('case.additionalThumbnailSize')}
                          name="settings.twilio.additionalThumbnailSize"
                          defaultValue={additionalThumbnailSize} //medium
                          options={ThumbnailSizeOptions}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Trans i18nKey={'case.moreTwilioInfoMessage'}>
                          To find out more about Twilio setting click
                          <Link
                            component="button"
                            variant="body2"
                            style={{ padding: 0, lineHeight: 1 }}
                            onClick={() => {
                              openNewBackgroundTab(
                                'https://www.twilio.com/docs/video/tutorials/using-bandwidth-profile-api',
                              );
                            }}
                          >
                            {{ link }}
                          </Link>
                          .
                        </Trans>
                      </Grid>
                    </Grid>
                  </Collapsible>
                )}
                {isSystemAdmin && (
                  <Collapsible
                    className={classes.divider}
                    open
                    title={T.translate('generic.advanceOptions')}
                  >
                    <Grid container className={classes.caseDetails} spacing={2}>
                      <Grid item xs={12}>
                        <Form.Label>{T.translate('case.trialviewIdFormat')}</Form.Label>
                        <div style={{ display: 'flex' }}>
                          <Form.Control
                            readOnly={true}
                            defaultValue={'TV'}
                            style={{ width: '45px' }}
                          />
                          <div style={{ margin: '10px' }}>-</div>
                          <Form.Control
                            {...register('trialviewFilePrefixMask', {
                              required: true,
                              maxLength: 5,
                              onChange: e => {
                                if (e.target.value) {
                                  e.target.value = e.target.value.replace(/[^A-Za-z0-9#]/g, '');
                                }
                              },
                            })}
                            placeholder={T.translate('case.trialviewFilePrefixPlaceholder')}
                            isInvalid={!!errors.trialviewFilePrefix}
                            defaultValue={trialviewFilePrefixMask}
                          />
                          <div style={{ margin: '10px' }}>-</div>
                          <Form.Control
                            {...register('trialviewFileSecondTriadMask', {
                              required: true,
                              maxLength: 5,
                              onChange: e => {
                                if (e.target.value) {
                                  e.target.value = e.target.value.replace(/[^#]/g, '');
                                }
                              },
                            })}
                            placeholder={T.translate(
                              'case.trialviewFileSecondTriadDigitsPlaceholder',
                            )}
                            isInvalid={!!errors.trialviewFileSecondTriadMask}
                            defaultValue={trialviewFileSecondTriadMask}
                          />
                          <div style={{ margin: '10px' }}>-</div>
                          <Form.Control
                            {...register('trialviewFileThirdTriadMask', {
                              maxLength: 5,
                              onChange: e => {
                                if (e.target.value) {
                                  e.target.value = e.target.value.replace(/[^#]/g, '');
                                }
                              },
                            })}
                            placeholder={T.translate(
                              'case.trialviewFileThirdTriadDigitsPlaceholder',
                            )}
                            isInvalid={!!errors.trialviewFileThirdTriadMask}
                            defaultValue={trialviewFileThirdTriadMask}
                          />
                        </div>
                        {(errors.trialviewFileThirdTriadMask ||
                          errors.trialviewFileSecondTriadMask ||
                          errors.trialviewFilePrefix) && (
                          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                            {(
                              errors.trialviewFileThirdTriadMask ||
                              errors.trialviewFileSecondTriadMask ||
                              errors.trialviewFilePrefix
                            ).type === 'required'
                              ? T.translate('generic.fieldIsRequired')
                              : T.translate('case.trialviewIdFormatMessage')}
                          </Form.Control.Feedback>
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <ToggleForm
                          {...register('active')}
                          name=""
                          defaultValue={active}
                          label={T.translate(`generic.active`)}
                        />
                      </Grid>
                    </Grid>
                  </Collapsible>
                )}
              </Form.Group>
            </PerfectScrollbar>
          </Form>
          <Grid container className={classes.caseDetails} spacing={2}>
            <Button
              variant="contained"
              color="primary"
              className={classes.saveButton}
              onClick={handleSubmit(data => handleUpdateCaseSettings(data))}
            >
              {loadingButton ? (
                <Spinner style={{ height: '1rem', width: '1rem' }} />
              ) : (
                T.translate('generic.save')
              )}
            </Button>
          </Grid>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default CaseSettingsPage;
