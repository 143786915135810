import {
  CASE_REMOVE_TEAM_MEMBER_BEGIN,
  CASE_REMOVE_TEAM_MEMBER_SUCCESS,
  CASE_REMOVE_TEAM_MEMBER_FAILURE,
  CASE_REMOVE_TEAM_MEMBER_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { deleteItemImmutable, replaceItemImmutable } from 'utils/arrays';

export const removeTeamMember = createPromiseAction(CASE_REMOVE_TEAM_MEMBER_BEGIN);

export function dismissRemoveTeamMemberFeedback() {
  return {
    type: CASE_REMOVE_TEAM_MEMBER_DISMISS_FEEDBACK,
  };
}

export function* doRemoveTeamMember(action) {
  const { caseId, teamId, user } = action.payload;
  let res = yield call(api.del, `/cases/${caseId}/teams/${teamId}/users/${user.id}`);

  if (res && res.error) {
    yield put({
      type: CASE_REMOVE_TEAM_MEMBER_FAILURE,
      feedback: {
        message: 'feedback.removeTeamMemberFailure',
        params: { userEmail: user.mail },
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_REMOVE_TEAM_MEMBER_SUCCESS,
    data: { team: res, userId: user.id },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchRemoveTeamMember() {
  yield takeEvery(removeTeamMember, withCurrentCaseId(doRemoveTeamMember));
}

export function reducer(state, action) {
  const getIndex = userId => state.team && state.team.users.findIndex(({ id }) => id === userId);
  const getTeamIndex = teamId => state.teams && state.teams.findIndex(({ id }) => id === teamId);

  switch (action.type) {
    case CASE_REMOVE_TEAM_MEMBER_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        removeTeamMemberPending: true,
        removeTeamMemberFeedback: null,
      };

    case CASE_REMOVE_TEAM_MEMBER_SUCCESS:
      // The request is success
      return {
        ...state,
        removeTeamMemberPending: false,
        removeTeamMemberFeedback: action.feedback,
        ...(state.team &&
          state.team.id === action.data.team.id && {
            team: {
              ...action.data.team,
              users: deleteItemImmutable(state.team.users, getIndex(action.data.userId)),
            },
          }),
        ...(state.teams && {
          teams: replaceItemImmutable(
            state.teams,
            action.data.team,
            getTeamIndex(action.data.team.id),
          ),
        }),
      };

    case CASE_REMOVE_TEAM_MEMBER_FAILURE:
      // The request is failed
      return {
        ...state,
        removeTeamMemberPending: false,
        removeTeamMemberFeedback: action.feedback,
      };

    case CASE_REMOVE_TEAM_MEMBER_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        removeTeamMemberFeedback: null,
      };

    default:
      return state;
  }
}
