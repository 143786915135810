import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CONFERENCE_GET_TURN_CREDENTIALS_BEGIN,
  CONFERENCE_GET_TURN_CREDENTIALS_SUCCESS,
  CONFERENCE_GET_TURN_CREDENTIALS_FAILURE,
  CONFERENCE_GET_TURN_CREDENTIALS_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const getTurnCredentials = createPromiseAction(CONFERENCE_GET_TURN_CREDENTIALS_BEGIN);

export function dismissGetTurnCredentialsFeedback() {
  return {
    type: CONFERENCE_GET_TURN_CREDENTIALS_DISMISS_FEEDBACK,
  };
}

export function* doGetTurnCredentials(action) {
  const {
    payload: { caseId },
  } = action;

  let res = yield call(api.post, `/t2/cases/${caseId}/rooms/public/turn-credentials`);

  if (res && res.error) {
    yield put({
      type: CONFERENCE_GET_TURN_CREDENTIALS_FAILURE,
      feedback: {
        message: 'feedback.getTurnCredentialsFailure',
        error: res.error,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CONFERENCE_GET_TURN_CREDENTIALS_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchGetTurnCredentials() {
  yield takeLatest(getTurnCredentials, withCurrentCaseId(doGetTurnCredentials));
}

export function reducer(state, action) {
  switch (action.type) {
    case CONFERENCE_GET_TURN_CREDENTIALS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        getTurnCredentialsPending: true,
        getTurnCredentialsFeedback: null,
      };

    case CONFERENCE_GET_TURN_CREDENTIALS_SUCCESS:
      // The request is success
      return {
        ...state,
        getTurnCredentialsPending: false,
        getTurnCredentialsFeedback: action.feedback,
      };

    case CONFERENCE_GET_TURN_CREDENTIALS_FAILURE:
      // The request is failed
      return {
        ...state,
        getTurnCredentialsPending: false,
        getTurnCredentialsFeedback: action.feedback,
      };

    case CONFERENCE_GET_TURN_CREDENTIALS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        getTurnCredentialsFeedback: null,
      };

    default:
      return state;
  }
}
