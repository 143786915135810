import React, { useState, useEffect } from 'react';
import * as actions from 'features/case/redux/actions';
import T from 'i18n';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import SelectableContext from 'react-bootstrap/SelectableContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle,
  faTrashAlt,
  faEdit,
  faUsers,
  faCog,
  faUserShield,
  faPhoneAlt,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import history from 'common/history';
import { Sidebar } from 'features/common';
import { CustomToggle } from './SidebarCase';
import { caseAdminModalActions } from './enums';
import {
  selectAssignDialInNumber,
  selectCurrentCaseId,
  selectCurrentCaseTeams,
  selectGroups,
  selectIsAdmin,
  selectIsCaseAdmin,
  selectShowGroupBundles,
} from 'common/selectors';
import Modifier from 'features/common/Modifier';
import CaseAdminModal from './CaseAdminModal';
import { useFetchCaseDetails } from './redux/fetchCaseDetails';

const SidebarItemCaseAdmin = ({ team, to, buttonActions, type }) => {
  const teamPath =
    team.id === 'all-users' ? to : `${to}/${type === 'teams' ? 'teams' : 'groups'}/${team.id}`;
  return (
    <div className="case-sidebar-item">
      <NavLink
        activeClassName={'active'}
        to={teamPath}
        onClick={e => teamPath === history.location.pathname && e.preventDefault()}
        className="nav-link"
      >
        {team.name}
        {buttonActions && <Modifier {...{ buttonActions: buttonActions({ team }) }} />}
      </NavLink>
    </div>
  );
};

const SidebarCaseAdmin = ({ NavBarLinks, isSidebarOpen }) => {
  const dispatch = useDispatch();
  const teams = useSelector(selectCurrentCaseTeams);
  const showGroupBundles = useSelector(selectShowGroupBundles);
  const groups = useSelector(selectGroups);
  const { caseDetails } = useFetchCaseDetails();
  const [showModal, setShowModal] = useState(false);
  const [modalAction, setModalAction] = useState('');
  const [selectedTeam, setSelectedTeam] = useState(null);
  const currentCaseId = useSelector(selectCurrentCaseId);
  const isCaseAdmin = useSelector(selectIsCaseAdmin);
  const isSystemAdmin = useSelector(selectIsAdmin);
  const isAssignDialInNumber = useSelector(selectAssignDialInNumber);

  const outside = (
    <ul className="nav all-documents">
      <SidebarItemCaseAdmin
        team={{ name: T.translate('case.allUsers'), id: 'all-users' }}
        to={`/case/${currentCaseId}/settings/case-admin/all-users`}
      />
    </ul>
  );

  const handleShow = (action, team) => {
    setModalAction(action);
    setSelectedTeam(team);
    setShowModal(true);
  };

  const handleDefaultBehav = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClose = () => {
    setShowModal(false);
    setModalAction('');
  };

  const handleSave = (action, data) => {
    switch (action) {
      case caseAdminModalActions.addNewTeam:
        dispatch(actions.createTeam({ team: data }));
        break;
      case caseAdminModalActions.updateTeamName:
        dispatch(actions.updateTeam({ team: data }));
        break;
      case caseAdminModalActions.removeTeam:
        dispatch(actions.removeTeam({ teamId: data })).then(() => {
          history.push(`/case/${currentCaseId}/settings/case-admin`);
        });
        break;
      case caseAdminModalActions.addNewGroup:
        dispatch(actions.createGroup({ group: data }));
        break;
      case caseAdminModalActions.removeGroup:
        dispatch(actions.removeGroup({ groupId: data })).then(() => {
          history.push(`/case/${currentCaseId}/settings/case-admin`);
        });
        break;
      default:
    }
  };

  const buttonActions = ({ team }) => {
    return [
      {
        title: T.translate('case.updateTeamName'),
        handler: () => handleShow(caseAdminModalActions.updateTeamName, team),
        icon: faEdit,
      },
      team.users.length === 0 && {
        title: T.translate('case.removeTeam'),
        handler: () => handleShow(caseAdminModalActions.removeTeam, team),
        icon: faTrashAlt,
      },
    ].filter(Boolean);
  };

  const buttonActionsGroups = ({ team }) => {
    return [
      {
        title: T.translate('case.removeGroup'),
        handler: () => handleShow(caseAdminModalActions.removeGroup, team),
        icon: faTrashAlt,
      },
    ].filter(Boolean);
  };

  useEffect(() => {
    if (caseDetails) {
      dispatch(actions.fetchTeams()).catch(err => console.error(err));
      showGroupBundles && dispatch(actions.fetchGroups()).catch(err => console.error(err));
    }
  }, [caseDetails, dispatch, showGroupBundles]);
  return (
    <Sidebar
      outside={outside}
      NavBarLinks={NavBarLinks}
      isSidebarOpen={isSidebarOpen}
      outsideFolderCount={1}
    >
      <Accordion defaultActiveKey="case-settings" className="titleButton">
        <NavLink
          activeClassName={'activeTitleButton'}
          className="title nav-title-button"
          to={`/case/${currentCaseId}/settings/case-admin/case-settings`}
        >
          <span style={{ background: 'transparent' }}>
            {
              <FontAwesomeIcon
                style={{ marginRight: '0.8rem' }}
                icon={faCog}
                alt={faCog.iconName}
              />
            }
            {T.translate('case.caseSettingS')}
          </span>
        </NavLink>
      </Accordion>
      <Accordion defaultActiveKey="case-roles" className="titleButton">
        <NavLink
          activeClassName={'activeTitleButton'}
          className="title nav-title-button"
          to={`/case/${currentCaseId}/settings/case-admin/case-roles`}
          style={{ margin: 0 }}
        >
          <span style={{ background: 'transparent' }}>
            {
              <FontAwesomeIcon
                style={{ marginRight: '0.5rem' }}
                icon={faUserShield}
                alt={faUserShield.iconName}
              />
            }
            {T.translate('case.caseROLES')}
          </span>
        </NavLink>
      </Accordion>
      {(isSystemAdmin || isAssignDialInNumber) && (
        <Accordion defaultActiveKey="dial-in-numbers" className="titleButton">
          <NavLink
            activeClassName={'activeTitleButton'}
            className="title nav-title-button"
            to={`/case/${currentCaseId}/settings/case-admin/dial-in-numbers`}
            style={{ margin: 0 }}
          >
            <span style={{ background: 'transparent' }}>
              {
                <FontAwesomeIcon
                  style={{ marginRight: '0.5rem' }}
                  icon={faPhoneAlt}
                  alt={faPhoneAlt.iconName}
                />
              }
              {T.translate('case.dialInNumbers')}
            </span>
          </NavLink>
        </Accordion>
      )}
      <Accordion defaultActiveKey="hearing-room-logs" className="titleButton">
        <NavLink
          activeClassName={'activeTitleButton'}
          className="title nav-title-button"
          to={`/case/${currentCaseId}/settings/case-admin/hearing-room-logs`}
          style={{ margin: 0 }}
        >
          <span style={{ background: 'transparent' }}>
            {
              <FontAwesomeIcon
                style={{ marginRight: '0.5rem' }}
                icon={faInfoCircle}
                alt={faInfoCircle.iconName}
              />
            }
            {T.translate('case.hearingRoomLogs')}
          </span>
        </NavLink>
      </Accordion>
      <div className="divider" />
      {isCaseAdmin && teams && (
        <>
          <Accordion defaultActiveKey="teams">
            <CustomToggle className="title" eventKey="teams">
              <span>
                {
                  <FontAwesomeIcon
                    style={{ marginRight: '0.5rem' }}
                    icon={faUsers}
                    alt={faUsers.iconName}
                  />
                }
                {T.translate('case.teams')}
              </span>
              {teams.length > 0 && (
                <FontAwesomeIcon
                  onClick={e => {
                    handleShow(caseAdminModalActions.addNewTeam);
                    handleDefaultBehav(e);
                  }}
                  icon={faPlusCircle}
                  alt={faPlusCircle.iconName}
                />
              )}
            </CustomToggle>
            <Accordion.Collapse eventKey="teams">
              <SelectableContext.Provider value={false}>
                <ul className="nav">
                  {teams.length > 0 ? (
                    teams.map((prop, key) => (
                      <SidebarItemCaseAdmin
                        team={prop}
                        key={key}
                        to={`/case/${currentCaseId}/settings/case-admin`}
                        type="teams"
                        buttonActions={buttonActions}
                      />
                    ))
                  ) : (
                    <div
                      className="add"
                      onClick={e => {
                        handleShow(caseAdminModalActions.addNewTeam);
                        handleDefaultBehav(e);
                      }}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} alt={faPlusCircle.iconName} />
                      <span>{T.translate('case.addNewTeam')}</span>
                    </div>
                  )}
                </ul>
              </SelectableContext.Provider>
            </Accordion.Collapse>
          </Accordion>
          {showGroupBundles && (
            <>
              <div className="divider" />
              <Accordion defaultActiveKey="groups">
                <CustomToggle className="title" eventKey="groups">
                  <span>{T.translate('case.groups')}</span>
                  {groups.length > 0 && (
                    <FontAwesomeIcon
                      onClick={e => {
                        handleShow(caseAdminModalActions.addNewGroup);
                        handleDefaultBehav(e);
                      }}
                      icon={faPlusCircle}
                      alt={faPlusCircle.iconName}
                    />
                  )}
                </CustomToggle>
                <Accordion.Collapse eventKey="groups">
                  <SelectableContext.Provider value={false}>
                    <ul className="nav">
                      {groups.length > 0 ? (
                        groups.map((prop, key) => (
                          <SidebarItemCaseAdmin
                            team={prop}
                            key={key}
                            to={`/case/${currentCaseId}/settings/case-admin`}
                            type="groups"
                            buttonActions={buttonActionsGroups}
                          />
                        ))
                      ) : (
                        <div
                          className="add"
                          onClick={e => {
                            handleShow(caseAdminModalActions.addNewGroup);
                            handleDefaultBehav(e);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} alt={faPlusCircle.iconName} />
                          <span>{T.translate('case.addNewGroup')}</span>
                        </div>
                      )}
                    </ul>
                  </SelectableContext.Provider>
                </Accordion.Collapse>
              </Accordion>
            </>
          )}
          <CaseAdminModal
            show={showModal}
            action={modalAction}
            handleClose={handleClose}
            handleSave={handleSave}
            team={selectedTeam}
          />
        </>
      )}
    </Sidebar>
  );
};

export default SidebarCaseAdmin;
