import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import T from 'i18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'features/common/MaterialBasedComponents';
import { TextForm, SelectForm } from 'features/common';
import Spinner from 'features/common/Spinner';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { fetchTemplates } from 'features/case/redux/fetchTemplates';
import { useDispatch, useSelector } from 'react-redux';
import { regularToKebab } from 'utils/strings';
import { selectIsOrganizationAdmin } from 'common/selectors';

export default function CaseCreateModal({ show, handleClose, handleSave }) {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const name = '',
    id = '',
    countryCode = 'IRL',
    shortName = '',
    claimNumber = '',
    indexPageJurisdiction = '',
    indexPageClaimant = '',
    indexPageDefendant = '',
    labelClaimant = 'Claimant',
    labelDefendant = 'Defendant',
    template = null;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingCreateBtn, setLoadingCreateBtn] = useState(false);
  const [allTemplateOptions, setAllTemplateOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [idValue, setIdValue] = useState(id);
  const isOrganizationAdmin = useSelector(selectIsOrganizationAdmin);
  const [isClosable, setIsClosable] = useState(true);

  const handleTemplateOptionChange = useCallback(
    value => {
      if (value) {
        const filteredOptions = allTemplateOptions.filter(
          item => item.countryCode === (value || countryCode),
        );
        setTemplateOptions(filteredOptions);
      } else {
        setTemplateOptions(allTemplateOptions);
      }
    },
    [allTemplateOptions, countryCode],
  );

  useEffect(() => {
    if (allTemplateOptions && allTemplateOptions.length > 0) {
      countryCode
        ? handleTemplateOptionChange(countryCode)
        : setTemplateOptions(allTemplateOptions);
    }
  }, [allTemplateOptions, countryCode, handleTemplateOptionChange]);

  useEffect(() => {
    if (show) {
      setLoading(true);
      dispatch(fetchTemplates())
        .then(res => {
          setAllTemplateOptions(res);
        })
        .finally(() => setLoading(false));
    } else {
      setAllTemplateOptions([]);
      setTemplateOptions([]);
      setIdValue(id);
      setLoading(false);
      reset({});
    }
  }, [show, dispatch, reset]);

  return (
    <Modal
      show={show}
      title={T.translate('case.createCase')}
      handleClose={() => {
        if (isClosable) {
          setLoadingCreateBtn(false);
          handleClose();
        }
      }}
      handleSave={handleSubmit(newData => {
        setLoadingCreateBtn(true);
        setIsClosable(false);
        handleSave(newData)
          .then(() => {
            handleClose();
            setLoadingCreateBtn(false);
            setIsClosable(true);
          })
          .catch(() => {
            setLoadingCreateBtn(false);
            setIsClosable(true);
          });
      })}
      loadingBtn={loadingCreateBtn}
      saveTitle={T.translate('generic.create')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          className="case-case-modal"
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '55rem' }}>
            <Form.Group>
              <Row>
                <Col sm={8}>
                  <TextForm
                    {...register('name', {
                      required: true,
                      onChange: ({ target: { value } }) => setIdValue(regularToKebab(value)),
                    })}
                    label={T.translate('case.name')}
                    placeholder={T.translate('case.namePlaceholder')}
                    defaultValue={name}
                    errors={errors}
                    autofocus
                  />
                </Col>
                <Col sm={4}>
                  <TextForm
                    label={T.translate('case.id')}
                    name="id"
                    placeholder={T.translate('case.idPlaceholder')}
                    value={idValue}
                    readOnly
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextForm
                    {...register('shortName')}
                    label={T.translate('case.shortName')}
                    placeholder={T.translate('case.shortNamePlaceholder')}
                    defaultValue={shortName}
                    errors={errors}
                  />
                </Col>
                <Col>
                  <SelectForm
                    label={T.translate('case.template')}
                    name="templateId"
                    defaultValue={template}
                    valueKey="id"
                    labelKey="name"
                    options={templateOptions}
                    control={control}
                    isClearable
                    menuPosition="fixed"
                    rules={{ required: isOrganizationAdmin }}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextForm
                    {...register('settings.dataRoom.mergeFilesOptions.indexPageClaimant')}
                    as="textarea"
                    label={T.translate('case.indexPageClaimant')}
                    placeholder={T.translate('case.indexPageClaimantPlaceholder')}
                    defaultValue={indexPageClaimant}
                    onKeyDown={e =>
                      (e.code === 'Enter' || e.code === 'NumpadEnter') && e.stopPropagation()
                    }
                    errors={errors}
                  />
                </Col>
                <Col>
                  <TextForm
                    {...register('settings.dataRoom.mergeFilesOptions.indexPageDefendant')}
                    as="textarea"
                    label={T.translate('case.indexPageDefendant')}
                    placeholder={T.translate('case.indexPageDefendantPlaceholder')}
                    defaultValue={indexPageDefendant}
                    onKeyDown={e =>
                      (e.code === 'Enter' || e.code === 'NumpadEnter') && e.stopPropagation()
                    }
                    errors={errors}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextForm
                    {...register('settings.dataRoom.mergeFilesOptions.labelClaimant')}
                    label={T.translate('case.labelClaimant')}
                    placeholder={T.translate('case.labelPlaceholder')}
                    defaultValue={labelClaimant}
                    errors={errors}
                  />
                </Col>
                <Col>
                  <TextForm
                    {...register('settings.dataRoom.mergeFilesOptions.labelDefendant')}
                    label={T.translate('case.labelDefendant')}
                    placeholder={T.translate('case.labelPlaceholder')}
                    defaultValue={labelDefendant}
                    errors={errors}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <TextForm
                    {...register('claimNumber')}
                    as="textarea"
                    label={T.translate('case.indexPageClaimNo')}
                    placeholder={T.translate('case.indexPageClaimNoPlaceholder')}
                    defaultValue={claimNumber}
                    onKeyDown={e =>
                      (e.code === 'Enter' || e.code === 'NumpadEnter') && e.stopPropagation()
                    }
                    errors={errors}
                  />
                </Col>
                <Col>
                  <TextForm
                    {...register('settings.dataRoom.mergeFilesOptions.indexPageJurisdiction')}
                    as="textarea"
                    label={T.translate('case.indexPageJurisdiction')}
                    placeholder={T.translate('case.indexPageJurisdictionPlaceholder')}
                    defaultValue={indexPageJurisdiction}
                    onKeyDown={e =>
                      (e.code === 'Enter' || e.code === 'NumpadEnter') && e.stopPropagation()
                    }
                    errors={errors}
                  />
                </Col>
              </Row>
            </Form.Group>
          </PerfectScrollbar>
        </Form>
      )}
    </Modal>
  );
}

CaseCreateModal.propTypes = {};
CaseCreateModal.defaultProps = {};
