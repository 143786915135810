import React from 'react';
import { Box, Menu, Theme, useMediaQuery } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { useUpdateUserSettings } from '../redux/updateUserSettings';
import { createListofRecentItems } from 'utils/arrays';
import { selectCurrentCaseId } from 'common/selectors';
import { useSelector } from 'react-redux';
import SearchMenuHome from './SearchMenuHome';

const useStyles = makeStyles(() =>
  createStyles({
    menuPaperContainer: {
      '& 	.MuiMenu-paper': {
        border: '1px solid rgba(0,0,0,.15)',
        borderRadius: '0.5rem',
        padding: 0,
        boxShadow: 'none',
        overflow: 'visible',
      },
    },
  }),
);

interface SearchMenuWrapperProps {
  handleCloseSearchMenu: Function;
  searchMenuPosition: HTMLElement;
  handleSearch: Function;
  searchVal: string;
}

export default function SearchMenuWrapper({
  handleCloseSearchMenu,
  searchMenuPosition,
  handleSearch,
  searchVal,
}: SearchMenuWrapperProps) {
  const currentCaseId = useSelector(selectCurrentCaseId);
  const classes = useStyles();
  const { userSettings, updateUserSettings } = useUpdateUserSettings();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const doSearch = (val: string) => {
    handleSearch(val);
    if (val && val.trim().length !== 0) {
      if (
        !userSettings.recentSearches ||
        !userSettings.recentSearches[currentCaseId] ||
        val !== userSettings.recentSearches[[currentCaseId]][0]
      ) {
        const recentSearches =
          userSettings.recentSearches &&
          userSettings.recentSearches[currentCaseId] &&
          userSettings.recentSearches[currentCaseId].length > 0
            ? createListofRecentItems(val, userSettings.recentSearches[currentCaseId])
            : [val];
        updateUserSettings({
          userSettings: {
            ...userSettings,
            recentSearches: {
              ...userSettings.recentSearches,
              [currentCaseId]: recentSearches,
            },
          },
        });
      }
    }
  };

  return (
    <Menu
      anchorEl={searchMenuPosition}
      id="search-menu"
      className={classes.menuPaperContainer}
      PaperProps={{ sx: { width: isMobile ? '70vw' : '50vw' } }}
      open={true}
      onClose={() => handleCloseSearchMenu()}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <Box>
        <SearchMenuHome
          handleCloseSearchMenu={handleCloseSearchMenu}
          doSearch={doSearch}
          searchVal={searchVal}
          handleSearch={handleSearch}
        />
      </Box>
    </Menu>
  );
}
