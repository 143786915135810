import React from 'react';
import { Divider, Link } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { openNewBackgroundTab } from 'utils/browser';
import Duration from 'features/common/Duration';

const useStyles = makeStyles(() =>
  createStyles({
    transcriptSidebar: {
      color: '#777',
      background: 'transparent',
      borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      marginLeft: '1rem',
    },
    transcriptSidebarHeading: {
      marginTop: '1rem',
      flexGrow: 1,
      fontWeight: '600',
    },
    transcriptModalHeading: {
      marginTop: '0.5rem',
      fontWeight: '600',
    },
    transcriptSection: {
      width: '100%',
      height: '100%',
    },
    transcriptList: {
      listStyleType: 'none',
      padding: 0,
    },
    transcriptItem: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0.5rem 1rem',
    },
    transcriptItemSpeaker: {
      width: '15%',
      marginRight: '1rem',
    },
    transcriptItemText: {
      width: '85%',
      marginLeft: '1rem',
      '&:hover': { cursor: 'pointer' },
    },
    transcriptItemOffset: {
      fontSize: '1rem',
    },
    spinner: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      marginTop: '60%',
    },
  }),
);

type TranscriptItemProps = {
  index: any;
  transcriptItem: any;
  handlePlayerSliderChange: any;
  handlePopoverClose?: any;
  isFullSizeViewer?: any;
  recordingTime: any;
  showRecordingTime?: any;
  showParagraphView?: any;
};

export default ({
  index,
  transcriptItem,
  handlePlayerSliderChange,
  handlePopoverClose,
  isFullSizeViewer,
  recordingTime,
  showRecordingTime,
  showParagraphView,
}: TranscriptItemProps) => {
  const classes = useStyles();
  const getRecordingTime = (recordingTimeStr: any, currentTime: any) => {
    if (recordingTimeStr) {
      const rt = recordingTimeStr.split(':');
      const recordingTimeInSec = parseInt(rt[0]) * 60 * 60 + parseInt(rt[1]) * 60 + parseInt(rt[2]);
      const totalTime = recordingTimeInSec + parseFloat(currentTime);
      return <Duration seconds={totalTime} />;
    } else return '';
  };
  return (
    <li
      key={index}
      className={classes.transcriptItem}
      style={{ ...(isFullSizeViewer && { fontSize: '1.2rem' }) }}
    >
      <div className={classes.transcriptItemSpeaker}>
        <div>{transcriptItem.recordedSpeaker || transcriptItem.speaker}</div>
        {transcriptItem.link ? (
          <Link
            underline="always"
            className={classes.transcriptItemOffset}
            onClick={() => {
              openNewBackgroundTab(`/${transcriptItem.link}`);
            }}
          >
            {transcriptItem.offset}
          </Link>
        ) : (
          <span className={classes.transcriptItemOffset}>
            {showRecordingTime
              ? getRecordingTime(recordingTime, transcriptItem.seconds)
              : transcriptItem.offset}
          </span>
        )}
      </div>
      <div>
        <Divider orientation="vertical" sx={{ borderColor: 'rgb(0 0 0 / 52%)' }} />
      </div>
      <Link
        underline="hover"
        color={'#777'}
        className={classes.transcriptItemText}
        onClick={() => {
          handlePlayerSliderChange(parseFloat(transcriptItem.seconds));
          handlePopoverClose && handlePopoverClose();
        }}
      >
        {showParagraphView ? transcriptItem.paragraphText : transcriptItem.text}
      </Link>
    </li>
  );
};
