import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_FETCH_ANNOTATIONS_BEGIN,
  CASE_FETCH_ANNOTATIONS_SUCCESS,
  CASE_FETCH_ANNOTATIONS_FAILURE,
  CASE_FETCH_ANNOTATIONS_DISMISS_FEEDBACK,
  CASE_CLEAR_ANNOTATIONS,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchAnnotations = createPromiseAction(CASE_FETCH_ANNOTATIONS_BEGIN);

export function dismissFetchAnnotationsFeedback() {
  return {
    type: CASE_FETCH_ANNOTATIONS_DISMISS_FEEDBACK,
  };
}

export function clearAnnotations() {
  return {
    type: CASE_CLEAR_ANNOTATIONS,
  };
}

// worker Saga: will be fired on CASE_FETCH_ANNOTATIONS_BEGIN actions
export function* doFetchAnnotations(action) {
  const {
    payload: { caseId, tagId, isAllAnnotations },
  } = action;

  const res = yield call(
    api.get,
    isAllAnnotations ? `/cases/${caseId}/annotations` : `/cases/${caseId}/tags/${tagId}/locations`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_ANNOTATIONS_FAILURE,
      feedback: {
        message: 'feedback.fetchAnnotationsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_ANNOTATIONS_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchAnnotations() {
  yield takeLatest(fetchAnnotations, withCurrentCaseId(doFetchAnnotations));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_ANNOTATIONS_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchAnnotationsPending: true,
        fetchAnnotationsFeedback: null,
      };

    case CASE_FETCH_ANNOTATIONS_SUCCESS:
      return {
        ...state,
        fetchAnnotationsPending: false,
        fetchAnnotationsFeedback: action.feedback,
        annotations: action.data,
      };

    case CASE_CLEAR_ANNOTATIONS:
      return {
        ...state,
        annotations: [],
      };

    case CASE_FETCH_ANNOTATIONS_FAILURE:
      return {
        ...state,
        fetchAnnotationsPending: false,
        fetchAnnotationsFeedback: action.feedback,
      };

    case CASE_FETCH_ANNOTATIONS_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchAnnotationsFeedback: null,
      };

    default:
      return state;
  }
}
