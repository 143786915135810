import React, { useCallback, useEffect, useRef, useState } from 'react';
import './UploadFileContent.scss';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import api from 'common/api';

import { selectCurrentCaseId } from 'common/selectors';
import { useSelector } from 'react-redux';
import { BlobServiceClient } from '@azure/storage-blob';
import MuiButton from '@mui/material/Button';
import { Spinner } from 'features/common';
import { CircularProgressWithLabel } from './CustomProgress';
import { BatchUploadReport, FileMetaData, SasResponse } from './types';
import { v4 as uuidv4 } from 'uuid';
import { Accordion, Card } from 'react-bootstrap';
import {
  faCheckCircle,
  faChevronDown,
  faExclamationCircle,
  faFolder,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validFileExts, validFileExtsShareOnly } from './constants';
import T from 'i18n';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { fetchFolder } from '../redux/fetchFolder';
import { fetchDocuments } from '../redux/fetchDocuments';
import { useFetchNotifications } from '../redux/fetchNotifications';
import {
  areAllFilesUploaded,
  buildFolderTree,
  findNodeByPath,
  flattenFolderTree,
  getAllSubfolderPaths,
  hasProtectedFiles,
  hasUnsupportedFiles,
  isPDFEncrypted,
} from './utils';
import { ReplayCircleFilledOutlined } from '@mui/icons-material';

type UploadFileContentProps = {
  setUploadComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setBatchUploading: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentBatchId: React.Dispatch<React.SetStateAction<number | null>>;
  setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setBatchUploadReport: React.Dispatch<React.SetStateAction<BatchUploadReport>>;
  setFileMetadata: React.Dispatch<React.SetStateAction<FileMetaData[]>>;
  setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  setBatchSize: React.Dispatch<React.SetStateAction<number>>;

  fileMetadata: FileMetaData[];

  currentFolderId: string;
  showSidebar: boolean;
  uploadComplete: boolean;
  uploadInProgress: boolean;
  currentBatchId: number | null;
};

const UploadFileContent = ({
  setUploadComplete,
  setBatchUploading,
  setCurrentBatchId,
  setUploadInProgress,
  setBatchUploadReport,
  setFileMetadata,
  setShowSideBar,
  setBatchSize,

  fileMetadata,
  currentFolderId,
  showSidebar,
  uploadComplete,
  uploadInProgress,
  currentBatchId,
}: UploadFileContentProps) => {
  const dragCounter = useRef(0);
  const caseId = useSelector(selectCurrentCaseId);

  const [showOverlay, setShowOverlay] = useState(false);
  const [containerClient, setContainerClient] = useState<any>(null);
  const [containsValidFiles, setContainsValidFiles] = useState(true);
  const [progressState, setProgressState] = useState<{ [key: string]: number }>({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState<string | null>(null);
  const [expandedFolders, setExpandedFolders] = useState<{ [key: string]: boolean }>({});
  const [processingFiles, setProcessingFiles] = useState(false);
  const [apiError, setApiError] = useState<string | null>(null);

  const { notifications } = useFetchNotifications();
  const [resolveNotificationsPromise, setResolveNotificationsPromise] = useState<
    (() => void) | null
  >(null);
  const initialNotificationsLengthRef = useRef(notifications?.length);

  const dispatch = useDispatch();

  const processFiles = async (files: File[], folderPath: string) => {
    for (const file of files) {
      const extension = file.name
        .split('.')
        .pop()
        ?.toLowerCase();
      const isValidExtension =
        extension &&
        (validFileExts.includes(extension) || validFileExtsShareOnly.includes(extension));

      let status = isValidExtension ? '' : 'unsupported';

      if (isValidExtension) {
        // TODO: Correctly implement: Check for password protection
        let isProtected = false;

        if (extension === 'pdf') {
          isProtected = await isPDFEncrypted(file);
          if (isProtected) {
            status = 'protected';
          }
        }
      }

      const newId = uuidv4();
      const fileNameWithExtension = `${newId}.${extension}`;

      setProgressState(prevState => ({
        ...prevState,
        [newId]: 0,
      }));

      setFileMetadata(prevFiles => [
        ...prevFiles,
        {
          file,
          name: file.name,
          uniqueId: newId,
          fileNameWithExtension,
          size: file.size,
          type: file.type,
          folderPath,
          progress: 0,
          status,
        },
      ]);
    }
  };

  const onDrop = async (acceptedFiles: File[]) => {
    if (uploadComplete) {
      handleClearAll();
    }

    setProcessingFiles(true);

    const folderMap: { [folderPath: string]: File[] } = {};

    acceptedFiles.forEach(file => {
      const filePath = (file as any).path || file.name;
      const pathParts = filePath.split('/'); // we could add .slice(2) here but it's problematic with js runtimes
      const modifiedPathParts = pathParts.slice(2); // Remove first folder
      if (modifiedPathParts.length > 1) {
        const folderPath = modifiedPathParts.slice(0, -1).join('/');
        if (!folderMap[folderPath]) {
          folderMap[folderPath] = [];
        }
        folderMap[folderPath].push(file);
      } else {
        if (!folderMap['root']) {
          folderMap['root'] = [];
        }
        folderMap['root'].push(file);
      }
    });

    for (const [folderPath, files] of Object.entries(folderMap)) {
      await processFiles(files, folderPath === 'root' ? '' : folderPath);
    }

    setProcessingFiles(false);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    if (uploadComplete) {
      handleClearAll();
    }
    e.preventDefault();
    e.stopPropagation();
    setShowOverlay(false);

    setProcessingFiles(true);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const items = e.dataTransfer.items;

      for (let i = 0; i < items.length; i++) {
        const entry = items[i].webkitGetAsEntry();
        if (!entry) {
          console.warn('webkitGetAsEntry is not supported, processing files directly');
          await processFiles(Array.from(files), '');
          setProcessingFiles(false);
          return;
        }

        if (entry) {
          if (entry.isFile) {
            const file = files[i];
            await processFiles([file], '');
          } else if (entry.isDirectory) {
            await processDirectory(entry as FileSystemDirectoryEntry, '');
          }
        }
      }
    }

    setProcessingFiles(false);
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      dragCounter.current += 1;
      if (dragCounter.current === 1) {
        setShowOverlay(true);
      }
    }
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    dragCounter.current -= 1;
    if (dragCounter.current === 0) {
      setShowOverlay(false);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleToggleAccordion = (folderPath: string, isExpanding: boolean) => {
    setExpandedFolders(prevState => {
      const newState = { ...prevState };
      newState[folderPath] = isExpanding;

      if (!isExpanding) {
        const node = findNodeByPath(folderTree, folderPath);
        if (node) {
          const allSubfolderPaths = getAllSubfolderPaths(node);
          allSubfolderPaths.forEach(path => {
            newState[path] = false;
          });
        }
      }

      return newState;
    });
  };

  const handleOpenSnackbar = (folder: string) => {
    setFolderToDelete(folder);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
    setFolderToDelete(null);
  }, []);

  const handleProceed = useCallback(() => {
    if (folderToDelete !== null) {
      setFileMetadata(prevFiles =>
        prevFiles.filter(file => !file?.folderPath?.startsWith(folderToDelete ?? '')),
      );
    }
    handleCloseSnackbar();
  }, [folderToDelete, setFileMetadata, handleCloseSnackbar]);

  const handleConfirm = async () => {
    try {
      setApiError(null);
      setUploadInProgress(true);
      setBatchUploading(true);

      const { sasUrl } = await getSasToken();

      const blobServiceClient = new BlobServiceClient(sasUrl);
      const containerClient = blobServiceClient.getContainerClient('');

      const filesToUpload = fileMetadata.filter(file => file.status !== 'unsupported');
      await uploadFilesToAzure(filesToUpload, containerClient);

      // Check for failed and cancelled files
      const incompleteFiles = filesToUpload.filter(file => file.status === 'failed');
      const successfulFiles = filesToUpload.filter(file => file.status === 'uploaded');

      if (incompleteFiles.length > 0) {
        // Show dialog with options
        setShowRetryDialog(true);
      } else {
        // Proceed to create batch
        await proceedToCreateBatch(successfulFiles);
      }
    } catch (error) {
      console.error('Error in handleConfirm:', error);
      setUploadInProgress(false);
      setBatchUploading(false);
      setApiError(T.translate('case.batchUpload.apiError'));

      // Update all file statuses to 'failed'
      setFileMetadata(prevFiles => prevFiles.map(file => ({ ...file, status: 'failed' })));
    }
  };
  const getSasToken = async () => {
    try {
      const response = (await api.get(`/cases/${caseId}/batch-token`)) as
        | SasResponse
        | { error: any };
      if ('error' in response) {
        throw new Error(T.translate('case.batchUpload.fetchSasTokenError'));
      }

      const sasUrl = response?.sasToken;
      const containerName = response?.container;

      if (!sasUrl || !containerName) {
        throw new Error(T.translate('case.batchUpload.missingSasToken'));
      }
      return { sasUrl, containerName };
    } catch (error) {
      throw error;
    }
  };

  const createBatch = async (
    payload: { uniqueId: string; fileName: string; folderPath: string | undefined }[],
  ) => {
    try {
      const response = await api.post(`/cases/${caseId}/batch`, {
        folderId: currentFolderId,
        data: payload,
      });
      if (!response || (response as any)?.error) {
        throw new Error(T.translate('case.batchUpload.createBatchError'));
      }
      return response;
    } catch (error) {
      throw new Error(T.translate('case.batchUpload.createBatchError'));
    }
  };

  const triggerBatchProcessing = async (batchId: any) => {
    try {
      const response = await api.put(`/cases/${caseId}/batch/${batchId}`);
      if (!response || (response as any)?.error) {
        throw new Error(T.translate('case.batchUpload.triggerBatchError'));
      }
    } catch (error) {
      throw new Error(T.translate('case.batchUpload.triggerBatchError'));
    }
  };

  const fetchBatchReport = async (batchId: any) => {
    try {
      const report = await api.get(`/cases/${caseId}/batch/${batchId}`);
      if (report && (report as any)?.error) {
        throw new Error(T.translate('case.batchUpload.fetchReportWarning'));
      }
      setBatchUploadReport(report as BatchUploadReport);
    } catch {
      console.warn(T.translate('case.batchUpload.fetchReportWarning'));
    }
  };

  const uploadFilesToAzure = async (files: FileMetaData[], containerClient: any) => {
    try {
      const uploadPromises = files.map(async file => {
        const abortController = new AbortController();
        file.abortController = abortController;
        file.status = 'uploading';

        const blockBlobClient = containerClient.getBlockBlobClient(file.fileNameWithExtension);

        try {
          await blockBlobClient.uploadData(file.file, {
            blobHTTPHeaders: {
              blobContentType: file.type,
            },
            onProgress: (progress: { loadedBytes: number }) => {
              const percentCompleted = Math.round((progress.loadedBytes / file.size) * 100);
              setProgressState(prevState => ({
                ...prevState,
                [file.uniqueId]: percentCompleted,
              }));
            },
            abortSignal: abortController.signal,
            maxSingleShotSize: 4 * 1024 * 1024,
            concurrency: 10,
          });

          file.status = 'uploaded';
        } catch (error) {
          if (error instanceof Error) {
            file.status = error.name === 'AbortError' ? 'cancelled' : 'failed';
            file.errorMessage = error.message;
          } else {
            file.status = 'failed';
            file.errorMessage = 'Unknown error';
          }
        }
      });

      await Promise.all(uploadPromises);
      setFileMetadata([...files]);
    } catch {
      throw new Error(T.translate('case.batchUpload.uploadError'));
    }
  };

  const prepareJsonDataPayload = (files: FileMetaData[]) => {
    return files
      .filter(file => file.status === 'uploaded')
      .map(file => ({
        uniqueId: file.uniqueId,
        fileName: file.name,
        folderPath: file.folderPath,
      }));
  };

  const waitForNotifications = async () => {
    initialNotificationsLengthRef.current = notifications.length;
    const notificationsPromise = new Promise<void>(resolve => {
      setResolveNotificationsPromise(() => resolve);
    });
    await notificationsPromise;
  };

  const refreshData = async () => {
    try {
      await dispatch(fetchFolder({ folderId: currentFolderId, noPromise: false }));
      await dispatch(fetchDocuments({} as any));
    } catch {
      console.warn(T.translate('case.batchUpload.refreshDataWarning'));
    }
  };

  const handleClearAll = useCallback(() => {
    if (uploadComplete) {
      setShowSideBar(false);
    }
    setApiError(null);
    setOpenSnackbar(false);
    setUploadComplete(false);
    setFileMetadata([]);
    setBatchUploading(false);
  }, [
    setOpenSnackbar,
    setUploadComplete,
    setFileMetadata,
    setBatchUploading,
    setShowSideBar,
    uploadComplete,
  ]);

  const processDirectory = async (directoryEntry: FileSystemDirectoryEntry, folderPath: string) => {
    const reader = directoryEntry.createReader();
    const entries = await new Promise<FileSystemEntry[]>((resolve, reject) => {
      reader.readEntries(resolve, reject);
    });

    for (const entry of entries) {
      if (entry.isFile) {
        const fileEntry = entry as FileSystemFileEntry;
        const file = await new Promise<File>((resolve, reject) => fileEntry.file(resolve, reject));
        await processFiles([file], folderPath);
      } else if (entry.isDirectory) {
        const dirEntry = entry as FileSystemDirectoryEntry;
        const newFolderPath = folderPath ? `${folderPath}/${entry.name}` : entry.name;
        await processDirectory(dirEntry, newFolderPath);
      }
    }
  };
  const getContainerClient = async () => {
    if (containerClient) {
      return containerClient;
    } else {
      const { sasUrl } = await getSasToken();
      const blobServiceClient = new BlobServiceClient(sasUrl);
      const newContainerClient = blobServiceClient.getContainerClient('');
      setContainerClient(newContainerClient);
      return newContainerClient;
    }
  };
  const [showRetryDialog, setShowRetryDialog] = useState(false);

  const handleDialogClose = () => {
    setShowRetryDialog(false);
  };

  const handleRetryFailures = async () => {
    setShowRetryDialog(false);
    await retryAllFailedFiles();
  };

  const handleProcessUploadedFiles = async () => {
    setShowRetryDialog(false);
    const successfulFiles = fileMetadata.filter(file => file.status === 'uploaded');
    if (successfulFiles.length > 0) {
      await proceedToCreateBatch(successfulFiles);
    } else {
      setApiError(T.translate('case.batchUpload.noValidFiles'));
      setUploadInProgress(false);
      setBatchUploading(false);
    }
  };

  const handleCancelUpload = () => {
    setShowRetryDialog(false);
    setUploadInProgress(false);
    setBatchUploading(false);
  };
  const proceedToCreateBatch = async (successfulFiles: FileMetaData[]) => {
    try {
      const jsonDataPayload = prepareJsonDataPayload(successfulFiles);
      setBatchSize(jsonDataPayload.length);

      if (jsonDataPayload.length === 0) {
        throw new Error(T.translate('case.batchUpload.noValidFiles'));
      }

      const batch: any = await createBatch(jsonDataPayload);
      setCurrentBatchId(batch.id);

      await triggerBatchProcessing(batch.id);
      await waitForNotifications();
      await refreshData();

      setUploadComplete(true);
      setUploadInProgress(false);
      setBatchUploading(false);

      await fetchBatchReport(batch.id);
    } catch (error) {
      console.error('Error in proceedToCreateBatch:', error);
      setUploadInProgress(false);
      setBatchUploading(false);
      setApiError(T.translate('case.batchUpload.apiError'));
    }
  };

  const retryAllFailedFiles = async () => {
    try {
      const containerClient = await getContainerClient();

      const incompleteFiles = fileMetadata.filter(
        file => file.status === 'failed' || file.status === 'cancelled',
      );

      // Update statuses to 'uploading' and reset progress
      incompleteFiles.forEach(file => {
        setProgressState(prevState => ({ ...prevState, [file.uniqueId]: 0 }));
        file.status = 'uploading';
      });

      // Update fileMetadata state
      setFileMetadata(prevFiles => [...prevFiles]);

      // Retry uploads
      await Promise.all(
        incompleteFiles.map(async file => {
          await retryFileUpload(file, containerClient);
        }),
      );

      // After retries, check statuses directly from incompleteFiles
      const incompleteFilesAfterRetry = incompleteFiles.filter(
        file => file.status === 'failed' || file.status === 'cancelled',
      );

      const successfulFiles = fileMetadata.filter(file => file.status === 'uploaded');

      if (incompleteFilesAfterRetry.length > 0) {
        // Show dialog again to let the user decide
        setShowRetryDialog(true);
      } else if (successfulFiles.length > 0) {
        // All files uploaded successfully after retries
        await proceedToCreateBatch(successfulFiles);
      } else {
        // No files to process
        setApiError(T.translate('case.batchUpload.noValidFiles'));
        setUploadInProgress(false);
        setBatchUploading(false);
      }
    } catch (error) {
      console.error('Error in retryAllFailedFiles:', error);
      setApiError(T.translate('case.batchUpload.uploadError'));
      setUploadInProgress(false);
      setBatchUploading(false);
    }
  };
  const retryFileUpload = async (file: FileMetaData, containerClient: any) => {
    try {
      file.status = 'uploading';
      setFileMetadata(prevFiles => [...prevFiles]);

      setProgressState(prevState => ({ ...prevState, [file.uniqueId]: 0 }));

      const abortController = new AbortController();
      file.abortController = abortController;

      const blockBlobClient = containerClient.getBlockBlobClient(file.fileNameWithExtension);

      try {
        await blockBlobClient.uploadData(file.file, {
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
          onProgress: (progress: { loadedBytes: number }) => {
            const percentCompleted = Math.round((progress.loadedBytes / file.size) * 100);
            setProgressState(prevState => ({
              ...prevState,
              [file.uniqueId]: percentCompleted,
            }));
          },
          abortSignal: abortController.signal,
          maxSingleShotSize: 4 * 1024 * 1024,
          concurrency: 10,
        });

        file.status = 'uploaded';
        file.errorMessage = undefined;
      } catch (error) {
        if (error instanceof Error) {
          file.status = error.name === 'AbortError' ? 'cancelled' : 'failed';
          file.errorMessage = error.message;
        } else {
          file.status = 'failed';
          file.errorMessage = 'Unknown error';
        }
      }

      // Update the fileMetadata state after each retry
      setFileMetadata(prevFiles => [...prevFiles]);
    } catch (error) {
      console.error('Error in retryFileUpload:', error);
    }
  };

  const removeFile = (fileName: string) => {
    setFileMetadata(prevFiles => prevFiles.filter(file => file.name !== fileName));
  };

  const renderFilesInFolder = (files: FileMetaData[]) => {
    const statusOrder: any = {
      unsupported: 0,
      failed: 1,
      cancelled: 2,
      uploading: 3,
      '': 4,
      uploaded: 5,
    };
    const sortedFiles = [...files].sort((a, b) => {
      const aStatus = statusOrder[a.status] ?? 6;
      const bStatus = statusOrder[b.status] ?? 6;
      return aStatus - bStatus;
    });

    return sortedFiles.map(file => {
      const progressValue = progressState[file.uniqueId] || 0;

      return (
        <li key={file.uniqueId}>
          <Tooltip title={file.name}>
            <span>
              {file.name.length > 25
                ? `${file?.name?.substring(0, 10)}...${file?.name?.substring(
                    file?.name?.length - 10,
                    file?.name?.length - 1,
                  )}`
                : file.name}
            </span>
          </Tooltip>

          <span>{(file.size / (1024 * 1024)).toFixed(2)} MB</span>

          {file?.status === 'uploading' ? (
            <CircularProgressWithLabel value={progressValue} size={28} />
          ) : file?.status === 'unsupported' ? (
            <span style={{ color: 'red' }}>
              {T.translate('case.batchUpload.fileLabels.unsupported')}
            </span>
          ) : file?.status === 'cancelled' && uploadInProgress ? (
            <span style={{ color: 'orange' }}>
              {T.translate('case.batchUpload.fileLabels.cancelled')}
            </span>
          ) : file?.status === 'uploaded' ? (
            <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: '8px' }} />
          ) : file?.status === 'failed' || file?.status === 'cancelled' ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={file.errorMessage || 'Unknown error'}>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{
                    color: file?.status === 'cancelled' ? 'orange' : 'red',
                    marginLeft: '8px',
                  }}
                />
              </Tooltip>
            </div>
          ) : null}
          {file?.status === 'uploading' && progressValue < 100 ? (
            <IconButton
              onClick={() => {
                if (file.abortController) {
                  file.abortController.abort();
                  setFileMetadata(prevFiles =>
                    prevFiles.map(f =>
                      f.uniqueId === file.uniqueId ? { ...f, status: 'cancelled' } : f,
                    ),
                  );
                }
              }}
              style={{ right: 0, top: 0 }}
            >
              <CloseIcon style={{ height: 12, width: 12 }} />
            </IconButton>
          ) : file?.status !== 'uploaded' && file?.status !== 'cancelled' ? (
            <IconButton onClick={() => removeFile(file.name)} style={{ right: 0, top: 0 }}>
              <CloseIcon style={{ height: 12, width: 12 }} />
            </IconButton>
          ) : null}
        </li>
      );
    });
  };

  const folderTree = buildFolderTree(fileMetadata);
  const flattenedFolders = flattenFolderTree(folderTree);

  useEffect(() => {
    const validFiles = fileMetadata.filter(
      fileMeta => fileMeta.status !== 'unsupported' && fileMeta.status !== 'failed',
    );

    setContainsValidFiles(validFiles.length > 0);
  }, [fileMetadata]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleProceed();
      }
    };

    if (openSnackbar) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openSnackbar, handleProceed]);

  useEffect(() => {
    if (uploadComplete && !showSidebar) {
      handleClearAll();
    }
  }, [handleClearAll, showSidebar, uploadComplete]);

  useEffect(() => {
    if (
      notifications?.length > initialNotificationsLengthRef?.current &&
      resolveNotificationsPromise
    ) {
      resolveNotificationsPromise();
      setResolveNotificationsPromise(null);
    }
  }, [notifications, resolveNotificationsPromise]);

  return (
    <>
      {showOverlay && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            backgroundColor: 'transparent',
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        />
      )}
      {!uploadComplete ? (
        <div className="upload-blob-container">
          <div
            {...getRootProps({ className: `drag-drop-box ${isDragActive ? 'drag-active' : ''}` })}
          >
            <input {...getInputProps()} />
            <p>{T.translate('case.batchUpload.dropzone.title')}</p>
            <MuiButton variant="text" sx={{ paddingLeft: 0, justifyContent: 'start' }} size="small">
              <p>{T.translate('case.batchUpload.dropzone.subtitle')}</p>
            </MuiButton>
          </div>
        </div>
      ) : (
        <div className="upload-blob-container">
          <div className="drag-drop-box">
            <p
              style={{
                color: '#7b8184',
              }}
            >
              {T.translate('case.batchUpload.dropzone.title')}
            </p>
            <MuiButton
              variant="text"
              sx={{ paddingLeft: 0, justifyContent: 'start' }}
              size="small"
              disabled
            >
              <p
                style={{
                  color: '#7b8184',
                }}
              >
                {T.translate('case.batchUpload.dropzone.subtitle')}
              </p>
            </MuiButton>
          </div>
        </div>
      )}
      <Dialog
        open={showRetryDialog}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            // Prevent closing the dialog by clicking on the backdrop
            return;
          }
          // Allow closing the dialog via other reasons (e.g., button clicks)
          handleDialogClose();
        }}
        disableEscapeKeyDown
        aria-labelledby="retry-dialog-title"
        aria-describedby="retry-dialog-description"
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
        PaperProps={{
          style: {
            margin: '20px',
            width: '400px',
          },
        }}
        maxWidth="md"
      >
        <DialogTitle>
          <h4>{T.translate('case.batchUpload.dialog.title')}</h4>
        </DialogTitle>
        <DialogContent>
          <p style={{ paddingRight: 20, fontSize: 13 }}>
            {T.translate('case.batchUpload.dialog.message')}
          </p>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
          }}
        >
          <Button onClick={handleCancelUpload} color="inherit">
            {T.translate('case.batchUpload.dialog.actions.cancel')}
          </Button>
          <Button onClick={handleRetryFailures} color="primary">
            {T.translate('case.batchUpload.dialog.actions.retry')}
          </Button>
          <Button onClick={handleProcessUploadedFiles} color="secondary">
            {T.translate('case.batchUpload.dialog.actions.skip')}
          </Button>
        </DialogActions>
      </Dialog>
      <div className="upload-file-list">
        <p
          style={{
            color: 'red',
            textAlign: 'center',
            margin: '10px 0',
            display: 'block',
          }}
        >
          {apiError}
        </p>
        {fileMetadata?.length > 0 && !processingFiles && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: 4,
              alignItems: 'center',
              alignContent: 'center',
              justifyItems: 'center',
            }}
          >
            {uploadComplete ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                }}
              >
                <h5>{T.translate('case.batchUpload.uploadComplete')}</h5>
              </div>
            ) : (
              <h5>
                {T.translate('case.batchUpload.dropzone.documents', {
                  count: fileMetadata?.length,
                })}
              </h5>
            )}
            <div>
              {containsValidFiles && !uploadComplete ? (
                <>
                  {!uploadInProgress && (
                    <>
                      <IconButton
                        onClick={handleClearAll}
                        sx={{
                          color: 'red',
                          '&:hover': {
                            backgroundColor: 'rgba(250, 78, 75, 0.1)',
                          },
                        }}
                      >
                        <FontAwesomeIcon size="sm" icon={faTrash} />
                      </IconButton>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={async () => await handleConfirm()}
                        disabled={uploadInProgress}
                        style={{ marginLeft: 5 }}
                      >
                        {uploadInProgress ? (
                          <Spinner style={{ fontSize: '0.5rem' }} />
                        ) : (
                          T.translate('case.batchUpload.dropzone.upload')
                        )}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <IconButton
                  onClick={handleClearAll}
                  sx={{
                    color: 'red',
                    '&:hover': {
                      backgroundColor: 'rgba(250, 78, 75, 0.1)',
                    },
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </IconButton>
              )}
            </div>
          </div>
        )}
        {processingFiles ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 10,
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <Spinner />
            <p
              style={{
                textAlign: 'center',
              }}
            >
              {T.translate('case.batchUpload.processingFiles', { fileCount: fileMetadata?.length })}
            </p>
          </div>
        ) : (
          flattenedFolders.map(({ node, depth }) => {
            if (node.name === 'root') {
              if (node.files.length > 0) {
                return (
                  <div key={node.path}>
                    <ul>{renderFilesInFolder(node.files)}</ul>
                  </div>
                );
              }
              return null;
            }

            const allFilesUploaded = areAllFilesUploaded(node);
            const containsUnsupportedFiles = hasUnsupportedFiles(node);
            const containsProtectedFiles = hasProtectedFiles(node);

            return (
              <div key={node.path} style={{ marginLeft: depth * 20 }}>
                <Accordion>
                  <Card
                    style={{
                      margin: 3,
                      border: 'none',
                      boxShadow: 'none',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <Accordion.Toggle
                      as={Button}
                      eventKey={node.path}
                      onClick={() => handleToggleAccordion(node.path, !expandedFolders[node.path])}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: 'transparent',
                        flexDirection: 'row',
                        gap: 10,
                        color: 'black',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        style={{
                          transform: expandedFolders[node.path] ? 'rotate(180deg)' : 'rotate(0deg)',
                          transition: 'transform 0.2s',
                        }}
                      />
                      {/* Folder icon */}
                      <FontAwesomeIcon
                        icon={faFolder}
                        style={{
                          color: '#7b8184',
                          height: 13,
                          width: 13,
                        }}
                      />
                      <Tooltip title={node.name}>
                        <span>
                          {node?.name.length > 25
                            ? `${node?.name?.substring(0, 10)}...${node?.name?.substring(
                                node?.name?.length - 10,
                                node?.name?.length - 1,
                              )}`
                            : node.name}
                        </span>
                      </Tooltip>
                      {containsUnsupportedFiles && (
                        <Tooltip
                          title={T.translate('case.batchUpload.unsupported')}
                          placement="top"
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              style={{
                                color: 'red',
                                marginRight: '8px',
                                height: 12,
                                width: 12,
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {containsProtectedFiles && (
                        <Tooltip
                          title={T.translate('case.batchUpload.protectedFile')}
                          placement="top"
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faExclamationCircle}
                              style={{
                                color: 'red',
                                marginRight: '8px',
                                height: 12,
                                width: 12,
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                      {!uploadInProgress ? (
                        allFilesUploaded ? (
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{
                              color: 'green',
                              marginLeft: 'auto',
                              height: 12,
                              width: 12,
                            }}
                          />
                        ) : (
                          <IconButton
                            onClick={() => handleOpenSnackbar(node.path)}
                            style={{ right: 0, marginLeft: 'auto' }}
                          >
                            <CloseIcon style={{ height: 12, width: 12 }} />
                          </IconButton>
                        )
                      ) : (
                        <Spinner style={{ fontSize: '0.2rem', right: 0, marginLeft: 'auto' }} />
                      )}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={node.path} in={expandedFolders[node.path]}>
                      <Card.Body>
                        <ul>{renderFilesInFolder(node.files)}</ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
                {node.name === 'root' && node.files.length > 0 && (
                  <ul>{renderFilesInFolder(node.files)}</ul>
                )}
              </div>
            );
          })
        )}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={20000}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          handleCloseSnackbar();
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="warning"
          variant="filled"
          sx={{ backgroundColor: '#7CA0F4', color: 'white' }}
          action={
            <>
              <Button color="inherit" size="small" onClick={handleProceed}>
                {T.translate('case.batchUpload.snackbar.proceed')}
              </Button>
              <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
                {T.translate('case.batchUpload.snackbar.cancel')}
              </Button>
            </>
          }
        >
          {T.translate('case.batchUpload.snackbar.warning', { folderToDelete })}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadFileContent;
