import React, { Component } from 'react';
import T from 'i18n';
import TableWrapper from './TableWrapper';

export default class TeamBundlesPage extends Component {
  static propTypes = {};

  render() {
    return <TableWrapper category={T.translate('case.teamBundles')} />;
  }
}
