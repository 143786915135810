import Logger from 'utils/logger';

const mockedDocuments = (method, documentType) => {
  if (method.toUpperCase() === 'POST') return {};
  if (method.toUpperCase() === 'GET')
    switch (documentType) {
      case 'trialbooks':
        return [
          {
            id: 'TV01-203-2763',
            type: 'pdf',
            name: 'Architects First Instruction',
            code: 'architects-first-instruction',
          },
          {
            id: 'TV01-917-9731',
            type: 'pdf',
            name: 'Architects Instruction',
            code: 'architects-instruction',
          },
          {
            id: 'TV01-240-2250',
            type: 'pdf',
            name: 'Certificate of Roof Inspection',
            code: 'certificate-of-roof-inspection',
          },
          { id: 'TV01-041-9046', type: 'json', name: 'data', code: 'data' },
          {
            id: 'TV01-699-1391',
            type: 'pdf',
            name: 'Email from Architect',
            code: 'email-from-architect',
          },
          {
            id: 'TV01-762-0062',
            type: 'pdf',
            name: 'Email re Construction Architect',
            code: 'email-re-construction-architect',
          },
          {
            id: 'TV01-609-3911',
            type: 'pdf',
            name: 'Email with 2nd Spec',
            code: 'email-with-2nd-spec',
          },
          { id: 'TV01-004-6465', type: 'pdf', name: 'Email with Spec', code: 'email-with-spec' },
          {
            id: 'TV01-272-7101',
            type: 'pdf',
            name: 'Engineers Certificate',
            code: 'engineers-certificate',
          },
          { id: 'TV01-672-0761', type: 'ps1', name: 'filesToJson', code: 'files-to-json' },
          { id: 'TV01-349-3604', type: 'pdf', name: 'First Complaint', code: 'first-complaint' },
          {
            id: 'TV01-260-0970',
            type: 'pdf',
            name: 'First Engineers Certificate',
            code: 'first-engineers-certificate',
          },
          { id: 'TV01-246-5423', type: 'json', name: 'flatdata', code: 'flatdata' },
          { id: 'TV01-622-1246', type: 'pdf', name: 'Fourth Complaint', code: 'fourth-complaint' },
          {
            id: 'TV01-936-3463',
            type: 'pdf',
            name: 'Further Complaints',
            code: 'further-complaints',
          },
          { id: 'TV01-167-9421', type: 'pdf', name: 'Memo of Engineer', code: 'memo-of-engineer' },
          {
            id: 'TV01-523-9134',
            type: 'pdf',
            name: 'Original Contract',
            code: 'original-contract',
          },
          { id: 'TV01-460-0154', type: 'pdf', name: 'Roof Detail', code: 'roof-detail' },
          { id: 'TV01-123-6307', type: 'pdf', name: 'Second Complaint', code: 'second-complaint' },
          { id: 'TV01-963-5415', type: 'pdf', name: 'Site Diaries', code: 'site-diaries' },
        ];
      case 'bundles':
        return [
          { id: 'TV01-223-3032', type: 'pdf', name: 'Specification', code: 'specification' },
          {
            id: 'TV01-112-9514',
            type: 'pdf',
            name: 'Terms for Plaintiffs Discovery',
            code: 'terms-for-plaintiffs-discovery',
          },
          {
            id: 'TV01-975-4151',
            type: 'pdf',
            name: 'Visual Inspection Survey',
            code: 'visual-inspection-survey',
          },
          { id: 'TV01-649-9150', type: 'pdf', name: 'Visual Survey', code: 'visual-survey' },
          {
            id: 'TV01-235-9429',
            type: 'pdf',
            name: 'Architects First Instruction',
            code: 'architects-first-instruction',
          },
          {
            id: 'TV01-253-5670',
            type: 'pdf',
            name: 'Certificate of Roof Inspection',
            code: 'certificate-of-roof-inspection',
          },
          { id: 'TV01-544-7066', type: 'json', name: 'data', code: 'data' },
          {
            id: 'TV01-936-1614',
            type: 'pdf',
            name: 'Email re Construction Architect',
            code: 'email-re-construction-architect',
          },
          {
            id: 'TV01-279-4911',
            type: 'pdf',
            name: 'Email with 2nd Spec',
            code: 'email-with-2nd-spec',
          },
          {
            id: 'TV01-179-5941',
            type: 'pdf',
            name: 'First Engineers Certificate',
            code: 'first-engineers-certificate',
          },
          { id: 'TV01-349-9005', type: 'pdf', name: 'Fourth Complaint', code: 'fourth-complaint' },
          { id: 'TV01-232-0161', type: 'pdf', name: 'Memo of Engineer', code: 'memo-of-engineer' },
          {
            id: 'TV01-292-2913',
            type: 'pdf',
            name: 'Original Contract',
            code: 'original-contract',
          },
          {
            id: 'TV01-659-9331',
            type: 'xlsx',
            name: 'Plaintiffs Discovery Load File',
            code: 'plaintiffs-discovery-load-file',
          },
          { id: 'TV01-254-7339', type: 'pdf', name: 'Roof Detail', code: 'roof-detail' },
          { id: 'TV01-049-5170', type: 'pdf', name: 'Second Complaint', code: 'second-complaint' },
          { id: 'TV01-396-1709', type: 'pdf', name: 'Shelving Detail', code: 'shelving-detail' },
          { id: 'TV01-647-0565', type: 'pdf', name: 'Visual Survey', code: 'visual-survey' },
        ];
      default:
        return [
          { id: 'TV01-929-5137', type: '', name: 'Disclosure', code: 'disclosure' },
          {
            id: 'TV01-203-2763',
            type: 'pdf',
            name: 'Architects First Instruction',
            code: 'architects-first-instruction',
          },
          {
            id: 'TV01-917-9731',
            type: 'pdf',
            name: 'Architects Instruction',
            code: 'architects-instruction',
          },
          {
            id: 'TV01-240-2250',
            type: 'pdf',
            name: 'Certificate of Roof Inspection',
            code: 'certificate-of-roof-inspection',
          },
          { id: 'TV01-041-9046', type: 'json', name: 'data', code: 'data' },
          {
            id: 'TV01-699-1391',
            type: 'pdf',
            name: 'Email from Architect',
            code: 'email-from-architect',
          },
          {
            id: 'TV01-762-0062',
            type: 'pdf',
            name: 'Email re Construction Architect',
            code: 'email-re-construction-architect',
          },
          {
            id: 'TV01-609-3911',
            type: 'pdf',
            name: 'Email with 2nd Spec',
            code: 'email-with-2nd-spec',
          },
          { id: 'TV01-004-6465', type: 'pdf', name: 'Email with Spec', code: 'email-with-spec' },
          {
            id: 'TV01-272-7101',
            type: 'pdf',
            name: 'Engineers Certificate',
            code: 'engineers-certificate',
          },
          { id: 'TV01-672-0761', type: 'ps1', name: 'filesToJson', code: 'files-to-json' },
          { id: 'TV01-349-3604', type: 'pdf', name: 'First Complaint', code: 'first-complaint' },
          {
            id: 'TV01-260-0970',
            type: 'pdf',
            name: 'First Engineers Certificate',
            code: 'first-engineers-certificate',
          },
          { id: 'TV01-246-5423', type: 'json', name: 'flatdata', code: 'flatdata' },
          { id: 'TV01-622-1246', type: 'pdf', name: 'Fourth Complaint', code: 'fourth-complaint' },
          {
            id: 'TV01-936-3463',
            type: 'pdf',
            name: 'Further Complaints',
            code: 'further-complaints',
          },
          { id: 'TV01-167-9421', type: 'pdf', name: 'Memo of Engineer', code: 'memo-of-engineer' },
          {
            id: 'TV01-523-9134',
            type: 'pdf',
            name: 'Original Contract',
            code: 'original-contract',
          },
          { id: 'TV01-460-0154', type: 'pdf', name: 'Roof Detail', code: 'roof-detail' },
          { id: 'TV01-123-6307', type: 'pdf', name: 'Second Complaint', code: 'second-complaint' },
          { id: 'TV01-963-5415', type: 'pdf', name: 'Site Diaries', code: 'site-diaries' },
          { id: 'TV01-223-3032', type: 'pdf', name: 'Specification', code: 'specification' },
          {
            id: 'TV01-112-9514',
            type: 'pdf',
            name: 'Terms for Plaintiffs Discovery',
            code: 'terms-for-plaintiffs-discovery',
          },
          {
            id: 'TV01-975-4151',
            type: 'pdf',
            name: 'Visual Inspection Survey',
            code: 'visual-inspection-survey',
          },
          { id: 'TV01-649-9150', type: 'pdf', name: 'Visual Survey', code: 'visual-survey' },
          {
            id: 'TV01-235-9429',
            type: 'pdf',
            name: 'Architects First Instruction',
            code: 'architects-first-instruction',
          },
          {
            id: 'TV01-253-5670',
            type: 'pdf',
            name: 'Certificate of Roof Inspection',
            code: 'certificate-of-roof-inspection',
          },
          { id: 'TV01-544-7066', type: 'json', name: 'data', code: 'data' },
          {
            id: 'TV01-936-1614',
            type: 'pdf',
            name: 'Email re Construction Architect',
            code: 'email-re-construction-architect',
          },
          {
            id: 'TV01-279-4911',
            type: 'pdf',
            name: 'Email with 2nd Spec',
            code: 'email-with-2nd-spec',
          },
          {
            id: 'TV01-179-5941',
            type: 'pdf',
            name: 'First Engineers Certificate',
            code: 'first-engineers-certificate',
          },
          { id: 'TV01-349-9005', type: 'pdf', name: 'Fourth Complaint', code: 'fourth-complaint' },
          { id: 'TV01-232-0161', type: 'pdf', name: 'Memo of Engineer', code: 'memo-of-engineer' },
          {
            id: 'TV01-292-2913',
            type: 'pdf',
            name: 'Original Contract',
            code: 'original-contract',
          },
          {
            id: 'TV01-659-9331',
            type: 'xlsx',
            name: 'Plaintiffs Discovery Load File',
            code: 'plaintiffs-discovery-load-file',
          },
          { id: 'TV01-254-7339', type: 'pdf', name: 'Roof Detail', code: 'roof-detail' },
          { id: 'TV01-049-5170', type: 'pdf', name: 'Second Complaint', code: 'second-complaint' },
          { id: 'TV01-396-1709', type: 'pdf', name: 'Shelving Detail', code: 'shelving-detail' },
          { id: 'TV01-647-0565', type: 'pdf', name: 'Visual Survey', code: 'visual-survey' },
          {
            id: 'TV01-130-9610',
            type: 'pdf',
            name: 'Architects First Instruction',
            code: 'architects-first-instruction',
          },
          {
            id: 'TV01-375-0553',
            type: 'pdf',
            name: 'Architects Instruction',
            code: 'architects-instruction',
          },
          {
            id: 'TV01-555-2659',
            type: 'pdf',
            name: 'Certificate of Roof Inspection',
            code: 'certificate-of-roof-inspection',
          },
          { id: 'TV01-354-0264', type: 'json', name: 'data', code: 'data' },
          {
            id: 'TV01-105-9230',
            type: 'pdf',
            name: 'Email from Architect',
            code: 'email-from-architect',
          },
          {
            id: 'TV01-957-1904',
            type: 'pdf',
            name: 'Email re Construction Architect',
            code: 'email-re-construction-architect',
          },
          {
            id: 'TV01-100-3172',
            type: 'pdf',
            name: 'Email with 2nd Spec',
            code: 'email-with-2nd-spec',
          },
          { id: 'TV01-141-6101', type: 'pdf', name: 'Email with Spec', code: 'email-with-spec' },
          {
            id: 'TV01-740-3493',
            type: 'pdf',
            name: 'Engineers Certificate',
            code: 'engineers-certificate',
          },
          { id: 'TV01-475-5556', type: 'ps1', name: 'filesToJson', code: 'files-to-json' },
          { id: 'TV01-274-7367', type: 'pdf', name: 'First Complaint', code: 'first-complaint' },
          {
            id: 'TV01-032-1267',
            type: 'pdf',
            name: 'First Engineers Certificate',
            code: 'first-engineers-certificate',
          },
          { id: 'TV01-496-1599', type: 'json', name: 'flatdata', code: 'flatdata' },
          { id: 'TV01-536-0027', type: 'pdf', name: 'Fourth Complaint', code: 'fourth-complaint' },
          {
            id: 'TV01-542-1773',
            type: 'pdf',
            name: 'Further Complaints',
            code: 'further-complaints',
          },
          { id: 'TV01-267-4304', type: 'pdf', name: 'Memo of Engineer', code: 'memo-of-engineer' },
          {
            id: 'TV01-740-0722',
            type: 'pdf',
            name: 'Original Contract',
            code: 'original-contract',
          },
          { id: 'TV01-100-0754', type: 'pdf', name: 'Roof Detail', code: 'roof-detail' },
          { id: 'TV01-764-5992', type: 'pdf', name: 'Second Complaint', code: 'second-complaint' },
          { id: 'TV01-397-3391', type: 'pdf', name: 'Site Diaries', code: 'site-diaries' },
          { id: 'TV01-655-3744', type: 'pdf', name: 'Specification', code: 'specification' },
          {
            id: 'TV01-055-7920',
            type: 'pdf',
            name: 'Terms for Plaintiffs Discovery',
            code: 'terms-for-plaintiffs-discovery',
          },
          {
            id: 'TV01-792-1513',
            type: 'pdf',
            name: 'Visual Inspection Survey',
            code: 'visual-inspection-survey',
          },
          { id: 'TV01-465-4362', type: 'pdf', name: 'Visual Survey', code: 'visual-survey' },
        ];
    }
};

const mockedFolders = method => {
  if (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT')
    return {
      id: '5132e2f3-47cc-01f3-0f31-7eb80a3272a8',
      name: 'MOCKED BUNDLE',
      code: 'mocked-bundle',
      type: 'bundle',
      shared: false,
    };
  if (method.toUpperCase() === 'GET')
    return {
      trialbooks: [
        { id: 'ed3b09ec-8e02-46c5-893f-17637b6c84f9', name: 'Pleadings', code: 'pleadings' },
        {
          id: 'f9dc23b2-a6ba-486b-a42f-02e21b34b0fe',
          name: 'Core Documents',
          code: 'core-documents',
          folders: [
            { id: '9e1ab40a-60ce-4381-a4c0-95edf7acdc52', name: 'Motions', code: 'motions' },
            {
              id: '90847b19-136f-4386-906b-e06118dcbc7d',
              name: 'Expert Reports',
              code: 'expert-reports',
            },
            {
              id: '92af98bc-efb0-452e-910d-3b685103c430',
              name: 'Legal Submissions',
              code: 'legal-submissions',
            },
          ],
        },
        { id: '9e1ab40a-60ce-4381-a4c0-95edf7acdc52', name: 'Motions', code: 'motions' },
        {
          id: '90847b19-136f-4386-906b-e06118dcbc7d',
          name: 'Expert Reports',
          code: 'expert-reports',
        },
        {
          id: '92af98bc-efb0-452e-910d-3b685103c430',
          name: 'Legal Submissions',
          code: 'legal-submissions',
        },
        { id: '273c2fe4-c629-4340-a002-9097200a0552', name: 'Discovery', code: 'discovery' },
        { id: '181775d8-680b-402a-8373-1f4b57ea3625', name: 'Transcripts', code: 'transcripts' },
        {
          id: '270855b6-ce56-4676-868b-84d1c2be0c58',
          name: 'Correspondence',
          code: 'correspondence',
        },
      ],
      courtBundles: [
        {
          id: '4de595ee-ec1e-46b6-8b6c-a439cdc819a2',
          name: 'Plaintiff Opening Book',
          code: 'plaintiff-opening-book',
        },
        {
          id: '4de595ee-ec1e-46b6-8b6c-a439cdc819a2',
          name: 'Defendant One Opening Book',
          code: 'defendant-one-opening-book',
        },
        {
          id: '4de595ee-ec1e-46b6-8b6c-a439cdc819a2',
          name: 'Cross Examine Witness 1 - Defendant One',
          code: 'x-exam-wit-1',
        },
        {
          id: '4de595ee-ec1e-46b6-8b6c-a439cdc819a2',
          name: 'Cross Examine Expert 5 - Defendant One',
          code: 'x-exam-exp-5',
        },
      ],
      bundles: [
        {
          id: 'ed3b09ec-8e02-46c5-893f-17637b6c84f9',
          name: 'Private bundle',
          code: 'private-bundle',
        },
        {
          id: 'ed3b09ec-8e02-46c5-893f-17637b6c84f9',
          name: 'Team bundle',
          code: 'team-bundle',
          shared: true,
        },
      ],
    };
};

const mockedCases = [
  {
    id: 'bennett-vs-mfh',
    name: 'Bennett Vs MFH',
    features: {
      privateConference: 'twilio',
      publicConference: 'twilio',
      hearingRoomMode: ['private', 'public'],
    },
    team: {
      id: 'hayes-bennett-moh',
      name: 'Bennett-MOH',
    },
    permissions: {
      documents: {
        upload: true, // true | false only
        download: true, // true | false only
        delete: true, // true | false only
        merge: true,
        print: true,
        updateTeamData: true,
        updatePublicData: [
          // true - all regardless | false - none | [] - all documents only | ['private-bundles'] - all documents and private-bundles, ...
          'trialbooks',
          'my-court-bundles',
          'other-court-bundles',
          'private-bundles',
          'team-bundles',
        ],
        sort: [
          // true - all regardless | false - none | [] - all documents only | ['private-bundles'] - all documents and private-bundles, ...
          'trialbooks',
          'my-court-bundles',
          'other-court-bundles',
          'private-bundles',
          'team-bundles',
        ],
        assign: [
          // true - all regardless | false - none | [] - all documents only | ['private-bundles'] - all documents and private-bundles, ...
          'trialbooks',
          'my-court-bundles',
          'other-court-bundles',
          'private-bundles',
          'team-bundles',
        ],
        unassign: [
          // true - all regardless | false - none | [] - all documents only | ['private-bundles'] - all documents and private-bundles, ...
          'trialbooks',
          'my-court-bundles',
          'other-court-bundles',
          'private-bundles',
          'team-bundles',
        ],
      },
      folders: {
        // true - all folders | false - none | ['private-bundles'] - private-bundles only, ...
        create: ['private-bundles', 'team-bundles', 'my-court-bundles'], // create teamBundles => share create courtBundles => publish
        delete: ['private-bundles', 'team-bundles', 'other-court-bundles'], // delete courtBundles => unpublish
        update: ['private-bundles', 'team-bundles'],
      },
      hearingRoom: {
        // private vs public is hearingRoomMode in feautres, hearingRoomMode: ['public', 'private']
        canRecord: ['private', 'public'], // true - can do it in both | false - can't | [] - can't | ['private'] - only in private, ...
        join: ['private', 'public'],
        shareVideo: ['private', 'public'],
        shareAudio: ['private', 'public'],
        present: ['private', 'public'],
      },
    },
  },
];

export const mockedNotifications = [1, 2, 3, 4, 5, 6, 7, 8];

export const mockedIdToken = {
  idToken: {
    rawIdToken:
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJleHAiOjE1ODAxNTc2MTcsIm5iZiI6MTU4MDE1NDAxNywidmVyIjoiMS4wIiwiaXNzIjoiaHR0cHM6Ly90cmlhbHZpZXdiMmMuYjJjbG9naW4uY29tLzM3MzU3ZjhkLTBjMDctNGJhNC04MGYzLTJkYTU2ZmFhNmQ2OS92Mi4wLyIsInN1YiI6IjA0ZTE5MWQwLTMzNDktNDIwNy1iMTliLTYyNjMyMDFjYmQzMSIsImF1ZCI6ImYwNDg2NTY3LTFkNGQtNGM2My05YzA3LTg1OTkwN2RmMGM4NSIsIm5vbmNlIjoiYjc2MDdmYTctYTc2NC00MzU0LWEyYjgtNjM1ZjhlNDYzM2QxIiwiaWF0IjoxNTgwMTU0MDE3LCJhdXRoX3RpbWUiOjE1ODAxNTQwMTcsIm9pZCI6IjA0ZTE5MWQwLTMzNDktNDIwNy1iMTliLTYyNjMyMDFjYmQzMSIsImdpdmVuX25hbWUiOiJGcmFuayBUViIsImZhbWlseV9uYW1lIjoiQnJvb2tzIiwiZW1haWxzIjpbImZyYW5rYnJvb2tzQHRyaWFsdmlldy5jb20iXSwidGZwIjoiQjJDXzFfc2lnbl91cF9zaWduX2luX3YyIn0.A7BL_6lSc9QFMZKPZbSbzyey0ATVpORLU6x1qDgGWVdpzEfjPs9Fte2ZkcDEKQY2CZG_AQE2cqAMFetqe8OyygLRKaRhLiU8tddzOZmxxs4xF6MTRdQQSyBxfNzsqZFsLNjLKiJMVCk1ASOJEDt_IU0VTi8dVELGIca5nABVTjXnfTWNPJRstUnfR_kbuSnKFErhVrpZ3kHj9ob0r7fNUsjumh-KnFnJI9_ooeiQAsujy8K1b2xtp_b-zV9-1TbELX3XsEmCkmSRIg_dkzfR-SWHhyyPQGrMbDgUo_TJD-W1PFZdwUWu25FJTf6OHrA46DHQMQdiVSkIhDFROfTDaA',
    claims: {
      exp: 1580157617,
      nbf: 1580154017,
      ver: '1.0',
      iss: 'https://trialviewb2c.b2clogin.com/37357f8d-0c07-4ba4-80f3-2da56faa6d69/v2.0/',
      sub: '04e191d0-3349-4207-b19b-6263201cbd31',
      aud: 'f0486567-1d4d-4c63-9c07-859907df0c85',
      nonce: 'b7607fa7-a764-4354-a2b8-635f8e4633d1',
      iat: 1580154017,
      auth_time: 1580154017,
      oid: '04e191d0-3349-4207-b19b-6263201cbd31',
      given_name: 'Frank TV',
      family_name: 'Brooks',
      emails: ['frankbrooks@trialview.com'],
      tfp: 'B2C_1_sign_up_sign_in_v2',
    },
    issuer: 'https://trialviewb2c.b2clogin.com/37357f8d-0c07-4ba4-80f3-2da56faa6d69/v2.0/',
    objectId: '04e191d0-3349-4207-b19b-6263201cbd31',
    subject: '04e191d0-3349-4207-b19b-6263201cbd31',
    version: '1.0',
    nonce: 'b7607fa7-a764-4354-a2b8-635f8e4633d1',
    expiration: 1580157617,
  },
  accountState: '71b47407-36bd-4019-90a5-a113525f826d',
};

export const mockedAccount = {
  accountIdentifier: '04e191d0-3349-4207-b19b-6263201cbd31',
  homeAccountIdentifier:
    'MDRlMTkxZDAtMzM0OS00MjA3LWIxOWItNjI2MzIwMWNiZDMxLWIyY18xX3NpZ25fdXBfc2lnbl9pbl92Mg==.MzczNTdmOGQtMGMwNy00YmE0LTgwZjMtMmRhNTZmYWE2ZDY5',
  idToken: {
    exp: 1580157617,
    nbf: 1580154017,
    ver: '1.0',
    iss: 'https://trialviewb2c.b2clogin.com/37357f8d-0c07-4ba4-80f3-2da56faa6d69/v2.0/',
    sub: '04e191d0-3349-4207-b19b-6263201cbd31',
    aud: 'f0486567-1d4d-4c63-9c07-859907df0c85',
    nonce: 'b7607fa7-a764-4354-a2b8-635f8e4633d1',
    iat: 1580154017,
    auth_time: 1580154017,
    oid: '04e191d0-3349-4207-b19b-6263201cbd31',
    given_name: 'Frank TV',
    family_name: 'Brooks',
    emails: ['frankbrooks@trialview.com'],
    tfp: 'B2C_1_sign_up_sign_in_v2',
  },
  idTokenClaims: {
    exp: 1580157617,
    nbf: 1580154017,
    ver: '1.0',
    iss: 'https://trialviewb2c.b2clogin.com/37357f8d-0c07-4ba4-80f3-2da56faa6d69/v2.0/',
    sub: '04e191d0-3349-4207-b19b-6263201cbd31',
    aud: 'f0486567-1d4d-4c63-9c07-859907df0c85',
    nonce: 'b7607fa7-a764-4354-a2b8-635f8e4633d1',
    iat: 1580154017,
    auth_time: 1580154017,
    oid: '04e191d0-3349-4207-b19b-6263201cbd31',
    given_name: 'Frank TV',
    family_name: 'Brooks',
    emails: ['frankbrooks@trialview.com'],
    tfp: 'B2C_1_sign_up_sign_in_v2',
  },
  environment: 'https://trialviewb2c.b2clogin.com/37357f8d-0c07-4ba4-80f3-2da56faa6d69/v2.0/',
};

export const mockedAccessToken = {
  accessToken:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL3RyaWFsdmlld2IyYy5iMmNsb2dpbi5jb20vMzczNTdmOGQtMGMwNy00YmE0LTgwZjMtMmRhNTZmYWE2ZDY5L3YyLjAvIiwiZXhwIjoxNTgwMTU3NjIyLCJuYmYiOjE1ODAxNTQwMjIsImF1ZCI6Ijc0M2E1ZWJjLTc4OGItNDRkNS1hMjFmLTU2ZjAyYjRmMjhmNiIsIm9pZCI6IjA0ZTE5MWQwLTMzNDktNDIwNy1iMTliLTYyNjMyMDFjYmQzMSIsInN1YiI6IjA0ZTE5MWQwLTMzNDktNDIwNy1iMTliLTYyNjMyMDFjYmQzMSIsImdpdmVuX25hbWUiOiJGcmFuayBUViIsImZhbWlseV9uYW1lIjoiQnJvb2tzIiwiZW1haWxzIjpbImZyYW5rYnJvb2tzQHRyaWFsdmlldy5jb20iXSwidGZwIjoiQjJDXzFfc2lnbl91cF9zaWduX2luX3YyIiwibm9uY2UiOiIxNjEyODcwOC1mNmNjLTRiYzktYmQ4ZS1lYjU4MDNiZmEwMDkiLCJzY3AiOiJjYXNlcyIsImF6cCI6ImYwNDg2NTY3LTFkNGQtNGM2My05YzA3LTg1OTkwN2RmMGM4NSIsInZlciI6IjEuMCIsImlhdCI6MTU4MDE1NDAyMn0.f7_lXWzHUh9UrbEDFLoUqCXUAIIMy37peNu6MZo19AM1oxxHBBcs3Mqzlv-p7E4O33zkSTA2-V86i0ds_tznd1b4WVuC2P9UFyFyRkUSnJi-hWmChBXLHJK9fW4Zu51t7X9BvF_YvYcSTV5aDmBO0U4uw5YC-5O2_YZ_yfTemuZqGJ8RYokFF2DamIfgr9uejwem7RgWKuDUzawb2LKzphFw3xFeJ-73pjcOR7XxuwlDOjbzDqKJm0BUydP_6YPVhW42i-yJViuWVcIBFNhJPwzTHvymS4ZrBoo2tZk1tZwni95QZ47ID8FC3O18AirStG_SFa2EL_QPi5wnelJrfQ',
  expiresOn: '2020-01-27T20:40:23.000Z',
  scopes: ['https://trialviewb2c.onmicrosoft.com/api-dev/cases'],
  accountState: '9cee1753-4131-4889-b2b9-a93c74b96da1',
};

export default (url, method) => {
  Logger.INFO('fallback to mocked data', url);

  switch (true) {
    case new RegExp(
      '/cases/.+/folders/(pleadings|core-documents|motions|expert-reports|legal-submissions|discovery|transcripts|correspondence)/files',
    ).test(url):
      return mockedDocuments(method, 'trialbooks');
    case new RegExp('/cases/.+/folders/.+/files').test(url):
      return mockedDocuments(method, 'bundles');
    case new RegExp('/cases/.+/folders').test(url):
      return mockedFolders(method);
    case new RegExp('cases/.+/files').test(url):
      return mockedDocuments(method);
    case url === '/cases':
      return mockedCases;
    default:
      return { error: 'no mocked data' };
  }
};
