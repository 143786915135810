import React, { Component } from 'react';
import T from 'i18n';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPencilAlt,
  faDoorOpen,
  faShieldAlt,
  faNetworkWired,
  faPhoneAlt,
  faTv,
} from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';

const Pointer = ({ icon, title, text }) => (
  <Col className="pointer">
    <h6>
      <FontAwesomeIcon icon={icon} alt="logo_image" />
      {title}
    </h6>
    <span>{text}</span>
  </Col>
);

export default class Information extends Component {
  static propTypes = {};

  render() {
    const link = T.translate('home.securityTextLinkText');
    return (
      <div className="home-information">
        <Row>
          <Pointer
            icon={faDoorOpen}
            title={T.translate('home.welcomeToTrialview')}
            text={T.translate('home.welcomeToTrialviewText')}
          />
          <Pointer
            icon={faPencilAlt}
            title={T.translate('home.trialPreparation')}
            text={T.translate('home.trialPreparationText')}
          />
          <Pointer
            icon={faTv}
            title={T.translate('home.trialPresentation')}
            text={T.translate('home.trialPresentationText')}
          />
        </Row>
        <Row>
          <Pointer
            icon={faShieldAlt}
            title={T.translate('home.security')}
            text={
              // prettier-ignore
              <Trans i18nKey={'home.securityText'}>
                All documents are securely kept in the TrialView secure rooms in the Cloud. TrialView uses latest HTML5 viewing technology to ensure that documents can be shown, but not shared. Users viewing the documents cannot download, copy or edit without authorisation.​ <br/> TrialView is GDPR Compliant. Click <a style={{ color: 'inherit' }} href={T.translate('home.securityTextLink')} target="_blank" rel="noopener noreferrer">
                  {{link}}
                </a> to see our Security and GDPR Whitepaper which includes our Privacy Policy.​
              </Trans>
            }
          />
          <Pointer
            icon={faNetworkWired}
            title={T.translate('home.deviceToDevice')}
            text={T.translate('home.deviceToDeviceText')}
          />
          <Pointer
            icon={faPhoneAlt}
            title={T.translate('home.support')}
            text={T.translate('home.supportText')}
          />
        </Row>
      </div>
    );
  }
}
