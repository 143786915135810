import {
  CASE_CREATE_PAGE_REF_BEGIN,
  CASE_CREATE_PAGE_REF_SUCCESS,
  CASE_CREATE_PAGE_REF_FAILURE,
  CASE_CREATE_PAGE_REF_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const createPageRef = createPromiseAction(CASE_CREATE_PAGE_REF_BEGIN);

export function dismissCreatePageRefFeedback() {
  return {
    type: CASE_CREATE_PAGE_REF_DISMISS_FEEDBACK,
  };
}

export function* doCreatePageRef(action) {
  const { caseId, globalPage } = action.payload;
  let res = yield call(api.post, `/cases/${caseId}/page-refs`, { globalPage });

  if (res && res.error) {
    yield put({
      type: CASE_CREATE_PAGE_REF_FAILURE,
      //feedback: { message: 'feedback.createPageRefFailure', error: true, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_CREATE_PAGE_REF_SUCCESS,
    data: { pageRef: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchCreatePageRef() {
  yield takeLatest(createPageRef, withCurrentCaseId(doCreatePageRef));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_CREATE_PAGE_REF_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        createPageRefPending: true,
        createPageRefFeedback: null,
      };

    case CASE_CREATE_PAGE_REF_SUCCESS:
      // The request is success
      return {
        ...state,
        createPageRefPending: false,
        createPageRefFeedback: action.feedback,
      };

    case CASE_CREATE_PAGE_REF_FAILURE:
      // The request is failed
      return {
        ...state,
        createPageRefPending: false,
        createPageRefFeedback: action.feedback,
      };

    case CASE_CREATE_PAGE_REF_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        createPageRefFeedback: null,
      };

    default:
      return state;
  }
}
