import React, { Component } from 'react';
import classnames from 'classnames';

export default class CustomViewerMultiActionButton extends Component {
  static propTypes = {};

  state = {
    fabOpen: false,
  };

  render() {
    const down = this.props.direction === 'down';

    const { length, [length - 1]: last } = this.props.children;
    const first = this.props.children[0];

    const newSpecialElement = React.cloneElement(down ? first : last, {
      key: 'specialGuy',
      onMouseEnter: () => this.setState({ fabOpen: true }),
    });

    const newChildren = down
      ? [newSpecialElement, ...this.props.children.slice(1, length)]
      : [...this.props.children.slice(0, length - 1), newSpecialElement];

    return (
      <div className="conference-custom-viewer-multi-action-button">
        <div
          className={classnames(
            this.props.className,
            { open: this.state.fabOpen, down, up: !down },
            'fab-container ',
          )}
          onMouseLeave={() => this.setState({ fabOpen: false })}
        >
          {newChildren}
        </div>
      </div>
    );
  }
}
