// This is the JSON way to define React Router rules in a Rekit app..
// Learn more from: http://rekit.js.org/docs/routing.html
import {
  NothingSelectedPage,
  Layout,
  TableAndViewer,
  View,
  Theatre,
  TheatreDocOnly,
  TsAndCsModal,
  SettingsWrapper,
  CaseAdminLayout,
  TeamAdmin,
  CaseAdmin,
  CaseSettingsPage,
  CaseRoles,
  Transcript,
  DialInNumbersPage,
  HearingRoomLogs,
} from './';
import React from 'react';
import { kebabCaseToRegular } from 'utils/strings';
import T from 'i18n';
import AiToolsResponseWrapper from './AiToolsResponseWrapper';
import GroupAdmin from './GroupAdmin';

export default {
  path: '',
  name: '',
  childRoutes: [
    {
      path: 'case/:case',
      component: TsAndCsModal,
      childRoutes: [
        {
          path: 'settings',
          childRoutes: [
            {
              path: 'role-settings',
              name: 'routes.roleSettings',
              component: props => <SettingsWrapper settingsType="rolesSettings" {...props} />,
            },
            {
              path: 'team-admin',
              name: 'routes.teamAdmin',
              component: props => <SettingsWrapper settingsType="teamAdmin" {...props} />,
            },
            {
              path: 'case-admin',
              name: 'routes.caseAdmin',
              component: CaseAdminLayout,
              childRoutes: [
                {
                  path: '',
                  name: 'routes.caseAdmin',
                  component: CaseAdmin,
                },
                {
                  path: 'case-settings',
                  name: 'routes.caseSettings',
                  component: CaseSettingsPage,
                },
                {
                  path: 'case-roles',
                  name: 'routes.caseRoles',
                  component: CaseRoles,
                },
                {
                  path: 'dial-in-numbers',
                  name: 'routes.dialInNumbers',
                  component: DialInNumbersPage,
                },
                {
                  path: 'hearing-room-logs',
                  name: 'routes.hearingRoomLogs',
                  component: HearingRoomLogs,
                },
                {
                  path: 'all-users',
                  name: 'routes.caseAdmin',
                  component: props => <TeamAdmin isAllUsers={true} {...props} />,
                },
                {
                  path: 'teams/:teamId',
                  name: 'routes.caseAdmin',
                  component: TeamAdmin,
                },
                { path: 'groups/:groupId', name: 'routes.caseAdmin', component: GroupAdmin },
              ],
            },
          ],
        },
        {
          path: 'private-team-room/:type?',
          name: 'routes.teamRoom',
          component: props => <Theatre hearingRoomMode="private" {...props} />,
        },
        {
          path: 'private-team-room-transcript',
          name: 'routes.privateRoomTranscript',
          component: props => <Transcript hearingRoomMode="private" {...props} />,
        },
        {
          path: 'public-hearing-room/document-presentation',
          name: 'routes.evidenceView',
          component: props => <TheatreDocOnly hearingRoomMode="public" {...props} />,
        },
        {
          path: 'public-hearing-room/:type?',
          name: 'routes.hearingRoom',
          component: props => <Theatre hearingRoomMode="public" {...props} />,
        },
        {
          path: 'public-hearing-room-transcript',
          name: 'routes.hearingRoomTranscript',
          component: props => <Transcript hearingRoomMode="public" {...props} />,
          childRoutes: [
            {
              path: 'files/:file',
              name: 'routes.hearingRoomTranscript',
              component: AiToolsResponseWrapper,
            },
          ],
        },
        {
          path: 'private-hearing-room-transcript',
          name: 'routes.privateRoomTranscript',
          component: props => <Transcript hearingRoomMode="private" {...props} />,
        },
        { path: 'files/:file', name: ({ file }) => file, component: View },
        {
          path: '',
          component: Layout,
          childRoutes: [
            { path: '', name: 'routes.nothingSelected', component: NothingSelectedPage },
            {
              path: 'all-documents',
              childRoutes: [
                {
                  path: 'files/:file',
                  component: TableAndViewer,
                  key: 'all-docs',
                  name: ({ file }) => `${T.translate('routes.allDocuments')} - ${file}`,
                },
                {
                  path: '',
                  component: TableAndViewer,
                  key: 'all-docs',
                  name: 'routes.allDocuments',
                },
              ],
            },
            {
              path: 'all-annotations',
              childRoutes: [
                {
                  path: 'files/:file',
                  component: TableAndViewer,
                  key: 'all-annotations',
                  name: ({ file }) => `${T.translate('routes.allAnnotations')} - ${file}`,
                },
                {
                  path: 'files/:file/annotationId/:annotationId',
                  component: TableAndViewer,
                  key: 'all-annotations',
                  name: ({ file }) => `${T.translate('routes.allAnnotations')} - ${file}`,
                },
                {
                  path: '',
                  component: TableAndViewer,
                  key: 'all-annotations',
                  name: 'routes.allAnnotations',
                },
              ],
            },
            {
              path: 'present-mode',
              childRoutes: [
                {
                  path: 'files/:file',
                  component: TableAndViewer,
                  key: 'presentMode',
                  name: ({ file }) => `${T.translate('routes.presentMode')} - ${file}`,
                },
                {
                  path: 'files/:file/compositeKey/:compositeKey',
                  component: TableAndViewer,
                  key: 'presentMode',
                  name: ({ file }) => `${T.translate('routes.presentMode')} - ${file}`,
                },
                {
                  path: '',
                  component: TableAndViewer,
                  key: 'presentMode',
                  name: 'routes.presentMode',
                },
              ],
            },
            {
              path: 'search-mode',
              childRoutes: [
                {
                  path: 'files/:file',
                  component: TableAndViewer,
                  key: 'search',
                  name: ({ file }) => `${T.translate('routes.searchMode')} - ${file}`,
                },
                {
                  path: '',
                  component: TableAndViewer,
                  key: 'search',
                  name: 'routes.searchMode',
                },
              ],
            },
            {
              path: 'ai-tools',
              childRoutes: [
                {
                  path: ':aiSearchId/files/:file',
                  component: TableAndViewer,
                  key: 'aiTools',
                  name: ({ file }) => `${T.translate('routes.aiTools')} - ${file}`,
                },
                {
                  path: ':aiSearchId',
                  component: TableAndViewer,
                  key: 'aiTools',
                  name: 'routes.aiTools',
                },
                {
                  path: 'files/:file',
                  component: TableAndViewer,
                  key: 'aiTools',
                  name: ({ file }) => `${T.translate('routes.aiTools')} - ${file}`,
                },
                {
                  path: '',
                  component: TableAndViewer,
                  key: 'aiTools',
                  name: 'routes.aiTools',
                },
              ],
            },
            {
              path: 'docFinder',
              childRoutes: [
                {
                  path: 'files/:file',
                  component: TableAndViewer,
                  key: 'docFinder',
                  name: ({ file }) => `${T.translate('routes.docFinder')} - ${file}`,
                },
                {
                  path: '',
                  component: TableAndViewer,
                  key: 'docFinder',
                  name: 'routes.docFinder',
                },
              ],
            },
            {
              path: 'page-refs',
              childRoutes: [
                {
                  path: 'files/:file',
                  component: TableAndViewer,
                  key: 'page-refs',
                  name: ({ file }) => `${T.translate('routes.pageRefs')} - ${file}`,
                },
                {
                  path: '',
                  component: TableAndViewer,
                  key: 'page-refs',
                  name: 'routes.pageRefs',
                },
              ],
            },
            {
              path: 'trialbundles',
              childRoutes: [
                {
                  path: ':trialbook/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'trialbooks-folder',
                  name: ({ folder, trialbook }) => kebabCaseToRegular(folder || trialbook),
                },
                {
                  path: ':trialbook/:folder?/files/:file/compositeKey/:compositeKey',
                  component: TableAndViewer,
                  key: 'trialbooks-folder',
                  name: ({ folder, trialbook }) => kebabCaseToRegular(folder || trialbook),
                },
                {
                  path: ':trialbook/:folder/:folder1?/files/:file',
                  component: TableAndViewer,
                  key: 'trialbooks-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook/:folder/:folder1?/files/:file/compositeKey/:compositeKey',
                  component: TableAndViewer,
                  key: 'trialbooks-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook/:folder/:folder1/:folder2?/files/:file',
                  component: TableAndViewer,
                  key: 'trialbooks-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path:
                    ':trialbook/:folder/:folder1/:folder2?/files/:file/compositeKey/:compositeKey',
                  component: TableAndViewer,
                  key: 'trialbooks-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook/:folder/:folder1/:folder2/:folder3?/files/:file',
                  component: TableAndViewer,
                  key: 'trialbooks-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path:
                    ':trialbook/:folder/:folder1/:folder2/:folder3?/files/:file/compositeKey/:compositeKey',
                  component: TableAndViewer,
                  key: 'trialbooks-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook/:folder/:folder1/:folder2/:folder3/:folder4?/files/:file',
                  component: TableAndViewer,
                  key: 'trialbooks-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path:
                    ':trialbook/:folder/:folder1/:folder2/:folder3/:folder4?/files/:file/compositeKey/:compositeKey',
                  component: TableAndViewer,
                  key: 'trialbooks-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook',
                  component: TableAndViewer,
                  key: 'trialbooks-folder',
                  name: ({ folder, trialbook }) => kebabCaseToRegular(folder || trialbook),
                },
                {
                  path: ':trialbook/:folder',
                  component: TableAndViewer,
                  key: 'trialbooks-folder',
                  name: ({ folder, trialbook }) => kebabCaseToRegular(folder || trialbook),
                },
                {
                  path: ':trialbook/:folder/:folder1',
                  component: TableAndViewer,
                  key: 'trialbooks-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook/:folder/:folder1/:folder2',
                  component: TableAndViewer,
                  key: 'trialbooks-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook/:folder/:folder1/:folder2/:folder3',
                  component: TableAndViewer,
                  key: 'trialbooks-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':trialbook/:folder/:folder1/:folder2/:folder3/:folder4',
                  component: TableAndViewer,
                  key: 'trialbooks-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
              ],
            },
            {
              path: 'bundles',
              name: 'routes.bundles',
              childRoutes: [
                {
                  path: ':bundle/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'bundle-folder',
                  name: ({ folder, bundle }) => kebabCaseToRegular(folder || bundle),
                },
                {
                  path: ':bundle/:folder/:folder1?/files/:file',
                  component: TableAndViewer,
                  key: 'bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':bundle/:folder/:folder1/:folder2?/files/:file',
                  component: TableAndViewer,
                  key: 'bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':bundle/:folder/:folder1/:folder2/:folder3?/files/:file',
                  component: TableAndViewer,
                  key: 'bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':bundle/:folder/:folder1/:folder2/:folder3/:folder4?/files/:file',
                  component: TableAndViewer,
                  key: 'bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':bundle',
                  component: TableAndViewer,
                  key: 'bundle-folder',
                  name: ({ folder, bundle }) => kebabCaseToRegular(folder || bundle),
                },
                {
                  path: ':bundle/:folder',
                  component: TableAndViewer,
                  key: 'bundle-folder',
                  name: ({ folder, bundle }) => kebabCaseToRegular(folder || bundle),
                },
                {
                  path: ':bundle/:folder/:folder1',
                  component: TableAndViewer,
                  key: 'bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':bundle/:folder/:folder1/:folder2',
                  component: TableAndViewer,
                  key: 'bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':bundle/:folder/:folder1/:folder2/:folder3',
                  component: TableAndViewer,
                  key: 'bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':bundle/:folder/:folder1/:folder2/:folder3/:folder4',
                  component: TableAndViewer,
                  key: 'bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
              ],
            },
            {
              path: 'teamBundles',
              name: 'routes.teamBundles',
              childRoutes: [
                {
                  path: ':teamBundle/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'team-bundle-folder',
                  name: ({ folder, teamBundle }) => kebabCaseToRegular(folder || teamBundle),
                },
                {
                  path: ':teamBundle/:folder/:folder1?/files/:file',
                  component: TableAndViewer,
                  key: 'team-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':teamBundle/:folder/:folder1/:folder2?/files/:file',
                  component: TableAndViewer,
                  key: 'team-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':teamBundle/:folder/:folder1/:folder2/:folder3?/files/:file',
                  component: TableAndViewer,
                  key: 'team-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':teamBundle/:folder/:folder1/:folder2/:folder3/:folder4?/files/:file',
                  component: TableAndViewer,
                  key: 'team-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':teamBundle',
                  component: TableAndViewer,
                  key: 'team-bundle-folder',
                  name: ({ folder, teamBundle }) => kebabCaseToRegular(folder || teamBundle),
                },
                {
                  path: ':teamBundle/:folder',
                  component: TableAndViewer,
                  key: 'team-bundle-folder',
                  name: ({ folder, teamBundle }) => kebabCaseToRegular(folder || teamBundle),
                },
                {
                  path: ':teamBundle/:folder/:folder1',
                  component: TableAndViewer,
                  key: 'team-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':teamBundle/:folder/:folder1/:folder2',
                  component: TableAndViewer,
                  key: 'team-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':teamBundle/:folder/:folder1/:folder2/:folder3',
                  component: TableAndViewer,
                  key: 'team-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':teamBundle/:folder/:folder1/:folder2/:folder3/:folder4',
                  component: TableAndViewer,
                  key: 'team-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
              ],
            },
            {
              path: 'privateBundles',
              name: 'routes.privateBundles',
              childRoutes: [
                {
                  path: ':privateBundle/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'private-bundle-folder',
                  name: ({ folder, privateBundle }) => kebabCaseToRegular(folder || privateBundle),
                },
                {
                  path: ':privateBundle/:folder/:folder1?/files/:file',
                  component: TableAndViewer,
                  key: 'private-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':privateBundle/:folder/:folder1/:folder2?/files/:file',
                  component: TableAndViewer,
                  key: 'private-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':privateBundle/:folder/:folder1/:folder2/:folder3?/files/:file',
                  component: TableAndViewer,
                  key: 'private-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':privateBundle/:folder/:folder1/:folder2/:folder3/:folder4?/files/:file',
                  component: TableAndViewer,
                  key: 'private-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':privateBundle',
                  component: TableAndViewer,
                  key: 'private-bundle-folder',
                  name: ({ folder, privateBundle }) => kebabCaseToRegular(folder || privateBundle),
                },
                {
                  path: ':privateBundle/:folder',
                  component: TableAndViewer,
                  key: 'private-bundle-folder',
                  name: ({ folder, privateBundle }) => kebabCaseToRegular(folder || privateBundle),
                },
                {
                  path: ':privateBundle/:folder/:folder1',
                  component: TableAndViewer,
                  key: 'private-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':privateBundle/:folder/:folder1/:folder2',
                  component: TableAndViewer,
                  key: 'private-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':privateBundle/:folder/:folder1/:folder2/:folder3',
                  component: TableAndViewer,
                  key: 'private-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':privateBundle/:folder/:folder1/:folder2/:folder3/:folder4',
                  component: TableAndViewer,
                  key: 'private-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
              ],
            },
            {
              path: 'court-bundles',
              name: 'routes.courtBundles',
              childRoutes: [
                {
                  path: ':courtBundle/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'court-bundle-folder',
                  name: ({ folder, courtBundle }) => kebabCaseToRegular(folder || courtBundle),
                },
                {
                  path: ':courtBundle/:folder/:folder1?/files/:file',
                  component: TableAndViewer,
                  key: 'court-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':courtBundle/:folder/:folder1/:folder2?/files/:file',
                  component: TableAndViewer,
                  key: 'court-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':courtBundle/:folder/:folder1/:folder2/:folder3?/files/:file',
                  component: TableAndViewer,
                  key: 'court-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':courtBundle/:folder/:folder1/:folder2/:folder3/:folder4?/files/:file',
                  component: TableAndViewer,
                  key: 'court-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':courtBundle',
                  component: TableAndViewer,
                  key: 'court-bundle-folder',
                  name: ({ folder, courtBundle }) => kebabCaseToRegular(folder || courtBundle),
                },
                {
                  path: ':courtBundle/:folder',
                  component: TableAndViewer,
                  key: 'court-bundle-folder',
                  name: ({ folder, courtBundle }) => kebabCaseToRegular(folder || courtBundle),
                },
                {
                  path: ':courtBundle/:folder/:folder1',
                  component: TableAndViewer,
                  key: 'court-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':courtBundle/:folder/:folder1/:folder2',
                  component: TableAndViewer,
                  key: 'court-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':courtBundle/:folder/:folder1/:folder2/:folder3',
                  component: TableAndViewer,
                  key: 'court-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':courtBundle/:folder/:folder1/:folder2/:folder3/:folder4',
                  component: TableAndViewer,
                  key: 'court-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
              ],
            },
            {
              path: 'group-bundles',
              name: 'routes.groupBundles',
              childRoutes: [
                {
                  path: ':groupBundle/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'group-bundle-folder',
                  name: ({ folder, groupBundle }) => kebabCaseToRegular(folder || groupBundle),
                },
                {
                  path: ':groupBundle/:folder/:folder1?/files/:file',
                  component: TableAndViewer,
                  key: 'group-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':groupBundle/:folder/:folder1/:folder2?/files/:file',
                  component: TableAndViewer,
                  key: 'group-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':groupBundle/:folder/:folder1/:folder2/:folder3?/files/:file',
                  component: TableAndViewer,
                  key: 'group-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':groupBundle/:folder/:folder1/:folder2/:folder3/:folder4?/files/:file',
                  component: TableAndViewer,
                  key: 'group-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':groupBundle',
                  component: TableAndViewer,
                  key: 'group-bundle-folder',
                  name: ({ folder, groupBundle }) => kebabCaseToRegular(folder || groupBundle),
                },
                {
                  path: ':groupBundle/:folder',
                  component: TableAndViewer,
                  key: 'group-bundle-folder',
                  name: ({ folder, groupBundle }) => kebabCaseToRegular(folder || groupBundle),
                },
                {
                  path: ':groupBundle/:folder/:folder1',
                  component: TableAndViewer,
                  key: 'group-bundle-folder1',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':groupBundle/:folder/:folder1/:folder2',
                  component: TableAndViewer,
                  key: 'group-bundle-folder2',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':groupBundle/:folder/:folder1/:folder2/:folder3',
                  component: TableAndViewer,
                  key: 'group-bundle-folder3',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
                {
                  path: ':groupBundle/:folder/:folder1/:folder2/:folder3/:folder4',
                  component: TableAndViewer,
                  key: 'group-bundle-folder4',
                  name: ({ folder }) => kebabCaseToRegular(folder),
                },
              ],
            },
            {
              path: 'quick-links',
              name: 'routes.quickLinks',
              childRoutes: [
                {
                  path: ':quickLink/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'quick-links-folder',
                  name: ({ folder, quickLink }) => kebabCaseToRegular(folder || quickLink),
                },
                {
                  path: ':quickLink/:folder1/:folder?/files/:file',
                  component: TableAndViewer,
                  key: 'quick-links-folder',
                  name: ({ folder, quickLink }) => kebabCaseToRegular(folder || quickLink),
                },
                {
                  path: ':quickLink/:folder?/files/:file/annotationId/:annotationId',
                  component: TableAndViewer,
                  key: 'quick-links-folder',
                  name: ({ folder, quickLink }) => kebabCaseToRegular(folder || quickLink),
                },
                {
                  path: ':quickLink/:folder1/:folder?/files/:file/annotationId/:annotationId',
                  component: TableAndViewer,
                  key: 'quick-links-folder',
                  name: ({ folder, quickLink }) => kebabCaseToRegular(folder || quickLink),
                },
                {
                  path: ':quickLink/:folder?',
                  component: TableAndViewer,
                  key: 'quick-links-folder',
                  name: ({ folder, quickLink }) => kebabCaseToRegular(folder || quickLink),
                },
                {
                  path: ':quickLink/:folder1/:folder?',
                  component: TableAndViewer,
                  key: 'quick-links-folder',
                  name: ({ folder, quickLink }) => kebabCaseToRegular(folder || quickLink),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
