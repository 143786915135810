import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FiltersStyles } from './FiltersStyles.js';
import FilterNameChip from 'features/case/TableWrapper/FilterNameChip';
import { Modal } from '@mui/base';
import { Box } from '@mui/system';
import { useBookmarks } from '../redux/bookmarks.js';

export default ({
  isAnnotation,
  setFilters,
  containerRef,
}: {
  isAnnotation: boolean;
  setFilters: (filterCategory: any) => void;
  containerRef: React.RefObject<HTMLDivElement>;
}) => {
  const { bookmarks, deleteBookmark, fetchBookmarks } = useBookmarks(
    isAnnotation ? 'annotations' : 'documents',
  );
  const [areYouSureDelete, setAreYouSureDelete] = useState(null);
  const classes = FiltersStyles();

  useEffect(() => {
    fetchBookmarks();
    // ON MOUNT
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return bookmarks.length > 0 ? (
    <>
      <Divider />
      <PerfectScrollbar
        options={{ suppressScrollY: true }}
        style={{ display: 'flex', flexDirection: 'row', padding: '0.25rem' }}
      >
        {bookmarks.map((bookmark: any) => {
          return (
            <FilterNameChip
              idx={`chip-bookmark-${bookmark.name}`}
              label={bookmark.name}
              deleteHandler={() => setAreYouSureDelete(bookmark.id)}
              clickHandler={() => setFilters(bookmark.filterCategory)}
            />
          );
        })}
      </PerfectScrollbar>
      <Modal open={!!areYouSureDelete} container={containerRef.current}>
        <div className={classes.modalContainer}>
          <Box className={classes.modal}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                textAlign: 'center',
              }}
            >
              {T.translate('filters.areYouSureDelete')}
            </Typography>

            <div
              style={{ width: '100%', padding: '1rem', display: 'flex', justifyContent: 'center' }}
            >
              <Button
                style={{ margin: '0.2rem', background: '#4567e5', color: 'white' }}
                onClick={() => {
                  deleteBookmark({ id: areYouSureDelete });
                  setAreYouSureDelete(null);
                }}
              >
                {T.translate('generic.yes')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setAreYouSureDelete(null);
                }}
                style={{ margin: '0.2rem', borderColor: '#4567e5' }}
              >
                {T.translate('generic.no')}
              </Button>
            </div>
          </Box>
        </div>
      </Modal>
    </>
  ) : null;
};
