import {
  CASE_FETCH_PAGE_REFS_BEGIN,
  CASE_FETCH_PAGE_REFS_SUCCESS,
  CASE_FETCH_PAGE_REFS_FAILURE,
  CASE_FETCH_PAGE_REFS_DISMISS_FEEDBACK,
  CASE_FETCH_PAGE_REFS_CLEAR_ANNOTATIONS,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchPageRefs = createPromiseAction(CASE_FETCH_PAGE_REFS_BEGIN);

export function dismissFetchPageRefsFeedback() {
  return {
    type: CASE_FETCH_PAGE_REFS_DISMISS_FEEDBACK,
  };
}

export function clearPageRefs() {
  return {
    type: CASE_FETCH_PAGE_REFS_CLEAR_ANNOTATIONS,
  };
}

export function* doFetchPageRefs(action) {
  const { caseId, originalGP } = action.payload;
  let res = yield call(api.get, `/cases/${caseId}/page-refs`, originalGP);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_PAGE_REFS_FAILURE,
      feedback: { message: 'feedback.fetchPageRefsFailure', error: true, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_PAGE_REFS_SUCCESS,
    data: { pageRefs: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchPageRefs() {
  yield takeLatest(fetchPageRefs, withCurrentCaseId(doFetchPageRefs));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_PAGE_REFS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchPageRefsPending: true,
        fetchPageRefsFeedback: null,
      };

    case CASE_FETCH_PAGE_REFS_CLEAR_ANNOTATIONS:
      return {
        ...state,
        pageRefs: [],
      };

    case CASE_FETCH_PAGE_REFS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchPageRefsPending: false,
        fetchPageRefsFeedback: action.feedback,
        pageRefs: action.data.pageRefs,
      };

    case CASE_FETCH_PAGE_REFS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchPageRefsPending: false,
        fetchPageRefsFeedback: action.feedback,
      };

    case CASE_FETCH_PAGE_REFS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchPageRefsFeedback: null,
      };

    default:
      return state;
  }
}
