import moment from 'moment';

export const groupDataByDate = data => {
  const groups = {
    today: [],
    yesterday: [],
    lastWeek: [],
    lastMonth: [],
    older: [],
  };

  data.forEach(item => {
    const itemDate = moment(item.createdBy.date);
    const today = moment().startOf('day');
    const yesterday = moment()
      .subtract(1, 'days')
      .startOf('day');
    const lastWeek = moment()
      .subtract(7, 'days')
      .startOf('day');
    const lastMonth = moment()
      .subtract(1, 'month')
      .startOf('day');

    if (itemDate.isSame(today, 'd')) {
      groups.today.push(item);
    } else if (itemDate.isBetween(yesterday, today, 'd', '[)')) {
      groups.yesterday.push(item);
    } else if (itemDate.isBetween(lastWeek, yesterday, 'd', '[)')) {
      groups.lastWeek.push(item);
    } else if (itemDate.isBetween(lastMonth, lastWeek, 'd', '[)')) {
      groups.lastMonth.push(item);
    } else {
      groups.older.push(item);
    }
  });

  return groups;
};

export const groupByDateLabel = key => {
  const labels = {
    today: 'Today',
    yesterday: 'Yesterday',
    lastWeek: 'Last Week',
    lastMonth: 'Last Month',
    older: 'Older',
  };
  return labels[key];
};
