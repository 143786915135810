import { CASE_UPDATE_DOCUMENT_SORT_SELECTION } from './constants';

//This method is use to disable the performed sort action on selected column (e.g. if 'asc' is performed on id col then disable 'asc' action on that column)
export function modifyDocumentSortSelection({ sortByColumn, sortDirection }) {
  return {
    type: CASE_UPDATE_DOCUMENT_SORT_SELECTION,
    payload: { sortByColumn, sortDirection },
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_DOCUMENT_SORT_SELECTION:
      return {
        ...state,
        sortByColumn: action.payload.sortByColumn,
        sortDirection: action.payload.sortDirection,
      };
    default:
      return state;
  }
}
