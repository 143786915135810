import React from 'react';
import { isValidKey } from 'utils/keyboard';
import { transformDate } from './transformDateTime';
import TextForm from '../TextForm';
import TextFormMui from 'features/common/MaterialBasedComponents/TextForm';

export default React.forwardRef(
  ({ value, errors, autofocus, label, placeholder, mui, ...props }: any, ref) => {
    return mui ? (
      <TextFormMui
        {...props}
        ref={ref}
        onChange={(e: any) => {
          return props.onChange({
            ...e,
            target: { ...e.target, value: transformDate(e.target.value, value) },
          });
        }}
        label={label}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e: any) => isValidKey(e)}
        errors={errors}
        autofocus={autofocus}
      />
    ) : (
      <TextForm
        {...props}
        ref={ref}
        onChange={(e: any) => {
          return props.onChange({
            ...e,
            target: { ...e.target, value: transformDate(e.target.value, value) },
          });
        }}
        label={label}
        placeholder={placeholder}
        value={value}
        onKeyDown={(e: any) => isValidKey(e)}
        errors={errors}
        autofocus={autofocus}
      />
    );
  },
);
