import React, { useState } from 'react';
import T from 'i18n';
import classnames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from '../common/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import PopoverStickOnHover from 'features/common/PopoverStickOnHover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStickyNote,
  faUserCheck,
  faExclamationTriangle,
  faLaptopCode,
  faCheck,
  faExclamationCircle,
  faTimes,
  faCircle,
  faScroll,
} from '@fortawesome/free-solid-svg-icons';
import SortHeader from './TableCase/SortHeader';
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import { green, pink, deepOrange, deepPurple } from '@mui/material/colors';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoTwoRows from 'features/common/InfoTwoRows';
import { getHighlightedText } from 'utils/highlightText';
import { fetchRolesByType } from 'features/common/redux/fetchRolesByType';
import { teamAdminModal } from './enums';
import useSearchContext from 'features/common/Search/useSearchContext';
import { diff } from 'deep-object-diff';
import {
  selectConfidentialityRing,
  selectCurrentUserConfidentialityRing,
  selectCustomDataRoomRoles,
  selectCustomHearingRoomRoles,
  selectDataRoomRoles,
  selectHearingRoomRoles,
} from 'common/selectors';
import { cfRingColorMapping } from './TableCase/TableInfo';
import FilterProvider from './TableCase/FilterProvider';

const useStyles = makeStyles(theme => ({
  avatarSize: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.9rem',
  },
  pending: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  inActive: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  declined: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  accepted: {
    color: '#fff',
    backgroundColor: green[500],
  },
}));

const UserInfo = ({ user, handleSystemTestShow }) => {
  const currentUserCR = useSelector(selectCurrentUserConfidentialityRing);
  const isConfidentialityRing =
    useSelector(selectConfidentialityRing) && currentUserCR !== 'noRing';
  const {
    teamInviteStatus,
    notes,
    wifiTest,
    hasDiagnostics,
    diagnostics,
    confidentialityRing,
    canViewRealTime,
  } = user;
  const cfRingTypeMapping = {
    noRing: 'No Ring',
    outerRing: 'Outer Ring',
    innerRing: 'Inner Ring',
  };
  return (
    <Row style={{ width: '100%', marginRight: '-2px', marginLeft: '-2px' }} className="customGrid">
      <Col
        style={{
          paddingRight: '2px',
          paddingLeft: '2px',
        }}
      >
        <PopoverStickOnHover
          component={
            <div>
              <Popover.Title as="h4">{T.translate('case.inviteStatus')}</Popover.Title>
              <Popover.Content>{teamInviteStatus}</Popover.Content>
            </div>
          }
          placement="left"
          onMouseEnter={() => {}}
          delay={{ show: 250, hide: 400 }}
          style={{ maxWidth: '350px', fontSize: '0.75rem' }}
        >
          <div style={{ height: '12px', width: '12px', paddingRight: 3 }}>
            {teamInviteStatus === 'accepted' ? (
              <FontAwesomeIcon
                style={{ color: '#4caf50' }}
                icon={faUserCheck}
                alt={faUserCheck.iconName}
              />
            ) : (
              <FontAwesomeIcon
                style={{ color: 'orange' }}
                icon={faExclamationTriangle}
                alt={faExclamationTriangle.iconName}
              />
            )}
          </div>
        </PopoverStickOnHover>
      </Col>
      <Col
        style={{
          paddingRight: '2px',
          paddingLeft: '2px',
        }}
      >
        <div style={{ height: '12px', width: '12px' }}>
          {canViewRealTime && (
            <FontAwesomeIcon color="#3F5AC0" icon={faScroll} alt={faScroll.iconName} />
          )}
        </div>
      </Col>
      {isConfidentialityRing && !confidentialityRing && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
          }}
        >
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover>
                <React.Fragment>
                  <Popover.Content>{cfRingTypeMapping[confidentialityRing]}</Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon
              style={{
                color: cfRingColorMapping[confidentialityRing],
                stroke: 'black',
                strokeWidth: '10',
              }}
              icon={faCircle}
              alt={faStickyNote.iconName}
            />
          </OverlayTrigger>
        </Col>
      )}
      {notes && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ width: '350px', maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Content>
                    {notes && (
                      <FilterProvider>
                        <InfoTwoRows field={notes} title={'case.notes'} />
                      </FilterProvider>
                    )}
                  </Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <FontAwesomeIcon
              style={{ color: 'yellow', stroke: 'black', strokeWidth: '10' }}
              icon={faStickyNote}
              alt={faStickyNote.iconName}
            />
          </OverlayTrigger>
        </Col>
      )}
      {wifiTest && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
          }}
        >
          <OverlayTrigger
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Popover style={{ maxWidth: '350px', fontSize: '0.75rem' }}>
                <React.Fragment>
                  <Popover.Title as="h4">{T.translate('case.wifiTest')}</Popover.Title>
                  <Popover.Content>{wifiTest ? 'Completed' : 'Pending'}</Popover.Content>
                </React.Fragment>
              </Popover>
            }
          >
            <NetworkCheckIcon style={{ color: '#6C757C' }} />
          </OverlayTrigger>
        </Col>
      )}
      {(hasDiagnostics || diagnostics) && (
        <Col
          style={{
            paddingRight: '2px',
            paddingLeft: '2px',
          }}
        >
          <PopoverStickOnHover
            component={
              <div>
                <Popover.Title as="h4">
                  {diagnostics
                    ? T.translate('generic.systemTestStatus', { status: diagnostics })
                    : T.translate('generic.systemTestResult')}
                </Popover.Title>
                <Popover.Content>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => handleSystemTestShow(user, teamAdminModal.systemTestResult)}
                  >
                    {T.translate('generic.view')}
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    size="small"
                    onClick={() =>
                      handleSystemTestShow(user, teamAdminModal.removeSystemTestResult)
                    }
                  >
                    {T.translate('generic.remove')}
                  </Button>
                </Popover.Content>
              </div>
            }
            placement="left"
            onMouseEnter={() => {}}
            delay={{ show: 250, hide: 400 }}
            style={{ maxWidth: '350px', fontSize: '0.75rem' }}
          >
            <div style={{ height: '12px', width: '12px' }}>
              <FontAwesomeIcon
                style={{ color: '#6C757C', stroke: 'black', strokeWidth: '10' }}
                icon={faLaptopCode}
                alt={faLaptopCode.iconName}
                transform="shrink-1"
              />
              {diagnostics && (
                <FontAwesomeIcon
                  style={
                    diagnostics === 'pass'
                      ? { color: '#4caf50' }
                      : diagnostics === 'fail'
                      ? { color: '#FF0000' }
                      : { color: '#FFA500' }
                  }
                  icon={
                    diagnostics === 'pass'
                      ? faCheck
                      : diagnostics === 'fail'
                      ? faTimes
                      : faExclamationCircle
                  }
                  transform="shrink-4 up-32 right-14"
                />
              )}
            </div>
          </PopoverStickOnHover>
        </Col>
      )}
    </Row>
  );
};

const getRoleDetails = (selectedConference, roleId, roles = []) => {
  const getFlatOptions = options =>
    options.reduce(
      (acc, item) => (item.options ? [...acc, ...getFlatOptions(item.options)] : [...acc, item]),
      [],
    );
  const selectedRole = getFlatOptions(roles).find(item => item.id === roleId);
  if (selectedRole) return `${selectedRole.name} - ${selectedRole.description}`;
  else if (selectedConference) return roleId;
  else return T.translate('case.showDefaultHiddenRole');
};

const columnSelector = (
  handleSystemTestShow,
  classes,
  isAllUsers,
  dataRoomRoles,
  hearingRoomRoles,
  selectedConference,
  filterVal,
) => {
  const getInitials = val => {
    const names = val.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  return [
    {
      Header: () => <SortHeader text="case.name" />,
      id: 'name',
      Cell: user => (
        <div className="customGrid">
          {user.name && (
            <Avatar className={classnames(classes.avatarSize, classes[user.teamInviteStatus])}>
              {getInitials(user.name)}
            </Avatar>
          )}
          <span
            style={{ marginLeft: '0.5rem' }}
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(user.name, filterVal.term),
            }}
          ></span>
        </div>
      ),

      minWidth: '230px',
      width: '22rem',
    },
    {
      id: 'mail',
      Header: () => <SortHeader text="case.emailAddress" />,
      Cell: user => (
        <div className="customGrid">
          <span
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(user.mail, filterVal.term),
            }}
          ></span>
        </div>
      ),
      minWidth: '240px',
      width: '23rem',
    },
    {
      id: 'dataRoomRole',
      Header: () => <SortHeader text={'case.dataRoomRole'} />,
      Cell: user => {
        return (
          <div className="customGrid">
            <span
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(
                  getRoleDetails(selectedConference, user.dataRoomRole, dataRoomRoles),
                  filterVal.term,
                ),
              }}
            ></span>
          </div>
        );
      },
      minWidth: '200px',
      width: `calc(100% - ${(selectedConference && isAllUsers ? 19 : 0) +
        (selectedConference ? 117 : 57)}rem)`,
    },
    selectedConference && {
      id: 'hearingRoomRole',
      Header: () => <SortHeader text="case.hearingRoomRole" />,
      Cell: user => (
        <div className="customGrid">
          <span
            dangerouslySetInnerHTML={{
              __html: `${user.startInWaitingRoom ? '* ' : ''}${getRoleDetails(
                selectedConference,
                user.hearingRoomRole,
                hearingRoomRoles,
              )}${
                user.startInWaitingRoom
                  ? `, ${T.translate('case.startInWaitingRoom').toLowerCase()}`
                  : ''
              }`,
            }}
          ></span>
        </div>
      ),
      minWidth: '210px',
      width: '21rem',
    },
    selectedConference &&
      isAllUsers && {
        id: 'positionId',
        Header: () => <SortHeader text="case.hearingRoomPosition" />,
        Cell: user => (
          <div className="customGrid">
            <span
              className="positionColumn"
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(user.positionId, filterVal.term),
              }}
            ></span>
          </div>
        ),
        minWidth: '200px',
        width: '19rem',
      },
    selectedConference && {
      id: 'hearingRoomName',
      Header: () => <SortHeader text="case.hearingRoomName" />,
      Cell: user => (
        <div className="customGrid">
          <span
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(user.hearingRoomName, filterVal.term),
            }}
          ></span>
        </div>
      ),
      minWidth: '210px',
      width: '21rem',
    },
    selectedConference && {
      id: 'hearingRoomTitle',
      Header: () => <SortHeader text="case.hearingRoomTitle" />,
      Cell: user => (
        <div className="customGrid">
          <span
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(user.hearingRoomTitle, filterVal.term),
            }}
          ></span>
        </div>
      ),
      minWidth: '170px',
      width: '17rem',
    },
    {
      Header: 'case.info',
      Cell: user => <UserInfo user={user} handleSystemTestShow={handleSystemTestShow} />,
      id: 'info',
      minWidth: '130px',
      width: '12rem',
      onCustomSort: (
        a,
        b,
        { teamInviteStatus: teamInviteStatusA, notes: notesA, ...rest },
        { teamInviteStatus: teamInviteStatusB, notes: notesB },
      ) => {
        let comparison = 0;
        if (teamInviteStatusA) ++comparison;
        if (teamInviteStatusB) --comparison;
        if (notesA) ++comparison;
        if (notesB) --comparison;
        return comparison;
      },
    },
  ].filter(Boolean);
};

const getFilterByColumns = (isAllUsers, selectedConference, dataRoomRoles, hearingRoomRoles) => {
  return [
    'name',
    'mail',
    {
      id: 'dataRoomRole',
      accessor: row =>
        row.dataRoomRole && getRoleDetails(selectedConference, row.dataRoomRole, dataRoomRoles),
    },
    selectedConference && {
      id: 'hearingRoomRole',
      accessor: row =>
        row.hearingRoomRole &&
        getRoleDetails(selectedConference, row.hearingRoomRole, hearingRoomRoles),
    },
    'hearingRoomName',
    'hearingRoomTitle',
    selectedConference && isAllUsers && 'positionId',
  ].filter(Boolean);
};

const TeamAdminTableCase = React.memo(
  ({ onRowSelected, users, isAllUsers, selectedConference, handleSystemTestShow }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { searchHandler, searchFunctionsHandler, clearSearch } = useSearchContext();
    const customDataRoomRoles = useSelector(selectCustomDataRoomRoles);
    const customHearingRoomRoles = useSelector(selectCustomHearingRoomRoles);
    const hearingRoomRoles = useSelector(selectHearingRoomRoles);
    const dataRoomRoles = useSelector(selectDataRoomRoles);
    const [filterVal, setFilterVal] = useState({ term: '' });
    const columns = columnSelector(
      handleSystemTestShow,
      classes,
      isAllUsers,
      dataRoomRoles,
      hearingRoomRoles,
      selectedConference,
      filterVal,
    );
    const filterByColumns = getFilterByColumns(
      isAllUsers,
      selectedConference,
      dataRoomRoles,
      hearingRoomRoles,
    );
    const filterRows = term => setFilterVal({ term: term ? term : '' });
    const isItemLoaded = ({ index }) => !!users[index];
    const loadMoreItems = () => {
      return;
    };

    useEffect(() => {
      if (typeof selectedConference !== 'undefined' && !hearingRoomRoles && !dataRoomRoles) {
        dispatch(
          fetchRolesByType({
            type: 'hearingRoomRole',
            groupRoleByClass: true,
            includePermissions: false,
            customRoles: customHearingRoomRoles,
          }),
        );
        dispatch(
          fetchRolesByType({
            type: 'dataRoomRole',
            groupRoleByClass: true,
            includePermissions: false,
            customRoles: customDataRoomRoles,
          }),
        );
      }
    }, [
      selectedConference,
      dispatch,
      customHearingRoomRoles,
      customDataRoomRoles,
      hearingRoomRoles,
      dataRoomRoles,
    ]);

    useEffect(() => {
      if (clearSearch !== null && typeof clearSearch === 'function') {
        clearSearch();
      }
      searchHandler &&
        searchHandler({
          isReady: true,
          hideFilterOptions: true,
          placeholder: T.translate('case.filter'),
        });
      searchFunctionsHandler &&
        searchFunctionsHandler({
          searchChanged: filterRows,
        });
    }, [users]);

    useEffect(() => {
      // on unMount
      return () => {
        searchHandler && searchHandler(null);
        searchFunctionsHandler && searchFunctionsHandler(null);
      };
    }, []);

    const filter = { ...diff({ term: '' }, filterVal) };

    return (
      <>
        {users &&
          hearingRoomRoles &&
          dataRoomRoles &&
          (users.length > 0 ? (
            <Table
              key={Math.random()}
              filter={filter}
              filterByColumns={filterByColumns}
              onRowSelected={onRowSelected}
              data={users}
              columns={columns}
              isItemLoaded={isItemLoaded}
              loadMoreItems={loadMoreItems}
              totalDocumentsCount={0}
              rowHeight={50}
            />
          ) : (
            <div className="no-users">{T.translate('case.noUsersAssignedMsg')}</div>
          ))}
      </>
    );
  },
);

export default TeamAdminTableCase;
