import React from 'react';
import T from 'i18n';
import { Button, ListItem, Menu, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchInputLocations from './SearchInputLocations';
import { isMobile } from 'utils/device';
import DocumentsList from './DocumentsList';

interface MatchesMenuProps {
  filters: any;
  handleChangeFilters: Function;
  filtersMenu: any;
  openFiltersMenu: boolean;
  setFiltersMenu: Function;
  containsMatches?: boolean;
  matches?: Array<any>;
}

const MatchesMenu: React.FC<MatchesMenuProps> = ({
  filters,
  handleChangeFilters,
  filtersMenu,
  openFiltersMenu,
  setFiltersMenu,
  containsMatches,
  matches,
}) => {
  const filtersScrollbarRef = React.useRef<any>(null);

  return (
    <Menu
      anchorEl={filtersMenu}
      open={openFiltersMenu}
      onClose={() => setFiltersMenu(null)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      sx={{ maxWidth: isMobile ? '90vw' : '60vw' }}
    >
      <ListItem sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ color: 'GrayText' }}>Locations</Typography>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            handleChangeFilters({
              location: [{ id: 'allMatches', name: 'All Matches', type: 'matches' }],
              files: [],
            });
          }}
        >
          {T.translate('common.clear')}
        </Button>
      </ListItem>
      <PerfectScrollbar
        style={{ maxHeight: '70vh' }}
        containerRef={ref => (filtersScrollbarRef.current = ref)}
      >
        {!containsMatches && filters.files?.length > 0 && (
          <DocumentsList filters={filters} handleChangeFilters={handleChangeFilters} />
        )}
        <SearchInputLocations
          locationsMenu={matches}
          filters={filters}
          handleChangeFilters={handleChangeFilters}
          isAnswersMode={true}
          filtersScrollbarRef={filtersScrollbarRef}
        />
      </PerfectScrollbar>
    </Menu>
  );
};

export default MatchesMenu;
