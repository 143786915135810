import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';

const SimpleTooltip = ({ children, title, startOpenned = false }) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(startOpenned);

  useEffect(() => {
    if (startOpenned) setTimeout(() => setTooltipIsOpen(false), 2000);
  }, []);

  return (
    <Tooltip
      sx={{ width: 'auto', maxWidth: '350px', fontSize: '0.75rem' }}
      placement="top"
      arrow
      title={title}
      enterDelay={600}
      leaveDelay={400}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => setTooltipIsOpen(false)}
      open={tooltipIsOpen}
    >
      {children}
    </Tooltip>
  );
};

export default SimpleTooltip;
