import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  CASE_GENERATE_ANSWERS_BEGIN,
  CASE_GENERATE_ANSWERS_SUCCESS,
  CASE_GENERATE_ANSWERS_FAILURE,
  CASE_GENERATE_ANSWERS_DISMISS_FEEDBACK,
  CASE_GENERATE_ANSWERS_CLEAR,
  CASE_FETCH_SIGNALR_ANSWER_SUCCESS,
  CASE_SET_TYPED_AI_RESPONSE_COUNT,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { selectUserSettings, withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const generateAnswers = createPromiseAction(CASE_GENERATE_ANSWERS_BEGIN);

export function dismissGenerateAnswersFeedback() {
  return {
    type: CASE_GENERATE_ANSWERS_DISMISS_FEEDBACK,
  };
}

export function clearAnswers() {
  return {
    type: CASE_GENERATE_ANSWERS_CLEAR,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCUMENTS_BEGIN actions
export function* doGenerateAnswers(action) {
  const {
    payload: {
      caseId,
      matches = [],
      searchQuery = '',
      answerType = 'generate',
      verbose = true,
      searchHistoryId,
    },
  } = action;

  if (!searchQuery) {
    return yield put({
      type: CASE_GENERATE_ANSWERS_CLEAR,
    });
  }

  const userSettings = yield select(selectUserSettings);
  const res = yield call(api.post, `/cases/${caseId}/answers`, {
    ...(searchHistoryId && { searchHistoryId }),
    searchQuery,
    matches,
    answerType,
    verbose,
    fuzzyMatch: (userSettings.aiSettings && userSettings.aiSettings.fuzzyMatch) || false,
    wrapMatchesWithContext:
      (userSettings.aiSettings && userSettings.aiSettings.wrapMatchesWithContext) || false,
    useDefaultPrompt:
      userSettings.aiSettings &&
      userSettings.aiSettings.useDefaultPrompt &&
      typeof userSettings.aiSettings.useDefaultPrompt === 'boolean'
        ? userSettings.aiSettings.useDefaultPrompt
        : true,
    ...(userSettings.aiSettings &&
      !userSettings.aiSettings.useDefaultPrompt && {
        systemPrompt: (userSettings.aiSettings && userSettings.aiSettings.systemPrompt) || '',
        sourcePrompt: (userSettings.aiSettings && userSettings.aiSettings.sourcePrompt) || '',
      }),
  });

  if (res && res.error) {
    yield put({
      type: CASE_GENERATE_ANSWERS_FAILURE,
      feedback: {
        message: 'feedback.generateAnswersFailure',
        error: res.error,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_GENERATE_ANSWERS_SUCCESS,
    data: res,
  });
  return yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGenerateAnswers() {
  yield takeLatest(generateAnswers, withCurrentCaseId(doGenerateAnswers));
}

export function useGenerateAnswers() {
  const dispatch = useDispatch();

  const {
    searchQuery,
    answers,
    generateAnswersPending,
    generateAnswersFeedback,
    aiToolsResponse,
    typedResponseCount,
  } = useSelector(
    state => ({
      searchQuery: state.case.searchQuery,
      answers: state.case.answers,
      aiToolsResponse: state.case.aiToolsResponse,
      typedResponseCount: state.case.typedResponseCount,
      generateAnswersPending: state.case.generateAnswersPending,
      generateAnswersFeedback: state.case.generateAnswersFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(generateAnswers(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissGenerateAnswersFeedback());
  }, [dispatch]);

  const boundClearAnswers = useCallback(() => {
    return dispatch(clearAnswers());
  }, [dispatch]);

  return {
    searchQuery,
    answers,
    aiToolsResponse,
    typedResponseCount,
    generateAnswers: boundAction,
    generateAnswersPending,
    generateAnswersFeedback,
    dismissGenerateAnswersFeedback: boundDismissFeedback,
    clearAnswers: boundClearAnswers,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_GENERATE_ANSWERS_BEGIN + '.TRIGGER':
      return {
        ...state,
        generateAnswersPending: true,
        generateAnswersFeedback: action.feedback,
        answers: null,
        searchQuery: action.payload.searchQuery,
      };

    case CASE_GENERATE_ANSWERS_SUCCESS:
      return {
        ...state,
        generateAnswersPending: false,
        generateAnswersFeedback: action.feedback,
        answers: action.data.answer,
      };

    case CASE_FETCH_SIGNALR_ANSWER_SUCCESS:
      return {
        ...state,
        generateAnswersPending: false,
        generateAnswersFeedback: action.feedback,
        aiToolsResponse: [...state.aiToolsResponse, action.data],
      };

    case CASE_SET_TYPED_AI_RESPONSE_COUNT:
      return {
        ...state,
        typedResponseCount: action.data,
      };

    case CASE_GENERATE_ANSWERS_FAILURE:
      return {
        ...state,
        generateAnswersPending: false,
        generateAnswersFeedback: action.feedback,
      };

    case CASE_GENERATE_ANSWERS_DISMISS_FEEDBACK:
      return {
        ...state,
        generateAnswersFeedback: null,
      };

    case CASE_GENERATE_ANSWERS_CLEAR:
      return {
        ...state,
        generateAnswersPending: false,
        searchQuery: '',
        answers: null,
        generateAnswersFeedback: action.feedback,
      };

    default:
      return state;
  }
}
