import React, { useRef, useState } from 'react';
import T from 'i18n';
import { Menu as MenuContainer, MenuItem, Typography, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { transcriptModes } from '../enums';

interface TranscriptMenuProps {
  setTranscriptMode: Function;
}

export default function TranscriptMenu({ setTranscriptMode }: TranscriptMenuProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [showTranscriptMenu, setShowTranscriptMenu] = useState(false);

  return (
    <>
      <IconButton ref={anchorRef} onClick={() => setShowTranscriptMenu(true)}>
        <MoreVert fontSize="large" />
      </IconButton>
      <MenuContainer
        open={showTranscriptMenu}
        onClose={() => setShowTranscriptMenu(!showTranscriptMenu)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => setTranscriptMode(transcriptModes.VIEWOPTIONS)}>
          <Typography variant="body1">{T.translate('viewing.settings')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => setTranscriptMode(transcriptModes.ASSIGNSPEAKERS)}>
          <Typography variant="body1">{T.translate('viewing.editMode')}</Typography>
        </MenuItem>
      </MenuContainer>
    </>
  );
}
