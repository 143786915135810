import React, { useCallback, useRef } from 'react';

import Button from '@mui/material/Button';
import StreamingOffIcon from '../../icons/StreamingOffIcon';
import StreamingOnIcon from '../../icons/StreamingOnIcon';
import T from 'i18n';
import useStreamingToggle from '../../hooks/useStreamingToggle/useStreamingToggle';

export default function ToggleStreamingButton(props: { disabled?: boolean; className?: string, whipUrl?: string }) {
  const [streamingState, toggleStreamingEnabled] = useStreamingToggle(props.whipUrl);
  const lastClickTimeRef = useRef(0);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleStreamingEnabled();
    }
  }, [toggleStreamingEnabled]);
  return (
    <Button
      className={props.className}
      onClick={toggleVideo}
      disabled={props.disabled}
      startIcon={streamingState !== "connected" ? <StreamingOnIcon /> : <StreamingOffIcon />}
    >
      {streamingState !== "connected"
        ? T.translate('conference.startStreaming')
        : T.translate('conference.stopStreaming')}
    </Button>
  );
}
