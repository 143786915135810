import React from 'react';
// import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import T from 'i18n';
import useFilterContext from 'features/case/TableCase/useFilterContext';
import { getHighlightedText } from 'utils/highlightText';

export function InfoRowBoolean({ field, title, style, className, noPadding }) {
  return (
    <Row style={style || { margin: '0.1rem' }} className={className}>
      {title && (
        <Col xs={7} style={{ fontWeight: 'bold', ...(noPadding && { padding: 0 }) }}>
          {T.translate(title)}:
        </Col>
      )}
      <Col style={{ margin: 0, ...(noPadding && { padding: 0 }) }}>{field ? 'Yes' : 'No'}</Col>
    </Row>
  );
}

export default function InfoRow({ field, title, style, className, noPadding }) {
  const filterContext = useFilterContext();
  return (
    <Row style={style || { margin: '0.1rem' }} className={className}>
      {title && (
        <Col xs={field && 7} style={{ fontWeight: 'bold', ...(noPadding && { padding: 0 }) }}>
          {T.translate(title)}:
        </Col>
      )}
      {field && (
        <Col style={{ margin: 0, ...(noPadding && { padding: 0 }) }}>
          {filterContext && filterContext.filter ? (
            <div
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(field, filterContext.filter),
              }}
            ></div>
          ) : (
            field
          )}
        </Col>
      )}
    </Row>
  );
}

InfoRow.propTypes = {};
InfoRow.defaultProps = {};
