import React from 'react';
import { InputBase } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    searchInputField: {
      '& .MuiInputBase-input': {
        fontSize: '1rem',
      },
    },
  }),
);

interface SearchMenuInputFieldProps {
  value: string;
  placeholder: string;
  onSubmit: Function;
  onChange: Function;
}

export default function SearchMenuInputField({
  value,
  placeholder,
  onSubmit,
  onChange,
}: SearchMenuInputFieldProps) {
  const classes = useStyles();

  return (
    <InputBase
      sx={{ flex: 1 }}
      className={classes.searchInputField}
      placeholder={placeholder}
      value={value}
      onChange={(e: { target: { value: string } }) => {
        onChange && onChange(e.target.value);
      }}
      onKeyDown={(e: { key: string; stopPropagation: Function }) => {
        if (e.key !== 'Escape') {
          e.stopPropagation();
          if (e.key === 'Enter') {
            onSubmit();
          }
        }
      }}
      autoFocus
    />
  );
}
