import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_DELETE_TAG_BEGIN,
  CASE_DELETE_TAG_SUCCESS,
  CASE_DELETE_TAG_FAILURE,
  CASE_DELETE_TAG_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { deleteItemImmutable } from 'utils/arrays';
import { withCurrentCaseId } from 'common/selectors';

export const deleteTag = createPromiseAction(CASE_DELETE_TAG_BEGIN);

export function dismissDeleteTagFeedback() {
  return {
    type: CASE_DELETE_TAG_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_REMOVE_BUNDLE_BEGIN actions
export function* doDeleteTag(action) {
  const { tagId, caseId } = action.payload;
  const res = yield call(api.del, `/cases/${caseId}/tags/${tagId}`);

  if (res && res.error) {
    yield put({
      type: CASE_DELETE_TAG_FAILURE,
      feedback: { message: 'feedback.deleteTagFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_DELETE_TAG_SUCCESS,
    data: { tag: res, deletedTagId: tagId },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchDeleteTag() {
  yield takeEvery(deleteTag, withCurrentCaseId(doDeleteTag));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_DELETE_TAG_BEGIN + '.TRIGGER':
      return {
        ...state,
        deleteTagPending: true,
        deleteTagFeedback: null,
      };

    case CASE_DELETE_TAG_SUCCESS:
      return {
        ...state,
        deleteTagPending: false,
        deleteTagFeedback: action.feedback,
        ...(state.tags && {
          tags: deleteItemImmutable(
            state.tags,
            state.tags.findIndex(tag => tag.id === action.data.deletedTagId),
          ),
        }),
      };

    case CASE_DELETE_TAG_FAILURE:
      return {
        ...state,
        deleteTagPending: false,
        deleteTagFeedback: action.feedback,
      };

    case CASE_DELETE_TAG_DISMISS_FEEDBACK:
      return {
        ...state,
        deleteTagFeedback: null,
      };

    default:
      return state;
  }
}
