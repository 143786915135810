import {
  CASE_FETCH_LAYOUTS_BEGIN,
  CASE_FETCH_LAYOUTS_SUCCESS,
  CASE_FETCH_LAYOUTS_FAILURE,
  CASE_FETCH_LAYOUTS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchLayouts = createPromiseAction(CASE_FETCH_LAYOUTS_BEGIN);

export function dismissFetchLayoutsFeedback() {
  return {
    type: CASE_FETCH_LAYOUTS_DISMISS_FEEDBACK,
  };
}

export function* doFetchLayouts(action) {
  const { caseId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/hearing-room-layout`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_LAYOUTS_FAILURE,
      feedback: { message: 'feedback.fetchLayoutsFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_LAYOUTS_SUCCESS,
    data: { layouts: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchLayouts() {
  yield takeLatest(fetchLayouts, withCurrentCaseId(doFetchLayouts));
}

export function useFetchLayouts() {
  const dispatch = useDispatch();

  const { layouts, fetchLayoutsPending, fetchLayoutsFeedback } = useSelector(
    state => ({
      layouts: state.case.layouts,
      fetchLayoutsPending: state.case.fetchLayoutsPending,
      fetchLayoutsFeedback: state.case.fetchLayoutsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchLayouts(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchLayoutsFeedback());
  }, [dispatch]);

  return {
    layouts,
    fetchLayouts: boundAction,
    fetchLayoutsPending,
    fetchLayoutsFeedback,
    dismissFetchLayoutsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_LAYOUTS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchLayoutsPending: true,
        fetchLayoutsFeedback: null,
      };

    case CASE_FETCH_LAYOUTS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchLayoutsPending: false,
        fetchLayoutsFeedback: action.feedback,
        layouts: action.data.layouts,
      };

    case CASE_FETCH_LAYOUTS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchLayoutsPending: false,
        fetchLayoutsFeedback: action.feedback,
      };

    case CASE_FETCH_LAYOUTS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchLayoutsFeedback: null,
      };

    default:
      return state;
  }
}
