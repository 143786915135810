import React, { createContext } from 'react';

export interface IDroppableBodyContainerContext {
  updateSort: (index: number, idsToUpdate: Array<string>) => void;
  columns: Array<any>;
  data: Array<any>;
  sortMode: boolean;
  forwardedRef: any;
  onSortHandler: ({ sortByColumn, sortDirection, onCustomSort }: any) => void;
  sortedColumn: string;
  sortedDirection: 'asc' | 'desc' | null;
}

export const DroppableBodyContainerContext = createContext<IDroppableBodyContainerContext>(null!);

export default function DroppableBodyContainerProvider({
  updateSort,
  columns,
  data,
  sortMode,
  forwardedRef,
  onSortHandler,
  sortedColumn,
  sortedDirection,
  children,
}: IDroppableBodyContainerContext & {
  children: JSX.Element;
}) {
  return (
    <DroppableBodyContainerContext.Provider
      value={{
        updateSort,
        columns,
        data,
        sortMode,
        forwardedRef,
        onSortHandler,
        sortedColumn,
        sortedDirection,
      }}
    >
      {children}
    </DroppableBodyContainerContext.Provider>
  );
}
