/* This is the Root component mainly initializes Redux and React Router. */

import React from 'react';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { hot, setConfig } from 'react-hot-loader';
import { DndProvider } from 'react-dnd';
import { TouchBackend as DnDTouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend as DnDHtml5Backend } from 'react-dnd-html5-backend';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';

import configuredStore from './common/configStore';
import routeConfig from './common/routeConfig';
import history from './common/history';
import withAppInsights from './common/appInsights';
import Route from 'features/matcher/CustomRoute';
import ConnectivityListener from 'features/common/ConnectivityListener';
import FeedbackListener from 'features/common/FeedbackListener';
import CustomToast from 'features/common/CustomToast';
import ErrorBoundary from 'features/common/ErrorBoundary';
import NotSupportedBrowser from 'features/common/NotSupportedBrowser';
import TopNotificationWrapper from 'features/common/TopNotificationWrapper';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

const customTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#fff',
          backgroundColor: '#7BA0F4',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          color: '#fff',
          backgroundColor: '#7BA0F4',
        },
      },
    },
  },
  typography: { fontFamily: 'Montserrat-Regular' },
});

setConfig({
  logLevel: 'debug',
});

let isMobile = 'ontouchstart' in document.documentElement;
let DnDBackend = isMobile ? DnDTouchBackend : DnDHtml5Backend;

const { store, persistor } = configuredStore;

function renderRouteConfigV3(routes, contextPath) {
  // Resolve route config object in React Router v3.
  const children = []; // children component list

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath);
      children.push(
        <Route
          key={item.key || newContextPath}
          render={props => <item.component {...props}>{childRoutes}</item.component>}
          path={newContextPath}
          title={item.name}
        />,
      );
    } else if (item.component) {
      children.push(
        <Route
          key={item.key || newContextPath}
          component={item.component}
          path={newContextPath}
          exact
          title={item.name}
        />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach(r =>
        renderRoute({ ...r, name: r.name || item.name }, newContextPath),
      );
    }
  };

  routes.forEach(item => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return <Switch>{children}</Switch>;
}

function Root() {
  const children = renderRouteConfigV3(routeConfig, '/');
  return (
    <PersistGate persistor={persistor}>
      <DndProvider backend={DnDBackend}>
        <Provider store={store}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={customTheme}>
              <ErrorBoundary>
                <NotSupportedBrowser>
                  <ToastProvider components={{ Toast: CustomToast }} placement="bottom-right">
                    <ConnectedRouter history={history}>
                      <TopNotificationWrapper>{children} </TopNotificationWrapper>
                    </ConnectedRouter>
                    <ConnectivityListener />
                    <FeedbackListener />
                  </ToastProvider>
                </NotSupportedBrowser>
              </ErrorBoundary>
            </ThemeProvider>
          </StyledEngineProvider>
        </Provider>
      </DndProvider>
    </PersistGate>
  );
}

export default hot(module)(withAppInsights(Root));
