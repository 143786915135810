import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { CUSTOM_FLAG } from 'features/common/MaterialBasedComponents/Lookup';
import classnames from 'classnames';
import { faEdit, faCopy, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { fixPosition } from '../utils';
import { makeStyles } from '@mui/styles';
import { CustomViewerButton, SimpleTooltip } from 'features/common';

const useStyles = makeStyles(() => ({
  markButtonContainer: {
    zIndex: 9999,
    position: 'fixed',
    visibility: 'hidden',
    opacity: 0,
    display: 'flex',
    borderRadius: '0.5rem',

    '& :first-child': {
      flexGrow: 1,
      borderTopLeftRadius: '0.5rem',
      borderBottomLeftRadius: '0.5rem',
    },

    '& :last-child': {
      borderTopRightRadius: '0.5rem',
      borderBottomRightRadius: '0.5rem',
    },

    '&.visible': {
      opacity: 1,
      transition: 'opacity 150ms',
      visibility: 'visible',
    },
  },
  markButton: {
    padding: '0.25rem',
    position: 'relative',
    transition: 'opacity 150ms, visibility 0ms 150ms',
    whiteSpace: 'nowrap',
    backgroundColor: '#4567e5',

    border: 'none',
    boxShadow: '0 4px 6px 0 #666',

    color: 'white',
    textAlign: 'center',
    opacity: '0.85',

    a: {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    "&[type='button']:hover": {
      boxShadow: '0 4px 10px 0 #666',
      opacity: 1,
    },
  },
}));

interface MarkTooltipProps {
  markHoveredOver?: any;
  tooltipPoint: { x: number; y: number };
  showTooltip: (mark: any) => void;
  hideTooltip: (delay: number) => void;
  canCreateAndEditPublicHyperlinks: boolean;
  setMarkToBeUpdated: (mark: any) => void;
}

const MarkTooltip: FC<MarkTooltipProps> = ({
  markHoveredOver,
  tooltipPoint,
  showTooltip,
  hideTooltip,
  canCreateAndEditPublicHyperlinks,
  setMarkToBeUpdated,
}) => {
  const classes = useStyles();

  const getContent = () => {
    const linkId = markHoveredOver.getData('linkId');
    if (!linkId) return '';
    const linkType = markHoveredOver.getData('linkType');
    if (linkType === CUSTOM_FLAG) return linkId;
    const linkName = markHoveredOver.getData('linkName');
    const linkText = markHoveredOver.getData('linkText');
    return linkText ? linkText : `${linkId} (${linkName})`;
  };

  const getLinkCopy = () => {
    const linkId = markHoveredOver.getData('linkId');
    if (!linkId) return '';
    const linkType = markHoveredOver.getData('linkType');
    if (linkType === CUSTOM_FLAG) return linkId;

    return window.location.origin + (markHoveredOver.href || markHoveredOver.getData('href'));
  };

  const handleUpdateLink = () => {
    hideTooltip(0);
    setMarkToBeUpdated(markHoveredOver);
  };

  const tooltipRef = (el: HTMLDivElement | null) => {
    if (!el) return;

    if (markHoveredOver) {
      if (el.childNodes[1]) (el.childNodes[1] as HTMLElement).innerHTML = getContent();
    }

    const { x, y } = fixPosition(tooltipPoint, el.getBoundingClientRect());
    el.style.left = `${x}px`;
    el.style.top = `${y}px`;
    el.className = classnames({ visible: !!markHoveredOver }, classes.markButtonContainer, 'show');
  };

  return (
    markHoveredOver &&
    markHoveredOver.getData('linkId') && (
      <div
        onMouseEnter={() => showTooltip(markHoveredOver)}
        onMouseLeave={() => hideTooltip(500)}
        style={{
          position: 'fixed',
          textAlign: 'left',
          padding: '5px',
          lineHeight: '1.2rem',
        }}
        ref={tooltipRef}
      >
        <span className={classes.markButton}>
          <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faGlobe} />
        </span>
        <span className={classes.markButton} style={{ color: 'white', paddingRight: '0.5rem' }} />
        <SimpleTooltip title={'Copy hyperlink'}>
          <CustomViewerButton
            onClick={() => {
              navigator.clipboard.writeText(getLinkCopy());
            }}
            className={classes.markButton}
          >
            <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faCopy} />
          </CustomViewerButton>
        </SimpleTooltip>
        {canCreateAndEditPublicHyperlinks && (
          <CustomViewerButton onClick={handleUpdateLink} className={classes.markButton}>
            <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faEdit} />
          </CustomViewerButton>
        )}
      </div>
    )
  );
};

export default MarkTooltip;
