import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Video from 'twilio-video';

import { ActivePane, useAppStateContext } from '../AppStateProvider/AppStateProvider';
import { AudioTest } from '../panes/AudioTest/AudioTest';
import { BrowserTest } from '../panes/BrowserTest/BrowserTest';
import { CameraTest } from '../panes/CameraTest/CameraTest';
import { CheckPermissions } from '../panes/DeviceSetup/CheckPermissions/CheckPermissions';
import { GetStarted } from '../panes/GetStarted/GetStarted';
import { PermissionError } from '../panes/DeviceSetup/PermissionError/PermissionError';
import { Connectivity } from '../panes/Connectivity/Connectivity';
import { Results } from '../panes/Results/Results';
import { Quality } from '../panes/Quality/Quality';

const useStyles = makeStyles({
  contentContainer: {
    position: 'absolute',
    top: '0',
    //left: '50%',
    //transform: 'translateX(-50%)',
  },
  scrollContainer: {
    transition: 'all 1s ease',
    position: 'relative',
    transform: 'translateY(50vh)',
    '& .inactive': {
      opacity: 0,
      userSelect: 'none',
      cursor: 'pointer',
      '& > *': {
        pointerEvents: 'none',
      },
    },
  },
  hideAll: {
    '& .inactive': {
      opacity: 0,
      visibility: 'hidden',
    },
  },
  hideAfter: {
    '& .active ~ $item': {
      opacity: 0,
      visibility: 'hidden',
    },
  },
  item: {
    transition: 'all 0.75s ease',
    padding: '3em 0',
  },
  hideItem: {
    visibility: 'hidden',
    position: 'fixed',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    display: 'flex',
    flexDirection: 'column',
    '& button': {
      minWidth: 0,
      padding: '0.6em 0.9em',
      background: 'white',
      '&:first-child': {
        marginBottom: '1.5em',
      },
      '&[disabled]': {
        visibility: 'hidden',
      },
    },
  },
});

export function Item({
  children,
  isActive,
  onClick,
  isHidden,
}: {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
  isHidden: boolean;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    if (isActive) {
      const el = ref.current;
      const offset = el.offsetTop + el.offsetHeight * 0.5;
      el.parentElement!.style.transform = `translateY(calc(15rem - ${offset}px ))`;
    }
  });

  return (
    <div
      ref={ref}
      className={clsx(classes.item, {
        inactive: !isActive,
        active: isActive,
        [classes.hideItem]: isHidden,
      })}
      onClick={!isActive ? onClick : undefined}
      aria-hidden={!isActive}
      data-testid={`item-container`}
    >
      {children}
    </div>
  );
}

const getContent = (setData: (val: Array<any>) => void) => [
  { pane: ActivePane.GetStarted, component: <GetStarted /> },
  { pane: ActivePane.DeviceCheck, component: <CheckPermissions /> },
  { pane: ActivePane.DeviceError, component: <PermissionError /> },
  { pane: ActivePane.CameraTest, component: <CameraTest /> },
  { pane: ActivePane.AudioTest, component: <AudioTest /> },
  { pane: ActivePane.BrowserTest, component: <BrowserTest /> },
  { pane: ActivePane.Connectivity, component: <Connectivity /> },
  { pane: ActivePane.Quality, component: <Quality /> },
  {
    pane: ActivePane.Results,
    component: <Results setData={setData} />,
  },
];

export function MainContent({
  setData,
  isModalOpened,
}: {
  setData: (val: Array<any>) => void;
  isModalOpened: Boolean;
}) {
  const classes = useStyles();
  const { state, dispatch, nextPane } = useAppStateContext();
  const devicesPermitted = state.audioGranted && state.videoGranted;
  const testsInProgress = state.preflightTestInProgress || state.bitrateTestInProgress;
  const onLoadingScreen = state.activePane === ActivePane.Connectivity && testsInProgress;

  const content = getContent(setData);

  useEffect(() => {
    if (!isModalOpened) dispatch({ type: 'set-active-pane', newActivePane: ActivePane.GetStarted });
  }, [isModalOpened]);

  return (
    <>
      <div className={classes.contentContainer}>
        <div
          className={clsx(classes.scrollContainer, {
            [classes.hideAll]: state.activePane === 0,
            [classes.hideAfter]:
              state.activePane === ActivePane.DeviceCheck ||
              state.activePane === ActivePane.DeviceError ||
              onLoadingScreen ||
              (state.activePane === ActivePane.BrowserTest &&
                (testsInProgress || !Video.isSupported)),
          })}
        >
          {content.map((pane, i) => {
            return (
              <Item
                key={i}
                isActive={state.activePane === pane.pane}
                onClick={() => dispatch({ type: 'set-active-pane', newActivePane: pane.pane })}
                isHidden={
                  (pane.pane === ActivePane.DeviceError && !state.deviceError) ||
                  (pane.pane === ActivePane.DeviceCheck && devicesPermitted)
                }
              >
                {pane.component}
              </Item>
            );
          })}
        </div>
      </div>
    </>
  );
}
