import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import AsyncS from 'react-select/async';
import classnames from 'classnames';
import T from 'i18n';

const style = {
  control: base => {
    return {
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: 'none',
    };
  },
  option: base => ({
    ...base,
    backgroundColor: 'inherit',
    color: 'black',
    ':active': {
      backgroundColor: '#7BA0F4',
    },
    ':hover': {
      backgroundColor: '#7BA0F430',
    },
  }),
};

const AsyncSelect = forwardRef(
  (
    {
      defaultValue,
      labelKey,
      valueKey,
      loadOptions,
      onChange,
      className,
      isMulti,
      defaultOptions,
      getOptionLabel,
      customOption,
      ...rest
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const onFocusHandle = () => setIsFocused(true);

    const onBlurHandle = () => setIsFocused(false);

    return (
      <AsyncS
        {...rest}
        ref={ref}
        styles={style}
        className={classnames(className, { focused: !!isFocused }, 'common-async-select')}
        defaultValue={defaultValue}
        isMulti={isMulti}
        cacheOptions
        defaultOptions={defaultOptions === undefined ? true : defaultOptions}
        onFocus={onFocusHandle}
        onBlur={onBlurHandle}
        onChange={onChange}
        loadOptions={inputValue => loadOptions({ inputValue })}
        getOptionLabel={getOptionLabel || (option => option[labelKey])}
        getOptionValue={option => option[valueKey]}
        noOptionsMessage={() => T.translate('generic.loading')}
        components={
          customOption && {
            Option: customOption,
          }
        }
      />
    );
  },
);

AsyncSelect.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.any,
  defaultOptions: PropTypes.any,
  isMulti: PropTypes.bool,
};
AsyncSelect.defaultProps = { valueKey: 'value', labelKey: 'label' };

export default AsyncSelect;
