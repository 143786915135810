import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import T from 'i18n';
import classnames from 'classnames';
import { ActionsButton } from 'features/common';
import { faPlusCircle, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { annotationsTableAction } from '../enums';
import * as actions from '../redux/actions';
import * as actionsV from 'features/viewing/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentFolder,
  selectCurrentSelectedFileMetaData,
  selectFetchAnnotationsPending,
  selectFolderPath,
  selectParams,
  selectTimeline,
} from 'common/selectors';
import AnnotationsTable from '../AnnotationsTable';
import AnnotationsPageModal from './AnnotationsPageModal';
import equal from 'react-fast-compare';
import PerfectScrollbar from 'react-perfect-scrollbar';
import FilterChips from '../TableWrapper/FilterChips';
import useSearchContext from 'features/common/Search/useSearchContext';
import history from 'common/history';
import { useFetchTimeline } from '../redux/fetchTimeline';

export default ({ category }: any) => {
  const dispatch = useDispatch();
  //@ts-ignore
  const path = useSelector(state => selectFolderPath(state, category));
  const { file: alreadyOpenFileId, annotationId, quickLink } = useSelector(selectParams);
  const isTimelines = useSelector(selectTimeline) && quickLink === 'timelines';
  const fetchAnnotationsPending = useSelector(selectFetchAnnotationsPending);
  const currentFileMetaData = useSelector(selectCurrentSelectedFileMetaData);
  const currentFolder = useSelector(selectCurrentFolder);
  const { timeline } = useFetchTimeline();
  const [modalAction, setModalAction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [filterCategory, setFilterCategory] = useState(null);
  const { clearSearch } = useSearchContext();

  const handleShow = (action: any) => {
    setModalAction(action);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setModalAction(null);
  };

  const onRowSelected = useCallback(newlySelectedRows => {
    setSelectedRows(prevSelectedRows => {
      if (!equal(newlySelectedRows, prevSelectedRows)) {
        return newlySelectedRows;
      }
      return prevSelectedRows;
    });
  }, []);

  const onFilterCategoryChanged = (category: any) => {
    setFilterCategory(category);
  };

  const handleSave = (action: any, data: any) => {
    switch (action) {
      case annotationsTableAction.update:
        if (isTimelines) {
          dispatch(
            actions.updateTimelines({
              timeline: {
                ...timeline,
                events: [
                  ...timeline.events.reduce(
                    (acc: any, itm: any) => (itm.id === data.id ? [...acc, data] : [...acc, itm]),
                    [],
                  ),
                ],
              },
            }),
          );
        } else dispatch(actions.updateAnnotations({ annotation: data }));
        break;
      case annotationsTableAction.delete:
        const listOfIdsToRemove = data.map((itm: any) => itm.id);
        if (isTimelines) {
          dispatch(
            actions.updateTimelines({
              timeline: {
                ...timeline,
                events: [
                  ...timeline.events.reduce(
                    (acc: any, itm: any) =>
                      listOfIdsToRemove.includes(itm.id) ? acc : [...acc, itm],
                    [],
                  ),
                ],
              },
            }),
          );
        } else
          data.map((itm: any) =>
            dispatch(actions.deleteAnnotations({ annotation: itm })).then((res: any) => {
              // if (annotationId && annotationId === itm.annotation.id)
              //   history.push(history.location.pathname.replace(/\/annotationId.*/, ``));
            }),
          );
        break;
      case annotationsTableAction.deleteAnnotaion:
        data.map((itm: any) =>
          dispatch(actionsV.deleteAnnotation({ annotation: itm, fileId: itm.fileId })),
        );
        break;
      case annotationsTableAction.addNewEvent:
        dispatch(
          actions.updateTimelines({
            timeline: { ...timeline, events: [...timeline.events, data] },
          }),
        );
        break;
      default:
        break;
    }
  };

  const buttonActions = () => {
    return [
      isTimelines && {
        title: T.translate('generic.add'),
        handler: () => handleShow(annotationsTableAction.addNewEvent),
        icon: faPlusCircle,
        disabled: currentFolder && currentFolder.level === 0,
      },
      category !== T.translate('case.allAnnotations') && {
        title: T.translate('generic.update'),
        handler: () => handleShow(annotationsTableAction.update),
        icon: faEdit,
        disabled: selectedRows.length !== 1,
      },
      category !== T.translate('case.allAnnotations') && {
        title: T.translate('generic.remove'),
        handler: () => handleShow(annotationsTableAction.delete),
        icon: faTrashAlt,
        disabled: selectedRows.length === 0,
      },
      category === T.translate('case.allAnnotations') && {
        title: T.translate('generic.remove'),
        handler: () => handleShow(annotationsTableAction.deleteAnnotaion),
        icon: faTrashAlt,
        disabled: selectedRows.length === 0,
      },
    ].filter(Boolean);
  };

  const handleShowModal = useCallback(
    (modalAct: any) => {
      const currentUrlHashVal = window.location.hash;
      const hashEventId = currentUrlHashVal.split('#')[1];
      if (
        (hashEventId && selectedRows.length === 1 && selectedRows[0].id !== hashEventId) ||
        (hashEventId && selectedRows.length !== 1)
      )
        history.push(history.location.pathname, { ...history.location, hash: '' });

      setShowModal(true);
      setModalAction(modalAct);
    },
    [selectedRows],
  );

  const handleUserKeyPress = useCallback(
    (e: any) => {
      if (selectedRows.length === 1) {
        if ((e.ctrlKey || e.metaKey) && e.code === 'KeyM') {
          if (alreadyOpenFileId && alreadyOpenFileId !== selectedRows[0].id) {
            history.push(
              history.location.pathname.replace(/\/files.*/, ``) +
                `/files/${selectedRows[0].file.id}`,
            );
          }
          handleShowModal(annotationsTableAction.update);
        }
      }
    },
    [selectedRows, alreadyOpenFileId, handleShowModal],
  );

  useEffect(() => {
    if (isTimelines) window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      if (isTimelines) window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress, isTimelines]);

  useEffect(() => {
    if (
      selectedRows.length === 1 &&
      annotationId === selectedRows[0].id &&
      Object.keys(currentFileMetaData).length === 0
    ) {
      dispatch(
        actions.setCurrentSelectedFile({
          id: selectedRows[0].fileId,
          name: selectedRows[0].fileName,
          type: 'pdf',
        }),
      );
    }
  }, [dispatch, selectedRows, annotationId, currentFileMetaData]);

  const breadcrumbMeasuredRef = createRef<any>();

  const breadcrumbWidth =
    (breadcrumbMeasuredRef &&
      breadcrumbMeasuredRef.current &&
      breadcrumbMeasuredRef.current.offsetWidth) ||
    0;

  return (
    <>
      <div className="case-table-wrapper" key="table-wrapper">
        <Container fluid className="h-100">
          <Row className="justify-content-between align-items-center p-2 px-3">
            <Breadcrumb ref={breadcrumbMeasuredRef}>
              <Breadcrumb.Item active={true}>{category}</Breadcrumb.Item>
              {path.length > 2 && (
                <Breadcrumb.Item href="" active={true}>
                  ...
                </Breadcrumb.Item>
              )}
              {path.length > 1 && <Breadcrumb.Item active>{path[path.length - 1]}</Breadcrumb.Item>}
            </Breadcrumb>
            <PerfectScrollbar
              style={{ maxWidth: `calc(100% - ${breadcrumbWidth + 120}px)`, flexGrow: 1 }}
              className="filterChipSection"
              options={{ suppressScrollY: true, useBothWheelAxes: true }}
            >
              <FilterChips filterCategory={filterCategory} clearFilter={clearSearch} />
            </PerfectScrollbar>
            <ActionsButton
              buttonActions={buttonActions()}
              showDropDown={category !== T.translate('case.allAnnotations')}
            />
          </Row>
          <Row className="secondRow">
            <Col md={12} className="h-100">
              <Card
                className={classnames({
                  loading: fetchAnnotationsPending,
                })}
              >
                <AnnotationsTable
                  onRowSelected={onRowSelected}
                  onFilterCategoryChanged={onFilterCategoryChanged}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {showModal && modalAction && (
        <AnnotationsPageModal
          show={showModal}
          action={modalAction}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
        />
      )}
    </>
  );
};
