import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  CASE_FETCH_CONTAINER_BEGIN,
  CASE_FETCH_CONTAINER_SUCCESS,
  CASE_FETCH_CONTAINER_FAILURE,
  CASE_FETCH_CONTAINER_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { selectFolderContainers, withCurrentCaseId } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

const mapping = {
  trialbook: 'trialbooks',
  'court-bundle': 'courtBundles',
  bundle: 'bundles',
  'team-bundle': 'teamBundles',
  'private-bundle': 'privateBundles',
  quickLinks: 'quickLinks',
  tags: 'quickLinks',
  timelines: 'quickLinks',
};

export const fetchContainer = createPromiseAction(CASE_FETCH_CONTAINER_BEGIN);

export function dismissFetchContainerFeedback() {
  return {
    type: CASE_FETCH_CONTAINER_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doFetchContainer(action) {
  const {
    payload: { folder, containerId, caseId },
  } = action;
  const currentContainerCode = mapping[folder.type];
  const containers = yield select(selectFolderContainers);
  const currentContainer = containers.find(cnt => cnt.code === currentContainerCode);

  const res = yield call(
    api.get,
    `/cases/${caseId}/containers/${currentContainer ? currentContainer.id : containerId}`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_CONTAINER_FAILURE,
      feedback: {
        message: 'feedback.fetchContainerFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_CONTAINER_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchContainer() {
  yield takeLatest(fetchContainer, withCurrentCaseId(doFetchContainer));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_CONTAINER_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchContainerPending: true,
        fetchContainerFeedback: null,
      };

    case CASE_FETCH_CONTAINER_SUCCESS:
      const containerIndex = state.containers.findIndex(cnt => cnt.id === action.data.id);
      return {
        ...state,
        fetchContainerPending: false,
        fetchContainerFeedback: action.feedback,
        folders: {
          ...state.folders,
          [action.data.code]: action.data.folders,
        },
        ...(containerIndex >= 0 && {
          containers: replaceItemImmutable(state.containers, action.data, containerIndex),
        }),
      };

    case CASE_FETCH_CONTAINER_FAILURE:
      return {
        ...state,
        fetchContainerPending: false,
        fetchContainerFeedback: action.feedback,
      };

    case CASE_FETCH_CONTAINER_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchContainerFeedback: null,
      };

    default:
      return state;
  }
}
