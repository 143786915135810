import React from 'react';
import { Container, Typography, Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppStateContext } from '../../../AppStateProvider/AppStateProvider';
import { QualityScore } from '../Quality';

const useStyles = makeStyles({
  modal: {
    width: '6000px',
  },
  illustrationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  viewButton: {
    marginTop: '2em',
    '& svg': {
      position: 'relative',
      left: '-5px',
    },
  },
});

interface ExcellentQualityProps {
  quality: QualityScore;
  openModal: () => void;
}

export function ExcellentQuality({ quality, openModal }: ExcellentQualityProps) {
  const { nextPane } = useAppStateContext();
  const classes = useStyles();

  const qualityScore = quality === QualityScore.Excellent ? 'excellent' : 'good';

  return (
    <Container>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={5}>
          <Typography variant="h5" gutterBottom>
            Expected quality is {qualityScore}
          </Typography>

          <Typography variant="body1" gutterBottom>
            This is the last step! Your expected audio and video quality is{' '}
            <strong>{qualityScore}</strong> and overall performance looks{' '}
            {qualityScore === 'excellent' ? 'good' : 'ok'}.
          </Typography>

          <Button variant="contained" color="primary" onClick={nextPane}>
            Finish up!
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
