import {
  COMMON_FETCH_HEALTHCHECK_BEGIN,
  COMMON_FETCH_HEALTHCHECK_SUCCESS,
  COMMON_FETCH_HEALTHCHECK_FAILURE,
  COMMON_FETCH_HEALTHCHECK_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchHealthcheck = createPromiseAction(COMMON_FETCH_HEALTHCHECK_BEGIN);

export function dismissFetchHealthcheckFeedback() {
  return {
    type: COMMON_FETCH_HEALTHCHECK_DISMISS_FEEDBACK,
  };
}

export function* doFetchHealthcheck(action) {
  let res = yield call(api.get, `/healthcheck`);

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_HEALTHCHECK_FAILURE,
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_HEALTHCHECK_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchHealthcheck() {
  yield takeLatest(fetchHealthcheck, doFetchHealthcheck);
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_HEALTHCHECK_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchHealthcheckPending: true,
        fetchHealthcheckFeedback: null,
      };

    case COMMON_FETCH_HEALTHCHECK_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchHealthcheckPending: false,
        fetchHealthcheckFeedback: action.feedback,
        systemStatus: action.data && action.data.status,
      };

    case COMMON_FETCH_HEALTHCHECK_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchHealthcheckPending: false,
        fetchHealthcheckFeedback: action.feedback,
      };

    case COMMON_FETCH_HEALTHCHECK_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchHealthcheckFeedback: null,
      };

    default:
      return state;
  }
}
