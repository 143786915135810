import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import T from 'i18n';
import FilterAnnotations from 'features/viewing/AcusoftViewer/AcusoftViewerControl/AnnotationSidebarList/FilterAnnotations';
import { Mark } from '@prizmdoc/viewer-core';
import AnnotationItem from './AnnotationItem';
import { updateAnnotationSeq } from 'features/viewing/redux/updateAnnotationSeq';
import Avatar from '@mui/material/Avatar';
import reorder from './reorder';
import { useSelector } from 'react-redux';
import { selectDocumentAnnotationsFilter } from 'common/selectors';
import { filterAnnotations } from 'features/viewing/redux/fetchAnnotations';
import { Tooltip } from '@mui/material';
import api from 'common/api';
import { SummaryData } from './types';
import DocSummary from 'features/case/SearchCenter/DocSummary';

type SidebarViewerProps = {
  highlights: Array<any>;
  selectedHighlight: any;
  scrollToHighlight: (highlight: any) => void;
  userId: string;
  summaries: any;
};

export default ({
  highlights,
  selectedHighlight,
  scrollToHighlight,
  userId,
  summaries,
}: SidebarViewerProps) => {
  const dispatch = useDispatch();
  const [sortMode, setSortMode] = useState(false);
  const [filterPosition, setFilterPosition] = useState<HTMLElement | null>(null);
  const [header, setHeader] = useState(T.translate('viewing.annotations'));
  const [viewAnnotations, setViewAnnotations] = useState(true);

  const handleSwitchView = () => {
    setViewAnnotations(val => !val);
  };

  const onlyAnnotations = highlights.filter(highlight =>
    [Mark.Type.HighlightAnnotation, Mark.Type.RectangleAnnotation].includes(
      highlight.annotation.type,
    ),
  );

  const filter = useSelector(selectDocumentAnnotationsFilter);

  const filterCount =
    Object.keys(filter).filter(key => !filter[key]).length +
    (filter.createdBy
      ? Object.keys(filter.createdBy).filter(key => !filter.createdBy[key]).length
      : 0);

  const filterHandler = (updatedFilters: any) => {
    dispatch(filterAnnotations(updatedFilters, userId));
  };

  const handleLockClick = (canSort: boolean) => {
    const hasMyAnnotations = onlyAnnotations.find((ann: any) => ann.createdBy.id === userId);

    setSortMode(canSort);
    if (canSort) {
      if (!!filterPosition) handleClickAway();
      filterHandler({
        ...(filter.createdBy && {
          createdBy: {
            ...Object.keys(filter.createdBy).reduce((a, key) => ({ ...a, [key]: false }), {}),
            ...(hasMyAnnotations && { [hasMyAnnotations.createdBy.name]: true }),
          },
        }),
        hyperlinks: false,
        crossReference: false,
      });
    } else {
      filterHandler({
        ...(filter.createdBy && {
          createdBy: {
            ...Object.keys(filter.createdBy).reduce((a, key) => ({ ...a, [key]: true }), {}),
          },
        }),
        hyperlinks: true,
        crossReference: true,
      });
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.source.index === result.destination.index) return;

    const updatedAnnotations = reorder(
      onlyAnnotations,
      result.source.index,
      result.destination.index,
    );
    dispatch(updateAnnotationSeq({ updatedAnnotations }));
  };

  const filterClicked = (event: any) => {
    setFilterPosition(event.currentTarget);
  };

  const handleClickAway = () => {
    setFilterPosition(null);
  };

  // console.log('sidebar-viewer-list', 'render');
  const renderAnnotations = () => (
    <>
      <div className="annotationsContainer" style={{ display: 'flex' }}>
        <div className="annotationsScrollbarContainer">
          {onlyAnnotations.length > 0 ? (
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="annotations-list">
                {provided => {
                  return (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <ul className="sidebar-view__highlights" style={{ listStyleType: 'none' }}>
                        {onlyAnnotations.map((highlight, index) => {
                          return highlight.annotation.data ? (
                            sortMode ? (
                              <Draggable
                                key={highlight.id}
                                draggableId={highlight.id}
                                index={index}
                              >
                                {(providedDrag, snapshot) => (
                                  <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    {...providedDrag.dragHandleProps}
                                    style={{
                                      ...(snapshot.isDragging && {
                                        backgroundColor: 'rgba(255, 255, 255, 0.23)',
                                      }),
                                      ...providedDrag.draggableProps.style,
                                    }}
                                  >
                                    <AnnotationItem
                                      highlight={highlight}
                                      selectedHighlight={selectedHighlight}
                                      scrollToHighlightHandler={scrollToHighlight}
                                      sortMode={sortMode}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ) : (
                              <AnnotationItem
                                key={highlight.id}
                                highlight={highlight}
                                selectedHighlight={selectedHighlight}
                                scrollToHighlightHandler={scrollToHighlight}
                                sortMode={sortMode}
                              />
                            )
                          ) : null;
                        })}
                        {provided.placeholder}
                      </ul>
                    </div>
                  );
                }}
              </Droppable>
            </DragDropContext>
          ) : (
            <div className="clearAll">{T.translate('viewing.nothingSelected')}</div>
          )}
        </div>
      </div>
    </>
  );
  const perfectScrollbarRef = useRef<any | null>(null);

  const renderSummaries = () => {
    return (
      <div
        className="annotationsContainer"
        style={{ width: '100%', backgroundColor: 'transparent' }}
      >
        {summaries?.length > 0 && (
          <div className="annotationsScrollbarContainer">
            <DocSummary data={summaries} scrollBarRef={perfectScrollbarRef} collapsable={true} />
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    setHeader(
      viewAnnotations ? T.translate('viewing.annotations') : T.translate('viewing.summaries'),
    );
  }, [viewAnnotations]);

  return (
    <div
      style={{
        height: '100%',
        flexDirection: 'column',
        position: 'relative',
        display: 'flex',
        flex: 1,
      }}
    >
      <div className="description-class">
        <IconButton
          sx={{ marginRight: '0.25rem', marginTop: '0.45rem' }}
          onClick={() => handleLockClick(!sortMode)}
        >
          <FontAwesomeIcon
            style={{ width: '1.1rem', height: '1.1rem' }}
            icon={sortMode ? faLockOpen : faLock}
          />
        </IconButton>

        <h4 style={{ marginTop: '1rem', flexGrow: 1 }}>{header}</h4>
        {summaries?.length ? (
          <Tooltip
            title={
              viewAnnotations
                ? T.translate('viewing.switchView.viewSummaries')
                : T.translate('viewing.switchView.viewAnnotations')
            }
          >
            <IconButton sx={{ marginRight: 14, marginTop: '0.45rem' }} onClick={handleSwitchView}>
              <FontAwesomeIcon style={{ width: '1.1rem', height: '1.1rem' }} icon={faExchangeAlt} />
            </IconButton>
          </Tooltip>
        ) : null}

        {!sortMode && (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div>
              <div onClick={filterClicked}>
                <Badge badgeContent={filterCount} overlap="circular" color="primary">
                  <Avatar className="filterAnnotationsButton">
                    <FilterListRoundedIcon style={{ color: 'gray' }} />
                  </Avatar>
                </Badge>
              </div>
              <Popper
                id={'simple-popover'}
                className="popper-shadow"
                open={!!filterPosition}
                disablePortal={true}
                placement="bottom-end"
                style={{ pointerEvents: 'all', width: '18rem' }}
                {...(filterPosition && { anchorEl: filterPosition })}
              >
                <FilterAnnotations filterHandler={filterHandler} />
              </Popper>
            </div>
          </ClickAwayListener>
        )}
      </div>
      {viewAnnotations ? renderAnnotations() : renderSummaries()}
    </div>
  );
};
