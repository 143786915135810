import React from 'react';
import { Grid } from '@mui/material';
import { TextForm } from 'features/common';
import moment from 'moment';
import T from 'i18n';

const EventsDateTimeForm = ({
  dateTimeVal,
  setDateTimeVal,
  errors,
  register,
  trigger,
  currentSelection,
  setCurrentSelection = () => {},
  focusDateField = false,
}: any) => {
  const isValidDate = (val: any) => {
    if (val) return moment(val, 'DD/MM/YYYY', true).isValid();
    else return true;
  };

  const isValidTime = (val: any) => {
    if (val) return moment(val, 'HH:mm:ss', true).isValid();
    else return true;
  };

  const isValidKey = (event: any) => {
    const key = window.event ? event.keyCode : event.which;
    const {
      keyCode,
      ctrlKey,
      metaKey,
      shiftKey,
      target: { value },
    } = event;
    if (
      (!value ||
        (value.match(new RegExp('/', 'g')) || []).length === 2 ||
        (value.match(new RegExp(':', 'g')) || []).length === 2 ||
        value[value.length - 1] === ':' ||
        value[value.length - 1] === '/') &&
      (keyCode === 110 || keyCode === 111 || keyCode === 190 || keyCode === 191)
    )
      event.preventDefault();
    else if (
      keyCode === 8 ||
      keyCode === 9 ||
      keyCode === 46 ||
      keyCode === 37 ||
      keyCode === 39 ||
      keyCode === 35 ||
      keyCode === 36 ||
      keyCode === 190 ||
      keyCode === 191 ||
      keyCode === 110 ||
      keyCode === 111 ||
      ((ctrlKey || metaKey) && (keyCode === 65 || keyCode === 67 || keyCode === 86))
    )
      return true;
    else if ((shiftKey || key < 48 || key > 57) && (key < 96 || key > 105)) event.preventDefault();
    else return true;
  };

  const handleDateTimechange = ({ target: { value } }: any, key: any, seprator: any) => {
    if ((key === 'date' && value?.length > 10) || (key === 'time' && value.length > 8)) return;
    else if (value.length < dateTimeVal[key as keyof typeof dateTimeVal].length) {
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value,
      });
      if (
        errors &&
        ((Object.keys(errors).includes('date') && key === 'date' && value.length === 0) ||
          (Object.keys(errors).includes('time') && key === 'time' && value.length === 0))
      ) {
        setTimeout(() => trigger(['date', 'time']), 0);
      }
    } else if (value.length === 2 || value.length === 5) {
      if (value.length === 2 && (value[1] === '/' || value[1] === '.')) {
        setDateTimeVal({ ...dateTimeVal, [key]: '0' + value[0] + seprator });
      } else if (value.length === 5 && (value[4] === '/' || value[4] === '.')) {
        setDateTimeVal({
          ...dateTimeVal,
          [key]: value.slice(0, 3) + '0' + value[3] + seprator,
        });
      } else setDateTimeVal({ ...dateTimeVal, [key]: value + seprator });
    } else if (key === 'date' && value.length === 4 && parseInt(value[3]) > 1) {
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value.slice(0, 3) + '0' + value[3] + seprator,
      });
    } else if (value.length > 2 && value[2] !== seprator && !value.slice(0, 4).includes('/'))
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value.slice(0, 2) + seprator + value.slice(2),
      });
    else if (value.length > 5 && value[5] !== seprator && !value.slice(4, 7).includes('/'))
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value.slice(0, 5) + seprator + value.slice(5),
      });
    else {
      setDateTimeVal({
        ...dateTimeVal,
        [key]: value,
      });
      if (
        errors &&
        ((Object.keys(errors).includes('date') && key === 'date' && value.length === 10) ||
          (Object.keys(errors).includes('time') && key === 'time' && value.length === 8))
      )
        trigger(['date', 'time']);
    }
  };

  return (
    <>
      <Grid item xs={6}>
        <TextForm
          {...register('date', {
            validate: {
              isValidDate: (val: any) => isValidDate(val),
            },
            required: true,
            onChange: (e: any) => handleDateTimechange(e, 'date', '/'),
            // onBlur: () =>
            //   setTimeout(() => {
            //     if (dateTimeVal.time) trigger(['date', 'time']);
            //   }, 0),
          })}
          /*@ts-ignore*/
          label={T.translate('case.dateWithFormat')}
          placeholder={T.translate('case.datePlaceholder')}
          value={dateTimeVal.date}
          onKeyDown={(e: any) => isValidKey(e)}
          errors={errors}
          onFocus={(e: any) => setCurrentSelection(e.target.name)}
          autofocus={(!currentSelection && focusDateField) || currentSelection === 'date'}
        />
      </Grid>
      <Grid item xs={6}>
        <TextForm
          {...register('time', {
            validate: {
              isValidTime: (val: any) => isValidTime(val),
            },
            onChange: (e: any) => handleDateTimechange(e, 'time', ':'),
            // onBlur: (e: any) => {
            //   if (!e.target.value) trigger(['date', 'time']);
            //   const formatedVal = moment(e.target.value, 'HH:mm:ss').format('HH:mm:ss');
            //   if (!isValidTime(formatedVal)) trigger(['date', 'time']);
            //   else {
            //     setDateTimeVal({ ...dateTimeVal, time: formatedVal });
            //     setTimeout(() => trigger(['date', 'time']), 0);
            //   }
            // },
          })}
          /*@ts-ignore*/
          label={T.translate('case.time')}
          placeholder={T.translate('case.timePlaceholder')}
          value={dateTimeVal.time}
          onKeyDown={(e: any) => isValidKey(e)}
          errors={errors}
          onFocus={(e: any) => setCurrentSelection(e.target.name)}
          autofocus={currentSelection === 'time'}
        />
      </Grid>
    </>
  );
};

export default EventsDateTimeForm;
