import React from 'react';
// import PropTypes from 'prop-types';
import Collaps from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export default function Collapsible({
  open,
  title,
  children,
  triggerDisabled = false,
  showTrigger = true,
  className,
}) {
  return (
    <Collaps
      open={open}
      className={className}
      openedClassName={className}
      overflowWhenOpen="visible"
      triggerDisabled={triggerDisabled}
      trigger={
        showTrigger ? (
          <div className="common-collapsible">
            <div className="text">{title}</div>
            <div className="line" />
            <div className="icon">
              <FontAwesomeIcon icon={faAngleDown} alt={faAngleDown.iconName} />
            </div>
          </div>
        ) : null
      }
    >
      {children}
    </Collaps>
  );
}

Collapsible.propTypes = {};
Collapsible.defaultProps = {};
