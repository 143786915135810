import React from 'react';
import T from 'i18n';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { setHours, setMinutes, setSeconds, setMilliseconds, parse, isValid } from 'date-fns';
import logger from 'utils/logger';

export default ({
  filterCategory,
  setFilters,
}: {
  filterCategory: null | any;
  setFilters: (newFilterCategory: any) => void;
}) => {
  const handleUserTypedDate = (field: any) => (event: any) => {
    const typedDate = event.target.value;
    const parsedDate = parse(typedDate, 'dd/MM/yyyy', new Date());
    if (isValid(parsedDate)) {
      handleDateChange(field)(parsedDate);
    } else {
      logger.ERROR('Invalid date format.');
    }
  };

  const handleDateChange = (field: 'dateFrom' | 'dateTo') => (newValue: any) => {
    const dateWithTimeReset = newValue
      ? setMilliseconds(setSeconds(setMinutes(setHours(newValue, 0), 0), 0), 0)
      : newValue;

    setFilters({
      ...filterCategory,
      [field]: dateWithTimeReset,
      dateFilter: !!(
        (field === 'dateFrom' ? filterCategory?.dateTo : filterCategory?.dateFrom) ||
        dateWithTimeReset
      ),
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid xs={3}>
        <Typography
          style={{
            marginLeft: 0,
            alignSelf: 'center',
            marginTop: '0.5rem',
            fontWeight: 'bold',
          }}
        >
          {T.translate('filters.dateFromTo')}
        </Typography>
      </Grid>
      <Grid xs={9}>
        <div style={{ display: 'flex' }}>
          <DatePicker
            format="dd/MM/yyyy"
            value={filterCategory?.dateFrom && new Date(filterCategory?.dateFrom)}
            onChange={handleDateChange('dateFrom')}
            slotProps={{
              textField: {
                size: 'small',
                autoComplete: 'off',
                onBlur: handleUserTypedDate('dateFrom'),
                variant: 'standard',
                style: { flexGrow: 1 },
                placeholder: T.translate('generic.from'),
              },
            }}
          />
          <DatePicker
            format="dd/MM/yyyy"
            value={filterCategory?.dateTo && new Date(filterCategory?.dateTo)}
            onChange={handleDateChange('dateTo')}
            slotProps={{
              textField: {
                size: 'small',
                autoComplete: 'off',
                onBlur: handleUserTypedDate('dateTo'),
                variant: 'standard',
                style: { marginLeft: '1rem', flexGrow: 1 },
                placeholder: T.translate('generic.to'),
              },
            }}
          />
        </div>
      </Grid>
    </LocalizationProvider>
  );
};
