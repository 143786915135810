import React, { useState, forwardRef } from 'react';
import classnames from 'classnames';

type RowProps = {
  columns: Array<any>;
  dragHandleProps: any;
  row: any;
  sortMode: boolean;
  style: React.CSSProperties;
  isScrolling?: boolean;
  selected: any;
  index: number;
  hoverable?: boolean;
};

export default React.memo(
  forwardRef(
    (
      {
        columns,
        dragHandleProps,
        row,
        sortMode,
        style,
        isScrolling,
        selected,
        index: rowIndex,
        hoverable,
        ...rest
      }: RowProps,
      ref: any,
    ) => {
      const [hover, setHover] = useState(false);
      let timeoutExit: NodeJS.Timeout, timeoutEnter: NodeJS.Timeout;
      const minWidth = columns.reduce(
        (acc, col) => acc + ((col.minWidth && parseInt(col.minWidth, 10)) || 0),
        0,
      );
      return (
        <tr
          id={row.compositeKey || row.id}
          {...rest}
          style={{ ...style, display: 'flex', minWidth }}
          ref={ref}
          onMouseEnter={() => {
            if (hoverable) {
              clearTimeout(timeoutExit);
              timeoutEnter = setTimeout(() => setHover(true), 50);
            }
          }}
          onMouseLeave={() => {
            clearTimeout(timeoutEnter);
            timeoutExit = setTimeout(() => setHover(false), 50);
          }}
        >
          {columns.map(({ id, accessor, Cell, forwardRef, minWidth, width }, index) => (
            // in other tables there's no compositeKey so Id is used
            <td
              key={`${row.compositeKey || row.id}-${index}`}
              className={classnames(id || accessor, {
                'tab-align': id === 'tab',
              })}
              style={{
                minWidth,
                width,
                // extra for drag
                padding: '12px 8px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {Cell ? (
                forwardRef ? (
                  <Cell
                    dragHandleProps={dragHandleProps}
                    ref={dragHandleProps ? dragHandleProps.ref : null}
                    sortMode={sortMode}
                    isScrolling={isScrolling}
                    rowIndex={rowIndex}
                    rowHover={hover}
                    rowSelected={selected}
                    {...row}
                  />
                ) : (
                  <Cell
                    {...row}
                    sortMode={sortMode}
                    isScrolling={isScrolling}
                    rowIndex={rowIndex}
                    rowHover={hover}
                    rowSelected={selected}
                  />
                )
              ) : typeof accessor === 'function' ? (
                accessor(row)
              ) : (
                row[accessor]
              )}
            </td>
          ))}
        </tr>
      );
    },
  ),
);
