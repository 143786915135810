import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';
import { CustomViewerButton } from 'features/common';
import React, { useState } from 'react';
import { FitmentType } from '../../types';

export default ({
  zoomFitPage,
  zoomFitWidth,
}: {
  zoomFitPage: (e: any) => void;
  zoomFitWidth: (e: any) => void;
}) => {
  const [fitment, setFitment] = useState(FitmentType.Page);
  return (
    <CustomViewerButton
      icon={fitment === FitmentType.Width ? faCompress : faExpand}
      onClick={(e: any) => {
        if (fitment === FitmentType.Width) {
          zoomFitPage(e);
          setFitment(FitmentType.Page);
        } else {
          zoomFitWidth(e);
          setFitment(FitmentType.Width);
        }
      }}
    />
  );
};
