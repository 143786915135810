import {
  CASE_FETCH_FOLDER_HEALTH_BEGIN,
  CASE_FETCH_FOLDER_HEALTH_SUCCESS,
  CASE_FETCH_FOLDER_HEALTH_FAILURE,
  CASE_FETCH_FOLDER_HEALTH_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchFolderHealth = createPromiseAction(CASE_FETCH_FOLDER_HEALTH_BEGIN);

export function dismissFetchFolderHealthFeedback() {
  return {
    type: CASE_FETCH_FOLDER_HEALTH_DISMISS_FEEDBACK,
  };
}

export function* doFetchFolderHealth(action) {
  const { caseId, folderId, healthCheckType } = action.payload;
  let res = yield call(
    api.get,
    `/cases/${caseId}/folders/${folderId}/health?healthCheckType=${healthCheckType}`,
  );

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_FOLDER_HEALTH_FAILURE,
      feedback: { message: 'feedback.fetchFolderHealthFailure', error: true, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_FOLDER_HEALTH_SUCCESS,
    data: { folderHealth: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchFolderHealth() {
  yield takeLatest(fetchFolderHealth, withCurrentCaseId(doFetchFolderHealth));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_FOLDER_HEALTH_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchFolderHealthPending: true,
        fetchFolderHealthFeedback: null,
      };

    case CASE_FETCH_FOLDER_HEALTH_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchFolderHealthPending: false,
        fetchFolderHealthFeedback: action.feedback,
      };

    case CASE_FETCH_FOLDER_HEALTH_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchFolderHealthPending: false,
        fetchFolderHealthFeedback: action.feedback,
      };

    case CASE_FETCH_FOLDER_HEALTH_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchFolderHealthFeedback: null,
      };

    default:
      return state;
  }
}
