import React from 'react';
import T from 'i18n';
import { Button, ListItem, Menu, Typography } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SearchInputLocations from './SearchInputLocations';
import { isMobile } from 'utils/device';
import { removeIndexFolders } from 'utils/foldersStructuring';
import { useSelector } from 'react-redux';
import { selectFolders } from 'common/selectors';

interface TrialBundlesMenuProps {
  value: any;
  onChange: Function;
  anchorEl: any;
  isOpen: boolean;
  onClose: (e: any) => void;
  noClear?: boolean;
  width?: string;
}

const TrialBundlesMenu: React.FC<TrialBundlesMenuProps> = ({
  value,
  onChange,
  anchorEl,
  isOpen,
  onClose,
  noClear,
  width = 'auto',
}) => {
  const folders = useSelector(selectFolders);
  const trialBundlesWithoutIndexFolder = removeIndexFolders(folders['trialbooks']);
  const addTrialbooksList =
    trialBundlesWithoutIndexFolder && trialBundlesWithoutIndexFolder.length > 0;
  const addTeamBundlesList = folders && folders['teamBundles'] && folders['teamBundles'].length > 0;
  const addPrivateBundlesList =
    folders && folders['privateBundles'] && folders['privateBundles'].length > 0;

  const locationsList = [
    addTrialbooksList && { id: 'trialBundle', name: 'Trial Bundles' },
    ...(addTrialbooksList ? trialBundlesWithoutIndexFolder : []),
    addTeamBundlesList && { id: 'teamBundles', name: 'Team Bundles' },
    ...(addTeamBundlesList ? folders['teamBundles'] : []),
    addPrivateBundlesList && { id: 'privateBundles', name: 'Private Bundles' },
    ...(addPrivateBundlesList ? folders['privateBundles'] : []),
  ].filter(Boolean);

  const filtersScrollbarRef = React.useRef<any>(null);

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      sx={{
        width,
        maxWidth: isMobile ? '90vw' : '60vw',
      }}
    >
      <ListItem sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ color: 'GrayText' }}>Locations</Typography>
        {!noClear && (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onChange([]);
            }}
          >
            {T.translate('common.clear')}
          </Button>
        )}
      </ListItem>
      <PerfectScrollbar
        style={{ maxHeight: '70vh' }}
        containerRef={ref => (filtersScrollbarRef.current = ref)}
      >
        <SearchInputLocations
          locationsMenu={locationsList}
          filters={{ location: value, files: [] }}
          handleChangeFilters={(val: any) => onChange(val.location)}
          isAnswersMode={false}
          filtersScrollbarRef={filtersScrollbarRef}
        />
      </PerfectScrollbar>
    </Menu>
  );
};

export default TrialBundlesMenu;
