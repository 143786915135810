import { put, takeEvery, call } from 'redux-saga/effects';
import {
  CASE_BURN_CROSS_REF_BEGIN,
  CASE_BURN_CROSS_REF_SUCCESS,
  CASE_BURN_CROSS_REF_FAILURE,
  CASE_BURN_CROSS_REF_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const burnCrossReferences = createPromiseAction(CASE_BURN_CROSS_REF_BEGIN);

export function dismissBurnCrossReferencesFeedback() {
  return {
    type: CASE_BURN_CROSS_REF_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_BURN_CROSS_REF_BEGIN actions
export function* doBurnCrossReferences(action) {
  const {
    payload: { folderId, caseId },
  } = action;

  const res = yield call(api.post, `/cases/${caseId}/folders/${folderId}/annotations/burn`);

  if (res && res.error) {
    yield put({
      type: CASE_BURN_CROSS_REF_FAILURE,
      feedback: {
        message: 'feedback.burnCrossReferencesFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_BURN_CROSS_REF_SUCCESS,
    feedback: {
      message: 'feedback.burnCrossRefSuccess',
      success: true,
    },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchBurnCrossReferences() {
  yield takeEvery(burnCrossReferences, withCurrentCaseId(doBurnCrossReferences));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_BURN_CROSS_REF_BEGIN + '.TRIGGER':
      return {
        ...state,
        burnCrossReferencesPending: true,
        burnCrossReferencesFeedback: null,
      };

    case CASE_BURN_CROSS_REF_SUCCESS:
      return {
        ...state,
        burnCrossReferencesPending: false,
        burnCrossReferencesFeedback: action.feedback,
      };

    case CASE_BURN_CROSS_REF_FAILURE:
      return {
        ...state,
        burnCrossReferencesPending: false,
        burnCrossReferencesFeedback: action.feedback,
      };

    case CASE_BURN_CROSS_REF_DISMISS_FEEDBACK:
      return {
        ...state,
        burnCrossReferencesFeedback: null,
      };

    default:
      return state;
  }
}
