import { HomePage } from './';

export default {
  path: '',
  name: '',
  childRoutes: [
    {
      path: '/',
      name: 'routes.home',
      component: HomePage,
      isIndex: true,
    },
    {
      path: 'signin*',
      name: 'routes.home',
      component: HomePage,
    },
    {
      path: 'signup*',
      name: 'routes.home',
      component: HomePage,
    },
  ],
};
