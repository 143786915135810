import { Mark } from '@prizmdoc/viewer-core';
import { isEmpty } from 'utils/objects';

export const DEFAULT_ANNOTATION_FILTER = {
  hyperlinks: true,
  crossReference: true,
};

export const createFilter = (annotations, defaultFilter) => {
  let createdByList = [
    ...new Set(
      annotations
        .filter(ann =>
          [Mark.Type.HighlightAnnotation, Mark.Type.RectangleAnnotation].includes(
            ann.annotation.type,
          ),
        )
        .map(ann => ann.createdBy.name),
    ),
  ]
    .sort()
    .reduce((a, key) => ({ ...a, [key]: true }), {});
  return { ...(!isEmpty(createdByList) && { createdBy: createdByList }), ...defaultFilter };
};

export const filterAnnotations = (allAnnotations, updatedFilters, userId) => {
  if (
    Object.values(updatedFilters).every(v => v) &&
    (updatedFilters.createdBy ? Object.values(updatedFilters.createdBy).every(v => v) : true)
  )
    return allAnnotations;

  return allAnnotations.filter(highlight => {
    return (
      ([Mark.Type.HighlightAnnotation, Mark.Type.RectangleAnnotation].includes(
        highlight.annotation.type,
      ) &&
        (!updatedFilters.createdBy ||
          Object.keys(updatedFilters.createdBy)
            .filter(key => updatedFilters.createdBy[key])
            .includes(highlight.createdBy.name))) ||
      (updatedFilters.hyperlinks &&
        highlight.annotation.type === Mark.Type.TextHyperlinkAnnotation) ||
      (updatedFilters.crossReference && highlight.annotation.type === Mark.Type.TextAnnotation)
    );
  });
};
