import TemplatePage from './TemplatePage';

export default {
  path: '',
  name: '',
  childRoutes: [
    {
      path: 'template/:templateId',
      name: 'routes.template',
      component: TemplatePage,
    },
  ],
};
