import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CASE_GET_FOLDER_STATS_BEGIN,
  CASE_GET_FOLDER_STATS_SUCCESS,
  CASE_GET_FOLDER_STATS_FAILURE,
  CASE_GET_FOLDER_STATS_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const getFolderStats = createPromiseAction(CASE_GET_FOLDER_STATS_BEGIN);

export function dismissGetFolderStatsFeedback() {
  return {
    type: CASE_GET_FOLDER_STATS_DISMISS_FEEDBACK,
  };
}

export function* doGetFolderStats(action) {
  const { caseId, folderId } = action.payload;
  let res2 = yield call(api.get, `/cases/${caseId}/folders/${folderId}/stats`);
  if (res2 && res2.error) {
    yield put({
      type: CASE_GET_FOLDER_STATS_FAILURE,
      feedback: {
        message: 'feedback.getFolderStatsFailure',
        error: res2.error,
      },
    });
    yield call(rejectPromiseAction, action, res2.error);
  } else {
    yield put({
      type: CASE_GET_FOLDER_STATS_SUCCESS,
      data: { folderStats: res2 },
    });
    yield call(resolvePromiseAction, action, res2);
  }
}
export function* watchGetFolderStats() {
  yield takeLatest(getFolderStats, withCurrentCaseId(doGetFolderStats));
}

export function useGetFolderStats() {
  const dispatch = useDispatch();

  const { folderStats, getFolderStatsPending, getFolderStatsFeedback } = useSelector(
    state => ({
      folderStats: state.case.folderStats,
      getFolderStatsPending: state.case.getFolderStatsPending,
      getFolderStatsFeedback: state.case.getFolderStatsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getFolderStats(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissGetFolderStatsFeedback());
  }, [dispatch]);

  return {
    folderStats,
    getFolderStats: boundAction,
    getFolderStatsPending,
    getFolderStatsFeedback,
    dismissGetFolderStatsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_GET_FOLDER_STATS_BEGIN + '.TRIGGER':
      return {
        ...state,
        getFolderStatsPending: true,
        getFolderStatsFeedback: null,
      };

    case CASE_GET_FOLDER_STATS_SUCCESS:
      return {
        ...state,
        getFolderStatsPending: false,
        getFolderStatsFeedback: action.feedback,
        folderStats: action.data.folderStats,
      };

    case CASE_GET_FOLDER_STATS_FAILURE:
      return {
        ...state,
        getFolderStatsPending: false,
        getFolderStatsFeedback: action.feedback,
      };

    case CASE_GET_FOLDER_STATS_DISMISS_FEEDBACK:
      return {
        ...state,
        getFolderStatsFeedback: null,
      };

    default:
      return state;
  }
}
