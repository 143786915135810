import { useCallback, useRef, useState } from 'react';
import WHIPClient from '../../WHIPClient';

export default function useStreamingToggle(whipUrl: string | undefined) {
  const [streamingState, setStreamingState] = useState('');
  const whipClient = useRef<WHIPClient>();

  const toggleStreamingEnabled = useCallback(async () => {
    if (streamingState === 'connected') {
      //stop streaming to WHIP
      setStreamingState('disconnected');
      whipClient.current?.disconnectStream();
    } else {
      //go capture the element
      // @ts-ignore
      const stream = await navigator.mediaDevices.getDisplayMedia({
        preferCurrentTab: true,
        audio: {
          echoCancellation: false,
          suppressLocalAudioPlayback: false,
        },
        video: {
          frameRate: { ideal: 30, max: 60 },
          // @ts-ignore
          cursor: 'never',
        },
        systemAudio: 'include',
      });
      const [track] = stream.getVideoTracks();
      // track.contentHint = "detail";

      await track.applyConstraints({
        frameRate: { min: 30, ideal: 30, max: 60 },
        // @ts-ignore
        cursor: 'never',
      });
      const captureTarget = document.querySelector('.conference-twilio');

      // @ts-expect-error: Let's ignore RestrictionTarget
      const restrictionTarget = await RestrictionTarget.fromElement(captureTarget);
      // @ts-expect-error: Let's ignore restrictTo
      await track.restrictTo(restrictionTarget);

      //start streaming to WHIP
      whipClient.current = new WHIPClient(whipUrl, stream);

      track.addEventListener('ended', () => {
        setStreamingState('disconnected');
        whipClient?.current?.disconnectStream();
      });

      whipClient?.current?.on('connectstate', state => {
        setStreamingState(state);
      });
    }
  }, [streamingState]);

  return [streamingState, toggleStreamingEnabled] as const;
}
