import React, { useCallback, useState } from 'react';
import { Breadcrumb, Card, Col, Container, Row } from 'react-bootstrap';
import T from 'i18n';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { selectFetchPageRefsPending, selectFolderPath } from 'common/selectors';
import equal from 'react-fast-compare';
import PageRefsTable from './PageRefsTable';

export default ({ category }: any) => {
  //@ts-ignore
  const path = useSelector(state => selectFolderPath(state, category));
  const fetchPageRefsPending = useSelector(selectFetchPageRefsPending);
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const onRowSelected = useCallback(newlySelectedRows => {
    setSelectedRows(prevSelectedRows => {
      if (!equal(newlySelectedRows, prevSelectedRows)) {
        return newlySelectedRows;
      }
      return prevSelectedRows;
    });
  }, []);

  return (
    <>
      <div className="case-table-wrapper" key="table-wrapper">
        <Container fluid className="h-100">
          <Row className="justify-content-between align-items-center p-2 px-3">
            <Breadcrumb>
              <Breadcrumb.Item active={true}>{category}</Breadcrumb.Item>
              {path.length > 2 && (
                <Breadcrumb.Item href="" active={true}>
                  ...
                </Breadcrumb.Item>
              )}
              {path.length > 1 && <Breadcrumb.Item active>{path[path.length - 1]}</Breadcrumb.Item>}
            </Breadcrumb>
          </Row>
          <Row className="secondRow">
            <Col md={12} className="h-100">
              <Card
                className={classnames({
                  loading: fetchPageRefsPending,
                })}
              >
                <PageRefsTable onRowSelected={onRowSelected} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
