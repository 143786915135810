import {
  CASE_FETCH_ROLES_BEGIN,
  CASE_FETCH_ROLES_SUCCESS,
  CASE_FETCH_ROLES_FAILURE,
  CASE_FETCH_ROLES_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchCaseRoles = createPromiseAction(CASE_FETCH_ROLES_BEGIN);

export function dismissFetchCaseRolesFeedback() {
  return {
    type: CASE_FETCH_ROLES_DISMISS_FEEDBACK,
  };
}

export function* doFetchCaseRoles(action) {
  const { caseId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/roles`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_ROLES_FAILURE,
      feedback: {
        message: 'feedback.fetchCaseRolesFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_ROLES_SUCCESS,
    data: { caseRoles: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchCaseRoles() {
  yield takeEvery(fetchCaseRoles, withCurrentCaseId(doFetchCaseRoles));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_ROLES_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchCaseRolesPending: true,
        fetchCaseRolesFeedback: null,
      };

    case CASE_FETCH_ROLES_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchCaseRolesPending: false,
        fetchCaseRolesFeedback: action.feedback,
        caseRoles: action.data.CaseRoles,
      };

    case CASE_FETCH_ROLES_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchCaseRolesPending: false,
        fetchCaseRolesFeedback: action.feedback,
      };

    case CASE_FETCH_ROLES_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchCaseRolesFeedback: null,
      };

    default:
      return state;
  }
}
