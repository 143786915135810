import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_FETCH_CONTAINERS_BEGIN,
  CASE_FETCH_CONTAINERS_SUCCESS,
  CASE_FETCH_CONTAINERS_FAILURE,
  CASE_FETCH_CONTAINERS_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export function fetchContainers() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_FETCH_CONTAINERS_BEGIN,
  };
}

export function dismissFetchContainersFeedback() {
  return {
    type: CASE_FETCH_CONTAINERS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doFetchContainers(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/containers`);

  if (res && res.error) {
    return yield put({
      type: CASE_FETCH_CONTAINERS_FAILURE,
      feedback: {
        message: 'feedback.fetchContainersFailure',
        error: res.error,
        retryAction: action,
      },
    });
  }

  yield put({
    type: CASE_FETCH_CONTAINERS_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchContainers() {
  yield takeLatest(CASE_FETCH_CONTAINERS_BEGIN, withCurrentCaseId(doFetchContainers));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_CONTAINERS_BEGIN:
      return {
        ...state,
        fetchContainersPending: true,
        fetchContainersFeedback: null,
      };

    case CASE_FETCH_CONTAINERS_SUCCESS:
      return {
        ...state,
        fetchContainersPending: false,
        fetchContainersFeedback: action.feedback,
        folders: action.data.reduce((acc, { code, folders }) => {
          acc[code] = folders;
          return acc;
        }, {}),
        containers: action.data,
      };

    case CASE_FETCH_CONTAINERS_FAILURE:
      return {
        ...state,
        fetchContainersPending: false,
        fetchContainersFeedback: action.feedback,
      };

    case CASE_FETCH_CONTAINERS_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchContainersFeedback: null,
      };

    default:
      return state;
  }
}
