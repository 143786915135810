import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import BootstrapModal from 'react-bootstrap/Modal';
import T from 'i18n';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export default function Modal({
  className,
  show,
  handleClose,
  disableOnOutsideClick = true,
  disableClose,
  title,
  children,
  buttons,
  handleSave,
  disableSave,
  saveTitle,
  wide,
  closeTitle,
  hintContent,
}) {
  useEffect(() => {
    const listener = event => {
      if (show && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        if (disableSave) !buttons && handleClose && handleClose();
        else handleSave && handleSave();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [show, handleSave, buttons, disableSave, handleClose]);

  return (
    <BootstrapModal
      className={classnames('modal', className, { 'modal-wide': wide })}
      show={show}
      onHide={handleClose}
      {...(disableOnOutsideClick && { backdrop: 'static' })}
    >
      <BootstrapModal.Header closeButton={!disableClose}>
        {title && <BootstrapModal.Title>{title}</BootstrapModal.Title>}
      </BootstrapModal.Header>
      <BootstrapModal.Body>{children}</BootstrapModal.Body>
      <BootstrapModal.Footer>
        {hintContent && (
          <div style={{ flex: 'auto' }}>
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Popover>
                  <Popover.Title as="h4">{T.translate('generic.shortcuts')}</Popover.Title>
                  <Popover.Content>{hintContent}</Popover.Content>
                </Popover>
              }
            >
              <FontAwesomeIcon
                size="lg"
                style={{ color: '#7BA0F4' }}
                icon={faInfoCircle}
                alt={faInfoCircle.iconName}
              />
            </OverlayTrigger>
          </div>
        )}
        {!disableClose && (
          <Button type="button" variant="secondary" onClick={handleClose}>
            {closeTitle || T.translate('generic.close')}
          </Button>
        )}
        {buttons}
        {handleSave && (
          <Button type="submit" onClick={handleSave} variant="primary" disabled={disableSave}>
            {saveTitle || T.translate('generic.save')}
          </Button>
        )}
      </BootstrapModal.Footer>
    </BootstrapModal>
  );
}

Modal.propTypes = {
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  saveTitle: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  disableClose: PropTypes.bool,
  disableSave: PropTypes.bool,
  buttons: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
};
Modal.defaultProps = {};
