import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';

function PopoverStickOnHover({
  delay: { show: showDelay, hide: hideDelay },
  onMouseEnter,
  children,
  component,
  placement,
  style,
}: {
  delay: any;
  onMouseEnter: any;
  children: any;
  component: any;
  placement: any;
  style?: any;
}) {
  const [showPopover, setShowPopover] = useState(false);
  const childNode = useRef(null);
  let setTimeoutConst = null as any,
    setTimeoutHide = null as any;

  useEffect(() => {
    return () => {
      if (setTimeoutConst) {
        clearTimeout(setTimeoutConst);
      }
    };
  });

  const handleMouseEnter = () => {
    clearTimeout(setTimeoutHide);
    setTimeoutConst = setTimeout(() => {
      setShowPopover(true);
      onMouseEnter();
    }, showDelay);
  };

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst);
    setTimeoutHide = setTimeout(() => {
      setShowPopover(false);
    }, hideDelay);
  };

  const displayChild = React.Children.map(children, child =>
    React.cloneElement(child, {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,

      ref: (node: any) => {
        childNode.current = node;
        const { ref } = child;
        if (typeof ref === 'function') {
          ref(node);
        }
      },
    }),
  )[0];

  return (
    <>
      {displayChild}
      <Overlay show={showPopover} placement={placement} target={childNode}>
        <Popover
          id="popoverStickOnHover"
          onMouseEnter={() => {
            clearTimeout(setTimeoutHide);
            setShowPopover(true);
          }}
          onMouseLeave={handleMouseLeave}
          style={style}
          onClick={e => {
            e.stopPropagation();
          }}
          onDoubleClick={e => {
            e.stopPropagation();
          }}
        >
          {component}
        </Popover>
      </Overlay>
    </>
  );
}

PopoverStickOnHover.propTypes = {
  children: PropTypes.element.isRequired,
  delay: PropTypes.object,
  onMouseEnter: PropTypes.func,
  component: PropTypes.node.isRequired,
  placement: PropTypes.string.isRequired,
};

PopoverStickOnHover.defaultProps = {
  delay: { show: 0, hide: 0 },
  onMouseEnter: () => {},
};

export default PopoverStickOnHover;
