import React, { useState, useEffect } from 'react';
import T from 'i18n';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, CircularProgress, IconButton, Popover } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import TranscriptMenu from './TrascriptMenu';
import { transcriptModes } from '../enums';
import TranscriptSettings from './TranscriptSettings';
import equal from 'react-fast-compare';
import { CancelPresentation } from '@mui/icons-material';
import { usePrevious } from 'utils/hooks';
import TranscriptEditableItems from './TranscriptEditableItems';
import { useUpdateRecording } from '../../redux/updateRecording';
import TranscriptItem from './TranscriptItem';

const useStyles = makeStyles(() =>
  createStyles({
    transcriptSidebar: {
      color: '#777',
      background: 'transparent',
      borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
      height: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      marginLeft: '1rem',
    },
    transcriptSidebarHeading: {
      marginTop: '1rem',
      flexGrow: 1,
      fontWeight: '600',
    },
    transcriptModalHeading: {
      marginTop: '0.5rem',
      fontWeight: '600',
    },
    editModeMsg: {
      padding: '1rem',
      paddingTop: 0,
    },
    transcriptSection: {
      width: '100%',
      height: '100%',
    },
    transcriptList: {
      listStyleType: 'none',
      padding: 0,
    },
    transcriptItem: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0.5rem 1rem',
    },
    transcriptItemSpeaker: {
      width: '15%',
      marginRight: '1rem',
    },
    transcriptItemText: {
      width: '85%',
      marginLeft: '1rem',
      '&:hover': { cursor: 'pointer' },
    },
    transcriptItemOffset: {
      fontSize: '1rem',
    },
    spinner: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      marginTop: '60%',
    },
  }),
);

type TranscriptProps = {
  videoDetails: any;
  isFullSizeViewer: any;
  transcript: any;
  anchorEl: any;
  openPopover: any;
  handlePopoverClose: any;
  handlePlayerSliderChange: any;
  recordingTime: any;
  dispatch: any;
};

const Transcript = ({
  videoDetails,
  isFullSizeViewer,
  transcript,
  anchorEl,
  openPopover,
  handlePopoverClose,
  handlePlayerSliderChange,
  recordingTime,
  dispatch,
}: TranscriptProps) => {
  const classes = useStyles();
  const prevTranscript = usePrevious(transcript);
  const { updateRecordingPending } = useUpdateRecording();
  const [transcriptList, setTranscriptList] = useState<any>(null);
  const [showRecordingTime, setShowRecordingTime] = useState(false);
  const [showParagraphView, setShowParagrapghView] = useState(false);
  const [transcriptMode, setTranscriptMode] = useState(transcriptModes.VIEW);
  const [isCtrlPressed, setIsCntrlPressed] = useState(false);

  useEffect(() => {
    if (!transcriptList || !equal(transcript, prevTranscript)) {
      if (showParagraphView) {
        setTranscriptList(
          [...transcript].reduce((acc, itm, index) => {
            if (index === 0 || acc[acc.length - 1].speaker !== itm.speaker)
              return [...acc, { ...itm, paragraphText: itm.text }];
            else {
              acc[acc.length - 1].paragraphText =
                acc[acc.length - 1].paragraphText + ` ${itm.text}`;
              return acc;
            }
          }, []),
        );
      } else setTranscriptList(transcript);
    }
  }, [showParagraphView, transcript, prevTranscript, transcriptList]);

  useEffect(() => {
    const onKeyDown = (event: any) => {
      if (event.ctrlKey) setIsCntrlPressed(true);
    };
    const onKeyUp = () => {
      if (isCtrlPressed) setIsCntrlPressed(false);
    };
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
      window.addEventListener('keyup', onKeyUp);
    };
  }, [isCtrlPressed]);

  return transcriptMode === transcriptModes.VIEW || transcriptMode === transcriptModes.EDIT ? (
    <>
      {isFullSizeViewer ? (
        <div className={classes.transcriptSidebar}>
          <div className="description-class" style={{ paddingLeft: '1rem' }}>
            <h3 className={classes.transcriptSidebarHeading}>
              {transcriptMode === transcriptModes.VIEW
                ? T.translate('viewing.transcript')
                : T.translate('viewing.editMode')}
            </h3>
            {transcriptMode === transcriptModes.VIEW ? (
              <TranscriptMenu setTranscriptMode={setTranscriptMode} />
            ) : (
              <IconButton onClick={() => setTranscriptMode(transcriptModes.VIEW)}>
                <CancelPresentation fontSize="large" />
              </IconButton>
            )}
          </div>
          {transcriptMode === transcriptModes.EDIT && (
            <div className={classes.editModeMsg}>{T.translate('viewing.editModeMsg')}</div>
          )}
          <div className={classes.transcriptSection}>
            {updateRecordingPending ? (
              <div className={classes.spinner}>
                <CircularProgress />
              </div>
            ) : (
              <PerfectScrollbar>
                {transcriptList && (
                  <ul className={classes.transcriptList}>
                    {transcriptList.map((item: any, index: any) => {
                      const recordedSpeaker = videoDetails.recordedSpeakers.find(
                        ({ mappedToDetectedSpeaker }: any) =>
                          mappedToDetectedSpeaker === item.speaker,
                      );
                      return transcriptMode === transcriptModes.VIEW ? (
                        <TranscriptItem
                          index={index}
                          transcriptItem={{ ...item, recordedSpeaker: recordedSpeaker?.name }}
                          handlePlayerSliderChange={handlePlayerSliderChange}
                          isFullSizeViewer={isFullSizeViewer}
                          recordingTime={recordingTime}
                          showRecordingTime={showRecordingTime}
                          showParagraphView={showParagraphView}
                        />
                      ) : (
                        <TranscriptEditableItems
                          index={index}
                          videoDetails={videoDetails}
                          transcriptItem={{ ...item, recordedSpeaker: recordedSpeaker?.name }}
                          isCtrlPressed={isCtrlPressed}
                          handlePlayerSliderChange={handlePlayerSliderChange}
                        />
                      );
                    })}
                  </ul>
                )}
              </PerfectScrollbar>
            )}
          </div>
        </div>
      ) : (
        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Box sx={{ maxWidth: '25vw' }}>
            <div style={{ padding: '0.5rem' }}>
              <h4 className={classes.transcriptModalHeading}>
                {T.translate('viewing.transcript')}
              </h4>
            </div>
            <PerfectScrollbar options={{ suppressScrollX: true }} style={{ maxHeight: '35vh' }}>
              <ul className={classes.transcriptList}>
                {transcript.map((item: any, index: number) => (
                  <TranscriptItem
                    index={index}
                    transcriptItem={item}
                    handlePlayerSliderChange={handlePlayerSliderChange}
                    handlePopoverClose={handlePopoverClose}
                    recordingTime={recordingTime}
                  />
                ))}
              </ul>
            </PerfectScrollbar>
          </Box>
        </Popover>
      )}
    </>
  ) : (
    <TranscriptSettings
      videoDetails={videoDetails}
      setTranscriptMode={setTranscriptMode}
      setShowRecordingTime={setShowRecordingTime}
      showRecordingTime={showRecordingTime}
      showParagraphView={showParagraphView}
      setShowParagrapghView={setShowParagrapghView}
      setTranscriptList={setTranscriptList}
      transcriptMode={transcriptMode}
      dispatch={dispatch}
    />
  );
};

export default Transcript;
