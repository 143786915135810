import React from 'react';
import T from 'i18n';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Checkbox, Collapse, IconButton, ListItem, Typography } from '@mui/material';

const DocumentsList = ({ filters, handleChangeFilters }: any) => {
  const [showDocuments, setShowDocuments] = React.useState(true);

  return (
    <>
      <ListItem sx={{ justifyContent: 'space-between', alignItems: 'center', py: 0 }}>
        <Typography sx={{ color: 'lightgray' }}>{T.translate('case.files')}</Typography>
        <IconButton onClick={() => setShowDocuments(!showDocuments)}>
          {showDocuments ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
      <Collapse in={showDocuments}>
        {filters.files.map((file: any) => (
          <ListItem
            key={file.id}
            sx={{ justifyContent: 'space-between', alignItems: 'center', py: 0 }}
          >
            <Typography sx={{ pl: '0.5rem' }}>{`${file.id} - ${file.name}`}</Typography>
            <Checkbox
              color="primary"
              name={file.id}
              onChange={e => {
                handleChangeFilters({
                  files: e.target.checked
                    ? [...filters.files, { id: file.id, name: file.name }]
                    : filters.files.filter((f: any) => f.id !== file.id),
                });
              }}
              checked={!!filters.files.find((f: any) => f.id === file.id)}
              sx={{ '&.Mui-checked': { color: '#7BA0F4' } }}
            />
          </ListItem>
        ))}
      </Collapse>
    </>
  );
};

export default DocumentsList;
