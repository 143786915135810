import { CASE_SET_AITOOLS_FILTERS, CASE_SET_AITOOLS_FILTERS_FAILURE } from './constants';

export function setAiToolsFilters(filters) {
  return {
    type: CASE_SET_AITOOLS_FILTERS,
    filters,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_SET_AITOOLS_FILTERS:
      return {
        ...state,
        aiToolsFilters: action.filters,
      };
    case CASE_SET_AITOOLS_FILTERS_FAILURE:
      return {
        ...state,
        aiToolsFilters: [],
        aiToolsFiltersFeedback: action.feedback,
      };

    default:
      return state;
  }
}
