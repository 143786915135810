import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';

export default function Logo({ showIcon, showText }) {
  return (
    <div className="logo">
      {showIcon && (
        <a href="/" className="simple-text logo-mini">
          <div className="logo-img">
            <FontAwesomeIcon icon={faHome} alt={faHome.iconName} />
          </div>
        </a>
      )}
      {showText && (
        <a href="/" className="simple-text logo-normal">
          {T.translate('case.trialview')}
        </a>
      )}
    </div>
  );
}

Logo.propTypes = {};
Logo.defaultProps = { showIcon: true, showText: true };
