export const AUTH_SET_HUB_CONNECTION_ID = 'AUTH_SET_HUB_CONNECTION_ID';
export const AUTH_SET_HUB_CONNECTION_ID_FAILURE = 'AUTH_SET_HUB_CONNECTION_ID_FAILURE';
export const AUTH_LOGIN_REQUIRED = 'AUTH_LOGIN_REQUIRED';
export const AUTH_LOGIN_REQUIRED_REMOVE = 'AUTH_LOGIN_REQUIRED_REMOVE';
export const AUTH_UPDATE_USER_SESSION = 'AUTH_UPDATE_USER_SESSION';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGIN_ACCEPT_INVITE = 'AUTH_LOGIN_ACCEPT_INVITE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE = 'AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE';
export const AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE_FAILURE =
  'AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE_FAILURE';
