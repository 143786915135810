import React, { Component } from 'react';
import T from 'i18n';
import Logger from 'utils/logger';
import { appInsights } from 'common/appInsights';
import { connect } from 'react-redux';
import { selectUserId } from 'common/selectors';
import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Logger.ERROR(error, info);
    appInsights?.trackException({
      error: error,
      exception: error,
      severityLevel: 'Error',
      properties: { ...info, currentUserId: this.props.currentUserId },
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage text={T.translate('generic.somethingWentWrong')} />;
    }
    return this.props.children;
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    currentUserId: selectUserId(state),
  };
}

export default connect(mapStateToProps)(ErrorBoundary);
