import {
  CASE_TEAM_MEMBER_RESEND_INVITE_BEGIN,
  CASE_TEAM_MEMBER_RESEND_INVITE_SUCCESS,
  CASE_TEAM_MEMBER_RESEND_INVITE_FAILURE,
  CASE_TEAM_MEMBER_RESEND_INVITE_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const reSendInvite = createPromiseAction(CASE_TEAM_MEMBER_RESEND_INVITE_BEGIN);

export function dismissReSendInviteFeedback() {
  return {
    type: CASE_TEAM_MEMBER_RESEND_INVITE_DISMISS_FEEDBACK,
  };
}

export function* doReSendInvite(action) {
  const { caseId, teamId, user } = action.payload;

  let res = yield call(api.post, `/cases/${caseId}/teams/${teamId}/re-invites/${user.id}`);

  if (res && res.error) {
    yield put({
      type: CASE_TEAM_MEMBER_RESEND_INVITE_FAILURE,
      feedback: {
        message: 'feedback.reSendInviteFailure',
        params: { userEmail: user.mail },
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_TEAM_MEMBER_RESEND_INVITE_SUCCESS,
    data: { res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchReSendInvite() {
  yield takeEvery(reSendInvite, withCurrentCaseId(doReSendInvite));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_TEAM_MEMBER_RESEND_INVITE_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        reSendInvitePending: true,
        reSendInviteFeedback: null,
      };

    case CASE_TEAM_MEMBER_RESEND_INVITE_SUCCESS:
      // The request is success
      return {
        ...state,
        reSendInvitePending: false,
        reSendInviteFeedback: action.feedback,
      };

    case CASE_TEAM_MEMBER_RESEND_INVITE_FAILURE:
      // The request is failed
      return {
        ...state,
        reSendInvitePending: false,
        reSendInviteFeedback: action.feedback,
      };

    case CASE_TEAM_MEMBER_RESEND_INVITE_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        reSendInviteFeedback: null,
      };

    default:
      return state;
  }
}
