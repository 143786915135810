import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  CASE_GENERATE_HYPERLINKS_BEGIN,
  CASE_GENERATE_HYPERLINKS_SUCCESS,
  CASE_GENERATE_HYPERLINKS_FAILURE,
  CASE_GENERATE_HYPERLINKS_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from 'common/selectors';

export const generateHyperlinks = createPromiseAction(
  CASE_GENERATE_HYPERLINKS_BEGIN,
  (payloadData, payloadFileId) => ({
    data: payloadData,
    fileId: payloadFileId,
  }),
);

export function dismissGenerateHyperlinksFeedback() {
  return {
    type: CASE_GENERATE_HYPERLINKS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_GENERATE_HYPERLINKS_BEGIN actions
export function* doGenerateHyperlinks(action) {
  const {
    payload: { data, fileId, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);

  const res = yield call(api.put, `/cases/${caseId}/files/${fileId}/hyperlinks`, {
    ...data,
    limitToFolderId: data.useFileNames ? currentFolder.id : undefined,
  });

  if (res && res.error) {
    yield put({
      type: CASE_GENERATE_HYPERLINKS_FAILURE,
      feedback: {
        message: typeof res.error === 'string' ? res.error : 'feedback.generateHyperlinksFailure',
        params: { fileId },
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  // Dispatch success action out of try/catch so that render errors are not catched.

  yield put({
    type: CASE_GENERATE_HYPERLINKS_SUCCESS,
    feedback: {
      message: res && typeof res === 'string' ? res : 'feedback.generateHyperlinksSuccess',
      params: { fileId },
    },
  });
  return yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchGenerateHyperlinks() {
  yield takeEvery(generateHyperlinks, withCurrentCaseId(doGenerateHyperlinks));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_GENERATE_HYPERLINKS_BEGIN + '.TRIGGER':
      return {
        ...state,
        generateHyperlinksPending: true,
        generateHyperlinksFeedback: null,
      };

    case CASE_GENERATE_HYPERLINKS_SUCCESS:
      return {
        ...state,
        generateHyperlinksPending: false,
        generateHyperlinksFeedback: action.feedback,
      };

    case CASE_GENERATE_HYPERLINKS_FAILURE:
      return {
        ...state,
        generateHyperlinksPending: false,
        generateHyperlinksFeedback: action.feedback,
      };

    case CASE_GENERATE_HYPERLINKS_DISMISS_FEEDBACK:
      return {
        ...state,
        generateHyperlinksFeedback: null,
      };

    default:
      return state;
  }
}
