import React from 'react';
import AddNewEvent from './AddNewEvent';
import { annotationsTableAction } from '../enums';
import UpdateAnnotationModal from './UpdateAnnotationsModal';
import DeleteAnnotationModal from './DeleteAnnotationsModal';

export default ({ show, action, handleClose, handleSave, selectedRows }: any) => {
  return (
    <>
      {action === annotationsTableAction.update && (
        <UpdateAnnotationModal
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
        />
      )}
      {(action === annotationsTableAction.delete ||
        action === annotationsTableAction.deleteAnnotaion) && (
        <DeleteAnnotationModal
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
        />
      )}
      {action === annotationsTableAction.addNewEvent && (
        <AddNewEvent
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
    </>
  );
};
