import { Button, Chip, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import T from 'i18n';
import SearchInput from './SearchInput';
import SearchInfo from './SearchInfo';
import { Compare, PhotoFilter } from '@mui/icons-material';

interface NewSearchContainerProps {
  handleChangeFilters: Function;
  handleSearch: Function;
  filters: any;
}

export const NewSearchContainer = ({
  handleChangeFilters,
  handleSearch,
  filters,
}: NewSearchContainerProps) => {
  const [summaryTopic, setSummaryTopic] = React.useState<null | String>(null);
  const summarizeDoc = (docId: string) => {
    const topic = `Summarize ${docId}`;
    handleChangeFilters({ phrase: topic, files: [] });
    setSummaryTopic(topic);
  };

  useEffect(() => {
    if (summaryTopic && summaryTopic === filters.phrase) {
      handleSearch();
      setSummaryTopic(null);
    }
    return () => {
      setSummaryTopic(null);
    };
  }, [summaryTopic, filters.phrase, handleSearch]);
  return (
    <>
      <Grid
        container
        height="100%"
        sx={{ alignContent: 'center', justifyContent: 'center', mt: '-4.5rem' }}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" sx={{ color: '#212529', fontWeight: 700 }}>
            {T.translate('case.enterTopicHEading')}
          </Typography>
          <Button
            style={{
              padding: 0,
              height: 'fit-content',
              minWidth: 0,
            }}
          >
            <SearchInfo isNewSearch={true} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <SearchInput
            filters={filters}
            handleChangeFilters={handleChangeFilters}
            handleSearch={handleSearch}
            isAnswersMode={false}
            isNewSearch={true}
          />
        </Grid>
        {(filters.files.length === 1 || filters.files.length === 2) && (
          <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
            {filters.files.length === 1 && (
              <Chip
                icon={<PhotoFilter color="primary" />}
                label={`Summarize ${filters.files[0].id}`}
                variant="outlined"
                sx={{ fontSize: '1.1rem' }}
                onClick={() => summarizeDoc(filters.files[0].id)}
              />
            )}
            {filters.files.length === 2 && (
              <Chip
                icon={<Compare color="primary" />}
                variant="outlined"
                label={`Compare ${filters.files[0].id} & ${filters.files[1].id}`}
                sx={{ fontSize: '1.1rem', ml: 1 }}
                disabled
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
};
