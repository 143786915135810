import React from 'react';
import T from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { appInsights } from 'common/appInsights';
import { selectUserId } from 'common/selectors';
import { useSelector } from 'react-redux';

export const useRefreshPage = () => {
  const currentUserId = useSelector(selectUserId);
  return () => {
    appInsights?.trackEvent('Refresh when having issues', { currentUserId });
    window.location.reload();
  };
};

export default ({ className }) => {
  const refreshPage = useRefreshPage();

  return (
    <div className={className}>
      <OverlayTrigger
        placement="left"
        delay={{ show: 1000, hide: 400 }}
        overlay={
          <Popover>
            <Popover.Content>{T.translate('case.issuesRefresh')}</Popover.Content>
          </Popover>
        }
      >
        <Nav.Link className="d-inline-flex" onClick={refreshPage}>
          <FontAwesomeIcon icon={faSync} />
          <p className="d-none  ml-3">{T.translate('case.theatre')}</p>
        </Nav.Link>
      </OverlayTrigger>
    </div>
  );
};
