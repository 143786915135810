import {
  AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE,
  AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE_FAILURE,
} from './constants';

export function setHubConnectionTransportType(connectionType) {
  return {
    type: AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE,
    connectionType,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE:
      return {
        ...state,
        hubConnectionTransportType: action.connectionType,
      };
    case AUTH_SET_HUB_CONNECTION_TRANSPORT_TYPE_FAILURE:
      return {
        ...state,
        hubConnectionFeedback: action.feedback,
      };

    default:
      return state;
  }
}
