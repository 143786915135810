const initialState = {
  fetchCasesPending: true,
  fetchCasesFailure: false,
  newCase: false,
  caseRegionTemp: null,
  fetchCasesFeedback: null,
  cases: [],
  createCasePending: false,
  createCaseFeedback: null,
  updateCaseFeedback: null,
};

export default initialState;
