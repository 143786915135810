import React, { useState, useEffect } from 'react';
import T from 'i18n';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import InfoRow from 'features/common/InfoRow';
import InfoTwoRows from 'features/common/InfoTwoRows';
import { useGetMoreInfoDocument } from './redux/hooks';
import Spinner from 'features/common/Spinner';
import { formatBytes } from 'utils/formating';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { CollapsibleActivityLogs } from 'features/common/ActivityLogs';

export const FileDetails = ({ row, columns, DefaultShowMoreInfo }) => {
  const {
    id,
    folderSpecificName,
    name,
    originalName,
    type,
    pageCount,
    docDate,
    author,
    recipient,
    discoveryId,
    externalId,
    description,
    createdBy,
    lastEditedBy,
    assignedBy,
    assignedTo,
    locations,
    size,
  } = row;
  const { getMoreInfoDocument, getMoreInfoDocumentPending } = useGetMoreInfoDocument();
  const [showMoreInfo, setShowMoreInfo] = useState(DefaultShowMoreInfo);

  useEffect(() => {
    if (DefaultShowMoreInfo) {
      getMoreInfoDocument({ documentId: id }).then(res => setShowMoreInfo(res));
    }
  }, [DefaultShowMoreInfo, getMoreInfoDocument, id]);

  return (
    <>
      {folderSpecificName && <InfoRow field={name} title={'case.systemName'} />}
      {name && <InfoRow field={originalName} title={'case.originalName'} />}
      {recipient && <InfoRow field={recipient} title={'case.recipient'} />}
      {author && <InfoRow field={author} title={'case.author'} />}
      {docDate && !columns.includes('docDate') && <InfoRow field={docDate} title={'case.date'} />}
      {discoveryId && <InfoRow field={discoveryId} title={'case.discoveryId'} />}
      {externalId && <InfoRow field={externalId} title={'case.externalId'} />}
      {description && <InfoTwoRows field={description} title={'case.description'} />}
      {pageCount && !columns.includes('pageCount') && (
        <InfoRow field={pageCount} title={'case.pageCount'} />
      )}
      {size && !columns.includes('size') && (
        <InfoRow field={formatBytes(size)} title={'case.fileSize'} />
      )}
      {type && !columns.includes('fileType') && <InfoRow field={type} title={'case.type'} />}
      {assignedTo && assignedTo.length > 0 && (
        <InfoTwoRows
          field={assignedTo.map(item => T.translate(`case.${item}`)).join(', ')}
          title={'case.assignedTo'}
        />
      )}
      {locations && locations.length > 0 && (
        <InfoTwoRows field={locations.join(', ')} title={'case.locations'} />
      )}
      {getMoreInfoDocumentPending ? (
        <Spinner style={{ fontSize: '0.5rem' }} />
      ) : showMoreInfo ? (
        <>
          {createdBy && (
            <>
              <InfoRow field={createdBy.name} title={'case.createdByName'} />
              <InfoRow
                field={moment(createdBy.date)
                  .utc()
                  .format('DD/MM/YYYY HH:mm')}
                title={'case.createdByDate'}
              />
            </>
          )}
          {lastEditedBy && (
            <>
              <InfoRow field={lastEditedBy.name} title={'case.lastEditedByName'} />
              <InfoRow
                field={moment(lastEditedBy.date)
                  .utc()
                  .format('DD/MM/YYYY HH:mm')}
                title={'case.lastEditedByDate'}
              />
            </>
          )}
          {assignedBy && (
            <>
              <InfoRow field={assignedBy.name} title={'case.assignedByName'} />
              <InfoRow
                field={moment(assignedBy.date)
                  .utc()
                  .format('DD/MM/YYYY HH:mm')}
                title={'case.assignedByDate'}
              />
            </>
          )}
          <CollapsibleActivityLogs
            data={showMoreInfo}
            tableHeight={'20rem'}
            rowHeight={70}
            outsideTableMargin={0}
          />
        </>
      ) : (
        <Row style={{ margin: '0.1rem' }}>
          <Col>
            <Button
              variant="link"
              style={{ marginTop: '1rem', padding: 0, fontSize: 'inherit' }}
              onClick={() => {
                getMoreInfoDocument({ documentId: id }).then(res => setShowMoreInfo(res));
              }}
            >
              {T.translate('generic.moreInfo')}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};
