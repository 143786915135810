import { VIEWING_SET_DOC_SEARCH_SUCCESS } from './constants';

export function setDocSearch(docSearch, docSearchLocation = null) {
  return {
    type: VIEWING_SET_DOC_SEARCH_SUCCESS,
    docSearch,
    docSearchLocation,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_SET_DOC_SEARCH_SUCCESS:
      return {
        ...state,
        docSearch: action.docSearch,
        docSearchLocation: action.docSearchLocation,
      };
    default:
      return state;
  }
}
