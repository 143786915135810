import { call, put, takeLatest, delay } from 'redux-saga/effects';
import {
  COMMON_FETCH_NOTIFICATIONS_BEGIN,
  COMMON_FETCH_NOTIFICATIONS_SUCCESS,
  COMMON_FETCH_NOTIFICATION_SUCCESS,
  COMMON_FETCH_NOTIFICATIONS_FAILURE,
  COMMON_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK,
} from './constants';

export function fetchNotifications() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: COMMON_FETCH_NOTIFICATIONS_BEGIN,
  };
}

export function dismissFetchNotificationsFeedback() {
  return {
    type: COMMON_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on COMMON_FETCH_NOTIFICATIONS_SUCCESS actions
export function* doFetchNotifications(action) {
  // If necessary, use argument to receive the begin action with parameters.
  let res;
  try {
    // Do Ajax call or other async request here. delay(20) is just a placeholder.
    res = yield call(delay, 20);
  } catch (err) {
    yield put({
      type: COMMON_FETCH_NOTIFICATIONS_FAILURE,
      feedback: { message: 'feedback.fetchNotificationsFailure', error: err, retryAction: action },
    });
    return;
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: COMMON_FETCH_NOTIFICATIONS_SUCCESS,
    data: res,
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchNotifications() {
  yield takeLatest(COMMON_FETCH_NOTIFICATIONS_BEGIN, doFetchNotifications);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_NOTIFICATIONS_BEGIN:
      return {
        ...state,
        fetchNotificationsPending: true,
        fetchNotificationsFeedback: null,
      };

    case COMMON_FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        fetchNotificationsPending: false,
        fetchNotificationsFeedback: null,
        notifications: action.data,
      };

    case COMMON_FETCH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        fetchNotificationsPending: false,
        fetchNotificationsFeedback: action.feedback,
        notifications: [action.data, ...state.notifications],
      };

    case COMMON_FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        fetchNotificationsPending: false,
        fetchNotificationsFeedback: action.feedback,
      };

    case COMMON_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchNotificationsFeedback: null,
      };

    default:
      return state;
  }
}
