import {
  CASE_FETCH_LOGIN_HISTORY_BEGIN,
  CASE_FETCH_LOGIN_HISTORY_SUCCESS,
  CASE_FETCH_LOGIN_HISTORY_FAILURE,
  CASE_FETCH_LOGIN_HISTORY_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchLoginHistory = createPromiseAction(CASE_FETCH_LOGIN_HISTORY_BEGIN);

export function dismissFetchLoginHistoryFeedback() {
  return {
    type: CASE_FETCH_LOGIN_HISTORY_DISMISS_FEEDBACK,
  };
}

export function* doFetchLoginHistory(action) {
  const { caseId, userId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/users/${userId}/login-history`);

  if (res && res.error) {
    if (res && res.error && res.error.status === 404) {
      yield put({
        type: CASE_FETCH_LOGIN_HISTORY_SUCCESS,
        data: { loginHistory: {} },
      });
    } else
      yield put({
        type: CASE_FETCH_LOGIN_HISTORY_FAILURE,
        feedback: {
          message: 'feedback.fetchLoginHistoryFailure',
          error: res.error,
          retryAction: action,
        },
      });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_LOGIN_HISTORY_SUCCESS,
    data: { loginHistory: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchLoginHistory() {
  yield takeLatest(fetchLoginHistory, withCurrentCaseId(doFetchLoginHistory));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_LOGIN_HISTORY_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchLoginHistoryPending: true,
        fetchLoginHistoryFeedback: null,
      };

    case CASE_FETCH_LOGIN_HISTORY_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchLoginHistoryPending: false,
        fetchLoginHistoryFeedback: action.feedback,
        loginHistory: action.data.loginHistory,
      };

    case CASE_FETCH_LOGIN_HISTORY_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchLoginHistoryPending: false,
        fetchLoginHistoryFeedback: action.feedback,
      };

    case CASE_FETCH_LOGIN_HISTORY_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchLoginHistoryFeedback: null,
      };

    default:
      return state;
  }
}
