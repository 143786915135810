import { put, takeLatest, select, call } from 'redux-saga/effects';
import {
  HOME_FETCH_CASES_BEGIN,
  HOME_FETCH_CASES_SUCCESS,
  HOME_FETCH_CASES_FAILURE,
  HOME_FETCH_CASES_DISMISS_FEEDBACK,
  HOME_FETCH_CASES_SUCCESS_DUMMY,
} from './constants';
import { AUTH_LOGIN } from 'features/auth/redux/constants';
import api from 'common/api';
import { selectCases, selectAcceptTsAndCsSuccess, selectIsNewCase } from 'common/selectors';

export function fetchCases() {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: HOME_FETCH_CASES_BEGIN,
  };
}

export function dismissFetchCasesFeedback() {
  return {
    type: HOME_FETCH_CASES_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_GET_USER_DETAILS_BEGIN actions
export function* doFetchCases(action) {
  const acceptTsAndCsSuccess = yield select(selectAcceptTsAndCsSuccess);
  const isNewCase = yield select(selectIsNewCase);
  const cases = acceptTsAndCsSuccess || isNewCase ? [] : yield select(selectCases);
  // don't fetch cases if we have them already,
  // but still trigger success action so hub connectionId can be loaded
  if (cases.length > 0) {
    return yield put({
      type: HOME_FETCH_CASES_SUCCESS_DUMMY,
    });
  }

  let res = yield call(api.get, `/users-me`);

  if (res && res.error) {
    yield put({
      type: HOME_FETCH_CASES_FAILURE,
      feedback: { message: 'feedback.fetchCasesFailure', error: res.error, retryAction: action },
    });
    return;
  }

  yield put({
    type: HOME_FETCH_CASES_SUCCESS,
    data: res,
  });
}
/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchCases() {
  yield takeLatest([HOME_FETCH_CASES_BEGIN, AUTH_LOGIN], doFetchCases);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case HOME_FETCH_CASES_BEGIN:
      return {
        ...state,
        fetchCasesPending: true,
        fetchCasesFeedback: null,
      };

    case HOME_FETCH_CASES_SUCCESS:
      return {
        ...state,
        fetchCasesPending: false,
        fetchCasesFailure: false,
        fetchCasesFeedback: action.feedback,
        cases:
          action.data.cases &&
          Array.isArray(action.data.cases) &&
          action.data.cases.map(
            ({
              id,
              name,
              claimNumber,
              permissions,
              userNeedsToAcceptTsAndCs,
              showTranscript,
              showTranscriptPrivate,
            }) => ({
              id,
              name,
              claimNumber,
              userNeedsToAcceptTsAndCs,
              showTranscript,
              showTranscriptPrivate,
              canViewRealTime:
                permissions && permissions.hearingRoom && permissions.hearingRoom.canViewRealTime,
              canBrowseDocuments:
                permissions && permissions.documents && permissions.documents.list,
              canJoinConference: (
                (permissions && permissions.hearingRoom && permissions.hearingRoom.join) ||
                []
              ).includes('public'),
              canViewDocuments: (
                (permissions && permissions.hearingRoom && permissions.hearingRoom.viewDocuments) ||
                []
              ).includes('public'),
              canStream: (
                (permissions && permissions.hearingRoom && permissions.hearingRoom.stream) ||
                []
              ).includes('public'),
              canJoinConferencePrivate: (
                (permissions && permissions.hearingRoom && permissions.hearingRoom.join) ||
                []
              ).includes('private'),
              canViewDocumentsPrivate: (
                (permissions && permissions.hearingRoom && permissions.hearingRoom.viewDocuments) ||
                []
              ).includes('private'),
              canStreamPrivate: (
                (permissions && permissions.hearingRoom && permissions.hearingRoom.stream) ||
                []
              ).includes('private'),
              canViewTeamAdmin: permissions && permissions.admin && permissions.admin.team,
              canViewCaseAdmin: permissions && permissions.admin && permissions.admin.caseAdmin,
              canViewModerator: permissions && permissions.admin && permissions.admin.moderator,
            }),
          ),
      };

    case HOME_FETCH_CASES_FAILURE:
      return {
        ...state,
        fetchCasesPending: false,
        fetchCasesFailure: true,
        fetchCasesFeedback: action.feedback,
      };

    case HOME_FETCH_CASES_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchCasesFeedback: null,
      };

    default:
      return state;
  }
}
