import React, { useState } from 'react';
import { Typography, Grid, Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import T from 'i18n';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    screenShareBanner: {
      zIndex: 8,
      position: 'absolute',
      width: '100%',
      height: '108px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      pointerEvents: 'none',
      '& button': {
        pointerEvents: 'all',
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
      [theme.breakpoints.down('sm')]: {
        bottom: `${theme.trialViewFooterHeight + theme.mobileFooterHeight}px`,
      },
    },
  }),
);

export default ({
  unpauseTheatreMode,
  disconnectPauseTheatreMode,
}: {
  unpauseTheatreMode: (e: any) => void;
  disconnectPauseTheatreMode: () => void;
}) => {
  const classes = useStyles();

  const [disconnected, setDisconnected] = useState(false);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.screenShareBanner}
    >
      <Typography variant="h6">
        {T.translate(disconnected ? 'viewing.disconnected' : 'viewing.paused')}
      </Typography>
      {disconnected ? (
        <Button onClick={unpauseTheatreMode}>{T.translate('viewing.reconnect')}</Button>
      ) : (
        <Button
          onClick={() => {
            disconnectPauseTheatreMode();
            setDisconnected(true);
          }}
        >
          {T.translate('viewing.disconnect')}
        </Button>
      )}
    </Grid>
  );
};
