import React, { memo } from 'react';
// import PropTypes from 'prop-types';
import StickyDropdown from 'features/common/StickyDropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import { useSelector } from 'react-redux';
import { selectCurrentCaseId, selectCurrentCaseTeamId, selectPermissions } from 'common/selectors';
import history from 'common/history';
import { ChangeTeam } from './TeamAdminModal';
import { teamAdminModal } from './enums';
import { useDispatch } from 'react-redux';
import * as actions from 'features/case/redux/actions';

// className gets mapped onto it, keep it
const CaseSettingsButton = memo(({ className, isTeamAdmin, isSystemAdmin, isCaseAdmin }) => {
  const dispatch = useDispatch();
  const {
    hearingRoom: { conferenceType },
  } = useSelector(selectPermissions);
  const currentCaseId = useSelector(selectCurrentCaseId);
  const currentTeamId = useSelector(selectCurrentCaseTeamId);
  const selectedConference = conferenceType && conferenceType('public');
  const [showChangeTeamModal, setShowChangeTeamModal] = React.useState(false);

  const changeTeamHandler = (action, data, teamId) => {
    const sendInviteHandler = () => {
      dispatch(
        actions.sendInvite({
          teamId: data.newTeamId,
          email: data.data,
          sendInvite: false,
          changeTeam: true,
        }),
      )
        .then(() => {
          dispatch(actions.fetchCaseDetails());
          dispatch(actions.fetchContainers());
        })
        .catch(err => {
          console.error(err);
          return false;
        });
    };
    teamId
      ? dispatch(actions.removeTeamMember({ teamId, user: data }))
          .then(() => sendInviteHandler())
          .catch(err => {
            console.error(err);
          })
      : sendInviteHandler();
  };

  const title = (
    <div className="d-inline-flex">
      <FontAwesomeIcon icon={faCog} alt={faCog.iconName} />
      <p className="d-none ml-3">{T.translate('generic.settings')}</p>
    </div>
  );

  const items = (
    <React.Fragment>
      {isSystemAdmin && !isCaseAdmin && (
        <NavDropdown.Item
          onClick={() =>
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) +
                `/case/${currentCaseId}/settings/case-admin/case-settings`,
            )
          }
        >
          <p>{T.translate('case.caseSettings')}</p>
        </NavDropdown.Item>
      )}
      {isCaseAdmin && (
        <NavDropdown.Item
          onClick={() =>
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) +
                `/case/${currentCaseId}/settings/case-admin`,
            )
          }
        >
          <p>{T.translate('case.caseAdmin')}</p>
        </NavDropdown.Item>
      )}
      {isTeamAdmin && !isCaseAdmin && (
        <NavDropdown.Item
          onClick={() =>
            history.push(
              history.location.pathname.replace(/\/case.*/, ``) +
                `/case/${currentCaseId}/settings/team-admin`,
            )
          }
        >
          <p>{T.translate('case.teamAdmin')}</p>
        </NavDropdown.Item>
      )}
      {isCaseAdmin && (
        <NavDropdown.Item onClick={() => setShowChangeTeamModal(true)}>
          <p>{T.translate('generic.changeTeam')}</p>
        </NavDropdown.Item>
      )}
      <NavDropdown.Item
        onClick={() =>
          history.push(
            history.location.pathname.replace(/\/case.*/, ``) +
              `/case/${currentCaseId}/settings/role-settings`,
          )
        }
      >
        <p>{T.translate('case.roleSettings')}</p>
      </NavDropdown.Item>
    </React.Fragment>
  );

  return (
    <div className={className}>
      <StickyDropdown alignRight title={title} className="d-none">
        {items}
      </StickyDropdown>
      <NavDropdown id="user" alignRight title={title} className="d-l-none">
        {items}
      </NavDropdown>
      {showChangeTeamModal && (
        <ChangeTeam
          show={showChangeTeamModal}
          action={teamAdminModal.changeTeam}
          handleClose={() => setShowChangeTeamModal(false)}
          handleSave={changeTeamHandler}
          teamId={currentTeamId}
          fetchTeamDetails={true}
          selectedConference={selectedConference}
        />
      )}
    </div>
  );
});

CaseSettingsButton.propTypes = {};
CaseSettingsButton.defaultProps = {};

export default CaseSettingsButton;
