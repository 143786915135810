import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import T from 'i18n';
import { Modal } from 'features/common/MaterialBasedComponents';
import { Button, Divider, Grid } from '@mui/material';
import { Form } from 'react-bootstrap';
import { selectParams, selectTimeline } from 'common/selectors';
import { useSelector } from 'react-redux';
import { Spinner, TextForm } from 'features/common';
import { DocumentLookup } from 'features/viewing';
import EventsDateTimeForm from './EventsDateTimeForm';
import history from 'common/history';
import equal from 'react-fast-compare';
import { useFetchTimeline } from '../redux/fetchTimeline';

export default ({ show, action, handleClose, handleSave, selectedRows }: any) => {
  const [event, setEvent] = useState(selectedRows[0] || {});
  const [dateTimeVal, setDateTimeVal] = useState(
    event.date
      ? {
          date: event.date.split(' ')[0] || '',
          time: event.date.split(' ')[1] || '',
        }
      : { date: '', time: '' },
  );
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    trigger,
    reset,
  } = useForm({ defaultValues: { ...event, ...dateTimeVal } });
  const isTimeline = useSelector(selectTimeline);
  const { file: alreadyOpenedFile, quickLink } = useSelector(selectParams);

  const { timeline } = useFetchTimeline();
  const currentGridList = timeline?.events || [];
  const [loading, setLoading] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<string | null>(null);
  const [disableActionBtns, setDisableActionBtns] = useState({
    disableNext: false,
    disablePrev: false,
  });
  const isTimelines = isTimeline && quickLink === 'timelines';

  const selectNewDocument = (eventId: any) => {
    currentGridList.forEach((doc: any) => {
      if (doc.id === eventId) doc.selected = true;
      else doc.selected = false;
    });
  };

  const handleUserKeyPress = useCallback(
    (e: any) => {
      if (disableActionBtns.disablePrev && disableActionBtns.disableNext) return;
      if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowRight') {
        const nextBtn = document.getElementById('updateFileNextButton');
        nextBtn?.click();
      } else if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowLeft') {
        const prevBtn = document.getElementById('updateFilePrevButton');
        prevBtn?.click();
      }
    },
    [disableActionBtns],
  );

  const saveData = (data: any, eventId: any) => {
    const { date, time, ...rest } = data;
    const dateTime = !!time ? `${date} ${time}` : date;
    if (isTimelines && equal({ ...rest, date: dateTime }, event) && eventId)
      selectNewDocument(eventId);
    else handleSave(action, { ...rest, date: dateTime });
  };

  const prevClicked = (data: any) => {
    const currentEventIdx = currentGridList.findIndex((itm: any) => itm.id === event.id);
    if (currentEventIdx > 0) {
      setLoading(true);
      saveData({ ...event, ...data }, currentGridList[currentEventIdx - 1].id);
      reset({});
      setEvent(currentGridList[currentEventIdx - 1]);
      setDateTimeVal(
        currentGridList[currentEventIdx - 1] && currentGridList[currentEventIdx - 1].date
          ? {
              date: currentGridList[currentEventIdx - 1].date.split(' ')[0] || '',
              time: currentGridList[currentEventIdx - 1].date.split(' ')[1] || '',
            }
          : { date: '', time: '' },
      );
      setTimeout(() => {
        setLoading(false);
        setDisableActionBtns(
          currentEventIdx - 1 === 0
            ? { disableNext: false, disablePrev: true }
            : currentEventIdx - 1 === currentGridList.length - 1
            ? { disableNext: true, disablePrev: false }
            : { disableNext: false, disablePrev: false },
        );
      }, 0);
    }
  };

  const nextClicked = (data: any) => {
    const currentEventIdx = currentGridList.findIndex((itm: any) => itm.id === event.id);
    if (currentEventIdx < currentGridList.length - 1) {
      setLoading(true);
      saveData({ ...event, ...data }, currentGridList[currentEventIdx + 1].id);
      reset({});
      setEvent(currentGridList[currentEventIdx + 1]);
      setDateTimeVal(
        currentGridList[currentEventIdx + 1] && currentGridList[currentEventIdx + 1].date
          ? {
              date: currentGridList[currentEventIdx + 1].date.split(' ')[0] || '',
              time: currentGridList[currentEventIdx + 1].date.split(' ')[1] || '',
            }
          : { date: '', time: '' },
      );
      setTimeout(() => {
        setLoading(false);
        setDisableActionBtns(
          currentEventIdx + 1 === 0
            ? { disableNext: false, disablePrev: true }
            : currentEventIdx + 1 === currentGridList.length - 1
            ? { disableNext: true, disablePrev: false }
            : { disableNext: false, disablePrev: false },
        );
      }, 0);
    }
  };

  useEffect(() => {
    if (isTimelines) window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      if (isTimelines) window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress, isTimelines]);

  useEffect(() => {
    if (isTimelines) {
      if (alreadyOpenedFile && event.file) {
        if (alreadyOpenedFile !== event.file.id)
          history.push(
            history.location.pathname.replace(/\/files.*/, ``) + `/files/${event.file.id}`,
          );
      } else window.location.hash = event.id;
    }
  }, [alreadyOpenedFile, event, isTimelines]);

  return (
    <Modal
      show={show}
      handleClose={() => {
        setCurrentSelection(null);
        handleClose();
      }}
      handleSave={handleSubmit(data => {
        if (isTimelines) {
          saveData({ ...event, ...data }, null);
          setLoading(false);
          setDisableActionBtns({ disableNext: false, disablePrev: false });
        } else handleSave(action, { ...selectedRows[0], ...data });
        setCurrentSelection(null);
        handleClose();
      })}
      saveTitle={T.translate('generic.update')}
      title={T.translate('generic.update')}
      buttons={
        !isTimelines ? null : (
          <>
            <Button
              type="button"
              id="updateFilePrevButton"
              onClick={handleSubmit(data => prevClicked(data))}
              variant="contained"
              color="primary"
              disabled={disableActionBtns.disablePrev}
            >
              {T.translate('generic.previous')}
            </Button>
            <Button
              type="button"
              id="updateFileNextButton"
              onClick={handleSubmit(data => nextClicked(data))}
              variant="contained"
              color="primary"
              disabled={disableActionBtns.disableNext}
            >
              {T.translate('generic.next')}
            </Button>
          </>
        )
      }
      /*@ts-ignore*/
      hintContent={
        !isTimelines ? null : (
          <>
            <span>
              <b>{T.translate('generic.shortcuts')}</b>
            </span>
            <Divider />
            <span>{T.translate('common.shortcutPrevText')}</span>
            <br />
            <span>{T.translate('common.shortcutNextText')}</span>
            <br />
            <span>{T.translate('common.shortcutSaveText')}</span>
          </>
        )
      }
      draggableDialog
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Grid container spacing={2}>
                {!isTimelines && (
                  <Grid item xs={6}>
                    <TextForm
                      {...register('note')}
                      /*@ts-ignore*/
                      label={T.translate('case.privateNote')}
                      placeholder={T.translate('case.notePlaceholder')}
                      errors={errors}
                    />
                  </Grid>
                )}
                {isTimelines && (
                  <EventsDateTimeForm
                    register={register}
                    trigger={trigger}
                    errors={errors}
                    dateTimeVal={dateTimeVal}
                    setDateTimeVal={setDateTimeVal}
                    currentSelection={currentSelection}
                    setCurrentSelection={setCurrentSelection}
                    focusDateField={true}
                  />
                )}
              </Grid>
              {isTimelines && (
                <>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('name', { required: true })}
                        /*@ts-ignore*/
                        as="textarea"
                        label={T.translate('case.eventName')}
                        placeholder={T.translate('case.eventNamePlaceholder')}
                        errors={errors}
                        defaultValue={event.name || ''}
                        onFocus={(e: any) => setCurrentSelection(e.target.name)}
                        autofocus={currentSelection === 'name'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name="file"
                        control={control}
                        render={({ field }: any) => {
                          return (
                            <div>
                              <Form.Label>{T.translate('case.file')}</Form.Label>
                              <div style={{ border: '1px solid #E3E3E3', borderRadius: '4px' }}>
                                <DocumentLookup
                                  {...field}
                                  sx={{ padding: '4px 0px', flexGrow: 1 }}
                                  placeholder={T.translate('case.linkPlaceholder')}
                                  defaultValue={event.file}
                                  onFocus={(e: any) => setCurrentSelection('file')}
                                  autoFocus={currentSelection === 'file'}
                                />
                              </div>
                            </div>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('fileText')}
                        /*@ts-ignore*/
                        as="textarea"
                        label={T.translate('case.fileText')}
                        placeholder={T.translate('case.fileTextPlaceholder')}
                        defaultValue={event.fileText || ''}
                        onFocus={(e: any) => setCurrentSelection(e.target.name)}
                        autofocus={currentSelection === 'fileText'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register('comment')}
                        /*@ts-ignore*/
                        as="textarea"
                        label={T.translate('case.comment')}
                        placeholder={T.translate('case.commentPlaceholder')}
                        defaultValue={event.comment || ''}
                        onFocus={(e: any) => setCurrentSelection(e.target.name)}
                        autofocus={currentSelection === 'comment'}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          )}
        </Form.Group>
      </Form>
    </Modal>
  );
};
