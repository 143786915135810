import { buffers, eventChannel, END } from 'redux-saga';
import mockedData from 'common/mockedData';
import Logger from 'utils/logger';
import { withoutTrailingSlash } from 'utils/path';
import runningInDev from 'utils/runningInDev';
import configuredStore from 'common/configStore';
import { selectAccessToken, selectCaseRegionTemp, selectCountryCode } from 'common/selectors';
import T from 'i18n';

export const getAccessToken = () => {
  return selectAccessToken(configuredStore.store.getState());
};

const getCountryCode = () => {
  const tempcaseRegion = selectCaseRegionTemp(configuredStore.store.getState());
  if (tempcaseRegion) return tempcaseRegion;
  else return selectCountryCode(configuredStore.store.getState());
};

const createErrorFromResponse = ({ status, statusText }, body) => ({
  status,
  statusText,
  body,
});

const getBody = async response => {
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    try {
      return response.json();
    } catch (e) {
      Logger.ERROR('Wrong content-type header - parsing json failed');
    }
  } else if (contentType && contentType.indexOf('application/pdf') !== -1) {
    try {
      return response.arrayBuffer();
    } catch (e) {
      Logger.ERROR('Wrong content-type header - parsing blob failed');
    }
  } else {
    return response.text();
  }
};

const headersFrom = (headersInit = {}) => new Headers(headersInit);

function _fetch(url, init, processResponse, protectedApiCall = true) {
  const handleError = (error, method) => {
    if (runningInDev && process.env.REACT_APP_MOCKED === 'true') {
      error && Logger.ERROR('Error fetching', url, error);
      return mockedData(url, method);
    } else {
      return {
        error, //: JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))),
      };
    }
  };

  const code = getCountryCode();
  const accessToken = getAccessToken();

  if (!protectedApiCall || accessToken) {
    const headers = headersFrom(init.headers);
    if (protectedApiCall) headers.set('Authorization', `Bearer ${accessToken}`);
    const newInit = {
      ...init,
      headers: headers,
    };
    return fetch(withoutTrailingSlash(process.env[`REACT_APP_API_${code}`]) + url, newInit)
      .then(async response => {
        if (processResponse) return processResponse(response);

        return getBody(response).then(body => {
          if (response.ok) {
            if (response.status !== 204) return body;
            return;
          }
          return handleError(
            createErrorFromResponse(response, body).body ||
              T.translate('generic.beErrorDefaultMessage'),
            newInit.method,
          );
        });
      })
      .catch(error => {
        return handleError(error.message, newInit.method);
      });
  } else {
    return handleError('No access token');
  }
}

function get(url, init = {}, protectedApiCall) {
  const newInit = {
    ...init,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return _fetch(url, newInit, undefined, protectedApiCall);
}

function post(url, body) {
  const newInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return _fetch(url, newInit);
}

function postFile(url, body) {
  const newInit = {
    method: 'POST',
    body,
  };
  return _fetch(url, newInit);
}

const fileNameRegex = "filename[^;=\\n]*=(?:(\\\\?['\"])(.*?)\\1|(?:[^\\s]+'.*?')?([^;\\n]*))";
const fileNameEncodedRegex = "filename\*[^;=\\n]*=UTF-8''([^;\\n]*)";

function getFile(url, body) {
  const newInit = {
    method: 'GET',
    body,
  };
  const processResponse = response => {
    if (response.ok) {
      const content = response.headers.get('content-disposition');
      const fileName = content && content.match(fileNameRegex);
      return response.blob().then(x => ({
        blob: x,
        fileName:
          fileName &&
          ((fileName.length > 2 && fileName[3]) || (fileName.length > 1 && fileName[2])),
      }));
    } else return response.json().then(x => ({ error: x || response.status }));
  };
  return _fetch(url, newInit, processResponse);
}

function put(url, body) {
  const newInit = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return _fetch(url, newInit);
}

function del(url, body) {
  const newInit = body
    ? {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    : {
        method: 'DELETE',
      };
  return _fetch(url, newInit);
}

function createUploadFileChannel(method, url, file) {
  const accessToken = getAccessToken();
  const code = getCountryCode();
  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = ((e.loaded / e.total) * 100).toFixed(2);
        emitter({ progress });
      }
    };
    const onFailure = error => {
      emitter({ error });
      emitter(END);
    };
    xhr.timeout = 600000;
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status, responseText } = xhr;
      let body;
      try {
        body = JSON.parse(responseText);
      } catch (e) {
        body = responseText;
      }
      if (readyState === 4) {
        if ([200, 201].includes(status)) {
          emitter({ success: true, response: body });
          emitter(END);
        } else if (status === 204) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure({ ...createErrorFromResponse(xhr, body) });
        }
      }
    };
    xhr.open(method, withoutTrailingSlash(process.env[`REACT_APP_API_${code}`]) + url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    xhr.send(file);
    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}

function createDownloadFileChannel(url, useGivenUrl) {
  const processResponse = xhr => {
    if (xhr.status === 200) {
      const content = xhr.getResponseHeader('content-disposition');
      const fileName = content && content.match(fileNameEncodedRegex);
      return {
        blob: xhr.response,
        fileName:
        fileName && decodeURIComponent(fileName[1]),
      };
    } else if (xhr.status === 0) {
      return 'Possible timeout';
    } else {
      try {
        return xhr.responseText;
      } catch {
        return xhr.response;
      }
    }
  };

  const accessToken = getAccessToken();
  const code = getCountryCode();

  return eventChannel(emitter => {
    const xhr = new XMLHttpRequest();
    const onProgress = e => {
      if (e.lengthComputable) {
        const progress = ((e.loaded / e.total) * 100).toFixed(2);
        emitter({ progress });
      }
    };
    const onFailure = error => {
      emitter({ error });
      emitter(END);
    };
    xhr.timeout = 600000;
    xhr.addEventListener('progress', onProgress);
    xhr.addEventListener('error', onFailure);
    xhr.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if ([200, 201].includes(status)) {
          emitter({ success: true, response: processResponse(xhr) });
          emitter(END);
        } else if (status === 204) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure({ ...createErrorFromResponse(xhr, processResponse(xhr)) });
        }
      }
    };
    xhr.open(
      'GET',
      withoutTrailingSlash(useGivenUrl ? url : process.env[`REACT_APP_API_${code}`] + url),
      true,
    );
    xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
    xhr.responseType = 'blob';
    xhr.send();
    return () => {
      xhr.removeEventListener('progress', onProgress);
      xhr.removeEventListener('error', onFailure);
      xhr.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = null;
      xhr.abort();
    };
  }, buffers.sliding(2));
}

export default {
  get,
  put,
  del,
  post,
  postFile,
  getFile,
  createUploadFileChannel,
  createDownloadFileChannel,
};
