export const getSortFunction = (
  key: string,
  order: string = 'asc',
  onCustomSort: (val1: any, val2: any, col1: any, col2: any) => number,
) => {
  if (!key) return null;

  return function innerSort(a: any, b: any) {
    let comparison = 0;

    if (onCustomSort) {
      comparison = onCustomSort(a[key], b[key], a, b);
    } else {
      if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return comparison;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key] + '';
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key] + '';

      comparison = varA.localeCompare(varB, undefined, { numeric: true, sensitivity: 'base' });
    }

    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export function sortDocumentsWithAttachments(
  documents: any[],
  sortFunc: (a: any, b: any) => number,
): any[] {
  if (documents.length < 2) return documents;
  // Step 1: Separate main documents and attachments
  const attachmentsMap = documents.reduce((acc, doc) => {
    if (doc.isAttachedTo.length) {
      const parentId = doc.isAttachedTo[0].id;
      acc[parentId] = acc[parentId] || [];
      acc[parentId].push(doc);
    }
    return acc;
  }, {} as { [key: string]: any[] });

  function processDocument(mainDoc: any): any[] {
    const sortedForThisDoc: any[] = [mainDoc];

    const docAttachments = attachmentsMap[mainDoc.id];
    if (docAttachments) {
      docAttachments.sort((a: any, b: any) => {
        const aSequence = mainDoc.attachments.findIndex((attach: any) => attach.id === a.id);
        const bSequence = mainDoc.attachments.findIndex((attach: any) => attach.id === b.id);
        return aSequence - bSequence;
      });

      docAttachments.forEach((attachment: any) => {
        // Recursively process attachments that are also parents
        sortedForThisDoc.push(...processDocument(attachment));
      });
    }

    return sortedForThisDoc;
  }

  const mainDocuments = documents.filter(doc => !doc.isAttachedTo.length);
  // Step 2: Sort main documents
  mainDocuments.sort(sortFunc);

  const sortedDocuments: any[] = [];
  // Step 3: Insert attachments after their corresponding main document
  mainDocuments.forEach(mainDoc => {
    sortedDocuments.push(...processDocument(mainDoc));
  });

  return sortedDocuments;
}
