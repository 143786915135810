import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import * as actions from './redux/actions';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolderOpen,
  faPlusCircle,
  faTv,
  faLock,
  faDatabase,
  faUsersCog,
  faScroll,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import CaseCreateModal from 'features/case/CaseCreateModal';
import {
  selectIsAdmin,
  selectLastFolder,
  selectCases,
  selectFetchCasesFailureStatus,
  selectIsOrganizationAdmin,
} from 'common/selectors';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';
import { updateUserBehaviour } from 'features/common/redux/updateUserBehaviour';
import FilterCases from './FilterCases';
import history from 'common/history';
import { AddCircle } from '@mui/icons-material';
import { Divider, Fab, Tooltip } from '@mui/material';

const caseFilterLimit = 10;

const CardLink = ({ link, icon, icon2, labelKey, lastFolder, trackCardButtonClick }) => {
  return (
    <Link
      to={lastFolder ? `${link}/${lastFolder}` : link}
      onClick={() => trackCardButtonClick(labelKey)}
      target="_blank"
      className="case-card-link"
    >
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={icon} />
        {icon2 && <FontAwesomeIcon icon={icon2} transform="shrink-5 up-5 right-6" />}
      </span>
      {T.translate(labelKey)}
    </Link>
  );
};

const Card = ({
  id,
  name,
  claimNumber,
  canBrowseDocuments,
  canViewDocuments,
  canJoinConference,
  canStream,
  canViewDocumentsPrivate,
  canJoinConferencePrivate,
  canStreamPrivate,
  canViewCaseAdmin,
  canViewModerator,
  canViewRealTime,
  lastFolder,
  showTranscript,
  showTranscriptPrivate,
}) => {
  const dispatch = useDispatch();
  const showDocumentsLink = canBrowseDocuments;
  const documentsLink = `/case/${id}`;
  const showHearingRoomLink =
    canJoinConference || canStream || canViewModerator || canViewDocuments;
  const hearingRoomLink =
    (canJoinConference || canStream) && canViewDocuments
      ? `/case/${id}/public-hearing-room`
      : canViewDocuments
      ? `/case/${id}/public-hearing-room/document-presentation`
      : canJoinConference || canStream
      ? `/case/${id}/public-hearing-room/conference`
      : '/';
  const showPrivateRoomLink =
    (canJoinConferencePrivate || canStreamPrivate) && canViewDocumentsPrivate;
  const showTranscriptLink = showHearingRoomLink && showTranscript && canViewRealTime;
  const showTranscriptPrivateLink = showHearingRoomLink && showTranscriptPrivate && canViewRealTime;
  const privateRoomLink = `/case/${id}/private-team-room`;
  const showHearingRoomDocsOnlyLink = canViewDocuments;
  const hearingRoomDocsOnlyLink = `/case/${id}/public-hearing-room/document-presentation`;
  const showCaseAdminLink = canViewCaseAdmin;
  const caseAdminLink = `/case/${id}/settings/case-admin`;
  const transcriptLink = `/case/${id}/public-hearing-room-transcript`;
  const transcriptLinkPrivate = `/case/${id}/private-hearing-room-transcript`;

  const links = [
    showDocumentsLink && {
      link: documentsLink,
      icon: faDatabase,
      labelKey: 'home.dataRoom',
      lastFolder,
    },
    showHearingRoomLink &&
      hearingRoomLink !== hearingRoomDocsOnlyLink && {
        link: hearingRoomLink,
        icon: faTv,
        labelKey: 'home.hearingRoom',
      },
    showHearingRoomDocsOnlyLink && {
      link: hearingRoomDocsOnlyLink,
      icon: faFileAlt,
      labelKey: 'home.hearingRoomDocsOnly',
    },
    showPrivateRoomLink && {
      link: privateRoomLink,
      icon: faTv,
      icon2: faLock,
      labelKey: 'home.privateRoom',
    },
    showCaseAdminLink && {
      link: caseAdminLink,
      icon: faUsersCog,
      labelKey: 'home.caseAdmin',
    },
    showTranscriptLink && {
      link: transcriptLink,
      icon: faScroll,
      labelKey: 'home.transcript',
    },
    showTranscriptPrivateLink && {
      link: transcriptLinkPrivate,
      icon: faScroll,
      icon2: faLock,
      labelKey: 'home.transcriptPrivate',
    },
  ].filter(Boolean);

  const trackCardButtonClick = labelKey => {
    dispatch(
      updateUserBehaviour({
        caseId: id,
        name: `Home Card ${T.translate(labelKey)} Clicked`,
      }),
    );
  };

  return (
    <div className="case-card">
      <div className="links">
        {links.map((props, index) => (
          <CardLink key={index} {...props} trackCardButtonClick={trackCardButtonClick} />
        ))}
      </div>
      {links.length > 0 ? (
        <Link
          to={links[0].link}
          className="title"
          onClick={() => trackCardButtonClick('home.caseTitle')}
        >
          <span>{name}</span>
          <span>{claimNumber}</span>
        </Link>
      ) : (
        <div className="title" onClick={() => trackCardButtonClick('home.caseTitle')}>
          {name}
        </div>
      )}
    </div>
  );
};

const CardAdmin = ({ id, name }) => {
  return (
    <div key={id} className="case-card-admin">
      <Link to={`/case/${id}`}>
        <FontAwesomeIcon icon={faFolderOpen} alt="case_image" />
        <span>{name}</span>
      </Link>
    </div>
  );
};

const AddButton = ({ onClick }) => (
  <Button key="add-new-case" className="new-case" variant="link" onClick={onClick}>
    <FontAwesomeIcon icon={faPlusCircle} alt="faPlusCircle.iconName" />
    <span>{T.translate('home.createNewCase')}</span>
  </Button>
);

export class Cases extends Component {
  static propTypes = {
    cases: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
  };

  state = { showModal: false, loc: '', cases: [] };

  componentDidMount() {
    this.setState({ cases: this.props.cases });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.cases.length !== this.props.cases.length)
      this.setState({ cases: this.props.cases });
  }

  render() {
    return (
      <>
        {(this.props.cases.length > caseFilterLimit || this.props.isOrganizationAdmin) && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {this.props.cases.length > caseFilterLimit && (
              <FilterCases
                cases={this.props.cases}
                handleFilterCases={val => this.setState({ cases: val })}
              />
            )}
            {this.props.cases.length > caseFilterLimit &&
              this.props.isOrganizationAdmin &&
              this.props.cases.length > 0 && (
                <Divider orientation="vertical" sx={{ height: 'auto', mr: '2rem', ml: '2rem' }} />
              )}
            {this.props.isOrganizationAdmin && this.props.cases.length > 0 && (
              <Tooltip title={T.translate('home.createNewCase')} placement="top" arrow>
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => this.setState({ showModal: true })}
                >
                  <AddCircle />
                </Fab>
              </Tooltip>
            )}
          </div>
        )}
        <div
          className={classnames('home-cases', {
            'justify-content-center': this.props.cases.length <= 3,
          })}
        >
          {(this.props.isSystemAdmin ||
            (this.props.isOrganizationAdmin && this.props.cases.length === 0)) && (
            <AddButton onClick={() => this.setState({ showModal: true })} />
          )}
          {this.state.cases.map(props =>
            this.props.isSystemAdmin ? (
              <CardAdmin key={props.id} {...props} />
            ) : (
              <Card key={props.id} {...props} lastFolder={this.props.lastFolder[props.id]} />
            ),
          )}
          {this.props.cases.length > 0 && this.state.cases.length === 0 && (
            <div className="justify-message">{T.translate('home.noCaseFoundMessage')}</div>
          )}
          {this.props.cases.length === 0 &&
            (!this.props.fetchCasesFailureStatus ? (
              !this.props.isOrganizationAdmin && <div>{T.translate('home.noCasesMessage')}</div>
            ) : (
              <div>{T.translate('home.fetchCasesProbMessage')}</div>
            ))}
          {
            <CaseCreateModal
              show={this.state.showModal}
              handleClose={() => this.setState({ showModal: false })}
              handleSave={caseDetails =>
                this.props.actions
                  .createCase(caseDetails)
                  .then(({ id: currentCaseId }) => {
                    this.props.actions.fetchCases();
                    if (this.state.showModal) history.push(`/case/${currentCaseId}`);
                  })

                  .catch(err => console.error(err))
              }
            />
          }
        </div>
      </>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const fetchCasesFailureStatus = selectFetchCasesFailureStatus(state);
  const cases = selectCases(state);
  const isSystemAdmin = selectIsAdmin(state);
  const isOrganizationAdmin = selectIsOrganizationAdmin(state);
  const lastFolder = selectLastFolder(state);
  return {
    fetchCasesFailureStatus,
    cases,
    isSystemAdmin,
    isOrganizationAdmin,
    lastFolder,
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Cases);
