export const easeUpToAValue = (
  startPoint,
  valueToReach,
  callBack,
  maxPoint = valueToReach,
  duration = 500,
  shouldExitFn,
) => {
  return new Promise((resolve, reject) => {
    const cosParameter = (valueToReach - startPoint) / 2;
    let scrollCount = 0,
      oldTimestamp = performance.now(),
      currentValue = -1;
    function step(newTimestamp) {
      if (shouldExitFn && shouldExitFn()) return reject();
      scrollCount += Math.PI / (duration / (newTimestamp - oldTimestamp));
      if (scrollCount >= Math.PI) {
        currentValue = Math.min(valueToReach, maxPoint);
        callBack(currentValue);
      }
      if (Math.min(valueToReach, maxPoint) === currentValue) return resolve();
      currentValue = Math.round(startPoint + cosParameter - cosParameter * Math.cos(scrollCount));
      callBack(currentValue);
      oldTimestamp = newTimestamp;
      window.requestAnimationFrame(step);
    }
    window.requestAnimationFrame(step);
  });
};
