import {
  COMMON_FETCH_USERS_BEGIN,
  COMMON_FETCH_USERS_SUCCESS,
  COMMON_FETCH_USERS_FAILURE,
  COMMON_FETCH_USERS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import qs from 'qs';

export const fetchUsers = createPromiseAction(COMMON_FETCH_USERS_BEGIN);

export function dismissFetchUsersFeedback() {
  return {
    type: COMMON_FETCH_USERS_DISMISS_FEEDBACK,
  };
}

export function* doFetchUsers(action) {
  const query =
    action.payload &&
    qs.stringify({
      ...(action.payload.inputValue && { lookup: action.payload.inputValue }),
      ...(action.payload?.excludeUsersInCase && {
        excludeUsersInCase: action.payload.excludeUsersInCase,
      }),
    });

  let res = yield call(api.get, `/users${query ? `?${query}` : ''}`);

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_USERS_FAILURE,
      feedback: { message: 'feedback.fetchUsersFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_USERS_SUCCESS,
    data: { users: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchUsers() {
  yield takeEvery(fetchUsers, doFetchUsers);
}

export function useFetchUsers() {
  const dispatch = useDispatch();

  const { users, fetchUsersPending, fetchUsersFeedback } = useSelector(
    state => ({
      users: state.common.users,
      fetchUsersPending: state.common.fetchUsersPending,
      fetchUsersFeedback: state.common.fetchUsersFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchUsers(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchUsersFeedback());
  }, [dispatch]);

  return {
    users,
    fetchUsers: boundAction,
    fetchUsersPending,
    fetchUsersFeedback,
    dismissFetchUsersFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_USERS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchUsersPending: true,
        fetchUsersFeedback: null,
      };

    case COMMON_FETCH_USERS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchUsersPending: false,
        fetchUsersFeedback: action.feedback,
        users: action.data.users,
      };

    case COMMON_FETCH_USERS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchUsersPending: false,
        fetchUsersFeedback: action.feedback,
      };

    case COMMON_FETCH_USERS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchUsersFeedback: null,
      };

    default:
      return state;
  }
}
