import React, { useEffect } from 'react';
import { useFetchTemplates } from 'features/case/redux/fetchTemplates';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import * as actions from 'features/case/redux/actions';
import * as actionsA from 'features/auth/redux/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Templates = () => {
  const dispatch = useDispatch();
  const { templates } = useFetchTemplates();

  useEffect(() => {
    if (!templates) dispatch(actions.fetchTemplates());
  }, [templates, dispatch]);

  useEffect(() => {
    dispatch(actionsA.loginRequired());
  }, [dispatch]);

  return (
    <div
      className={classnames('home-cases', {
        'justify-content-center': templates && templates.length <= 3,
      })}
    >
      {templates &&
        templates.length > 0 &&
        templates.map(template => (
          <div key={template.id} className="case-card-admin">
            <Link to={`/template/${template.id}`}>
              <FontAwesomeIcon icon={faFolderOpen} alt="case_image" />
              <span>{template.name}</span>
            </Link>
          </div>
        ))}
    </div>
  );
};

export default Templates;
