import { takeEvery, put, call } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import {
  VIEWING_FETCH_VIEWING_SESSION_ID_BEGIN,
  VIEWING_FETCH_VIEWING_SESSION_ID_SUCCESS,
  VIEWING_FETCH_VIEWING_SESSION_ID_FAILURE,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const fetchViewingSessionId = createPromiseAction(
  VIEWING_FETCH_VIEWING_SESSION_ID_BEGIN,
  payload => ({
    fileId: payload,
  }),
);

// worker Saga: will be fired on VIEWING_FETCH_VIEWING_SESSION_ID_BEGIN actions
export function* doFetchViewingSessionId(action) {
  const {
    payload: { caseId, fileId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/files/${fileId}/viewingSession`);

  if (res && res.error) {
    yield put({
      type: VIEWING_FETCH_VIEWING_SESSION_ID_FAILURE,
      feedback: {
        message: 'feedback.fetchViewingSessionIdFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: VIEWING_FETCH_VIEWING_SESSION_ID_SUCCESS,
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchViewingSessionId() {
  yield takeEvery(fetchViewingSessionId, withCurrentCaseId(doFetchViewingSessionId));
}

export function useFetchViewingSessionId() {
  const dispatch = useDispatch();

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchViewingSessionId(...args));
    },
    [dispatch],
  );

  return {
    fetchViewingSessionId: boundAction,
    // WE DON'T WANT PENDING AND FEEDBACK CAUSE IT CAUSES RERENDER
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_FETCH_VIEWING_SESSION_ID_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchViewingSessionIdPending: true,
        fetchViewingSessionIdFeedback: null,
      };

    case VIEWING_FETCH_VIEWING_SESSION_ID_SUCCESS:
      return {
        ...state,
        fetchViewingSessionIdPending: false,
        fetchViewingSessionIdFeedback: action.feedback,
      };

    case VIEWING_FETCH_VIEWING_SESSION_ID_FAILURE:
      return {
        ...state,
        fetchViewingSessionIdPending: false,
        fetchViewingSessionIdFeedback: action.feedback,
      };

    default:
      return state;
  }
}
