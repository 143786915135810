import {
  COMMON_FETCH_USER_SETTINGS_BEGIN,
  COMMON_FETCH_USER_SETTINGS_SUCCESS,
  COMMON_FETCH_USER_SETTINGS_FAILURE,
  COMMON_FETCH_USER_SETTINGS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import api from 'common/api';

export function fetchUserSettings() {
  return {
    type: COMMON_FETCH_USER_SETTINGS_BEGIN,
  };
}

export function dismissFetchUserSettingsFeedback() {
  return {
    type: COMMON_FETCH_USER_SETTINGS_DISMISS_FEEDBACK,
  };
}

export function* doFetchUserSettings(action) {
  let res = yield call(api.get, '/user-settings');

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_USER_SETTINGS_FAILURE,
      feedback: {
        message: 'feedback.fetchUserSettingsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return;
  }

  yield put({
    type: COMMON_FETCH_USER_SETTINGS_SUCCESS,
    data: { userSettings: res },
  });
}

export function* watchFetchUserSettings() {
  yield takeLatest([COMMON_FETCH_USER_SETTINGS_BEGIN], doFetchUserSettings);
}

export function useFetchUserSettings() {
  const dispatch = useDispatch();

  const { userSettings, fetchUserSettingsPending, fetchUserSettingsFeedback } = useSelector(
    state => ({
      userSettings: state.common.userSettings,
      fetchUserSettingsPending: state.common.fetchUserSettingsPending,
      fetchUserSettingsFeedback: state.common.fetchUserSettingsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchUserSettings(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchUserSettingsFeedback());
  }, [dispatch]);

  return {
    userSettings,
    fetchUserSettings: boundAction,
    fetchUserSettingsPending,
    fetchUserSettingsFeedback,
    dismissFetchUserSettingsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case COMMON_FETCH_USER_SETTINGS_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchUserSettingsPending: true,
        fetchUserSettingsFeedback: null,
      };

    case COMMON_FETCH_USER_SETTINGS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchUserSettingsPending: false,
        fetchUserSettingsFeedback: action.feedback,
        userSettings: action.data.userSettings,
      };

    case COMMON_FETCH_USER_SETTINGS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchUserSettingsPending: false,
        fetchUserSettingsFeedback: action.feedback,
      };

    case COMMON_FETCH_USER_SETTINGS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchUserSettingsFeedback: null,
      };

    default:
      return state;
  }
}
