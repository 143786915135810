import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'features/case/redux/actions';
import withAuth from 'features/auth/withAuth';
import Logo from 'features/common/Logo';
import NavBar from 'features/common/NavBar';
import Footer from 'features/common/Footer';
import { selectIsAuthenticated } from 'common/selectors';
import { Spinner } from 'features/common';
import CaseSettingsPage from 'features/case/CaseSettingsPage';

const TemplatePage = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (isAuthenticated) dispatch(actions.fetchCaseDetails());
  }, [dispatch, isAuthenticated]);

  return (
    <div className="settings-content">
      {isAuthenticated ? (
        <>
          <NavBar brandText={<Logo />} />
          <div style={{ height: 'calc(100% - 80px)', width: '100%' }}>
            <CaseSettingsPage />
          </div>
          <Footer />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default withAuth(TemplatePage, { forceLogin: true });
