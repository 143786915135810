import React from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select } from '@mui/material';
import T from 'i18n';
import { cfRingColorMapping } from 'features/case/TableCase/TableInfo';

const SelectConfidentialityRing = ({
  register,
  name,
  defaultValue,
  showLabel = true,
  onChange,
  disableNoRingMenu = false,
  disableOuterRingMenu = false,
  disableInnerRingMenu = false,
}) => {
  return (
    <>
      {showLabel && <span>{T.translate('generic.confidentialityRing')} </span>}
      <Select
        defaultValue={defaultValue || 'noRing'}
        {...(register && register(name))}
        onChange={e => onChange && onChange(e)}
        variant="standard"
        autoWidth
        size="small"
        sx={{
          p: 0,
          '&::before': { borderBottom: 'none' },
          '&::after': { borderBottom: 'none' },
        }}
      >
        <MenuItem value="noRing" disabled={disableNoRingMenu}>
          <FontAwesomeIcon
            style={{
              color: cfRingColorMapping['noRing'],
              stroke: 'black',
              strokeWidth: '10',
              marginRight: '1rem',
            }}
            icon={faCircle}
            alt={faCircle.iconName}
          />
          {T.translate('generic.noRing')}
        </MenuItem>
        <MenuItem value="outerRing" disabled={disableOuterRingMenu}>
          <FontAwesomeIcon
            style={{
              color: cfRingColorMapping['outerRing'],
              stroke: 'black',
              strokeWidth: '10',
              marginRight: '1rem',
            }}
            icon={faCircle}
            alt={faCircle.iconName}
          />
          {T.translate('generic.outerRing')}
        </MenuItem>
        <MenuItem value="innerRing" disabled={disableInnerRingMenu}>
          <FontAwesomeIcon
            style={{
              color: cfRingColorMapping['innerRing'],
              stroke: 'black',
              strokeWidth: '10',
              marginRight: '1rem',
            }}
            icon={faCircle}
            alt={faCircle.iconName}
          />
          {T.translate('generic.innerRing')}
        </MenuItem>
      </Select>
    </>
  );
};

export default SelectConfidentialityRing;
