import React from 'react';
import T from 'i18n';
import { Launch } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import history from 'common/history';
import { useDispatch } from 'react-redux';
import { setCurrentSelectedFile } from '../redux/setCurrentSelectedFile';

const DocInfoField = ({ label, value }: any) => {
  return (
    <div>
      <span>
        <b>{label} :</b> {value}
      </span>
    </div>
  );
};

const DocumentsContainer = ({ documents }: any) => {
  const dispatch = useDispatch();
  const openFile = (event: any, doc: any, index: number) => {
    history.push(history.location.pathname.replace(/\/files.*/, ``) + `/files/${doc.id}`);
    dispatch(setCurrentSelectedFile(doc));
  };

  const renderDate = (doc: any) => {
    let dateToShow = null;

    doc.docDates.some((date: { name: string; date: string }) => {
      if (date.date) {
        if (date.name === 'Email Sent') {
          dateToShow = <DocInfoField label={date.name} value={date.date} />;
          return true;
        } else if (date.name === 'First Date In Doc') {
          dateToShow = <DocInfoField label={date.name} value={date.date} />;
          return true;
        } else if (date.name === 'Last Date In Doc') {
          dateToShow = <DocInfoField label={date.name} value={date.date} />;
          return true;
        }
      }
      return false;
    });

    return dateToShow;
  };
  return (
    <div style={{ border: '1px solid rgba(0,0,0,.125)', backgroundColor: 'white', width: '100%' }}>
      {documents.map((doc: any, index: number) => (
        <div
          key={index}
          id={doc.id}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            padding: '0.5rem',
            alignItems: 'baseline',
          }}
        >
          <Typography
            sx={{ color: 'rgba(0, 0, 0, 0.65)', fontWeight: 600, fontSize: '1.1rem', mr: '1rem' }}
          >
            {index + 1}
          </Typography>
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 'calc(100% - 1.5rem)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'rgba(0, 0, 0, 0.65)',
                fontWeight: 600,
                fontSize: '1.1rem',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span>
                {doc.id} {doc.name}
              </span>
              <IconButton
                size="small"
                color="primary"
                onClick={event => openFile(event, doc, index)}
              >
                <Launch fontSize="small" />
              </IconButton>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', color: 'rgba(0, 0, 0, 0.65)' }}>
              {doc.suggestedSubject && doc.name !== doc.suggestedSubject && (
                <DocInfoField label={T.translate('case.subject')} value={doc.suggestedSubject} />
              )}
              {doc.author && <DocInfoField label={T.translate('case.author')} value={doc.author} />}
              {!doc.author && doc.suggestedAuthors?.length > 0 && (
                <DocInfoField
                  label={T.translate('case.authors')}
                  value={doc.suggestedAuthors.map((auth: any) => auth).join(', ')}
                />
              )}
              {doc.recipient && (
                <DocInfoField label={T.translate('case.recipient')} value={doc.recipient} />
              )}
              {!doc.recipient && doc.recipientsList?.length > 0 && (
                <DocInfoField
                  label={T.translate('case.recipients')}
                  value={doc.recipientsList.map((rec: any) => rec).join(', ')}
                />
              )}
              {doc.docDate && <DocInfoField label={T.translate('case.date')} value={doc.docDate} />}
              {!doc.docDate && doc.docDates?.length > 0 && renderDate(doc)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DocumentsContainer;
