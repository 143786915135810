import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_UPDATE_RECORDING_BEGIN,
  VIEWING_UPDATE_RECORDING_SUCCESS,
  VIEWING_UPDATE_RECORDING_FAILURE,
  VIEWING_UPDATE_RECORDING_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const updateRecording = createPromiseAction(VIEWING_UPDATE_RECORDING_BEGIN);

export function dismissUpdateRecordingFeedback() {
  return {
    type: VIEWING_UPDATE_RECORDING_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_RECORDING_BEGIN actions
export function* doUpdateRecording(action) {
  const {
    payload: { caseId, fileId, recording },
  } = action;

  const res = yield call(api.put, `/cases/${caseId}/recordings/${fileId}`, recording);

  if (res && res.error) {
    yield put({
      type: VIEWING_UPDATE_RECORDING_FAILURE,
      feedback: {
        message: 'feedback.updateRecordingFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_UPDATE_RECORDING_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateRecording() {
  yield takeLatest(updateRecording, withCurrentCaseId(doUpdateRecording));
}

export function useUpdateRecording() {
  const dispatch = useDispatch();

  const { updateRecordingPending, recording, updateRecordingFeedback } = useSelector(
    state => ({
      recording: state.viewing.recording,
      updateRecordingPending: state.viewing.updateRecordingPending,
      updateRecordingFeedback: state.viewing.updateRecordingFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateRecording(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateRecordingFeedback());
  }, [dispatch]);

  return {
    recording,
    updateRecording: boundAction,
    updateRecordingPending,
    updateRecordingFeedback,
    dismissUpdateRecordingFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_UPDATE_RECORDING_BEGIN + '.TRIGGER':
      return {
        ...state,
        updateRecordingPending: true,
        updateRecordingFeedback: null,
      };

    case VIEWING_UPDATE_RECORDING_SUCCESS:
      return {
        ...state,
        updateRecordingPending: false,
        recording: action.data,
        updateRecordingFeedback: action.feedback,
      };

    case VIEWING_UPDATE_RECORDING_FAILURE:
      return {
        ...state,
        updateRecordingPending: false,
        updateRecordingFeedback: action.feedback,
      };

    default:
      return state;
  }
}
