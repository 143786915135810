import React, { forwardRef, useState } from 'react';
import classnames from 'classnames';
// import PropTypes from 'prop-types';

const ToggleButton = forwardRef((props, ref) => {
  const { label = '', style, defaultValue, onChange, readOnly, disabled, checked, ...rest } = props;
  const [value, setValue] = useState(defaultValue);
  return (
    <div className={classnames('toggle-button')} style={style}>
      {label && <div className="label">{label}</div>}
      <div style={{ position: 'relative' }}>
        <input
          {...rest}
          ref={ref}
          style={{ ...(disabled && { cursor: 'not-allowed' }) }}
          className="checkbox"
          type="checkbox"
          checked={checked !== undefined ? checked : value}
          onChange={e => {
            const {
              target: { checked: value },
            } = e;
            checked === undefined && setValue(value);
            onChange && onChange(e);
          }}
          disabled={readOnly || disabled}
        />
        <div
          className="toggle-wrapper"
          style={{ ...(disabled && { backgroundColor: '#e4e4e47a' }) }}
        >
          <div className="checkbox-toggle" />
        </div>
      </div>
    </div>
  );
});

ToggleButton.propTypes = {};
ToggleButton.defaultProps = {};

export default ToggleButton;
