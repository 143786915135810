import React, { useRef, forwardRef } from 'react';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ErrorFeedback from '../ErrorFeedback';
import { TextField } from '@mui/material';

const DateTimeForm = forwardRef(({ label, name, value, onChange, autofocus, errors }, ref) => {
  const firstInputRef = useRef();
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>{label}</span>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          inputRef={el => {
            ref && ref(el);
            firstInputRef.current = el;
          }}
          clearable
          inputFormat="DD/MM/YYYY HH:mm:ss"
          mask="__/__/____ __:__:__"
          placeholder="Type in date"
          renderInput={props => (
            <TextField
              name={name}
              sx={{
                border: errors && errors[name] ? '1px solid red' : '1px solid #ced4da',
                borderRadius: '4px',
                fieldset: {
                  border: 'none',
                },
                '& input:invalid + fieldset': {
                  border: errors && errors[name] ? '1px solid red' : '1px solid #ced4da',
                },
              }}
              fullWidth
              size="small"
              {...props}
            />
          )}
          value={value}
          onChange={onChange}
          ampm={false}
          autoFocus={autofocus}
        />
      </LocalizationProvider>
      <ErrorFeedback style={{ display: 'block' }} name={name} errors={errors} />
    </div>
  );
});

export default DateTimeForm;
