import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { AppStateProvider } from './components/AppStateProvider/AppStateProvider';
import { MainContent } from './components/MainContent/MainContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appContainer: {
      position: 'relative',
      width: `100%`,
      height: '100%',
      overflow: 'hidden',
      overflowX: 'auto',
    },
  }),
);

function App({
  setData,
  isModalOpened,
}: {
  setData: (val: Array<any>) => void;
  isModalOpened: Boolean;
}) {
  const classes = useStyles();
  return (
    <AppStateProvider>
      <div className={classes.appContainer}>
        <MainContent setData={setData} isModalOpened={isModalOpened} />
      </div>
    </AppStateProvider>
  );
}

export default App;
