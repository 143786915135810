import React from 'react';
import DraggableRow from './DraggableRow';

type ListRowProps = {
  data?: any;
  index: number;
  style?: React.CSSProperties;
  isScrolling?: boolean;
};

export default React.memo(
  ({
    data: {
      data,
      onDoubleClick,
      columns,
      sortMode,
      additionalDragInfo,
      onRowSelected,
      selectedRowIds = [],
      setSelectedRowsIds,
      shiftPressedIndex,
      setShiftPressedIndex,
      hoverable,
      readOnly,
      forwardedRef,
    } = {},
    index,
    style,
    isScrolling,
  }: ListRowProps) => {
    const row = data[index] ? data[index] : { compositeKey: '', id: '' };

    const isSelectedRow = selectedRowIds.includes(row.compositeKey || row.id);

    return (
      <DraggableRow
        ref={forwardedRef}
        index={index}
        style={style}
        row={row}
        onDoubleClick={onDoubleClick}
        columns={columns}
        sortMode={sortMode}
        additionalDragInfo={additionalDragInfo}
        allRows={data}
        onRowSelected={onRowSelected}
        setSelectedRowsIds={setSelectedRowsIds}
        selected={isSelectedRow}
        selectedRowIds={isSelectedRow ? selectedRowIds : undefined}
        shiftPressedIndex={shiftPressedIndex}
        setShiftPressedIndex={setShiftPressedIndex}
        isScrolling={isScrolling}
        hoverable={hoverable}
        readOnly={readOnly}
      />
    );
  },
);
