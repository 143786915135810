import React from 'react';
import Split from 'react-split';
import classNames from 'classnames';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    videoPlayerAndTranscript: { display: 'flex', width: '100%', height: '100%' },
  }),
);

export default ({ reverse, show, children }: any) => {
  const classes = useStyles();
  return (
    <Split
      sizes={show ? (reverse ? [70, 30] : [30, 70]) : reverse ? [100, 0] : [0, 100]}
      minSize={show ? 100 : 0}
      expandToMin={false}
      gutterSize={show ? 10 : 0}
      gutterAlign="center"
      snapOffset={30}
      dragInterval={1}
      direction="horizontal"
      cursor="col-resize"
      className={classNames({ 'no-gutter': !show }, classes.videoPlayerAndTranscript)}
    >
      {children}
    </Split>
  );
};
