import {
  CASE_TEAM_MEMBER_ACCEPT_INVITE_BEGIN,
  CASE_TEAM_MEMBER_ACCEPT_INVITE_SUCCESS,
  CASE_TEAM_MEMBER_ACCEPT_INVITE_FAILURE,
  CASE_TEAM_MEMBER_ACCEPT_INVITE_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { selectUserEmail } from 'common/selectors';

export const acceptTeamInvite = createPromiseAction(
  CASE_TEAM_MEMBER_ACCEPT_INVITE_BEGIN,
  payload => ({
    inputData: payload,
  }),
);

export function dismissAcceptTeamInviteFeedback() {
  return {
    type: CASE_TEAM_MEMBER_ACCEPT_INVITE_DISMISS_FEEDBACK,
  };
}

export function* doAcceptTeamInvite(action) {
  const { emailId, caseId, teamId, inviteId } = action.payload.inputData;
  const currentUserEmail = yield select(selectUserEmail);
  if (currentUserEmail.toLowerCase() !== emailId) {
    yield put({
      type: CASE_TEAM_MEMBER_ACCEPT_INVITE_FAILURE,
      feedback: {
        message: 'feedback.acceptInviteFailureDifferentUser',
        params: { emailId },
        error: { error: true },
      },
    });
    return yield call(rejectPromiseAction, action, { error: true });
  }

  let res = yield call(api.put, `/cases/${caseId}/teams/${teamId}/invites/${inviteId}`);

  if (res && res.error) {
    if (res.error.body === 'Invalid invite link') {
      yield put({
        type: CASE_TEAM_MEMBER_ACCEPT_INVITE_FAILURE,
        feedback: {
          message: 'feedback.acceptInviteFailureInvalidInvite',
          error: res.error.statusText,
          retryAction: action,
        },
      });
    } else {
      yield put({
        type: CASE_TEAM_MEMBER_ACCEPT_INVITE_FAILURE,
        feedback: {
          message: 'feedback.acceptInviteFailure',
          error: res.error,
          retryAction: action,
        },
      });
    }
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_TEAM_MEMBER_ACCEPT_INVITE_SUCCESS,
    data: { res },
    feedback: {
      message: 'feedback.acceptInviteSuccess',
    },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchAcceptTeamInvite() {
  yield takeEvery(acceptTeamInvite, doAcceptTeamInvite);
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_TEAM_MEMBER_ACCEPT_INVITE_BEGIN + '.TRIGGER':
      const { countryCode } = action.payload && action.payload.inputData;
      // Just after a request is sent
      return {
        ...state,
        acceptTeamInvitePending: true,
        caseRegionTemp: countryCode || null,
        acceptTeamInviteFeedback: null,
      };

    case CASE_TEAM_MEMBER_ACCEPT_INVITE_SUCCESS:
      // The request is success
      return {
        ...state,
        acceptTeamInvitePending: false,
        caseRegionTemp: null,
        acceptTeamInviteFeedback: action.feedback,
      };

    case CASE_TEAM_MEMBER_ACCEPT_INVITE_FAILURE:
      // The request is failed
      return {
        ...state,
        acceptTeamInvitePending: false,
        caseRegionTemp: null,
        acceptTeamInviteFeedback: action.feedback,
      };

    case CASE_TEAM_MEMBER_ACCEPT_INVITE_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        acceptTeamInviteFeedback: null,
        caseRegionTemp: null,
      };

    default:
      return state;
  }
}
