import {
  COMMON_FETCH_ROLES_BY_TYPE_BEGIN,
  COMMON_FETCH_ROLES_BY_TYPE_SUCCESS,
  COMMON_FETCH_ROLES_BY_TYPE_FAILURE,
  COMMON_FETCH_ROLES_BY_TYPE_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchRolesByType = createPromiseAction(COMMON_FETCH_ROLES_BY_TYPE_BEGIN, payload => ({
  inputData: payload,
}));

export function dismissFetchRolesByTypeFeedback() {
  return {
    type: COMMON_FETCH_ROLES_BY_TYPE_DISMISS_FEEDBACK,
  };
}

export function* doFetchRolesByType(action) {
  const { type, groupRoleByClass, includePermissions, customRoles } = action.payload.inputData;
  let res = yield call(
    api.get,
    `/roles?type=${type}&groupByRoleClass=${groupRoleByClass}&includePermissions=${includePermissions}`,
  );

  if (res && res.error) {
    yield put({
      type: COMMON_FETCH_ROLES_BY_TYPE_FAILURE,
      feedback: {
        message: 'feedback.fetchRolesByTypeFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: COMMON_FETCH_ROLES_BY_TYPE_SUCCESS,
    data: { roles: res, rolesType: type, customRoles },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchRolesByType() {
  yield takeEvery(fetchRolesByType, doFetchRolesByType);
}

export function reducer(state, action) {
  const filterRoles = (roles, selectedCustomRoles) =>
    roles.map(itm => {
      return {
        ...itm,
        options: itm.options.filter(opt => selectedCustomRoles.includes(opt.id)),
      };
    });
  switch (action.type) {
    case COMMON_FETCH_ROLES_BY_TYPE_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchRolesByTypePending: true,
        fetchRolesByTypeFeedback: null,
      };

    case COMMON_FETCH_ROLES_BY_TYPE_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchRolesByTypePending: false,
        fetchRolesByTypeFeedback: action.feedback,
        [action.data.rolesType]: action.data.customRoles
          ? filterRoles(action.data.roles, action.data.customRoles)
          : action.data.roles,
      };

    case COMMON_FETCH_ROLES_BY_TYPE_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchRolesByTypePending: false,
        fetchRolesByTypeFeedback: action.feedback,
      };

    case COMMON_FETCH_ROLES_BY_TYPE_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        fetchRolesByTypeFeedback: null,
      };

    default:
      return state;
  }
}
