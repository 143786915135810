import React from 'react';
// import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { isMobile } from 'utils/device';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import { MoreHoriz } from '@mui/icons-material';

export default function Modifier({ buttonActions }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [focus, setFocus] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setFocus(true);
    event.preventDefault();
    event.stopPropagation();
  };
  const handleClose = event => {
    setAnchorEl(null);
    setFocus(false);
    event.preventDefault();
    event.stopPropagation();
  };

  return buttonActions.length > 0 ? (
    <>
      <IconButton
        className={classnames({ isMobile, 'focussed-modifier': focus }, 'modifier')}
        aria-label="more"
        id="more-button"
        aria-controls={open ? 'more-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ color: 'white', padding: 0 }}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="more-menu"
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
        PaperProps={{
          sx: {
            color: 'black',
          },
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {buttonActions.map((item, index) =>
          item.divider ? (
            <Divider key={index} />
          ) : (
            <MenuItem
              key={index}
              onClick={e => {
                item.handler();
                handleClose(e);
              }}
              dense
            >
              {item.icon && (
                <ListItemIcon sx={{ color: 'black' }}>
                  {item.icon.type === 'muiIcon' ? (
                    item.icon.component
                  ) : (
                    <FontAwesomeIcon className="mr-2" icon={item.icon} />
                  )}
                </ListItemIcon>
              )}
              <ListItemText>{item.title}</ListItemText>
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  ) : (
    <div />
  );
}

Modifier.propTypes = {};
Modifier.defaultProps = {};
