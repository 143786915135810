import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import Auth from '@aws-amplify/auth';
import { Controller, useForm } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CircularProgress from '@mui/material/CircularProgress';
import { emailValidation } from 'utils/regex';
import { password } from 'utils/regex';
import Logger from 'utils/logger';
import T from 'i18n';
import { useSelector } from 'react-redux';
import { selectQuery } from 'common/selectors';
import { Trans } from 'react-i18next';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

const useStyles = makeStyles({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '1rem',
  },
  avatar: {
    backgroundColor: '#f50057 !important',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  error: { height: '1rem' },
});

function SignUp({
  onSignInClick,
  onSignUpCompleted,
}: {
  onSignInClick: any;
  onSignUpCompleted: any;
}) {
  const classes = useStyles();
  const queryParams = useSelector(selectQuery);

  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      email: queryParams.email,
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      number: '+353',
    },
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<any>(null);

  const signUp = async (data: any) => {
    setLoading(true);
    const { email, password, number, firstName, lastName } = data;
    const name = [firstName, lastName].join(' ');
    const signIn = T.translate('auth.signIn');
    // prettier-ignore
    const userExistMsg = (
      <Trans>
        An account with the given email already exists. Please <Link component="button" variant="h6" onClick={onSignInClick}>
          {{ signIn }}
        </Link>
      </Trans>
    );
    const result = await Auth.signUp({
      username: email,
      password,
      attributes: {
        phone_number: number.replace(/ /g, ''),
        name,
        email,
      },
    })
      .then(result => {
        onSignUpCompleted(email, result);
      })
      .catch(e => {
        Logger.ERROR(e);
        if (e.code === 'UsernameExistsException') setMessage(userExistMsg);
        else if (e.code === 'CodeDeliveryFailureException')
          setMessage(T.translate('auth.invalidEmailMessage'));
        else setMessage(T.translate('auth.otherIssueMessage'));
      })
      .finally(() => setLoading(false));
    return result;
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassoword, setShowConfirmPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper} style={{ marginTop: 0 }}>
        <Avatar className={classes.avatar} sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {T.translate('auth.signUp')}
        </Typography>
        <Typography color="error" component="h1" variant="h6">
          {message}
        </Typography>
        <form
          onSubmit={handleSubmit(signUp)}
          className={classes.form}
          style={{ marginTop: '24px' }}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="firstName"
                    autoComplete="given-name"
                    name=""
                    variant="outlined"
                    required
                    fullWidth
                    label={T.translate('auth.firstName')}
                    autoFocus
                    error={!!errors.firstName}
                    helperText={errors.firstName && T.translate('auth.firstNameRequired')}
                    style={{ minHeight: '6rem', marginTop: 0 }}
                    InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="lastName"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="lastName"
                    variant="outlined"
                    required
                    fullWidth
                    label={T.translate('auth.lastName')}
                    autoComplete="family-name"
                    error={!!errors.lastName}
                    helperText={errors.lastName && T.translate('auth.lastNameRequired')}
                    style={{ minHeight: '6rem', marginTop: 0 }}
                    InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="email"
                rules={{ required: true, pattern: emailValidation }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    id="email"
                    required
                    fullWidth
                    label={T.translate('auth.email')}
                    autoComplete="email"
                    error={!!errors.email}
                    helperText={
                      errors.email &&
                      T.translate(
                        errors.email.type === 'required'
                          ? 'auth.emailRequired'
                          : 'auth.emailInvalid',
                      )
                    }
                    style={{ minHeight: '6rem', marginTop: 0 }}
                    InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="password"
                rules={{
                  required: true,
                  pattern: password.eightCharsNumberLowerUpperSpecial,
                }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="password"
                    variant="outlined"
                    required
                    fullWidth
                    label={T.translate('auth.password')}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="new-password"
                    error={!!errors.password}
                    onChange={e => {
                      setPasswordValue(e.target.value);
                      field.onChange(e.target.value);
                    }}
                    helperText={
                      <>
                        {!errors.password && (
                          <span style={{ fontSize: '0.9rem' }}>
                            {T.translate('auth.passwordInvalid')}
                          </span>
                        )}
                        {errors.password &&
                          T.translate(
                            errors.password.type === 'required'
                              ? 'auth.passwordRequired'
                              : 'auth.passwordInvalid',
                          )}
                      </>
                    }
                    style={{ minHeight: '6rem', marginTop: 0 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: { fontSize: '1.2rem' },
                    }}
                    InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="confirmPassword"
                rules={{
                  required: true,
                  validate: {
                    noMatch: value => value && value === passwordValue,
                  },
                }}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="confirmPassword"
                    variant="outlined"
                    required
                    fullWidth
                    label={T.translate('auth.confirmPassword')}
                    type={showConfirmPassoword ? 'text' : 'password'}
                    autoComplete="new-confirm-password"
                    error={!!errors.confirmPassword}
                    helperText={
                      errors.confirmPassword &&
                      T.translate(
                        errors.confirmPassword.type === 'noMatch'
                          ? 'auth.passwordNotMatch'
                          : 'auth.confirmPasswordRequired',
                      )
                    }
                    style={{ minHeight: '6rem', marginTop: 0 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowConfirmPassword(!showConfirmPassoword)}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfirmPassoword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: { fontSize: '1.2rem' },
                    }}
                    InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="number"
                rules={{
                  required: true,
                  validate: val => matchIsValidTel(val),
                }}
                control={control}
                render={({ field }) => (
                  <MuiTelInput
                    {...field}
                    id="number"
                    defaultCountry={'IE'}
                    variant="outlined"
                    required
                    fullWidth
                    label={T.translate('auth.phone')}
                    autoComplete="do-not-complete-this-field"
                    error={!!errors.number}
                    helperText={
                      errors.number &&
                      T.translate(
                        errors.number.type === 'validate'
                          ? 'auth.phoneInvalid'
                          : 'auth.phoneRequired',
                      )
                    }
                    style={{ minHeight: '6rem', marginTop: 0 }}
                    InputLabelProps={{ style: { fontSize: '1.2rem', fontWeight: 500 } }}
                    InputProps={{ style: { fontSize: '1.2rem' } }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid> */}
            <Grid item xs={12} sx={{ pt: '0 !important' }}>
              <Typography sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                {T.translate('auth.smsConfirmationMsg')}
              </Typography>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading}
            sx={{ mt: 3, mb: 2, fontSize: '1rem' }}
          >
            {loading && <CircularProgress size={17} />}
            {!loading && T.translate('auth.signUp')}
          </Button>
          <Grid container>
            <Grid item>
              <Link
                component="button"
                variant="body2"
                onClick={onSignInClick}
                sx={{ fontSize: '1rem' }}
              >
                {T.translate('auth.alreadyHaveAnAccount')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

SignUp.propTypes = {};
SignUp.defaultProps = {};

export default SignUp;
