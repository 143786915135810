import { put, select, call, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_UPDATE_ANNOTATION_SEQ_BEGIN,
  VIEWING_UPDATE_ANNOTATION_SEQ_SUCCESS,
  VIEWING_UPDATE_ANNOTATION_SEQ_FAILURE,
  VIEWING_UPDATE_ANNOTATION_SEQ_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFile, withCurrentUserId } from 'common/selectors';
import { filterAnnotations } from './filterAnnotations';

export const updateAnnotationSeq = createPromiseAction(VIEWING_UPDATE_ANNOTATION_SEQ_BEGIN);

export function dismissUpdateAnnotationSeqFeedback() {
  return {
    type: VIEWING_UPDATE_ANNOTATION_SEQ_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on VIEWING_UPDATE_ANNOTATION_SEQ_BEGIN actions
export function* doUpdateAnnotationSeq(action) {
  const {
    payload: { updatedAnnotations, caseId, currentUserId: userId },
  } = action;
  const updatedAnnotationIds = updatedAnnotations.map(({ id }) => id);

  const file = yield select(selectCurrentFile);

  const res = yield call(
    api.put,
    `/cases/${caseId}/files/${file}/annotations`,
    updatedAnnotationIds,
  );

  if (res && res.error) {
    yield put({
      type: VIEWING_UPDATE_ANNOTATION_SEQ_FAILURE,
      feedback: {
        message: 'feedback.updateAnnotationSeqFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: VIEWING_UPDATE_ANNOTATION_SEQ_SUCCESS,
    data: { annotations: res },
    userId,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAnnotationSeq() {
  yield takeLatest(
    updateAnnotationSeq,
    withCurrentUserId(withCurrentCaseId(doUpdateAnnotationSeq)),
  );
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_UPDATE_ANNOTATION_SEQ_BEGIN + '.TRIGGER':
      return {
        ...state,
        updateAnnotationSeqPending: true,
        updateAnnotationSeqFeedback: null,
        annotationsBeforeUpdate: state.annotations,
        annotations: action.payload.updatedAnnotations,
        filteredAnnotations: filterAnnotations(
          action.payload.updatedAnnotations,
          state.updatedFilters,
          action.userId,
        ),
      };

    case VIEWING_UPDATE_ANNOTATION_SEQ_SUCCESS:
      return {
        ...state,
        updateAnnotationSeqPending: false,
        updateAnnotationSeqFeedback: action.feedback,
        annotationsBeforeUpdate: [],
      };

    case VIEWING_UPDATE_ANNOTATION_SEQ_FAILURE:
      return {
        ...state,
        updateAnnotationSeqPending: false,
        updateAnnotationSeqFeedback: action.feedback,
        annotations: state.annotationsBeforeUpdate,
      };

    case VIEWING_UPDATE_ANNOTATION_SEQ_DISMISS_FEEDBACK:
      return {
        ...state,
        updateAnnotationSeqFeedback: null,
      };

    default:
      return state;
  }
}
