import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import T from 'i18n';
import { withoutTrailingSlash } from 'utils/path';
import { faObjectUngroup } from '@fortawesome/free-solid-svg-icons';
import { openNewBackgroundTab } from 'utils/browser';

export default ({ history, className }) => {
  return (
    <div className={className}>
      <OverlayTrigger
        placement="left"
        delay={{ show: 1000, hide: 400 }}
        overlay={
          <Popover>
            <Popover.Content>{T.translate('case.moveDocPresentationToNewTab')}</Popover.Content>
          </Popover>
        }
      >
        <Nav.Link
          className="d-inline-flex"
          onClick={() => {
            const path = withoutTrailingSlash(history.location.pathname);
            history.push(path + '/conference');
            openNewBackgroundTab(path + '/document-presentation');
          }}
        >
          <FontAwesomeIcon icon={faObjectUngroup} />
        </Nav.Link>
      </OverlayTrigger>
    </div>
  );
};
