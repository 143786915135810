import React, { useEffect } from 'react';
import { Chip, IconButton } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as actions from 'features/viewing/redux/actions';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'utils/hooks';
import * as actionsV from 'features/viewing/redux/actions';

const ChipLabel = ({ label, searchFunctions }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <IconButton
        size="small"
        onClick={() => searchFunctions.searchNext && searchFunctions.searchPrev()}
      >
        <ArrowLeftIcon />
      </IconButton>
      <OverlayTrigger
        placement="top"
        delay={{ show: 500, hide: 400 }}
        trigger={label && label.length > 13 && ['hover', 'focus']}
        overlay={
          <Popover style={{ pointerEvents: 'none' }}>
            <Popover.Content>{label}</Popover.Content>
          </Popover>
        }
      >
        <div
          style={{
            maxWidth: '8rem',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            margin: 'auto',
          }}
        >
          {label}
        </div>
      </OverlayTrigger>
      <IconButton
        size="small"
        onClick={() => searchFunctions.searchNext && searchFunctions.searchNext()}
      >
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

const DocumentSearch = ({ searchFunctions, docSearchVal, docSearchLocation }) => {
  const dispatch = useDispatch();
  const prevDocSearchVal = usePrevious(docSearchVal);
  const prevDocSearchLocation = usePrevious(docSearchLocation);
  useEffect(() => {
    if (searchFunctions && (docSearchVal || docSearchLocation)) {
      if (docSearchLocation && docSearchLocation !== prevDocSearchLocation) {
        dispatch(actionsV.setGoToPageNumber(parseInt(docSearchLocation.pageNumber)));
        searchFunctions.highlightLocation && searchFunctions.highlightLocation(docSearchLocation);
      } else if (docSearchVal && docSearchVal !== prevDocSearchVal) {
        setTimeout(() => searchFunctions.search && searchFunctions.search(docSearchVal));
      }
    }
  }, [
    docSearchVal,
    searchFunctions,
    prevDocSearchVal,
    docSearchLocation,
    prevDocSearchLocation,
    dispatch,
  ]);

  return (
    docSearchVal && (
      <Chip
        label={<ChipLabel label={docSearchVal} searchFunctions={searchFunctions} />}
        size="small"
        sx={{
          fontSize: '1rem',
          mr: '0.5rem',
          cursor: 'initial !important',
        }}
        onDelete={() => {
          searchFunctions.search && searchFunctions.search('');
          dispatch(actions.setDocSearch(''));
        }}
      />
    )
  );
};

export default DocumentSearch;
