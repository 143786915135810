import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_FETCH_TIMELINE_BEGIN,
  CASE_FETCH_TIMELINE_SUCCESS,
  CASE_FETCH_TIMELINE_FAILURE,
  CASE_FETCH_TIMELINE_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const fetchTimeline = createPromiseAction(CASE_FETCH_TIMELINE_BEGIN);

export function dismissFetchTimelineFeedback() {
  return {
    type: CASE_FETCH_TIMELINE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_TIMELINE_BEGIN actions
export function* doFetchTimeline(action) {
  const {
    payload: { caseId, timelineId },
  } = action;
  const res = yield call(api.get, `/cases/${caseId}/timelines/${timelineId}`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_TIMELINE_FAILURE,
      feedback: {
        message: 'feedback.fetchTimelineFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_TIMELINE_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchTimeline() {
  yield takeLatest(fetchTimeline, withCurrentCaseId(doFetchTimeline));
}

export function useFetchTimeline() {
  const dispatch = useDispatch();

  const { timeline, fetchTimelinePending, fetchTimelineFeedback } = useSelector(
    state => ({
      timeline: state.case.timeline,
      fetchTimelinePending: state.case.fetchTimelinePending,
      fetchTimelineFeedback: state.case.fetchTimelineFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchTimeline(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchTimelineFeedback());
  }, [dispatch]);

  return {
    timeline,
    fetchTimeline: boundAction,
    fetchTimelinePending,
    fetchTimelineFeedback,
    dismissFetchTimelineFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_TIMELINE_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchTimelinePending: true,
        fetchTimelineFeedback: null,
      };

    case CASE_FETCH_TIMELINE_SUCCESS:
      return {
        ...state,
        fetchTimelinePending: false,
        fetchTimelineFeedback: action.feedback,
        timeline: action.data,
      };

    case CASE_FETCH_TIMELINE_FAILURE:
      return {
        ...state,
        fetchTimelinePending: false,
        fetchTimelineFeedback: action.feedback,
      };

    case CASE_FETCH_TIMELINE_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchTimelineFeedback: null,
      };

    default:
      return state;
  }
}
