import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AUTH_LOGOUT } from './constants';
import Auth from '@aws-amplify/auth';
import { redirectToLoginStartPage } from 'utils/path';
import Logger from 'utils/logger';

export function logout() {
  return {
    type: AUTH_LOGOUT,
  };
}

const signOut = () => {
  redirectToLoginStartPage();
  Auth.signOut()
    .then(() => {})
    .catch(e => Logger.ERROR(e));
};

export function useLogout() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(logout(...params)), [dispatch]);
  return { logout: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_LOGOUT:
      Logger.INFO('Authentication state changed: Unauthenticated');
      signOut();
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
}
