import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import { caseAdminModalActions } from './enums';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'features/common/MaterialBasedComponents';
import TextForm from 'features/common/TextForm';
import { SelectForm } from 'features/common';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCurrentCaseTeamId } from 'common/selectors';
import { fetchTeams } from './redux/fetchTeams';

const AddNewGroup = ({ show, action, handleSave, handleClose }) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const teamId = useSelector(selectCurrentCaseTeamId);
  const [teamOptions, setTeamOptions] = useState(null);

  useEffect(() => {
    if (!teamOptions) {
      dispatch(fetchTeams()).then(res => {
        setTeamOptions(res.map(team => ({ name: team.name, id: team.id })));
      });
    }
  }, [teamOptions, dispatch, teamId]);

  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        handleSave(action, data);
        handleClose();
      })}
      title={T.translate('case.addNewGroup')}
      saveTitle={T.translate('generic.add')}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Row>
            <Col>
              <TextForm
                {...register('name', { required: true })}
                label={T.translate('case.groupName')}
                placeholder={T.translate('case.groupNamePlaceholder')}
                errors={errors}
                autofocus
              />
            </Col>
            <Col />
          </Row>
          <Row>
            <Col>
              <SelectForm
                menuPosition={'fixed'}
                name={'teams'}
                isMulti
                label={T.translate('case.selectTeam')}
                defaultValue={''}
                valueKey="id"
                labelKey="name"
                isClearable
                options={teamOptions}
                errors={errors}
                control={control}
                rules={{ required: true }}
                onBlur={() => {}}
              />
            </Col>
            <Col />
          </Row>
        </Form.Group>
      </Form>
    </Modal>
  );
};

const AddNewTeam = ({ show, action, handleSave, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        handleSave(action, data);
        handleClose();
      })}
      title={T.translate('case.addNewTeam')}
      saveTitle={T.translate('generic.add')}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Row>
            <Col>
              <TextForm
                {...register('name', { required: true })}
                label={T.translate('case.teamName')}
                placeholder={T.translate('case.teamNamePlaceholder')}
                errors={errors}
                autofocus
              />
            </Col>
            <Col />
          </Row>
        </Form.Group>
      </Form>
    </Modal>
  );
};

const RemoveTeam = ({ show, action, team, handleSave, handleClose }) => {
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={() => {
        handleSave(action, team.id);
        handleClose();
      }}
      title={T.translate('case.removeTeam')}
      saveTitle={T.translate('generic.remove')}
    >
      {T.translate('case.removeATeamMessage', {
        team: team.name,
      })}
    </Modal>
  );
};

const RemoveGroup = ({ show, action, team, handleSave, handleClose }) => {
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={() => {
        handleSave(action, team.id);
        handleClose();
      }}
      title={T.translate('case.removeGroup')}
      saveTitle={T.translate('generic.remove')}
    >
      {T.translate('case.removeAGroupMessage', {
        group: team.name,
      })}
    </Modal>
  );
};

const UpdateTeamName = ({ show, action, team, handleSave, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  return (
    <Modal
      show={show}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        const updatedTeam = {
          ...team,
          name: data.name,
        };
        handleSave(action, updatedTeam);
        handleClose();
      })}
      title={T.translate('case.updateTeamName')}
      saveTitle={T.translate('generic.update')}
    >
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          <Row>
            <Col>
              <TextForm
                {...register('name', { required: true })}
                label={T.translate('case.teamName')}
                placeholder={T.translate('case.teamNamePlaceholder')}
                name="name"
                defaultValue={team.name}
                errors={errors}
                autofocus
              />
            </Col>
            <Col />
          </Row>
        </Form.Group>
      </Form>
    </Modal>
  );
};

const CaseAdminModal = ({ show, action, team, handleClose, handleSave }) => {
  return (
    <>
      {action === caseAdminModalActions.addNewTeam && (
        <AddNewTeam show={show} action={action} handleClose={handleClose} handleSave={handleSave} />
      )}
      {action === caseAdminModalActions.updateTeamName && (
        <UpdateTeamName
          show={show}
          action={action}
          team={team}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
      {action === caseAdminModalActions.removeTeam && (
        <RemoveTeam
          show={show}
          action={action}
          team={team}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
      {action === caseAdminModalActions.addNewGroup && (
        <AddNewGroup
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
      {action === caseAdminModalActions.removeGroup && (
        <RemoveGroup
          show={show}
          action={action}
          team={team}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      )}
    </>
  );
};

export default CaseAdminModal;
