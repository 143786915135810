import React, { useEffect, useState } from 'react';
import T from 'i18n';
import { Table } from 'features/common';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMatchedUrl,
  selectCurrentCaseId,
  selectUserSettingPageRefs,
  selectPageRefs,
  selectFetchPageRefsPending,
} from 'common/selectors';
import * as actions from '../redux/actions';
import useSearchContext from 'features/common/Search/useSearchContext';
import columnsSelector from './columnsSelector';

interface PageRefsTableProps {
  onRowSelected: (newlySelectedRows: any) => void;
}

const filterByColumns = [
  'fileId',
  'originalTab',
  'originalGlobalPage',
  'page',
  'currentTab',
  'currentGlobalPage',
];

export default React.memo(({ onRowSelected }: PageRefsTableProps) => {
  const dispatch = useDispatch();
  const caseId = useSelector(selectCurrentCaseId);
  const pageRefs = useSelector(selectPageRefs);
  const fetchPageRefsPending = useSelector(selectFetchPageRefsPending);
  const currentUrl = useSelector(selectMatchedUrl);
  const { searchHandler, searchFunctionsHandler, clearSearch } = useSearchContext();
  const defaultFilters = {
    term: '',
  };
  const [pageRefsState, setPageRefsState] = useState<Array<any> | null>(null);
  const [filter, setFilter] = useState(defaultFilters);
  const isPageRefs =
    useSelector(selectUserSettingPageRefs) && currentUrl.includes(`${caseId}/page-refs`);

  const columns = columnsSelector(filter.term);

  const filterRows = (term: string) => setFilter({ term: term ? term : '' });

  useEffect(() => {
    if (clearSearch !== null && clearSearch !== undefined && typeof clearSearch === 'function') {
      clearSearch();
    }
    searchHandler &&
      searchHandler({
        isReady: true,
        placeholder: T.translate('case.filter'),
        hideFilterOptions: true,
      });
    searchFunctionsHandler &&
      searchFunctionsHandler({
        searchChanged: filterRows,
      });
    //on unMount
    return () => {
      searchHandler && searchHandler(null);
      searchFunctionsHandler && searchFunctionsHandler(null);
      if (clearSearch !== null && clearSearch !== undefined && typeof clearSearch === 'function') {
        clearSearch();
      }
    };
  }, [clearSearch, searchFunctionsHandler, searchHandler]);

  useEffect(() => {
    // on unMount
    return () => {
      searchHandler && searchHandler(null);
      searchFunctionsHandler && searchFunctionsHandler(null);
    };
  }, []);

  useEffect(() => {
    if (isPageRefs && !pageRefsState)
      dispatch(actions.fetchPageRefs()).then((res: any) => setPageRefsState(res));
  }, [dispatch, isPageRefs, pageRefsState]);

  return (
    <>
      {!fetchPageRefsPending &&
        (pageRefsState && pageRefsState.length > 0 ? (
          <Table
            key={Math.random()}
            data={pageRefs}
            filter={filter}
            filterByColumns={filterByColumns}
            onDoubleClick={() => null}
            columns={columns}
            onRowSelected={onRowSelected}
            sortMode={false}
            updateSort={() => null}
          />
        ) : (
          <div className="no-users">{T.translate('case.noPageRefsMsg')}</div>
        ))}
    </>
  );
});
