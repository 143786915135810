// Rekit uses a new approach to organizing actions and reducers. That is
// putting related actions and reducers in one file. See more at:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da

import history from 'common/history';
import { MATCHER_ROUTE_CHANGED } from './constants';
import qs from 'qs';

export function routeChanged(payload) {
  return {
    type: MATCHER_ROUTE_CHANGED,
    payload,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case MATCHER_ROUTE_CHANGED:
      const computedMatch = action.payload;

      // let components change route only when firt time loading,
      // then after only if the path is exact to prevent unnecesarry re-rendering
      if (computedMatch.isExact || !state.initialised) {
        return {
          ...state,
          ...{
            initialised: computedMatch.isExact,
            url: computedMatch.url,
            path: computedMatch.path,
            params: computedMatch.params,
            query: qs.parse(computedMatch.location.search.substr(1)),
          },
        };
      } else return state;

    default:
      return state;
  }
}
