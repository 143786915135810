import { addItemImmutable, deleteItemImmutable } from 'utils/arrays';
import { CASE_ADD_ANNOTATIONS, CASE_REMOVE_ANNOTATIONS } from './constants';

export function reducer(state, action) {
  switch (action.type) {
    case CASE_ADD_ANNOTATIONS:
      return {
        ...state,
        annotations: addItemImmutable(state.annotations, action.data),
      };
    case CASE_REMOVE_ANNOTATIONS:
      return {
        ...state,
        ...(action.data.index > -1 && {
          annotations: deleteItemImmutable(state.annotations, action.data.index),
        }),
      };
    default:
      return state;
  }
}
