import {
  CASE_UPDATE_LAYOUTS_BEGIN,
  CASE_UPDATE_LAYOUTS_SUCCESS,
  CASE_UPDATE_LAYOUTS_FAILURE,
  CASE_UPDATE_LAYOUTS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

export const updateLayouts = createPromiseAction(CASE_UPDATE_LAYOUTS_BEGIN);

export function dismissUpdateLayoutsFeedback() {
  return {
    type: CASE_UPDATE_LAYOUTS_DISMISS_FEEDBACK,
  };
}

export function* doUpdateLayouts(action) {
  const { caseId, layouts } = action.payload;
  let res = yield call(api.put, `/cases/${caseId}/hearing-room-layout`, layouts);

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_LAYOUTS_FAILURE,
      feedback: { message: 'feedback.updateLayoutsFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_LAYOUTS_SUCCESS,
    data: { layouts: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchUpdateLayouts() {
  yield takeLatest(updateLayouts, withCurrentCaseId(doUpdateLayouts));
}

export function useUpdateLayouts() {
  const dispatch = useDispatch();

  const { updateLayoutsPending, updateLayoutsFeedback } = useSelector(
    state => ({
      updateLayoutsPending: state.case.updateLayoutsPending,
      updateLayoutsFeedback: state.case.updateLayoutsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateLayouts(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissUpdateLayoutsFeedback());
  }, [dispatch]);

  return {
    updateLayouts: boundAction,
    updateLayoutsPending,
    updateLayoutsFeedback,
    dismissUpdateLayoutsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_LAYOUTS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        updateLayoutsPending: true,
        updateLayoutsFeedback: null,
      };

    case CASE_UPDATE_LAYOUTS_SUCCESS:
      // The request is success
      const index = state.layouts.findIndex(x => x.id === action.data.layouts.id);
      return {
        ...state,
        updateLayoutsPending: false,
        updateLayoutsFeedback: action.feedback,
        layouts: replaceItemImmutable(state.layouts, action.data.layouts, index),
      };

    case CASE_UPDATE_LAYOUTS_FAILURE:
      // The request is failed
      return {
        ...state,
        updateLayoutsPending: false,
        updateLayoutsFeedback: action.feedback,
      };

    case CASE_UPDATE_LAYOUTS_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        updateLayoutsFeedback: null,
      };

    default:
      return state;
  }
}
