import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CASE_FETCH_DOCUMENT_MERGE_DETAILS_BEGIN,
  CASE_FETCH_DOCUMENT_MERGE_DETAILS_SUCCESS,
  CASE_FETCH_DOCUMENT_MERGE_DETAILS_FAILURE,
  CASE_FETCH_DOCUMENT_MERGE_DETAILS_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchDocumentMergeDetails = createPromiseAction(
  CASE_FETCH_DOCUMENT_MERGE_DETAILS_BEGIN,
);

export function dismissFetchDocumentMergeDetailsFeedback() {
  return {
    type: CASE_FETCH_DOCUMENT_MERGE_DETAILS_DISMISS_FEEDBACK,
  };
}

export function* doFetchDocumentMergeDetails(action) {
  const { caseId, fileId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/merged-files/${fileId}m`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_DOCUMENT_MERGE_DETAILS_FAILURE,
      feedback: {
        message: 'feedback.fetchDocumentMergeDetailsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_DOCUMENT_MERGE_DETAILS_SUCCESS,
    data: { mergeDetails: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchDocumentMergeDetails() {
  yield takeLatest(fetchDocumentMergeDetails, withCurrentCaseId(doFetchDocumentMergeDetails));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_DOCUMENT_MERGE_DETAILS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchDocumentMergeDetailsPending: true,
        fetchDocumentMergeDetailsFeedback: null,
      };

    case CASE_FETCH_DOCUMENT_MERGE_DETAILS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchDocumentMergeDetailsPending: false,
        fetchDocumentMergeDetailsFeedback: action.feedback,
      };

    case CASE_FETCH_DOCUMENT_MERGE_DETAILS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchDocumentMergeDetailsPending: false,
        fetchDocumentMergeDetailsFeedback: action.feedback,
      };

    case CASE_FETCH_DOCUMENT_MERGE_DETAILS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchDocumentMergeDetailsFeedback: null,
      };

    default:
      return state;
  }
}
