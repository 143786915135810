import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_BEGIN,
  CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_SUCCESS,
  CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_FAILURE,
  CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const fetchRecentlyPresentedToday = createPromiseAction(
  CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_BEGIN,
);

export function dismissFetchRecentlyPresentedTodayFeedback() {
  return {
    type: CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_DISMISS_FEEDBACK,
  };
}

export function* doFetchRecentlyPresentedToday(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/files?presentedToday=true`);

  if (res && res.error) {
    yield put({
      type: CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_FAILURE,
      feedback: {
        message: 'feedback.fetchRecentlyPresentedTodayFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_SUCCESS,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchRecentlyPresentedToday() {
  yield takeLatest(fetchRecentlyPresentedToday, withCurrentCaseId(doFetchRecentlyPresentedToday));
}

export function useFetchRecentlyPresentedToday() {
  const dispatch = useDispatch();

  const { fetchRecentlyPresentedTodayPending, fetchRecentlyPresentedTodayFeedback } = useSelector(
    state => ({
      fetchRecentlyPresentedTodayPending: state.conference.fetchRecentlyPresentedTodayPending,
      fetchRecentlyPresentedTodayFeedback: state.conference.fetchRecentlyPresentedTodayFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchRecentlyPresentedToday(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchRecentlyPresentedTodayFeedback());
  }, [dispatch]);

  return {
    fetchRecentlyPresentedToday: boundAction,
    fetchRecentlyPresentedTodayPending,
    fetchRecentlyPresentedTodayFeedback,
    dismissFetchRecentlyPresentedTodayFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  // switch (action.type) {
  //   case CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_BEGIN + '.TRIGGER':
  //     // Just after a request is sent
  //     return {
  //       ...state,
  //       fetchRecentlyPresentedTodayPending: true,
  //       fetchRecentlyPresentedTodayFeedback: null,
  //     };

  //   case CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_SUCCESS:
  //     // The request is success
  //     return {
  //       ...state,
  //       fetchRecentlyPresentedTodayPending: false,
  //       fetchRecentlyPresentedTodayFeedback: action.feedback,
  //     };

  //   case CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_FAILURE:
  //     // The request is failed
  //     return {
  //       ...state,
  //       fetchRecentlyPresentedTodayPending: false,
  //       fetchRecentlyPresentedTodayFeedback: action.feedback,
  //     };

  //   case CONFERENCE_FETCH_RECENTLY_PRESENTED_TODAY_DISMISS_FEEDBACK:
  //     // Dismiss the request failure error
  //     return {
  //       ...state,
  //       fetchRecentlyPresentedTodayFeedback: null,
  //     };

  //   default:
      return state;
  // }
}
