import React, { useRef, useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import onClickOutside from 'react-onclickoutside';

const PageNumberControl = ({
  currentPageNumber,
  setPageNumber,
  pageCount,
}: {
  currentPageNumber: number;
  setPageNumber: (val: any) => void;
  pageCount: number;
}) => {
  const [pageNumberValue, setpageNumberValue] = useState(currentPageNumber);
  const pageNumberElement = useRef<any>(null);

  //@ts-ignore
  PageNumberControl.handleClickOutside = () =>
    pageNumberElement.current && pageNumberElement.current.blur();

  const changePageNumber = () => {
    const newNum = Math.min(Math.max(pageNumberValue, 1), pageCount);
    setPageNumber(newNum);
    setpageNumberValue(newNum);
  };
  return (
    <div
      className="pageNumber"
      onClick={() => pageNumberElement.current && pageNumberElement.current.focus()}
    >
      <AutosizeInput
        ref={pageNumberElement}
        value={pageNumberValue || ''}
        onChange={(event: any) => {
          const re = /^[0-9\b]+$/;
          if (event.target.value === '' || re.test(event.target.value))
            setpageNumberValue(event.target.value);
        }}
        onBlur={changePageNumber}
        onKeyDown={(e: any) => e.key === 'Enter' && pageNumberElement.current.blur()}
      />
      <span>{' / ' + pageCount}</span>
    </div>
  );
};

export default onClickOutside(PageNumberControl, {
  //@ts-ignore
  handleClickOutside: () => PageNumberControl.handleClickOutside,
});
