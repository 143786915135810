import React from 'react';
// import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const SimpleTooltip = ({ children, title }) =>
  title ? (
    <OverlayTrigger
      placement="top"
      delay={{ show: 500, hide: 400 }}
      trigger={['hover', 'hover']}
      overlay={
        <Popover style={{ width: 'auto', maxWidth: '350px', fontSize: '0.75rem' }}>
          <React.Fragment>
            <Popover.Content>{title}</Popover.Content>
          </React.Fragment>
        </Popover>
      }
    >
      {children}
    </OverlayTrigger>
  ) : (
    children
  );

SimpleTooltip.propTypes = {};
SimpleTooltip.defaultProps = {};

export default SimpleTooltip;
