import { put, select, takeLatest, call } from 'redux-saga/effects';
import {
  CASE_FETCH_DOCUMENTS_BEGIN,
  CASE_FETCH_DOCUMENTS_SUCCESS,
  CASE_FETCH_DOCUMENTS_SUCCESS_PARTIAL,
  CASE_FETCH_DOCUMENTS_FAILURE,
  CASE_FETCH_DOCUMENTS_DISMISS_FEEDBACK,
  CASE_FETCH_DOCUMENTS_CLEAR,
  CASE_UPDATE_FOLDER_AGREED_SUCCESS,
  CASE_UPDATE_DOCUMENT_TAB_SUCCESS,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from 'common/selectors';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import { withoutTrailingAmpersand, withoutTrailingQuestionMark } from 'utils/path';

export function fetchDocuments({ pageSize, pageIndex, presentMode }) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_FETCH_DOCUMENTS_BEGIN,
    payload: { pageSize, pageIndex, presentMode },
  };
}

export function dismissFetchDocumentsFeedback() {
  return {
    type: CASE_FETCH_DOCUMENTS_DISMISS_FEEDBACK,
  };
}

export function clearDocuments() {
  return {
    type: CASE_FETCH_DOCUMENTS_CLEAR,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCUMENTS_BEGIN actions
export function* doFetchDocuments(action) {
  const {
    payload: { caseId, pageSize, pageIndex, presentMode },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);

  let url = `/cases/${caseId}/`;

  // all docs
  if (!currentFolder.id) url += `files`;
  else if (currentFolder.type === 'quicklinks')
    if (currentFolder.code === 'recently-viewed')
      // recently Viewed
      url += `files?recentlyViewed=true&pageSize=20`;
    else if (currentFolder.code === 'recently-presented')
      // recently Presented
      url += `files?recentlyPresented=true&pageSize=20`;
    else {
    }
  // recordings
  else if (currentFolder.type === 'recordings') url += `files?recordings=true`;
  // tag folders
  else if (currentFolder.type === 'tags') url += `tags/${currentFolder.id}/locations`;
  // trialbooks, bundles, regular stuff
  else url += `folders/${currentFolder.id}/files`;

  let params = '?includeBundleLocations=false&';

  if (pageSize !== undefined && pageIndex !== undefined)
    params += `pageSize=${pageSize}&pageIndex=${pageIndex}&`;

  if (presentMode) params += 'presentMode=true&';

  params = withoutTrailingQuestionMark(withoutTrailingAmpersand(params));

  const res = yield call(api.get, url + params);

  if (res && res.error) {
    return yield put({
      type: CASE_FETCH_DOCUMENTS_FAILURE,
      feedback: {
        message: 'feedback.fetchDocumentsFailure',
        params: { folder: currentFolder.name },
        error: res.error,
        id: currentFolder.id,
        retryAction: { feedback: { id: currentFolder.id }, ...action },
      },
    });
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  if ((pageIndex || pageSize) && !currentFolder.id) {
    return yield put({
      type: CASE_FETCH_DOCUMENTS_SUCCESS_PARTIAL,
      data: {
        documents: res.results,
        totalDocuments: res.count,
        zeroBasedIndex: currentFolder.zeroBasedIndex,
      },
    });
  }

  yield put({
    type: CASE_FETCH_DOCUMENTS_SUCCESS,
    data: {
      documents: res.nonce || presentMode ? res.results : res,
      zeroBasedIndex: currentFolder.zeroBasedIndex,
    },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchDocuments() {
  yield takeLatest(
    [
      CASE_FETCH_DOCUMENTS_BEGIN,
      CASE_UPDATE_FOLDER_AGREED_SUCCESS,
      CASE_UPDATE_DOCUMENT_TAB_SUCCESS,
    ],
    withCurrentCaseId(doFetchDocuments),
  );
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_DOCUMENTS_BEGIN:
      return {
        ...state,
        fetchDocumentsPending: true,
        fetchDocumentsFeedback: action.feedback,
        sortDirection: null,
        sortByColumn: null,
      };

    case CASE_FETCH_DOCUMENTS_CLEAR:
      return {
        ...state,
        documents: [],
        totalDocuments: 0,
        authorsList: null,
        recipientsList: null,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_DOCUMENTS_SUCCESS_PARTIAL:
      if (state.documents.length >= action.data.totalDocuments) {
        return {
          ...state,
          fetchDocumentsPending: false,
        };
      }
      return {
        ...state,
        fetchDocumentPartial: true,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        documents: addSortNumberToDocuments(
          [...state.documents, ...action.data.documents],
          action.data.zeroBasedIndex,
        ),
        totalDocuments: action.data.totalDocuments,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        fetchDocumentPartial: false,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        documents: addSortNumberToDocuments(action.data.documents, action.data.zeroBasedIndex),
        totalDocuments: action.data.totalDocuments,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchDocumentsPending: false,
        fetchDocumentsFeedback: action.feedback,
        isDeepSearchPerformed: false,
      };

    case CASE_FETCH_DOCUMENTS_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
