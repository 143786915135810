import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_REINDEX_BUNDLE_BEGIN,
  CASE_REINDEX_BUNDLE_SUCCESS,
  CASE_REINDEX_BUNDLE_FAILURE,
  CASE_REINDEX_BUNDLE_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const reIndexBundle = createPromiseAction(CASE_REINDEX_BUNDLE_BEGIN);

export function dismissReIndexBundleFeedback() {
  return {
    type: CASE_REINDEX_BUNDLE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doReIndexBundle(action) {
  const {
    payload: { caseId, folderId, data },
  } = action;

  const res = yield call(api.post, `/cases/${caseId}/folders/${folderId}/indexes`, data);

  if (res && res.error) {
    yield put({
      type: CASE_REINDEX_BUNDLE_FAILURE,
      feedback: {
        message: 'feedback.reIndexBundleFailure',
        error: res,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_REINDEX_BUNDLE_SUCCESS,
    data: res,
    feedback: {
      message: typeof res === 'string' ? res : 'feedback.reIndexBundleSuccess',
    },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchReIndexBundle() {
  yield takeLatest(reIndexBundle, withCurrentCaseId(doReIndexBundle));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_REINDEX_BUNDLE_BEGIN + '.TRIGGER':
      return {
        ...state,
        reIndexBundlePending: true,
        reIndexBundleFeedback: null,
      };

    case CASE_REINDEX_BUNDLE_SUCCESS:
      return {
        ...state,
        reIndexBundlePending: false,
        reIndexBundleFeedback: action.feedback,
      };

    case CASE_REINDEX_BUNDLE_FAILURE:
      return {
        ...state,
        reIndexBundlePending: false,
        reIndexBundleFeedback: action.feedback,
      };

    case CASE_REINDEX_BUNDLE_DISMISS_FEEDBACK:
      return {
        ...state,
        reIndexBundleFeedback: null,
      };

    default:
      return state;
  }
}
