import React from 'react';
import T from 'i18n';
import TableWrapper from './TableWrapper';

const DocFinder = ({ isPresentModeWithBackEnd }) => {
  return (
    <TableWrapper
      category={
        isPresentModeWithBackEnd ? T.translate('case.presentMode') : T.translate('case.docFinder')
      }
    />
  );
};

export default DocFinder;
