import history from 'common/history';
import Logger from './logger';

export const withoutTrailingSlash = url => url.replace(/\/$/, '');

export const withoutTrailingQuestionMark = url =>
  url[url.length - 1] === '?' ? url.slice(0, -1) : url;

export const withoutTrailingAmpersand = url =>
  url[url.length - 1] === '&' ? url.slice(0, -1) : url;

export function removeHash() {
  var scrollV,
    scrollH,
    loc = window.location;
  if ('pushState' in history) history.pushState('', document.title, loc.pathname + loc.search);
  else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;

    loc.hash = '';

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
}

export const redirectToLoginStartPage = loginStartPage => {
  // // get login redirect value to go to after successful login
  // const loginStartPage = window.sessionStorage.getItem('login.request', window.location.href);
  Logger.INFO('redirect to: ', loginStartPage);
  if (loginStartPage && window.location.href !== loginStartPage)
    window.location.href = loginStartPage;
  else {
    window.location.href = '/';
    // removeHash();
  }
};

// export const setRedirectLoginStartPage = () => {
//   window.sessionStorage.setItem('login.request', window.location.href);
// };
