export const VIEWING_SEND_MESSAGE_BEGIN = 'VIEWING_SEND_MESSAGE_BEGIN';
export const VIEWING_SEND_MESSAGE_SUCCESS = 'VIEWING_SEND_MESSAGE_SUCCESS';
export const VIEWING_SEND_MESSAGE_FAILURE = 'VIEWING_SEND_MESSAGE_FAILURE';
export const VIEWING_SEND_MESSAGE_DISMISS_FEEDBACK = 'VIEWING_SEND_MESSAGE_DISMISS_FEEDBACK';
export const VIEWING_FETCH_THEATRE_DATA_BEGIN = 'VIEWING_FETCH_THEATRE_DATA_BEGIN';
export const VIEWING_FETCH_THEATRE_DATA_SUCCESS = 'VIEWING_FETCH_THEATRE_DATA_SUCCESS';
export const VIEWING_FETCH_THEATRE_DATA_FAILURE = 'VIEWING_FETCH_THEATRE_DATA_FAILURE';
export const VIEWING_FETCH_THEATRE_DATA_DISMISS_FEEDBACK =
  'VIEWING_FETCH_THEATRE_DATA_DISMISS_FEEDBACK';
export const VIEWING_START_PRESENTING_BEGIN = 'VIEWING_START_PRESENTING_BEGIN';
export const VIEWING_START_PRESENTING_SUCCESS = 'VIEWING_START_PRESENTING_SUCCESS';
export const VIEWING_START_PRESENTING_FAILURE = 'VIEWING_START_PRESENTING_FAILURE';
export const VIEWING_LEAVE_A_GROUP_BEGIN = 'VIEWING_LEAVE_A_GROUP_BEGIN';
export const VIEWING_LEAVE_A_GROUP_SUCCESS = 'VIEWING_LEAVE_A_GROUP_SUCCESS';
export const VIEWING_LEAVE_A_GROUP_FAILURE = 'VIEWING_LEAVE_A_GROUP_FAILURE';
export const VIEWING_LEAVE_A_GROUP_DISMISS_FEEDBACK = 'VIEWING_LEAVE_A_GROUP_DISMISS_FEEDBACK';
export const VIEWING_FETCH_VIEWING_SESSION_ID_BEGIN = 'VIEWING_FETCH_VIEWING_SESSION_ID_BEGIN';
export const VIEWING_FETCH_VIEWING_SESSION_ID_SUCCESS = 'VIEWING_FETCH_VIEWING_SESSION_ID_SUCCESS';
export const VIEWING_FETCH_VIEWING_SESSION_ID_FAILURE = 'VIEWING_FETCH_VIEWING_SESSION_ID_FAILURE';
export const VIEWING_DOWNLOAD_DOCUMENT_BEGIN = 'VIEWING_DOWNLOAD_DOCUMENT_BEGIN';
export const VIEWING_DOWNLOAD_DOCUMENT_SUCCESS = 'VIEWING_DOWNLOAD_DOCUMENT_SUCCESS';
export const VIEWING_DOWNLOAD_DOCUMENT_FAILURE = 'VIEWING_DOWNLOAD_DOCUMENT_FAILURE';
export const VIEWING_DOWNLOAD_DOCUMENT_PROGRESS = 'VIEWING_DOWNLOAD_DOCUMENT_PROGRESS';
export const VIEWING_FETCH_ANNOTATIONS_BEGIN = 'VIEWING_FETCH_ANNOTATIONS_BEGIN';
export const VIEWING_FETCH_ANNOTATIONS_SUCCESS = 'VIEWING_FETCH_ANNOTATIONS_SUCCESS';
export const VIEWING_FETCH_ANNOTATIONS_FAILURE = 'VIEWING_FETCH_ANNOTATIONS_FAILURE';
export const VIEWING_FETCH_ANNOTATIONS_DISMISS_FEEDBACK =
  'VIEWING_FETCH_ANNOTATIONS_DISMISS_FEEDBACK';
export const VIEWING_FILTER_ANNOTATIONS = 'VIEWING_FILTER_ANNOTATIONS';
export const VIEWING_ADD_ANNOTATION_BEGIN = 'VIEWING_ADD_ANNOTATION_BEGIN';
export const VIEWING_ADD_ANNOTATION_SUCCESS = 'VIEWING_ADD_ANNOTATION_SUCCESS';
export const VIEWING_ADD_ANNOTATION_FAILURE = 'VIEWING_ADD_ANNOTATION_FAILURE';
export const VIEWING_ADD_ANNOTATION_DISMISS_FEEDBACK = 'VIEWING_ADD_ANNOTATION_DISMISS_FEEDBACK';
export const VIEWING_DELETE_ANNOTATION_BEGIN = 'VIEWING_DELETE_ANNOTATION_BEGIN';
export const VIEWING_DELETE_ANNOTATION_SUCCESS = 'VIEWING_DELETE_ANNOTATION_SUCCESS';
export const VIEWING_DELETE_ANNOTATION_FAILURE = 'VIEWING_DELETE_ANNOTATION_FAILURE';
export const VIEWING_DELETE_ANNOTATION_DISMISS_FEEDBACK =
  'VIEWING_DELETE_ANNOTATION_DISMISS_FEEDBACK';
export const VIEWING_UPDATE_ANNOTATION_BEGIN = 'VIEWING_UPDATE_ANNOTATION_BEGIN';
export const VIEWING_UPDATE_ANNOTATION_SUCCESS = 'VIEWING_UPDATE_ANNOTATION_SUCCESS';
export const VIEWING_UPDATE_ANNOTATION_FAILURE = 'VIEWING_UPDATE_ANNOTATION_FAILURE';
export const VIEWING_UPDATE_ANNOTATION_DISMISS_FEEDBACK =
  'VIEWING_UPDATE_ANNOTATION_DISMISS_FEEDBACK';
export const VIEWING_GENERATE_SIGNATURE_BEGIN = 'VIEWING_GENERATE_SIGNATURE_BEGIN';
export const VIEWING_GENERATE_SIGNATURE_SUCCESS = 'VIEWING_GENERATE_SIGNATURE_SUCCESS';
export const VIEWING_GENERATE_SIGNATURE_FAILURE = 'VIEWING_GENERATE_SIGNATURE_FAILURE';
export const VIEWING_GENERATE_SIGNATURE_DISMISS_FEEDBACK =
  'VIEWING_GENERATE_SIGNATURE_DISMISS_FEEDBACK';
export const VIEWING_GENERATE_STUFF_BEGIN = 'VIEWING_GENERATE_STUFF_BEGIN';
export const VIEWING_GENERATE_STUFF_SUCCESS = 'VIEWING_GENERATE_STUFF_SUCCESS';
export const VIEWING_GENERATE_STUFF_FAILURE = 'VIEWING_GENERATE_STUFF_FAILURE';
export const VIEWING_GENERATE_STUFF_DISMISS_FEEDBACK = 'VIEWING_GENERATE_STUFF_DISMISS_FEEDBACK';
export const VIEWING_FETCH_DOCUMENTS_LOOKUP_BEGIN = 'VIEWING_FETCH_DOCUMENTS_LOOKUP_BEGIN';
export const VIEWING_FETCH_AUTHORS_LOOKUP_BEGIN = 'VIEWING_FETCH_AUTHORS_LOOKUP_BEGIN';
export const VIEWING_FETCH_RECIPIENTS_LOOKUP_BEGIN = 'VIEWING_FETCH_RECIPIENTS_LOOKUP_BEGIN';
export const VIEWING_FETCH_CREATEDBY_LOOKUP_BEGIN = 'VIEWING_FETCH_CREATEDBY_LOOKUP_BEGIN';
export const VIEWING_FETCH_FILE_LOCATION_BEGIN = 'VIEWING_FETCH_FILE_LOCATION_BEGIN';
export const VIEWING_FETCH_FILE_LOCATION_SUCCESS = 'VIEWING_FETCH_FILE_LOCATION_SUCCESS';
export const VIEWING_FETCH_FILE_LOCATION_FAILURE = 'VIEWING_FETCH_FILE_LOCATION_FAILURE';
export const VIEWING_FETCH_FILE_LOCATION_DISMISS_FEEDBACK =
  'VIEWING_FETCH_FILE_LOCATION_DISMISS_FEEDBACK';
export const VIEWING_BURN_ANNOTATION_BEGIN = 'VIEWING_BURN_ANNOTATION_BEGIN';
export const VIEWING_BURN_ANNOTATION_SUCCESS = 'VIEWING_BURN_ANNOTATION_SUCCESS';
export const VIEWING_BURN_ANNOTATION_FAILURE = 'VIEWING_BURN_ANNOTATION_FAILURE';
export const VIEWING_BURN_ANNOTATION_DISMISS_FEEDBACK = 'VIEWING_BURN_ANNOTATION_DISMISS_FEEDBACK';
export const VIEWING_CLEAR_ANNOTATIONS = 'VIEWING_CLEAR_ANNOTATIONS';
export const VIEWING_UPDATE_THEATREDATA = 'VIEWING_UPDATE_THEATREDATA';
export const VIEWING_UPDATE_ANNOTATION_TAGS_BEGIN = 'VIEWING_UPDATE_ANNOTATION_TAGS_BEGIN';
export const VIEWING_UPDATE_ANNOTATION_TAGS_SUCCESS = 'VIEWING_UPDATE_ANNOTATION_TAGS_SUCCESS';
export const VIEWING_UPDATE_ANNOTATION_TAGS_FAILURE = 'VIEWING_UPDATE_ANNOTATION_TAGS_FAILURE';
export const VIEWING_UPDATE_ANNOTATION_TAGS_DISMISS_FEEDBACK =
  'VIEWING_UPDATE_ANNOTATION_TAGS_DISMISS_FEEDBACK';
export const VIEWING_SET_CURRENT_PAGE_NUMBER = 'VIEWING_SET_CURRENT_PAGE_NUMBER';
export const VIEWING_SET_GOTO_PAGE_NUMBER = 'VIEWING_SET_GOTO_PAGE_NUMBER';
export const VIEWING_SET_GOTO_PAGE_NUMBER_FAILURE = 'VIEWING_SET_GOTO_PAGE_NUMBER_FAILURE';
export const VIEWING_UPDATE_ANNOTATION_SEQ_BEGIN = 'VIEWING_UPDATE_ANNOTATION_SEQ_BEGIN';
export const VIEWING_UPDATE_ANNOTATION_SEQ_SUCCESS = 'VIEWING_UPDATE_ANNOTATION_SEQ_SUCCESS';
export const VIEWING_UPDATE_ANNOTATION_SEQ_FAILURE = 'VIEWING_UPDATE_ANNOTATION_SEQ_FAILURE';
export const VIEWING_UPDATE_ANNOTATION_SEQ_DISMISS_FEEDBACK =
  'VIEWING_UPDATE_ANNOTATION_SEQ_DISMISS_FEEDBACK';
export const VIEWING_FETCH_RECORDING_BEGIN = 'VIEWING_FETCH_RECORDING_BEGIN';
export const VIEWING_FETCH_RECORDING_SUCCESS = 'VIEWING_FETCH_RECORDING_SUCCESS';
export const VIEWING_FETCH_RECORDING_FAILURE = 'VIEWING_FETCH_RECORDING_FAILURE';
export const VIEWING_FETCH_RECORDING_DISMISS_FEEDBACK = 'VIEWING_FETCH_RECORDING_DISMISS_FEEDBACK';
export const VIEWING_SET_DOC_SEARCH_BEGIN = 'VIEWING_SET_DOC_SEARCH_BEGIN';
export const VIEWING_SET_DOC_SEARCH_SUCCESS = 'VIEWING_SET_DOC_SEARCH_SUCCESS';
export const VIEWING_FETCH_DOCS_PRESENTED_BEGIN = 'VIEWING_FETCH_DOCS_PRESENTED_BEGIN';
export const VIEWING_FETCH_DOCS_PRESENTED_SUCCESS = 'VIEWING_FETCH_DOCS_PRESENTED_SUCCESS';
export const VIEWING_FETCH_DOCS_PRESENTED_FAILURE = 'VIEWING_FETCH_DOCS_PRESENTED_FAILURE';
export const VIEWING_FETCH_DOCS_PRESENTED_DISMISS_FEEDBACK =
  'VIEWING_FETCH_DOCS_PRESENTED_DISMISS_FEEDBACK';
export const VIEWING_UPDATE_RECORDING_BEGIN = 'VIEWING_UPDATE_RECORDING_BEGIN';
export const VIEWING_UPDATE_RECORDING_SUCCESS = 'VIEWING_UPDATE_RECORDING_SUCCESS';
export const VIEWING_UPDATE_RECORDING_FAILURE = 'VIEWING_UPDATE_RECORDING_FAILURE';
export const VIEWING_UPDATE_RECORDING_DISMISS_FEEDBACK =
  'VIEWING_UPDATE_RECORDING_DISMISS_FEEDBACK';
export const VIEWING_UPDATE_TRANSCRIPT_ITEM_BEGIN = 'VIEWING_UPDATE_TRANSCRIPT_ITEM_BEGIN';
export const VIEWING_UPDATE_TRANSCRIPT_ITEM_SUCCESS = 'VIEWING_UPDATE_TRANSCRIPT_ITEM_SUCCESS';
export const VIEWING_UPDATE_TRANSCRIPT_ITEM_FAILURE = 'VIEWING_UPDATE_TRANSCRIPT_ITEM_FAILURE';
export const VIEWING_UPDATE_TRANSCRIPT_ITEM_DISMISS_FEEDBACK =
  'VIEWING_UPDATE_TRANSCRIPT_ITEM_DISMISS_FEEDBACK';
