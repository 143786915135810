import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import T from 'i18n';
import validation from 'utils/validation';
import { Controller, useForm } from 'react-hook-form';
import { CUSTOM_FLAG } from 'features/common/MaterialBasedComponents/Lookup';
import clsx from 'clsx';
import { Box, CircularProgress, Link, Modal, Typography } from '@mui/material';
import { faEdit, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { CustomViewerButton, SimpleTooltip } from 'features/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import DocumentLookup from '../../DocumentLookup';
import { Button } from 'react-bootstrap';
import { BundleLocations } from 'features/case/Viewer';
import { useFetchFileDetails } from 'features/case/redux/fetchFileDetails';
import { useSelector } from 'react-redux';
import { selectFetchFileDetailsPending, selectPermissions } from 'common/selectors';

const useStyles = makeStyles(() => ({
  noBorder: {
    border: 'none',
  },
  uploadIcon: {
    padding: '0.2rem',
    borderRadius: '50% !important',
    fontSize: '2rem',
    color: 'white',
    margin: '0.5rem',
    cursor: 'pointer',
    backgroundColor: '#4567e5',
  },
  markButton: {
    padding: '0.25rem',
    position: 'relative',
    transition: 'opacity 150ms, visibility 0ms 150ms',
    whiteSpace: 'nowrap',
    backgroundColor: '#4567e5',
    border: 'none',
    boxShadow: '0 4px 6px 0 #666',
    color: 'white',
    textAlign: 'center',
    opacity: '0.85',
    margin: '0.1rem',
    borderRadius: '10%',
    a: {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    "&[type='button']:hover": {
      boxShadow: '0 4px 10px 0 #666',
      opacity: 1,
    },
  },
  error: {
    backgroundColor: 'red',
  },
  disabled: {
    backgroundColor: '#A5A4A1',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    borderRadius: '0.5rem',
    width: 200,
    height: 100,
    padding: '1rem',
  },
}));

type CrossRefInputPropsType = {
  onClick: () => void;
  crossRef: any;
  link: any;
  linkText: any;
  href: any;
  update?: boolean;
  deleteAnnotation: any;
  createdBy: any;
  selectMark: any;
  page: any;
  burned: any;
  crossRefFileId?: string;
};

export default ({
  onClick,
  crossRef,
  link,
  linkText,
  href,
  update = false,
  deleteAnnotation,
  createdBy,
  selectMark,
  page,
  burned,
  crossRefFileId,
}: CrossRefInputPropsType) => {
  const classes = useStyles();

  const permissions = useSelector(selectPermissions);
  const crossRefRef = useRef<HTMLInputElement>();
  const {
    documents: { canCreateCrossRef },
  } = permissions;
  const canCreateAndEditCrossRefs = useSelector(canCreateCrossRef);

  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    getValues,
    setValue,
  } = useForm({
    defaultValues: {
      link,
      crossRef,
      linkText,
    },
  });

  const [edit, setEdit] = useState(false);
  const [areYouSureDelete, setAreYouSureDelete] = useState(false);
  const [areYouSureLeave, setAreYouSureLeave] = useState<any | null>(false);
  const [fileDetails, setFileDetails] = useState<any | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const noLinkText = !getValues('linkText');

  const saveLink = () => !noLinkText && handleSubmit(onClick)();
  const { fetchFileDetails } = useFetchFileDetails();
  const fetchFileDetailsPending = useSelector(selectFetchFileDetailsPending);

  const closePopup = useCallback(() => {
    if (page) {
      page.dispatchEvent(
        new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );
      page.dispatchEvent(
        new MouseEvent('mouseup', {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );
    }
  }, [page]);

  const handleMouseDown = useCallback(
    (event: any) => {
      if ((update && isDirty) || areYouSureDelete) {
        !areYouSureDelete && isDirty && setAreYouSureLeave(event.target);
        event.stopPropagation();
        event.preventDefault();
        return false;
      } else {
        closePopup();
        document.removeEventListener('mousedown', handleMouseDown, true);
      }
    },
    [isDirty, update, closePopup, areYouSureDelete],
  );

  useEffect(() => {
    if (
      !burned &&
      update &&
      crossRefFileId &&
      !fetchFileDetailsPending &&
      fileDetails?.id !== crossRefFileId
    ) {
      fetchFileDetails({ fileId: crossRefFileId })
        .then((res: any) => setFileDetails(res))
        .catch(() => setFileDetails({}));
    }
  }, [burned, crossRefFileId, fetchFileDetails, update, fileDetails, fetchFileDetailsPending]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div
        style={{ position: 'fixed', bottom: 0, top: 0, right: 0, left: 0, zIndex: -1 }}
        onClick={handleMouseDown}
      />
      <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
        <Modal
          open={areYouSureDelete || !!areYouSureLeave}
          container={containerRef.current}
          sx={{ position: 'absolute', '& .MuiModal-backdrop': { position: 'absolute' } }}
        >
          <Box className={classes.modal}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                textAlign: 'center',
              }}
            >
              {areYouSureDelete ? 'Delete. Are you sure?' : 'Leave without saving?'}
            </Typography>

            <div
              style={{ width: '100%', padding: '1rem', display: 'flex', justifyContent: 'center' }}
            >
              <Button
                style={{ margin: '0.2rem', background: '#4567e5', color: 'white' }}
                onClick={() => {
                  if (areYouSureDelete) deleteAnnotation();
                  else {
                    closePopup();
                    document.removeEventListener('mousedown', handleMouseDown, true);
                  }
                }}
              >
                {T.translate('generic.yes')}
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  if (areYouSureDelete) setAreYouSureDelete(false);
                  else setAreYouSureLeave(null);
                }}
                style={{ margin: '0.2rem', borderColor: '#4567e5' }}
              >
                {T.translate('generic.no')}
              </Button>
            </div>
          </Box>
        </Modal>
        <div
          style={{
            display: 'flex',
            flexDirection: update ? 'column' : 'row',
            ...(update && { padding: '1rem' }),
          }}
        >
          <div style={{ position: 'absolute', right: '0.5rem', top: '0.5rem' }}>
            {!burned && update && (
              <CustomViewerButton disabled={fetchFileDetailsPending} className={classes.markButton}>
                <span style={{ margin: '0 0.25rem' }}>
                  {fetchFileDetailsPending ? (
                    <CircularProgress size={10} sx={{ color: 'white' }} />
                  ) : (
                    <BundleLocations
                      bundleLocations={fileDetails?.bundleLocations || []}
                      fileId={crossRefFileId}
                      filterVal={''}
                      isCrossRef={true}
                    />
                  )}
                </span>
              </CustomViewerButton>
            )}
            {!burned && !edit && update && canCreateAndEditCrossRefs && (
              <CustomViewerButton
                onClick={() => {
                  setEdit(true);
                  crossRefRef.current?.focus();
                  selectMark && selectMark();
                }}
                className={classes.markButton}
              >
                <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faEdit} />
              </CustomViewerButton>
            )}
            {edit && update && (
              <CustomViewerButton
                onClick={saveLink}
                className={clsx(classes.markButton, {
                  [classes.disabled]: noLinkText,
                })}
              >
                <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faUpload} />
              </CustomViewerButton>
            )}
            {!burned && update && canCreateAndEditCrossRefs && (
              <CustomViewerButton
                onClick={() => setAreYouSureDelete(true)}
                className={classes.markButton}
              >
                <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faTrash} />
              </CustomViewerButton>
            )}
          </div>
          {update && <Typography variant="h6">{createdBy?.name}</Typography>}
          {update && (
            <Typography style={{ color: 'gray' }} variant="subtitle2">
              {`Created on ${moment(createdBy?.date)
                .utc()
                .format('DD MMM HH:mm')}`}
            </Typography>
          )}
          {update && (
            <Typography style={{ marginTop: '1rem' }} variant="h6" component="h6">
              {'Link'}
            </Typography>
          )}
          {!edit && update ? (
            <div style={{ padding: '14px 16.5px' }}>
              <Link target="_blank" rel="noreferrer" href={href}>
                {getValues('linkText')}
              </Link>
            </div>
          ) : (
            <Controller
              control={control}
              render={({ field }: any) => {
                return (
                  <DocumentLookup
                    {...field}
                    warning={T.translate('case.crossRefVisibleWarning')}
                    readOnly={!edit && update}
                    autoFocus
                    sx={{ padding: '9px 0px', flexGrow: 1 }}
                    placeholder={T.translate('case.linkPlaceholder')}
                    onCustomSelect={(val: any) => {
                      let linkId;
                      if (val && !val.type) {
                        Object.keys(val).length > 2 && val.crossRefPrompt
                          ? (linkId = val.crossRefPrompt)
                          : (linkId = val.id);
                        setValue('linkText', `${linkId} (${val.name})`);
                        setValue('crossRef', linkId);
                      } else {
                        setValue('linkText', val ? val.id : '');
                        setValue('crossRef', val ? val.id : '');
                      }
                    }}
                    {...(update && {
                      error: errors.link,
                      helperText: errors.link && errors.link.message,
                    })}
                  />
                );
              }}
              rules={{
                required: true,
                validate: (item: any) => {
                  if (!item || !item.id) return 'required';
                  if (item.type === CUSTOM_FLAG && !new RegExp(validation.link).test(item.id))
                    return 'invalid link';
                  return true;
                },
              }}
              name="link"
            />
          )}
          {!update && (
            <SimpleTooltip title={errors.link?.message}>
              <CustomViewerButton
                onClick={saveLink}
                className={clsx(classes.markButton, {
                  [classes.error]: !!errors.link,
                  [classes.disabled]: noLinkText,
                })}
                style={{
                  margin: '0.5rem',
                  width: '2.5rem',
                  borderRadius: '10%',
                  height: '2.5rem',
                  alignSelf: 'center',
                }}
              >
                <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faUpload} />
              </CustomViewerButton>
            </SimpleTooltip>
          )}
        </div>
      </div>
    </div>
  );
};
