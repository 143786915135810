import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  VIEWING_SET_CURRENT_PAGE_NUMBER,
  VIEWING_SET_GOTO_PAGE_NUMBER,
  VIEWING_SET_GOTO_PAGE_NUMBER_FAILURE,
} from './constants';

export function setGoToPageNumber(pageNo) {
  return {
    type: VIEWING_SET_GOTO_PAGE_NUMBER,
    pageNo,
  };
}

export function setCurrentPageNumber(currentPageNo) {
  return {
    type: VIEWING_SET_CURRENT_PAGE_NUMBER,
    currentPageNo,
  };
}

export function useSetPageNumber() {
  const dispatch = useDispatch();

  const currentPageNumber = useSelector(state => state.viewing.currentPageNumber);
  const goToPageNumber = useSelector(state => state.viewing.goToPageNumber);

  const boundActionSetCurrentPageNumber = useCallback(
    (...args) => {
      return dispatch(setCurrentPageNumber(...args));
    },
    [dispatch],
  );

  const boundActionSetGoToPageNumber = useCallback(
    (...args) => {
      return dispatch(setGoToPageNumber(...args));
    },
    [dispatch],
  );

  return {
    setCurrentPageNumber: boundActionSetCurrentPageNumber,
    setGoToPageNumber: boundActionSetGoToPageNumber,
    currentPageNumber,
    goToPageNumber,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_SET_CURRENT_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.currentPageNo,
      };
    case VIEWING_SET_GOTO_PAGE_NUMBER:
      return {
        ...state,
        goToPageNumber: action.pageNo,
      };
    case VIEWING_SET_GOTO_PAGE_NUMBER_FAILURE:
      return {
        ...state,
        goToPageNumber: null,
        goToPageNumberFeedback: action.feedback,
      };

    default:
      return state;
  }
}
