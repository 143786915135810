import React, { useState, useRef, useEffect, useCallback } from 'react';
import T from 'i18n';
import { Button, Fab, Grid, Tooltip } from '@mui/material';
import { ChatOutlined, History, Share } from '@mui/icons-material';
import SearchInput from './SearchInput';
import { NewSearchContainer } from './NewSearchContainer';
import SearchContainer from './SearchContainer';
import { fetchMatches, useFetchMatches } from '../redux/fetchMatches';
import { useDispatch, useSelector } from 'react-redux';
import { searchCenterLocation } from './enums';
import { generateAnswers, useGenerateAnswers } from '../redux/generateAnswers';
import {
  selectAiToolsFilters,
  selectCurrentCaseId,
  selectParams,
  selectUserId,
  selectUserName,
} from 'common/selectors';
import { clearSelection } from 'utils/browser';
import SearchHistory from './SearchHistory';
import { fetchSearchHistoryItem } from '../redux/fetchSearchHistoryItem';
import { Spinner } from 'features/common';
import { generateRandomAlphanumeric } from 'utils/generateRandomAlphanumeric';
import { useUpdateUserSettings } from 'features/common/redux/updateUserSettings';
import { getInitials } from 'utils/getInitials';
import history from 'common/history';
import SearchInfo from './SearchInfo';
import { setAiToolsFilters } from '../redux/setAiToolsFilters';
import { getSelectedLocation } from './SearchInputLocations';
import { useSetAISearchState } from '../redux/setAISearchState';
import { askAI } from '../redux/askAI';

const SearchCenter = () => {
  const perfectScrollbarRef = useRef<any | null>(null);
  const dispatch = useDispatch();
  const { clearMatches, fetchDocumentsPending } = useFetchMatches();
  const { clearAnswers } = useGenerateAnswers();
  const { userSettings, updateUserSettings } = useUpdateUserSettings();
  const {
    searchMatchesInput,
    searchSuggestedQuestions,
    searchAnsChat,
    setAISearchAnswerChat,
    setAISearchMatches,
    updateAISearchMatches,
    setAISearchSuggestedQuestions,
    clearAISearchState,
  } = useSetAISearchState();
  const userName = useSelector(selectUserName);
  const caseId = useSelector(selectCurrentCaseId);
  const currentUserId = useSelector(selectUserId);
  const { file, aiSearchId } = useSelector(selectParams);
  const alreadySelectedFilters = useSelector(selectAiToolsFilters);

  const [isNewSearch, setIsNewSearch] = useState(true);
  const [selectedMatches, setSelectedMatches] = useState<any>([]);
  const [firstSelectedIndex, setFirstSelectedIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [showShareTooltip, setShowShareTooltip] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const showSuggestedQuestions =
    typeof userSettings?.aiSettings?.suggestedQuestions === 'boolean'
      ? userSettings?.aiSettings?.suggestedQuestions
      : true;

  const isAnswersMode = !isNewSearch && searchMatchesInput.find((itm: any) => itm.matches);

  const userInitials = getInitials(userName);
  const [filters, setFilters] = useState({
    location: [] as object[],
    files: [] as object[],
    phrase: '',
    searchHistoryId: '',
    question: '',
    triggerSearch: false,
  });

  const matchesList = searchMatchesInput.find((itm: any) => itm.matches)?.matches || [];

  const matches = [
    {
      id: 'selectedMatches',
      name: 'Selected Matches',
      type: 'matches',
      matches: selectedMatches,
    },
    { id: 'allMatches', name: 'All Matches', type: 'matches' },
    ...(
      (matchesList &&
        matchesList.length > 0 &&
        matchesList.reduce((acc: any, itm: any) => {
          const existingFile = acc.find((f: any) => f.id === itm.fileId);
          if (existingFile) {
            existingFile.matches.push(itm);
            return acc;
          } else {
            return [
              ...acc,
              { id: itm.fileId, name: itm.fileName, type: 'matches', matches: [itm] },
            ];
          }
        }, [])) ||
      []
    ).sort((a: any, b: any) => b.matches.length - a.matches.length),
  ];

  const handleSearchItemClick = useCallback(
    (searchHistoryItmId: string) => {
      handleSearchHistoryClose();
      clearAISearchState();
      setLoading(true);
      dispatch(fetchSearchHistoryItem({ searchHistoryItmId }))
        .then((res: any) => {
          if (res && res.length === 0) {
            setAISearchAnswerChat([
              {
                isError: true,
                text: 'You don"t have access to this search history item',
              },
            ]);
            if (isNewSearch) setIsNewSearch(false);
            return;
          }
          if (isNewSearch) setIsNewSearch(false);
          clearMatches();
          clearAnswers();
          const matchesRes = res.find((itm: any) => itm.action === 'matches');
          const originalAnswersRes = res.filter((itm: any) => itm.action === 'answers');
          const answersRes = originalAnswersRes.reduce((acc: any, itm: any) => {
            const contributor = itm.createdBy.id !== currentUserId ? itm.createdBy.name : null;
            const itemOwnerInitials = itm.createdBy
              ? getInitials(itm.createdBy.name)
              : userInitials;
            return [
              ...acc,
              {
                id: itm.id + '-Question',
                text: itm.query,
                location:
                  matchesRes.matches.length === itm.matches.length
                    ? 'All Matches'
                    : 'Selected Matches',
                user: itemOwnerInitials,
                contributor,
                typingEffect: false,
                options: {
                  sourcePrompt: itm.sourcePrompt,
                  systemPrompt: itm.systemPrompt,
                  useDefaultPrompt: itm.useDefaultPrompt,
                },
              },
              {
                id: itm.id + '-Answer',
                text: itm.sources.length > 0 ? itm.sources : T.translate('case.noAnswerFoundMsg'),
                user: 'botIcon',
                matches: itm.matches,
                showMatches: false,
                typingEffect: false,
                contributor: null,
              },
            ];
          }, []);
          const isAllMatches =
            matchesRes &&
            originalAnswersRes &&
            originalAnswersRes.length > 0 &&
            matchesRes.matches.length ===
              originalAnswersRes[originalAnswersRes.length - 1].matches.length;
          setFilters({
            searchHistoryId: searchHistoryItmId,
            phrase: '',
            location: [{ id: 'allMatches', name: 'All Matches', type: 'matches' }],
            files: [],
            question: '',
            triggerSearch: false,
          });
          setSelectedMatches(
            isAllMatches || originalAnswersRes.length === 0
              ? []
              : originalAnswersRes[originalAnswersRes.length - 1].matches,
          );
          const matchesOwnerInitials = matchesRes.createdBy
            ? getInitials(matchesRes.createdBy.name)
            : userInitials;
          const contributor =
            matchesRes.createdBy.id !== currentUserId ? matchesRes.createdBy.name : null;
          setAISearchMatches({
            phrase: matchesRes.query,
            location: matchesRes.filters.folders,
            files: matchesRes.filters?.files || [],
            user: matchesOwnerInitials,
            contributor,
            matches: matchesRes.matches,
            options: matchesRes.options,
          });
          setAISearchAnswerChat(answersRes);
          history.push(
            history.location.pathname.replace(/\/ai-tools.*/, ``) +
              `/ai-tools/${searchHistoryItmId}`,
          );
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      dispatch,
      isNewSearch,
      userInitials,
      clearMatches,
      clearAnswers,
      currentUserId,
      setAISearchAnswerChat,
      setAISearchMatches,
      clearAISearchState,
    ],
  );

  const handleSelectMatches = (e: any, matchItm: any, index: number, matchesL: Array<any>) => {
    if (e.ctrlKey) {
      // For Ctrl key: Toggle individual match selection
      const isAlreadySelected = selectedMatches.some(
        (selectedMatch: any) => selectedMatch.id === matchItm.id,
      );
      let newSelectedMatches = isAlreadySelected
        ? selectedMatches.filter((selectedMatch: any) => selectedMatch.id !== matchItm.id)
        : [...selectedMatches, matchItm];
      setSelectedMatches(newSelectedMatches);
    } else if (e.shiftKey && firstSelectedIndex !== null) {
      clearSelection();
      // For Shift key: Select all matches in the range
      const range = [firstSelectedIndex, index].sort((a, b) => a - b);
      const newSelectedMatches = matchesL.slice(range[0], range[1] + 1);
      setSelectedMatches(newSelectedMatches);
    } else {
      setSelectedMatches([matchItm]);
      setFirstSelectedIndex(index); // Update the first selected index
    }
  };

  const handleChangeFilters = (updatedItem: any) => {
    setFilters({ ...filters, ...updatedItem });
    if (isAnswersMode && updatedItem['location']) {
      if (updatedItem['location'][0]?.id === searchCenterLocation.allMatches) {
        setSelectedMatches([]);
      } else if (updatedItem['location']?.id === searchCenterLocation.selectedMatches) {
        setSelectedMatches(updatedItem['location'].matches);
      } else {
        setSelectedMatches(updatedItem['location'].map((loc: any) => loc.matches).flat());
      }
    }
  };

  const updateScrollbar = () => {
    if (perfectScrollbarRef.current) {
      const container = perfectScrollbarRef.current;
      container.scrollTop = container.scrollHeight;
    }
  };

  const createMatchesPayload = (topicId: string, inp: any): Object => {
    return {
      id: topicId,
      phrase: inp.phrase,
      location: inp.location,
      files: inp.files,
      user: userInitials,
      contributor: null,
      options: {
        numMatches: (userSettings.aiSettings && userSettings.aiSettings.numMatches) || 10,
        multiQuery: (userSettings.aiSettings && userSettings.aiSettings.multiQuery) || false,
        maxMarginalRelevance:
          (userSettings.aiSettings && userSettings.aiSettings.maxMarginalRelevance) || false,
        index: (userSettings.aiSettings && userSettings.aiSettings.index) || '',
      },
    };
  };

  const processTopic = () => {
    if (isNewSearch) setIsNewSearch(false);
    const topicId = generateRandomAlphanumeric(8);
    setAISearchMatches(createMatchesPayload(topicId, filters));
    dispatch(
      fetchMatches({
        searchTerm: filters.phrase,
        searchLocation: filters.location,
        files: filters.files,
        matchCentric: true,
      }),
    )
      .then((res: any) => {
        if (res.router === 'topic' || res.router === 'question') {
          setFilters({
            phrase: filters.question
              ? filters.question
              : res.router === 'question'
              ? filters.phrase
              : showSuggestedQuestions && res.results.length > 0
              ? 'Can you suggest 5 questions based on these extracts. List the questions without numbering them.' // Default suggested questions
              : '',
            location: res.results.length > 0 ? [matches[matches.length - 1]] : [],
            files: [],
            searchHistoryId: res.searchHistoryId,
            question: '',
            triggerSearch:
              res.router === 'question' ||
              (showSuggestedQuestions && res.router === 'topic' && res.results.length > 0),
          });
          updateAISearchMatches(topicId, { matches: res.results });
          setSelectedMatches([]);
        } else if (res.router === 'lookup') {
          setFilters((prevState: any) => ({
            ...prevState,
            phrase: '',
            location: [],
            files: res.files,
            searchHistoryId: res.searchHistoryId,
            question: '',
            triggerSearch: false,
          }));
          updateAISearchMatches(topicId, { documents: res.files });
          setSelectedMatches([]);
        } else if (res.router === 'summarize') {
          updateAISearchMatches(topicId, {
            router: res.router,
            ...(res.results.length > 0 && { showSummarizeMsg: true }),
            matches: res.results,
          });
        } else if (res.router === 'summarize_transcript') {
          updateAISearchMatches(topicId, {
            router: res.router,
            ...(res.results.length > 0 && { showSummarizeMsg: true }),
            matches: res.results,
          });
        }
        history.push(
          history.location.pathname.replace(/\/ai-tools.*/, ``) +
            `/ai-tools${res.searchHistoryId ? `/${res.searchHistoryId}` : ''}`,
        );
      })
      .catch((err: any) => {
        clearAISearchState();
        setIsNewSearch(true);
      });
  };

  const processQuestions = useCallback(
    (isSuggestedQuestions?: any, inpVal?: string) => {
      const elementId = generateRandomAlphanumeric(8);
      if (isSuggestedQuestions)
        setAISearchSuggestedQuestions({
          id: elementId + '-Answer',
          searching: true,
        });
      else {
        setAISearchAnswerChat([
          {
            id: elementId + '-Question',
            text: inpVal ? inpVal : filters.phrase,
            location: selectedMatches.length > 0 ? 'Selected Matches' : 'All Matches',
            user: userInitials,
            contributor: null,
            typingEffect: false,
            options: {
              //To-Do remove options from here
              sourcePrompt: (userSettings.aiSettings && userSettings.aiSettings.sourcePrompt) || '',
              systemPrompt: (userSettings.aiSettings && userSettings.aiSettings.systemPrompt) || '',
              useDefaultPrompt:
                (userSettings.aiSettings && userSettings.aiSettings.useDefaultPrompt) || true,
            },
          },
          {
            id: elementId + '-AnswerReq',
            text: T.translate('case.searchingText'),
            user: 'botIcon',
            contributor: null,
            typingEffect: false,
          },
        ]);
        setTimeout(() => updateScrollbar(), 0);
      }
      dispatch(
        generateAnswers({
          matches: (selectedMatches?.length > 0
            ? selectedMatches
            : searchMatchesInput.find((itm: any) => itm.matches).matches
          ).map(({ file: f, ...rest }: any) => rest),
          searchQuery: inpVal ? inpVal : filters.phrase,
          searchHistoryId: isSuggestedQuestions ? '' : filters.searchHistoryId,
        }),
      )
        .then((res: any) => {
          const newSearchBtn = document.getElementById('new-search-button');
          //const searchContainerElem = document.getElementById('search-container');
          if (
            newSearchBtn &&
            res &&
            //searchContainerElem?.ariaLabel === res.searchHistoryId && //check if the search container is still the same
            (!res.async || (res.async && res.answer))
          ) {
            if (isSuggestedQuestions)
              setAISearchSuggestedQuestions({
                ...searchSuggestedQuestions,
                searching: false,
                questions: res.sources.length > 0 ? res.sources : null,
              });
            else
              setAISearchAnswerChat([
                {
                  id: elementId + '-Answer',
                  text: res.sources.length > 0 ? res.sources : T.translate('case.noAnswerFoundMsg'),
                  user: 'botIcon',
                  contributor: null,
                  showMatches: false,
                  typingEffect: true,
                  matches:
                    selectedMatches.length > 0 ? selectedMatches : searchMatchesInput.matches,
                },
              ]);
          }
          updateScrollbar();
        })
        .catch((err: any) => {
          if (!isSuggestedQuestions) {
            const newSearchBtn = document.getElementById('new-search-button');
            if (newSearchBtn) {
              setAISearchAnswerChat([
                {
                  id: elementId + '-Error',
                  typingEffect: false,
                  text:
                    err && typeof err.body === 'string'
                      ? err.body
                      : T.translate('case.answersErrorMsg'),
                  user: 'botIcon',
                  contributor: null,
                },
              ]);
              updateScrollbar();
            }
          } else {
            setAISearchSuggestedQuestions({
              ...searchSuggestedQuestions,
              searching: false,
              questions: null,
            }); // Clear suggested questions
          }
        });
    },
    [
      dispatch,
      filters,
      selectedMatches,
      userInitials,
      userSettings,
      searchMatchesInput,
      searchSuggestedQuestions,
      setAISearchAnswerChat,
      setAISearchSuggestedQuestions,
    ],
  );

  const askAIHandler = (command: string, fileId: string, summaryType: string) => {
    const elementId = generateRandomAlphanumeric(8);
    setAISearchAnswerChat([
      {
        id: elementId + '-Question',
        text: `${command} ${fileId}`,
        location: selectedMatches.length > 0 ? 'Selected Matches' : 'All Matches',
        user: userInitials,
        contributor: null,
        typingEffect: false,
        options: {
          //To-Do remove options from here
          sourcePrompt: (userSettings.aiSettings && userSettings.aiSettings.sourcePrompt) || '',
          systemPrompt: (userSettings.aiSettings && userSettings.aiSettings.systemPrompt) || '',
          useDefaultPrompt:
            (userSettings.aiSettings && userSettings.aiSettings.useDefaultPrompt) || true,
        },
      },
      {
        id: elementId + '-AnswerReq',
        text: T.translate('case.searchingText'),
        user: 'botIcon',
        contributor: null,
        typingEffect: false,
      },
    ]);
    setTimeout(() => updateScrollbar(), 0);
    dispatch(askAI({ command, fileId, summaryType }))
      .then((res: any) => {
        setAISearchAnswerChat([
          {
            id: elementId + '-Answer',
            text: res.summary ? res.summary : T.translate('case.noAnswerFoundMsg'),
            command: res.command,
            user: 'botIcon',
            contributor: null,
            showMatches: false,
            typingEffect: true,
            matches: selectedMatches.length > 0 ? selectedMatches : searchMatchesInput.matches,
          },
        ]);
      })
      .catch((err: any) => {
        setAISearchAnswerChat([
          {
            id: elementId + '-Error',
            typingEffect: false,
            text:
              err && typeof err.body === 'string' ? err.body : T.translate('case.answersErrorMsg'),
            user: 'botIcon',
            contributor: null,
          },
        ]);
        updateScrollbar();
      });
  };

  const saveSearchInputs = (phrase: string) => {
    const aiInputs = userSettings?.aiSearchInputs || [];
    const searchInputs = aiInputs[caseId] ? aiInputs[caseId] : [];
    let updatedSearchInputs = [];
    if (
      !!filters.location.find(
        (loc: any) =>
          loc.id === searchCenterLocation.allMatches ||
          loc.id === searchCenterLocation.selectedMatches,
      )
    ) {
      const existingTopicEntry = searchInputs.find(
        (entry: any) => entry.topic === searchMatchesInput.phrase,
      );
      const existingEntry = existingTopicEntry?.questions.find(
        (question: string) => question === phrase,
      );
      updatedSearchInputs = existingEntry
        ? aiInputs
        : {
            ...aiInputs,
            [caseId]: [
              {
                topic: existingTopicEntry ? existingTopicEntry.topic : searchMatchesInput.phrase,
                questions: [phrase, ...(existingTopicEntry?.questions || [])],
              },
              ...searchInputs.filter((entry: any) => entry.topic !== searchMatchesInput.phrase),
            ],
          };
    } else {
      const existingTopicEntry = searchInputs.find((entry: any) => entry.topic === phrase);
      updatedSearchInputs = existingTopicEntry
        ? aiInputs
        : { ...aiInputs, [caseId]: [{ topic: phrase, questions: [] }, ...searchInputs] };
    }
    updateUserSettings({
      userSettings: { ...userSettings, aiSearchInputs: updatedSearchInputs },
    });
  };

  const handleSearch = () => {
    saveSearchInputs(filters.phrase);
    setFilters({ ...filters, phrase: '' });
    updateScrollbar();
    const containsMatches = searchMatchesInput?.find((itm: any) => itm.matches);
    if (!isNewSearch && containsMatches) {
      processQuestions();
    } else {
      processTopic();
    }
  };

  const handleSearchHistoryClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSearchHistoryClose = () => {
    setAnchorEl(null);
  };

  const handleNewSearchClicked = () => {
    setIsNewSearch(true);
    history.push(history.location.pathname.replace(/\/ai-tools.*/, ``) + `/ai-tools`);
    const topicLocation = searchMatchesInput?.location || [];
    const files = searchMatchesInput?.files || [];
    clearMatches();
    clearAnswers();
    setFilters({
      searchHistoryId: '',
      phrase: '',
      location: topicLocation,
      files,
      question: '',
      triggerSearch: false,
    });
    clearAISearchState();
    setSelectedMatches([]);
    const searchInp = document.getElementById('ai-search-input');
    if (searchInp) {
      searchInp.click();
    }
  };

  const copySearchUrl = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      setShowShareTooltip(true);
    });
  };

  // useEffect(() => {
  //   if (aiSearchId && !loading) {
  //     handleSearchItemClick(aiSearchId);
  //   }
  // }, [aiSearchId, handleSearchItemClick, loading]);

  useEffect(() => {
    if (showShareTooltip) {
      setTimeout(() => {
        setShowShareTooltip(false);
      }, 1500);
    }
    return () => {
      clearAISearchState();
    };
  }, [showShareTooltip, clearAISearchState]);

  useEffect(() => {
    if (
      showSuggestedQuestions &&
      !isNewSearch &&
      filters.triggerSearch &&
      searchMatchesInput.find((itm: any) => itm.matches)
    ) {
      const suggestedQuestion =
        'Can you suggest 5 questions based on these extracts. List the questions without numbering them.';
      setFilters({ ...filters, phrase: '', triggerSearch: false });
      processQuestions(filters.phrase === suggestedQuestion);
    }
  }, [
    isNewSearch,
    filters,
    processQuestions,
    showSuggestedQuestions,
    searchMatchesInput,
    searchAnsChat,
  ]);

  useEffect(() => {
    if (alreadySelectedFilters && alreadySelectedFilters.folder) {
      setFilters({
        ...filters,
        location: [...getSelectedLocation(alreadySelectedFilters.folder)],
      });
      dispatch(setAiToolsFilters({}));
    } else if (alreadySelectedFilters && alreadySelectedFilters.files) {
      setFilters({
        ...filters,
        files: [...alreadySelectedFilters.files],
      });
      dispatch(setAiToolsFilters({}));
    }
  }, [alreadySelectedFilters, filters, dispatch]);

  return (
    <>
      <Grid container width="100%" height="100%" padding={1}>
        <Grid
          item
          xs={12}
          sx={{ height: '2.5rem' }}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={<History fontSize="small" />}
            onClick={handleSearchHistoryClick}
          >
            {T.translate('case.searchHistory')}
          </Button>
          <div>
            {!isNewSearch && (
              <>
                <Tooltip
                  placement="bottom"
                  onClose={() => setShowShareTooltip(false)}
                  open={showShareTooltip}
                  onOpen={() => setTimeout(() => setShowShareTooltip(false), 1000)}
                  disableHoverListener
                  title={T.translate('generic.copytoClipboard')}
                  arrow
                >
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{ mr: '0.5rem', height: '2.5rem' }}
                    startIcon={<Share fontSize="small" />}
                    onClick={copySearchUrl}
                  >
                    {T.translate('case.shareSearchUrl')}
                  </Button>
                </Tooltip>
                <Button
                  id="new-search-button"
                  variant="outlined"
                  size="small"
                  sx={{ height: '2.5rem' }}
                  startIcon={<ChatOutlined fontSize="small" />}
                  onClick={handleNewSearchClicked}
                >
                  {T.translate('case.newSearch')}
                </Button>
              </>
            )}
          </div>
        </Grid>
        {!file && <Grid item xs={0} sm={1} md={2} lg={3} />}
        <Grid
          item
          xs={12}
          sm={file ? 12 : 10}
          md={file ? 12 : 8}
          lg={file ? 12 : 6}
          height={`calc(100% - ${isNewSearch ? '3rem' : '14rem'})`}
        >
          {loading ? (
            <Spinner />
          ) : isNewSearch ? (
            <>
              <NewSearchContainer
                handleChangeFilters={handleChangeFilters}
                handleSearch={handleSearch}
                filters={filters}
              />
            </>
          ) : (
            <SearchContainer
              selectedMatches={selectedMatches}
              handleSelectMatches={handleSelectMatches}
              scrollBarRef={perfectScrollbarRef}
              searchContainerId={filters.searchHistoryId}
              startNewSearch={handleNewSearchClicked}
              processQuestions={processQuestions}
              askAIHandler={askAIHandler}
            />
          )}
        </Grid>
        {!file && <Grid item xs={0} sm={1} md={2} lg={3} />}
        {!isNewSearch &&
          !fetchDocumentsPending &&
          searchMatchesInput &&
          (searchMatchesInput.find((itm: any) => itm.matches?.length > 0) ||
            searchMatchesInput.find((itm: any) => itm.documents?.length > 0)) && (
            <>
              {!file && <Grid item xs={0} sm={1} md={2} lg={3} />}
              <Grid item xs={12} sm={file ? 12 : 10} md={file ? 12 : 8} lg={file ? 12 : 6}>
                {/* <Typography
                  variant="h4"
                  sx={{ color: '#212529', fontWeight: 700, textAlign: 'center', mb: '0.5rem' }}
                >
                  {searchContainerState['searchAnsChat']?.length > 1
                    ? T.translate('case.askfollowupQuestionHeading')
                    : T.translate('case.interrogateTopicHeading')}
                </Typography> */}
                <SearchInput
                  filters={filters}
                  handleChangeFilters={handleChangeFilters}
                  handleSearch={handleSearch}
                  isAnswersMode={isAnswersMode}
                  matches={matches}
                  isNewSearch={false}
                  containsMatches={!!searchMatchesInput.find((itm: any) => itm.matches)}
                />
              </Grid>
              {!file && (
                <Grid
                  item
                  xs={0}
                  sm={1}
                  md={2}
                  lg={3}
                  sx={{ textAlign: 'end', alignContent: 'end' }}
                >
                  <Fab size="small" sx={{ backgroundColor: 'whitesmoke', mb: '1rem' }}>
                    <SearchInfo isNewSearch={false} />
                  </Fab>
                </Grid>
              )}
            </>
          )}
        {open && anchorEl && (
          <SearchHistory
            open={open}
            anchorEl={anchorEl}
            handleSearchHistoryClose={handleSearchHistoryClose}
            handleSearchItemClick={handleSearchItemClick}
          />
        )}
      </Grid>
    </>
  );
};

export default SearchCenter;
