import { makeStyles } from '@mui/styles';

export const FiltersStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,.15)',
    borderRadius: '.25rem',
    padding: 0,
    maxWidth: '600px',
  },
  listSubheaderHeading: {
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 1.4rem',
    color: 'gray',
    lineHeight: '2.4rem',
  },
  listSecondaryAction: {
    paddingRight: '4rem',
  },
  clearButton: {
    marginLeft: '9rem',
    marginBottom: ' 0.1rem',
    borderRadius: '0.5rem !important',
    fontSize: '1rem !important',
    height: '2.5rem !important',
    alignSelf: 'center !important',
    margin: '0 !important',
  },
  clearButtonWithInput: {
    marginLeft: '1rem',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  outerBox: {
    borderRadius: '0.5rem',
  },
  formLabel: {
    paddingLeft: '2rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemLabel: {
    padding: 0,
    '& span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      width: '80%',
    },
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    borderRadius: '0.25rem',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    borderRadius: '0.25rem',
    width: 200,
    height: 100,
    padding: '1rem',
  },
}));
