import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CASE_FETCH_TSANDCS_BEGIN,
  CASE_FETCH_TSANDCS_SUCCESS,
  CASE_FETCH_TSANDCS_FAILURE,
  CASE_FETCH_TSANDCS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';

export const fetchTsAndCs = createPromiseAction(CASE_FETCH_TSANDCS_BEGIN, payload => ({
  caseId: payload,
}));

export function dismissfetchTsAndCsFeedback() {
  return {
    type: CASE_FETCH_TSANDCS_FEEDBACK,
  };
}

// worker Saga: will be fired on HOME_FETCH_TSANDCS_BEGIN actions
export function* doFetchTsAndCs(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/terms`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_TSANDCS_FAILURE,
      feedback: {
        message: 'feedback.fetchTsAndCsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_FETCH_TSANDCS_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchTsAndCs() {
  yield takeLatest(fetchTsAndCs, doFetchTsAndCs);
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_TSANDCS_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchTsAndCsPending: true,
        fetchTsAndCsFeedback: null,
      };

    case CASE_FETCH_TSANDCS_SUCCESS:
      return {
        ...state,
        fetchTsAndCsPending: false,
        fetchTsAndCsFeedback: action.feedback,
      };

    case CASE_FETCH_TSANDCS_FAILURE:
      return {
        ...state,
        fetchTsAndCsPending: false,
        fetchTsAndCsFeedback: action.feedback,
      };

    default:
      return state;
  }
}
