import React from 'react';
import T from 'i18n';
import { Modal } from 'features/common/MaterialBasedComponents';
import { useSelector } from 'react-redux';
import { selectParams, selectTimeline } from 'common/selectors';
import { annotationsTableAction } from '../enums';

export default ({ show, action, handleClose, handleSave, selectedRows }: any) => {
  const isTimeline = useSelector(selectTimeline);
  const { quickLink } = useSelector(selectParams);
  const isTimelines = isTimeline && quickLink === 'timelines';
  return (
    <Modal
      show={show}
      title={T.translate('generic.remove')}
      handleClose={handleClose}
      handleSave={() => {
        handleSave(action, selectedRows);
        handleClose();
      }}
      saveTitle={T.translate('generic.remove')}
    >
      {isTimelines
        ? T.translate('case.removeEventsMsg')
        : action === annotationsTableAction.delete
        ? T.translate('case.removeAnnotationsMsg')
        : T.translate('case.removeAnotationsMsg')}
    </Modal>
  );
};
