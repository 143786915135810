import {
  CASE_CREATE_FILE_VERSION_BEGIN,
  CASE_CREATE_FILE_VERSION_SUCCESS,
  CASE_CREATE_FILE_VERSION_FAILURE,
  CASE_CREATE_FILE_VERSION_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const createFileVersion = createPromiseAction(CASE_CREATE_FILE_VERSION_BEGIN);

export function dismissCreateFileVersionFeedback() {
  return {
    type: CASE_CREATE_FILE_VERSION_DISMISS_FEEDBACK,
  };
}

export function* doCreateFileVersion(action) {
  const { caseId, fileId, newVersion } = action.payload;

  let res = yield call(api.post, `/cases/${caseId}/files/${fileId}/versions`, newVersion);

  if (res && res.error) {
    yield put({
      type: CASE_CREATE_FILE_VERSION_FAILURE,
      feedback: {
        message: 'feedback.createFileVersionFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_CREATE_FILE_VERSION_SUCCESS,
    data: { fileVersions: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchCreateFileVersion() {
  yield takeLatest(createFileVersion, withCurrentCaseId(doCreateFileVersion));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_CREATE_FILE_VERSION_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        createFileVersionPending: true,
        createFileVersionFeedback: null,
      };

    case CASE_CREATE_FILE_VERSION_SUCCESS:
      // The request is success
      return {
        ...state,
        createFileVersionPending: false,
        createFileVersionFeedback: action.feedback,
      };

    case CASE_CREATE_FILE_VERSION_FAILURE:
      // The request is failed
      return {
        ...state,
        createFileVersionPending: false,
        createFileVersionFeedback: action.feedback,
      };

    case CASE_CREATE_FILE_VERSION_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        createFileVersionFeedback: null,
      };

    default:
      return state;
  }
}
