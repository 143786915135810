// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as setHubConnectionIdReducer } from './setHubConnectionId';
import { reducer as loginRequiredReducer } from './loginRequired';
import { reducer as updateUserSessionReducer } from './updateUserSession';
import { reducer as loginReducer } from './login';
import { reducer as logoutReducer } from './logout';
import { reducer as setHubConnectionTransportTypeReducer } from './setHubConnectionTransportType';

const reducers = [
  setHubConnectionIdReducer,
  loginRequiredReducer,
  updateUserSessionReducer,
  loginReducer,
  logoutReducer,
  setHubConnectionTransportTypeReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    case 'HOME_FETCH_CASES_SUCCESS': {
      const { systemRole, id, identityId, internalId } = action.data;
      newState = {
        ...state,
        systemRole,
        id,
        identityId,
        internalId,
      };
      break;
    }
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
