import React, { useState, useEffect } from 'react';
import T from 'i18n';
import * as actions from 'features/case/redux/actions';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCurrentCaseTeamId,
  selectCurrentCase,
  selectParams,
  selectCurrentCaseTeams,
  selectIsCaseAdmin,
  selectIsTeamAdmin,
} from 'common/selectors';
import { PageNotFound, Spinner } from 'features/common';
import { Typography } from '@mui/material';
import TeamAdminTableWrapper from './TeamAdminTableWrapper';
import history from 'common/history';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classnames from 'classnames';
import { useFetchCaseDetails } from './redux/fetchCaseDetails';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#F7F7F8',
    padding: '1.3rem 2.6rem',
    height: 'calc(100% - 120px)',
    overflow: 'overlay',
  },
  fullsize: {
    height: '100%',
  },
  teamGrid: {
    height: 'calc(100% - 30px)',
    width: '100%',
  },
  heading: {
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
    fontWeight: 400,
    color: '#9a9a9a',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  teamHeading: {
    '&:hover': {
      cursor: 'pointer',
      color: '#0056b3',
      textDecoration: 'underline',
    },
  },
  '@media (max-width: 991px)': {
    heading: {
      fontSize: '1.3rem',
      lineHeight: '1.5rem',
    },
  },
  '@media (max-width: 600px)': {
    heading: {
      fontSize: '1.2rem',
      lineHeight: '1.25rem',
    },
  },
}));

export const TeamAdmin = ({ isAllUsers }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { caseDetails } = useFetchCaseDetails();
  const [team, setTeam] = useState(null);
  const currentTeamId = useSelector(selectCurrentCaseTeamId);
  const isCaseAdmin = useSelector(selectIsCaseAdmin);
  const isTeamAdmin = useSelector(selectIsTeamAdmin);
  const params = useSelector(selectParams);
  const { name: caseName, id: currentCaseId } = useSelector(selectCurrentCase);
  const allTeams = useSelector(selectCurrentCaseTeams);
  const currentTeam = allTeams.length > 0 && allTeams.find(team => team.id === params.teamId);
  useEffect(() => {
    if (caseDetails && (currentTeamId || params.teamId) && !isAllUsers)
      dispatch(actions.fetchTeam(params.teamId ? params.teamId : currentTeamId))
        .then(res => setTeam({ teamName: res.name, teamId: res.id, users: res.users }))
        .catch(err => console.error(err));
  }, [dispatch, caseDetails, currentTeamId, params.teamId, isAllUsers]);

  useEffect(() => {
    if (isAllUsers) {
      const allUsers = allTeams.reduce((acc, team) => {
        return [...acc, ...team.users];
      }, []);
      setTeam({ teamName: T.translate('case.allUsers'), users: allUsers });
    }
  }, [allTeams, isAllUsers]);

  if (!isTeamAdmin && !isCaseAdmin) return <PageNotFound />;

  return (
    <>
      {team ? (
        <div
          className={classes.root}
          style={(params.teamId || isAllUsers) && { height: '100%', padding: '0 0.7rem' }}
        >
          <Grid container spacing={2} className={classes.fullsize}>
            {!params.teamId && !isAllUsers ? (
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography
                    className={classes.heading}
                    color="inherit"
                    variant="h6"
                    id="teamAdminTitle"
                    component="div"
                  >
                    <span
                      className={classes.teamHeading}
                      onClick={() =>
                        history.push(
                          history.location.pathname.replace(/\/case.*/, ``) +
                            `/case/${currentCaseId}`,
                        )
                      }
                    >
                      {caseName}
                    </span>
                    <ChevronRightIcon fontSize="large" />
                    {team.teamName}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid
              container
              item
              xs={12}
              className={classnames(!params.teamId && !isAllUsers && classes.teamGrid)}
            >
              <TeamAdminTableWrapper
                teamName={
                  isAllUsers
                    ? T.translate('case.allUsers')
                    : currentTeam
                    ? currentTeam.name
                    : team.teamName
                }
                isAllUsers={isAllUsers}
              />
            </Grid>
          </Grid>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default TeamAdmin;
