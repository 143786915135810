import {
  CASE_DELETE_FILE_RING_BEGIN,
  CASE_DELETE_FILE_RING_SUCCESS,
  CASE_DELETE_FILE_RING_FAILURE,
  CASE_DELETE_FILE_RING_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export const deleteFileRing = createPromiseAction(CASE_DELETE_FILE_RING_BEGIN);

export function dismissDeleteFileRingFeedback() {
  return {
    type: CASE_DELETE_FILE_RING_DISMISS_FEEDBACK,
  };
}

export function* doDeleteFileRing(action) {
  const { caseId, fileId, ringId } = action.payload;

  let res = yield call(api.del, `/cases/${caseId}/files/${fileId}/rings/${ringId}`);

  if (res && res.error) {
    yield put({
      type: CASE_DELETE_FILE_RING_FAILURE,
      feedback: {
        message: 'feedback.deleteFileRingFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_DELETE_FILE_RING_SUCCESS,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchDeleteFileRing() {
  yield takeLatest(deleteFileRing, withCurrentCaseId(doDeleteFileRing));
}

export function useDeleteFileRing() {
  const dispatch = useDispatch();

  const { deleteFileRingPending, deleteFileRingFeedback } = useSelector(
    state => ({
      deleteFileRingPending: state.case.deleteFileRingPending,
      deleteFileRingFeedback: state.case.deleteFileRingFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(deleteFileRing(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissDeleteFileRingFeedback());
  }, [dispatch]);

  return {
    deleteFileRing: boundAction,
    deleteFileRingPending,
    deleteFileRingFeedback,
    dismissDeleteFileRingFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_DELETE_FILE_RING_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        deleteFileRingPending: true,
        deleteFileRingFeedback: null,
      };

    case CASE_DELETE_FILE_RING_SUCCESS:
      // The request is success
      return {
        ...state,
        deleteFileRingPending: false,
        deleteFileRingFeedback: action.feedback,
      };

    case CASE_DELETE_FILE_RING_FAILURE:
      // The request is failed
      return {
        ...state,
        deleteFileRingPending: false,
        deleteFileRingFeedback: action.feedback,
      };

    case CASE_DELETE_FILE_RING_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        deleteFileRingFeedback: null,
      };

    default:
      return state;
  }
}
