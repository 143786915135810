import React, { Component } from 'react';
import { connect } from 'react-redux';
import T from 'i18n';
import { selectCurrentCase } from '../../common/selectors';

class NothingSelectedPage extends Component {
  render() {
    return (
      !this.props.fetchCasesPending && (
        <div className="case-nothing-selected-page">
          {T.translate('case.pleaseSelectToGetGoing', { case: this.props.currentCase.name })}
        </div>
      )
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const {
    home: { fetchCasesPending },
  } = state;
  return {
    fetchCasesPending,
    currentCase: selectCurrentCase(state),
  };
}

export default connect(mapStateToProps)(NothingSelectedPage);
