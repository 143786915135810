import { call, put, take, takeLatest } from 'redux-saga/effects';

import {
  CASE_DOWNLOAD_FOLDER_BEGIN,
  CASE_DOWNLOAD_FOLDER_SUCCESS,
  CASE_DOWNLOAD_FOLDER_FAILURE,
  CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const downloadFolder = createPromiseAction(CASE_DOWNLOAD_FOLDER_BEGIN);

export function dismissDownloadFolderFeedback() {
  return {
    type: CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK,
  };
}
export function* doDownloadFolder(action) {
  const {
    payload: { caseId, folderId, ...data },
  } = action;

  yield put({
    type: CASE_DOWNLOAD_FOLDER_BEGIN,
    feedback: {
      message: 'feedback.downloadFolderPending',
      pending: true,
    },
  });

  try {
    const res = yield call(api.post, `/cases/${caseId}/folders/${folderId}/zip-files`, data);
    if (res && res.error) {
      yield put({
        type: CASE_DOWNLOAD_FOLDER_FAILURE,
        feedback: {
          message: 'feedback.downloadFolderFailure',
          error: res.error,
          retryAction: action,
        },
      });
      yield call(rejectPromiseAction, action, res.error);
    } else {
      yield put({
        type: CASE_DOWNLOAD_FOLDER_SUCCESS,
        feedback: {
          message: `feedback.exportFolderSuccess`,
        },
        data: res,
      });
      yield call(resolvePromiseAction, action, res);
    }
  } catch (error) {
    yield put({
      type: CASE_DOWNLOAD_FOLDER_FAILURE,
      feedback: {
        message: 'feedback.downloadFolderFailure',
        error: error.message || error,
        retryAction: action,
      },
    });
    yield call(rejectPromiseAction, action, error);
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchDownloadFolder() {
  yield takeLatest(downloadFolder, withCurrentCaseId(doDownloadFolder));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_DOWNLOAD_FOLDER_BEGIN + '.TRIGGER':
      return {
        ...state,
        downloadFolderPending: true,
        downloadFolderFeedback: null,
      };

    case CASE_DOWNLOAD_FOLDER_SUCCESS:
      return {
        ...state,
        downloadFolderPending: false,
        downloadFolderFeedback: action.feedback,
      };

    case CASE_DOWNLOAD_FOLDER_FAILURE:
      return {
        ...state,
        downloadFolderPending: false,
        downloadFolderFeedback: action.feedback,
      };

    case CASE_DOWNLOAD_FOLDER_DISMISS_FEEDBACK:
      return {
        ...state,
        downloadFolderFeedback: null,
      };

    default:
      return state;
  }
}
