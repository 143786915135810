import React, { useEffect } from 'react';
import T from 'i18n';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useGenerateAnswers } from './redux/generateAnswers';
import { Avatar, Typography } from '@mui/material';
import SearchAnswerContainer from './SearchCenter/SearchAnswerContainer';
import SelectedMatchesContainer from './SearchCenter/SelectedMatchesContainer';
import { useDispatch } from 'react-redux';

const AiToolsResponse = () => {
  const dispatch = useDispatch();
  const { aiToolsResponse, typedResponseCount } = useGenerateAnswers();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [matchesMenuList, setMatchesMenuList] = React.useState([]);
  const openAnswerMenu = Boolean(anchorEl);

  const handleMatchesLinkClicked = (event, index, allMatches, highlight) => {
    const match = {
      ...allMatches[index - 1],
      srcIndex: index,
      highlight,
    };
    if (match) {
      setMatchesMenuList([match]);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMatchesMenuClose = () => {
    setMatchesMenuList([]);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (aiToolsResponse.length > 0 && typedResponseCount < aiToolsResponse.length) {
      setTimeout(
        () => dispatch({ type: 'CASE_SET_TYPED_AI_RESPONSE_COUNT', data: aiToolsResponse.length }),
        5000,
      );
    }
  }, [aiToolsResponse, typedResponseCount, dispatch]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          background: '#ccc',
          fontSize: '1.2rem',
          height: '5rem',
        }}
      >
        <div
          style={{
            flexGrow: 1,
            textAlign: 'center',
            marginRight: '0.5rem',
            alignSelf: 'center',
            fontSize: '1.8rem',
            fontWeight: '600',
          }}
        >
          {T.translate('generic.tvAiAssistant')}
        </div>
      </div>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        style={{ width: '100%', maxHeight: 'calc(100% - 5rem)' }}
      >
        {aiToolsResponse.length > 0 &&
          aiToolsResponse.map((response, indx) => (
            <div
              key={indx}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'start',
                padding: '1rem',
              }}
            >
              <Avatar
                sx={{
                  width: 28,
                  height: 28,
                  mr: 2,
                  bgcolor: '#85A0F1',
                }}
              >
                <Typography>{'TV'}</Typography>
              </Avatar>
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 600, fontSize: '1.5rem', color: 'red', mr: '0.5rem' }}
                  >
                    Inconsistency Detected
                  </Typography>
                </div>
                <Typography
                  style={{
                    lineBreak: 'auto',
                    fontSize: '1.5rem',
                  }}
                >
                  <SearchAnswerContainer
                    response={response.sources}
                    typingEffect={
                      typedResponseCount < aiToolsResponse.length &&
                      indx === aiToolsResponse.length - 1
                    }
                    scrollBarRef={null}
                    handleMatchesLinkClicked={handleMatchesLinkClicked}
                    allMatchesCount={response.matches ? response.matches.length : 100}
                    allMatches={response.matches}
                    isTranscriptPage={true}
                  />
                </Typography>
                <br />
                <br />
              </div>
            </div>
          ))}
      </PerfectScrollbar>
      {openAnswerMenu && anchorEl && (
        <SelectedMatchesContainer
          matchesItm={matchesMenuList}
          handleMatchesMenuClose={handleMatchesMenuClose}
          anchorEl={anchorEl}
          openAnswerMenu={openAnswerMenu}
          parentContainerId={null}
          isTranscriptPage={true}
        />
      )}
    </>
  );
};

export default AiToolsResponse;
