import React, { forwardRef } from 'react';
import LookupMui from 'features/common/MaterialBasedComponents/Lookup';
import { useDispatch } from 'react-redux';
import { fetchDocumentsLookup } from 'features/viewing/redux/fetchDocumentsLookup';

export default forwardRef((props, ref) => {
  const dispatch = useDispatch();
  return (
    <LookupMui
      {...props}
      loadOptions={inputValue => dispatch(fetchDocumentsLookup(inputValue))}
      minInputLength={2}
      valueKey="id"
      valueKey0="crossRefPrompt"
      labelKey="name"
      subLabelKey="externalId"
      placeholder="Search document or type link"
      variant="outlined"
      noOptionsText="No documents"
      customInput
    />
  );
});
