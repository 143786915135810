import { otpLength } from 'features/auth/Otp';
import React, { useState } from 'react';
import OtpInput from 'react-otp-input';

const OtpForm = ({ defaultValue, label, setOtpValue, setDisableSubmit }) => {
  const [inpValue, setInpValue] = useState(defaultValue);

  return (
    <div>
      <span
        style={{
          display: 'block',
          textAlign: 'left',
          marginBottom: '0.5rem',
          fontSize: '1.1rem',
        }}
      >
        {label}
      </span>
      <OtpInput
        numInputs={6}
        value={inpValue}
        onChange={val => {
          setInpValue(val);
          setOtpValue(val);
          val.length === otpLength ? setDisableSubmit(false) : setDisableSubmit(true);
        }}
        //separator={<span>-</span>}
        shouldAutoFocus
        isInputNum
        inputStyle={{
          width: '3rem',
          height: '3rem',
          //margin: '0 1rem',
          marginRight: '1rem',
          fontSize: '1.2rem',
          borderRadius: 4,
          border: '1px solid rgba(0,0,0,0.3)',
        }}
      />
    </div>
  );
};

export default OtpForm;
