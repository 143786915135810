import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  CASE_UPDATE_DOCUMENT_BEGIN,
  CASE_UPDATE_DOCUMENT_SUCCESS,
  CASE_UPDATE_DOCUMENT_FAILURE,
  CASE_UPDATE_DOCUMENT_DISMISS_FEEDBACK,
  CASE_UPDATE_DOCUMENT_TAB_SUCCESS,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder } from '../../../common/selectors';
import { replaceItemImmutable } from 'utils/arrays';
import addSortNumberToDocuments from './addSortNumberToDocuments';

export function updateDocument(data, fileId) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_UPDATE_DOCUMENT_BEGIN,
    payload: { data, fileId },
  };
}

export function dismissUpdateDocumentFeedback() {
  return {
    type: CASE_UPDATE_DOCUMENT_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_META_BEGIN actions
export function* doUpdateDocument(action) {
  const {
    payload: { data, fileId, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);
  const folderId = currentFolder.id;

  const res = yield call(api.put, `/cases/${caseId}/folders/${folderId}/files/${fileId}`, {
    ...data,
    etag: currentFolder.etag,
  });

  if (res && res.error) {
    return yield put({
      type: CASE_UPDATE_DOCUMENT_FAILURE,
      feedback: {
        message: 'feedback.updateDocumentFailure',
        error: res.error,
        retryAction: action,
      },
      fileId,
    });
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  if (data && data.tab) {
    return yield put({
      type: CASE_UPDATE_DOCUMENT_TAB_SUCCESS,
      data: { data: data, fileId, zeroBasedIndex: currentFolder.zeroBasedIndex },
    });
  }

  yield put({
    type: CASE_UPDATE_DOCUMENT_SUCCESS,
    data: { data: data, fileId, zeroBasedIndex: currentFolder.zeroBasedIndex },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateDocument() {
  yield takeEvery(CASE_UPDATE_DOCUMENT_BEGIN, withCurrentCaseId(doUpdateDocument));
}

// Redux reducer
export function reducer(state, action) {
  const getIndex = documentId => state.documents.findIndex(({ id }) => id === documentId);
  switch (action.type) {
    case CASE_UPDATE_DOCUMENT_BEGIN:
      return {
        ...state,
        updateDocumentPending: true,
        updateDocumentFeedback: null,
      };

    case CASE_UPDATE_DOCUMENT_TAB_SUCCESS:
    case CASE_UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        updateDocumentPending: false,
        updateDocumentFeedback: action.feedback,
        documents: addSortNumberToDocuments(
          replaceItemImmutable(
            state.documents,
            { ...state.documents[getIndex(action.data.fileId)], ...action.data.data },
            getIndex(action.data.fileId),
          ),
          action.data.zeroBasedIndex,
        ),
      };

    case CASE_UPDATE_DOCUMENT_FAILURE:
      // The request is failed
      const concurencyError = action.feedback.error.status === 412;
      return {
        ...state,
        updateDocumentPending: false,
        updateDocumentFeedback: action.feedback,
        ...(concurencyError && {
          documents: replaceItemImmutable(
            state.documents,
            {
              ...state.documents[getIndex(action.fileId)],
              ...action.feedback.error.body,
            },
            getIndex(action.fileId),
          ),
        }),
      };

    case CASE_UPDATE_DOCUMENT_DISMISS_FEEDBACK:
      return {
        ...state,
        updateDocumentFeedback: null,
      };

    default:
      return state;
  }
}
