import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectConfidentialityRing, selectCurrentUserConfidentialityRing } from 'common/selectors';
import React from 'react';
import { useSelector } from 'react-redux';
import { cfRingColorMapping } from '../TableCase/TableInfo';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export default ({ confidentialityRing }: any) => {
  const currentUserCR = useSelector(selectCurrentUserConfidentialityRing);
  const isConfidentialityRing = (useSelector(selectConfidentialityRing) &&
    currentUserCR !== 'noRing') as boolean;

  return isConfidentialityRing && confidentialityRing ? (
    <FontAwesomeIcon
      style={{
        color: cfRingColorMapping[confidentialityRing],
        margin: '0.2rem',
      }}
      icon={faCircle}
    />
  ) : null;
};
