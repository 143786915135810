import {
  CASE_FETCH_NOTIFICATIONS_BEGIN,
  CASE_FETCH_NOTIFICATIONS_SUCCESS,
  CASE_FETCH_NOTIFICATIONS_FAILURE,
  CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK,
  CASE_FETCH_SIGNALR_NOTIFICATION_SUCCESS,
  CASE_FETCH_SIGNALR_NOTIFICATION_FEEDBACK,
  CASE_FETCH_SIGNALR_TRANSIENT_NOTIFICATION_SUCCESS,
} from './constants';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchNotifications = createPromiseAction(CASE_FETCH_NOTIFICATIONS_BEGIN);

export function dismissFetchNotificationsFeedback() {
  return {
    type: CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK,
  };
}

export function* doFetchNotifications(action) {
  const {
    payload: { caseId },
  } = action;

  try {
    const res = yield call(api.get, `/cases/${caseId}/notifications`);
    if (res && res.error) {
      console.error('Error fetching notifications:', res.error);
      throw new Error(res.error);
    }
    const notifications = res || [];

    const regularNotifications = notifications.filter(notification => !notification.transient);
    const transientNotifications = notifications.filter(notification => notification.transient);

    yield put({
      type: CASE_FETCH_NOTIFICATIONS_SUCCESS,
      data: {
        notifications: regularNotifications,
        transientNotifications: transientNotifications,
      },
    });

    yield call(resolvePromiseAction, action, res);
  } catch (error) {
    console.error('Exception in doFetchNotifications:', error);
    yield put({
      type: CASE_FETCH_NOTIFICATIONS_FAILURE,
      feedback: {
        message: 'feedback.fetchNotificationsFailure',
        error,
        retryAction: action,
      },
    });
    yield call(rejectPromiseAction, action, error);
  }
}

export function* watchFetchNotifications() {
  yield takeLatest(fetchNotifications, withCurrentCaseId(doFetchNotifications));
}

export function useFetchNotifications() {
  const dispatch = useDispatch();
  const {
    notifications,
    transientNotifications,
    fetchNotificationsPending,
    fetchNotificationsFeedback,
  } = useSelector(
    state => ({
      notifications: state.case.notifications,
      transientNotifications: state.case.transientNotifications,
      fetchNotificationsPending: state.case.fetchNotificationsPending,
      fetchNotificationsFeedback: state.case.fetchNotificationsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchNotifications(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchNotificationsFeedback());
  }, [dispatch]);

  return {
    notifications,
    transientNotifications,
    fetchNotifications: boundAction,
    fetchNotificationsPending,
    fetchNotificationsFeedback,
    dismissFetchNotificationsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_SIGNALR_TRANSIENT_NOTIFICATION_SUCCESS:
      return {
        ...state,
        transientNotifications: [...state.transientNotifications, action.data.notification],
      };
    case CASE_FETCH_NOTIFICATIONS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchNotificationsPending: true,
        fetchNotificationsFeedback: null,
      };

    case CASE_FETCH_NOTIFICATIONS_SUCCESS:
      const { notifications } = action.data;
      const regularNotifications = notifications.filter(notification => !notification.transient);
      const transientNotifications = notifications.filter(notification => notification.transient);

      return {
        ...state,
        fetchNotificationsPending: false,
        fetchNotificationsFeedback: action.feedback,
        notifications: regularNotifications,
        transientNotifications: transientNotifications,
      };

    case CASE_FETCH_SIGNALR_NOTIFICATION_SUCCESS:
      const notification = action.data.notification;
      if (notification.transient) {
        return {
          ...state,
          transientNotifications: [...state.transientNotifications, notification],
          fetchNotificationsFeedback: action.feedback,
        };
      } else {
        return {
          ...state,
          notifications: [...state.notifications, notification],
          fetchNotificationsFeedback: action.feedback,
        };
      }
    case CASE_FETCH_SIGNALR_NOTIFICATION_FEEDBACK:
      return {
        ...state,
        fetchNotificationsFeedback: action.feedback,
      };

    case CASE_FETCH_NOTIFICATIONS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchNotificationsPending: false,
        fetchNotificationsFeedback: action.feedback,
      };

    case CASE_FETCH_NOTIFICATIONS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchNotificationsFeedback: null,
      };

    default:
      return state;
  }
}
