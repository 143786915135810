import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import T from 'i18n';
import * as actions from 'features/case/redux/actions';
import { teamAdminModal } from './enums';
import { Modal } from 'features/common/MaterialBasedComponents';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import {
  SelectConfidentialityRing,
  SelectForm,
  SystemTestsTable,
  TextForm,
  ToggleForm,
} from 'features/common';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Spinner from 'features/common/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAISearch,
  selectConfidentialityRing,
  selectCurrentCaseTeams,
  selectDataRoomRoles,
  selectHearingRoomRoles,
  selectUserEmail,
  selectUserId,
} from 'common/selectors';
import copy from 'copy-to-clipboard';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { emailValidation } from 'utils/regex';
import ActivityLogs from 'features/common/ActivityLogs';
import LoginHistoryTable from './LoginHistoryTable';
import moment from 'moment';
import { Divider } from '@mui/material';

const getTeamId = (user, teams) => {
  if (teams.length > 0) {
    const team = teams.find(team => team.users.find(item => item.id === user.id));
    return team?.id;
  }
};

const InviteMemberForm = ({
  idx,
  selectedConference,
  users,
  register,
  reset,
  control,
  errors,
  hearingRoomRoles,
  dataRoomRoles,
  removeMember,
  isConfidentialityRing,
  updateInvitations,
  invitations,
}) => {
  const isAiSearchEnabled = useSelector(selectAISearch);

  const isExistingUser = val => {
    if (emailValidation.test(val)) {
      const existingUser = users.find(user => user.mail === val);
      if (existingUser) return false;
      else return true;
    }
  };
  return (
    <Grid
      container
      key={idx}
      sx={{ border: '1px solid #E3E3E3', borderRadius: '0.5rem', p: '1rem', mb: '0.5rem' }}
    >
      {selectedConference && (
        <>
          <Grid item container sx={{ mt: '1rem' }} columnSpacing={1}>
            <Grid item xs={3}>
              <ToggleForm
                {...register(`users[${idx}].hasProtectedVideo`)}
                defaultValue={invitations[idx].hasProtectedVideo}
                label={T.translate(`case.hasProtectedVideo`)}
                onChange={e =>
                  updateInvitations(idx, {
                    hasProtectedVideo: e.target.checked,
                  })
                }
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <ToggleForm
                {...register(`users[${idx}].canSeeProtectedVideo`)}
                defaultValue={invitations[idx].canSeeProtectedVideo}
                label={T.translate(`case.canSeeProtectedVideo`)}
                onChange={e =>
                  updateInvitations(idx, {
                    canSeeProtectedVideo: e.target.checked,
                  })
                }
              />
            </Grid>
          </Grid>
          <Grid item container sx={{ mt: '1rem' }} columnSpacing={1}>
            <Grid item xs={3}>
              <ToggleForm
                {...register(`users[${idx}].startInWaitingRoom`)}
                defaultValue={invitations[idx].startInWaitingRoom}
                label={T.translate(`case.startInWaitingRoom`)}
                onChange={e =>
                  updateInvitations(idx, {
                    startInWaitingRoom: e.target.checked,
                  })
                }
              />
            </Grid>
            {isConfidentialityRing && (
              <>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      height: '2rem',
                    }}
                  >
                    <SelectConfidentialityRing
                      register={register}
                      name={`users[${idx}].confidentialityRing`}
                      onChange={e =>
                        updateInvitations(idx, {
                          confidentialityRing: e.target.value,
                        })
                      }
                      defaultValue={invitations[idx].confidentialityRing}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}

      <Grid item container sx={{ mt: '1rem' }} columnSpacing={1}>
        {isAiSearchEnabled && (
          <>
            <Grid item xs={3}>
              <ToggleForm
                {...register(`users[${idx}].aiTools`)}
                defaultValue={invitations[idx].aiTools}
                label={T.translate(`generic.aiTools`)}
                onChange={e =>
                  updateInvitations(idx, {
                    aiTools: e.target.checked,
                  })
                }
              />
            </Grid>
            <Grid item xs={4} />
          </>
        )}
        <Grid item xs={isAiSearchEnabled ? 4 : 3}>
          <ToggleForm
            {...register(`users[${idx}].canViewRealTime`)}
            defaultValue={invitations[idx].canViewRealTime}
            label={T.translate(`generic.canViewRealTime`)}
            onChange={e =>
              updateInvitations(idx, {
                canViewRealTime: e.target.checked,
              })
            }
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} columnSpacing={1} sx={{ mt: '1rem' }}>
        <Grid item xs={3}>
          <TextForm
            {...register(`users[${idx}].email`, {
              validate: {
                required: val => !!val,
                isInvalidEmail: val => emailValidation.test(val),
                isExistingUser,
              },
              onChange: e => {
                e.target.value = e.target.value.trim();
                updateInvitations(idx, { email: e.target.value });
              },
            })}
            label={T.translate('case.emailAddress')}
            placeholder={T.translate('case.emailAddressPlaceholder')}
            defaultValue={invitations[idx].email}
            errors={errors}
            autofocus
          />
        </Grid>
        <Grid item xs={4}>
          <SelectForm
            label={T.translate('case.dataRoomRole')}
            name={`users[${idx}].dataRoomRole`}
            defaultValue={invitations[idx].dataRoomRole}
            onChange={val => updateInvitations(idx, { dataRoomRole: val })}
            valueKey="id"
            labelKey="name"
            options={dataRoomRoles}
            getOptionLabel={option => `${option['name']} - ${option['description'] || ''}`}
            control={control}
            errors={errors}
            rules={{ required: true }}
            menuPosition="fixed"
          />
        </Grid>
        {selectedConference && (
          <Grid item xs={4}>
            <SelectForm
              label={T.translate('case.hearingRoomRole')}
              name={`users[${idx}].hearingRoomRole`}
              defaultValue={invitations[idx].hearingRoomRole}
              onChange={val => updateInvitations(idx, { hearingRoomRole: val })}
              valueKey="id"
              labelKey="name"
              options={hearingRoomRoles}
              getOptionLabel={option => `${option['name']} - ${option['description'] || ''}`}
              control={control}
              errors={errors}
              rules={{ required: true }}
              menuPosition="fixed"
            />
          </Grid>
        )}
        {idx !== 0 && (
          <Grid item xs={1}>
            <IconButton
              style={{ marginTop: '-10px' }}
              onClick={() => {
                reset(formValues => ({
                  users: [...formValues.users.filter((itm, index) => index !== idx)],
                }));
                removeMember(idx);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const InviteMember = ({
  show,
  action,
  handleClose,
  handleSave,
  users,
  teamId,
  selectedConference,
  dataRoomRoles,
  hearingRoomRoles,
  importedUsersList,
}) => {
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const isConfidentialityRing = useSelector(selectConfidentialityRing);
  const [loading, setLoading] = useState(false);
  const defaultInvitation = {
    ...(selectedConference && {
      startInWaitingRoom: false,
      hasProtectedVideo: false,
      canSeeProtectedVideo: false,
      aiTools: false,
      canViewRealTime: false,
    }),
    ...(selectedConference && isConfidentialityRing && { confidentialityRing: 'noRing' }),
    email: '',
    dataRoomRole: '',
    ...(selectedConference && { hearingRoomRole: '' }),
  };
  const [invitations, setInvitations] = useState(
    importedUsersList.length > 0
      ? importedUsersList.map(user => ({ defaultInvitation, email: user }))
      : [defaultInvitation],
  );

  const removeMember = index => {
    setLoading(true);
    setInvitations(invitations.filter((itm, idx) => idx !== index));
    setTimeout(() => setLoading(false), 500);
  };

  const updateInvitations = (index, itm) => {
    const updatedItems = [...invitations];
    updatedItems[index] = { ...updatedItems[index], ...itm };
    setInvitations(updatedItems);
  };

  const handleCloseModal = () => {
    setInvitations([defaultInvitation]);
    handleClose();
  };

  const updateRoles = () => {
    setLoading(true);
    const updatedItems = [...invitations];
    updatedItems.forEach((itm, idx) => {
      updatedItems[idx] = {
        ...updatedItems[idx],
        dataRoomRole: updatedItems[0].dataRoomRole,
        hearingRoomRole: updatedItems[0].hearingRoomRole,
      };
    });
    setInvitations(updatedItems);
    reset(() => ({
      users: updatedItems,
    }));
    setTimeout(() => setLoading(false), 500);
  };

  return (
    <Modal
      show={show}
      title={T.translate('case.addNewMember')}
      handleClose={() => handleCloseModal()}
      handleSave={handleSubmit(data => {
        invitations.map(user => handleSave(action, user, teamId, data.sendInvite));
        handleCloseModal();
      })}
      saveTitle={T.translate('generic.add')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            <Grid
              container
              sx={{ mb: '0.5rem', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Grid item xs={3}>
                <ToggleForm
                  {...register('sendInvite')}
                  defaultValue={true}
                  label={T.translate(`case.invite`)}
                />
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'end' }}>
                <Button variant="text" onClick={() => updateRoles()}>
                  {T.translate('case.copyRolesToAllMembers')}
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              {invitations.map((itm, idx) => (
                <InviteMemberForm
                  idx={idx}
                  selectedConference={selectedConference}
                  users={users}
                  register={register}
                  errors={errors}
                  control={control}
                  reset={reset}
                  dataRoomRoles={dataRoomRoles}
                  hearingRoomRoles={hearingRoomRoles}
                  removeMember={removeMember}
                  isConfidentialityRing={isConfidentialityRing}
                  updateInvitations={updateInvitations}
                  invitations={invitations}
                />
              ))}
            </Grid>
            <Grid container sx={{ mt: '0.5rem' }}>
              <Grid item xs={6}>
                <Button
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  onClick={() =>
                    setInvitations([
                      ...invitations,
                      {
                        ...(selectedConference && {
                          startInWaitingRoom: false,
                          hasProtectedVideo: false,
                          canSeeProtectedVideo: false,
                          aiTools: false,
                          canViewRealTime: false,
                        }),
                        ...(selectedConference &&
                          isConfidentialityRing && { confidentialityRing: 'noRing' }),
                        email: '',
                        dataRoomRole: '',
                        ...(selectedConference && { hearingRoomRole: '' }),
                      },
                    ])
                  }
                >
                  {T.translate('case.addAnotherPerson')}
                </Button>
              </Grid>
            </Grid>
          </Form.Group>
        </Form>
      )}
    </Modal>
  );
};

const ReSendInvite = ({ show, action, handleClose, handleSave, selectedRows, teamId }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      show={show}
      title={T.translate('case.reSendInvite')}
      handleClose={handleClose}
      handleSave={() => {
        setLoading(true);
        selectedRows.map(row => handleSave(action, row, teamId));
        setLoading(false);
        handleClose();
      }}
      saveTitle={T.translate('generic.reSend')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div className="removeUser">
          <span>{T.translate('case.reSendInviteMsg1')}</span>
          <ul>
            {selectedRows.map((row, index) => (
              <li key={index}>{row.mail}</li>
            ))}
          </ul>
          <span>{T.translate('case.removeUserMsg2')}</span>
        </div>
      )}
    </Modal>
  );
};

const UpdateMember = ({
  show,
  action,
  handleClose,
  handleSave,
  selectedRows,
  hearingRoomRoles,
  dataRoomRoles,
  teamId,
  teams,
  selectedConference,
  users,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const dispatch = useDispatch();

  const isAiSearchEnabled = useSelector(selectAISearch);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(selectedRows[0] || {});
  const currentUserIndex = users.findIndex(itm => itm.id === user.id);
  const [navigationBtnClicked, setNavigationBtnClicked] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(null);
  const [disableActionBtns, setDisableActionBtns] = useState({
    disableNext: false,
    disablePrev: false,
  });
  const isConfidentialityRing = useSelector(selectConfidentialityRing);
  let selectedUser = selectedRows.length > 1 ? selectedRows : [user];

  const saveFileDataNoFetch = data => {
    setLoading(true);
    data.users.map(userItm =>
      dispatch(
        actions.updateTeamMember({
          teamId: teamId ? teamId : getTeamId(userItm, teams),
          user: userItm,
        }),
      ),
    );
    setLoading(false);
  };

  const prevClicked = data => {
    const currentFileIdx = users.findIndex(itm => itm.id === user.id);
    if (currentFileIdx > 0) {
      saveFileDataNoFetch(data);
      const newUser = users[currentFileIdx - 1];
      setUser(newUser);
      reset({ users: [newUser] });
    }
  };

  const nextClicked = data => {
    const currentFileIdx = users.findIndex(itm => itm.id === user.id);
    if (currentFileIdx < users.length - 1) {
      saveFileDataNoFetch(data);
      const newUser = users[currentFileIdx + 1];
      setUser(newUser);
      reset({ users: [newUser] });
    }
  };

  const handleUserKeyPress = useCallback(
    e => {
      if (disableActionBtns.disablePrev && disableActionBtns.disableNext) return;
      setNavigationBtnClicked(false);
      if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowRight') {
        setCurrentSelection(e.target.name);
        const nextBtn = document.getElementById('updateUserNextButton');
        nextBtn.click();
        setNavigationBtnClicked(true);
      } else if ((e.ctrlKey || e.metaKey) && e.code === 'ArrowLeft') {
        setCurrentSelection(e.target.name);
        const prevBtn = document.getElementById('updateUserPrevButton');
        prevBtn.click();
        setNavigationBtnClicked(true);
      }
    },
    [disableActionBtns],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    setDisableActionBtns({
      disableNext: currentUserIndex === users.length - 1 ? true : false,
      disablePrev: currentUserIndex === 0 ? true : false,
    });
  }, [currentUserIndex, users]);

  const onSave = data => {
    if (navigationBtnClicked) {
      dispatch(actions.fetchCaseDetails());
      handleClose();
    } else {
      setLoading(true);
      const updatePromises = data.users.map(userItm =>
        dispatch(
          actions.updateTeamMember({
            teamId: teamId ? teamId : getTeamId(userItm, teams),
            user: userItm,
          }),
        ),
      );
      Promise.all(updatePromises)
        .then(() => {
          return dispatch(actions.fetchCaseDetails());
        })
        .then(() => {
          setLoading(false);
          setNavigationBtnClicked(false);
          handleClose();
        })
        .catch(error => {
          console.error('Error updating team members:', error);
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      show={show}
      title={T.translate('case.updateMember')}
      handleClose={() => {
        setNavigationBtnClicked(false);
        handleClose();
      }}
      handleSave={handleSubmit(data => onSave(data))}
      saveTitle={
        loading ? <Spinner style={{ fontSize: '0.5rem' }} /> : T.translate('generic.update')
      }
      buttons={
        <>
          <Button
            type="button"
            id="updateUserPrevButton"
            onClick={handleSubmit(data => prevClicked(data))}
            variant="contained"
            color="primary"
            disabled={disableActionBtns.disablePrev || selectedRows.length > 1}
          >
            {T.translate('generic.previous')}
          </Button>
          <Button
            type="button"
            id="updateUserNextButton"
            onClick={handleSubmit(data => nextClicked(data))}
            variant="contained"
            color="primary"
            disabled={disableActionBtns.disableNext || selectedRows.length > 1}
          >
            {T.translate('generic.next')}
          </Button>
        </>
      }
      hintContent={
        <>
          <span>
            <b>{T.translate('generic.shortcuts')}</b>
          </span>
          <Divider />
          <span>{T.translate('common.shortcutPrevText')}</span>
          <br />
          <span>{T.translate('common.shortcutNextText')}</span>
          <br />
          <span>{T.translate('common.shortcutSaveText')}</span>
        </>
      }
    >
      {/* {loading ? (
        <Spinner />
      ) : ( */}
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <Form.Group>
          {selectedUser.map((row, index) => {
            const {
              id,
              mail,
              name,
              dataRoomRole,
              hearingRoomName,
              hearingRoomTitle,
              hearingRoomRole,
              notes,
              startInWaitingRoom,
              aiTools,
              canViewRealTime,
              hasProtectedVideo,
              canSeeProtectedVideo,
              confidentialityRing,
              autoShareTranscriptNotesInto,
            } = row;
            return (
              <div
                key={id}
                style={{
                  border: '1px solid #E3E3E3',
                  borderRadius: '0.5rem',
                  padding: '1rem',
                  marginBottom: '0.5rem',
                }}
              >
                <input
                  {...register(`users[${selectedUser.length - index - 1}].id`)}
                  type="hidden"
                  defaultValue={id}
                />
                <h5>{name ? `${name} (${mail})` : mail}</h5>
                {selectedConference && (
                  <>
                    <Grid
                      container
                      sx={{ mt: '1rem', mb: '0.5rem', justifyContent: 'space-between' }}
                      columnSpacing={3}
                    >
                      <Grid item xs={6}>
                        <ToggleForm
                          {...register(
                            `users[${selectedUser.length - index - 1}].hasProtectedVideo`,
                          )}
                          defaultValue={hasProtectedVideo}
                          label={T.translate(`case.hasProtectedVideo`)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ToggleForm
                          {...register(
                            `users[${selectedUser.length - index - 1}].canSeeProtectedVideo`,
                          )}
                          defaultValue={canSeeProtectedVideo}
                          label={T.translate(`case.canSeeProtectedVideo`)}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{ mt: '1rem', mb: '0.5rem', justifyContent: 'space-between' }}
                      columnSpacing={3}
                    >
                      <Grid item xs={6}>
                        <ToggleForm
                          {...register(
                            `users[${selectedUser.length - index - 1}].startInWaitingRoom`,
                          )}
                          defaultValue={startInWaitingRoom}
                          label={T.translate(`case.startInWaitingRoom`)}
                        />
                      </Grid>
                      {isConfidentialityRing && (
                        <Grid item xs={6}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              height: '2rem',
                            }}
                          >
                            <SelectConfidentialityRing
                              register={register}
                              name={`users[${selectedUser.length - index - 1}].confidentialityRing`}
                              defaultValue={confidentialityRing}
                            />
                          </div>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
                <Grid
                  container
                  sx={{ mt: '1rem', mb: '0.5rem', justifyContent: 'space-between' }}
                  columnSpacing={3}
                >
                  {isAiSearchEnabled && (
                    <Grid item xs={6}>
                      <ToggleForm
                        {...register(`users[${selectedUser.length - index - 1}].aiTools`)}
                        defaultValue={aiTools}
                        label={T.translate(`generic.aiTools`)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <ToggleForm
                      {...register(`users[${selectedUser.length - index - 1}].canViewRealTime`)}
                      defaultValue={canViewRealTime}
                      label={T.translate(`generic.canViewRealTime`)}
                    />
                  </Grid>
                </Grid>
                {selectedConference && (
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <TextForm
                        {...register(`users[${selectedUser.length - index - 1}].hearingRoomName`, {
                          required: false,
                        })}
                        label={T.translate('case.hearingRoomName')}
                        placeholder={T.translate('case.userNamePlaceholder')}
                        defaultValue={hearingRoomName}
                        autofocus={
                          !currentSelection ||
                          (navigationBtnClicked &&
                            currentSelection ===
                              `users[${selectedUser.length - index - 1}].hearingRoomName`)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextForm
                        {...register(`users[${selectedUser.length - index - 1}].hearingRoomTitle`, {
                          required: false,
                        })}
                        label={T.translate('case.hearingRoomTitle')}
                        placeholder={T.translate('case.userNamePlaceholder')}
                        defaultValue={hearingRoomTitle}
                        autofocus={
                          navigationBtnClicked &&
                          currentSelection ===
                            `users[${selectedUser.length - index - 1}].hearingRoomTitle`
                        }
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container sx={{ mt: '0.5rem' }} columnSpacing={4}>
                  <Grid item xs={6}>
                    <SelectForm
                      label={T.translate('case.dataRoomRole')}
                      name={`users[${selectedUser.length - index - 1}].dataRoomRole`}
                      defaultValue={dataRoomRole}
                      valueKey="id"
                      labelKey="name"
                      options={dataRoomRoles}
                      getOptionLabel={option =>
                        `${option['name']} - ${option['description'] || ''}`
                      }
                      control={control}
                      errors={errors}
                      rules={{ required: true }}
                      menuPosition="fixed"
                      autofocus={
                        navigationBtnClicked &&
                        currentSelection ===
                          `users[${selectedUser.length - index - 1}].dataRoomRole`
                      }
                    />
                  </Grid>
                  {selectedConference && (
                    <Grid item xs={6}>
                      <SelectForm
                        label={T.translate('case.hearingRoomRole')}
                        name={`users[${selectedUser.length - index - 1}].hearingRoomRole`}
                        defaultValue={hearingRoomRole}
                        valueKey="id"
                        labelKey="name"
                        options={hearingRoomRoles}
                        getOptionLabel={option =>
                          `${option['name']} - ${option['description'] || ''}`
                        }
                        control={control}
                        errors={errors}
                        rules={{ required: true }}
                        menuPosition="fixed"
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container sx={{ mt: '0.5rem' }}>
                  <Grid item xs={12}>
                    <TextForm
                      {...register(`users[${selectedUser.length - index - 1}].notes`)}
                      as="textarea"
                      label={T.translate('case.notes')}
                      placeholder={T.translate('case.notesPlaceholder')}
                      defaultValue={notes}
                      autofocus={
                        navigationBtnClicked &&
                        currentSelection === `users[${selectedUser.length - index - 1}].notes`
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </Form.Group>
      </Form>
      {/* )} */}
    </Modal>
  );
};

const ChangeHearingRoomPosition = ({
  show,
  action,
  handleClose,
  handleSave,
  selectedRows,
  teams,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      show={show}
      title={T.translate('case.hearingRoomPosition')}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        setLoading(true);
        data.users.map(user => handleSave(action, user, getTeamId(user, teams)));
        setLoading(false);
        handleClose();
      })}
      saveTitle={T.translate('generic.update')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            {selectedRows.map((row, index) => {
              const { id, mail, name, positionId } = row;
              return (
                <div
                  key={id}
                  style={{
                    border: '1px solid #E3E3E3',
                    borderRadius: '0.5rem',
                    padding: '1rem',
                    marginBottom: '0.5rem',
                  }}
                >
                  <input
                    {...register(`users[${selectedRows.length - index - 1}].id`)}
                    type="hidden"
                    defaultValue={id}
                  />
                  <h5>{name ? `${name} (${mail})` : mail}</h5>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <TextForm
                        {...register(`users[${selectedRows.length - index - 1}].positionId`, {
                          validate: { isValidEntry: val => val > 0 && val <= 20 },
                          onChange: e => {
                            e.target.value = e.target.value.toString().slice(0, 2);
                          },
                        })}
                        label={T.translate('case.hearingRoomPosition')}
                        type={'number'}
                        placeholder={T.translate('case.hearingRoomPositionPlaceholder')}
                        defaultValue={positionId}
                        errors={errors}
                        autofocus
                      />
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </Form.Group>
        </Form>
      )}
    </Modal>
  );
};

const RemoveMember = ({ show, action, handleClose, handleSave, selectedRows, teamId, teams }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      show={show}
      title={T.translate('case.removeMember')}
      handleClose={handleClose}
      handleSave={() => {
        setLoading(true);
        selectedRows.map(row => handleSave(action, row, teamId ? teamId : getTeamId(row, teams)));
        setLoading(false);
        handleClose();
      }}
      saveTitle={T.translate('generic.remove')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <div className="removeUser">
          <span>{T.translate('case.removeUserMsg1')}</span>
          <ul>
            {selectedRows.map((row, index) => (
              <li key={index}>{row.mail}</li>
            ))}
          </ul>
          <span>{T.translate('case.removeUserMsg2')}</span>
        </div>
      )}
    </Modal>
  );
};

export const ChangeTeam = ({
  show,
  action,
  handleClose,
  handleSave,
  selectedRows,
  teamId,
  selectedConference,
  fetchTeamDetails,
}) => {
  const teams = useSelector(selectCurrentCaseTeams);
  const currentUserId = useSelector(selectUserId);
  const currentUserEmail = useSelector(selectUserEmail);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const userDetails = fetchTeamDetails
    ? teams?.find(t => t.id === teamId)?.users.find(u => u.internalId === currentUserId)
    : null;
  const defaultDetails = {
    mail: currentUserEmail,
    aiTools: false,
    canSeeProtectedVideo: false,
    canViewRealTime: false,
    dataRoomRole: 'godDR',
    hearingRoomRole: 'fixedActive',
    startInWaitingRoom: false,
  };
  const currentRow =
    (fetchTeamDetails
      ? userDetails
        ? userDetails
        : defaultDetails
      : selectedRows && selectedRows[0]) || {};
  const teamsOption = teams
    .map(team => ({ name: team.name, id: team.id }))
    .filter(item => item.id !== teamId);

  useEffect(() => {
    if (fetchTeamDetails) dispatch(actions.fetchTeams());
  }, [fetchTeamDetails, dispatch]);

  return (
    <Modal
      show={show}
      title={T.translate('generic.changeTeam')}
      handleClose={handleClose}
      handleSave={handleSubmit(data => {
        setLoading(true);
        handleSave(
          action,
          {
            ...currentRow,
            ...data,
            data: selectedConference
              ? {
                  email: currentRow.mail,
                  dataRoomRole: currentRow.dataRoomRole,
                  hearingRoomRole: currentRow.hearingRoomRole,
                }
              : { email: currentRow.mail, roleId: currentRow.roleId },
          },
          teamId ? teamId : getTeamId(currentRow, teams),
        );
        setTimeout(() => {
          setLoading(false);
          handleClose();
        }, 500);
      })}
      saveTitle={T.translate('generic.change')}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
          }}
        >
          <Form.Group>
            <Grid container spacing={2}>
              <Grid container item sx={{ display: 'block' }}>
                {T.translate('case.changeTeamMsg', {
                  email: currentRow.mail,
                })}
              </Grid>
              <Grid container item>
                <Grid item xs={6}>
                  <SelectForm
                    name="newTeamId"
                    placeholder={T.translate('case.changeTeamPlaceholder')}
                    valueKey="id"
                    labelKey="name"
                    //autofocus
                    defaultValue={''}
                    options={teamsOption}
                    control={control}
                    errors={errors}
                    rules={{ required: true }}
                    menuPosition="fixed"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form.Group>
        </Form>
      )}
    </Modal>
  );
};

const useStylesToolTip = makeStyles(theme => ({
  arrow: {
    color: 'black !important',
  },
  tooltip: {
    backgroundColor: 'black !important',
    color: 'white !important',
  },
}));

const GetInviteLink = ({ show, handleClose, selectedRows, teamId, teams }) => {
  const classes = useStylesToolTip();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const [showToolTip, setShowToolTip] = useState(false);

  const copyLink = e => {
    e.target.select();
    copy(inviteLink);
    setShowToolTip(true);
    setTimeout(() => {
      setShowToolTip(false);
    }, 1000);
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      actions.fetchInviteLink({
        teamId: teamId ? teamId : getTeamId(selectedRows[0], teams),
        user: selectedRows[0],
      }),
    )
      .then(res => {
        setInviteLink(res);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        handleClose();
      });
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Modal
          show={show}
          title={T.translate('generic.inviteLink')}
          handleClose={() => {
            setShowToolTip(false);
            handleClose();
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Tooltip
                classes={classes}
                open={showToolTip}
                title={T.translate('generic.copytoClipboard')}
                arrow
              >
                <TextForm
                  label={T.translate('case.shareLink')}
                  defaultValue={inviteLink}
                  className="link-overflow"
                  onClick={copyLink}
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

const GetTeamActivityLogs = ({ show, handleClose, teamId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activityLog, setActivityLog] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(actions.getTeamActivityLogs({ teamId }))
      .then(res => {
        setActivityLog(res);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
        handleClose();
      });
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Modal
          show={show}
          title={T.translate('generic.activityLogs')}
          handleClose={() => {
            setActivityLog([]);
            handleClose();
          }}
        >
          {activityLog.length > 0 ? (
            <ActivityLogs
              data={activityLog}
              tableHeight={'40rem'}
              rowHeight={95}
              outsideTableMargin={'-1.5rem'}
            />
          ) : (
            T.translate('generic.noActivityRecords')
          )}
        </Modal>
      )}
    </>
  );
};

const ShowSystemTestResult = ({ show, action, selectedRows, handleClose, hoveredUser }) => {
  const dispatch = useDispatch();
  const [testResults, setTestResults] = useState(null);
  const [testDate, setTestDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const canfetchSystemTestResult =
    (hoveredUser && hoveredUser.hasDiagnostics) ||
    (selectedRows && selectedRows.length === 1 && selectedRows[0].hasDiagnostics) ||
    (hoveredUser && hoveredUser.diagnostics) ||
    (selectedRows && selectedRows.length === 1 && selectedRows[0].diagnostics);

  useEffect(() => {
    if (canfetchSystemTestResult)
      dispatch(
        actions.fetchSystemTest({ userId: (hoveredUser && hoveredUser.id) || selectedRows[0].id }),
      )
        .then(res => {
          setLoading(false);
          setTestResults(res.length > 0 ? res[0].diagnostics : []);
          setTestDate(
            res.length > 0 ? moment(res[0].created).format('MMMM DD, YYYY, HH:mm') : null,
          );
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    else {
      setTestResults([]);
      setLoading(false);
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title={
        testDate
          ? T.translate('generic.systemTestResultWithDate', {
              date: `(${testDate})`,
            })
          : T.translate('generic.systemTestResult')
      }
      handleClose={() => {
        setTestResults(null);
        setTestDate(null);
        handleClose();
      }}
    >
      {loading ? (
        <Spinner />
      ) : testResults && testResults.length > 0 ? (
        <div style={{ margin: '-1.5rem' }}>
          <SystemTestsTable data={testResults} />
        </div>
      ) : (
        T.translate('case.noSystemTestResultFound')
      )}
    </Modal>
  );
};

const RemoveSystemTestResult = ({
  show,
  action,
  handleClose,
  handleSave,
  teams,
  teamId,
  hoveredUser,
}) => {
  return (
    <Modal
      show={show}
      title={T.translate('generic.removeSystemTestResult')}
      saveTitle={T.translate('generic.remove')}
      handleSave={() => {
        const updatedUser = { ...hoveredUser, hasDiagnostics: false };
        handleSave(action, updatedUser, teamId ? teamId : getTeamId(hoveredUser, teams));
        handleClose();
      }}
      handleClose={() => {
        handleClose();
      }}
    >
      {T.translate('case.removeSystemTestResulMsg', { email: hoveredUser.mail })}
    </Modal>
  );
};

const LoginHistory = ({ show, handleClose, selectedRows, teamId, teams }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loginHistoryRecords, setLoginHistoryRecords] = useState(null);
  const [userStatus, setUserStatus] = useState('');

  const userStatusMsgMapping = {
    NOTFOUND: 'User Has Not Signed Up',
    CONFIRMED: 'User Is Signed Up',
    UNCONFIRMED: 'User Has Not Confirmed Email',
  };

  useEffect(() => {
    if (selectedRows && selectedRows[0].id) {
      setLoading(true);
      dispatch(actions.fetchLoginHistory({ userId: selectedRows[0].id }))
        .then(res => {
          setUserStatus(res.userStatus);
          setLoginHistoryRecords(res.loginEvents);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          if (err.status === 404) setUserStatus('NOTFOUND');
          setLoading(false);
        });
    }
  }, [show]);

  return (
    <Modal
      show={show}
      title={`${T.translate('generic.loginHistory')}: ` + (userStatusMsgMapping[userStatus] || '')}
      handleClose={() => {
        handleClose();
      }}
    >
      {loading ? (
        <Spinner />
      ) : loginHistoryRecords && loginHistoryRecords.length > 0 ? (
        <div style={{ margin: '-1.5rem' }}>
          <LoginHistoryTable data={loginHistoryRecords} />
        </div>
      ) : (
        T.translate('case.nologinHistoryFound')
      )}
    </Modal>
  );
};

const TeamAdminModal = ({
  show,
  action,
  selectedRows,
  handleClose,
  handleSave,
  teamId,
  users,
  teams,
  selectedConference,
  hoveredUser,
  importedUsersList,
}) => {
  const hearingRoomRoles = useSelector(selectHearingRoomRoles);
  const dataRoomRoles = useSelector(selectDataRoomRoles);

  return (
    <>
      {action === teamAdminModal.updateMember && (
        <UpdateMember
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
          dataRoomRoles={Array.isArray(dataRoomRoles) ? dataRoomRoles : []}
          hearingRoomRoles={Array.isArray(hearingRoomRoles) ? hearingRoomRoles : []}
          teamId={teamId}
          teams={teams}
          selectedConference={selectedConference}
          users={users}
        />
      )}
      {action === teamAdminModal.changeHearingRoomPosition && (
        <ChangeHearingRoomPosition
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
          teams={teams}
        />
      )}
      {action === teamAdminModal.removeMember && (
        <RemoveMember
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
          teamId={teamId}
          teams={teams}
        />
      )}
      {action === teamAdminModal.inviteMember && (
        <InviteMember
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          users={users}
          teamId={teamId}
          selectedConference={selectedConference}
          dataRoomRoles={Array.isArray(dataRoomRoles) ? dataRoomRoles : []}
          hearingRoomRoles={Array.isArray(hearingRoomRoles) ? hearingRoomRoles : []}
          importedUsersList={importedUsersList}
        />
      )}
      {action === teamAdminModal.changeTeam && (
        <ChangeTeam
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
          teamId={teamId}
          selectedConference={selectedConference}
        />
      )}
      {action === teamAdminModal.getInviteLink && (
        <GetInviteLink
          show={show}
          action={action}
          handleClose={handleClose}
          selectedRows={selectedRows}
          teamId={teamId}
          teams={teams}
        />
      )}
      {action === teamAdminModal.getActivityLogs && (
        <GetTeamActivityLogs
          show={show}
          action={action}
          handleClose={handleClose}
          selectedRows={selectedRows}
          teamId={teamId}
        />
      )}
      {action === teamAdminModal.systemTestResult && (
        <ShowSystemTestResult
          show={show}
          action={action}
          handleClose={handleClose}
          selectedRows={selectedRows}
          hoveredUser={hoveredUser}
        />
      )}
      {action === teamAdminModal.loginHistory && (
        <LoginHistory
          show={show}
          action={action}
          handleClose={handleClose}
          selectedRows={selectedRows}
          teamId={teamId}
          teams={teams}
        />
      )}
      {action === teamAdminModal.removeSystemTestResult && (
        <RemoveSystemTestResult
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          teamId={teamId}
          teams={teams}
          hoveredUser={hoveredUser}
        />
      )}
      {action === teamAdminModal.reSendInvite && (
        <ReSendInvite
          show={show}
          action={action}
          handleClose={handleClose}
          handleSave={handleSave}
          selectedRows={selectedRows}
          teamId={teamId}
        />
      )}
    </>
  );
};

TeamAdminModal.prototype = {
  handleClose: PropTypes.func,
  modalAction: PropTypes.string,
};

export default TeamAdminModal;
