import EventEmitter from 'events';
import negotiateConnectionWithClientOffer from './negotiateConnectionWithClientOffer.js';
import logger from 'utils/logger.js';

/**
 * Example implementation of a client that uses WHIP to broadcast video over WebRTC
 *
 * https://www.ietf.org/archive/id/draft-ietf-wish-whip-01.html
 */
export default class WHIPClient extends EventEmitter {
  constructor(endpoint, stream) {
    super();
    this.endpoint = endpoint;
    this.localStream = stream;
    /**
     * Create a new WebRTC connection, using public STUN servers with ICE,
     * allowing the client to disover its own IP address.
     * https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Protocols#ice
     */
    this.peerConnection = new RTCPeerConnection({
      iceServers: [
        {
          urls: 'stun:stun.cloudflare.com:3478',
        },
      ],
      bundlePolicy: 'max-bundle',
    });
    /**
     * Listen for negotiationneeded events, and use WHIP as the signaling protocol to establish a connection
     *
     * https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/negotiationneeded_event
     * https://www.ietf.org/archive/id/draft-ietf-wish-whip-01.html
     */
    this.peerConnection.addEventListener('negotiationneeded', async ev => {
      await negotiateConnectionWithClientOffer(this.peerConnection, this.endpoint);
    });

    this.peerConnection.addEventListener('connectionstatechange', ev => {
      this.emit('connectstate', this.peerConnection.connectionState);
      if (
        this.peerConnection.connectionState === 'closed' ||
        this.peerConnection.connectionState === 'disconnected' ||
        this.peerConnection.connectionState === 'failed'
      ) {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      }
    });

    stream.getTracks().forEach(track => {
      this.peerConnection.addTransceiver(track, {
        /** WHIP is only for sending streaming media */
        direction: 'sendonly',
      });
    });
  }

  /**
   * Terminate the streaming session
   * 1. Notify the WHIP server by sending a DELETE request
   * 2. Close the WebRTC connection
   * 3. Stop using the local camera and microphone
   *
   * Note that once you call this method, this instance of this WHIPClient cannot be reused.
   */
  async disconnectStream() {
    logger.INFO('disconnecting from whip');
    var _a;
    await fetch(this.endpoint, {
      method: 'DELETE',
      mode: 'cors',
    });
    this.peerConnection.close();
    (_a = this.localStream) === null || _a === void 0
      ? void 0
      : _a.getTracks().forEach(track => track.stop());
  }
}
