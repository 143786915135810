import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AUTH_LOGIN, AUTH_LOGIN_ACCEPT_INVITE } from './constants';
import Logger from 'utils/logger';

export function login(user, showScreen) {
  if (showScreen) {
    return {
      type: AUTH_LOGIN_ACCEPT_INVITE,
      payload: user,
    };
  } else {
    return {
      type: AUTH_LOGIN,
      payload: user,
    };
  }
}

export function useLogin() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(login(...params)), [dispatch]);
  return { login: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      Logger.INFO('Authentication state changed: Authenticated');
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_LOGIN_ACCEPT_INVITE:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
