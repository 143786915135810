import {
  CASE_FETCH_CASE_DETAILS_BEGIN,
  CASE_FETCH_CASE_DETAILS_SUCCESS,
  CASE_FETCH_CASE_DETAILS_FAILURE,
  CASE_FETCH_CASE_DETAILS_DISMISS_FEEDBACK,
} from './constants';
import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchCaseDetails = createPromiseAction(CASE_FETCH_CASE_DETAILS_BEGIN, payload => {
  // in order to get that payload to trigger action nwith meta
  return { ...payload };
});

export function dismissFetchCaseDetailsFeedback() {
  return {
    type: CASE_FETCH_CASE_DETAILS_DISMISS_FEEDBACK,
  };
}

export function* doFetchCaseDetails(action) {
  const { caseId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_CASE_DETAILS_FAILURE,
      feedback: {
        message: 'feedback.fetchCaseDetailsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_CASE_DETAILS_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchCaseDetails() {
  yield takeLatest(fetchCaseDetails, withCurrentCaseId(doFetchCaseDetails));
}

export function useFetchCaseDetails() {
  const dispatch = useDispatch();

  const { caseDetails, fetchCaseDetailsPending, fetchCaseDetailsFeedback } = useSelector(
    state => ({
      caseDetails: state.case.caseDetails,
      fetchCaseDetailsPending: state.case.fetchCaseDetailsPending,
      fetchCaseDetailsFeedback: state.case.fetchCaseDetailsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchCaseDetails(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchCaseDetailsFeedback());
  }, [dispatch]);

  return {
    caseDetails,
    fetchCaseDetails: boundAction,
    fetchCaseDetailsPending,
    fetchCaseDetailsFeedback,
    dismissFetchCaseDetailsFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_CASE_DETAILS_BEGIN + '.TRIGGER':
      // Just after a request is sent

      return {
        ...state,
        ...(!action.payload.noPending && { fetchCaseDetailsPending: true }),
        fetchCaseDetailsFeedback: null,
      };

    case CASE_FETCH_CASE_DETAILS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchCaseDetailsPending: false,
        fetchCaseDetailsFeedback: action.feedback,
        caseDetails: action.data,
      };

    case CASE_FETCH_CASE_DETAILS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchCaseDetailsPending: false,
        fetchCaseDetailsFeedback: action.feedback,
      };

    case CASE_FETCH_CASE_DETAILS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchCaseDetailsFeedback: null,
      };

    default:
      return state;
  }
}
