import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import PropTypes from 'prop-types';
import T from 'i18n';
import useFilterContext from 'features/case/TableCase/useFilterContext';
import { getHighlightedText } from 'utils/highlightText';

export default function InfoTwoRows({ field, title, style = undefined, className = undefined }) {
  const filterContext = useFilterContext();
  return (
    <React.Fragment>
      <Row style={style || { margin: '0.1rem' }} className={className}>
        <Col style={{ fontWeight: 'bold', margin: 0 }}>{T.translate(title)}</Col>
      </Row>
      {field && (
        <Row style={style || { margin: '0.2rem' }} className={className}>
          <Col style={{ margin: 0 }}>
            {filterContext && filterContext.filter ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: getHighlightedText(field, filterContext.filter),
                }}
              ></div>
            ) : (
              field
            )}
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
}

InfoTwoRows.propTypes = {};
InfoTwoRows.defaultProps = {};
