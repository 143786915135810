import React, { useEffect, useState } from 'react';
import { CustomViewerButton } from 'features/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import T from 'i18n';
import { makeStyles } from '@mui/styles';
import AddNewEvent from 'features/case/AnnotationsTableWrapper/AddNewEvent';
import { annotationsTableAction } from 'features/case/enums';
import { useDispatch } from 'react-redux';
import { updateTimelines } from 'features/case/redux/updateTimelines';
import * as actionsV from 'features/viewing/redux/actions';
import { useSelector } from 'react-redux';
import { selectParams } from 'common/selectors';

const useStyles = makeStyles(() => ({
  markButton: {
    padding: '0.25rem',
    position: 'relative',
    transition: 'opacity 150ms, visibility 0ms 150ms',
    whiteSpace: 'nowrap',
    backgroundColor: '#4567e5',

    border: 'none',
    boxShadow: '0 4px 6px 0 #666',

    color: 'white',
    textAlign: 'center',
    opacity: '0.85',

    '> div': {
      fontSize: '1.2rem',
    },

    a: {
      color: 'white',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },

    "&[type='button']:hover, &div [type='button']:hover": {
      boxShadow: '0 4px 10px 0 #666',
      opacity: 1,
    },
  },
}));

const AddEvent = ({ mark, addEventHandler }: any) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { file } = useSelector(selectParams);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [defaultFile, setDefaultFile] = useState(null);

  useEffect(() => {
    if (mark && file) {
      dispatch(actionsV.fetchDocumentsLookup(file)).then((res: any) => setDefaultFile(res[0]));
    }
  }, [mark, dispatch, file]);

  return (
    <>
      <CustomViewerButton
        onClick={() => {
          if (defaultFile) setShowAddEventModal(!showAddEventModal);
        }}
        className={classes.markButton}
      >
        <FontAwesomeIcon style={{ margin: '0 0.25rem' }} icon={faCalendarCheck} />
        {T.translate('case.event')}
      </CustomViewerButton>
      {showAddEventModal && (
        <AddNewEvent
          handleClose={() => {
            addEventHandler();
            setShowAddEventModal(false);
          }}
          show={showAddEventModal}
          action={annotationsTableAction.addNewEvent}
          handleSave={(timeline: any, data: any) => {
            dispatch(
              updateTimelines({
                timeline: {
                  ...timeline,
                  events: [
                    ...timeline.events,
                    mark && {
                      ...data,
                      location: {
                        startIndex: mark.position.startIndex,
                        textLength: mark.position.length,
                        pageNumber: mark.pageNumber,
                        x: mark.boundingRectangle.x,
                        y: mark.boundingRectangle.y,
                      },
                    },
                  ].filter(Boolean),
                },
                showSuccessToast: true,
              }),
            );
            addEventHandler();
          }}
          showTimelineList={true}
          mark={mark}
          defaultFile={defaultFile}
        />
      )}
    </>
  );
};

export default AddEvent;
