import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import sessionStorage from 'redux-persist/lib/storage/session';

import homeReducer from '../features/home/redux/reducer';
import commonReducer from '../features/common/redux/reducer';
import caseReducer from '../features/case/redux/reducer';
import authReducer from '../features/auth/redux/reducer';
import matcherReducer from '../features/matcher/redux/reducer';
import viewingReducer from '../features/viewing/redux/reducer';
import conferenceReducer from '../features/conference/redux/reducer';

const persistConfigAuth = {
  key: 'login.request',
  storage,
  whitelist: ['redirectStartPage'],
};

const persistConfigCommon = {
  key: 'common.nonSupportedBrowser',
  storage: sessionStorage,
  whitelist: ['nonSupportedBrowser'],
};

const persistConfigConference = {
  key: 'conference.token',
  storage,
  whitelist: ['token', 'room'],
};

// NOTE 1: DO NOT CHANGE the 'reducerMap' name and the declaration pattern.
// This is used for Rekit cmds to register new features, remove features, etc.
// NOTE 2: always use the camel case of the feature folder name as the store branch name
// So that it's easy for others to understand it and Rekit could manage them.
const reducerMap = {
  home: homeReducer,
  common: persistReducer(persistConfigCommon, commonReducer),
  case: caseReducer,
  auth: persistReducer(persistConfigAuth, authReducer),
  matcher: matcherReducer,
  viewing: viewingReducer,
  conference: persistReducer(persistConfigConference, conferenceReducer),
};

const createRootReducer = history =>
  combineReducers({
    ...reducerMap,
    router: connectRouter(history),
  });

export default createRootReducer;
