import { select, take } from 'redux-saga/effects';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import fastEqual from 'react-fast-compare';
import calculateSize from 'calculate-size';
import { getThumbnailSize } from 'features/twilio/utils';

export const fastDeepEqualSelectorCreator = createSelectorCreator(defaultMemoize, fastEqual);

export const selectMatchedPath = function({ matcher: { path } }: any) {
  return path;
};

export const selectMatchedUrl = function({ matcher: { url } }: any) {
  return url;
};

export const selectQuery = function({ matcher: { query } }: any) {
  return query;
};

export const selectPresentQuery = function({ matcher: { query } }: any) {
  return query.present;
};

export const selectDocSearchQuery = function({ matcher: { query } }: any) {
  return query.docSearch;
};

export const selectIsPublicHearingRoom = createSelector(selectMatchedPath, path =>
  path.includes('public-hearing-room'),
);

export const selectIsHome = createSelector(selectMatchedPath, path => path === '/');

export const selectParams = fastDeepEqualSelectorCreator(
  ({ matcher: { params } }: any) => params,
  function(params) {
    return params || {};
  },
);

export const selectSpecificParam = createSelector(
  selectParams,
  (_: any, specificParam: any) => specificParam,
  (params: any, specificParam: any) => params[specificParam],
);

export const selectCases = fastDeepEqualSelectorCreator(
  function({ home: { cases } }: any) {
    return cases;
  },
  function(cases: any) {
    return cases || [];
  },
);

export const selectFetchCasesFailureStatus = createSelector(
  ({ home: { fetchCasesFailure } }: any) => fetchCasesFailure,
  fetchCasesFailure => fetchCasesFailure || false,
);

export const selectFetchCasesPending = function({ home: { fetchCasesPending = false } }) {
  return fetchCasesPending;
};

export const selectIsNewCase = function({ home: { newCase = false } }) {
  return newCase;
};

const _selectCaseDetails = fastDeepEqualSelectorCreator(
  function(state: any) {
    return state.case.caseDetails;
  },
  function(caseDetails: any) {
    return caseDetails || {};
  },
);

export const selectCaseDetails = createSelector(
  (state: any) => selectSpecificParam(state, 'case'),
  _selectCaseDetails,
  (caseCode: any, details: any) => {
    return caseCode === (details && details.id) && details;
  },
);

export const selectCurrentCase = createSelector(
  selectCases,
  (state: any) => selectSpecificParam(state, 'case'),
  selectCaseDetails,
  (cases: any, caseCode, caseDetails) => {
    const homeCase = cases.find((c: any) => c.id === caseCode);
    return caseDetails || homeCase || {};
  },
);

export const selectUserNeedsToAcceptTsAndCs = createSelector(_selectCaseDetails, function(
  caseDetails: any,
) {
  return caseDetails.userNeedsToAcceptTsAndCs || false;
});

export const selectCountryCode = createSelector(_selectCaseDetails, function(caseDetails: any) {
  return caseDetails.countryCode || 'IRL';
});

export const selectCaseRegionTemp = createSelector(
  (state: any) => state.home || {},
  (state: any) => state.case || {},
  (home: any, currentCase: any) => home.caseRegionTemp || currentCase.caseRegionTemp || null,
);

export const selectSettings = fastDeepEqualSelectorCreator(
  selectCurrentCase,
  currentCase => currentCase.settings || {},
);

export const selectCaseShortName = createSelector(
  selectCurrentCase,
  currentCase => currentCase.shortName || '',
);

export const selectCaseClaimNo = createSelector(
  selectCurrentCase,
  currentCase => currentCase.claimNumber || '',
);

export const selectTwilioSettings = fastDeepEqualSelectorCreator(
  selectSettings,
  settings => settings.twilio || {},
);

export const selectRolesSettings = createSelector(selectSettings, settings => settings.roles || {});

export const selectCustomDataRoomRoles = createSelector(
  selectRolesSettings,
  settings => settings.customDataRoomRoles || null,
);

export const selectCustomHearingRoomRoles = createSelector(
  selectRolesSettings,
  settings => settings.customHearingRoomRoles || null,
);

export const selectCaseDataRoomSettings = createSelector(
  selectSettings,
  settings => settings.dataRoom || {},
);

export const selectPublicHyperlinks = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.publicHyperlinks || false,
);

export const selectGenerateHyperlinks = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.generateHyperlinks || false,
);

export const selectShowCourtBundles = createSelector(selectCaseDataRoomSettings, dataRoom =>
  typeof dataRoom.showCourtBundles === 'boolean' ? dataRoom.showCourtBundles : true,
);

export const selectShowGroupBundles = createSelector(selectCaseDataRoomSettings, dataRoom =>
  typeof dataRoom.showGroupBundles === 'boolean' ? dataRoom.showGroupBundles : false,
);

export const selectDeepSearch = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.deepSearch || false,
);

export const selectAISearch = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.pySemanticSearch || false,
);

export const selectUsePrizmDocCaching = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.usePrizmDocCaching || false,
);

export const selectTruncateLongFolderName = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.truncateLongFolderName || true,
);

export const selectCustomContainers = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.customContainers || false,
);

export const selectContainers = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.containers || [],
);

export const selectGlobalPagingTrialbooks = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.globalPagingTrialbooks || false,
);

export const selectCrossRef = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.crossReference || false,
);

export const selectCrossRefFontSize = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => parseInt(dataRoom.crossRefFontSize) || 7,
);

export const selectEnableRedaction = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.enableRedaction || false,
);

export const selectWiderColumns = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.widerColumns || false,
);

export const selectWiderDateColumn = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.widerDateColumn || false,
);

export const selectTagsFeature = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.tags || false,
);

export const selectMaxPagePrefetch = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.maxPagePrefetch || 5,
);

export const selectEnableNotifications = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.enableNotifications || false,
);

export const selectAutoSetDoDate = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.automaticallySetDocDate || true,
);

export const selectConfidentialityRing = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.confidentialityRing || false,
);

export const selectTimeline = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.timeline || false,
);

export const selectBBCDemo = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.bbcDemo || false,
);

export const selectPreserveAttchedFileSortOrder = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.preserveAttchedFileSortOrder || false,
);

export const selectUseOnlyIdColForPresentMode = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.useOnlyIdColForPresentMode || false,
);

export const selectUseBackEndForPresentMode = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.useBackEndForPresentMode || false,
);

export const selectDisableSearchFromAllDocs = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.disableSearchFromAllDocs || false,
);

export const selectShowAllDocuments = createSelector(selectCaseDataRoomSettings, dataRoom =>
  typeof dataRoom.showAllDocuments === 'boolean' ? dataRoom.showAllDocuments : true,
);

export const selectAllowBatchUpload = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.allowBatchUpload || false,
);

export const selectDocFinderFilter = createSelector(
  selectCaseDataRoomSettings,
  dataRoom => dataRoom.docFinderFilter || false,
);

export const selectRules = createSelector(
  (state: any) => state.twilio,
  (twilio: any) =>
    twilio.rules || [
      {
        all: true,
        type: 'include',
      },
    ],
);

export const selectRoomToken = createSelector(
  (state: any, roomMode: any) => state.conference.room === roomMode && state.conference.token,
  token => token,
);

export const selectCurrentCaseId = createSelector(selectCurrentCase, currentCase => currentCase.id);

export const selectCurrentCaseTeam = createSelector(
  selectCurrentCase,
  currentCase => currentCase.team || {},
);

const selectCase = (state: any) => state.case || {};

export const selectCurrentTeam = createSelector(
  selectCase,
  (currentCase: any) => currentCase.team || {},
);

export const selectCurrentCaseTeamId = createSelector(
  selectCurrentCaseTeam,
  team => team.id || null,
);

export const selectCurrentCaseTeams = createSelector(
  selectCase,
  (currentCase: any) => currentCase.teams || [],
);

export const selectGroups = createSelector(
  selectCase,
  (currentCase: any) => currentCase.groups || [],
);

export const selectFolderContainers = createSelector(
  selectCase,
  currentCase => currentCase.containers || [],
);

export const selectFolders = fastDeepEqualSelectorCreator(
  selectCase,
  currentCase => currentCase.folders || {},
);

export const selectAllRoles = function(state: any) {
  return state.common.roles;
};

export const selectDataRoomRoles = function(state: any) {
  return state.common.dataRoomRole;
};

export const selectHearingRoomRoles = function(state: any) {
  return state.common.hearingRoomRole;
};

export const selectAllUsers = function(state: any) {
  return state.common.users;
};

export const selectUserSettings = function(state: any) {
  return state.common.userSettings || {};
};

export const selectUpdateUserSettingsPending = function(state: any) {
  return state.common.updateUserSettingsPending || false;
};

export const selectOverlayedThumbnails = createSelector(
  selectUserSettings,
  userSettings => !!userSettings.overlayedThumbnails,
);

export const selectAutohideThumbnails = createSelector(
  selectUserSettings,
  userSettings => !!userSettings.autohideThumbnails,
);

export const selectAutohideMenus = createSelector(
  selectUserSettings,
  userSettings => !!userSettings.autohideMenus,
);

export const selectSpeechDetection = createSelector(
  selectUserSettings,
  userSettings => userSettings.speechDetection || true,
);

export const selectLastFolder = createSelector(
  selectUserSettings,
  userSettings => userSettings.lastFolder || {},
);

export const selectUserDataRoomSettings = createSelector(
  selectUserSettings,
  userSettings => userSettings.dataRoom || {},
);

export const selectUserSettingPresentMode = createSelector(
  selectUserDataRoomSettings,
  userSettings => userSettings.presentMode || false,
);

export const selectClickEnterToPresent = createSelector(
  selectUserDataRoomSettings,
  userSettings => userSettings.clickEnterToPresent || false,
);

export const selectUserSettingSearchMode = createSelector(
  selectUserDataRoomSettings,
  userSettings => (typeof userSettings.searchMode === 'boolean' ? userSettings.searchMode : true),
);

export const selectUserSettingDocFinder = createSelector(selectUserDataRoomSettings, userSettings =>
  typeof userSettings.docFinder === 'boolean' ? userSettings.docFinder : true,
);

export const selectUserSettingAllDocs = createSelector(selectUserDataRoomSettings, userSettings =>
  typeof userSettings.allDocuments === 'boolean' ? userSettings.allDocuments : true,
);

export const selectUserSettingAllAnnotations = createSelector(
  selectUserDataRoomSettings,
  userSettings =>
    typeof userSettings.allAnnotations === 'boolean' ? userSettings.allAnnotations : true,
);

export const selectUserSettingPageRefs = createSelector(selectUserDataRoomSettings, userSettings =>
  typeof userSettings.pageRefs === 'boolean' ? userSettings.pageRefs : false,
);

export const selectCurrentLastFolder = createSelector(
  selectLastFolder,
  selectCurrentCaseId,
  (lastFolder, currentCase) => lastFolder[currentCase] || '',
);

export const selectRememberPreflightSettings = createSelector(
  selectUserSettings,
  userSettings => userSettings.lastFolder || {},
);

export const selectDocumentsBookmarks = createSelector(
  selectUserSettings,
  userSettings => userSettings.documentsBookmarks || [],
);

export const selectAnnotationsBookmarks = createSelector(
  selectUserSettings,
  userSettings => userSettings.annotationsBookmarks || [],
);

const _selectAllFolders = createSelector(
  selectFolders,
  selectParams,
  (
    {
      bundles = [],
      teamBundles = [],
      privateBundles = [],
      trialbooks = [],
      courtBundles = [],
      groupBundles = [],
      quickLinks = [],
    },
    {
      folder,
      folder1,
      folder2,
      folder3,
      folder4,
      bundle,
      teamBundle,
      privateBundle,
      trialbook,
      courtBundle,
      groupBundle,
      quickLink,
    },
  ) => {
    let parrentFolderCode =
      bundle || teamBundle || privateBundle || trialbook || courtBundle || groupBundle || quickLink;
    let folderCollectionToSearchFolderIn = {
      ...((bundle && { type: 'bundles', folders: bundles }) ||
        (teamBundle && { type: 'bundles', folders: teamBundles }) ||
        (privateBundle && { type: 'bundles', folders: privateBundles }) ||
        (trialbook && { type: 'trialbooks', folders: trialbooks }) ||
        (courtBundle && { type: 'courtBundles', folders: courtBundles }) ||
        (groupBundle && { type: 'groupBundles', folders: groupBundles }) ||
        (quickLink && { type: 'quickLinks', folders: quickLinks }) ||
        []),
    };
    const allFolders = [
      folderCollectionToSearchFolderIn,
      ...[folder, folder1, folder2, folder3, folder4].reduce((folders, col) => {
        if (col) {
          folderCollectionToSearchFolderIn = folderCollectionToSearchFolderIn.folders.find(
            (fol: any) => fol.code === parrentFolderCode,
          ) || { folders: [] };
          parrentFolderCode = col;
          return [...folders, folderCollectionToSearchFolderIn];
        }
        return folders;
      }, []),
    ];
    return allFolders;
  },
);

export const selectFolderCode = createSelector(
  selectParams,
  ({
    folder4,
    folder3,
    folder2,
    folder1,
    folder,
    bundle,
    teamBundle,
    privateBundle,
    trialbook,
    courtBundle,
    groupBundle,
    quickLink,
  }) =>
    folder4 ||
    folder3 ||
    folder2 ||
    folder1 ||
    folder ||
    bundle ||
    teamBundle ||
    privateBundle ||
    trialbook ||
    courtBundle ||
    groupBundle ||
    quickLink,
);

export const selectCurrentFolder = createSelector(
  _selectAllFolders,
  selectFolderCode,
  (allFolders, folderCode) => {
    const lastFolders = allFolders[allFolders.length - 1].folders;
    return ((lastFolders && lastFolders.find((folder: any) => folder.code === folderCode)) ||
      {}) as any;
  },
);

export const selectFolderPath = fastDeepEqualSelectorCreator(
  selectCurrentFolder,
  _selectAllFolders,
  (_: any, category: any) => category,
  (currentFolder: any, allFolders: any, category: any) =>
    [category, ...allFolders.map((x: any) => x.name), currentFolder.name].filter(Boolean),
);

export const selectCurrentFolderId = createSelector(
  selectCurrentFolder,
  currentFolder => currentFolder.id,
);

export const selectCurrentFolderAgreed = createSelector(
  _selectAllFolders,
  selectFolderCode,
  (allFolders, folderCode) => {
    const lastFolders = allFolders[allFolders.length - 1].folders;
    const currentFolder =
      (lastFolders && lastFolders.find((folder: any) => folder.code === folderCode)) || {};
    return currentFolder.agreed || false;
  },
);

export const selectCurrentFile = function(state: any) {
  return selectSpecificParam(state, 'file');
};

export const selectCurrentAnnotationId = createSelector(
  (state: any) => selectSpecificParam(state, 'annotationId'),
  currentAnnotationId => currentAnnotationId,
);

export const selectDownloadedDocuments = createSelector(
  (state: any) => state.viewing.downloadedDocuments,
  downloadedDocuments => downloadedDocuments || [],
);

const _selectFeatures = createSelector(
  selectCurrentCase,
  ({ features }) => ({ ...features } || {}),
);

const _selectPerm = fastDeepEqualSelectorCreator(selectCurrentCase, ({ permissions }: any = {}) => {
  const { documents = {}, containers = {}, folders = {}, hearingRoom = {}, admin = {} } =
    permissions || {};
  return {
    documents,
    containers,
    folders,
    hearingRoom,
    admin,
  };
});

export const selectIsTeamAdmin = createSelector(_selectPerm, ({ admin }) => admin.team || false);

export const selectIsCaseAdmin = createSelector(
  _selectPerm,
  ({ admin }) => admin.caseAdmin || false,
);

export const selectChangeDocId = createSelector(
  _selectPerm,
  ({ admin }) => admin.changeDocId || false,
);

export const selectAssignDialInNumber = createSelector(
  _selectPerm,
  ({ admin }) => admin.assignDialInNumber || false,
);

export const selectDocSearchVal = function({ viewing }: any) {
  return viewing.docSearch;
};

export const selectDocSearchLocation = function({ viewing }: any) {
  return viewing.docSearchLocation;
};

export const selectGoToPageNumber = function({ viewing }: any) {
  return viewing.goToPageNumber;
};

export const selectCurrentPageNumber = function({ viewing }: any) {
  return viewing.currentPageNumber;
};

export const selectDocumentAnnotations = function({ viewing }: any) {
  return viewing.annotations || [];
};

export const selectDocumentAnnotationsFilter = function({ viewing }: any) {
  return viewing.updatedFilters || {};
};

export const selectFilteredDocumentAnnotations = function({ viewing }: any) {
  return viewing.filteredAnnotations || [];
};

export const selectVideoLocation = function({ viewing }: any) {
  return viewing.fileLocation || null;
};

export const selectPublicTheatreData = function({ viewing }: any) {
  return viewing.theatreData;
};

export const selectPrivateTheatreData = function({ viewing }: any) {
  return viewing.privateTheatreData;
};

export const selectTheatreData = createSelector(
  selectPublicTheatreData,
  selectPrivateTheatreData,
  (_: any, mode: any) => mode,
  (publicTheatreData, privateTheatreData, mode) =>
    mode === 'private' ? privateTheatreData : publicTheatreData,
);

export const selectIsAdmin = function({ auth: { systemRole } }: any) {
  return systemRole === 'admin';
};

export const selectIsOrganizationAdmin = function({ auth: { systemRole } }: any) {
  return systemRole === 'organizationAdmin';
};

export const selectIsRegistrar = function({ auth: { systemRole } }: any) {
  return systemRole === 'registrar';
};

export const selectIsLiveStream = function({ auth: { systemRole } }: any) {
  return systemRole === 'liveStream';
};

export const selectPermissions = fastDeepEqualSelectorCreator(
  _selectFeatures,
  _selectPerm,
  selectCurrentFolder,
  (
    { hearingRoomMode, publicConference, privateConference },
    { documents: d, containers: c, folders: f, hearingRoom: hr, admin: a },
    currentFolder,
  ) => {
    const documents = d || { list: true };
    const containers = c || {};
    const folders = f || {};
    const hearingRoom = hr || {};
    const admin = a || {};
    const moderate = admin.moderate || {};
    const isActiveInHearingRoom = (key: any, mode: any) =>
      (Array.isArray(hearingRoomMode) ? hearingRoomMode.includes(mode) : hearingRoomMode) &&
      (Array.isArray(hearingRoom[key]) ? hearingRoom[key].includes(mode) : hearingRoom[key]);

    const processFilePermissions = (permissions = [], { assignedTo = [] } = {}) => {
      for (const folderPermissionType of assignedTo) {
        if (!permissions.includes(folderPermissionType)) return false;
      }
      return true;
    };

    const isActiveInDocumentsFile = (key: any, file: any) =>
      Array.isArray(documents[key])
        ? processFilePermissions(documents[key] as any, file)
        : documents[key];

    const folderToPermissionType = ({ type, shared, teamFolder }: any) => {
      const mapping = {
        trialbook: 'trialbooks',
        'court-bundle': teamFolder ? 'my-court-bundles' : 'other-court-bundles',
        bundle: shared ? 'team-bundles' : 'private-bundles',
        'team-bundle': 'team-bundles',
        'private-bundle': 'private-bundles',
        'group-bundle': 'group-bundles',
      } as any;
      return mapping[type as any];
    };

    const isActiveInDocumentsFolder = (key: any, folder: any) =>
      Array.isArray(documents[key])
        ? documents[key].includes(folderToPermissionType(folder))
        : documents[key];

    const isActiveInFoldersFolder = (key: any, folder: any) =>
      Array.isArray(folders[key])
        ? folders[key].includes(folderToPermissionType(folder))
        : folders[key];

    const isActiveInContainersFolder = (key: any, containerCode: any) =>
      Array.isArray(containers[key]) ? containers[key].includes(containerCode) : containers[key];

    const wrapFolder = (fn: any) => (folder = currentFolder) => fn(folder);
    const allPermissions = {
      moderator: {
        canAdmitFromWaitingRoom: moderate.admitFromWaitingRoom,
        canViewLogs: moderate.canViewLogs,
        canChangeHearingRoomMessage: moderate.changeHearingRoomMessage,
        canEject: moderate.eject,
        canMakeActivePassive: moderate.makeActivePassive,
        canStartStopSession: moderate.startStopSession,
        canStartStopLiveStream: moderate.startStopLiveStream,
      },
      hearingRoom: {
        isShow:
          isActiveInHearingRoom('join', 'private') ||
          isActiveInHearingRoom('join', 'public') ||
          isActiveInHearingRoom('stream', 'private') ||
          isActiveInHearingRoom('stream', 'public') ||
          isActiveInHearingRoom('viewDocuments', 'public'),
        conferenceType: (mode: any) => (mode === 'public' ? publicConference : privateConference),
        isConferenceShow: (mode: any) =>
          mode === 'public' ? !!publicConference : !!privateConference,
        canPresent: (mode: any) => isActiveInHearingRoom('present', mode),
        canJoinConference: (mode: any) => isActiveInHearingRoom('join', mode),
        canViewDocuments: (mode: any) => isActiveInHearingRoom('viewDocuments', mode),
        canShareVideo: (mode: any) => isActiveInHearingRoom('shareVideo', mode),
        canShareAudio: (mode: any) => isActiveInHearingRoom('shareAudio', mode),
        canRecord: (mode: any) => isActiveInHearingRoom('canRecord', mode),
        canStream: (mode: any) => isActiveInHearingRoom('stream', mode),
        canShareScreen: (mode: any) => isActiveInHearingRoom('shareScreen', mode),
        canBypassWaitingRoom: hearingRoom.bypassWaitingRoom,
        canBypassNeedToJoinRoomForDocs: hearingRoom.bypassNeedToJoinRoomForDocs,
        canBeInAudience: hearingRoom.canBeInAudience,
        canBePublicGallery: hearingRoom.canBePublicGallery,
        canBePassive: hearingRoom.canBePassive,
        canBeActive: hearingRoom.canBeActive,
        canBeDisconnected: hearingRoom.canBeDisconnected,
        canBeEjected: hearingRoom.canBeEjected,
        canBeInWaitingRoom: hearingRoom.canBeInWaitingRoom,
        canJumpIn: hearingRoom.canJumpIn,
        canAdditional: hearingRoom.canAdditional,
        canViewRealTime: hearingRoom.canViewRealTime,
      },

      documents: {
        canMerge: (file: any) => isActiveInDocumentsFile('merge', file),
        canReplace: (file: any) => isActiveInDocumentsFile('replace', file),
        canPrint: (file: any) => isActiveInDocumentsFile('print', file),
        canBurnCrossRef: (file: any) => isActiveInDocumentsFile('burnCrossRef', file),
        canSeeFileVersions: (file: any) => isActiveInDocumentsFile('fileVersions', file),
        canAssignDocsToRings: (file: any) => isActiveInDocumentsFile('canAssignDocsToRings', file),
        canUpdateTeamData: (file: any) => isActiveInDocumentsFile('updateTeamData', file),
        canUpdatePublicData: (file: any) => isActiveInDocumentsFile('updatePublicData', file),
        canGeneratePublicHyperlinks: (file: any) =>
          isActiveInDocumentsFile('publicHyperlinks', file),
        canCreateCrossRef: (file: any) => isActiveInDocumentsFile('createCrossRef', file),
        canUpdateAnyData: (file: any) =>
          isActiveInDocumentsFile('updatePublicData', file) ||
          isActiveInDocumentsFile('updateTeamData', file),
        canAgreeTrialbook: Array.isArray(documents['agree'])
          ? documents['agree'].includes('trialbooks')
          : false,
        canUnAgreeTrialbook: Array.isArray(documents['unagree'])
          ? documents['unagree'].includes('trialbooks')
          : false,
        canMergeTrialBook: documents['merge'],
        canCreateAnnotations: documents['createAnnotations'],
        createNotesOnBehalfOfOtherUser: documents['createNotesOnBehalfOfOtherUser'],
        canRemoveFileVersions: documents['canRemoveFileVersions'],
      },

      folders: {
        canUploadDocuments: wrapFolder(
          (folder: any) =>
            documents.upload && (folder.id ? isActiveInDocumentsFolder('assign', folder) : true),
        ),
        canDownloadDocuments: documents.download,
        canBrowseDocuments: documents.list,
        canAddDocuments: wrapFolder(
          (folder: any) => folder.id && isActiveInDocumentsFolder('assign', folder),
        ),
        canRemoveDocuments: wrapFolder((folder: any) =>
          folder.id
            ? isActiveInDocumentsFolder('unassign', folder)
            : isActiveInDocumentsFolder('delete', folder),
        ),
        canSortDocuments: wrapFolder(
          (folder: any) => folder.id && isActiveInDocumentsFolder('sort', folder),
        ),
        canCreateTrialbook: isActiveInFoldersFolder('create', { type: 'trialbook' }),
        canCreateBundle: isActiveInFoldersFolder('create', { type: 'bundle' }),
        canCreateTeamBundle: isActiveInFoldersFolder('create', { type: 'team-bundle' }),
        canCreatePrivateBundle: isActiveInFoldersFolder('create', { type: 'private-bundle' }),
        canShareBundle: wrapFolder(
          (folder: any) =>
            folder.id &&
            (folder.type === 'bundle' || folder.type === 'private-bundle') &&
            !folder.shared &&
            isActiveInFoldersFolder('create', { ...folder, shared: true }),
        ),
        canPublishBundle: wrapFolder(
          (folder: any) =>
            folder.id &&
            isActiveInFoldersFolder('create', {
              ...folder,
              teamFolder: true,
              type: 'court-bundle',
            }),
        ),
        canPublishBundleToTrialbook: wrapFolder(
          (folder: any) =>
            folder.id &&
            isActiveInFoldersFolder('create', {
              ...folder,
              teamFolder: true,
              type: 'trialbook',
            }),
        ),
        canPublishToGroupBundles: wrapFolder(
          (folder: any) =>
            folder.id &&
            isActiveInFoldersFolder('create', {
              ...folder,
              type: 'group-bundle',
            }),
        ),
        canDelete: wrapFolder(
          (folder: any) => folder.id && isActiveInFoldersFolder('delete', folder),
        ),
        canUnpublishBundle: wrapFolder(
          (folder: any) => folder.id && isActiveInFoldersFolder('delete', folder),
        ),
        canUpdate: wrapFolder(
          (folder: any) => folder.id && isActiveInFoldersFolder('update', folder),
        ),
        canUpdateTrialbundles: isActiveInFoldersFolder('update', { type: 'trialbook' }),
        canUpdateTeamBundles: isActiveInFoldersFolder('update', { type: 'team-bundle' }),
        canUpdatePrivateBundles: isActiveInFoldersFolder('update', { type: 'private-bundle' }),
        canReIndex: wrapFolder(
          (folder: any) => folder.id && isActiveInFoldersFolder('reindex', folder),
        ),
        canConvertToSubBundle: wrapFolder(
          (folder: any) => folder.id && isActiveInFoldersFolder('convertToSubBundle', folder),
        ),
        canHealthCheck: folders['healthCheck'],
        canExport: folders['export'],
        canDownload: folders['download'],
        canDeleteWithFiles: folders.deleteWithFiles,
        canClone: folders.clone,
      },
      containers: {
        canUpdate: (containerCode: any) => isActiveInContainersFolder('update', containerCode),
      },
    };
    return allPermissions;
  },
);

export const selectWillUseAV = createSelector(
  selectIsPublicHearingRoom,
  selectPermissions,
  (isHearingRoom, { hearingRoom, moderator }) => {
    return isHearingRoom
      ? hearingRoom.canShareVideo('public') || hearingRoom.canShareAudio('public')
      : hearingRoom.canShareVideo('private') || hearingRoom.canShareAudio('private');
  },
);

export const selectDocumentsOnlyWhenInRoom = createSelector(
  selectTwilioSettings,
  selectPermissions,
  (twilioSettings, { hearingRoom }) => {
    return twilioSettings.documentsOnlyWhenInRoom !== false &&
      !hearingRoom.canBypassNeedToJoinRoomForDocs
      ? true
      : false;
  },
);

export const selectLeftSideDocument = createSelector(selectTwilioSettings, twilioSettings => {
  return !!twilioSettings.leftSideDocument;
});

export const selectScreenShareEnabled = createSelector(selectTwilioSettings, twilioSettings => {
  return !!twilioSettings.screenShareEnabled;
});

export const selectWhepStreamingEnabled = createSelector(selectTwilioSettings, twilioSettings => {
  return !!twilioSettings.useWhepStreaming;
});

export const selectWhepStreamingUrls = createSelector(selectTwilioSettings, twilioSettings => {
  return {
    whipUrl: twilioSettings.whipUrl || null,
    whepUrl: twilioSettings.whepUrl || null,
  };
});

export const selectCanStartLiveStream = createSelector(selectPermissions, permissions => {
  return !!permissions?.moderator?.canStartStopLiveStream;
});

export const selectContainerSizesForThumbnails = createSelector(
  selectTwilioSettings,
  twilioSettings => {
    return getThumbnailSize(twilioSettings.thumbnailSize);
  },
);

export const selectFolderStats = function({ case: { folderStats } }: any) {
  return folderStats || {};
};

export const selectContainerSizesForAdditionalThumbnails = createSelector(
  selectTwilioSettings,
  twilioSettings => {
    return getThumbnailSize(twilioSettings.additionalThumbnailSize);
  },
);

export const selectPreserveAspectRatio = createSelector(
  selectTwilioSettings,
  twilioSettings => twilioSettings.preserveAspectRatio || false,
);

export const selectLeaveRoomOnHearingRoomSessionStop = createSelector(
  selectTwilioSettings,
  selectIsPublicHearingRoom,
  ({ leaveRoomOnHearingRoomSessionStop = true }, isHearingRoom) =>
    isHearingRoom && leaveRoomOnHearingRoomSessionStop,
);

export const selectAllowBotUsers = createSelector(
  selectTwilioSettings,
  twilioSettings => twilioSettings.allowBotUsers || false,
);

export const selectEnableRoomToBeRecorded = createSelector(
  selectTwilioSettings,
  twilioSettings => twilioSettings.enableRoomToBeRecorded || false,
);

export const selectEvidenceViewEnabled = createSelector(selectTwilioSettings, twilioSettings => {
  return !!twilioSettings.evidenceViewEnabled;
});

export const selectMarkBasedScrolling = createSelector(selectTwilioSettings, twilioSettings => {
  return !!twilioSettings.markBasedScrolling;
});

export const selectAccessToken = function({ auth }: any) {
  return auth.user && (auth.user.signInUserSession.accessToken.jwtToken as string);
};

export const selectIsAuthenticated = function(state: any) {
  return !!state.auth.user;
};

export const selectUploadFiles = function({ case: { uploadFiles } }: any) {
  return uploadFiles;
};

export const selectHubConnectionId = function({ auth: { hubConnectionId } }: any) {
  return hubConnectionId;
};

export const selectHubConnectionTransportType = function({
  auth: { hubConnectionTransportType },
}: any) {
  return hubConnectionTransportType;
};

export const selectUserName = function({ auth: { user } }: any) {
  return user && user.attributes.name;
};

// the rule is, sub to authenticate after that user.id for everything
export const selectUserId = function({ auth: { user, id } }: any) {
  return id || (user && user.attributes.sub);
};

export const selectActiveParticipants = function(state: any) {
  return state.conference.activeParticipants;
};

export const selectWaitingRoomParticipants = function(state: any) {
  return state.conference.waitingRoomParticipants;
};

export const selectSessionStarted = function(state: any) {
  return state.conference.hearingRoomSessionStarted;
};

export const selectIsActiveParticipant = createSelector(
  selectUserId,
  selectActiveParticipants,
  (userId, activeParticipants) =>
    !!(
      activeParticipants && activeParticipants.find(({ user }: any) => user && user.id === userId)
    ),
);

export const selectInWaitingRoomCurrentUser = createSelector(
  selectUserId,
  selectWaitingRoomParticipants,
  (userId, waitingRoomParticipants) =>
    waitingRoomParticipants &&
    waitingRoomParticipants.find(({ user }: any) => user && user.id === userId),
);

export const selectWaitingRoomParticipantsCount = createSelector(
  selectWaitingRoomParticipants,
  waitingRoomParticipants => waitingRoomParticipants.length || 0,
);

export const selectUserEmail = createSelector(
  ({ auth: { user } }: any) => user && user.attributes.email,
  email => email,
);

export const selectCurrentUserConfidentialityRing = createSelector(
  selectUserEmail,
  selectCurrentCaseTeam,
  (email: any, team: any) =>
    team &&
    team.users &&
    ((team.users.find((user: any) => user.mail.toLowerCase() === email.toLowerCase()) || {})
      .confidentialityRing ||
      null),
);

export const selectUserAiTools = createSelector(
  selectUserEmail,
  selectCurrentCaseTeam,
  (email: any, team: any) =>
    team &&
    team.users &&
    ((team.users.find((user: any) => user.mail.toLowerCase() === email.toLowerCase()) || {})
      .aiTools ||
      null),
);

export const selectAiToolsFilters = function({ case: { aiToolsFilters } }: any) {
  return aiToolsFilters || {};
};

export const selectAuthorsList = ({ case: { authorsList } }: any) => authorsList || null;

export const selectRecipientsList = ({ case: { recipientsList } }: any) => recipientsList || null;

export const selectUserList = ({ case: { team } }: any) => team?.users || null;

export const selectDocuments = ({ case: { documents } }: any) => documents || [];

export const selectedDocument = createSelector(
  selectDocuments,
  selectCurrentFile,
  (documents, file) => documents.find((doc: any) => doc.id === file),
);

export const selectAcceptTsAndCsSuccess = ({ case: { acceptTsAndCsSuccess } }: any) =>
  acceptTsAndCsSuccess || false;

export const selectDocumentIds = createSelector(
  selectDocuments,
  documents => documents.map(({ id }: any) => id) || [],
);

export const selectCurrentFileMetData = createSelector(
  selectDocuments,
  selectCurrentFile,
  (documents, currentFile) => documents.find((file: any) => file.id === currentFile) || {},
);

export const selectCurrentSelectedFileMetaData = createSelector(
  function({ case: { currentSelectedFile } }: any) {
    return currentSelectedFile;
  },
  function(currentSelectedFile: any) {
    return currentSelectedFile || {};
  },
);

export const selectIsGlobalPageLookup = function({ case: { globalPageLookup } }: any) {
  return globalPageLookup || false;
};

export const selectFetchFileDetailsPending = ({ case: { fetchFileDetailsPending } }: any) =>
  fetchFileDetailsPending || false;

const selectMaxIdLength = createSelector(selectDocumentIds, documentIds =>
  Math.max(...documentIds.map((id: any) => id.length), 0),
);

export const selectMinIdWidth = createSelector(selectMaxIdLength, maxIdLength => {
  const size = calculateSize('0'.repeat(maxIdLength), {
    font: 'sans-serif',
    fontSize: '12px',
  });

  return size.width + 16; // za padding 8px sa svake strane
});

export const selectFetchAnnotationsPending = ({ case: { fetchAnnotationsPending } }: any) =>
  fetchAnnotationsPending || false;

export const selectAnnotations = ({ case: { annotations } }: any) => annotations || [];

export const selectedAnnotation = createSelector(
  selectAnnotations,
  selectCurrentFile,
  (annotations, file) => annotations.find((tagLoc: any) => tagLoc.fileId === file),
);

export const selectFetchPageRefsPending = ({ case: { fetchPageRefsPending } }: any) =>
  fetchPageRefsPending || false;

export const selectPageRefs = ({ case: { pageRefs } }: any) => pageRefs || [];

export const selectAnnotationsIds = createSelector(
  selectAnnotations,
  annotations => annotations.map(({ id }: any) => id) || [],
);

export const selectTags = ({ case: { tags } }: any) => tags || null;

export const selectFetchTagsPending = ({ case: { fetchTagsPending } }: any) => fetchTagsPending;

export const selectFilterOptions = fastDeepEqualSelectorCreator(
  ({ case: { filterOptions } }: any) => filterOptions,
  filterOptions => filterOptions || [],
);

export const selectFetchViewingSessionIdPending = ({
  viewing: { fetchViewingSessionIdPending },
}: any) => fetchViewingSessionIdPending;

export const selectFetchDocumentAnnotationsPending = ({
  viewing: { fetchAnnotationsPending },
}: any) => fetchAnnotationsPending;

export const selectDocumentCount = createSelector(selectDocuments, documents => documents.length);

export const selectTotalPageCount = createSelector(selectDocuments, documents => {
  return documents.length > 0
    ? documents.reduce((accumulator: any, { pageCount = 0 }: any) => {
        return accumulator + parseInt(pageCount);
      }, 0)
    : -1;
});

export const selectTotalDocumentSize = createSelector(selectDocuments, documents => {
  return documents.length > 0
    ? documents.reduce((accumulator: any, { size = 0 }) => {
        return accumulator + size;
      }, 0)
    : -1;
});

export const selectTotalDocumentsCount = createSelector(
  ({ case: { totalDocuments } }: any) => totalDocuments,
  totalDocumentsCount => totalDocumentsCount || 0,
);

export const selectFetchDocumentsPending = function(state: any) {
  return state.case.fetchDocumentsPending;
};

export const selectIsDeepSearchPerformed = function({ case: { isDeepSearchPerformed } }: any) {
  return isDeepSearchPerformed;
};

export const withCurrentFolder = (fn: any) => {
  return function*(action: any): Generator<any, any, any> {
    const currentFolder = yield select(selectCurrentFolder);
    yield fn({ ...action, payload: { ...action.payload, currentFolder } });
  };
};

export const withCurrentUserId = (fn: any) => {
  return function*(action: any): Generator<any, any, any> {
    const currentUserId = yield select(selectUserId);

    yield fn({ ...action, payload: { ...action.payload, currentUserId } });
  };
};

export const withCurrentCaseId = (fn: any) => {
  return function*(action: any): Generator<any, any, any> {
    const params = yield select(selectParams);
    let currentCaseId = params.templateId ? params.templateId : yield select(selectCurrentCaseId);

    if (typeof currentCaseId === 'undefined') {
      const cases = (yield take(['HOME_FETCH_CASES_SUCCESS', 'HOME_FETCH_CASES_FAILURE'])).data;

      if (cases.error) return;

      currentCaseId = yield select(selectCurrentCaseId);

      if (typeof currentCaseId === 'undefined') return;
    }

    yield fn({ ...action, payload: { ...action.payload, caseId: currentCaseId } });
  };
};

export const withHubConnectionId = (fn: any) => {
  return function*(action: any): Generator<any, any, any> {
    let connectionId = yield select(selectHubConnectionId);

    if (typeof connectionId === 'undefined') {
      connectionId = (yield take('AUTH_SET_HUB_CONNECTION_ID')).connectionId;

      if (typeof connectionId === 'undefined') return;
    }

    yield fn({ ...action, payload: { ...action.payload, connectionId } });
  };
};
