import React from 'react';
import {
  IconButton,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { RecursiveRenderer } from 'features/case/SearchCenter/DocSummary';

type SideSummaryDisplayProps = {
  open: boolean;
  onClose: () => void;
  summaries: any[];
};

const SideSummaryDisplay = ({ open, onClose, summaries }: SideSummaryDisplayProps) => {
  if (!open) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: '400px',
        height: '100%',
        backgroundColor: 'background.paper',
        boxShadow: 3,
        zIndex: 1300,
        display: 'flex',
        flexDirection: 'column',
        transition: 'width 0.1s ease-out',
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 2 }}>
        <Typography variant="h6">Summaries</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {summaries?.length > 0 ? (
        <PerfectScrollbar style={{ flexGrow: 1, padding: '0 16px 16px 16px', minHeight: '100px' }}>
          {summaries?.map((item, index) => (
            <Accordion key={index} defaultExpanded={false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {item.barrister?.name || item.witness?.name || `Summary ${index + 1}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RecursiveRenderer data={item} />
              </AccordionDetails>
            </Accordion>
          ))}
        </PerfectScrollbar>
      ) : (
        <Typography style={{ padding: 10 }}>No summaries available.</Typography>
      )}
    </Box>
  );
};

export default SideSummaryDisplay;
