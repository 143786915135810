import { call, put, take, takeLatest } from 'redux-saga/effects';

import {
  CASE_EXPORT_TIMELINE_BEGIN,
  CASE_EXPORT_TIMELINE_SUCCESS,
  CASE_EXPORT_TIMELINE_FAILURE,
  CASE_EXPORT_TIMELINE_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const exportTimeline = createPromiseAction(CASE_EXPORT_TIMELINE_BEGIN);

export function dismissExportTimelineFeedback() {
  return {
    type: CASE_EXPORT_TIMELINE_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doExportTimeline(action) {
  const {
    payload: { caseId, timelineId },
  } = action;

  const channel = yield call(
    api.createDownloadFileChannel,
    `/cases/${caseId}/timelines/${timelineId}/export`,
  );

  while (true) {
    const { error, success, response: document } = yield take(channel);
    if (error) {
      yield put({
        type: CASE_EXPORT_TIMELINE_FAILURE,
        feedback: {
          message: 'feedback.exportTimelineFailure',
          error: error,
          retryAction: action,
        },
      });
      return yield call(rejectPromiseAction, action, error);
    }

    if (success) {
      yield put({
        type: CASE_EXPORT_TIMELINE_SUCCESS,
        data: document,
      });
      yield call(resolvePromiseAction, action, document);
      return;
    }
  }
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchExportTimeline() {
  yield takeLatest(exportTimeline, withCurrentCaseId(doExportTimeline));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_EXPORT_TIMELINE_BEGIN + '.TRIGGER':
      return {
        ...state,
        exportTimelinePending: true,
        exportTimelineFeedback: null,
      };

    case CASE_EXPORT_TIMELINE_SUCCESS:
      return {
        ...state,
        exportTimelinePending: false,
        exportTimelineFeedback: action.feedback,
      };

    case CASE_EXPORT_TIMELINE_FAILURE:
      return {
        ...state,
        exportTimelinePending: false,
        exportTimelineFeedback: action.feedback,
      };

    case CASE_EXPORT_TIMELINE_DISMISS_FEEDBACK:
      return {
        ...state,
        exportTimelineFeedback: null,
      };

    default:
      return state;
  }
}
