import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { AUTH_UPDATE_USER_SESSION } from './constants';

export function updateUserSession(data) {
  return {
    type: AUTH_UPDATE_USER_SESSION,
    data,
  };
}

export function useUpdateUserSession() {
  const dispatch = useDispatch();

  const { userSession } = useSelector(
    state => ({
      userSession: state.auth.user && state.auth.user.signInUserSession,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...params) => dispatch(updateUserSession(...params)), [
    dispatch,
  ]);
  
  return { userSession, updateUserSession: boundAction };
}

export function reducer(state, action) {
  switch (action.type) {
    case AUTH_UPDATE_USER_SESSION:
      return {
        ...state,
        user: { ...state.user, signInUserSession: action.data },
      };

    default:
      return state;
  }
}
