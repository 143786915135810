import React from 'react';
import { useDrop } from 'react-dnd';
import classnames from 'classnames';

const DropZone = ({ allowedItems, onDrop, canDrop: canDropExt, children, className }) => {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: allowedItems,
    drop: (item, monitor) => {
      onDrop && onDrop(item);
    },
    canDrop: (item, monitor) => {
      return typeof canDropExt === 'undefined' ? true : canDropExt(item);
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isDragActive = isOver && canDrop;

  return (
    <div ref={drop} className={className}>
      {React.cloneElement(children, {
        className: classnames(
          {
            drag: canDrop,
            'drag-over': isDragActive,
          },
          children.props.className,
        ),
      })}
    </div>
  );
};

export default DropZone;
