import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_ASK_AI_BEGIN,
  CASE_ASK_AI_SUCCESS,
  CASE_ASK_AI_FAILURE,
  CASE_ASK_AI_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';

export const askAI = createPromiseAction(CASE_ASK_AI_BEGIN);

export function dismissAskAIFeedback() {
  return {
    type: CASE_ASK_AI_DISMISS_FEEDBACK,
  };
}

export function* doAskAI(action) {
  const {
    payload: { caseId, command, fileId, summaryType },
  } = action;

  const res = yield call(api.post, `/cases/${caseId}/ai`, { command, fileId, summaryType });

  if (res && res.error) {
    yield put({
      type: CASE_ASK_AI_FAILURE,
      feedback: {
        message: 'feedback.askAIFailure',
        error: res.error,
      },
      /*retryAction: action,*/
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_ASK_AI_SUCCESS,
    data: res,
  });

  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchAskAI() {
  yield takeEvery(askAI, withCurrentCaseId(doAskAI));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_ASK_AI_BEGIN + '.TRIGGER':
      return {
        ...state,
        askAIPending: true,
        askAIFeedback: null,
      };

    case CASE_ASK_AI_SUCCESS:
      return {
        ...state,
        askAIPending: false,
        askAITempFeed: null,
      };

    case CASE_ASK_AI_FAILURE:
      return {
        ...state,
        askAIPending: false,
        askAIFeedback: action.feedback,
      };

    case CASE_ASK_AI_DISMISS_FEEDBACK:
      return {
        ...state,
        askAIFeedback: null,
      };

    default:
      return state;
  }
}
