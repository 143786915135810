import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SidebarItem from './SidebarItem';
import * as actions from './redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectFolderContainers } from 'common/selectors';

const DraggableItems = React.memo(({ bundles, buttonActions, to, code, isDragDisabled }) => {
  return bundles.map((prop, key) => (
    <Draggable
      key={prop.id}
      draggableId={prop.id}
      index={key}
      isDragDisabled={isDragDisabled || false}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...(snapshot.isDragging && {
              backgroundColor: 'rgba(255, 255, 255, 0.23)',
            }),
            ...provided.draggableProps.style,
          }}
        >
          <SidebarItem
            folder={prop}
            key={key}
            to={to}
            buttonActions={buttonActions}
            code={code}
            isLocked={isDragDisabled || false}
            canUpdateContainer={true}
          />
        </div>
      )}
    </Draggable>
  ));
});

const DraggableBundles = ({ bundles, buttonActions, to, code, isLocked }) => {
  const dispatch = useDispatch();
  const containers = useSelector(selectFolderContainers);
  const currentContainer = containers.find(item => item.code === code);
  const [bundlesVal, setBundlesVal] = useState([]);

  const reorder = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const handleDragEnd = useCallback(
    result => {
      if (!result.destination) return;
      if (result.source.index === result.destination.index) return;

      const updatedBundles = reorder(bundlesVal, result.source.index, result.destination.index);
      setBundlesVal(updatedBundles);
      const currentDraggedFolder = bundles.find(folder => folder.id === result.draggableId);
      dispatch(
        actions.updateFolderSeq({
          folder: currentDraggedFolder,
          containerId: currentContainer.id,
          containerEtag: currentContainer.etag,
          index: result.destination.index,
        }),
      ).catch(err => {
        setBundlesVal(bundles);
        console.error(err);
      });
    },
    [dispatch, currentContainer, bundles, bundlesVal, reorder],
  );

  useEffect(() => {
    if (bundlesVal !== bundles) setBundlesVal(bundles);
  }, [bundles, bundlesVal]);

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={`${code}-list`}>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <DraggableItems
                bundles={bundlesVal}
                buttonActions={buttonActions}
                to={to}
                code={code}
                isDragDisabled={isLocked}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableBundles;
