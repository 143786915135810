export const addAlpha = (color, opacity = 255) =>
  color +
  (
    '00' +
    Math.min(Math.max(opacity, 0), 255)
      .toString(16)
      .toUpperCase()
  ).substr(-2);

export const removeAlpha = color => (color.length > 7 ? color.substr(0, 7) : color);

export const hex2rgba = hexColor => {
  if (hexColor.length === 7) hexColor = hexColor + 'FF';
  var num = parseInt(hexColor.slice(1), 16); // Convert to a number
  return [(num >> 24) & 255, (num >> 16) & 255, (num >> 8) & 255, num & 255];
};

export const getAlpha = color => hex2rgba(color)[3];

export const randomColor = () => {
  // FOR NOW IT'S DISABLED
  // var letters = '0123456789ABCDEF';
  // var color = '#';
  // for (var i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * 16)];
  // }
  // return color;
};
