import { CASE_SET_CURRENT_FILE, CASE_SET_CURRENT_FILE_FAILURE } from './constants';

export function setCurrentSelectedFile(file) {
  return {
    type: CASE_SET_CURRENT_FILE,
    file,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_SET_CURRENT_FILE:
      return {
        ...state,
        currentSelectedFile: action.file,
      };
    case CASE_SET_CURRENT_FILE_FAILURE:
      return {
        ...state,
        currentSelectedFile: null,
        currentSelectedFileFeedback: action.feedback,
      };

    default:
      return state;
  }
}
