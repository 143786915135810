import { call, put, takeLatest } from 'redux-saga/effects';
import {
  VIEWING_FETCH_DOCS_PRESENTED_BEGIN,
  VIEWING_FETCH_DOCS_PRESENTED_SUCCESS,
  VIEWING_FETCH_DOCS_PRESENTED_FAILURE,
  VIEWING_FETCH_DOCS_PRESENTED_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const fetchDocsPresented = createPromiseAction(VIEWING_FETCH_DOCS_PRESENTED_BEGIN);

export function dismissFetchDocsPresentedFeedback() {
  return {
    type: VIEWING_FETCH_DOCS_PRESENTED_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_DOCS_PRESENTED_BEGIN actions
export function* doFetchDocsPresented(action) {
  const {
    payload: { caseId, fileId },
  } = action;
  const res = yield call(api.get, `/cases/${caseId}/recordings/${fileId}/docs-presented`);

  if (res && res.error) {
    yield put({
      type: VIEWING_FETCH_DOCS_PRESENTED_FAILURE,
      feedback: {
        message: 'feedback.fetchDocsPresentedFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }
  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: VIEWING_FETCH_DOCS_PRESENTED_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchDocsPresented() {
  yield takeLatest(fetchDocsPresented, withCurrentCaseId(doFetchDocsPresented));
}

export function useFetchDocsPresented() {
  const dispatch = useDispatch();

  const { fetchDocsPresentedPending, fetchDocsPresentedFeedback } = useSelector(
    state => ({
      fetchDocsPresentedPending: state.viewing.fetchDocsPresentedPending,
      fetchDocsPresentedFeedback: state.viewing.fetchDocsPresentedFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchDocsPresented(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchDocsPresentedFeedback());
  }, [dispatch]);

  return {
    fetchDocsPresented: boundAction,
    fetchDocsPresentedPending,
    fetchDocsPresentedFeedback,
    dismissFetchDocsPresentedFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_FETCH_DOCS_PRESENTED_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchDocsPresentedPending: true,
        fetchDocsPresentedFeedback: null,
      };

    case VIEWING_FETCH_DOCS_PRESENTED_SUCCESS:
      return {
        ...state,
        fetchDocsPresentedPending: false,
        fetchDocsPresentedFeedback: action.feedback,
      };

    case VIEWING_FETCH_DOCS_PRESENTED_FAILURE:
      return {
        ...state,
        fetchDocsPresentedPending: false,
        fetchDocsPresentedFeedback: action.feedback,
      };

    default:
      return state;
  }
}
