import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CASE_FETCH_TAGS_BEGIN,
  CASE_FETCH_TAGS_SUCCESS,
  CASE_FETCH_TAGS_FAILURE,
  CASE_FETCH_TAGS_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

export const fetchTags = createPromiseAction(CASE_FETCH_TAGS_BEGIN);

export function dismissFetchTagsFeedback() {
  return {
    type: CASE_FETCH_TAGS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_TAGS_BEGIN actions
export function* doFetchTags(action) {
  const {
    payload: { caseId },
  } = action;

  const res = yield call(api.get, `/cases/${caseId}/tags`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_TAGS_FAILURE,
      feedback: { message: 'feedback.fetchTagsFailure', error: res.error, retryAction: action },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_TAGS_SUCCESS,
    data: res,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchTags() {
  yield takeLatest(fetchTags, withCurrentCaseId(doFetchTags));
}

export function useFetchTags() {
  const dispatch = useDispatch();

  const { tags, fetchTagsPending, fetchTagsFeedback } = useSelector(
    state => ({
      tags: state.case.tags,
      fetchTagsPending: state.case.fetchTagsPending,
      fetchTagsFeedback: state.case.fetchTagsFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(fetchTags(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissFetchTagsFeedback());
  }, [dispatch]);

  return {
    tags,
    fetchTags: boundAction,
    fetchTagsPending,
    fetchTagsFeedback,
    dismissFetchTagsFeedback: boundDismissFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_TAGS_BEGIN + '.TRIGGER':
      return {
        ...state,
        fetchTagsPending: true,
        fetchTagsFeedback: null,
      };

    case CASE_FETCH_TAGS_SUCCESS:
      return {
        ...state,
        fetchTagsPending: false,
        fetchTagsFeedback: action.feedback,
        tags: action.data,
      };

    case CASE_FETCH_TAGS_FAILURE:
      return {
        ...state,
        fetchTagsPending: false,
        fetchTagsFeedback: action.feedback,
      };

    case CASE_FETCH_TAGS_DISMISS_FEEDBACK:
      return {
        ...state,
        fetchTagsFeedback: null,
      };

    default:
      return state;
  }
}
