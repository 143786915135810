export const HOME_FETCH_CASES_BEGIN = 'HOME_FETCH_CASES_BEGIN';
export const HOME_FETCH_CASES_SUCCESS = 'HOME_FETCH_CASES_SUCCESS';
export const HOME_FETCH_CASES_FAILURE = 'HOME_FETCH_CASES_FAILURE';
export const HOME_FETCH_CASES_DISMISS_FEEDBACK = 'HOME_FETCH_CASES_DISMISS_FEEDBACK';
export const HOME_FETCH_CASES_SUCCESS_DUMMY = 'HOME_FETCH_CASES_SUCCESS_DUMMY';
export const HOME_CREATE_CASE_BEGIN = 'HOME_CREATE_CASE_BEGIN';
export const HOME_CREATE_CASE_SUCCESS = 'HOME_CREATE_CASE_SUCCESS';
export const HOME_CREATE_CASE_FAILURE = 'HOME_CREATE_CASE_FAILURE';
export const HOME_CREATE_CASE_DISMISS_FEEDBACK = 'HOME_CREATE_CASE_DISMISS_FEEDBACK';
export const HOME_UPDATE_CASE_DISMISS_FEEDBACK = 'HOME_UPDATE_CASE_DISMISS_FEEDBACK';
export const HOME_UPDATE_CASE_NAME = 'HOME_UPDATE_CASE_NAME';
