import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { HOME_UPDATE_CASE_NAME } from './constants';
import { replaceItemImmutable } from 'utils/arrays';

export function updateCaseName({ id, name }) {
  return {
    type: HOME_UPDATE_CASE_NAME,
    payload: { id, name },
  };
}

export function useUpdateCaseName() {
  const dispatch = useDispatch();
  const boundAction = useCallback((...params) => dispatch(updateCaseName(...params)), [dispatch]);
  return { updateCaseName: boundAction };
}

export function reducer(state, action) {
  const getIndex = caseId => state.cases.findIndex(({ id }) => id === caseId);
  switch (action.type) {
    case HOME_UPDATE_CASE_NAME:
      return {
        ...state,
        cases: replaceItemImmutable(
          state.cases,
          {
            id: action.payload.id,
            name: action.payload.name,
            ...state.cases[getIndex(action.payload.id)],
          },
          getIndex(action.payload.id),
        ),
      };

    default:
      return state;
  }
}
