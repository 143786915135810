import {
  CASE_FETCH_ADMINS_BEGIN,
  CASE_FETCH_ADMINS_SUCCESS,
  CASE_FETCH_ADMINS_FAILURE,
  CASE_FETCH_ADMINS_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchCaseAdmins = createPromiseAction(CASE_FETCH_ADMINS_BEGIN, payload => ({
  inputData: payload,
}));

export function dismissFetchCaseAdminsFeedback() {
  return {
    type: CASE_FETCH_ADMINS_DISMISS_FEEDBACK,
  };
}

export function* doFetchCaseAdmins(action) {
  const { caseId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/roles/caseAdmin`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_ADMINS_FAILURE,
      feedback: {
        message: 'feedback.fetchCaseAdminsFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_ADMINS_SUCCESS,
    data: { caseAdmins: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchCaseAdmins() {
  yield takeEvery(fetchCaseAdmins, withCurrentCaseId(doFetchCaseAdmins));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_ADMINS_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchAdminsPending: true,
        fetchAdminsFeedback: null,
      };

    case CASE_FETCH_ADMINS_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchAdminsPending: false,
        fetchAdminsFeedback: action.feedback,
        caseAdmins: action.data.caseAdmins,
      };

    case CASE_FETCH_ADMINS_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchAdminsPending: false,
        fetchAdminsFeedback: action.feedback,
      };

    case CASE_FETCH_ADMINS_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchAdminsFeedback: null,
      };

    default:
      return state;
  }
}
