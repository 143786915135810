import React from 'react';
import { getHighlightedText } from 'utils/highlightText';
import SortHeader from '../TableCase/SortHeader';
import moment from 'moment';

export default (filterVal: any) =>
  [
    {
      id: 'globalPage',
      Header: () => <SortHeader text={'case.globalPage'} />,
      Cell: (pageRef: any) => {
        return (
          <div className="customGrid">
            <span
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(pageRef.globalPage || '', filterVal),
              }}
            ></span>
          </div>
        );
      },
      minWidth: '100px',
      width: `15rem`,
    },
    {
      id: 'tab',
      Header: () => <SortHeader text={'case.tab'} />,
      Cell: (pageRef: any) => {
        return (
          <div className="customGrid">
            <span
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(pageRef.tab || '', filterVal),
              }}
            ></span>
          </div>
        );
      },
      minWidth: '60px',
      width: `10rem`,
    },
    {
      Header: () => <SortHeader text="case.documentId" />,
      id: 'fileId',
      Cell: (pageRef: any) => (
        <div className="customGrid">
          <span
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(pageRef.fileId, filterVal),
            }}
          ></span>
        </div>
      ),
      minWidth: '100px',
      width: `15rem`,
    },
    {
      id: 'page',
      Header: () => <SortHeader text="case.page" />,
      Cell: (pageRef: any) => (
        <div className="customGrid">
          <span
            dangerouslySetInnerHTML={{
              __html: getHighlightedText(pageRef.page, filterVal),
            }}
          ></span>
        </div>
      ),
      minWidth: '60px',
      width: '10rem',
    },
    {
      id: 'currentFileId',
      Header: () => <SortHeader text={'case.currentFileId'} />,
      Cell: (pageRef: any) => {
        return (
          <div className="customGrid">
            {pageRef.fileId && pageRef.fileId !== pageRef.currentFileId && (
              <span
                dangerouslySetInnerHTML={{
                  __html: getHighlightedText(pageRef.currentFileId || '', filterVal),
                }}
              ></span>
            )}
          </div>
        );
      },
      minWidth: '100px',
      width: `15rem`,
    },
    {
      id: 'currentPage',
      Header: () => <SortHeader text={'case.currentPage'} />,
      Cell: (pageRef: any) => {
        return (
          <div className="customGrid">
            {pageRef.currentPage && pageRef.currentPage !== pageRef.page && (
              <span
                dangerouslySetInnerHTML={{
                  __html: getHighlightedText(pageRef.currentPage || '', filterVal),
                }}
              ></span>
            )}
          </div>
        );
      },
      minWidth: '100px',
      width: `15rem`,
    },
    {
      id: 'createdAt',
      Header: () => <SortHeader text={'case.created'} />,
      Cell: (pageRef: any) => {
        return (
          <div className="customGrid">
            <span
              dangerouslySetInnerHTML={{
                __html: getHighlightedText(
                  moment(pageRef.createdBy.date).format('DD/MM/YYYY HH:mm:ss') || '',
                  filterVal,
                ),
              }}
            ></span>
          </div>
        );
      },
      minWidth: '150px',
      width: `calc(100% - 80rem)`,
    },
  ].filter(Boolean);
