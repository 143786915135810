import _ from 'lodash';

export const getHighlightedText = (text, filterValue) => {
  if (filterValue && text.toLowerCase().includes(filterValue.toLowerCase())) {
    const searchRegex = new RegExp(`(${_.escapeRegExp(filterValue)})`, 'gi');
    const textParts = text.split(searchRegex);
    return textParts
      .filter(part => part)
      .map((part, i) =>
        part.toLowerCase() === filterValue.toLowerCase()
          ? `<strong key=${i} class=highlight-filter>${part}</strong>`
          : part,
      )
      .join('');
  } else return text;
};
