import {
  CASE_DELETE_DIALIN_NUMBER_BEGIN,
  CASE_DELETE_DIALIN_NUMBER_SUCCESS,
  CASE_DELETE_DIALIN_NUMBER_FAILURE,
  CASE_DELETE_DIALIN_NUMBER_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const deleteDialInNumber = createPromiseAction(CASE_DELETE_DIALIN_NUMBER_BEGIN);

export function dismissDeleteDialInNumberFeedback() {
  return {
    type: CASE_DELETE_DIALIN_NUMBER_DISMISS_FEEDBACK,
  };
}

export function* doDeleteDialInNumber(action) {
  const { caseId, number } = action.payload;

  let res = yield call(api.del, `/cases/${caseId}/dial-in-numbers/${number}`);

  if (res && res.error) {
    yield put({
      type: CASE_DELETE_DIALIN_NUMBER_FAILURE,
      feedback: {
        message: 'feedback.deleteDialInNumberFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_DELETE_DIALIN_NUMBER_SUCCESS,
    data: { dialInNumber: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchDeleteDialInNumber() {
  yield takeLatest(deleteDialInNumber, withCurrentCaseId(doDeleteDialInNumber));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_DELETE_DIALIN_NUMBER_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        deleteDialInNumberPending: true,
        deleteDialInNumberFeedback: null,
      };

    case CASE_DELETE_DIALIN_NUMBER_SUCCESS:
      // The request is success
      return {
        ...state,
        deleteDialInNumberPending: false,
        deleteDialInNumberFeedback: action.feedback,
      };

    case CASE_DELETE_DIALIN_NUMBER_FAILURE:
      // The request is failed
      return {
        ...state,
        deleteDialInNumberPending: false,
        deleteDialInNumberFeedback: action.feedback,
      };

    case CASE_DELETE_DIALIN_NUMBER_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        deleteDialInNumberFeedback: null,
      };

    default:
      return state;
  }
}
