import {
  CASE_ADD_ROLES_USER_BEGIN,
  CASE_ADD_ROLES_USER_SUCCESS,
  CASE_ADD_ROLES_USER_FAILURE,
  CASE_ADD_ROLES_USER_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const addCaseRolesUser = createPromiseAction(CASE_ADD_ROLES_USER_BEGIN);

export function dismissAddCaseRolesUserFeedback() {
  return {
    type: CASE_ADD_ROLES_USER_DISMISS_FEEDBACK,
  };
}

export function* doAddCaseRolesUser(action) {
  const { caseId, roleType, user } = action.payload;

  let res = yield call(api.post, `/cases/${caseId}/roles/${roleType}/users`, user);

  if (res && res.error) {
    yield put({
      type: CASE_ADD_ROLES_USER_FAILURE,
      feedback: {
        message: 'feedback.addCaseRolesUserFailure',
        params: { userEmail: user.email },
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_ADD_ROLES_USER_SUCCESS,
    data: { [roleType]: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchAddCaseRolesUser() {
  yield takeEvery(addCaseRolesUser, withCurrentCaseId(doAddCaseRolesUser));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_ADD_ROLES_USER_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        addCaseRolesUserPending: true,
        addCaseRolesUserFeedback: null,
      };

    case CASE_ADD_ROLES_USER_SUCCESS:
      // The request is success
      return {
        ...state,
        addCaseRolesUserPending: false,
        addCaseRolesUserFeedback: action.feedback,
      };

    case CASE_ADD_ROLES_USER_FAILURE:
      // The request is failed
      return {
        ...state,
        addCaseRolesUserPending: false,
        addCaseRolesUserFeedback: action.feedback,
      };

    case CASE_ADD_ROLES_USER_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        addCaseRolesUserFeedback: null,
      };

    default:
      return state;
  }
}
