import initialState from './initialState';
import { reducer as fetchCasesReducer } from './fetchCases';
import { reducer as createCaseReducer } from './createCase';
import { reducer as updateCaseNameReducer } from './updateCaseName';

const reducers = [fetchCasesReducer, createCaseReducer, updateCaseNameReducer];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
