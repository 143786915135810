import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_DELETE_TIMELINES_BEGIN,
  CASE_DELETE_TIMELINES_SUCCESS,
  CASE_DELETE_TIMELINES_FAILURE,
  CASE_DELETE_TIMELINES_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const deleteTimelines = createPromiseAction(CASE_DELETE_TIMELINES_BEGIN);

export function dismissDeleteTimelinesFeedback() {
  return {
    type: CASE_DELETE_TIMELINES_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_REMOVE_BUNDLE_BEGIN actions
export function* doDeleteTimelines(action) {
  const { timelinesId, caseId } = action.payload;
  const res = yield call(api.del, `/cases/${caseId}/timelines/${timelinesId}`);

  if (res && res.error) {
    yield put({
      type: CASE_DELETE_TIMELINES_FAILURE,
      feedback: {
        message: 'feedback.deleteTimelinesFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_DELETE_TIMELINES_SUCCESS,
    data: { timelines: res, deletedTimelinesId: timelinesId },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchDeleteTimelines() {
  yield takeEvery(deleteTimelines, withCurrentCaseId(doDeleteTimelines));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_DELETE_TIMELINES_BEGIN + '.TRIGGER':
      return {
        ...state,
        deleteTimelinesPending: true,
        deleteTimelinesFeedback: null,
      };

    case CASE_DELETE_TIMELINES_SUCCESS:
      return {
        ...state,
        deleteTimelinesPending: false,
        deleteTimelinesFeedback: action.feedback,
      };

    case CASE_DELETE_TIMELINES_FAILURE:
      return {
        ...state,
        deleteTimelinesPending: false,
        deleteTimelinesFeedback: action.feedback,
      };

    case CASE_DELETE_TIMELINES_DISMISS_FEEDBACK:
      return {
        ...state,
        deleteTimelinesFeedback: null,
      };

    default:
      return state;
  }
}
