import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  CASE_MERGE_DOCUMENTS_BEGIN,
  CASE_MERGE_DOCUMENTS_SUCCESS,
  CASE_MERGE_DOCUMENTS_FAILURE,
  CASE_MERGE_DOCUMENTS_DISMISS_FEEDBACK,
  CASE_ASSIGN_DOCUMENTS_SUCCESS,
  CASE_FETCH_DOCUMENTS_BEGIN,
  CASE_FETCH_FOLDER_BEGIN,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import {
  withCurrentCaseId,
  selectCurrentFolder,
  selectEnableNotifications,
} from '../../../common/selectors';

export const mergeDocuments = createPromiseAction(CASE_MERGE_DOCUMENTS_BEGIN);

export function dismissMergeDocumentsFeedback() {
  return {
    type: CASE_MERGE_DOCUMENTS_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_FETCH_CASE_BEGIN actions
export function* doMergeDocuments(action) {
  const {
    payload: { caseId, ...rest },
  } = action;
  const isNotificationEnabled = yield select(selectEnableNotifications);

  let resMerge = yield call(api.post, `/cases/${caseId}/merged-files`, rest);
  let res;
  if (!resMerge.error) {
    res = yield call(api.put, `/cases/${caseId}/merged-files/${resMerge.id}`);
  }

  if ((res && res.error) || (resMerge && resMerge.error)) {
    yield put({
      type: CASE_MERGE_DOCUMENTS_FAILURE,
      feedback: {
        message: 'feedback.mergeDocumentsFailure',
        error: (res && res.error) || (resMerge && resMerge.error),
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, { res, resMerge });
  }

  const currentFolder = yield select(selectCurrentFolder);

  if (currentFolder && rest.folderId === currentFolder.id) {
    if (rest.generateGlobalPaging || currentFolder.globalPaging)
      yield put({ type: CASE_FETCH_DOCUMENTS_BEGIN });
    else
      yield put({
        type: CASE_ASSIGN_DOCUMENTS_SUCCESS,
        data: { document: res, shouldAssign: true },
      });
    yield put({
      type: CASE_FETCH_FOLDER_BEGIN,
      payload: { folderId: rest.folderId, noPromise: true },
    });
  }

  yield put({
    type: CASE_MERGE_DOCUMENTS_SUCCESS,
    ...((!isNotificationEnabled || !resMerge.asyncMerge) && {
      feedback: { message: 'feedback.mergeDocumentsSuccess' },
    }),
  });
  yield call(resolvePromiseAction, action, { res, resMerge });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchFetchMergeDocuments(caseId) {
  yield takeLatest(mergeDocuments, withCurrentCaseId(doMergeDocuments));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_MERGE_DOCUMENTS_BEGIN + '.TRIGGER':
      return {
        ...state,
        mergeDocumentsPending: true,
        mergeDocumentsFeedback: null,
      };

    case CASE_MERGE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        mergeDocumentsPending: false,
        mergeDocumentsFeedback: action.feedback,
      };

    case CASE_MERGE_DOCUMENTS_FAILURE:
      return {
        ...state,
        mergeDocumentsPending: false,
        mergeDocumentsFeedback: action.feedback,
      };

    case CASE_MERGE_DOCUMENTS_DISMISS_FEEDBACK:
      return {
        ...state,
        mergeDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
