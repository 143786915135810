import {
  CASE_FETCH_SYSTEM_TEST_BEGIN,
  CASE_FETCH_SYSTEM_TEST_SUCCESS,
  CASE_FETCH_SYSTEM_TEST_FAILURE,
  CASE_FETCH_SYSTEM_TEST_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const fetchSystemTest = createPromiseAction(CASE_FETCH_SYSTEM_TEST_BEGIN);

export function dismissFetchSystemTestFeedback() {
  return {
    type: CASE_FETCH_SYSTEM_TEST_DISMISS_FEEDBACK,
  };
}

export function* doFetchSystemTest(action) {
  const { caseId, userId } = action.payload;

  let res = yield call(api.get, `/cases/${caseId}/users/${userId}/diagnostics`);

  if (res && res.error) {
    yield put({
      type: CASE_FETCH_SYSTEM_TEST_FAILURE,
      feedback: {
        message: 'feedback.fetchSystemTestFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_FETCH_SYSTEM_TEST_SUCCESS,
    data: { systemTestResult: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchFetchSystemTest() {
  yield takeLatest(fetchSystemTest, withCurrentCaseId(doFetchSystemTest));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_FETCH_SYSTEM_TEST_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        fetchSystemTestPending: true,
        fetchSystemTestFeedback: null,
      };

    case CASE_FETCH_SYSTEM_TEST_SUCCESS:
      // The request is success
      return {
        ...state,
        fetchSystemTestPending: false,
        fetchSystemTestFeedback: action.feedback,
        systemTestResult: action.data.systemTestResult,
      };

    case CASE_FETCH_SYSTEM_TEST_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSystemTestPending: false,
        fetchSystemTestFeedback: action.feedback,
      };

    case CASE_FETCH_SYSTEM_TEST_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        fetchSystemTestFeedback: null,
      };

    default:
      return state;
  }
}
