import { call, put, takeEvery } from 'redux-saga/effects';
import {
  CASE_UPDATE_FOLDER_SEQ_BEGIN,
  CASE_UPDATE_FOLDER_SEQ_SUCCESS,
  CASE_UPDATE_FOLDER_SEQ_FAILURE,
  CASE_UPDATE_FOLDER_SEQ_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';
import { replaceItemImmutable } from 'utils/arrays';

export const updateFolderSeq = createPromiseAction(CASE_UPDATE_FOLDER_SEQ_BEGIN, payload => ({
  inputData: payload,
}));

export function dismissUpdateFolderSeqFeedback() {
  return {
    type: CASE_UPDATE_FOLDER_SEQ_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_BUNDLE_BEGIN actions
export function* doUpdateFolderSeq(action) {
  const { inputData, caseId } = action.payload;
  const { folder, containerId, containerEtag, index } = inputData;
  const res = yield call(
    api.put,
    `/cases/${caseId}/containers/${containerId}/folders/${folder.id}`,
    {
      index,
      etag: containerEtag,
    },
  );

  if (res && res.error) {
    yield put({
      type: CASE_UPDATE_FOLDER_SEQ_FAILURE,
      feedback: {
        message: 'feedback.updateFolderSeqFailure',
        error: res.error,
        //retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_UPDATE_FOLDER_SEQ_SUCCESS,
    data: { container: res, containerId },
    feedback: { message: 'feedback.updateFolderSeqSuccess', params: { folderName: folder.name } },
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateFolderSeq() {
  yield takeEvery(updateFolderSeq, withCurrentCaseId(doUpdateFolderSeq));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_FOLDER_SEQ_BEGIN + '.TRIGGER':
      return {
        ...state,
        updateFolderSeqPending: true,
        updateFolderSeqFeedback: null,
        prevFolders: state.folders,
        prevContainers: state.containers,
      };

    case CASE_UPDATE_FOLDER_SEQ_SUCCESS:
      const containerIndex = state.containers.findIndex(
        container => container.id === action.data.containerId,
      );
      const containerCode = state.containers[containerIndex].code;
      return {
        ...state,
        updateFolderSeqPending: false,
        updateFolderSeqFeedback: action.feedback,
        prevFolders: null,
        prevContainers: null,
        containers: replaceItemImmutable(state.containers, action.data.container, containerIndex),
        folders: { ...state.folders, [containerCode]: action.data.container.folders },
      };

    case CASE_UPDATE_FOLDER_SEQ_FAILURE:
      const concurencyError = Object.keys(action.feedback.error).includes('folders');
      const containerIndexVAl = state.containers.findIndex(
        container => container.id === action.feedback.error.id,
      );
      return {
        ...state,
        updateFolderSeqPending: false,
        updateFolderSeqFeedback: action.feedback,
        containers: state.prevContainers,
        folders: state.prevFolders,
        prevFolders: null,
        prevContainers: null,
        ...(concurencyError && {
          containers: replaceItemImmutable(
            state.containers,
            action.feedback.error,
            containerIndexVAl,
          ),
          folders: {
            ...state.folders,
            [action.feedback.error.id]: action.feedback.error.folders,
          },
        }),
      };

    case CASE_UPDATE_FOLDER_SEQ_DISMISS_FEEDBACK:
      return {
        ...state,
        updateFolderSeqFeedback: null,
      };

    default:
      return state;
  }
}
