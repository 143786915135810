import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import T from 'i18n';
import { Button, Grid } from '@mui/material';
import TrialView_Logo from 'images/TrialView_Logo.png';
import { selectIsAuthenticated } from 'common/selectors';
import * as actions from 'features/auth/redux/actions';
import history from 'common/history';

const TVHeader = ({ isHomePage, isMobileScreen }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  return (
    <Grid
      container
      sx={{
        width: 'auto',
        height: `${isMobileScreen ? '10rem' : '12rem'}`,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: `${isMobileScreen ? '2%' : '7%'}`,
        paddingLeft: `${isMobileScreen ? '2%' : '7%'}`,
        background: 'linear-gradient(90deg, #4567E5 0%, #7BA0F4 100%)',
        backgroundBlendMode: 'multiply',
      }}
    >
      <Grid
        item
        sx={{
          ...(!isHomePage && { '&:hover': { cursor: 'pointer' } }),
        }}
        onClick={() => {
          if (!isHomePage)
            history.push(history.location.pathname.replace(history.location.pathname, '/'));
        }}
      >
        <img src={TrialView_Logo} alt="TV_Logo" />
      </Grid>
      <Grid
        item
        sx={{
          pr: '0.5rem',
          pl: '1.5rem',
          ...(isMobileScreen && { display: 'flex', flexDirection: 'column', pl: 0 }),
        }}
      >
        <Button
          variant="outlined"
          sx={{
            borderRadius: '14.5px',
            backgroundColor: '#D86B57',
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '1.6rem',
            letterSpacing: '1.09px',
            '&:hover': {
              backgroundColor: '#D86B57',
              color: '#FFFFFF',
              borderColor: '#D86B57',
            },
            '@media (max-width: 550px)': {
              fontSize: '0.9rem',
            },
          }}
          onClick={e => {
            isAuthenticated ? dispatch(actions.logout()) : dispatch(actions.loginRequired());
            e.preventDefault();
          }}
        >
          {isAuthenticated ? T.translate('home.logOutBtnText') : T.translate('home.logInBtnText')}
        </Button>
        <Button
          variant="outlined"
          sx={{
            ...(isMobileScreen ? { mt: '0.5rem' } : { ml: '1rem' }),
            borderRadius: '14.5px',
            backgroundColor: '#3a68ed',
            color: '#FFFFFF',
            fontWeight: 700,
            fontSize: '1rem',
            lineHeight: '1.6rem',
            letterSpacing: '1.09px',
            '&:hover': {
              backgroundColor: '#3a68ed',
              color: '#FFFFFF',
            },
            '@media (max-width: 550px)': {
              fontSize: '0.9rem',
            },
          }}
          onClick={() => window.open('https://trialview.stonly.com/kb/en?lang=en')}
        >
          {T.translate('common.helpCenter')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TVHeader;
