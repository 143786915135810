import { put, take, cancel, fork, select, call, delay } from 'redux-saga/effects';
import {
  VIEWING_UPDATE_ANNOTATION_BEGIN,
  VIEWING_UPDATE_ANNOTATION_SUCCESS,
  VIEWING_UPDATE_ANNOTATION_FAILURE,
  VIEWING_UPDATE_ANNOTATION_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFile, withCurrentUserId } from 'common/selectors';
import { replaceItemImmutable } from 'utils/arrays';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { filterAnnotations } from './filterAnnotations';

export const updateAnnotation = createPromiseAction(VIEWING_UPDATE_ANNOTATION_BEGIN);

// export function updateAnnotation({ annotation, noDebounce, fileId }) {
//   // If need to pass args to saga, pass it with the begin action.
//   return {
//     type: VIEWING_UPDATE_ANNOTATION_BEGIN,
//     payload: { annotation, noDebounce, fileId },
//   };
// }

export function dismissUpdateAnnotationFeedback() {
  return {
    type: VIEWING_UPDATE_ANNOTATION_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on VIEWING_UPDATE_ANNOTATION_BEGIN actions
export function* doUpdateAnnotation(action) {
  const {
    payload: {
      annotation,
      id,
      tags,
      shared,
      noDebounce,
      fileId,
      folderId,
      caseId,
      currentUserId: userId,
      updateImageOnly,
    },
  } = action;
  if (!noDebounce) yield delay(process.env.REACT_APP_DEBOUNCE); // debounce
  const file = yield select(selectCurrentFile);

  const res = yield call(api.put, `/cases/${caseId}/files/${fileId || file}/annotations/${id}`, {
    annotation,
    tags,
    folderId,
    shared,
  });

  if (res && res.error) {
    yield put({
      type: VIEWING_UPDATE_ANNOTATION_FAILURE,
      feedback: {
        message: 'feedback.updateAnnotationFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: VIEWING_UPDATE_ANNOTATION_SUCCESS,
    data: { annotation: res, updateImageOnly },
    userId,
  });
  yield call(resolvePromiseAction, action, res);
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateAnnotation() {
  //debounce
  let task;
  while (true) {
    const action = yield take(updateAnnotation);
    if (task && !action.noDebounce) {
      yield cancel(task);
    }
    task = yield fork(withCurrentUserId(withCurrentCaseId(doUpdateAnnotation)), action);
  }
}

export function useUpdateAnnotation() {
  const dispatch = useDispatch();

  const { updateAnnotationPending, updateAnnotationFeedback, annotations } = useSelector(
    state => ({
      updateAnnotationPending: state.viewing.updateAnnotationPending,
      updateAnnotationFeedback: state.viewing.updateAnnotationFeedback,
      annotations: state.viewing.annotations,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(updateAnnotation(...args));
    },
    [dispatch],
  );

  return {
    annotations,
    updateAnnotation: boundAction,
    updateAnnotationPending,
    updateAnnotationFeedback,
  };
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case VIEWING_UPDATE_ANNOTATION_BEGIN:
      return {
        ...state,
        updateAnnotationPending: true,
        updateAnnotationFeedback: null,
      };

    case VIEWING_UPDATE_ANNOTATION_SUCCESS:
      const indexOfTheAnnotationToUpdate =
        !action.data.updateImageOnly &&
        state.annotations.findIndex(x => x.id === action.data.annotation.id);

      const newAnnotations =
        !action.data.updateImageOnly &&
        state.annotations &&
        replaceItemImmutable(
          state.annotations,
          action.data.annotation,
          indexOfTheAnnotationToUpdate,
        );

      return {
        ...state,
        updateAnnotationPending: false,
        updateAnnotationFeedback: action.feedback,
        ...(!action.data.updateImageOnly &&
          state.annotations && {
            annotations: newAnnotations,
            filteredAnnotations: filterAnnotations(
              newAnnotations,
              state.updatedFilters,
              action.userId,
            ),
          }),
      };

    case VIEWING_UPDATE_ANNOTATION_FAILURE:
      return {
        ...state,
        updateAnnotationPending: false,
        updateAnnotationFeedback: action.feedback,
      };

    case VIEWING_UPDATE_ANNOTATION_DISMISS_FEEDBACK:
      return {
        ...state,
        updateAnnotationFeedback: null,
      };

    default:
      return state;
  }
}
