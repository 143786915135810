import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  CONFERENCE_GET_STREAM_BEGIN,
  CONFERENCE_GET_STREAM_SUCCESS,
  CONFERENCE_GET_STREAM_FAILURE,
  CONFERENCE_GET_STREAM_DISMISS_FEEDBACK,
} from './constants';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from 'common/selectors';

export const getStream = createPromiseAction(CONFERENCE_GET_STREAM_BEGIN);

export function dismissGetStreamFeedback() {
  return {
    type: CONFERENCE_GET_STREAM_DISMISS_FEEDBACK,
  };
}

export function* doGetStream(action) {
  const {
    payload: { caseId },
  } = action;

  let res = yield call(api.get, `/cases/${caseId}/streaming`);

  if (res && res.error) {
    yield put({
      type: CONFERENCE_GET_STREAM_FAILURE,
      feedback: { message: 'feedback.getStreamFailure', error: res.error },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CONFERENCE_GET_STREAM_SUCCESS,
    data: { stream: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchGetStream() {
  yield takeLatest(getStream, withCurrentCaseId(doGetStream));
}

export function useStream() {
  const dispatch = useDispatch();

  const { liveStream, getStreamPending, getStreamFeedback, iceServers, iceServersTwilio } = useSelector(
    state => ({
        liveStream: state.conference.stream && state.conference.stream.waitingStreamUrl,
        iceServers: state.conference.stream && state.conference.stream.iceServers,
        iceServersTwilio: state.conference.stream && state.conference.stream.iceServersTwilio,
        getStreamPending: state.conference.getStreamPending,
        getStreamFeedback: state.conference.getStreamFeedback,
    }),
    shallowEqual,
  );

  const boundAction = useCallback(
    (...args) => {
      return dispatch(getStream(...args));
    },
    [dispatch],
  );

  const boundDismissFeedback = useCallback(() => {
    return dispatch(dismissGetStreamFeedback());
  }, [dispatch]);

  return {
    liveStream,
    iceServers,
    iceServersTwilio,
    getStream: boundAction,
    getStreamPending,
    getStreamFeedback,
    dismissGetStreamFeedback: boundDismissFeedback,
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case CONFERENCE_GET_STREAM_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        getStreamPending: true,
        getStreamFeedback: null,
      };

    case CONFERENCE_GET_STREAM_SUCCESS:
      // The request is success
      return {
        ...state,
        getStreamPending: false,
        getStreamFeedback: action.feedback,
        stream: action.data.stream,
      };

    case CONFERENCE_GET_STREAM_FAILURE:
      // The request is failed
      return {
        ...state,
        getStreamPending: false,
        getStreamFeedback: action.feedback,
      };

    case CONFERENCE_GET_STREAM_DISMISS_FEEDBACK:
      // Dismiss the request failure error
      return {
        ...state,
        getStreamFeedback: null,
      };

    default:
      return state;
  }
}
