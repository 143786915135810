import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import {
  CASE_UNASSIGN_DOCUMENTS_BEGIN,
  CASE_UNASSIGN_DOCUMENTS_SUCCESS,
  CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS,
  CASE_UNASSIGN_DOCUMENTS_FAILURE,
  CASE_UNASSIGN_DOCUMENTS_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import { withCurrentCaseId, selectCurrentFolder, selectParams } from '../../../common/selectors';
import addSortNumberToDocuments from './addSortNumberToDocuments';
import history from 'common/history';

export function unassignDocuments(documents) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_UNASSIGN_DOCUMENTS_BEGIN,
    payload: { documents },
  };
}

export function dismissUnassignDocumentsFeedback() {
  return {
    type: CASE_UNASSIGN_DOCUMENTS_DISMISS_FEEDBACK,
  };
}

function* unassignDocument(action) {
  const {
    payload: { document, length, index, caseId },
  } = action;

  const currentFolder = yield select(selectCurrentFolder);
  const { file } = yield select(selectParams);

  const res = yield call(
    api.del,
    currentFolder.id
      ? `/cases/${caseId}/folders/${currentFolder.id}/files/${document.id}`
      : `/cases/${caseId}/files/${document.id}`,
  );

  if (res && res.error) {
    return yield put({
      type: CASE_UNASSIGN_DOCUMENTS_FAILURE,
      feedback: { documentId: document.id, error: res.error /*retryAction: action*/ },
    });
  }

  yield put({
    type: CASE_UNASSIGN_DOCUMENTS_SUCCESS,
    data: { document, zeroBasedIndex: currentFolder.zeroBasedIndex },
  });
  if (length - 1 === index)
    yield put({
      type: CASE_UNASSIGN_ALL_DOCUMENTS_SUCCESS,
    });
  if (file) history.push(history.location.pathname.replace(/\/files.*/, ``));
}

// worker Saga: will be fired on CASE_REMOVE_DOCUMENTS_BEGIN actions
export function* doUnassignDocuments({ payload: { documents } }) {
  return yield all(
    documents.map((document, index) =>
      call(withCurrentCaseId(unassignDocument), {
        payload: { document, length: documents.length, index },
      }),
    ),
  );
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUnassignDocuments() {
  yield takeEvery(CASE_UNASSIGN_DOCUMENTS_BEGIN, doUnassignDocuments);
}

const deleteSpecificFile = (files, { id }) => {
  const indexOfTheFolderToUpdate = files.findIndex(x => x.id === id);

  return [
    ...((indexOfTheFolderToUpdate > 0 && files.slice(0, indexOfTheFolderToUpdate)) || []),
    ...((indexOfTheFolderToUpdate < files.length && files.slice(indexOfTheFolderToUpdate + 1)) ||
      []),
  ];
};

// Redux reducer
export function reducer(state, action) {
  let unassignDocumentsTempFeed;
  let documentLeft;
  switch (action.type) {
    case CASE_UNASSIGN_DOCUMENTS_BEGIN:
      return {
        ...state,
        unassignDocumentsPending: state.unassignDocumentsPending + action.payload.documents.length,
        // clear only if batch finished
        ...(!state.unassignDocumentsPending && { unassignDocumentsFeedback: null }),
      };

    case CASE_UNASSIGN_DOCUMENTS_SUCCESS:
      documentLeft = state.unassignDocumentsPending - 1;
      unassignDocumentsTempFeed = {
        message:
          state.unassignDocumentsTempFeed &&
          state.unassignDocumentsTempFeed.params &&
          state.unassignDocumentsTempFeed.params.unsuccesful
            ? 'feedback.unassignDocumentsFailureAndSuccess'
            : 'feedback.unassignDocumentsSuccess',
        params: {
          ...(state.unassignDocumentsTempFeed && state.unassignDocumentsTempFeed.params),
          successful:
            ((state.unassignDocumentsTempFeed &&
              state.unassignDocumentsTempFeed.params &&
              state.unassignDocumentsTempFeed.params.successful) ||
              0) + 1,
        },
      };
      return {
        ...state,
        unassignDocumentsPending: documentLeft,
        unassignDocumentsTempFeed,
        ...(!documentLeft && {
          unassignDocumentsFeedback: unassignDocumentsTempFeed,
          unassignDocumentsTempFeed: null,
        }),
        documents: addSortNumberToDocuments(
          deleteSpecificFile(state.documents, action.data.document),
          action.data.zeroBasedIndex,
        ),
      };

    case CASE_UNASSIGN_DOCUMENTS_FAILURE:
      documentLeft = state.unassignDocumentsPending - 1;
      unassignDocumentsTempFeed = {
        message:
          state.unassignDocumentsTempFeed &&
          state.unassignDocumentsTempFeed.params &&
          state.unassignDocumentsTempFeed.params.successful
            ? 'feedback.unassignDocumentsFailureAndSuccess'
            : 'feedback.unassignDocumentsFailure',
        params: {
          ...(state.unassignDocumentsTempFeed && state.unassignDocumentsTempFeed.params),
          unsuccessful: [
            ...((state.unassignDocumentsTempFeed &&
              state.unassignDocumentsTempFeed.params &&
              state.unassignDocumentsTempFeed.params.unsuccessful) ||
              []),
            action.feedback.documentId,
          ],
        },
        error: [
          ...((state.unassignDocumentsTempFeed && state.unassignDocumentsTempFeed.error) || []),
          action.feedback.error,
        ],
      };
      return {
        ...state,
        unassignDocumentsPending: documentLeft,
        unassignDocumentsTempFeed,
        ...(!documentLeft && {
          unassignDocumentsFeedback: unassignDocumentsTempFeed,
          unassignDocumentsTempFeed: null,
        }),
      };

    case CASE_UNASSIGN_DOCUMENTS_DISMISS_FEEDBACK:
      return {
        ...state,
        unassignDocumentsFeedback: null,
      };

    default:
      return state;
  }
}
