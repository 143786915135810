export function flattenFolders(folders, level = 0) {
  let flatFolders = [];
  if (folders)
    folders.forEach(folder => {
      if (folder.name !== 'index' && folder)
        flatFolders.push({
          ...folder,
          name: '\u00A0'.repeat(level * 2) + folder.name, // Adding indentation based on level
        });

      if (folder.folders.length > 0) {
        flatFolders = flatFolders.concat(flattenFolders(folder.folders, level + 1));
      }
    });

  return flatFolders;
}

export function removeIndexFolders(folders) {
  if (folders)
    return folders
      .filter(folder => !(folder.name === 'index' && folder.readOnly))
      .map(folder => ({
        ...folder,
        folders: removeIndexFolders(folder.folders),
      }));
}

export function getFolderById(folders, folderId) {
  let folder = null;
  if (folders)
    folders.forEach(f => {
      if (f.id === folderId) {
        folder = f;
      } else if (f.folders.length > 0) {
        folder = getFolderById(f.folders, folderId);
      }
    });

  return folder;
}
