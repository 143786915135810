import {
  CASE_REMOVE_CASE_ROLES_USER_BEGIN,
  CASE_REMOVE_CASE_ROLES_USER_SUCCESS,
  CASE_REMOVE_CASE_ROLES_USER_FAILURE,
  CASE_REMOVE_CASE_ROLES_USER_DISMISS_FEEDBACK,
} from './constants';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createPromiseAction,
  resolvePromiseAction,
  rejectPromiseAction,
} from '@adobe/redux-saga-promise';
import api from 'common/api';
import { withCurrentCaseId } from '../../../common/selectors';

export const removeCaseRolesUser = createPromiseAction(CASE_REMOVE_CASE_ROLES_USER_BEGIN);

export function dismissRemoveCaseRolesUserFeedback() {
  return {
    type: CASE_REMOVE_CASE_ROLES_USER_DISMISS_FEEDBACK,
  };
}

export function* doRemoveCaseRolesUser(action) {
  const { caseId, roleType, userId } = action.payload;

  let res = yield call(api.del, `/cases/${caseId}/roles/${roleType}/users/${userId}`);

  if (res && res.error) {
    yield put({
      type: CASE_REMOVE_CASE_ROLES_USER_FAILURE,
      feedback: {
        message: 'feedback.removeCaseRolesUserFailure',
        error: res.error,
        retryAction: action,
      },
    });
    return yield call(rejectPromiseAction, action, res.error);
  }

  yield put({
    type: CASE_REMOVE_CASE_ROLES_USER_SUCCESS,
    data: { roleType: res },
  });
  yield call(resolvePromiseAction, action, res);
}

export function* watchRemoveCaseRolesUser() {
  yield takeEvery(removeCaseRolesUser, withCurrentCaseId(doRemoveCaseRolesUser));
}

export function reducer(state, action) {
  switch (action.type) {
    case CASE_REMOVE_CASE_ROLES_USER_BEGIN + '.TRIGGER':
      // Just after a request is sent
      return {
        ...state,
        removeCaseRolesUserPending: true,
        removeCaseRolesUserFeedback: null,
      };

    case CASE_REMOVE_CASE_ROLES_USER_SUCCESS:
      // The request is success
      return {
        ...state,
        removeCaseRolesUserPending: false,
        removeCaseRolesUserFeedback: action.feedback,
      };

    case CASE_REMOVE_CASE_ROLES_USER_FAILURE:
      // The request is failed
      return {
        ...state,
        removeCaseRolesUserPending: false,
        removeCaseRolesUserFeedback: action.feedback,
      };

    case CASE_REMOVE_CASE_ROLES_USER_DISMISS_FEEDBACK:
      // Dismiss the request failure Feedback
      return {
        ...state,
        removeCaseRolesUserFeedback: null,
      };

    default:
      return state;
  }
}
