import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  CASE_UPDATE_DOCUMENT_SORT_BEGIN,
  CASE_UPDATE_DOCUMENT_SORT_SUCCESS,
  CASE_UPDATE_DOCUMENT_SORT_FAILURE,
  CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK,
} from './constants';
import api from 'common/api';
import {
  withCurrentCaseId,
  selectDocumentIds,
  selectCurrentFolder,
} from '../../../common/selectors';
import { moveItemImmutable } from 'utils/arrays';
import addSortNumberToDocuments from './addSortNumberToDocuments';

export function updateDocumentSort({
  index = 0,
  ids = [],
  zeroBasedIndex,
  etag,
  isAttachments,
  agreed,
}) {
  // If need to pass args to saga, pass it with the begin action.
  return {
    type: CASE_UPDATE_DOCUMENT_SORT_BEGIN,
    payload: { index, ids, zeroBasedIndex, etag, isAttachments, agreed },
  };
}

export function dismissUpdateDocumentSortFeedback() {
  return {
    type: CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK,
  };
}

// worker Saga: will be fired on CASE_UPDATE_META_BEGIN actions
export function* doUpdateDocumentSort(action) {
  const {
    payload: { index, ids, etag, isAttachments, caseId },
  } = action;
  const currentFolder = yield select(selectCurrentFolder);
  //if more than one we presort and documents are sorted before hand
  const fileSortOrder = yield select(selectDocumentIds);
  const folderId = currentFolder.id;

  const res = yield ids.length === 1
    ? call(api.put, `/cases/${caseId}/folders/${folderId}/files/${ids[0]}`, {
        index,
        etag: etag ? etag : currentFolder.etag,
      })
    : call(api.put, `/cases/${caseId}/folders/${folderId}/files`, {
        ...(isAttachments ? { files: ids.map(({ id }) => id) } : { files: fileSortOrder }),
        etag: currentFolder.etag,
      });

  if (res && res.error) {
    return yield put({
      type: CASE_UPDATE_DOCUMENT_SORT_FAILURE,
      feedback: {
        message: 'feedback.updateDocumentSortFailure',
        error: res.error,
        index,
        retryAction: action,
      },
    });
  }

  // Dispatch success action out of try/catch so that render errors are not catched.
  yield put({
    type: CASE_UPDATE_DOCUMENT_SORT_SUCCESS,
    data: {
      documents: res.files,
      index: index,
      zeroBasedIndex: currentFolder.zeroBasedIndex,
      agreed: currentFolder.agreed,
      isAttachments,
    },
    feedback: {
      message: 'feedback.changePositionSuccess',
      params: { folderName: ids[0], newIndex: index + 1 },
      id: new Date().getTime(), // Add unique ID to feedback
    },
  });
}

/*
  Alternatively you may use takeEvery.

  takeLatest does not allow concurrent requests. If an action gets
  dispatched while another is already pending, that pending one is cancelled
  and only the latest one will be run.
*/
export function* watchUpdateDocumentSort() {
  yield takeLatest(CASE_UPDATE_DOCUMENT_SORT_BEGIN, withCurrentCaseId(doUpdateDocumentSort));
}

// Redux reducer
export function reducer(state, action) {
  switch (action.type) {
    case CASE_UPDATE_DOCUMENT_SORT_BEGIN:
      if (action.payload.isAttachments) {
        return {
          ...state,
          updateDocumentSortPending: true,
          updateDocumentSortFeedback: null,
          prevSortedDocuments: state.documents,
          documents: addSortNumberToDocuments(
            action.payload.ids,
            action.payload.zeroBasedIndex,
            !action.payload.agreed,
          ),
        };
      } else {
        const ids = action.payload.ids;
        const indexOfTheFirstDocumentToMove = state.documents.findIndex(
          doc => doc.id === action.payload.ids[0],
        );
        if (indexOfTheFirstDocumentToMove > action.payload.index) ids.reverse();
        return {
          ...state,
          updateDocumentSortPending: true,
          updateDocumentSortFeedback: null,
          prevSortedDocuments: state.documents,
          documents: addSortNumberToDocuments(
            ids.reduce((documents, id) => {
              const indexOfTheDocumentToMove = documents.findIndex(doc => doc.id === id);
              return moveItemImmutable(
                documents,
                documents[indexOfTheDocumentToMove],
                indexOfTheDocumentToMove,
                action.payload.index,
              );
            }, state.documents),
            action.payload.zeroBasedIndex,
            !action.payload.agreed,
          ),
        };
      }
    case CASE_UPDATE_DOCUMENT_SORT_SUCCESS:
      return {
        ...state,
        updateDocumentSortPending: false,
        ...(action.data.isAttachments && {
          documents: addSortNumberToDocuments(
            action.data.documents,
            action.data.zeroBasedIndex,
            !action.data.agreed,
          ),
        }),
        updateDocumentSortFeedback: action.feedback,
        prevSortedDocuments: null,
        fetchFolderAfterSortPending: true, // bcoz after sort we are fetching folders to get new etag.
      };

    case CASE_UPDATE_DOCUMENT_SORT_FAILURE:
      return {
        ...state,
        updateDocumentSortPending: false,
        updateDocumentSortFeedback: action.feedback,
        documents: state.prevSortedDocuments,
        prevSortedDocuments: null,
      };
    case CASE_UPDATE_DOCUMENT_SORT_DISMISS_FEEDBACK:
      return {
        ...state,
        updateDocumentSortFeedback: null,
      };

    default:
      return state;
  }
}
