export const FitmentType = {
  Width: 'FullWidth',
  Page: 'FullPage',
};

export const MouseToolType = {
  PanAndEdit: 'PanAndEdit',
  SelectText: 'SelectText',
  HighlightAnnotation: 'HighlightAnnotation',
  RectangleAnnotation: 'RectangleAnnotation',
  TextHyperlinkAnnotation: 'TextHyperlinkAnnotation',
  PlaceSignature: 'PlaceSignature',
  TextSignature: 'TextSignature',
  TextAnnotation: 'TextAnnotation',
};

export const ZoomType = {
  In: 'in',
  Out: 'out',
  Width: 'width',
  Page: 'page',
};
